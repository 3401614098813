import {
  DEFAULT_ORG_USER_PERM_SCHEMA,
  ORG_USER_PRIV,
  ORG_USER_PRIV_TO_STRING,
  populateAgentPropsInput,
  UserPropsInputValidation,
} from '@cils/common'
import * as React from 'react'
import { Button, Col, FormGroup, Label } from 'reactstrap'
import * as Yup from 'yup'
import {
  GQLAddUserToOrgInput,
  GQLAddUserToOrgResponse,
  GQLOrgHasUser,
  GQLSingleIDInput,
  GQLUpdateOrgHasUserInput,
  GQLUser,
} from '../../../@types/server'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { ODColors } from '../../../styles/ODColors'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'
import { ChangeUserOrgPermissionOnNewUserModal } from './PermissionModal/ChangeUserOrgPermissionOnNewUserModal'

type OrgUserEditContainerProps = {
  orgId: number
  idEditing?: number
}

const USER_FULL_SNAPSHOT = `
  userId
  name
  email
  organizations {
    orgUserPriv
    org {
      name
    }
  }
`

const GQL_CREATE = `
mutation addUserToOrg($data: AddUserToOrgInput!) {
  addUserToOrg(data: $data) {
    password
  }
}
`

const GQL_GET = `
query getUserOfId($data: SingleIDInput!) {
  getUserOfId(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

type PropsInput = Partial<GQLAddUserToOrgInput>
type Entity = GQLUser

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    username: UserPropsInputValidation.username.required('ID is required.'),
    name: UserPropsInputValidation.name.required('Name is required.'),
    // password: UserPropsInputValidation.password.required('Password is required.'),
    email: UserPropsInputValidation.email,
  })
}

const generateFakeOrgHasUser = (orgId: number): GQLOrgHasUser => {
  return {
    // @ts-ignore
    org: {
      orgId,
    },
    orgUserPriv: ORG_USER_PRIV.Normal,
    // @ts-ignore
    user: {
      userId: 0,
    },
    permSchema: JSON.stringify(DEFAULT_ORG_USER_PERM_SCHEMA),
  }
}

export const OrgUserEditContainer: React.FC<OrgUserEditContainerProps> = props => {
  const { idEditing, orgId } = props
  const apiCreate = useODMutation<Partial<PropsInput>, GQLAddUserToOrgResponse>(GQL_CREATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const orgHasUser = React.useRef<GQLOrgHasUser>(generateFakeOrgHasUser(orgId))
  const [showModal, setShowModal] = React.useState(false)

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          return {
            id: null,
            username: '',
            name: '',
            email: '',
            orgId,
            orgUserPriv: ORG_USER_PRIV.Normal,
            permSchema: JSON.stringify(DEFAULT_ORG_USER_PERM_SCHEMA),
          }
        }

        const orgPerm = data.organizations.find(o => o.org.orgId === orgId)!
        return {
          id: data.userId,
          username: data.username || '',
          name: data.name || '',
          email: data.email || '',
          orgId,
          orgUserPriv: orgPerm.orgUserPriv,
          permSchema: orgPerm.permSchema || JSON.stringify(DEFAULT_ORG_USER_PERM_SCHEMA),
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        const { password } = await apiCreate({
          email: data.email,
          username: data.username,
          name: data.name,
          orgId,
          orgUserPriv: orgHasUser.current.orgUserPriv,
          permSchema: orgHasUser.current.permSchema!,
        })
        Utils.showSuccess('Added a user.', 'Success')
        alert(`Generated password : \n\n${password}`)
        return SiteUrls.OrgAdmin.User.List(orgId)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateAgentPropsInput,
      deleteItem: async () => {
        // if (idEditing) {
        //   await apiRemove({ id: idEditing })
        //   Utils.showSuccess('Removed a category.', 'Success')
        // }
        return SiteUrls.OrgAdmin.User.List(orgId)
      },
    }),
    [idEditing, apiCreate, apiGet, orgId]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New User' : 'Edit User'

  const handleOrgHasUserChange = async (input: GQLUpdateOrgHasUserInput) => {
    orgHasUser.current.permSchema = input.permSchema
    orgHasUser.current.orgUserPriv = input.orgUserPriv!
    setShowModal(false)
    return true
  }

  return (
    <Provider title={title}>
      <ChangeUserOrgPermissionOnNewUserModal
        show={showModal}
        value={orgHasUser.current}
        onChange={handleOrgHasUserChange}
        onClose={() => setShowModal(false)}
      />
      <ODEntityInput name="username" label="ID" placeholder="Login ID for this account." inputType="text" />
      <ODEntityInput name="name" label="Name" placeholder="Researcher name" inputType="text" />
      <ODEntityInput name="email" label="Email" placeholder="Researcher email" inputType="text" />
      <FormGroup row>
        <Col md="3">
          <Label style={{ paddingTop: 7 }}>Password</Label>
        </Col>
        <Col xs="12" md="9">
          <div style={{ display: 'flex', color: ODColors.Primary, fontSize: 12, paddingTop: 7, alignItems: 'center' }}>
            <ODIcon icon={ODIcons.MaterialError} style={{ transform: 'scale(0.8)' }} />
            Automatically generate a password and send it by email
          </div>
        </Col>
      </FormGroup>
      <hr />
      <FormGroup row>
        <Col md="3">
          <Label style={{ paddingTop: 7 }}>Organization Permission</Label>
        </Col>
        <Col xs="12" md="9">
          <div style={{ display: 'flex', color: '#73818f', fontSize: 14, paddingTop: 7, alignItems: 'center' }}>
            {ORG_USER_PRIV_TO_STRING[orgHasUser.current.orgUserPriv as ORG_USER_PRIV]}
          </div>
          <div style={{ marginTop: 15 }}>
            <Button type="button" color="primary" outline onClick={() => setShowModal(true)}>
              Change user permission
            </Button>
          </div>
        </Col>
      </FormGroup>
      <hr />
      <ODEntityEditorFooter saveButtonName="Save" context={Context} />
    </Provider>
  )
}
