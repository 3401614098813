import { ErrorCode } from '@cils/common'
import { produce } from 'immer'
import * as React from 'react'
import { GQLAppConfig, GQLLoginResponse, GQLUser, GQLUsernameLogin } from '../@types/server'
import { GQLOkResponse } from '../agent'
import { AppOptions, WebAppOptions } from '../AppOptions'
import { LOCAL_STORAGE_KEY_TOKEN } from '../common'
import { GQL_APP_CONFIG, GQL_GET_USER_PROFILE, GQL_USER_LOGIN, GQL_USER_LOGOUT } from '../gqls'
import { Utils } from '../utils'
import { useODMutation, useODQuery } from './ODCommon'

export const GQL_ITEM_IN_LIST_SNAPSHOT = `
  itemId
  title
  desc
  dataId
  meta
  orgId
  imageFile {
    fileId
    link
  }
  mainAttachment {
    fileId
    link
  }
  itemHasImages {
    modality
    image {
      link
    }
  }
  isTimeLapse
  createdAt
  recordingTime
  modifiedTime
  hidden
  shortName
`

export const GQL_ITEM_SIMPLE_SNAPSHOT = `
  itemId
  title
  desc
  dataId
  meta
  orgId
  imageFile {
    fileId
    link
  }
  mainAttachment {
    fileId
    link
  }
  attachmentFiles {
    fileId
    link
  }
  itemHasImages {
    modality
    image {
      link
    }
  }
  isTimeLapse
  createdAt
  recordingTime
  modifiedTime
  hidden
  shortName
`
export const GQL_ITEM_SNAPSHOT = `
  itemId
  title
  desc
  dataId
  meta
  orgId
  permission {
    canModifyMetadata
    canShare
    canDownload
    canAddToWorkset
    canFavorite
    canAddAttachment
    canEditAttachment
  }
  itemHasImages {
    itemHasImageId
    modality
    image {
      link
    }
    timeLapse {
      link
    }
  }
  imageFile {
    fileId
    link
  }
  uploader {
    userId
    name
  }
  currentSnapshot {
    size
    tcfSize
    uploadedSize
    uploader {
      name
    }
    files {
      shfId
      path
      cilsFile {
        md5
        available
        size
        storedIn {
          refId
          storage {
            storageType
            address
          }
        }
      }
    }
    uploadStatus {
      totalBytes
      uploadedBytes
      rawData
    }
  }
  tags {
    tagId
    name
  }
  categories {
    categoryId
    name
    color
  }
  mainAttachmentId
  mainAttachment {
    fileId
    fileName
    link
    description
    attachmentType {
      typeId
      name
    }
  }
  attachmentFiles {
    fileId
    fileName
    link
    description
    attachmentType {
      typeId
      name
    }
  }
  isFavorite
  timeLapse {
    link
  }
  createdAt
  accessibleUsers {
    userId
    name
  }
  recordingTime
  modifiedTime
  inWorksets {
    wsId
    name
    ownerOrgId
    owner {
      userId
      name
      email
    }
  }
  errors
  markedMetaUpdateRequired
  shortName
`

type ODAppContextType = {
  appOptions: WebAppOptions
  state: ODAppReducerState
  getAppConfig: (input: void) => Promise<GQLAppConfig>
  getUserProfile: (input: void) => Promise<GQLUser>
  refreshProfile: () => Promise<void>

  loginUser: (input: GQLUsernameLogin) => Promise<GQLUser>
  logoutUser: () => Promise<void>

  setLoggedIn: (profile: GQLUser) => void

  currentWorksetName: string
  setCurrentWorksetName: (name: string) => void
  currentTagName: string
  setCurrentTagName: (name: string) => void
  currentMachineName: string
  setCurrentMachineName: (name: string) => void
  currentProjectName: string
  setCurrentProjectName: (name: string) => void
  currentProjectDataName: string
  setCurrentProjectDataName: (name: string) => void

  onDirectoryViewNodeClick: () => void
}

export enum LOGIN_STATE {
  Checking = 'Checking',
  LoggedOut = 'LoggedOut',
  LoggingIn = 'LoggingIn',
  LoggedIn = 'LoggedIn',
  LoggingOut = 'LoggingOut',
  OrgDeleted = 'OrgDeleted',
}

type ODAppContextProviderProps = {}

type ODAppReducerState = {
  loginState: LOGIN_STATE
  userProfile: GQLUser | null
  appConfig: GQLAppConfig | null
  directoryNodeCounter: number
}

enum ODAppActionType {
  SetLoggedIn = 'odApp/SetLoggedIn',
  SetLoggedOut = 'odApp/SetLoggedOut',
  SetAppConfig = 'odApp/SetAppConfig',
  SetNodeClick = 'odApp/SetNodeClick', // whenever directory view's node is clicked, count++
  SetOrgDeleted = 'odApp/SetOrgDeleted',
}

type ODAppActionSetLoggedIn = { type: ODAppActionType.SetLoggedIn; profile: GQLUser }
type ODAppActionSetLoggedOut = { type: ODAppActionType.SetLoggedOut }
type ODAppActionSetAppConfig = { type: ODAppActionType.SetAppConfig; config: GQLAppConfig }
type ODAppActionSetNodeClicked = { type: ODAppActionType.SetNodeClick }
type ODAppActionSetOrgDeleted = { type: ODAppActionType.SetOrgDeleted }

type ODAppReducerAction =
  | ODAppActionSetLoggedIn
  | ODAppActionSetLoggedOut
  | ODAppActionSetAppConfig
  | ODAppActionSetNodeClicked
  | ODAppActionSetOrgDeleted

interface ODAppReducer extends React.Reducer<ODAppReducerState, ODAppReducerAction> {}

function createAppReducer(): ODAppReducer {
  return function(state: ODAppReducerState, action: ODAppReducerAction): ODAppReducerState {
    return produce(state, draft => {
      switch (action.type) {
        case ODAppActionType.SetLoggedIn:
          draft.loginState = LOGIN_STATE.LoggedIn
          draft.userProfile = action.profile
          break
        case ODAppActionType.SetLoggedOut:
          draft.loginState = LOGIN_STATE.LoggedOut
          break
        case ODAppActionType.SetAppConfig:
          draft.appConfig = action.config
          break
        case ODAppActionType.SetNodeClick:
          draft.directoryNodeCounter += 1
          break
        case ODAppActionType.SetOrgDeleted:
          draft.loginState = LOGIN_STATE.OrgDeleted
          break
        default:
          return
      }
    })
  }
}

const actionSetLoggedIn = (profile: GQLUser): ODAppActionSetLoggedIn => ({ type: ODAppActionType.SetLoggedIn, profile })
const actionSetLoggedOut = (): ODAppActionSetLoggedOut => ({ type: ODAppActionType.SetLoggedOut })
const actionSetAppConfig = (config: GQLAppConfig): ODAppActionSetAppConfig => ({
  type: ODAppActionType.SetAppConfig,
  config,
})
const actionSetNodeClicked = (): ODAppActionSetNodeClicked => ({ type: ODAppActionType.SetNodeClick })
const actionSetOrgDeleted = (): ODAppActionSetOrgDeleted => ({ type: ODAppActionType.SetOrgDeleted })

function createInitialAppReducerState(): ODAppReducerState {
  return {
    loginState: LOGIN_STATE.Checking,
    userProfile: null,
    appConfig: null,
    directoryNodeCounter: 0,
  }
}

function createODAppContext(appOptions: WebAppOptions) {
  const Context: React.Context<ODAppContextType> = React.createContext<ODAppContextType>({} as ODAppContextType)

  const ODAppProvider: React.FC<ODAppContextProviderProps> = props => {
    const { children } = props

    const simulateDelay = appOptions?.SIMULATE_DELAY || 0

    const apiGetAppConfig = useODQuery<void, GQLAppConfig>(GQL_APP_CONFIG, simulateDelay)
    const apiGetUserProfile = useODQuery<void, GQLUser>(GQL_GET_USER_PROFILE, simulateDelay)
    const apiLoginUser = useODMutation<GQLUsernameLogin, GQLLoginResponse>(GQL_USER_LOGIN, simulateDelay)
    const apiLogout = useODMutation<void, GQLOkResponse>(GQL_USER_LOGOUT, simulateDelay)

    const [state, dispatch] = React.useReducer<ODAppReducer>(createAppReducer(), createInitialAppReducerState())
    const setLoggedIn = React.useCallback((profile: GQLUser) => dispatch(actionSetLoggedIn(profile)), [dispatch])
    const [currentWorksetName, setCurrentWorksetName] = React.useState('')
    const [currentTagName, setCurrentTagName] = React.useState('')
    const [currentMachineName, setCurrentMachineName] = React.useState('')

    // project
    const [currentProjectName, setCurrentProjectName] = React.useState('')
    const [currentProjectDataName, setCurrentProjectDataName] = React.useState('')

    const checkLogin = React.useCallback(async () => {
      try {
        const appConfig = await apiGetAppConfig()
        dispatch(actionSetAppConfig(appConfig))

        const profile = await apiGetUserProfile()
        setLoggedIn(profile)
      } catch (ex) {
        const errorCode = Utils.parseErrorCode(ex)
        if (errorCode === ErrorCode.OrganizationIsDeletedOrWillBeDeleted) {
          dispatch(actionSetOrgDeleted())
        } else {
          // 로그인 실패, 로그아웃된 상태
          dispatch(actionSetLoggedOut())
        }
      }
    }, [apiGetUserProfile, setLoggedIn, apiGetAppConfig])

    const loginUser = React.useCallback(
      async (data: GQLUsernameLogin): Promise<GQLUser> => {
        try {
          const res = await apiLoginUser(data)
          await localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.token)
          setLoggedIn(res.me)
          return res.me
        } catch (ex) {
          const errorCode = Utils.parseErrorCode(ex)
          if (errorCode === ErrorCode.OrganizationIsDeletedOrWillBeDeleted) {
            dispatch(actionSetOrgDeleted())
          }
          throw ex
        }
      },
      [apiLoginUser, setLoggedIn]
    )

    const logoutUser = React.useCallback(async () => {
      await apiLogout()
      await localStorage.clear()
      dispatch(actionSetLoggedOut())
    }, [apiLogout])

    const refreshProfile = React.useCallback(async () => {
      const profile = await apiGetUserProfile()
      setLoggedIn(profile)
    }, [apiGetUserProfile, setLoggedIn])

    const onDirectoryViewNodeClick = React.useCallback(() => {
      dispatch(actionSetNodeClicked())
    }, [dispatch])

    React.useEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      checkLogin()
    }, [checkLogin])

    const context: ODAppContextType = {
      appOptions,
      state,
      getAppConfig: apiGetAppConfig,
      getUserProfile: apiGetUserProfile,
      loginUser,
      logoutUser,
      setLoggedIn,
      refreshProfile,
      currentWorksetName,
      setCurrentWorksetName,
      currentTagName,
      setCurrentTagName,
      currentMachineName,
      setCurrentMachineName,
      onDirectoryViewNodeClick,

      // project
      currentProjectName,
      setCurrentProjectName,
      currentProjectDataName,
      setCurrentProjectDataName,
    }
    return <Context.Provider value={context}>{children}</Context.Provider>
  }

  return { Context, Provider: ODAppProvider }
}

const { Context, Provider } = createODAppContext(AppOptions)

export const ODAppProvider = Provider
export const ODAppContext = Context

export function useODAppContext(): ODAppContextType {
  return React.useContext(Context)
}
