import classNames from 'classnames'
import React from 'react'
import { Modal } from 'reactstrap'
import { GQLItem, GQLShareTokenInput } from '../../@types/server'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { GQL_ITEM_SNAPSHOT } from '../../context/ODAppContext'
import { useODQuery2 } from '../../context/ODCommon'
import { TCFDetailWrapper } from './TCFDetailWrapper'

type TCFDetailModalByShareTokenProps = {
  orgId: number
  shareToken: string
}

export const GQL_GET_ITEM_BY_SHARE_TOKEN = `
query getItemByShareToken($data: ShareTokenInput!) {
  getItemByShareToken(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}
`
export const TCFDetailModalByShareToken: React.FC<TCFDetailModalByShareTokenProps> = ({ orgId, shareToken }) => {
  const { loading, data: tcfItem } = useODQuery2<GQLShareTokenInput, GQLItem>(GQL_GET_ITEM_BY_SHARE_TOKEN, {
    pickFirstKey: true,
    variables: {
      shareToken,
    },
    showErrorToast: true,
  })
  const sizeCls = { 'modal-tcf': true }

  return (
    <Modal
      fade={false}
      isOpen
      toggle={() => console.log(33, 'toggle')}
      className={classNames('modal-primary', 'modal-rect', sizeCls)}
    >
      {loading && <BlockingLoadBox show />}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <BlockingLoadBox show={!tcfItem} />
        {tcfItem && (
          <TCFDetailWrapper
            accessByShareToken
            tcfItem={tcfItem}
            onClose={() => console.log(41)}
            onTCFItemUpdated={() => 'onTCFItemUpdated'}
            hasNext={false}
            hasPrev={false}
            onClickNext={async () => null}
            onClickPrev={async () => null}
          />
        )}
      </div>
    </Modal>
  )
}
