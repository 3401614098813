import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AppConfigEntityCommon } from './AppConfigEntityCommon'
import { AppConfigListableTable } from './AppConfigListableTable'

export const AppConfigRoot: React.FC = () => {
  const match = useRouteMatch()
  console.log(8, match)
  return (
    <Switch>
      <Route exact path={`${match.url}/new`} render={() => <AppConfigEntityCommon itemId={null} />} />
      <Route
        exact
        path={`${match.url}/:id`}
        render={(props: any) => <AppConfigEntityCommon itemId={props.match.params.id} viewMode />}
      />
      <Route
        exact
        path={`${match.url}/:id/edit`}
        render={(props: any) => <AppConfigEntityCommon itemId={props.match.params.id} />}
      />
      <Route exact path="" render={() => <AppConfigListableTable />} />
    </Switch>
  )
}
