import { WorksetPermUtils } from '@cils/common/lib/permission/worksetPermUtils'
import React from 'react'
import { useODAppContext } from './ODAppContext'
import { useAPIs } from './useAPIs'
import { useSingleQueryAPI } from './useSingleQueryAPI'

export function useWorksetPerm(wsId: number): WorksetPermUtils | undefined {
  const {
    state: { userProfile },
  } = useODAppContext()

  const { getWorksetHasUser } = useAPIs()
  const { data } = useSingleQueryAPI(() => getWorksetHasUser({ firstId: wsId, secondId: userProfile!.userId }), [
    wsId,
    userProfile!.userId,
  ])
  const [permUtil, setPermUtil] = React.useState<WorksetPermUtils | undefined>()

  React.useEffect(() => {
    if (!data) {
      setPermUtil(undefined)
    } else {
      setPermUtil(new WorksetPermUtils(data))
    }
  }, [data])
  return permUtil
}
