import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { GQLOrg } from '../../@types/server'
import { useAPIs } from '../../context/useAPIs'
import { useSingleQueryAPI } from '../../context/useSingleQueryAPI'
import { CILSLoading } from './CILSLoading'

const CustomSelect = styled(Select)`
  width: 300px;
`

const SignupCustomSelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
`

export enum OrgSelectTheme {
  SignUp,
  UserEdit,
}

interface IOrgSelectProps {
  selected: number | null
  onChange: (org: GQLOrg) => any
  theme: OrgSelectTheme
}

interface IOrgOption {
  org: GQLOrg
  value: number
  label: string
}

const m = (org: GQLOrg): IOrgOption => ({
  org,
  value: org.orgId,
  label: org.name ?? '-',
})

export const OrgSelect: React.FC<IOrgSelectProps> = props => {
  const { selected, onChange, theme } = props
  const isSignup = theme === OrgSelectTheme.SignUp
  const { listOrg } = useAPIs()
  const { data } = useSingleQueryAPI(() => listOrg({ filter: null, page: 1, pageSize: 100 }), [])
  const options = data?.list.map(m) ?? []
  const value = options.find(v => v.org.orgId === selected) ?? null

  return (
    <div>
      {!data && <CILSLoading />}
      {data && isSignup && (
        <SignupCustomSelect
          name="orgUserPriv"
          options={options}
          value={value}
          placeholder="Select organization"
          onChange={(e: IOrgOption) => {
            onChange(e.org)
          }}
        />
      )}
      {data && !isSignup && (
        <CustomSelect
          name="orgUserPriv"
          options={options}
          value={value}
          onChange={(e: IOrgOption) => {
            onChange(e.org)
          }}
        />
      )}
    </div>
  )
}
