import { GQLCalculateItemsSizeInput, GQLCalculateItemsSizeOutput } from '../@types/server'
import { useODQuery } from '../context/ODCommon'

export const GQL_CALCULATE_ITEMS_SIZE = `
query calculateItemsSize($data: CalculateItemsSizeInput!) {
  calculateItemsSize(data: $data) {
    totalSize
  }
}`

export function useMainServerAPI() {
  const apiCalculateItemsSize = useODQuery<GQLCalculateItemsSizeInput, GQLCalculateItemsSizeOutput>(
    GQL_CALCULATE_ITEMS_SIZE
  )

  return {
    apiCalculateItemsSize,
  }
}
