import { ODListableOption, ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLProjectHasUser } from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'

export interface IProjectHasUserListableOption extends ODListableOption {
  filter: string
  projectId: number
}

export function useProjectHasUserListDataLoader(projectId: number) {
  const { listProjectHasUser } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectHasUserListableOption
    ): Promise<ODListableResponseType<GQLProjectHasUser>> {
      const r = await listProjectHasUser({
        page,
        pageSize,
        filter: options.filter,
        projectId,
      })
      return r as ODListableResponseType<GQLProjectHasUser>
    },
    [listProjectHasUser, projectId]
  )
}
