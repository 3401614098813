import { range } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLTag } from '../../@types/server'
import { TagDataLoaderOption } from '../../containers/User/TagContainer'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { ODListableContextType } from '../../ODListable/ODListableContext'
import { SiteUrls } from '../../urls'
import { BlockingLoadBox } from '../BlockingLoadBox'
import { NoImageDiv } from '../NoImageDiv'
import { ODIcon, ODIcons } from '../ODIcon'

type ODImageCardContentsProps = {
  title: string
  numItems: number
}

type ODImageCardProps = {
  size?: number
  space?: number
  listableContext: React.Context<ODListableContextType<GQLTag, TagDataLoaderOption>>
}

type BoxOfBoxesProps = {
  size: number
  space: number
}

type BoxProps = {
  size: number
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 269px;
  width: 286px;
  border-radius: 3px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  padding: 9px;
  margin-right: 14px;
  cursor: pointer;
  margin: 10px;
`

const ImageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const cols = 3
const rows = 2

const BoxOfBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${(p: BoxOfBoxesProps) => p.size * cols + p.space * cols}px;
  height: ${(p: BoxOfBoxesProps) => p.size * rows + p.space * rows}px;
  margin: 0;
  padding: 0;

  div:not(last-child) {
    margin-right: ${(p: BoxOfBoxesProps) => p.space}px;
  }
  img:not(last-child) {
    margin-right: ${(p: BoxOfBoxesProps) => p.space}px;
  }
`

const EmptyImageBox = styled.div`
  width: ${(p: BoxProps) => p.size}px;
  height: ${(p: BoxProps) => p.size}px;
  background-color: #ececec;
`

const ImageInBox = styled.img`
  width: ${(p: BoxProps) => p.size}px;
  height: ${(p: BoxProps) => p.size}px;
  background-color: #ececec;
`

const TagTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #2e343a;
`

const TagItems = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #73818f;
  margin-top: 4px;
`

export const ODImageCard: React.FC<ODImageCardProps> = props => {
  const { size = 88, space = 2, listableContext } = props
  const boxProps = { size, space }

  const { state } = React.useContext(listableContext)
  const { orgId } = useODSingleOrgContext()
  const { loading, list } = state

  const count = 6

  return (
    <>
      {loading && <BlockingLoadBox show />}
      {list.map((v, i) => {
        return (
          <Link key={i} to={SiteUrls.User.Org.Tag.ItemList(v.tagId)(orgId)} style={{ textDecoration: 'none' }}>
            <Wrapper>
              <ImageBox>
                <BoxOfBoxes {...boxProps}>
                  {v.mainItems.length === 0 && <NoImageDiv />}
                  {v.mainItems.length > 0 &&
                    range(0, count).map(i => {
                      const link = v.mainItems[i]?.imageFile?.link
                      if (link) {
                        return <ImageInBox key={i} size={size} width={size} height={size} src={link} />
                      }
                      return <EmptyImageBox key={i} size={size} />
                    })}
                </BoxOfBoxes>
              </ImageBox>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flex: 1,
                  width: '100%',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 7 }}>
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 60,
                      backgroundColor: '#29b6ca',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ODIcon icon={ODIcons.CoreTag} style={{ fontSize: 14, color: '#ffffff' }} />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 14 }}>
                  <TagTitle>{v.name}</TagTitle>
                  <TagItems>
                    {v.num} item{v.num > 0 ? 's' : ''}
                  </TagItems>
                </div>
              </div>
            </Wrapper>
          </Link>
        )
      })}
    </>
  )
}
