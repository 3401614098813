import { ORG_USER_PRIV, ORG_USER_PRIV_TO_STRING } from '@cils/common'
import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const CustomSelect = styled(Select)`
  width: 300px;
`

interface IOrgUserPrivSelectProps {
  selected: ORG_USER_PRIV
  onChange: (priv: ORG_USER_PRIV) => any
}

interface IUserPrivOption {
  userPriv: ORG_USER_PRIV
  value: string
  label: string
}

const m = (priv: ORG_USER_PRIV): IUserPrivOption => ({
  userPriv: priv,
  value: priv.toString(),
  label: ORG_USER_PRIV_TO_STRING[priv],
})

const OPTIONS: IUserPrivOption[] = [
  m(ORG_USER_PRIV.Pending),
  m(ORG_USER_PRIV.Rejected),
  m(ORG_USER_PRIV.Guest),
  m(ORG_USER_PRIV.Normal),
  m(ORG_USER_PRIV.DataAdmin),
  m(ORG_USER_PRIV.SystemAdmin),
]

export const OrgUserPrivSelect: React.FC<IOrgUserPrivSelectProps> = props => {
  const { selected, onChange } = props
  const value: IUserPrivOption = OPTIONS.find(o => o.userPriv === selected)!

  return (
    <div>
      <CustomSelect
        name="orgUserPriv"
        options={OPTIONS}
        value={value}
        onChange={(e: IUserPrivOption) => {
          onChange(e.userPriv)
        }}
      />
    </div>
  )
}
