import { ImageTagType } from '@cils/common'
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import { useDoubleClick } from '../../utils/useDoubleClick'
import { NoImageDiv } from '../NoImageDiv'
import { ImageTag } from './ImageTags'

export type GridItemProps = {
  link: string | null
  size: number
  selected?: boolean
  spacing?: number
  imageTagTypes: Array<ImageTagType>
  isHidden?: number
  itemId?: number
  dataId: string
  title: string
  shortName?: string | null
}

type CustomTooltipStyleProps = {
  x: number
  y: number
}

const GridItemImage = styled.div`
  width: ${(p: GridItemProps) => p.size}px;
  height: ${(p: GridItemProps) => p.size}px;
  border: ${(p: GridItemProps) => (p.selected ? 'solid 8px #29b6ca' : 0)};
  margin: ${(p: GridItemProps) => p.spacing || 12}px;
  background-image: ${(p: GridItemProps) =>
    p.isHidden === 1
      ? `linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0.5) 100%), url(${p.link})`
      : `url(${p.link})`};
  background-size: contain;
  user-select: none;
`

const NoImageGridWrapper = styled.div`
  width: ${(p: GridItemProps) => p.size}px;
  height: ${(p: GridItemProps) => p.size}px;
  border: ${(p: GridItemProps) => (p.selected ? 'solid 8px #29b6ca' : 0)};
  margin: ${(p: GridItemProps) => p.spacing || 12}px;
  user-select: none;
`

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: ${(p: GridItemProps) => p.spacing || 12}px;
  pointer-events: none;
  //background-color: #061c3f66;
`

const Tags = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`

const CustomTooltip = styled(UncontrolledTooltip)`
  & > .tooltip {
    top: ${(p: CustomTooltipStyleProps) => p.y - 50}px!important;
    left: ${(p: CustomTooltipStyleProps) => p.x - 40}px!important;
    transform: none !important;
    font-size: 11px;
    letter-spacing: 0.17px;
    color: #ffffff;
    // https://stackoverflow.com/a/48665379/388351
    pointer-events: none;
  }

  & > .tooltip > .tooltip-inner {
    border: 1px solid #ffffff;
  }

  & > .tooltip > .arrow {
    width: 8px !important;
    height: 8px !important;
    bottom: 3px;
    border: 1px solid #ffffff;
    transform: rotate(-135deg);
  }

  & > .tooltip > .arrow::before {
    width: 8px;
    height: 8px;
    background: #000000;
    border: 0;
  }
`

export const GridItem: React.FC<GridItemProps & { onCheckClick?: () => void; onClick: () => void }> = props => {
  const { itemId, onClick, onCheckClick, shortName, ...imageProps } = props
  const { link, imageTagTypes, isHidden, dataId, title } = imageProps

  const itemRef = React.useRef<HTMLDivElement>(null)

  const [x, setX] = React.useState<number>(0)
  const [y, setY] = React.useState<number>(0)

  useDoubleClick({
    onClick: () => {
      onCheckClick?.()
    },
    onDoubleClick: () => {
      onClick()
    },
    ref: itemRef,
    interval: 400,
  })

  const onMouseMove: React.MouseEventHandler<HTMLDivElement> = e => {
    setX(e.pageX)
    setY(e.pageY)
  }

  const titleWithoutTimeStamp = ((t: string, fallback: string) => {
    try {
      return t.split('.').slice(3)
    } catch (ex) {
      return t || fallback
    }
  })(title, dataId)

  return (
    <div
      ref={itemRef}
      style={{ position: 'relative', cursor: 'pointer', zIndex: 1 }}
      {...{ onMouseMove }}
      id={'tooltip_' + itemId!!.toString()}
    >
      {link && <GridItemImage {...imageProps} />}
      {!link && (
        <NoImageGridWrapper {...imageProps}>
          <NoImageDiv style={{ opacity: isHidden === 1 ? 0.5 : 1.0 }} />
        </NoImageGridWrapper>
      )}
      <CustomTooltip x={x} y={y} target={'tooltip_' + itemId!!.toString()} placement="top" delay={{ show: 0, hide: 0 }}>
        <div>{titleWithoutTimeStamp}</div>
        <div>{shortName ? `shortName: ${shortName}` : ''}</div>
      </CustomTooltip>
      <ImageOverlay {...imageProps}>
        <Tags>
          {imageTagTypes.map((tag, index) => (
            <ImageTag tag={tag} key={index} />
          ))}
        </Tags>
      </ImageOverlay>
    </div>
  )
}
