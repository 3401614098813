import React from 'react'
import './DirectoryCheckbox.css'

interface IDirectoryCheckboxProps {
  isChecked: boolean
  onClick: () => void
}

// https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
export const DirectoryCheckbox: React.FC<IDirectoryCheckboxProps> = props => {
  const { isChecked, onClick } = props

  return (
    <label className="dc-container">
      <input type="checkbox" checked={isChecked} onClick={onClick} />
      <span className="dc-checkmark intermediate" />
    </label>
  )
}
