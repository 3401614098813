import { populateAgentPropsInput, UserGroupPropsInputValidation } from '@cils/common'
import { difference } from 'lodash'
import * as React from 'react'
import * as Yup from 'yup'
import {
  GQLOkResponse,
  GQLSingleIDInput,
  GQLUser,
  GQLUserGroup,
  GQLUserGroupHasUserPropsInput,
  GQLUserGroupPropsInput,
} from '../../../@types/server'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { USER_GROUP_FULL_SNAPSHOT } from '../../../gqls'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'
import { GroupUserPicker } from './GroupUserPicker'

type OrgUserGroupEditContainerProps = {
  orgId: number
  idEditing?: number
}

const GQL_CREATE = `
mutation createUserGroup($data: UserGroupPropsInput!) {
  createUserGroup(data: $data) {
    ${USER_GROUP_FULL_SNAPSHOT}
  }
}
`

const GQL_UPDATE = `
mutation updateUserGroup($data: UserGroupPropsInput!) {
  updateUserGroup(data: $data) {
    ${USER_GROUP_FULL_SNAPSHOT}
  }
}
`

const GQL_GET = `
query getUserGroup($data: SingleIDInput!) {
  getUserGroup(data: $data) {
    ${USER_GROUP_FULL_SNAPSHOT}
  }
}
`

const GQL_REMOVE = `
mutation removeUserGroup($data: SingleIDInput!) {
  removeUserGroup(data: $data) {
    ok
  }
}
`

const GQL_CREATE_USER_GROUP_HAS_USER = `
mutation createUserGroupHasUser($data: UserGroupHasUserPropsInput!) {
  createUserGroupHasUser(data: $data) {
    ok
  }
}
`

const GQL_REMOVE_USER_GROUP_HAS_USER = `
mutation removeUserGroupHasUser($data: UserGroupHasUserPropsInput!) {
  removeUserGroupHasUser(data: $data) {
    ok
  }
}
`

type PropsInput = Partial<GQLUserGroupPropsInput>
type PropsHasUserInput = Partial<GQLUserGroupHasUserPropsInput>
type Entity = GQLUserGroup

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: UserGroupPropsInputValidation.name.required('Name is required.'),
  })
}

export const OrgUserGroupEditContainer: React.FC<OrgUserGroupEditContainerProps> = props => {
  const { idEditing, orgId } = props

  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_CREATE)
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_UPDATE)
  const apiCreateUserGroupHasUser = useODMutation<Partial<PropsHasUserInput>, Partial<GQLOkResponse>>(
    GQL_CREATE_USER_GROUP_HAS_USER
  )
  const apiRemoveUserGroupHasUser = useODMutation<Partial<PropsHasUserInput>, Partial<GQLOkResponse>>(
    GQL_REMOVE_USER_GROUP_HAS_USER
  )
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)
  const members = React.useRef<Array<GQLUser>>([])
  const origMembers = React.useRef<Array<GQLUser>>([])
  const [isUserPickerOpen, setIsUserPickerOpen] = React.useState(false)

  const setMembers = React.useCallback((users: Array<GQLUser>) => {
    members.current.length = 0
    users.forEach(u => members.current.push(u))
  }, [])
  const setOrigMembers = React.useCallback((users: Array<GQLUser>) => {
    origMembers.current.length = 0
    users.forEach(u => origMembers.current.push(u))
  }, [])

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          setOrigMembers([])
          setMembers([])

          return {
            id: null,
            name: '',
          }
        }

        if (data.members) {
          setOrigMembers(data.members!)
          setMembers(data.members!)
        }

        return {
          id: data.groupId,
          name: data.name,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (idEditing) {
          const removedItem = difference(origMembers.current, members.current)
          const addedItem = difference(members.current, origMembers.current)

          if (data.name) {
            await apiUpdate({
              id: idEditing,
              ...data,
            })
          }
          if (removedItem.length > 0) {
            await apiRemoveUserGroupHasUser({
              groupId: idEditing,
              userIds: removedItem.map(member => member.userId),
            })
          }
          if (addedItem.length > 0) {
            await apiCreateUserGroupHasUser({
              groupId: idEditing,
              userIds: addedItem.map(member => member.userId),
            })
          }
          Utils.showSuccess('Updated user group', 'Success')
        } else {
          const r = await apiCreate({
            name: data.name,
            orgId,
          })
          await apiCreateUserGroupHasUser({
            groupId: r.groupId,
            userIds: members.current.map(member => member.userId),
          })
          Utils.showSuccess('Added user group.', 'Success')
        }
        return SiteUrls.OrgAdmin.UserGroup.List(orgId)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateAgentPropsInput,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('Removed user group.', 'Success')
        }
        return SiteUrls.OrgAdmin.UserGroup.List(orgId)
      },
    }),
    [
      apiRemove,
      apiCreateUserGroupHasUser,
      apiRemoveUserGroupHasUser,
      setMembers,
      setOrigMembers,
      idEditing,
      apiCreate,
      apiGet,
      apiUpdate,
      orgId,
    ]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New Group' : 'Edit Group'

  return (
    <Provider title={title}>
      <ODEntityInput
        name="name"
        label="Group Name"
        placeholder="Human-friendly name for this user group."
        inputType="text"
      />
      <GroupUserPicker
        orgId={orgId}
        members={members.current}
        setMembers={setMembers}
        isPickerOpen={isUserPickerOpen}
        setIsPickerOpen={setIsUserPickerOpen}
      />
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
        deleteConfirmOptions={{
          message: (
            <>
              Are you sure to delete?
              <br />
              If this group is added to the workset, it cannot be deleted.
              <br /> This cannot be undone.
            </>
          ),
          yes: 'Confirm',
          no: 'Cancel',
        }}
      />
    </Provider>
  )
}
