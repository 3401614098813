import { PROJECT_USER_PERM } from '@cils/common'
import { ODListableResponseType, ODModalSize } from '@odc/od-react-belt'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { GQLProject } from '../../../@types/server'
import { useAPIs } from '../../../context/useAPIs'
import { ODColors } from '../../../global-styles'
import { ODListableOption } from '../../../ODListable/ODListableContext'
import { useWorksetPickerTableModal } from '../WorksetGroup/useWorksetPickerTableModal'
import { IProjectListableOption } from './ProjectCommon'

export interface IProjectSelectListableOption extends ODListableOption {
  filter: string
}

const THeads = ['Project name', 'Project Type', 'Items', 'Owner', 'Project Created']

export function useProjectSelectModal(handleSelect: (projectId: number, projectName: string) => Promise<void>) {
  const { listProject } = useAPIs()

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectListableOption
    ): Promise<ODListableResponseType<GQLProject>> {
      const r = await listProject({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLProject>
    },
    [listProject]
  )

  return useWorksetPickerTableModal<GQLProject, IProjectListableOption>({
    size: ODModalSize.XLarge,
    pageSpan: 10,
    rounded: false,
    pickDefaultOptions: {},
    dataLoader: dataLoader,
    keyExtractor: v => v.projectId,
    numColumns: 7,
    removePick: true,
    removeConfirmButton: true,
    renderSelectCol: (v: GQLProject, onClose: () => void) => {
      // 토스트 팝업... 구현 해야 하는데 오류가 생겨서 추후 다시 하자
      return (
        <td>
          <SelectSpan
            style={{ color: v.myPerm >= PROJECT_USER_PERM.Admin ? ODColors.Primary : ODColors.Silver }}
            onClick={async () => {
              if (v.myPerm >= PROJECT_USER_PERM.Admin) {
                await handleSelect(v.projectId, v.name)
                onClose()
              }
            }}
          >
            Select
          </SelectSpan>
        </td>
      )
    },
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, v: GQLProject) => {
      switch (index) {
        case 0:
          return v.name ?? '-'
        case 1:
          return v.type ?? '-'
        case 2:
          return v.numItems ?? 0
        case 3:
          const owner = v.owner
          return <div>{owner?.name || '-'}</div>
        case 4:
          return v.createdAt ? moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'
      }
    },
  })
}

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 14px;
  color: ${ODColors.Primary};
`
