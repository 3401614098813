import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../ODIcon'
import { ODModal } from './ODModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #c8ced3;
  background-color: #ffffff;
`
const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #73818f;
`
const Key = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2f353a;
  width: 72px;
`
const Button = styled.div`
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const ButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`

type ODModalCreateShareLinkProps = {
  onClose: () => void
  onCreateShareLink: (canDownload: boolean, expireAfterDays: number) => void
}

export const ODModalCreateShareLink: React.FC<ODModalCreateShareLinkProps> = props => {
  const { onClose, onCreateShareLink } = props

  const [canDownload, setCanDownload] = React.useState<boolean>(false)
  const [expireAfterDays, setExpireAfterDays] = React.useState<number>(7)

  return (
    <ODModal isOpen toggle={() => null}>
      <Wrapper>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 15 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 12,
            }}
          >
            <Title>Share Link</Title>
            <ODIcon
              icon={ODIcons.CoreX}
              style={{ color: '#bdbdbd', fontSize: 18, cursor: 'pointer' }}
              onClick={onClose}
            />
          </div>
          <KeyValueWrapper>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 32 }}>
              <Key>Permissions</Key>
            </div>
            <Input
              type="select"
              name="permissions"
              onChange={e => setCanDownload(JSON.parse(e.target.value))}
              style={{ width: '100%' }}
              value={JSON.stringify(canDownload)}
            >
              <option value="false">Can view only</option>
              <option value="true">Can view and download</option>
            </Input>
          </KeyValueWrapper>
          <KeyValueWrapper style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 32 }}>
              <Key>Expiration</Key>
            </div>
            <Input
              type="select"
              name="expireAfterDays"
              onChange={e => setExpireAfterDays(parseInt(e.target.value, 10))}
              style={{ width: '100%' }}
              value={expireAfterDays}
            >
              <option value={7}>7 days</option>
              <option value={15}>15 days</option>
              <option value={30}>30 days</option>
            </Input>
          </KeyValueWrapper>
          <Button style={{ marginTop: 20 }} onClick={() => onCreateShareLink(canDownload, expireAfterDays)}>
            <ButtonText>Create Share Link</ButtonText>
          </Button>
        </div>
      </Wrapper>
    </ODModal>
  )
}
