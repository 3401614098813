/**
 * Formik Context 를 이용하여 reactstrap Form 컴포넌트의 onSubmit 을 연동하는 컴포넌트
 */
import { useFormikContext } from 'formik'
import React from 'react'
import { Form, FormProps } from 'reactstrap'

export const FormikReactstrapForm: React.FC<FormProps> = props => {
  const { children } = props
  const { handleSubmit } = useFormikContext()

  return (
    // @ts-ignore
    <Form onSubmit={handleSubmit} {...props}>
      {children}
    </Form>
  )
}
