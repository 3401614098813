import React from 'react'
import styled from 'styled-components'

type ODImageGridDateSeparatorProps = {
  name: string
}

const Wrapper = styled.div`
  width: 100%;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #2f353a;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
`

// const CustomInput = styled.input`
//   width: 12px;
//   height: 12px;
//   border-radius: 3px;
//   border: solid 1px #c8ced3;
// `

export const ODImageGridDateSeparator: React.FC<ODImageGridDateSeparatorProps> = props => {
  const { name } = props
  return <Wrapper>{name}</Wrapper>
}
