import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { METADATA_EDIT_TYPE } from '../containers/User/DetailContainer'
import { ODIcon, ODIcons } from './ODIcon'
import { UseConfirmModalOptions, useModalConfirm } from './ODModal/ODModalConfirm'

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 28px;
`
export const KeyWrapper = styled.div`
  width: 52px;
  margin-right: 69px;
`
export const Key = styled.span`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #2f353a;
`
const Value = styled.span`
  min-height: 28px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.24px;
  color: #73818f;
  max-width: 417px;
  word-wrap: break-word;
`
const InputBox = styled.input`
  width: 424px;
  height: 28px;
  border-radius: 3px;
  border: solid 1px #29b6ca;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`

const TagBox = styled.div`
  height: 19px;
  border-radius: 4px;
  background-color: #20a8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  margin-right: 4px;
`
const TagBoxValue = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`
const PlaceholderText = styled.span`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  color: #29b6ca;
`
const TextAreaBox = styled.textarea`
  display: flex;
  width: 424px;
  height: 71px;
  border-radius: 3px;
  border: solid 1px #29b6ca;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: 0.24px;
  color: #73818f;
  resize: none;
`

type Props = {
  title: string
  initialValue: string
  onChange: (value: string) => Promise<any>
  type: string
  editable: boolean
}

const deleteTagOptions: UseConfirmModalOptions = {
  message: <>Are you sure you want to delete Tag?</>,
  yes: 'Delete',
  no: 'Cancel',
}

export const EditValueComponent: React.FC<Props> = props => {
  const { title, type, initialValue, onChange, editable } = props

  const [isDisabled, setIsDisabled] = useState(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(initialValue)
  const inputFocus = useRef<HTMLInputElement & HTMLTextAreaElement>(null)

  const { Component: DeleteTag, confirm: ConfirmTag, props: deleteTagProps } = useModalConfirm(deleteTagOptions)

  const handleSave = React.useCallback(
    async (value: string) => {
      setIsEdit(false)
      setIsDisabled(true)
      try {
        await onChange(value)
      } catch (ex) {
        console.error(ex)
      } finally {
        setIsDisabled(false)
      }
    },
    [onChange]
  )

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isEdit) {
      inputFocus.current && inputFocus.current.focus()
    }
  }, [isEdit, inputFocus])

  const onRemoveCategoryTag = async (i: number, type: string) => {
    if (!editable) {
      return
    }

    const remove = () => {
      const arr = inputValue.length > 1 && inputValue.split(',')
      arr && arr.splice(i, 1)
      setInputValue(arr.toString())
      handleSave(arr.toString())
    }

    if (type === METADATA_EDIT_TYPE.TAG) {
      if (await ConfirmTag()) {
        remove()
        return
      }
    }
  }

  const checkType = (type: string) => {
    if (type === METADATA_EDIT_TYPE.TITLE) {
      return <Value style={{ fontWeight: 'bold' }}>{inputValue}</Value>
    }

    if (type === METADATA_EDIT_TYPE.EXPLANATION) {
      return <Value style={{ fontWeight: 'normal', fontSize: 12, whiteSpace: 'pre-wrap' }}>{inputValue}</Value>
    }

    if (type === METADATA_EDIT_TYPE.TAG) {
      const arr = inputValue.length > 1 && inputValue.split(',')
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            maxWidth: 417,
            flexWrap: 'wrap',
          }}
        >
          {arr &&
            arr.map((v, i) => {
              return (
                <TagBox key={i}>
                  {editable ? (
                    <ODIcon
                      icon={ODIcons.CoreX}
                      style={{ marginRight: 8, fontSize: 10, color: '#ffffff', cursor: 'pointer' }}
                      onClick={() => onRemoveCategoryTag(i, METADATA_EDIT_TYPE.TAG)}
                    />
                  ) : null}
                  <TagBoxValue>{v}</TagBoxValue>
                </TagBox>
              )
            })}
        </div>
      )
    }
  }

  const editIcon = (() => {
    if (!editable) {
      return null
    }

    if (isEdit) {
      return (
        <ODIcon
          icon={ODIcons.CoreSave}
          style={{ color: '#73818f', cursor: isDisabled ? 'none' : 'pointer' }}
          onClick={() => !isDisabled && handleSave(inputValue)}
        />
      )
    }

    return (
      <ODIcon
        icon={ODIcons.CorePencil}
        style={{ color: '#73818f', cursor: isDisabled ? 'none' : 'pointer' }}
        onClick={() => !isDisabled && setIsEdit(true)}
      />
    )
  })()

  return (
    <>
      <DeleteTag {...deleteTagProps} />
      <RowWrapper
        style={{
          minHeight: type === METADATA_EDIT_TYPE.EXPLANATION ? 71 : 28,
          alignItems: type === METADATA_EDIT_TYPE.EXPLANATION ? 'flex-start' : 'center',
        }}
      >
        <KeyWrapper>
          <Key>{title}</Key>
        </KeyWrapper>
        {isEdit ? (
          <>
            {type === METADATA_EDIT_TYPE.EXPLANATION ? (
              <TextAreaBox
                ref={inputFocus}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onKeyDown={e => {
                  if ((e.ctrlKey && e.key === 'Enter') || (e.metaKey && e.key === 'Enter')) {
                    setInputValue(e.currentTarget.value)
                    handleSave(e.currentTarget.value)
                  }
                }}
              />
            ) : (
              <div style={{ position: 'relative' }}>
                <InputBox
                  ref={inputFocus}
                  type="text"
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || (e.ctrlKey && e.key === 'Enter') || (e.metaKey && e.key === 'Enter')) {
                      setInputValue(e.currentTarget.value)
                      handleSave(e.currentTarget.value)
                    }
                  }}
                  placeholder={type === METADATA_EDIT_TYPE.CATEGORY ? 'Enter comma-separated values' : ''}
                />
                {type === METADATA_EDIT_TYPE.TAG ? (
                  <PlaceholderText style={{ position: 'absolute', bottom: 6, right: 9 }}>
                    Enter comma-separated values
                  </PlaceholderText>
                ) : null}
              </div>
            )}
          </>
        ) : (
          checkType(type)
        )}
      </RowWrapper>
      {editIcon}
    </>
  )
}
