import React from 'react'
import { CategoryContainer } from '../containers/User/CategoryContainer'
import { DirectoryViewContainer } from '../containers/User/DirectoryView/DirectoryViewContainer'
import { LocalItemsContainer } from '../containers/User/LocalItemsContainer'
import { ProjectDataDetailPage } from '../containers/User/Project/detail/ProjectDataDetailPage'
import { ProjectDetailPage } from '../containers/User/Project/detail/ProjectDetailPage'
import { ProjectListPage } from '../containers/User/Project/list/ProjectListPage'
import { SyncLiveLogTable } from '../containers/User/Sync/SyncLiveLogTable'
import { SyncRootContainer } from '../containers/User/Sync/SyncRootContainer'
import { TagContainer } from '../containers/User/TagContainer'
import { AllItemsContainer } from '../containers/User/TCFItems/AllItemsContainer'
import { AllOrgItemsContainer } from '../containers/User/TCFItems/AllOrgItemsContainer'
import { CategoryItemsContainer } from '../containers/User/TCFItems/CategoryItemsContainer'
import { FavoriteItemsContainer } from '../containers/User/TCFItems/FavoriteItemsContainer'
import { SingleTCFViewContainer } from '../containers/User/TCFItems/SingleTCFViewContainer'
import { TaggedItemsContainer } from '../containers/User/TCFItems/TaggedItemsContainer'
import { WorksetItemsContainer } from '../containers/User/TCFItems/WorksetItemsContainer'
import { WorksetContainer } from '../containers/User/Workset/WorksetContainer'
import { WorksetGroupDetailContainer } from '../containers/User/WorksetGroup/detail/WorksetGroupDetailContainer'
import { WorksetGroupListPage } from '../containers/User/WorksetGroup/list/WorksetGroupListPage'
import { ODRoute } from '../context/ODCommon'
import { Strings } from '../i18n/res'
import { SiteUrls } from '../urls'

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const UserRoutesOfOrg = (orgId: string, isAdmin: boolean): Array<ODRoute> =>
  [
    {
      path: SiteUrls.User.Org.Item.SingleTCF(':itemId')(orgId),
      exact: true,
      name: Strings.TCF,
      render: (props: any) => {
        return <SingleTCFViewContainer itemId={props.match.params.itemId} snapshotId={props.match.params.snapshotId} />
      },
    },
    { path: SiteUrls.User.Org.Item.All(orgId), exact: true, name: Strings.ItemsAll, component: AllItemsContainer },
    {
      path: SiteUrls.User.Org.Item.AllInOrg(orgId),
      exact: true,
      name: Strings.ItemsAll,
      component: AllOrgItemsContainer,
    },
    { path: SiteUrls.User.Org.Item.Local(orgId), exact: true, name: Strings.Local, component: LocalItemsContainer },
    {
      path: SiteUrls.User.Org.Workset.Favorites(orgId),
      exact: true,
      name: 'Workset Favorites',
      render: () => <WorksetContainer showInOrg favoriteOnly />,
    },
    { path: SiteUrls.User.Org.Workset.Main(orgId), exact: true, name: Strings.Workset, component: WorksetContainer },
    {
      path: SiteUrls.User.Org.Workset.Org(orgId),
      exact: true,
      name: 'All Worksets',
      render: () => <WorksetContainer showInOrg />,
    },
    {
      path: SiteUrls.User.Org.Workset.Detail(':wsId')(orgId),
      exact: true,
      name: '',
      render: (props: any) => <WorksetItemsContainer wsId={parseInt(props.match.params.wsId, 10)} />,
    },
    { path: SiteUrls.User.Org.Tag.Main(orgId), exact: true, name: Strings.Tag, component: TagContainer },
    {
      path: SiteUrls.User.Org.Tag.ItemList(':tagId')(orgId),
      exact: true,
      name: '',
      render: (props: any) => <TaggedItemsContainer tagId={parseInt(props.match.params.tagId, 10)} />,
    },
    { path: SiteUrls.User.Org.Category.Main(orgId), exact: true, name: Strings.Category, component: CategoryContainer },
    {
      path: SiteUrls.User.Org.Category.ItemList(':catId')(orgId),
      exact: true,
      name: Strings.Category,
      render: (props: any) => <CategoryItemsContainer categoryId={parseInt(props.match.params.catId, 10)} />,
    },
    {
      path: SiteUrls.User.Org.Favorite.Main(orgId),
      exact: true,
      name: Strings.Favorite,
      component: FavoriteItemsContainer,
    },
    {
      path: SiteUrls.User.Org.Project.Main(orgId),
      exact: true,
      name: Strings.Project,
      render: (props: any) => <ProjectListPage orgId={parseInt(props.match.params.orgId, 10)} />,
    },
    {
      path: SiteUrls.User.Org.Project.Org(orgId),
      exact: true,
      name: Strings.AllProjects,
      render: (props: any) => <ProjectListPage orgId={parseInt(props.match.params.orgId, 10)} showInOrg />,
    },
    {
      path: SiteUrls.User.Org.Project.Detail(':projectId')(orgId),
      exact: true,
      name: '',
      render: (props: any) => (
        <ProjectDetailPage
          orgId={parseInt(props.match.params.orgId, 10)}
          projectId={parseInt(props.match.params.projectId, 10)}
        />
      ),
    },
    {
      path: SiteUrls.User.Org.Project.DataDetail(':projectId', ':projectDataId', ':dataTitle')(orgId),
      exact: true,
      name: '',
      render: (props: any) => (
        <ProjectDataDetailPage
          orgId={parseInt(props.match.params.orgId, 10)}
          projectId={parseInt(props.match.params.projectId, 10)}
          dataId={parseInt(props.match.params.projectDataId, 10)}
          dataTitle={props.match.params.dataTitle}
        />
      ),
    },
    {
      path: SiteUrls.User.Org.WorksetGroup.main(orgId),
      exact: true,
      name: 'Workset Group',
      render: (props: any) => <WorksetGroupListPage orgId={parseInt(props.match.params.orgId, 10)} />,
    },
    {
      path: SiteUrls.User.Org.WorksetGroup.Detail(':wsgId')(orgId),
      exact: true,
      name: '', // "Group A"와 같은 그룹 명을 전달 받아 표시해야 함!
      render: (props: any) => (
        <WorksetGroupDetailContainer
          worksetGroupId={parseInt(props.match.params.wsgId, 10)}
          orgId={parseInt(props.match.params.orgId, 10)}
        />
      ),
    },
    {
      path: SiteUrls.User.Org.Sync.Live(orgId),
      exact: true,
      name: 'Agent live log (debug)',
      component: SyncLiveLogTable,
    },
    {
      path: SiteUrls.User.Org.Sync.Main(orgId),
      exact: true,
      name: Strings.Sync,
      component: SyncRootContainer,
    },
    {
      path: SiteUrls.User.Org.DirectoryView.Main(orgId),
      exact: true,
      name: Strings.DirectoryView,
      component: DirectoryViewContainer,
    },
  ].filter(v => v) as Array<ODRoute>
