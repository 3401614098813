import { GQLAttachmentType, GQLItemAttachment } from '../../../@types/server'

export type ThumbnailInfo = {
  id: string
  isAttachment: boolean // false if main thumbnail (not an attachment)
  link: string
  fileName?: string // required if isAttachment === true
  isMain?: boolean // required if isAttachment === true
  description?: string // required if isAttachment === true
  attachmentType?: GQLAttachmentType // required if isAttachment === true
}

export const convertItemAttachmentToThumbnailInfo = (
  f: GQLItemAttachment,
  mainAttachmentId: number | null
): ThumbnailInfo => {
  return {
    id: `a_${f.fileId}`,
    isAttachment: true,
    link: f.link,
    fileName: f.fileName,
    isMain: f.fileId === mainAttachmentId,
    description: f.description,
    attachmentType: f.attachmentType,
  }
}
