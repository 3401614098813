import React, { useState } from 'react'
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import styled from 'styled-components'
import { useODSingleOrgContext } from '../context/ODSingleOrgContext'
import { GQLItemsListableContextType } from '../ODListable/ODListableContext'
import { useDetectOutsideClick } from '../utils/useDetectOutsideClick'
import { NoResultDropDown } from './NoResultDropDown'
import { ODIcon, ODIcons } from './ODIcon'
import { DROPDOWN_MENU, DropDownMenuProps } from './ODImageGrid/ODImageGridSearchToolBar'

export type DropButtonStyleProps = {
  disabled: boolean
}

const DropButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 14px;
  padding-right: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: ${(props: DropButtonStyleProps) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props: DropButtonStyleProps) => (props.disabled ? 0.6 : 1.0)};
  text-align: left;
`
const DropDown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`
const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 231px;
  min-height: 50px;
  max-height: 2400px;
  overflow-y: auto;
  border: solid 1px #c8ced3;
  z-index: 1001;
  flex-direction: column;
  padding: 8px;
`
const Label = styled.label`
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`
const SelectBox = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #29b6ca;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const SelectBoxText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #29b6ca;
`

type Props = {
  listableContext: React.Context<GQLItemsListableContextType>
  disabled: boolean
}

export const TagDropDownComponent: React.FC<Props & DropDownMenuProps> = props => {
  const { listableContext, isMenuOpen, setIsMenuOpen: _setIsMenuOpen, disabled } = props

  const [keyword, setKeyword] = useState<string>('')
  const menuRef = React.useRef<HTMLDivElement>(null)
  const isOpen = isMenuOpen === DROPDOWN_MENU.Tag

  const { state, updateLoadOption } = React.useContext(listableContext)

  const {
    state: { tags },
    updateTags,
  } = useODSingleOrgContext()

  const setIsMenuOpen = (v: DROPDOWN_MENU | null) => {
    // whenever menu is closed, update tags.
    if (v === null) {
      // noinspection JSIgnoredPromiseFromCall
      updateTags()
    }
    _setIsMenuOpen(v)
  }

  useDetectOutsideClick(() => setIsMenuOpen(null), menuRef, isOpen)

  const tagIds = Object.assign([], state.loadOption.tagIds || [])
  const selectedCount = tagIds && tagIds.length

  const setTagIdChecked = (tagId: number, checked: boolean) => {
    if (checked) {
      const index = tagIds.indexOf(tagId) === -1
      if (index) {
        tagIds.push(tagId)
      }
    } else {
      const index = tagIds.indexOf(tagId)
      if (index !== -1) {
        tagIds.splice(index, 1)
      }
    }

    updateLoadOption({ ...state.loadOption, tagIds })
  }

  const searchTags = tags.filter(item => item.name?.toLowerCase().includes(keyword.toLowerCase()))

  return (
    <DropDown ref={menuRef}>
      <DropButton
        disabled={disabled}
        onClick={() => !disabled && (isOpen ? setIsMenuOpen(null) : setIsMenuOpen(DROPDOWN_MENU.Tag))}
      >
        <span>{selectedCount === 0 ? 'Tag' : selectedCount + ' selected'}</span>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        <FormGroup>
          <InputGroup size="normal">
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ backgroundColor: 'transparent', borderRightColor: 'transparent' }}>
                <ODIcon icon={ODIcons.CoreSearch} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Tag"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
              style={{ borderLeftColor: 'transparent' }}
            />
          </InputGroup>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 12,
              flex: 1,
            }}
          >
            <SelectBox
              style={{ border: 'solid 1px #f86c6b' }}
              onClick={() => updateLoadOption({ ...state.loadOption, tagIds: [] })}
            >
              <SelectBoxText style={{ color: '#f86c6b' }}>Deselect All</SelectBoxText>
            </SelectBox>
          </div>
        </FormGroup>
        {searchTags.length === 0 ? (
          <NoResultDropDown />
        ) : (
          searchTags.map(item => {
            const isTagIdSelected = tagIds.indexOf(item.tagId) >= 0

            return (
              <Label key={item.tagId} style={{ marginBottom: 14 }}>
                <input
                  type="checkbox"
                  style={{ marginRight: 17 }}
                  onChange={e => setTagIdChecked(item.tagId, e.target.checked)}
                  checked={isTagIdSelected}
                />
                {item.name}
              </Label>
            )
          })
        )}
      </DropDownContent>
    </DropDown>
  )
}
