import { AGENT_SYNC_DIRS_UPDATED, TCFDirectoryInfo } from '@cils/common'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import { GQLMachine, GQLSingleIDInput, GQLSnapshotProgressInfo } from '../../../@types/server'
import { AppOptions } from '../../../AppOptions'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import {
  ODDropdownComponent,
  ODDropdownItem,
  renderDropdownItemNoFilter,
} from '../../../components/ODDropdownComponent'
import { ODSearchBox } from '../../../components/ODSearchBox'
import { useODAppContext } from '../../../context/ODAppContext'
import { useODQuery } from '../../../context/ODCommon'
import { MACHINE_FULL_SNAPSHOT } from '../../../gqls'
import {
  AgentDataLoaderOption,
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'
import { AutoReloadInput, AutoReloadLabel, AutoReloadWrapper } from './AgentListContainer'

type Props = {
  machineId: number
}

type DirInfo = TCFDirectoryInfo & {
  progress: GQLSnapshotProgressInfo | null
  status: DirStatus
  percentage: string
}

enum DirStatus {
  Unknown,
  Uploading,
  Completed,
}

const DirStatusToString = {
  [DirStatus.Unknown]: 'NA',
  [DirStatus.Uploading]: 'Uploading',
  [DirStatus.Completed]: 'Completed',
}

const Label = styled.label`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #73818f;
`

const { Provider, Context } = createODListableContext<DirInfo, AgentDataLoaderOption>()

const GQL_GET_MACHINE = `
query getMachine($data: SingleIDInput!) {
  getMachine(data: $data) {
    ${MACHINE_FULL_SNAPSHOT}
    dirInfo
    dirProgress {
      list {
        itemId
        snapshotId
        size
        uploaded
        orgId
      }
    }
  }
}
`

interface AgentDirDataLoaderOption extends ODListableOption {
  filter: string | null
}

const TableDefinition: ODListableTableDefinition<DirInfo, AgentDirDataLoaderOption> = [
  {
    id: 'path',
    title: 'Path',
    transform: v => v.path,
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'dataId',
    title: 'Data ID',
    transform: v => v.cilsInfo?.dataId || '-',
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'size',
    title: 'Size',
    transform: v => {
      const size = v.progress?.size
      if (size) {
        return prettyBytes(parseInt(size, 10))
      }
      return 'NA'
    },
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'progress',
    title: 'Progress',
    transform: v => {
      return <span>{v.percentage}</span>
    },
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'status',
    title: 'Status',
    transform: v => {
      return <span>{DirStatusToString[v.status]}</span>
    },
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'metaLink',
    title: 'Link',
    transform: v => {
      if (v.syncedItemId && v.progress) {
        const { siteAddress } = AppOptions
        const tcfPermalink = `${siteAddress}${SiteUrls.User.Org.Item.SingleTCF(v.syncedItemId)(v.progress.orgId)}`
        return (
          <a href={tcfPermalink} target="_blank" rel="noopener noreferrer">
            Show
          </a>
        )
      }
      return '-'
    },
    thClass: 'text-left',
    className: 'text-left user-td',
  },
]

type AGENT_WORKSPACE_STATUS_DROPDOWN_ITEM = ODDropdownItem & { value: DirStatus }

const StatusDropdownList: Array<AGENT_WORKSPACE_STATUS_DROPDOWN_ITEM> = [
  {
    id: -1,
    text: 'All',
    value: -1,
  },
  {
    id: 0,
    text: DirStatusToString[DirStatus.Unknown],
    value: DirStatus.Unknown,
  },
  {
    id: 1,
    text: DirStatusToString[DirStatus.Uploading],
    value: DirStatus.Uploading,
  },
  {
    id: 2,
    text: DirStatusToString[DirStatus.Completed],
    value: DirStatus.Completed,
  },
]

export const AgentDirContainer: React.FC<Props> = ({ machineId }) => {
  const apiGet = useODQuery<GQLSingleIDInput, GQLMachine>(GQL_GET_MACHINE)
  const [data, setData] = React.useState<GQLMachine>()
  const [autoReload, setAutoReload] = React.useState(false)
  const [updateToken, setUpdateToken] = React.useState(0)
  const [selectedAgentUploadStatus, setSelectedAgentUploadStatus] = React.useState<DirStatus>(-1)
  const [keyword, setKeyword] = React.useState<string>('')

  const { setCurrentMachineName } = useODAppContext()

  React.useEffect(() => {
    let handler: any = null
    if (autoReload) {
      handler = setInterval(() => {
        setUpdateToken(v => v + 1)
      }, 1000)
    }

    return () => clearInterval(handler)
  }, [autoReload, setUpdateToken])

  React.useEffect(() => {
    if (data) {
      setCurrentMachineName(data.machineName || '')
    }
    return () => setCurrentMachineName('')
  }, [setCurrentMachineName, data])

  const dataLoader = React.useCallback(
    async function MachineDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AgentDataLoaderOption
    ): Promise<ODListableResponseType<DirInfo>> {
      const r = await apiGet({ id: machineId })
      setData(r)

      if (!r.dirInfo) {
        return { list: [], page: 1, pageSize: 1, totalCount: 0 }
      }

      const progressByItemId = r.dirProgress.list.reduce<{ [itemId: number]: GQLSnapshotProgressInfo }>((acc, item) => {
        acc[item.itemId] = item
        return acc
      }, {})

      const info: AGENT_SYNC_DIRS_UPDATED = JSON.parse(r.dirInfo)
      const list = Object.values(info.dirs)
        .map(v => {
          const progress = v.syncedItemId ? progressByItemId[v.syncedItemId] : null
          const percentage = progress
            ? Math.min(100, Math.max(0, (parseInt(progress.uploaded, 10) * 100) / parseInt(progress.size, 10)))
            : null

          const ret: DirInfo = {
            ...v,
            progress,
            percentage: percentage === null ? '-' : `${percentage.toFixed(1)}%`,
            status: !progress ? DirStatus.Unknown : percentage === 100 ? DirStatus.Completed : DirStatus.Uploading,
          }
          return ret
        })
        .filter(v => {
          if (selectedAgentUploadStatus < 0) {
            return true
          }
          return v.status === selectedAgentUploadStatus
        })
        .filter(v => {
          const k = keyword.toLowerCase()
          if (keyword.length === 0) {
            return true
          }

          let ret = v.path.toLowerCase().indexOf(k) >= 0
          return ret || (v.cilsInfo?.dataId?.toLowerCase()?.indexOf(k) || -1) >= 0
        })

      return { list, page: 1, pageSize: list.length, totalCount: list.length }
    },
    [machineId, apiGet, selectedAgentUploadStatus, keyword]
  )

  const handleChangeStatus = (v: ODDropdownItem & { value: DirStatus }) => {
    setSelectedAgentUploadStatus(v.value)
  }

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Agent Workspace Status</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.path.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={updateToken.toString()}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ODSearchBox
                placeholder="Search by Path, Data ID"
                style={{ flexGrow: 6, maxWidth: 600 }}
                value={keyword}
                onChange={e => setKeyword(e)}
              />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <AutoReloadWrapper>
                  <AutoReloadLabel>
                    <AutoReloadInput
                      type="checkbox"
                      value="Auto reload"
                      checked={autoReload}
                      onChange={(e: any) => setAutoReload(e.target.checked)}
                    />
                    Auto reload
                  </AutoReloadLabel>
                </AutoReloadWrapper>
                <div
                  style={{
                    minWidth: 76,
                    paddingTop: 5,
                    marginRight: 20,
                  }}
                >
                  <Label htmlFor="select" style={{ fontWeight: 'bold' }}>
                    Filter
                  </Label>
                </div>
                <ODDropdownComponent
                  list={StatusDropdownList}
                  onChange={handleChangeStatus}
                  renderItem={renderDropdownItemNoFilter}
                  textNotSelected="Status"
                />
              </div>
              <div
                style={{
                  paddingTop: 20,
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
              >
                {/*<ActiveFilter listableContext={Context} />*/}
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
