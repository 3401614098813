import { atom } from 'recoil'

export enum ATOM_ID {
  WORKSET_DETAIL_STATE,
  GLOBAL_LOADING_BOX,
}

export type WORKSET_DETAIL_STATE = { wsId: number | null; perm: number | null; favorite: boolean | null }

/**
 * 워크셋 디테일 화면을 접근할 때 관련 정보를 업데이트 해 준다.
 */
export const worksetDetailState = atom<WORKSET_DETAIL_STATE>({
  key: ATOM_ID.WORKSET_DETAIL_STATE.toString(),
  default: {
    wsId: null,
    perm: null,
    favorite: null,
  },
})

export type GLOBAL_LOADING_STATE = { show: boolean; message: string | null }
/**
 * 글로벌에 존재하는 로딩화면을 제어하나.
 */
export const globalLoadingState = atom<GLOBAL_LOADING_STATE>({
  key: ATOM_ID.GLOBAL_LOADING_BOX.toString(),
  default: {
    show: false,
    message: null,
  },
})
