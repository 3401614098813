import { AppHeader, AppSidebar, AppSidebarFooter, AppSidebarMinimizer } from '@coreui/react'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ODAppSidebarNav } from '../components/coreuiBase/ODAppSidebarNav'
import { ODHeader } from '../components/ODHeader'
import { useODAppContext } from '../context/ODAppContext'
import { ODRoute, ODSiteNav } from '../context/ODCommon'
import { ODColors } from '../global-styles'
import { BreadcrumbBox, CurrentItemName, CustomBreadcrumb } from './User/UserOrgMainLayout'

type CommonMainLayoutProps = {
  routes: Array<ODRoute>
  navs: ODSiteNav
  defaultRedirect: string
  isAdminLayout: boolean
  isInOrgAdmin: number | null | false // 현재 특정 기관 관리자 페이지 안에 있나? (orgId)
  location?: any
  isRemoveLayoutMargin?: boolean
}

export const CommonMainLayout: React.FC<CommonMainLayoutProps> = props => {
  const {
    state: { userProfile },
  } = useODAppContext()

  const { routes, navs, defaultRedirect, isAdminLayout, isInOrgAdmin, location, isRemoveLayoutMargin = false } = props

  const { currentMachineName } = useODAppContext()

  const Loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  )

  const RouteWrapperOptionalStyle = !isRemoveLayoutMargin
    ? { marginTop: 30, marginBottom: 25, marginLeft: 30, marginRight: 30 }
    : {}
  return (
    <div className="app">
      <AppHeader fixed>
        <React.Suspense fallback={Loading}>
          <ODHeader
            name={userProfile?.name}
            profile={userProfile}
            isInAdmin={isAdminLayout}
            isInOrgAdmin={isInOrgAdmin}
          />
        </React.Suspense>
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <React.Suspense fallback={Loading}>
            <ODAppSidebarNav navConfig={navs} location={location} />
          </React.Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main" style={{ display: 'flex', flexDirection: 'column' }}>
          <BreadcrumbBox>
            <CustomBreadcrumb appRoutes={routes} />
            {isAdminLayout && currentMachineName && <CurrentItemName>/ {currentMachineName}</CurrentItemName>}
          </BreadcrumbBox>
          <div
            style={{
              display: 'flex',
              flexGrow: 3,
              backgroundColor: ODColors.ContentAreaBackground,
              overflowX: 'scroll',
              ...RouteWrapperOptionalStyle,
            }}
          >
            <React.Suspense fallback={Loading}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component || route.render ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      // @ts-ignore
                      name={route.name}
                      render={props =>
                        route.component ? <route.component message={route.name} {...props} /> : route.render!(props)
                      }
                    />
                  ) : null
                })}
                <Redirect to={defaultRedirect} />
              </Switch>
            </React.Suspense>
          </div>
        </main>
      </div>
    </div>
  )
}
