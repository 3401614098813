import React from 'react'
import { AgentDirContainer } from '../containers/Admin/Agent/AgentDirContainer'
import { AgentListContainer } from '../containers/Admin/Agent/AgentListContainer'
import { AgentLogEntitiesContainer } from '../containers/Admin/AgentLogs/AgentLogEntitiesContainer'
import { AgentLogsContainer } from '../containers/Admin/AgentLogs/AgentLogsContainer'
import { ApiKeyEditContainer } from '../containers/Admin/ApiKey/ApiKeyEditContainer'
import { ApiKeyListContainer } from '../containers/Admin/ApiKey/ApiKeyListContainer'
import { AttachmentTypeEditContainer } from '../containers/Admin/AttachmentType/AttachmentTypeEditContainer'
import { AttachmentTypeListContainer } from '../containers/Admin/AttachmentType/AttachmentTypeListContainer'
import { DashboardContainer } from '../containers/Admin/DashboardContainer'
import { OrganizationEditContainer } from '../containers/Admin/Organization/OrganizationEditContainer'
import { OrganizationListContainer } from '../containers/Admin/Organization/OrganizationListContainer'
import { UserEditContainer } from '../containers/Admin/User/UserEditContainer'
import { UsersContainer } from '../containers/Admin/UsersContainer'
import { AlertLogsListContainer } from '../containers/common/alert/AlertLogsListContainer'
import { AuditLogsListContainer } from '../containers/common/audit/AuditLogsListContainer'
import { ODRoute } from '../context/ODCommon'
import { Strings } from '../i18n/res'
import { AppConfigRoot } from '../odc/modules/AppConfig/AppConfigRoot'
import { SiteUrls } from '../urls'

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const AdminRoutes: Array<ODRoute> = [
  { path: SiteUrls.Admin.Dashboard.Main, exact: true, name: Strings.Dashboard, component: DashboardContainer },
  {
    path: SiteUrls.Admin.Alert.List,
    exact: true,
    name: 'Alerts',
    render: () => <AlertLogsListContainer orgId={null} userId={null} />,
  },
  { path: SiteUrls.Admin.User.List, exact: true, name: Strings.User, component: UsersContainer },
  { path: SiteUrls.Admin.User.Add, exact: true, name: Strings.User, component: UserEditContainer },
  {
    path: SiteUrls.Admin.Organization.List,
    exact: true,
    name: Strings.Organization,
    component: OrganizationListContainer,
  },
  { path: SiteUrls.Admin.Organization.Add, exact: true, name: 'Add', component: OrganizationEditContainer },
  {
    path: SiteUrls.Admin.Organization.Edit(':orgId'),
    exact: true,
    name: 'Edit',
    render: (props: any) => <OrganizationEditContainer idEditing={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.Admin.API.List,
    exact: true,
    name: Strings.APIKey,
    component: ApiKeyListContainer,
  },
  { path: SiteUrls.Admin.API.Add, exact: true, name: 'Add', component: ApiKeyEditContainer },
  {
    path: SiteUrls.Admin.API.Edit(':id'),
    exact: true,
    name: 'Edit',
    render: (props: any) => <ApiKeyEditContainer idEditing={parseInt(props.match.params.id, 10)} />,
  },
  { path: SiteUrls.Admin.API.List, exact: true, name: Strings.APIKey, component: DashboardContainer },
  {
    path: SiteUrls.Admin.Metadata.AttachmentType.List,
    exact: true,
    name: Strings.Metadata,
    component: AttachmentTypeListContainer,
  },
  {
    path: SiteUrls.Admin.Metadata.AttachmentType.Add,
    exact: true,
    name: Strings.Add,
    component: AttachmentTypeEditContainer,
  },
  {
    path: SiteUrls.Admin.Metadata.AttachmentType.Edit(':id'),
    exact: true,
    name: Strings.Edit,
    render: (props: any) => <AttachmentTypeEditContainer idEditing={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.Agent.Main,
    exact: true,
    name: Strings.AgentStatus,
    render: (props: any) => <AgentListContainer />,
  },
  {
    path: SiteUrls.Admin.Agent.DirStatus(':id'),
    exact: true,
    name: '',
    render: (props: any) => <AgentDirContainer machineId={parseInt(props.match.params.id, 10)} />,
  },
  {
    path: SiteUrls.Admin.AgentLogs.Main,
    exact: true,
    name: Strings.AgentLogs,
    render: (props: any) => <AgentLogEntitiesContainer />,
  },
  {
    path: SiteUrls.Admin.AgentLogs.LogOfPid(':pid'),
    exact: true,
    name: Strings.AgentLogs,
    render: (props: any) => <AgentLogsContainer pid={props.match.params.pid} />,
  },
  {
    path: SiteUrls.Admin.AppConfig.Main,
    exact: false,
    name: 'System Setting',
    render: (props: any) => <AppConfigRoot />,
  },
  {
    path: SiteUrls.Admin.Audit.List,
    exact: true,
    name: 'Audit Log',
    render: () => <AuditLogsListContainer orgId={null} />,
  },
]
