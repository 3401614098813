import { WORKSET_PERM } from '@cils/common'
import React, { ChangeEvent } from 'react'
import { Label } from 'reactstrap'
import styled from 'styled-components'
import { GQLListableWorkset, GQLListableWorksetInput, GQLWorkset, GQLWorksetPropsInput } from '../../@types/server'
import NoIcoImage from '../../assets/img/ic-noitems.png'
import { GQL_ITEM_SIMPLE_SNAPSHOT } from '../../context/ODAppContext'
import { useODMutation, useODQuery2 } from '../../context/ODCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { Utils } from '../../utils'
import { BlockingLoadBox } from '../BlockingLoadBox'
import { NoItemsFoundBox } from '../NoItemsFoundBox'
import { ODButton, ODButtonTheme } from '../ODButton'
import { ODIcon, ODIcons } from '../ODIcon'
import { ODSearchBox } from '../ODSearchBox'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

const GQL_LIST_WORKSET = `
query listWorkset($data: ListableWorksetInput!) {
  listWorkset(data: $data) {
    list {
      wsId
      name
      numItems
      defaultPerm
      mainItems {
        ${GQL_ITEM_SIMPLE_SNAPSHOT}
      }
    }
    totalCount
    page
    pageSize
  }
}
`

const GQL_CREATE_WORKSET = `
mutation createWorkset($data: WorksetPropsInput!) {
  createWorkset(data: $data) {
    wsId
  }
}
`

type WorksetListWrapperStyleProps = {
  hasKeyword: string
}

const CustomLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 2px #c8ced3;
`
const CreateWorksetBox = styled.div`
  width: 100%;
  max-height: 55px;
  border: dashed 1px #29b6ca;
  background-color: rgba(41, 182, 202, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 28px 11px 28px;
  cursor: pointer;
`
const CreateWorksetBoxMainText = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #29b6ca;
  margin-bottom: 2px;
`
const CreateWorksetBoxSubText = styled.span`
  font-size: 9px;
  color: #73818f;
`
const CircleIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: rgba(41, 182, 202, 0.2);
  margin-right: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`
const WorksetListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props: WorksetListWrapperStyleProps) => (props.hasKeyword ? 245 : 300)}px;
  overflow-y: auto;
  margin-top: 21px;
`

type ODModalAddToWorksetProps = {
  onClose: () => void
  onSave: (wsId: Array<number>) => Promise<boolean>
}

export const ODModalAddToWorkset: React.FC<ODModalAddToWorksetProps> = props => {
  const { onClose, onSave } = props

  const { orgId } = useODSingleOrgContext()

  const [keyword, setKeyword] = React.useState<string>('')
  const [checkedItems, setCheckedItems] = React.useState(new Map<number, boolean>())

  const { data, loading, error, refetch } = useODQuery2<Partial<GQLListableWorksetInput>, GQLListableWorkset>(
    GQL_LIST_WORKSET,
    {
      pickFirstKey: true,
      skip: false,
      variables: {
        onlyAddable: true,
        orgId,
        page: 1,
        pageSize: 1000,
        filter: keyword,
      },
    }
  )
  const apiCreateWorkset = useODMutation<Partial<GQLWorksetPropsInput>, GQLWorkset>(GQL_CREATE_WORKSET)

  React.useEffect(() => {
    if (error) {
      Utils.showError(error)
    }
  }, [error])

  const filteredItems = data
    ? data.list.filter(item => (item.name || '').toLowerCase().includes(keyword.toLowerCase()))
    : []

  const hasCheckedItems = React.useMemo(() => Array.from(checkedItems.entries()).find(([, value]) => value), [
    checkedItems,
  ])

  const handleChange = (v: string) => {
    setKeyword(v)
  }

  const handleSaveWorksetIds = (item: GQLWorkset, e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    checkedItems.set(item.wsId, isChecked)
    setCheckedItems(new Map(checkedItems))
  }

  const handleSave = async (checkedItems: Map<number, boolean>) => {
    const selectedWorksetIds = Array.from(checkedItems.entries())
      .filter(([, value]) => value)
      .map(([key]) => key)
    if (await onSave(selectedWorksetIds)) {
      setCheckedItems(new Map())
      onClose()
    }
  }

  const handleCreateWorkset = async () => {
    try {
      // @ts-ignore
      const r = await apiCreateWorkset({ name: keyword, ownerOrgId: orgId, defaultPerm: WORKSET_PERM.Private })
      await refetch()
      const m = new Map()
      m.set(r.wsId, true)
      setCheckedItems(m)
      // noinspection ES6MissingAwait
      handleSave(m)
    } catch (e) {
      Utils.showError(e)
    }
  }

  const worksetList = (() => {
    return filteredItems.length > 0 ? (
      filteredItems.map(workset => {
        const mainImageLink = workset.mainItems?.[0]?.imageFile?.link
        return (
          <CustomLabel key={workset.wsId}>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginBottom: 13 }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 13 }}
                onChange={e => handleSaveWorksetIds(workset, e)}
                checked={checkedItems.get(workset.wsId) === true}
              />
              {mainImageLink && <img src={mainImageLink} alt="workset thumbnail" style={{ width: 40 }} />}
              {!mainImageLink && <img src={NoIcoImage} alt="No Ico" style={{ width: 40 }} />}
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                <span style={{ fontSize: 15, fontWeight: 'bold', lineHeight: 1.07, color: '#2e343a', marginBottom: 4 }}>
                  {workset.name}
                </span>
                <span style={{ fontSize: 11, letterSpacing: 0.16, color: '#73818f' }}>{workset.numItems} items</span>
              </div>
            </div>
          </CustomLabel>
        )
      })
    ) : (
      <NoItemsFoundBox style={{ minHeight: '100%', maxHeight: 245 }} />
    )
  })()

  return (
    <ODModal isOpen toggle={() => null}>
      {loading && <BlockingLoadBox show />}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title="Add to workset" onClose={onClose} />
        <ODModalBody style={{ paddingBottom: 19, maxHeight: 375 }}>
          <ODSearchBox
            placeholder="Search or enter name of the new workset"
            value={keyword}
            onChange={handleChange}
            style={{ marginBottom: 0 }}
            formGroupStyle={{ marginBottom: 8 }}
            onKeyDown={e => {
              if ((e.ctrlKey && e.key === 'Enter') || (e.shiftKey && e.key === 'Enter')) {
                handleCreateWorkset()
              }
            }}
          />
          {keyword && (
            <CreateWorksetBox onClick={handleCreateWorkset}>
              <CircleIcon>
                <ODIcon icon={ODIcons.MaterialPermMedia} style={{ fontSize: 12, color: '#29b6ca' }} />
              </CircleIcon>
              <div style={{ display: 'flex', flexDirection: 'column', minWidth: 0, flex: 1 }}>
                <CreateWorksetBoxMainText
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  Create “{keyword}” and add items.
                </CreateWorksetBoxMainText>
                <CreateWorksetBoxSubText>or Shift+Enter , Ctrl+Enter</CreateWorksetBoxSubText>
              </div>
            </CreateWorksetBox>
          )}
          <WorksetListWrapper hasKeyword={keyword}>{worksetList}</WorksetListWrapper>
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            fullWidth
            theme={!hasCheckedItems ? ODButtonTheme.Cancel : ODButtonTheme.Primary}
            onClick={() => handleSave(checkedItems)}
            disabled={!hasCheckedItems}
          >
            Add to workset
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
