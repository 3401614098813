import { CILS_AGENT_SCAN_INFO } from '@cils/common'
import { ODHSpace, ODRow } from '@odc/od-react-belt'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'

type SyncScanProgressProps = {}

const ScanWrapper = styled.div`
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #c8ced3;
`

const ScanBoldText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: #2f353a;
`

const ScanInfoText = styled.span`
  font-size: 12px;
  color: #73818f;
`

export const SyncScanProgress: React.FC<SyncScanProgressProps> = props => {
  const { getScanningInfo, startScan } = useCILSAgentContext()
  const [scanInfo, setScanInfo] = React.useState<CILS_AGENT_SCAN_INFO | null>(null)

  React.useEffect(() => {
    const handler = setInterval(() => {
      getScanningInfo().then(v => {
        setScanInfo(JSON.parse(v.output))
      })
    }, 1000)
    return () => clearInterval(handler)
  }, [setScanInfo, getScanningInfo])

  const text = (() => {
    if (!scanInfo || !scanInfo.hasOwnProperty('scanning')) {
      return 'Scan Info not available'
    }

    const comps: Array<string> = []
    if (scanInfo.scanning) {
      const per = Math.floor((scanInfo.numScanned * 100) / scanInfo.numTotal) || 0
      comps.push(`Scanning workspace now : ${per}% (${scanInfo.numScanned}/${scanInfo.numTotal})`)
    } else if (scanInfo.syncing) {
      const per = Math.floor((scanInfo.numSynced * 100) / scanInfo.numStabilized) || 0
      comps.push(`Syncing workspace meta-data now : ${per}% (${scanInfo.numSynced}/${scanInfo.numStabilized})`)
    } else {
      comps.push(
        `Last scanned : ${scanInfo.lastScannedAt ? moment(scanInfo.lastScannedAt).fromNow() : '-'} - [Scanned: ${
          scanInfo.numTotal
        }, Watching: ${scanInfo.numNotStabilized}, Synced: ${scanInfo.numSynced}, Error: ${scanInfo.numError}]`
      )
    }

    return comps.join(' ')
  })()

  const hasError = (scanInfo?.errorMessages?.length || 0) > 0

  const handleShowError = () => {
    alert(scanInfo?.errorMessages?.join('\n') || '')
    console.log(45, scanInfo?.errorMessages)
  }

  return (
    <ScanWrapper>
      <ODRow>
        <ScanBoldText>
          <ODIcon icon={ODIcons.MaterialInfoOutline} onClick={() => startScan()} />
          <ODHSpace w={9} />
          Scan Info
        </ScanBoldText>
        <ODHSpace w={17} />
        <ScanInfoText onClick={hasError ? handleShowError : undefined}>{text}</ScanInfoText>
      </ODRow>
    </ScanWrapper>
  )
}
