import { USER_PRIV } from '@cils/common'
import { ODGlobal } from '@odc/od-react-belt'
import { ODGlobalDef } from '@odc/odbelt'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { EventEmitter } from 'events'
import { createBrowserHistory } from 'history'
import { isNumber } from 'lodash'
import React from 'react'
import { ApolloProvider } from 'react-apollo-hooks'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Slide, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RecoilRoot } from 'recoil'
import { applyMiddleware, compose, createStore } from 'redux'
import { QueryParamProvider } from 'use-query-params'
import { createApolloClient } from './apolloClientUtils'
import './App.scss'
import { AppOptions } from './AppOptions'
import { TopLevelLoadBox } from './components/TopLevelLoadBox'
import { WaitForAcceptRegistrationPage } from './components/WaitForAcceptRegistrationPage'
import { AdminMainLayout } from './containers/Admin/AdminMainLayout'
import { Authorized } from './containers/Authorized'
import { LoginSignupContainer } from './containers/LoginSignupContainer'
import { LogoutContainer } from './containers/LogoutContainer'
import { OrgAdminMainLayout } from './containers/OrgAdmin/OrgAdminMainLayout'
import { SyncRootContainer } from './containers/User/Sync/SyncRootContainer'
import { TCFDetailModalByShareToken } from './containers/User/TCFDetailModalByShareToken'
import { UserMainLayout } from './containers/User/UserMainLayout'
import { CILSAgentProvider } from './context/CILSAgentContext'
import { ODAppProvider } from './context/ODAppContext'
import { GlobalStyle } from './global-styles'
import createRootReducer from './reducers/index'
import { SiteUrls } from './urls'
import { registerServerErrorMessages } from './utils/registerServerErrorMessages'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

const emitter = new EventEmitter()

const { client } = createApolloClient(
  async () => localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
  token => localStorage.setItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN, token),
  AppOptions.SERVER_ADDRESS,
  AppOptions.WS_SERVER_ADDRESS,
  emitter
)
const { client: localClient } = createApolloClient(
  async () => localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
  token => localStorage.setItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN, token),
  AppOptions.localServerAddress,
  AppOptions.connectLocalAgent ? AppOptions.wsLocalServerAddress : ''
)

const history = createBrowserHistory()

// const store = createStore(createRootReducer(history), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  createRootReducer(history), // root reducer with router state
  {},
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history) // for dispatching history actions
      // ... other middlewares ...
    )
  )
)

toast.configure()

const globalThemeDef: ODGlobalDef = JSON.parse(
  `{"sidebar":{"bgColor":"black","iconColor":"#708393","textColor":"white","activeBGColor":"{{primary}}","activeIconColor":"white","activeTextColor":"white","hoverBGColor":"#3a4248","hoverIconColor":"{{primary}}","hoverTextColor":"white"},"appHeader":{"topBorderSize":"2px","topBorderColor":"{{primary}}"},"logo":{"logoString":"CILS","textColor":"{{primary}}"},"css":{"linkColor":"#29b6ca","linkHoverColor":"#20a8d8"},"serviceName":"Unnamed Service","theme":"cui","basePalette":{},"palette":{"primary":"#29b6ca","secondary":"#20a8d8"}}`
)

registerServerErrorMessages()

const App = () => {
  const containerStyle = {
    zIndex: 1999,
  }

  return (
    <ODGlobal def={globalThemeDef}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ApolloProvider client={client}>
            <RecoilRoot>
              <ODAppProvider>
                <CILSAgentProvider localClient={localClient} enable={AppOptions.connectLocalAgent}>
                  <GlobalStyle />
                  <ToastContainer position="top-right" autoClose={5000} style={containerStyle} transition={Slide} />
                  <TopLevelLoadBox />
                  <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <React.Suspense fallback={loading()}>
                        <Switch>
                          <Route
                            path={SiteUrls.Login}
                            render={(props: any) => <LoginSignupContainer {...props} initialStateSignup={false} />}
                          />
                          <Route
                            path={SiteUrls.Logout}
                            render={(props: any) => <LogoutContainer urlAfterLogout={SiteUrls.Login} {...props} />}
                          />
                          <Route
                            path={SiteUrls.WaitingForAcceptRegistration}
                            render={(props: any) => <WaitForAcceptRegistrationPage {...props} />}
                          />
                          <Route
                            path={SiteUrls.Register}
                            render={(props: any) => <LoginSignupContainer {...props} initialStateSignup />}
                          />
                          <Route
                            path={SiteUrls.SharedTCF(':shareToken', ':orgId')}
                            render={(props: any) => (
                              <TCFDetailModalByShareToken
                                orgId={parseInt(props.match.params.orgId, 10)}
                                shareToken={props.match.params.shareToken}
                              />
                            )}
                          />
                          <Route
                            path={SiteUrls.Admin.Root}
                            render={(props: any) => (
                              <Authorized minPriv={USER_PRIV.SuperAdmin} emitter={emitter}>
                                <AdminMainLayout {...props} />
                              </Authorized>
                            )}
                          />
                          <Route
                            path={SiteUrls.OrgAdmin.Root(':orgId')}
                            render={(props: any) => {
                              const orgId = parseInt(props.match.params.orgId, 10)
                              return (
                                <Authorized minPriv={USER_PRIV.Normal} shouldAdminOfOrg={orgId} emitter={emitter}>
                                  <OrgAdminMainLayout orgId={orgId} {...props} />
                                </Authorized>
                              )
                            }}
                          />
                          <Route path={SiteUrls.SyncWithoutLogin} render={(props: any) => <SyncRootContainer />} />
                          <Route
                            path={SiteUrls.User.Root}
                            render={(props: any) => (
                              <Authorized
                                minPriv={USER_PRIV.Normal}
                                redirect={priv =>
                                  isNumber(priv) ? SiteUrls.WaitingForAcceptRegistration : SiteUrls.Login
                                }
                                emitter={emitter}
                              >
                                <UserMainLayout {...props} />
                              </Authorized>
                            )}
                          />
                          <Redirect to={SiteUrls.User.Root} />
                        </Switch>
                      </React.Suspense>
                    </QueryParamProvider>
                  </Router>
                </CILSAgentProvider>
              </ODAppProvider>
            </RecoilRoot>
          </ApolloProvider>
        </ConnectedRouter>
      </Provider>
    </ODGlobal>
  )
}

// @ts-ignore
export default App
