import { ITEM_EXECUTION_CONFIRM_FILTER } from '@cils/common'
import React from 'react'
import { GQLITEM_EXECUTION_CONFIRM_FILTER } from '../../../@types/server'
import { ODColors } from '../../../global-styles'
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick'
import { DropButton, DropDown, DropDownContent, OptionWrapper, Value } from '../../Dropdown/DropdownCommon'
import { ODIcon, ODIcons } from '../../ODIcon'

export enum ProjectConfirmed {
  Confirmed = 'Confirmed',
  UnConfirmed = 'UnConfirmed',
}

interface IProjectConfirmedDropDown {
  value: GQLITEM_EXECUTION_CONFIRM_FILTER | null
  setValue: (v: GQLITEM_EXECUTION_CONFIRM_FILTER | null) => void
}

export const ProjectConfirmedDropDown: React.FC<IProjectConfirmedDropDown> = props => {
  const { value, setValue } = props
  const menuRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownMenus = Object.values(ITEM_EXECUTION_CONFIRM_FILTER)

  useDetectOutsideClick(() => setIsOpen(false), menuRef, isOpen)

  const onClick = (v: ITEM_EXECUTION_CONFIRM_FILTER | null) => {
    setValue((v as unknown) as GQLITEM_EXECUTION_CONFIRM_FILTER)
    setIsOpen(false)
  }

  const selectedValue = (() => {
    if (!value) {
      return 'Confirmed'
    }

    if (value === ((ITEM_EXECUTION_CONFIRM_FILTER.Confirmed as unknown) as GQLITEM_EXECUTION_CONFIRM_FILTER)) {
      return 'Confirmed'
    } else {
      return 'Unconfirmed'
    }
  })()

  return (
    <DropDown ref={menuRef} style={{ width: 125 }}>
      <DropButton onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))} style={{ width: 125 }}>
        <span
          style={{ display: 'block', width: 100, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {selectedValue}
        </span>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none', width: 186, marginTop: 5 }}>
        <OptionWrapper onClick={() => onClick(null)} style={{ minHeight: 41 }}>
          <Value style={{ fontWeight: 'bold', color: ODColors.CharcoalGrey }}>All</Value>
        </OptionWrapper>
        {dropdownMenus.map(menu => {
          return (
            <OptionWrapper key={menu} onClick={() => onClick(menu)} style={{ minHeight: 41 }}>
              <Value>{menu}</Value>
            </OptionWrapper>
          )
        })}
      </DropDownContent>
    </DropDown>
  )
}
