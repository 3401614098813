import { WORKSET_SORT_OPTION } from '@cils/common'
import React, { useState } from 'react'
import { GQLWorkset } from '../@types/server'
import { ODListableContextType, WorksetDataLoaderOption } from '../ODListable/ODListableContext'
import { useDetectOutsideClick } from '../utils/useDetectOutsideClick'
import { DropButton, DropDown, DropDownContent, OptionWrapper, Value } from './Dropdown/DropdownCommon'
import { ODIcon, ODIcons } from './ODIcon'
import { DROPDOWN_MENU, DropDownMenuProps } from './ODImageGrid/ODImageGridSearchToolBar'

const SortByName: { [key: string]: JSX.Element } = {
  Default: (
    <>
      <Value>
        Created <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
      </Value>
    </>
  ),
  [WORKSET_SORT_OPTION.CreatedDateAscending]: (
    <Value>
      Created <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [WORKSET_SORT_OPTION.CreatedDateDescending]: (
    <Value>
      Created <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [WORKSET_SORT_OPTION.NameAscending]: (
    <Value>
      Name <span style={{ color: '#2f353a' }}>A</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
      <span style={{ color: '#2f353a' }}>Z</span>
    </Value>
  ),
  [WORKSET_SORT_OPTION.NameDescending]: (
    <Value>
      Name <span style={{ color: '#2f353a' }}>Z</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
      <span style={{ color: '#2f353a' }}>A</span>
    </Value>
  ),
  [WORKSET_SORT_OPTION.LastAddedAscending]: (
    <Value>
      Last Added <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [WORKSET_SORT_OPTION.LastAddedDescending]: (
    <Value>
      Last Added <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
}

type Props = {
  listableContext: React.Context<ODListableContextType<GQLWorkset, WorksetDataLoaderOption>>
  removeLastAdded?: boolean
}

export const SortByWorksetDropdown: React.FC<Props & DropDownMenuProps> = props => {
  const { isMenuOpen, setIsMenuOpen, listableContext, removeLastAdded = false } = props

  const [value, setValue] = useState<string>('Default')
  const menuRef = React.useRef<HTMLDivElement>(null)
  const isOpen = isMenuOpen === DROPDOWN_MENU.SortByWorkset

  const {
    state: { loadOption },
    updateLoadOption,
  } = React.useContext(listableContext)

  useDetectOutsideClick(() => setIsMenuOpen(null), menuRef, isOpen)

  const onClick = (v: WORKSET_SORT_OPTION) => {
    // @ts-ignore
    updateLoadOption({ ...loadOption, sortBy: v })
    setValue(v)
    setIsMenuOpen(null)
  }

  return (
    <DropDown ref={menuRef}>
      <DropButton onClick={() => (isOpen ? setIsMenuOpen(null) : setIsMenuOpen(DROPDOWN_MENU.SortByWorkset))}>
        {SortByName[value]}
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        <OptionWrapper onClick={() => onClick(WORKSET_SORT_OPTION.CreatedDateAscending)}>
          <Value>
            Created <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(WORKSET_SORT_OPTION.CreatedDateDescending)}>
          <Value>
            Created <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(WORKSET_SORT_OPTION.NameAscending)}>
          <Value>
            Name <span style={{ color: '#2f353a' }}>A</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
            <span style={{ color: '#2f353a' }}>Z</span>
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(WORKSET_SORT_OPTION.NameDescending)}>
          <Value>
            Name <span style={{ color: '#2f353a' }}>Z</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
            <span style={{ color: '#2f353a' }}>A</span>
          </Value>
        </OptionWrapper>
        {!removeLastAdded && (
          <OptionWrapper onClick={() => onClick(WORKSET_SORT_OPTION.LastAddedAscending)}>
            <Value>
              Last Added <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
            </Value>
          </OptionWrapper>
        )}
        {!removeLastAdded && (
          <OptionWrapper
            style={{ borderBottom: 'none' }}
            onClick={() => onClick(WORKSET_SORT_OPTION.LastAddedDescending)}
          >
            <Value>
              Last Added <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
            </Value>
          </OptionWrapper>
        )}
      </DropDownContent>
    </DropDown>
  )
}
