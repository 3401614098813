"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidationRegistry = void 0;

var odbelt_1 = require("@odc/odbelt");

var projectValidationRegistry = JSON.parse("{\"ServerSideAppConfigUpdate\":{\"name\":\"__odc_autogen_AppConfigValidator\",\"errorName\":\"DB_ENTITY_VALIDATION_FAILED\",\"type\":\"ODObjectValidator\",\"fieldValidators\":[]}}");
exports.ValidationRegistry = new odbelt_1.CustomValidatorRegistry(projectValidationRegistry);