import React from 'react'
import ReactCSSTransitionReplace from 'react-css-transition-replace'
import styled from 'styled-components'
import logoSymbolW from '../../assets/img/brand/logo-symbol-w.png'

type CoverCardContentsProps = {
  isLoggingIn: boolean
  onClickButton: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 52px;
  padding-top: 72px;
  padding-bottom: 101px;
`

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`

const BottomText1 = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 7px;
  color: #ffffff;
`

const BottomText2 = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 28px;
`

const Button = styled.button`
  width: 170px;
  height: 36px;
  border: solid 1px #ffffff;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  background-color: #29b6ca;
  transition: 0.3s;

  :hover {
    background-color: #ffffff;
    color: #29b6ca;
  }
`

export const CoverCardContents: React.FC<CoverCardContentsProps> = ({ isLoggingIn, onClickButton }) => {
  return (
    <ReactCSSTransitionReplace transitionName="fade-wait" transitionEnterTimeout={1000} transitionLeaveTimeout={1000}>
      <Wrapper key={`cover-${isLoggingIn}`}>
        <img alt="cils logo" src={logoSymbolW} width={49} height={49} style={{ marginBottom: 23 }} />
        <Title>{isLoggingIn ? `Welcome Back!` : `Welcome to Tomocube.`}</Title>
        <div style={{ height: 123 }} />
        <BottomText1>{isLoggingIn ? `Don't have an account?` : 'Already Signed up?'}</BottomText1>
        <BottomText2>{isLoggingIn ? `Create your account` : `If you're already signed up, please log in.`}</BottomText2>
        <Button onClick={onClickButton}>{isLoggingIn ? 'SIGN UP' : 'LOG IN'}</Button>
      </Wrapper>
    </ReactCSSTransitionReplace>
  )
}
