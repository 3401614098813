import React from 'react'
import { AlertLogsListContainer } from '../containers/common/alert/AlertLogsListContainer'
import { AuditLogsListContainer } from '../containers/common/audit/AuditLogsListContainer'
import { OrgAgentEditContainer } from '../containers/OrgAdmin/Agent/OrgAgentEditContainer'
import { OrgCategoryEditContainer } from '../containers/OrgAdmin/Category/OrgCategoryEditContainer'
import { OrgCategoryListContainer } from '../containers/OrgAdmin/Category/OrgCategoryListContainer'
import { OrgAdminDashboardContainer } from '../containers/OrgAdmin/OrgAdminDashboardContainer'
import { OrgHasUserListContainer } from '../containers/OrgAdmin/OrgUsersListContainer'
import { ProjectMetaDataEditPage } from '../containers/OrgAdmin/ProjectMetadata/edit/ProjectMetaDataEditPage'
import { ProjectMetadataListPage } from '../containers/OrgAdmin/ProjectMetadata/list/ProjectMetadataListPage'
import { CilsStorageEditContainer } from '../containers/OrgAdmin/Storage/CilsStorageEditContainer'
import { CilsStorageListContainer } from '../containers/OrgAdmin/Storage/CilsStorageListContainer'
import { OrgUserEditContainer } from '../containers/OrgAdmin/User/OrgUserEditContainer'
import { OrgUserGroupEditContainer } from '../containers/OrgAdmin/UserGroup/OrgUserGroupEditContainer'
import { UserGroupContainer } from '../containers/OrgAdmin/UserGroupContainer'
import { ProjectListPage } from '../containers/User/Project/list/ProjectListPage'
import { ODRoute } from '../context/ODCommon'
import { Strings } from '../i18n/res'
import { SiteUrls } from '../urls'

const orgId = ':orgId'

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
export const OrgAdminRoutes: Array<ODRoute> = [
  {
    path: SiteUrls.OrgAdmin.Dashboard.Main(orgId),
    exact: true,
    name: Strings.Dashboard,
    render: (props: any) => <OrgAdminDashboardContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.Alert.List(orgId),
    exact: true,
    name: 'Alerts',
    render: (props: any) => <AlertLogsListContainer orgId={parseInt(props.match.params.orgId, 10)} userId={null} />,
  },
  {
    path: SiteUrls.OrgAdmin.User.List(orgId),
    exact: true,
    name: Strings.User,
    render: (props: any) => <OrgHasUserListContainer orgId={parseInt(props.match.params.orgId, 10)} showAddUser />,
  },
  {
    path: SiteUrls.OrgAdmin.User.Add(orgId),
    exact: true,
    name: Strings.User,
    render: (props: any) => <OrgUserEditContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.User.Edit(':userId')(orgId),
    exact: true,
    name: Strings.User,
    render: (props: any) => (
      <OrgUserEditContainer
        orgId={parseInt(props.match.params.orgId, 10)}
        idEditing={parseInt(props.match.params.userId, 10)}
      />
    ),
  },
  {
    path: SiteUrls.OrgAdmin.UserGroup.List(orgId),
    exact: true,
    name: Strings.UserGroup,
    render: (props: any) => <UserGroupContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.UserGroup.Add(orgId),
    exact: true,
    name: Strings.UserGroup,
    render: (props: any) => <OrgUserGroupEditContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.UserGroup.Edit(':groupId')(orgId),
    exact: true,
    name: Strings.UserGroup,
    render: (props: any) => (
      <OrgUserGroupEditContainer
        orgId={parseInt(props.match.params.orgId, 10)}
        idEditing={parseInt(props.match.params.groupId, 10)}
      />
    ),
  },
  {
    path: SiteUrls.OrgAdmin.Agent.List(orgId),
    exact: true,
    name: Strings.Agent,
    render: (props: any) => <OrgHasUserListContainer orgId={parseInt(props.match.params.orgId, 10)} showAgent />,
  },
  {
    path: SiteUrls.OrgAdmin.Agent.Add(orgId),
    exact: true,
    name: Strings.Add,
    render: (props: any) => <OrgAgentEditContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.Agent.Edit(':agentId')(orgId),
    exact: true,
    name: Strings.Edit,
    render: (props: any) => (
      <OrgAgentEditContainer
        orgId={parseInt(props.match.params.orgId, 10)}
        idEditing={parseInt(props.match.params.agentId, 10)}
      />
    ),
  },
  {
    path: SiteUrls.OrgAdmin.Category.List(orgId),
    exact: true,
    name: Strings.OrgAdminCategory,
    render: (props: any) => <OrgCategoryListContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.Category.Add(orgId),
    exact: true,
    name: Strings.Add,
    render: (props: any) => <OrgCategoryEditContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.Category.Edit(':catId')(orgId),
    exact: true,
    name: Strings.Edit,
    render: (props: any) => (
      <OrgCategoryEditContainer
        orgId={parseInt(props.match.params.orgId, 10)}
        idEditing={parseInt(props.match.params.catId, 10)}
      />
    ),
  },
  {
    path: SiteUrls.OrgAdmin.Storage.List(orgId),
    exact: true,
    name: 'Storages',
    render: (props: any) => <CilsStorageListContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.Storage.Add(orgId),
    exact: true,
    name: Strings.Add,
    render: (props: any) => <CilsStorageEditContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.Storage.Edit(':idEditing')(orgId),
    exact: true,
    name: Strings.Edit,
    render: (props: any) => (
      <CilsStorageEditContainer
        orgId={parseInt(props.match.params.orgId, 10)}
        idEditing={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
  {
    path: SiteUrls.OrgAdmin.Audit.List(orgId),
    exact: true,
    name: 'Audit Log',
    render: (props: any) => <AuditLogsListContainer orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.Projects.List(orgId),
    exact: true,
    name: 'Projects',
    render: (props: any) => <ProjectListPage orgId={parseInt(props.match.params.orgId, 10)} showInOrgAdmin />,
  },
  {
    path: SiteUrls.OrgAdmin.ProjectMetadata.List(orgId),
    exact: true,
    name: 'Project Metadata',
    render: (props: any) => <ProjectMetadataListPage orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.ProjectMetadata.Add(orgId),
    exact: true,
    name: 'New Preset',
    render: (props: any) => <ProjectMetaDataEditPage orgId={parseInt(props.match.params.orgId, 10)} />,
  },
  {
    path: SiteUrls.OrgAdmin.ProjectMetadata.Edit(':idEditing')(orgId),
    exact: true,
    name: 'New Preset',
    render: (props: any) => (
      <ProjectMetaDataEditPage
        orgId={parseInt(props.match.params.orgId, 10)}
        idEditing={parseInt(props.match.params.idEditing, 10)}
      />
    ),
  },
]
