import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import { GQLListableOrg, GQLListableOrgInput, GQLOrg } from '../../../@types/server'
import { Clickable } from '../../../components/Clickable'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODQuery } from '../../../context/ODCommon'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

export interface OrgDataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLOrg, OrgDataLoaderOption>()

export const GQL_LIST_ORG = `
query listOrg($data: ListableOrgInput!) {
  listOrg(data: $data) {
    list {
      orgId
      name
      color
      numUser
      deletedAt
      willDeleteAt
    }
    totalCount
    page
    pageSize
  }
}
`

const ActiveBadge = styled.div`
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  width: 60px;
  height: 16px;
  border-radius: 4px;
  background-color: #20a8d8;
`

const DeletedBadge = styled.div`
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  width: 60px;
  height: 16px;
  border-radius: 4px;
  background-color: #f86c6b;
`

const DeletionText = styled.span`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #8f9ba6;
`

export const OrganizationListContainer: React.FC = () => {
  const apiList = useODQuery<GQLListableOrgInput, GQLListableOrg>(GQL_LIST_ORG)

  const dataLoader = React.useCallback(
    async function OrgDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: OrgDataLoaderOption
    ): Promise<ODListableResponseType<GQLOrg>> {
      const r = await apiList({ page, pageSize, filter: options.filter || null })
      return r as ODListableResponseType<GQLOrg>
    },
    [apiList]
  )
  const TableDefinition: ODListableTableDefinition<GQLOrg, OrgDataLoaderOption> = [
    {
      id: 'org',
      title: 'Organization',
      transform: v => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'status',
      title: 'Status',
      transform: v => {
        if (v.willDeleteAt) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DeletedBadge>Deleted</DeletedBadge>
              <DeletionText style={{ marginTop: 6 }}>
                All data will be deleted at {Utils.formatDate(v.willDeleteAt)}
              </DeletionText>
            </div>
          )
        }
        return <ActiveBadge>Active</ActiveBadge>
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'numUsers',
      title: 'Users',
      transform: v => v.numUser.toLocaleString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'setting',
      title: 'Action',
      transform: v => {
        return (
          <Link to={SiteUrls.Admin.Organization.Edit(v.orgId)}>
            <Clickable>Setting</Clickable>
          </Link>
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Organization</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.orgId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <div>
                <Link to={SiteUrls.Admin.Organization.Add} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    New Organization
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
