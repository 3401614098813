import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { GQLAgentLogEntity, GQLListableAgentLogEntity, GQLListableAgentLogEntityInput } from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODQuery } from '../../../context/ODCommon'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

interface AgentLogEntitiesOption extends ODListableOption {}

const { Provider, Context } = createODListableContext<GQLAgentLogEntity, AgentLogEntitiesOption>()

const GQL_LIST_AGENT_LOG_ENTITIES = `
query listAgentLogEntities($data: ListableAgentLogEntityInput!) {
  listAgentLogEntities(data: $data) {
    list {
      pid
      minTS
      maxTS
    }
    totalCount
    page
    pageSize
  }
}
`

export const AgentLogEntitiesContainer: React.FC = () => {
  const apiList = useODQuery<GQLListableAgentLogEntityInput, GQLListableAgentLogEntity>(GQL_LIST_AGENT_LOG_ENTITIES)

  const dataLoader = React.useCallback(
    async function OrgDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AgentLogEntitiesOption
    ): Promise<ODListableResponseType<GQLAgentLogEntity>> {
      const r = await apiList({ page, pageSize })
      return r as ODListableResponseType<GQLAgentLogEntity>
    },
    [apiList]
  )
  const TableDefinition: ODListableTableDefinition<GQLAgentLogEntity, AgentLogEntitiesOption> = [
    {
      id: 'pid',
      title: 'Process ID',
      transform: v => <Link to={SiteUrls.Admin.AgentLogs.LogOfPid(v.pid)}>{v.pid}</Link>,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'minTS',
      title: 'First Log',
      transform: v => moment(new Date(parseInt(v.minTS, 10))).format('YYYY-MM-DD HH:mm:ss'),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'maxTS',
      title: 'Last Log',
      transform: v => moment(new Date(parseInt(v.maxTS, 10))).format('YYYY-MM-DD HH:mm:ss'),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Agent Logs by Processes</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.pid}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
          >
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
