import { BlockingLoadBox } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { GQLProjectHasWorkset } from '../../../../@types/server'
import { ODModalAddWorksetGroup } from '../../../../components/ODModal/ODModalAddWorksetGroup'
import { ODSearchBox } from '../../../../components/ODSearchBox'
import { ODModalAddWorksetsToProjectResult } from '../../../../components/project/modal/ODModalAddWorksetsToProjectResult'
import { useCILSStore } from '../../../../di/configureRootStore'
import { ODColors } from '../../../../global-styles'
import { useProjectPerm } from '../../../../hooks/useProjectPerm'
import { ODListableContextType } from '../../../../ODListable/ODListableContext'
import { Utils } from '../../../../utils'
import { IProjectHasWorksetListableOption } from '../list/useProjectHasWorksetListDataLoader'
import { useWorksetsToProjectPickerModal } from '../useWorksetsToProjectPickerModal'

interface IProjectWorksetSearch {
  orgId: number
  projectId: number
  listableContext: React.Context<ODListableContextType<GQLProjectHasWorkset, IProjectHasWorksetListableOption>>
  myProjectPerm: number
  refresh: () => void
}

export const ProjectWorksetSearch: React.FC<IProjectWorksetSearch> = observer(props => {
  const { orgId, projectId, listableContext, myProjectPerm, refresh } = props
  const store = useCILSStore()
  const projectPerm = useProjectPerm(orgId)
  const [loading, setLoading] = React.useState(false)
  const [keyword, setKeyword] = React.useState('')
  const [isAddWorksetsResultOpen, setIsAddWorksetsResultOpen] = React.useState(false)
  const [isOpenAddWorksetGroup, setIsOpenAddWorksetGroup] = React.useState(false)
  const [isOpenResult, setIsOpenResult] = React.useState(false)

  const {
    updateLoadOption,
    state: { loadOption },
  }: ODListableContextType<GQLProjectHasWorkset, IProjectHasWorksetListableOption> = React.useContext(listableContext)
  const {
    Component: PickerTableComponent,
    props: pickerTableProps,
    pick: pickWorksetsToProject,
  } = useWorksetsToProjectPickerModal(projectId)

  const handleMultipleWorksetsToProject = React.useCallback(async () => {
    const title = `Add Worksets`
    const searchPlaceholder = 'Search by workset name, project name'
    try {
      const selectedWorksetIds = await pickWorksetsToProject({
        showSearch: true,
        searchPlaceholder,
        returnFullValue: false,
        title,
      })

      if (!selectedWorksetIds) {
        return
      }

      setLoading(true)
      store.addWorksetToProjectStore.addWorksetIds(selectedWorksetIds as number[])
      store.addWorksetToProjectStore.submit().finally(() => {
        setTimeout(() => {
          refresh()
        }, 300)
      })
      setIsAddWorksetsResultOpen(true)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
    }
  }, [pickWorksetsToProject, refresh, store.addWorksetToProjectStore])

  const handleChange = (v: string) => {
    setKeyword(v)
    // @ts-ignore
    updateLoadOption({ ...loadOption, filter: v })
  }

  return (
    <Container>
      <PickerTableComponent {...pickerTableProps} />
      <BlockingLoadBox show={loading} />
      <ODModalAddWorksetsToProjectResult
        orgId={orgId}
        isOpen={isAddWorksetsResultOpen}
        setIsOpen={setIsAddWorksetsResultOpen}
        openAddWorkset={handleMultipleWorksetsToProject}
      />
      <ODModalAddWorksetGroup
        isOpen={isOpenAddWorksetGroup}
        setOpen={setIsOpenAddWorksetGroup}
        orgId={orgId}
        setOpenResult={() => setIsOpenResult(true)}
        refresh={refresh}
      />
      <ODModalAddWorksetsToProjectResult
        isOpen={isOpenResult}
        setIsOpen={setIsOpenResult}
        orgId={orgId}
        openAddWorkset={handleMultipleWorksetsToProject}
      />
      <ODSearchBox
        placeholder="Search by Workset name"
        value={keyword}
        onChange={handleChange}
        style={{ width: '100%', maxWidth: 595, height: 34, backgroundColor: ODColors.White }}
      />
      {/* 프로젝트 내 권한이 Owner, Admin인 유저에게만 버튼이 표시 됨 */}
      {projectPerm.isSameOrGreaterThanAdmin(myProjectPerm) && (
        <>
          <AddButton
            style={{ maxWidth: 180, marginLeft: 'auto', marginRight: 10 }}
            onClick={() => setIsOpenAddWorksetGroup(true)}
          >
            Add Workset Group
          </AddButton>
          <AddButton onClick={() => handleMultipleWorksetsToProject()}>Add Workset</AddButton>
        </>
      )}
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const AddButton = styled.button`
  width: 100%;
  max-width: 135px;
  height: 35px;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${ODColors.White};
`
