"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectUserPerm = exports.AddUserToProjectResultType = exports.SearchUserResultType = exports.WorksetUserPerm = void 0;

var constants_1 = require("../../constants");

var WorksetUserPerm;

(function (WorksetUserPerm) {
  WorksetUserPerm[WorksetUserPerm["Viewer"] = constants_1.WORKSET_USER_PERM.Viewer] = "Viewer";
  WorksetUserPerm[WorksetUserPerm["Collaborator"] = constants_1.WORKSET_USER_PERM.Collaborator] = "Collaborator";
  WorksetUserPerm[WorksetUserPerm["Editor"] = constants_1.WORKSET_USER_PERM.Editor] = "Editor";
  WorksetUserPerm[WorksetUserPerm["Owner"] = constants_1.WORKSET_USER_PERM.Owner] = "Owner";
})(WorksetUserPerm = exports.WorksetUserPerm || (exports.WorksetUserPerm = {}));

var SearchUserResultType;

(function (SearchUserResultType) {
  SearchUserResultType["User"] = "User";
  SearchUserResultType["UserGroup"] = "UserGroup";
})(SearchUserResultType = exports.SearchUserResultType || (exports.SearchUserResultType = {}));

var AddUserToProjectResultType;

(function (AddUserToProjectResultType) {
  AddUserToProjectResultType["Success"] = "Success";
  AddUserToProjectResultType["Fail"] = "Fail";
})(AddUserToProjectResultType = exports.AddUserToProjectResultType || (exports.AddUserToProjectResultType = {}));

var ProjectUserPerm;

(function (ProjectUserPerm) {
  ProjectUserPerm[ProjectUserPerm["Viewer"] = constants_1.PROJECT_USER_PERM.Viewer] = "Viewer";
  ProjectUserPerm[ProjectUserPerm["Editor"] = constants_1.PROJECT_USER_PERM.Editor] = "Editor";
  ProjectUserPerm[ProjectUserPerm["Admin"] = constants_1.PROJECT_USER_PERM.Admin] = "Admin";
  ProjectUserPerm[ProjectUserPerm["Owner"] = constants_1.PROJECT_USER_PERM.Owner] = "Owner";
})(ProjectUserPerm = exports.ProjectUserPerm || (exports.ProjectUserPerm = {}));