import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import CallEndIcon from '@material-ui/icons/CallEnd'
import ChevronRight from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import ErrorIcon from '@material-ui/icons/Error'
import FilterIcon from '@material-ui/icons/Filter'
import FullScreenIcon from '@material-ui/icons/Fullscreen'
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit'
import GetAppIcon from '@material-ui/icons/GetApp'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import NotificationsActive from '@material-ui/icons/NotificationsActive'
import PauseIcon from '@material-ui/icons/Pause'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PublishIcon from '@material-ui/icons/Publish'
import RefreshIcon from '@material-ui/icons/Refresh'
import ReportIcon from '@material-ui/icons/Report'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import SettingsIcon from '@material-ui/icons/Settings'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare'
import VideoCamIcon from '@material-ui/icons/Videocam'
import VideoCamOffIcon from '@material-ui/icons/VideocamOff'

import ViewListIcon from '@material-ui/icons/ViewList'
import classNames from 'classnames'
import React from 'react'
import { ODSVGCloseX } from './svg/ODSVGCloseX'
import { ODSVGHamburgMenu } from './svg/ODSVGHamburgMenu'
import { ODSVGTwitter } from './svg/ODSVGTwitter'

export enum ODIcons {
  Researcher,
  Organization,
  TCFFile,
  Patient,
  Dashboard,
  Doctor,
  Inquiry,
  CorePeople = 30000,
  CoreSiteMap,
  CoreHttps,
  CoreSearch,
  CoreViewModule,
  CoreSync,
  CoreStar,
  CoreList,
  CoreTag,
  CoreTags,
  CoreSettings,
  CoreLibrary,
  CoreAccountLogout,
  CoreCheckAlt,
  CoreStorage,
  CoreX,
  CoreEnvelopeClosed,
  CoreArrowCircleLeft,
  CoreFrown,
  CoreLibraryAdd,
  CoreDataTransferDown,
  CorePencil,
  CoreSave,
  CoreTrash,
  CoreXCircle,
  CoreImagePlus,
  CorePlus,
  CoreArrowCircleBottom,
  CoreArrowCircleTop,
  CoreMediaPause,
  CoreMediaPlay,
  CoreReload,
  CoreLink,
  CoreFolder,
  CoreAvTimer,
  CoreShare,
  CoreFolderOpen,
  CoreBuilding,
  CoreGrid,
  CoreGridSlash,
  CoreHospital,
  CoreEnvelopeLetter,
  CoreCalendar,
  CoreCheckCircle,
  CoreContact,
  CoreClipboard,
  CoreUserUnfollow,
  CoreCalendarCheck,
  CoreHistory,
  CoreFile,
  CoreUser,
  CoreBed,
  CoreSmoke,
  CoreBookmark,
  CoreCreditCard,
  CorePaperPlaneSend,
  CoreHighlighter,
  CoreTerminal,
  CoreWarning,
  CoreSpreadsheet,
  CoreObjectGroup,
  CoreShieldAlt,

  MaterialClose = 60000,
  MaterialSettings,
  MaterialArrowLeft,
  MaterialArrowRight,
  MaterialError,
  MaterialFilter,
  MaterialStar,
  MaterialStarBorder,
  MaterialArrowDropDownIcon,
  MaterialArrowUpwardIcon,
  MaterialArrowDownwardIcon,
  MaterialArrowForwardIcon,
  MaterialArrowRightIcon,
  MaterialFileDownload,
  MaterialFileUpload,
  MaterialAddCircleOutlined,
  MaterialReport,
  MaterialRefresh,
  MaterialPermMedia,
  MaterialPlayArrow,
  MaterialPause,
  MaterialCreateNewFolder,
  MaterialViewList,
  MaterialMic,
  MaterialMicOff,
  MaterialVideoCam,
  MaterialVideoCamOff,
  MaterialShareScreen,
  MaterialFullscreen,
  MaterialFullscreenExit,
  MaterialCallEnd,
  MaterialDesktopWindows,
  MaterialStopScreenShare,
  MaterialInfoOutline,
  MaterialChevronRight,
  MaterialNotificationsActive,
  MaterialAccountCircle,
  MaterialAddBox,

  SimpleLineIconsShareAlt = 70000,
  SimpleLineIconsFolder,

  FontAwesomeArrowCircleRight = 90000,
  FontAwesomeStarOutlined,
  FontAwesomeStar,

  SVGIconHamburg = 110000,
  SVGIconCloseX,
  SVGIconTwitter,
}

export enum IconSet {
  ODCode = 'ico-odcode',
  CoreUI = 'ico-cui',
  Material = 'ico-material',
  FontAwesome = 'ico-fa',
  SimpleLineIcons = 'ico-sli',
  SVGIcon = 'ico-svg',
}

type IconDefinition = {
  iconSet: IconSet
  name: string
  code?: string // for ODCode
  node?: any // for Material (타입파악실패), for SVGIcon (Component)
}

const createIcon = (iconSet: IconSet, name: string, code?: string, node?: any): IconDefinition => ({
  iconSet,
  name,
  code,
  node,
})

export const IconsDefinition = {
  [ODIcons.Researcher]: createIcon(IconSet.ODCode, 'researcher', 'e900'),
  [ODIcons.Organization]: createIcon(IconSet.ODCode, 'organization', 'e901'),
  [ODIcons.TCFFile]: createIcon(IconSet.ODCode, 'tcffile', 'e902'),
  [ODIcons.Dashboard]: createIcon(IconSet.ODCode, 'dashboard', 'e903'),
  [ODIcons.Doctor]: createIcon(IconSet.ODCode, 'doctor', 'e904'),
  [ODIcons.Inquiry]: createIcon(IconSet.ODCode, 'inquiry', 'e905'),
  [ODIcons.Patient]: createIcon(IconSet.ODCode, 'patient', 'e906'),
  [ODIcons.CorePeople]: createIcon(IconSet.CoreUI, 'cil-people'),
  [ODIcons.CoreSiteMap]: createIcon(IconSet.CoreUI, 'cil-sitemap'),
  [ODIcons.CoreHttps]: createIcon(IconSet.CoreUI, 'cil-https'),
  [ODIcons.CoreSearch]: createIcon(IconSet.CoreUI, 'cil-magnifying-glass', ''),
  [ODIcons.CoreViewModule]: createIcon(IconSet.CoreUI, 'cil-view-module', ''),
  [ODIcons.CoreSync]: createIcon(IconSet.CoreUI, 'cil-sync', ''),
  [ODIcons.CoreStar]: createIcon(IconSet.CoreUI, 'cil-star', ''),
  [ODIcons.CoreList]: createIcon(IconSet.CoreUI, 'cil-list', ''),
  [ODIcons.CoreTag]: createIcon(IconSet.CoreUI, 'cil-tag', ''),
  [ODIcons.CoreTags]: createIcon(IconSet.CoreUI, 'cil-tags', ''),
  [ODIcons.CoreSettings]: createIcon(IconSet.CoreUI, 'cil-settings', ''),
  [ODIcons.CoreLibrary]: createIcon(IconSet.CoreUI, 'cil-library', ''),
  [ODIcons.CoreAccountLogout]: createIcon(IconSet.CoreUI, 'cil-account-logout', ''),
  [ODIcons.CoreCheckAlt]: createIcon(IconSet.CoreUI, 'cil-check-alt', ''),
  [ODIcons.CoreStorage]: createIcon(IconSet.CoreUI, 'cil-storage', ''),
  [ODIcons.CoreX]: createIcon(IconSet.CoreUI, 'cil-x', ''),
  [ODIcons.CoreXCircle]: createIcon(IconSet.CoreUI, 'cil-x-circle', ''),
  [ODIcons.CoreEnvelopeClosed]: createIcon(IconSet.CoreUI, 'cil-envelope-closed', ''),
  [ODIcons.CoreArrowCircleLeft]: createIcon(IconSet.CoreUI, 'cil-arrow-circle-left', ''),
  [ODIcons.CoreFrown]: createIcon(IconSet.CoreUI, 'cil-frown', ''),
  [ODIcons.CoreLibraryAdd]: createIcon(IconSet.CoreUI, 'cil-library-add', ''),
  [ODIcons.CoreDataTransferDown]: createIcon(IconSet.CoreUI, 'cil-data-transfer-down', ''),
  [ODIcons.CorePencil]: createIcon(IconSet.CoreUI, 'cil-pencil', ''),
  [ODIcons.CoreSave]: createIcon(IconSet.CoreUI, 'cil-save', ''),
  [ODIcons.CoreTrash]: createIcon(IconSet.CoreUI, 'cil-trash', ''),
  [ODIcons.CoreImagePlus]: createIcon(IconSet.CoreUI, 'cil-image-plus', ''),
  [ODIcons.CorePlus]: createIcon(IconSet.CoreUI, 'cil-plus', ''),
  [ODIcons.CoreArrowCircleBottom]: createIcon(IconSet.CoreUI, 'cil-arrow-circle-bottom', ''),
  [ODIcons.CoreArrowCircleTop]: createIcon(IconSet.CoreUI, 'cil-arrow-circle-top', ''),
  [ODIcons.CoreMediaPause]: createIcon(IconSet.CoreUI, 'cil-media-pause', ''),
  [ODIcons.CoreMediaPlay]: createIcon(IconSet.CoreUI, 'cil-media-play', ''),
  [ODIcons.CoreReload]: createIcon(IconSet.CoreUI, 'cil-reload', ''),
  [ODIcons.CoreLink]: createIcon(IconSet.CoreUI, 'cil-link', ''),
  [ODIcons.CoreFolder]: createIcon(IconSet.CoreUI, 'cil-folder', ''),
  [ODIcons.CoreFolderOpen]: createIcon(IconSet.CoreUI, 'cil-folder-open', ''),
  [ODIcons.CoreBuilding]: createIcon(IconSet.CoreUI, 'cil-building', ''),
  [ODIcons.CoreAvTimer]: createIcon(IconSet.CoreUI, 'cil-av-timer', ''),
  [ODIcons.CoreShare]: createIcon(IconSet.CoreUI, 'cil-share', ''),
  [ODIcons.CoreGrid]: createIcon(IconSet.CoreUI, 'cil-grid', ''),
  [ODIcons.CoreGridSlash]: createIcon(IconSet.CoreUI, 'cil-grid-slash', ''),
  [ODIcons.CoreHospital]: createIcon(IconSet.CoreUI, 'cil-hospital', ''),
  [ODIcons.CoreEnvelopeLetter]: createIcon(IconSet.CoreUI, 'cil-envelope-letter', ''),
  [ODIcons.CoreCalendar]: createIcon(IconSet.CoreUI, 'cil-calendar', ''),
  [ODIcons.CoreCheckCircle]: createIcon(IconSet.CoreUI, 'cil-check-circle', ''),
  [ODIcons.CoreContact]: createIcon(IconSet.CoreUI, 'cil-contact', ''),
  [ODIcons.CoreClipboard]: createIcon(IconSet.CoreUI, 'cil-clipboard', ''),
  [ODIcons.CoreUserUnfollow]: createIcon(IconSet.CoreUI, 'cil-user-unfollow', ''),
  [ODIcons.CoreCalendarCheck]: createIcon(IconSet.CoreUI, 'cil-calendar-check', ''),
  [ODIcons.CoreHistory]: createIcon(IconSet.CoreUI, 'cil-history', ''),
  [ODIcons.CoreFile]: createIcon(IconSet.CoreUI, 'cil-file', ''),
  [ODIcons.CoreUser]: createIcon(IconSet.CoreUI, 'cil-user', ''),
  [ODIcons.CoreBed]: createIcon(IconSet.CoreUI, 'cil-bed', ''),
  [ODIcons.CoreSmoke]: createIcon(IconSet.CoreUI, 'cil-smoke', ''),
  [ODIcons.CoreBookmark]: createIcon(IconSet.CoreUI, 'cil-bookmark', ''),
  [ODIcons.CoreCreditCard]: createIcon(IconSet.CoreUI, 'cil-credit-card', ''),
  [ODIcons.CorePaperPlaneSend]: createIcon(IconSet.CoreUI, 'cil-paper-plane', ''),
  [ODIcons.CoreHighlighter]: createIcon(IconSet.CoreUI, 'cil-highlighter', ''),
  [ODIcons.CoreTerminal]: createIcon(IconSet.CoreUI, 'cil-terminal', ''),
  [ODIcons.CoreWarning]: createIcon(IconSet.CoreUI, 'cil-warning', ''),
  [ODIcons.CoreSpreadsheet]: createIcon(IconSet.CoreUI, 'cil-spreadsheet', ''),
  [ODIcons.CoreObjectGroup]: createIcon(IconSet.CoreUI, 'cil-object-group', ''),
  [ODIcons.CoreShieldAlt]: createIcon(IconSet.CoreUI, 'cil-shield-alt', ''),
  [ODIcons.MaterialClose]: createIcon(IconSet.Material, 'close', '', CloseIcon),
  [ODIcons.MaterialSettings]: createIcon(IconSet.Material, 'settings', '', SettingsIcon),
  [ODIcons.MaterialArrowLeft]: createIcon(IconSet.Material, 'arrow-left', '', KeyboardArrowLeftIcon),
  [ODIcons.MaterialArrowRight]: createIcon(IconSet.Material, 'arrow-right', '', KeyboardArrowRightIcon),
  [ODIcons.MaterialError]: createIcon(IconSet.Material, 'error', '', ErrorIcon),
  [ODIcons.MaterialFilter]: createIcon(IconSet.Material, 'filter', '', FilterIcon),
  [ODIcons.MaterialStar]: createIcon(IconSet.Material, 'star', '', StarIcon),
  [ODIcons.MaterialStarBorder]: createIcon(IconSet.Material, 'star-border', '', StarBorderIcon),
  [ODIcons.MaterialFileDownload]: createIcon(IconSet.Material, 'file_download', '', GetAppIcon),
  [ODIcons.MaterialFileUpload]: createIcon(IconSet.Material, 'file_upload', '', PublishIcon),
  [ODIcons.MaterialAddCircleOutlined]: createIcon(IconSet.Material, 'add_circle_outline', '', AddCircleOutlinedIcon),
  [ODIcons.MaterialArrowDropDownIcon]: createIcon(IconSet.Material, 'ArrowDropDown', '', ArrowDropDownIcon),
  [ODIcons.MaterialArrowUpwardIcon]: createIcon(IconSet.Material, 'ArrowUpward', '', ArrowUpwardIcon),
  [ODIcons.MaterialArrowDownwardIcon]: createIcon(IconSet.Material, 'ArrowDownward', '', ArrowDownwardIcon),
  [ODIcons.MaterialArrowForwardIcon]: createIcon(IconSet.Material, 'ArrowForward', '', ArrowForwardIcon),
  [ODIcons.MaterialArrowRightIcon]: createIcon(IconSet.Material, 'ArrowRight', '', ArrowRightIcon),
  [ODIcons.MaterialReport]: createIcon(IconSet.Material, 'Report', '', ReportIcon),
  [ODIcons.MaterialRefresh]: createIcon(IconSet.Material, 'Refresh', '', RefreshIcon),
  [ODIcons.MaterialPermMedia]: createIcon(IconSet.Material, 'perm_media', '', PermMediaIcon),
  [ODIcons.MaterialPlayArrow]: createIcon(IconSet.Material, 'play_arrow', '', PlayArrowIcon),
  [ODIcons.MaterialPause]: createIcon(IconSet.Material, 'pause', '', PauseIcon),
  [ODIcons.MaterialCreateNewFolder]: createIcon(IconSet.Material, 'create_new_folder', '', CreateNewFolderIcon),
  [ODIcons.MaterialViewList]: createIcon(IconSet.Material, 'view_list', '', ViewListIcon),
  [ODIcons.MaterialMic]: createIcon(IconSet.Material, 'mic', '', MicIcon),
  [ODIcons.MaterialMicOff]: createIcon(IconSet.Material, 'mic_off', '', MicOffIcon),
  [ODIcons.MaterialVideoCam]: createIcon(IconSet.Material, 'video_cam', '', VideoCamIcon),
  [ODIcons.MaterialVideoCamOff]: createIcon(IconSet.Material, 'video_cam_off', '', VideoCamOffIcon),
  [ODIcons.MaterialShareScreen]: createIcon(IconSet.Material, 'share_screen', '', ScreenShareIcon),
  [ODIcons.MaterialFullscreen]: createIcon(IconSet.Material, 'fullscreen', '', FullScreenIcon),
  [ODIcons.MaterialFullscreenExit]: createIcon(IconSet.Material, 'fullscreen_exit', '', FullScreenExitIcon),
  [ODIcons.MaterialCallEnd]: createIcon(IconSet.Material, 'call_end', '', CallEndIcon),
  [ODIcons.MaterialDesktopWindows]: createIcon(IconSet.Material, 'desktop_windows', '', DesktopWindowsIcon),
  [ODIcons.MaterialStopScreenShare]: createIcon(IconSet.Material, 'stop_screen_share', '', StopScreenShareIcon),
  [ODIcons.MaterialInfoOutline]: createIcon(IconSet.Material, 'info_outlined', '', InfoOutlinedIcon),
  [ODIcons.MaterialChevronRight]: createIcon(IconSet.Material, 'chevron_right', '', ChevronRight),
  [ODIcons.MaterialNotificationsActive]: createIcon(IconSet.Material, 'notifications_active', '', NotificationsActive),
  [ODIcons.MaterialAccountCircle]: createIcon(IconSet.Material, 'account_circle', '', AccountCircleIcon),
  [ODIcons.MaterialAddBox]: createIcon(IconSet.Material, 'account_circle', '', AddBoxIcon),
  [ODIcons.FontAwesomeStarOutlined]: createIcon(IconSet.FontAwesome, 'star-o', ''),
  [ODIcons.FontAwesomeStar]: createIcon(IconSet.FontAwesome, 'star', ''),
  [ODIcons.FontAwesomeArrowCircleRight]: createIcon(IconSet.FontAwesome, 'arrow-circle-right', ''),
  [ODIcons.SimpleLineIconsShareAlt]: createIcon(IconSet.SimpleLineIcons, 'share-alt', ''),
  [ODIcons.SimpleLineIconsFolder]: createIcon(IconSet.SimpleLineIcons, 'folder-alt', ''),
  [ODIcons.SVGIconHamburg]: createIcon(IconSet.SVGIcon, '', '', ODSVGHamburgMenu),
  [ODIcons.SVGIconCloseX]: createIcon(IconSet.SVGIcon, '', '', ODSVGCloseX),
  [ODIcons.SVGIconTwitter]: createIcon(IconSet.SVGIcon, '', '', ODSVGTwitter),
}

export interface IODIconProps {
  icon: ODIcons
  className?: string
  style?: object
  onClick?: () => void
  id?: string
}

export const getIconSetName = (icon: ODIcons) => {
  return IconsDefinition[icon].iconSet
}

export const ODIcon: React.FC<IODIconProps> = ({ id, icon, className = '', style = {}, onClick }) => {
  const { iconSet, code, name, node: Node } = IconsDefinition[icon]

  switch (iconSet) {
    case IconSet.ODCode:
      return (
        <i
          id={id}
          aria-hidden="true"
          data-icon={`&#x${code};`}
          className={classNames(className, `icon-od-menu-${name}`)}
          style={style}
          onClick={onClick}
        />
      )
    case IconSet.CoreUI:
      const cls = classNames(name, 'icons')
      return <i id={id} className={cls} style={style} onClick={onClick} />
    case IconSet.FontAwesome:
      return <i id={id} className={classNames('fa', `fa-${name}`)} onClick={onClick} style={style} />
    case IconSet.Material:
      return <Node id={id} style={style} onClick={onClick} />
    case IconSet.SimpleLineIcons:
      return <i id={id} className={classNames('icons', `icon-${name}`)} onClick={onClick} style={style} />
    case IconSet.SVGIcon: {
      return <Node id={id} onClick={onClick} className={className} />
    }
  }
}
