import { produce } from 'immer'
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { GQLListableTag, GQLListableTagInput, GQLOrg, GQLTag } from '../@types/server'
import { GQLSingleIDInput } from '../agent'
import { GQL_GET_ORG } from '../gqls'
import { SiteUrls } from '../urls'
import { Utils } from '../utils'
import { useODAppContext } from './ODAppContext'
import { useODQuery } from './ODCommon'

const GQL_LIST_TAG = `
query listTag($data: ListableTagInput!) {
  listTag(data: $data) {
    list {
      tagId
      name
      createdAt
    }
    totalCount
    page
    pageSize
  }
}`

type ODSingleOrgContextType = {
  state: ODSingleOrgReducerState
  orgId: number
  updateTags: () => Promise<void>
}

type ODSingleOrgContextProviderProps = {
  orgId: number
}

type ODSingleOrgReducerState = {
  loading: boolean
  orgProfile: GQLOrg | null
  tags: Array<GQLTag>
}

enum ODSingleOrgActionType {
  SetLoading = 'odApp/ODSingleOrgActionType/SetLoading',
  SetOrgProfile = 'odApp/ODSingleOrgActionType/SetOrgProfile',
  SetTags = 'odApp/ODSingleOrgActionType/SetTags',
}

type ODSingleOrgActionSetLoading = { type: ODSingleOrgActionType.SetLoading; loading: boolean }
type ODSingleOrgActionSetOrgProfile = { type: ODSingleOrgActionType.SetOrgProfile; orgProfile: GQLOrg }
type ODSingleOrgActionSetTags = { type: ODSingleOrgActionType.SetTags; tags: Array<GQLTag> }
type ODSingleOrgReducerAction = ODSingleOrgActionSetLoading | ODSingleOrgActionSetOrgProfile | ODSingleOrgActionSetTags

interface ODSingleOrgReducer extends React.Reducer<ODSingleOrgReducerState, ODSingleOrgReducerAction> {}

function createAppReducer(): ODSingleOrgReducer {
  return function(state: ODSingleOrgReducerState, action: ODSingleOrgReducerAction): ODSingleOrgReducerState {
    return produce(state, draft => {
      switch (action.type) {
        case ODSingleOrgActionType.SetLoading:
          draft.loading = action.loading
          break
        case ODSingleOrgActionType.SetOrgProfile:
          draft.loading = false
          draft.orgProfile = action.orgProfile
          break
        case ODSingleOrgActionType.SetTags:
          draft.tags = action.tags
          break
        default:
          return
      }
    })
  }
}

const actionSetLoading = (loading: boolean): ODSingleOrgActionSetLoading => ({
  type: ODSingleOrgActionType.SetLoading,
  loading,
})
const actionSetOrgProfile = (orgProfile: GQLOrg): ODSingleOrgActionSetOrgProfile => ({
  type: ODSingleOrgActionType.SetOrgProfile,
  orgProfile,
})
const actionSetTags = (tags: Array<GQLTag>): ODSingleOrgActionSetTags => ({
  type: ODSingleOrgActionType.SetTags,
  tags,
})

function createInitialAppReducerState(): ODSingleOrgReducerState {
  return {
    loading: false,
    orgProfile: null,
    tags: [],
  }
}

const ODSingleOrgContext: React.Context<ODSingleOrgContextType> = React.createContext<ODSingleOrgContextType>(
  {} as ODSingleOrgContextType
)

export const ODSingleOrgProvider: React.FC<ODSingleOrgContextProviderProps> = props => {
  const { orgId, children } = props

  const { appOptions } = useODAppContext()
  const simulateDelay = appOptions?.SIMULATE_DELAY || 0

  const apiGetOrg = useODQuery<GQLSingleIDInput, GQLOrg>(GQL_GET_ORG, simulateDelay)

  const [state, dispatch] = React.useReducer<ODSingleOrgReducer>(createAppReducer(), createInitialAppReducerState())
  const [redirection, setRedirection] = React.useState('')

  const loadOrgProfile = React.useCallback(
    async (orgId: number) => {
      dispatch(actionSetLoading(true))
      try {
        const res = await apiGetOrg({ id: orgId })
        dispatch(actionSetOrgProfile(res))
      } catch (ex) {
        Utils.showError('기관 정보를 찾을 수 없거나, 권한이 없습니다.', 'Error')
        setRedirection(SiteUrls.User.Root)
      }
    },
    [apiGetOrg]
  )

  const apiListTag = useODQuery<GQLListableTagInput, GQLListableTag>(GQL_LIST_TAG)
  const updateTags = React.useCallback(async () => {
    apiListTag({ page: 1, pageSize: 1000, filter: '', orgId, sortBy: null }).then(v => {
      dispatch(actionSetTags(v.list))
    })
  }, [apiListTag, orgId, dispatch])
  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    updateTags()
  }, [updateTags])

  React.useEffect(() => {
    loadOrgProfile(orgId).catch(Utils.showError)
  }, [loadOrgProfile, orgId])

  if (redirection) {
    return <Redirect to={redirection} />
  }

  const context: ODSingleOrgContextType = {
    state,
    orgId,
    updateTags,
  }
  return <ODSingleOrgContext.Provider value={context}>{children}</ODSingleOrgContext.Provider>
}

export function useODSingleOrgContext(): ODSingleOrgContextType {
  return React.useContext(ODSingleOrgContext)
}
