import { ODIcons } from '../components/ODIcon'
import { ODSiteNav } from '../context/ODCommon'
import { Strings } from '../i18n/res'
import { SiteUrls } from '../urls'

export const UserSiteNavsOfOrg = (
  orgId: string,
  isAdmin: boolean,
  canViewWorksetOfOrg: boolean,
  canViewCategoryOfOrg: boolean,
  canViewTagOfOrg: boolean,
  canViewOrgData: boolean,
  canViewAllProjects: boolean
): ODSiteNav => {
  const allOrgDataMenus = canViewOrgData
    ? [
        {
          name: 'All in Organization',
          url: SiteUrls.User.Org.Item.AllInOrg(orgId),
          icon: ODIcons.CoreLibraryAdd,
          iconSpanStyle: { position: 'relative', top: 2 },
        },
      ]
    : []

  const adminMenus = isAdmin
    ? [
        {
          name: Strings.GoToAdmin,
          url: SiteUrls.OrgAdmin.Root(orgId),
          icon: ODIcons.CoreSettings,
          class: 'nav-primary',
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 1 },
        },
      ]
    : []

  const adminSep =
    isAdmin && (canViewWorksetOfOrg || canViewTagOfOrg)
      ? [
          {
            name: '',
            url: '',
            isSeparator: true,
          },
        ]
      : []

  // project Nav Item

  const project = [
    {
      name: Strings.Project,
      url: SiteUrls.User.Org.Project.Main(orgId),
      icon: ODIcons.CoreFile,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 2 },
    },
  ]

  const allProjects = canViewAllProjects
    ? [
        {
          name: Strings.AllProjects,
          url: SiteUrls.User.Org.Project.Org(orgId),
          icon: ODIcons.CoreFile,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 2 },
        },
      ]
    : []

  // workset Group Nav Item
  const worksetGroup = [
    {
      name: Strings.WorksetGroup,
      url: SiteUrls.User.Org.WorksetGroup.main(orgId),
      icon: ODIcons.CoreObjectGroup,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 2 },
    },
  ]

  const worksetAdd = canViewWorksetOfOrg
    ? [
        {
          name: 'All Worksets',
          url: SiteUrls.User.Org.Workset.Org(orgId),
          icon: ODIcons.CoreViewModule,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 2 },
        },
      ]
    : []

  const categoryAdd = canViewCategoryOfOrg
    ? [
        {
          name: 'Categories',
          url: SiteUrls.User.Org.Category.Main(orgId),
          icon: ODIcons.CoreList,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 2 },
        },
      ]
    : []

  const tagAdd = canViewTagOfOrg
    ? [
        {
          name: 'Tags',
          url: SiteUrls.User.Org.Tag.Main(orgId),
          icon: ODIcons.CoreTags,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 2 },
        },
      ]
    : []

  const sepAdd =
    categoryAdd || tagAdd
      ? [
          {
            name: '',
            url: '',
            isSeparator: true,
          },
        ]
      : []

  return {
    items: [
      // {
      //   name: Strings.Local,
      //   url: SiteUrls.User.Org.Item.Local(orgId),
      //   icon: ODIcons.CoreLibrary,
      //   iconSpanStyle: { position: 'relative', top: 2 },
      // },
      {
        name: Strings.ItemsAll,
        url: SiteUrls.User.Org.Item.All(orgId),
        icon: ODIcons.CoreLibrary,
        iconSpanStyle: { position: 'relative', top: 2 },
      },
      ...allOrgDataMenus,
      {
        name: Strings.DirectoryView,
        url: SiteUrls.User.Org.DirectoryView.Main(orgId),
        icon: ODIcons.CoreFolderOpen,
        iconSpanStyle: { position: 'relative', top: 2 },
      },
      {
        name: Strings.Workset,
        url: SiteUrls.User.Org.Workset.Main(orgId),
        icon: ODIcons.CoreViewModule,
        iconStyle: { fontSize: '1rem' },
        iconSpanStyle: { position: 'relative', top: 2 },
      },
      ...worksetAdd,
      ...worksetGroup,
      ...project,
      ...allProjects,
      {
        name: '',
        url: '',
        isSeparator: true,
      },
      {
        name: 'Agent',
        url: SiteUrls.User.Org.Sync.Main(orgId),
        icon: ODIcons.CoreSync,
        iconSpanStyle: { position: 'relative', top: 2 },
      },
      {
        name: Strings.Favorite,
        url: SiteUrls.User.Org.Favorite.Main(orgId),
        icon: ODIcons.CoreStar,
        iconStyle: { fontSize: '1rem' },
        iconSpanStyle: { position: 'relative', top: 2 },
      },
      {
        name: 'Workset Favorites',
        url: SiteUrls.User.Org.Workset.Favorites(orgId),
        icon: ODIcons.CoreStar,
        iconStyle: { fontSize: '1rem' },
        iconSpanStyle: { position: 'relative', top: 2 },
      },
      ...sepAdd,
      ...categoryAdd,
      ...tagAdd,
      ...adminSep,
      ...adminMenus,
    ],
  }
}
