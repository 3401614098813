import React, { useState } from 'react'
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import styled from 'styled-components'
import { ODColors } from '../../global-styles'
import { useDetectOutsideClick } from '../../utils/useDetectOutsideClick'
import { ODIcon, ODIcons } from '../ODIcon'

const Text = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #29b6ca;
  margin-top: 4px;
`

export const NoResultDropDown: React.FC = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 109 }}
    >
      <ODIcon icon={ODIcons.CoreFrown} style={{ fontSize: 26, color: '#29b6ca' }} />
      <Text>No Result</Text>
    </div>
  )
}

export type DropdownMenu<T> = {
  name: string
  value: T
  key: string | number
}

export type ODMultiSelectDropdownProps<T> = {
  isOpen?: boolean
  setIsOpen?: (open: boolean) => void
  items: Array<DropdownMenu<T>>
  checkedItems: Array<T>
  setCheckedItems: (newArr: Array<T>) => void
  disabled?: boolean
  placeholder?: string
  title: string
}

export type DropButtonStyleProps = {
  disabled: boolean
}

const DropButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 14px;
  padding-right: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: ${(props: DropButtonStyleProps) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props: DropButtonStyleProps) => (props.disabled ? 0.6 : 1.0)};
  text-align: left;
`
const DropDown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`
const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 231px;
  min-height: 50px;
  max-height: 2400px;
  overflow-y: auto;
  border: solid 1px #c8ced3;
  z-index: 1001;
  flex-direction: column;
  padding: 8px;
`
const Label = styled.label`
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`
const SelectBox = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #29b6ca;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const SelectBoxText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #29b6ca;
`

export function ODMultiSelectDropdown<T>(props: ODMultiSelectDropdownProps<T>) {
  const {
    isOpen: _isOpen,
    setIsOpen: _setIsOpen,
    items,
    checkedItems,
    setCheckedItems,
    disabled = false,
    placeholder = '',
    title,
  } = props

  const [keyword, setKeyword] = useState<string>('')
  const menuRef = React.useRef<HTMLDivElement>(null)
  const [isOpen2, setIsOpen2] = React.useState(false)

  const isOpen = _isOpen !== undefined ? _isOpen : isOpen2
  const setIsOpen = _isOpen !== undefined ? _setIsOpen : setIsOpen2

  useDetectOutsideClick(() => setIsOpen?.(false), menuRef, isOpen)

  const filteredItems = items.filter(i => i.name.toLowerCase().includes(keyword.toLowerCase()))

  function setItemChecked(item: DropdownMenu<T>, checked: boolean) {
    if (checked) {
      setCheckedItems([...checkedItems, item.value])
    } else {
      setCheckedItems(checkedItems.filter(v => v !== item.value))
    }
  }

  return (
    <DropDown ref={menuRef}>
      <DropButton disabled={disabled} onClick={() => !disabled && (isOpen ? setIsOpen?.(false) : setIsOpen?.(true))}>
        <span>{title}</span>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: ODColors.DropdownArrow, fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        <FormGroup>
          <InputGroup size="normal">
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ backgroundColor: 'transparent', borderRightColor: 'transparent' }}>
                <ODIcon icon={ODIcons.CoreSearch} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder={placeholder}
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
              style={{ borderLeftColor: 'transparent' }}
            />
          </InputGroup>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 12,
              flex: 1,
            }}
          >
            <SelectBox style={{ border: 'solid 1px #f86c6b' }} onClick={() => setCheckedItems([])}>
              <SelectBoxText style={{ color: '#f86c6b' }}>Deselect All</SelectBoxText>
            </SelectBox>
          </div>
        </FormGroup>
        {filteredItems.length === 0 ? (
          <NoResultDropDown />
        ) : (
          filteredItems.map(item => {
            const isItemSelected = checkedItems.indexOf(item.value) >= 0

            return (
              <Label key={item.key} style={{ marginBottom: 14 }}>
                <input
                  type="checkbox"
                  style={{ marginRight: 17 }}
                  onChange={() => setItemChecked(item, !isItemSelected)}
                  checked={isItemSelected}
                />
                {item.name}
              </Label>
            )
          })
        )}
      </DropDownContent>
    </DropDown>
  )
}
