import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useODAppContext } from '../../context/ODAppContext'
import { SiteUrls } from '../../urls'

type UserDashboardContainerProps = {}

export const UserDashboardContainer: React.FC<UserDashboardContainerProps> = props => {
  const {
    state: { userProfile },
  } = useODAppContext()

  const { organizations, mainOrgId } = userProfile!

  if (mainOrgId) {
    return <Redirect to={SiteUrls.User.Org.Root(mainOrgId)} />
  }

  return (
    <div style={{ marginTop: 100, padding: 100 }}>
      Your organizations
      <hr />
      {organizations.map(o => {
        return (
          <div key={o.org.orgId}>
            <Link to={SiteUrls.User.Org.Root(o.org.orgId.toString())}>{o.org.name}</Link>
          </div>
        )
      })}
    </div>
  )
}
