import styled from 'styled-components'
import { ODColors } from '../../global-styles'

export const DropButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 14px;
  padding-right: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  text-align: left;
`
export const DropDown = styled.div`
  position: relative;
  display: inline-block;
`
export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  width: 150px;
  min-height: 50px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
  flex-direction: column;
`
export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 41px;
  width: 100%;
  padding-left: 14px;
  cursor: pointer;
  border-bottom: solid 1px #c8ced3;

  &:hover {
    background-color: ${ODColors.Primary};
    & > span,
    & > span > span,
    & > span > svg {
      color: #ffffff !important;
    }
  }
`
export const Value = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
  cursor: pointer;
`
