"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.urlOnly = exports.RESTUrl = void 0;
/**
 * 서비스에서 rest api 형태로 구현된 핸들러들의 주소를 정의한다.
 */

exports.RESTUrl = {
  serveFile: function serveFile(storageName, hashId) {
    return "/files/".concat(storageName, "/").concat(hashId);
  },
  loginInfo: function loginInfo() {
    return "/loginInfo";
  },
  CILSAgent: {
    thumbnail: function thumbnail(dataId) {
      return "/thumbnail/".concat(dataId);
    },
    itemInfo: function itemInfo(infoId) {
      return "/api/item/infoId/".concat(infoId);
    },
    agentOwners: function agentOwners() {
      return "/api/owners";
    },
    loginUser: function loginUser() {
      return "/api/loginUser";
    },
    listWorkset: function listWorkset() {
      return "/api/worksets";
    },
    listWorksetHasItemMetadata: function listWorksetHasItemMetadata(wsId) {
      return "/api/workset/".concat(wsId, "/meta");
    },
    listItemInWorkset: function listItemInWorkset(wsId) {
      return "/api/workset/".concat(wsId, "/items");
    },
    getCILSServerItem: function getCILSServerItem(dataId) {
      return "/api/item/".concat(dataId);
    },
    downloadItem: function downloadItem() {
      return "/api/download/item";
    },
    listDownloadItems: function listDownloadItems() {
      return "/api/download/items";
    },
    cancelDownload: function cancelDownload() {
      return "/api/download/item/cancel";
    },
    changeDownloadPriority: function changeDownloadPriority() {
      return "/api/download/priority/change";
    },
    pauseDownloadingItem: function pauseDownloadingItem() {
      return "/api/download/pause";
    },
    resumePausedItem: function resumePausedItem() {
      return "/api/download/resume";
    },
    getTCFPreview: function getTCFPreview(dataId) {
      return "/api/item/preview/".concat(dataId);
    },
    getMyProfile: function getMyProfile() {
      return "/api/me";
    }
  },
  Project: {
    projects: function projects() {
      return "/projects";
    },
    listItemExecution: function listItemExecution(projectId) {
      return "/project/".concat(projectId, "/itemExecutions");
    },
    itemExecution: function itemExecution(ieId) {
      return "/project/itemExecution/".concat(ieId);
    },
    startExecution: function startExecution(userType) {
      return "/project/".concat(userType, "/startExecution");
    },
    updateExecution: function updateExecution(userType) {
      return "/project/".concat(userType, "/updateExecution");
    },
    prepareExecution: function prepareExecution(userType) {
      return "/project/".concat(userType, "/prepareExecution");
    },
    onExecutionReady: function onExecutionReady(userType) {
      return "/project/".concat(userType, "/onExecutionReady");
    },
    finishExecution: function finishExecution(userType) {
      return "/project/".concat(userType, "/finishExecution");
    },
    listItemExecutionResultMetaData: function listItemExecutionResultMetaData(projectId) {
      return "/project/".concat(projectId, "/itemExecutions/results/meta");
    },
    itemExecutionResultMetaData: function itemExecutionResultMetaData(projectId, ieId) {
      return "/project/".concat(projectId, "/itemExecution/").concat(ieId, "/results/meta");
    },
    onExecution: function onExecution(projectId) {
      return "/project/".concat(projectId, "/onExecution");
    },
    projectMetaData: function projectMetaData(projectId) {
      return "/project/".concat(projectId, "/meta");
    },
    scanOutput: function scanOutput() {
      return "/project/result/scan";
    }
  },
  linkForItemByDataId: function linkForItemByDataId(dataId) {
    return "/itemByDataId/".concat(dataId);
  }
};
/**
 * RESTUrl 에서 정의된 url 에서 query 부분을 제외한 부분만 반환한다.
 * 통상 express 에서 handler 를 mount 하기 위해 사용된다.
 */

exports.urlOnly = function (url) {
  return url.split('?')[0];
};