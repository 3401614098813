import { observer } from 'mobx-react-lite'
import React from 'react'
import { FlexContentsContainer } from '../../../../components/FlexContentsContainer'
import { useAPIs } from '../../../../context/useAPIs'
import { SingleQueryWrapper } from '../../../../context/useSingleQueryAPI'
import { WorksetGroupHasWorksetListableTable } from '../list/WorksetGroupHasWorksetListableTable'

interface IWorksetGroupDetailContainer {
  worksetGroupId: number
  orgId: number
}

export const WorksetGroupDetailContainer: React.FC<IWorksetGroupDetailContainer> = observer(props => {
  const { worksetGroupId, orgId } = props
  const { getWorksetGroup } = useAPIs()

  return (
    <FlexContentsContainer>
      <SingleQueryWrapper deps={[worksetGroupId]} getter={() => getWorksetGroup({ id: worksetGroupId })}>
        {({ data }) => {
          return (
            <WorksetGroupHasWorksetListableTable wsgId={worksetGroupId} worksetGroupName={data.name} orgId={orgId} />
          )
        }}
      </SingleQueryWrapper>
    </FlexContentsContainer>
  )
})
