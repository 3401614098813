/**
 * ODListablePaginatedTable 은 ODContext 를 이용하여 동작하는 페이징이 가능한 테이블
 * 컴포넌트이다.
 *
 * (see ODListable.stories.tsx)
 */
import { IODRendererContext, ODRendererEvent } from '@odc/odbelt'
import React, { ReactNode } from 'react'
import { Table } from 'reactstrap'
import {
  IODListableEventContext,
  ODListableContextType,
  ODListableReducerState,
  ODListableOption,
} from '@odc/od-react-belt'

interface IProps<T, O extends ODListableOption, ParentContextType extends IODListableEventContext<T>> {
  numColumns: number
  renderHeader: () => ReactNode
  renderRow: (value: T, parentContext: IODRendererContext<T, ParentContextType>) => ReactNode
  // fields: ODListableTableDefinition<T, O, ParentContextType>
  listableContext: React.Context<ODListableContextType<T, O>>
  renderLoading?: (state: ODListableReducerState<T, O>) => React.ReactNode
  renderEmpty?: (state: ODListableReducerState<T, O>) => React.ReactNode
  // tableClassName?: string
  // children: ReactNode
  // onEvent?: (event: any, parentContext: ParentContextType) => void // event from column definition
  onEvent?: (event: ODRendererEvent<T>, parentContext: ParentContextType) => void
  eventParentContext: ParentContextType
}

const EmptyRow = (props: { colSpan: number; children: ReactNode }) => {
  return (
    <tr>
      <td colSpan={props.colSpan}>{props.children}</td>
    </tr>
  )
}

export function ODListablePaginatedTable2<
  T,
  O extends ODListableOption,
  ParentContextType extends IODListableEventContext<T>
>(props: IProps<T, O, ParentContextType>) {
  const {
    numColumns,
    renderHeader,
    renderRow,
    listableContext,
    renderEmpty,
    onEvent = () => null,
    eventParentContext,
  } = props
  const [rendererContext] = React.useState<IODRendererContext<T, ParentContextType>>(() => ({
    onEvent,
    parentContext: eventParentContext,
  }))

  const { state, keyExtractor }: ODListableContextType<T, O> = React.useContext(listableContext)
  const { loading, list } = state

  if (loading) {
    return <>Loading...</>
  }

  return (
    <Table responsive striped style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}>
      <thead>{renderHeader()}</thead>
      <tbody>
        {list.length > 0 && list.map(v => renderRow(v, rendererContext))}
        {list.length === 0 && <EmptyRow colSpan={numColumns}>{renderEmpty?.(state) ?? 'No data.'}</EmptyRow>}
      </tbody>
    </Table>
  )
}
