import { ODListableContextType, ODListableOption } from '@odc/od-react-belt'
import React from 'react'
import { Col, Label, Row } from 'reactstrap'
import { useCILSCategory, useWorksetGroupList } from '../../context/ODCILSHooks'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { FilterButton } from '../ODImageGrid/FilterButton'

interface Filter {
  key: string
  name: string
  onRemove: (v: Filter) => void
  color: string
  isRemove: boolean
}

interface CategoryFilter extends Filter {
  categoryId: number
  name: string
}

interface IODActiveFilter<T, O> {
  listableContext: React.Context<ODListableContextType<T, O>>
}

// 추후 추가 될 ListableOption 을 추가
interface IListableOption extends ODListableOption {}

export function ODActiveFilter<T, O extends IListableOption>(props: IODActiveFilter<T, O>) {
  const { listableContext } = props
  const { orgId } = useODSingleOrgContext()
  const { categories } = useWorksetGroupList(orgId)

  const { updateLoadOption, state } = React.useContext(listableContext)

  const { worksetGroupId } = state.loadOption

  const removeCategory = () => {
    updateLoadOption({ ...state.loadOption, worksetGroupId: null })
  }

  const removeAllFilters = () => {
    updateLoadOption({
      ...state.loadOption,
      worksetGroupId: null,
    })
  }

  const categoryFilter = (() => {
    if (!worksetGroupId) {
      return null
    }

    const findItem = categories.find(category => category.categoryId === worksetGroupId)
    if (findItem) {
      return {
        categoryId: findItem.categoryId,
        name: findItem.label,
        key: `category_${findItem.categoryId}`,
        onRemove: () => removeCategory(),
        color: '#e07442',
        isRemove: true,
      }
    } else {
      return null
    }
  })()

  if (!categoryFilter) {
    return null
  }

  return (
    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Col
        md={12}
        className="vcenter"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            width: 101,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginRight: 20,
          }}
        >
          <Label htmlFor="select" style={{ fontWeight: 'bold' }}>
            Active Filters
          </Label>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
          <FilterButton
            isClearAll={false}
            color={categoryFilter.color}
            key={categoryFilter.key}
            label={categoryFilter.name}
            onRemove={() => categoryFilter.onRemove()}
            isRemove={categoryFilter.isRemove}
          />

          <FilterButton isRemove isClearAll color="#f87170" label="Clear All" onRemove={() => removeAllFilters()} />
        </div>
      </Col>
    </Row>
  )
}
