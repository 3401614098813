import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../../../components/ODIcon'

type AddAttachmentBoxProps = {
  onClick: () => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 55px;
  border: dotted 1px #29b6ca;
  background-color: #e9f7f9;
  padding-left: 19px;
  margin-bottom: 22px;
  cursor: pointer;
`

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #28b5c9;
  background-color: #c2e9ef;
`

const BigText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #29b6ca;
  margin-left: 20px;
`

const SmallText = styled.div`
  font-size: 12px;
  color: #73818f;
  margin-left: 15px;
`

export const AddAttachmentBox: React.FC<AddAttachmentBoxProps> = props => {
  const { onClick } = props
  return (
    <Wrapper onClick={onClick}>
      <IconWrapper>
        <ODIcon icon={ODIcons.CoreHighlighter} />
      </IconWrapper>
      <BigText>Add Attachment</BigText>
      <SmallText>Click or Drop files here to upload attachment</SmallText>
    </Wrapper>
  )
}
