import { DODirectoryNode, DODirectoryNodeToggleArrowState } from '@cils/common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../ODIcon'
import { DirectoryCheckbox } from './DirectoryCheckbox'

interface IDirectoryItemProps {
  node: DODirectoryNode
}

const DirectoryToggleButton: React.FC<{ node: DODirectoryNode }> = observer(props => {
  const { node } = props
  if (node.toggleButtonState === DODirectoryNodeToggleArrowState.DoNotShow) {
    return <div style={{ width: 20 }} />
  }

  return (
    <ODIcon
      icon={
        node.toggleButtonState === DODirectoryNodeToggleArrowState.Opened
          ? ODIcons.MaterialArrowDropDownIcon
          : ODIcons.MaterialArrowRightIcon
      }
      style={{ color: '#73818f', fontSize: 16, marginRight: 4, cursor: 'pointer' }}
      onClick={() => node.toggleOpen()}
    />
  )
})

export const DirectoryItem: React.FC<IDirectoryItemProps> = observer(props => {
  const { node } = props
  const { depth, isSelected, text } = node
  const isChecking = node.store.isCheckMode

  if (node.isCollapsedChild) {
    return null
  }

  return (
    <ItemWrapper depth={depth} isSelected={isSelected}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: isSelected ? 11.4 : 'none',
          border: isSelected ? 'solid 1px #29b6ca' : 'none',
          paddingRight: isSelected ? 9 : 0,
        }}
      >
        <DirectoryToggleButton node={node} />
        {isChecking && <DirectoryCheckbox isChecked={node.isChecked} onClick={() => node.toggleChecked()} />}
        <ODIcon
          icon={isSelected ? ODIcons.CoreFolderOpen : ODIcons.CoreFolder}
          style={{
            color: '#29b6ca',
            fontSize: 12,
            marginRight: 7,
          }}
        />
        <ItemText
          depth={depth}
          isSelected={isSelected}
          onClick={() => node.selectPath()}
          onDoubleClick={() => node.toggleOpen()}
        >
          {text}
        </ItemText>
      </div>
    </ItemWrapper>
  )
})

type ItemProps = {
  depth: number
  isSelected?: boolean
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${(props: ItemProps) => props.depth * 20 + 14}px;
  min-height: 30px;
  background-color: ${(props: ItemProps) => (props.isSelected ? '#dcecee' : 'transparent')};
`
const ItemText = styled.span`
  font-size: 11px;
  font-weight: ${(props: ItemProps) => (props.depth === 0 || props.isSelected ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: ${(props: ItemProps) => (props.isSelected ? '#2e343a' : '#73818f')};
  cursor: pointer;
`
