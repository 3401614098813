import { IMAGE_SORT_OPTION } from '@cils/common'
import React, { useState } from 'react'
import styled from 'styled-components'
import { GQLTag } from '../@types/server'
import { TagDataLoaderOption } from '../containers/User/TagContainer'
import { ODColors } from '../global-styles'
import { ODListableContextType } from '../ODListable/ODListableContext'
import { useDetectOutsideClick } from '../utils/useDetectOutsideClick'
import { ODIcon, ODIcons } from './ODIcon'
import { DROPDOWN_MENU, DropDownMenuProps } from './ODImageGrid/ODImageGridSearchToolBar'

const DropButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 14px;
  padding-right: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  text-align: left;
`
const DropDown = styled.div`
  position: relative;
  display: inline-block;
`
const DropDownContent = styled.div`
  display: none;
  position: absolute;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  width: 150px;
  min-height: 50px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
  flex-direction: column;
`
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 41px;
  width: 100%;
  padding-left: 14px;
  cursor: pointer;
  border-bottom: solid 1px #c8ced3;

  &:hover {
    background-color: ${ODColors.Primary};
    & > span,
    & > span > span,
    & > span > svg {
      color: #ffffff !important;
    }
  }
`
const Value = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
  cursor: pointer;
`

const SortByName: { [key: string]: JSX.Element } = {
  Default: (
    <>
      <Value>Tag Created</Value>
    </>
  ),
  [IMAGE_SORT_OPTION.CreatedDateAscending]: (
    <Value>
      Tag Created <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [IMAGE_SORT_OPTION.CreatedDateDescending]: (
    <Value>
      Tag Created <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [IMAGE_SORT_OPTION.NameAscending]: (
    <Value>
      Title <span style={{ color: '#2f353a' }}>A</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
      <span style={{ color: '#2f353a' }}>Z</span>
    </Value>
  ),
  [IMAGE_SORT_OPTION.NameDescending]: (
    <Value>
      Title <span style={{ color: '#2f353a' }}>Z</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
      <span style={{ color: '#2f353a' }}>A</span>
    </Value>
  ),
}

type Props = {
  listableContext: React.Context<ODListableContextType<GQLTag, TagDataLoaderOption>>
}

export const SortByTagDropdown: React.FC<Props & DropDownMenuProps> = props => {
  const { isMenuOpen, setIsMenuOpen, listableContext } = props

  const [value, setValue] = useState<string>('Default')
  const menuRef = React.useRef<HTMLDivElement>(null)
  const isOpen = isMenuOpen === DROPDOWN_MENU.SortByTag

  const {
    state: { loadOption },
    updateLoadOption,
  } = React.useContext(listableContext)

  useDetectOutsideClick(() => setIsMenuOpen(null), menuRef, isOpen)

  const onClick = (v: IMAGE_SORT_OPTION) => {
    // @ts-ignore
    updateLoadOption({ ...loadOption, sortBy: v })
    setValue(v)
    setIsMenuOpen(null)
  }

  return (
    <DropDown ref={menuRef}>
      <DropButton onClick={() => (isOpen ? setIsMenuOpen(null) : setIsMenuOpen(DROPDOWN_MENU.SortByTag))}>
        {SortByName[value]}
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        <OptionWrapper onClick={() => onClick(IMAGE_SORT_OPTION.CreatedDateAscending)}>
          <Value>
            Tag Created <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(IMAGE_SORT_OPTION.CreatedDateDescending)}>
          <Value>
            Tag Created <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(IMAGE_SORT_OPTION.NameAscending)}>
          <Value>
            Title <span style={{ color: '#2f353a' }}>A</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
            <span style={{ color: '#2f353a' }}>Z</span>
          </Value>
        </OptionWrapper>
        <OptionWrapper style={{ borderBottom: 'none' }} onClick={() => onClick(IMAGE_SORT_OPTION.NameDescending)}>
          <Value>
            Title <span style={{ color: '#2f353a' }}>Z</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
            <span style={{ color: '#2f353a' }}>A</span>
          </Value>
        </OptionWrapper>
      </DropDownContent>
    </DropDown>
  )
}
