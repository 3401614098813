import { isString } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Utils } from '../utils'

type LastLoginTokenProps = {
  dateToken: string | Date
}

export const LastLoginToken: React.FC<LastLoginTokenProps> = ({ dateToken }) => {
  let time: moment.Moment
  if (isString(dateToken) && dateToken.length === 'YYYY-MM-DD'.length) {
    time = moment(dateToken, 'YYYY-MM-DD').startOf('day')
  } else {
    time = moment(dateToken)
  }

  const color = Utils.colorsForDecay(time.diff(moment(), 'day'))
  return <span style={{ color }}>{time.fromNow()}</span>
}
