import { useTimer } from '@odc/od-react-belt'
import { Decimal } from 'decimal.js'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import styled from 'styled-components'
import { GQLProgressWithWorkset, GQLUploadOrDownloadInfo } from '../../../agent'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { ODButton, ODButtonSize, ODButtonTheme } from '../../../components/ODButton'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { Utils } from '../../../utils'
import { AgentProgressStatus } from './AgentProgressStatus'
import { useAgentProgressDataAPI } from './common'

interface IAgentDownloadProgressFilteredListProps {
  itemId: number
  dataId: string
  onClose: () => void
  setRequiresSelection: (requiresSelection: boolean) => void
  setTomoAnalysisQueued: () => void
}

interface IAgentProgressListableOption extends ODListableOption {
  filter: string | null
}

export type ItemWithParent = GQLUploadOrDownloadInfo & { parent?: GQLProgressWithWorkset }
const { Provider, Context } = createODListableContext<ItemWithParent, IAgentProgressListableOption>()

const TitleText = styled.span`
  max-width: 200px;
  font-size: 14px;
  color: #708393;
`

export const AgentDownloadProgressFilteredList: React.FC<IAgentDownloadProgressFilteredListProps> = props => {
  const { itemId, dataId, onClose, setRequiresSelection, setTomoAnalysisQueued } = props

  const { queueDownload, openTomoAnalysis } = useCILSAgentContext()
  const refreshToken = useTimer(1000)
  const apiList = useAgentProgressDataAPI()
  const [items, setItems] = React.useState<ItemWithParent[] | null>(null)
  const [queued, setQueued] = React.useState(false)
  const [opened, setOpened] = React.useState(false)
  const [selected, setSelected] = React.useState('')

  React.useEffect(() => {
    if (items?.length === 0 && !queued) {
      setQueued(true)
      // noinspection JSIgnoredPromiseFromCall
      queueDownload({ dataId })
      // noinspection JSIgnoredPromiseFromCall
      Utils.showSuccess('Download queued.')
      return
    }

    if (items?.length === 1) {
      if (items![0].progressStatus !== 'Deleted') {
        if (!opened) {
          // noinspection JSIgnoredPromiseFromCall
          openTomoAnalysis({ infoId: items![0].infoId })
          Utils.showSuccess('TomoAnalysis will open the file.')
          setTomoAnalysisQueued()
          setOpened(true)
        }

        if (items![0].progressStatus === 'Finished') {
          onClose()
        }
        return
      }
    }

    if ((items?.length ?? 0) > 0) {
      if (!selected) {
        setRequiresSelection(true)
      }
    }
  }, [
    items,
    dataId,
    selected,
    onClose,
    openTomoAnalysis,
    opened,
    queueDownload,
    queued,
    setRequiresSelection,
    setTomoAnalysisQueued,
  ])

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IAgentProgressListableOption
    ): Promise<ODListableResponseType<ItemWithParent>> {
      const r = await apiList({
        page: 1,
        pageSize: 100,
        isDownload: true,
        sortOrder: null,
      })
      const list: ItemWithParent[] = []

      const filterItem = selected
        ? (i: GQLUploadOrDownloadInfo) => i.progressStatus !== 'Deleted' && i.itemFolderName === selected
        : (i: GQLUploadOrDownloadInfo) => i.progressStatus !== 'Deleted' && i.itemId === itemId

      r.list.forEach(p => {
        if (p.isWorkset) {
          p.worksetProgress?.childItems?.forEach(i => {
            if (filterItem(i)) {
              list.push({ ...i, parent: p })
            }
          })
        } else {
          if (filterItem(p.itemProgress!)) {
            list.push(p.itemProgress!)
          }
        }
      })

      setItems(list)

      return { list, page: 1, pageSize: 100, totalCount: list.length }
    },
    [apiList, itemId, selected]
  )

  const requiresToSelect = (items?.length ?? 0) > 1 && !selected

  const TableDefinition: ODListableTableDefinition<ItemWithParent, IAgentProgressListableOption> = [
    {
      id: 'title',
      title: 'Title',
      transform: v => {
        return <TitleText>{v.title ?? 'Unnamed'}</TitleText>
      },
      thClass: 'text-left width-200',
      className: 'text-left user-td vcenter',
    },
    {
      id: 'dataId',
      title: 'Data ID',
      transform: v => v.title ?? '',
      thClass: 'text-left width-200',
      className: 'text-left user-td vcenter',
    },
    {
      id: 'size',
      title: 'Size',
      transform: v =>
        `${prettyBytes(new Decimal(v.finishedSize ?? '0').toNumber())} / ${prettyBytes(
          new Decimal(v.size ?? '0').toNumber()
        )}`,
      thClass: 'text-left width-200',
      className: 'text-left user-td vcenter',
    },
    {
      id: 'status',
      title: 'Status',
      transform: v => {
        const wrapped: GQLProgressWithWorkset = {
          infoId: v.infoId,
          isWorkset: false,
          itemFolderName: v.itemFolderName,
          itemProgress: v,
          priority: 0,
          worksetProgress: null,
          isProject: v.parent?.isProject ?? false,
        }
        return (
          <div style={{ padding: '0 10px 0 0' }}>
            <AgentProgressStatus item={wrapped} isDownload />
          </div>
        )
      },
      thClass: 'text-left',
      className: 'text-center vcenter',
    },
    {
      id: 'location',
      title: 'File Location',
      transform: v => {
        return <div style={{ fontSize: 10, padding: '0 10px 0 0' }}>{v.itemFolderName}</div>
      },
      thClass: 'text-left',
      className: 'text-left vcenter',
    },
    ...(requiresToSelect
      ? [
          {
            id: 'select',
            title: 'Select',
            transform: (v: ItemWithParent) => {
              return (
                <ODButton
                  theme={ODButtonTheme.Primary}
                  size={ODButtonSize.XSmall}
                  style={{ borderRadius: 2, paddingLeft: 12, paddingRight: 12 }}
                  onClick={() => setSelected(v.itemFolderName)}
                >
                  Select
                </ODButton>
              )
            },
            thClass: 'text-left',
            className: 'text-left vcenter',
          },
        ]
      : []),
  ]

  return (
    <FlexContentsContainer>
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v.itemFolderName.toString()}
        pageSize={10}
        onDataLoaderError={Utils.showError}
        refreshToken={refreshToken.toString()}
        searchOnLoad
      >
        <ODListablePaginatedTable
          fields={TableDefinition}
          listableContext={Context}
          renderLoading={() => 'Loading..'}
          renderEmpty={() => 'No items found.'}
        />
      </Provider>
    </FlexContentsContainer>
  )
}
