import {
  GQLAssignItemExecutionToUserInput,
  GQLCheckProjectHasUserInput,
  GQLCheckProjectHasUserResponse,
  GQLConfirmItemExecutionsInput,
  GQLDualIDInput,
  GQLItem,
  GQLListableAlert,
  GQLListableAlertInput,
  GQLListableAudit,
  GQLListableAuditInput,
  GQLListableItemExecution,
  GQLListableItemExecutionInput, GQLListableItemExecutionResult, GQLListableItemExecutionResultInput,
  GQLListableOrg,
  GQLListableOrgInput,
  GQLListableProject,
  GQLListableProjectHasUser,
  GQLListableProjectHasUserInput,
  GQLListableProjectHasWorkset,
  GQLListableProjectHasWorksetInput,
  GQLListableProjectInOrgInput,
  GQLListableProjectInput,
  GQLListableProjectMetaDataPreset,
  GQLListableProjectMetaDataPresetInput,
  GQLListableWorkset,
  GQLListableWorksetGroup,
  GQLListableWorksetGroupHasWorkset,
  GQLListableWorksetGroupHasWorksetInOrgInput,
  GQLListableWorksetGroupHasWorksetInput,
  GQLListableWorksetGroupInput,
  GQLListableWorksetInput,
  GQLOrg,
  GQLOrgHasUser,
  GQLProject,
  GQLProjectHasUserRemoveInput,
  GQLProjectHasWorksetPropsInput,
  GQLProjectMetaData,
  GQLProjectMetaDataPreset,
  GQLProjectMetaDataPresetCreationInput,
  GQLProjectMetaDataPresetUpdateInput,
  GQLProjectMetaDataUpdateInput,
  GQLProjectPropsInput,
  GQLProjectRemoveInput,
  GQLResetPasswordResponse,
  GQLSingleIDInput,
  GQLUnlockItemExecutionInput,
  GQLUpdateFavorWorksetInput,
  GQLUpdateOrgHasUserInput,
  GQLWorksetGroup,
  GQLWorksetGroupHasWorkSetPropsInput,
  GQLWorksetGroupHasWorksetRemoveInput,
  GQLWorksetGroupPropsInput,
  GQLWorksetHasUser,
  GQLWorksetHasUserGroup,
  GQLWorksetHasUserGroupPropsInput,
  GQLWorksetHasUserPropsInput,
} from '../@types/server'
import { GQLOkResponse } from '../agent'
import {
  GQL_ASSIGN_ITEM_TO_ASSIGNEE,
  GQL_ASSIGN_ITEM_TO_REVIEWER,
  GQL_CANCEL_REMOVE_PROJECT,
  GQL_CHECK_PROJECT_HAS_USER,
  GQL_CONFIRM_ITEM_EXECUTION,
  GQL_CREATE_PROJECT,
  GQL_CREATE_PROJECT_METADATA_PRESET,
  GQL_CREATE_WORKSET_GROUP,
  GQL_CREATE_WORKSET_GROUP_HAS_WORKSET,
  GQL_CREATE_WORKSET_HAS_USER,
  GQL_CREATE_WORKSET_HAS_USER_GROUP,
  GQL_GET_PROJECT,
  GQL_GET_PROJECT_METADATA,
  GQL_GET_PROJECT_METADATA_PRESET,
  GQL_GET_WORKSET_GROUP,
  GQL_LIST_ITEM_EXECUTION, GQL_LIST_ITEM_EXECUTION_RESULT,
  GQL_LIST_PROJECT,
  GQL_LIST_PROJECT_HAS_USER,
  GQL_LIST_PROJECT_HAS_WORKSET,
  GQL_LIST_PROJECT_IN_ORG,
  GQL_LIST_PROJECT_METADATA_PRESET,
  GQL_LIST_WORKSET,
  GQL_LIST_WORKSET_GROUP_HAS_WORKSET,
  GQL_LIST_WORKSET_GROUP_HAS_WORKSET_IN_ORG,
  GQL_LIST_WORKSET_GROUP_IN_ORG,
  GQL_LIST_WORKSET_IN_ORG_WITH_FULL_PERMISSION,
  GQL_LIST_WORKSET_WITH_FULL_PERMISSION,
  GQL_PERMANENT_REMOVE_PROJECT,
  GQL_REMOVE_PROJECT,
  GQL_REMOVE_PROJECT_HAS_USER,
  GQL_REMOVE_PROJECT_HAS_WORKSET,
  GQL_REMOVE_PROJECT_METADATA_PRESET,
  GQL_REMOVE_WORKSET_GROUP_HAS_WORKSET,
  GQL_REMOVE_WORKSET_HAS_USER,
  GQL_REMOVE_WORKSET_HAS_USER_GROUP,
  GQL_UNLOCK_ITEM_EXECUTION,
  GQL_UPDATE_FAVOR_WORKSET,
  GQL_UPDATE_PROJECT,
  GQL_UPDATE_PROJECT_METADATA,
  GQL_UPDATE_PROJECT_METADATA_PRESET,
  GQL_UPDATE_WORKSET_HAS_USER,
  GQL_UPDATE_WORKSET_HAS_USER_GROUP,
} from '../gqls'
import { useODMutation, useODQuery } from './ODCommon'

const ORG_HAS_USER_SNAPSHOT = `
  user {
    userId
    email
    name
  }
  org {
    orgId
    name
  }
  orgUserPriv
  permSchema
`

const GQL_ORG_HAS_USER = `
query getOrgHasUserWithOrgIdUserId($data: DualIDInput!) {
  getOrgHasUserWithOrgIdUserId(data: $data) {
    ${ORG_HAS_USER_SNAPSHOT}
  }
}
`

const GQL_UPDATE_ORG_HAS_USER = `
mutation updateOrgHasUser($data: UpdateOrgHasUserInput!) {
  updateOrgHasUser(data: $data) {
    ${ORG_HAS_USER_SNAPSHOT}
  }
}
`

const GQL_LIST_ORG = `
query listOrg($data: ListableOrgInput!) {
  listOrg(data: $data) {
    list {
      orgId
      name
      color
      numUser
      deletedAt
      willDeleteAt
    }
    totalCount
    page
    pageSize
  }
}
`

const GQL_WORKSET_HAS_USER = `
query getWorksetHasUser($data: DualIDInput!) {
  getWorksetHasUser(data: $data) {
    wsId
    userId
    groupId
    permLevel
    expireAt
  }
}
`

const GQL_UNREMOVE_ORG = `
mutation unRemoveOrg($data: SingleIDInput!) {
  unRemoveOrg(data: $data) {
    orgId
  }
}
`

const GQL_GET_ITEM_WITH_PERMISSION = `
query getItem($data: SingleIDInput!) {
  getItem(data: $data) {
    itemId
    permission {
      canModifyMetadata
      canDownload
      canAddToWorkset
      canFavorite
      canShare
      canAddAttachment
      canEditAttachment
    }
  }
}`

export const GQL_RESET_USER_PASSWORD = `
mutation resetUserPassword($data: SingleIDInput!) {
  resetUserPassword(data: $data) {
    password
  }
}
`

export const GQL_LIST_ALERTS = `
query listAlert($data: ListableAlertInput!) {
  listAlert(data: $data) {
    list {
      alertId
      alertType
      description
      user {
        userId
        email
        name
      }
      createdAt
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_AUDITS = `
query listAudit($data: ListableAuditInput!) {
  listAudit(data: $data) {
    list {
      auditId
      auditType
      message
      createdAt
      orgId
      org {
        name
      }
      actor {
        userId
        email
        name
      }
      itemId
      item {
        title
        itemId
      }
    }
    totalCount
    page
    pageSize
  }
}`

export function useAPIs() {
  const getOrgHasUser = useODQuery<GQLDualIDInput, GQLOrgHasUser>(GQL_ORG_HAS_USER)
  const updateOrgHasUser = useODMutation<Partial<GQLUpdateOrgHasUserInput>, GQLOrgHasUser>(GQL_UPDATE_ORG_HAS_USER)
  const listOrg = useODQuery<GQLListableOrgInput, GQLListableOrg>(GQL_LIST_ORG)
  const getWorksetHasUser = useODQuery<GQLDualIDInput, GQLWorksetHasUser>(GQL_WORKSET_HAS_USER)
  const restoreOrg = useODMutation<GQLSingleIDInput, GQLOrg>(GQL_UNREMOVE_ORG)
  const getItemWithPermission = useODQuery<GQLSingleIDInput, GQLItem>(GQL_GET_ITEM_WITH_PERMISSION)
  const resetUserPassword = useODMutation<GQLSingleIDInput, GQLResetPasswordResponse>(GQL_RESET_USER_PASSWORD)
  const listAlerts = useODQuery<GQLListableAlertInput, GQLListableAlert>(GQL_LIST_ALERTS)
  const listAudits = useODQuery<GQLListableAuditInput, GQLListableAudit>(GQL_LIST_AUDITS)

  const listWorkset = useODQuery<Partial<GQLListableWorksetInput>, GQLListableWorkset>(GQL_LIST_WORKSET)
  const listWorksetWithFullPermission = useODQuery<Partial<GQLListableWorksetInput>, GQLListableWorkset>(
    GQL_LIST_WORKSET_WITH_FULL_PERMISSION
  )
  const listWorksetInOrgWithFullPermission = useODQuery<Partial<GQLListableWorksetInput>, GQLListableWorkset>(
    GQL_LIST_WORKSET_IN_ORG_WITH_FULL_PERMISSION
  )

  const apiAddMemberToWorkset = useODMutation<Partial<GQLWorksetHasUserPropsInput>, GQLWorksetHasUser>(
    GQL_CREATE_WORKSET_HAS_USER
  )
  const apiAddGroupToWorkset = useODMutation<Partial<GQLWorksetHasUserGroupPropsInput>, GQLWorksetHasUserGroup>(
    GQL_CREATE_WORKSET_HAS_USER_GROUP
  )
  const apiUpdateMemberOfWorkset = useODMutation<Partial<GQLWorksetHasUserPropsInput>, GQLWorksetHasUser>(
    GQL_UPDATE_WORKSET_HAS_USER
  )
  const apiUpdateGroupOfWorkset = useODMutation<Partial<GQLWorksetHasUserGroupPropsInput>, GQLWorksetHasUserGroup>(
    GQL_UPDATE_WORKSET_HAS_USER_GROUP
  )
  const apiRemoveMemberFromWorkset = useODMutation<GQLDualIDInput, GQLOkResponse>(GQL_REMOVE_WORKSET_HAS_USER)
  const apiRemoveGroupFromWorkset = useODMutation<GQLDualIDInput, GQLOkResponse>(GQL_REMOVE_WORKSET_HAS_USER_GROUP)

  const apiUpdateWorksetFavorite = useODMutation<GQLUpdateFavorWorksetInput, GQLOkResponse>(GQL_UPDATE_FAVOR_WORKSET)

  const listWorksetGroupInOrg = useODQuery<Partial<GQLListableWorksetGroupInput>, GQLListableWorksetGroup>(
    GQL_LIST_WORKSET_GROUP_IN_ORG
  )
  const createWorksetGroup = useODMutation<Partial<GQLWorksetGroupPropsInput>, GQLWorksetGroup>(
    GQL_CREATE_WORKSET_GROUP
  )
  const createWorksetGroupHasWorkset = useODMutation<Partial<GQLWorksetGroupHasWorkSetPropsInput>, GQLOkResponse>(
    GQL_CREATE_WORKSET_GROUP_HAS_WORKSET
  )
  const removeWorksetGroupHasWorkset = useODMutation<GQLWorksetGroupHasWorksetRemoveInput, GQLOkResponse>(
    GQL_REMOVE_WORKSET_GROUP_HAS_WORKSET
  )
  const listWorksetGroupHasWorkset = useODQuery<
    GQLListableWorksetGroupHasWorksetInput,
    GQLListableWorksetGroupHasWorkset
  >(GQL_LIST_WORKSET_GROUP_HAS_WORKSET)
  const listWorksetGroupHasWorksetInOrg = useODQuery<
    GQLListableWorksetGroupHasWorksetInOrgInput,
    GQLListableWorksetGroupHasWorkset
  >(GQL_LIST_WORKSET_GROUP_HAS_WORKSET_IN_ORG)
  const getWorksetGroup = useODQuery<GQLSingleIDInput, GQLWorksetGroup>(GQL_GET_WORKSET_GROUP)

  // Project
  const getProject = useODQuery<GQLSingleIDInput, GQLProject>(GQL_GET_PROJECT)
  const listProject = useODQuery<Partial<GQLListableProjectInput>, GQLListableProject>(GQL_LIST_PROJECT)
  const listProjectInOrg = useODQuery<Partial<GQLListableProjectInOrgInput>, GQLListableProject>(
    GQL_LIST_PROJECT_IN_ORG
  )
  const createProject = useODMutation<Partial<GQLProjectPropsInput>, GQLProject>(GQL_CREATE_PROJECT)
  const updateProject = useODMutation<Partial<GQLProjectPropsInput>, GQLProject>(GQL_UPDATE_PROJECT)
  const removeProject = useODMutation<Partial<GQLProjectRemoveInput>, GQLOkResponse>(GQL_REMOVE_PROJECT)
  const removePermanentProject = useODMutation<Partial<GQLProjectRemoveInput>, GQLOkResponse>(
    GQL_PERMANENT_REMOVE_PROJECT
  )
  const updateProjectMetaData = useODMutation<Partial<GQLProjectMetaDataUpdateInput>, GQLProjectMetaData>(
    GQL_UPDATE_PROJECT_METADATA
  )
  const getProjectMetaData = useODQuery<GQLSingleIDInput, GQLProjectMetaData>(GQL_GET_PROJECT_METADATA)
  const listItemExecution = useODQuery<Partial<GQLListableItemExecutionInput>, GQLListableItemExecution>(
    GQL_LIST_ITEM_EXECUTION
  )
  const assignItemExecutionToAssignee = useODMutation<GQLAssignItemExecutionToUserInput, GQLOkResponse>(
    GQL_ASSIGN_ITEM_TO_ASSIGNEE
  )
  const assignItemExecutionToReviewer = useODMutation<GQLAssignItemExecutionToUserInput, GQLOkResponse>(
    GQL_ASSIGN_ITEM_TO_REVIEWER
  )
  const confirmItemExecution = useODMutation<GQLConfirmItemExecutionsInput, GQLOkResponse>(GQL_CONFIRM_ITEM_EXECUTION)
  const unlockItemExecution = useODMutation<GQLUnlockItemExecutionInput, GQLOkResponse>(GQL_UNLOCK_ITEM_EXECUTION)
  const cancelRemoveProject = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_CANCEL_REMOVE_PROJECT)

  // Project Has User
  const listProjectHasUser = useODQuery<GQLListableProjectHasUserInput, GQLListableProjectHasUser>(
    GQL_LIST_PROJECT_HAS_USER
  )
  const removeProjectHasUser = useODMutation<GQLProjectHasUserRemoveInput, GQLOkResponse>(GQL_REMOVE_PROJECT_HAS_USER)

  // Project Has Workset
  const listProjectHasWorkset = useODQuery<GQLListableProjectHasWorksetInput, GQLListableProjectHasWorkset>(
    GQL_LIST_PROJECT_HAS_WORKSET
  )
  const removeProjectHasWorkset = useODMutation<GQLProjectHasWorksetPropsInput, GQLOkResponse>(
    GQL_REMOVE_PROJECT_HAS_WORKSET
  )

  // Project MetaData
  const listProjectMetaDataPreset = useODQuery<GQLListableProjectMetaDataPresetInput, GQLListableProjectMetaDataPreset>(
    GQL_LIST_PROJECT_METADATA_PRESET
  )
  const createProjectMetaDataPreset = useODMutation<
    Partial<GQLProjectMetaDataPresetCreationInput>,
    GQLProjectMetaDataPreset
  >(GQL_CREATE_PROJECT_METADATA_PRESET)
  const updateProjectMetaDataPreset = useODMutation<
    Partial<GQLProjectMetaDataPresetUpdateInput>,
    GQLProjectMetaDataPreset
  >(GQL_UPDATE_PROJECT_METADATA_PRESET)
  const getProjectMetaDataPreset = useODQuery<GQLSingleIDInput, GQLProjectMetaDataPreset>(
    GQL_GET_PROJECT_METADATA_PRESET
  )
  const removeProjectMetaDataPreset = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE_PROJECT_METADATA_PRESET)

  const checkProjectHasUser = useODMutation<GQLCheckProjectHasUserInput, GQLCheckProjectHasUserResponse>(
    GQL_CHECK_PROJECT_HAS_USER
  )

  // itemExecutionResult
  const listItemExecutionResult = useODQuery<GQLListableItemExecutionResultInput,GQLListableItemExecutionResult>(GQL_LIST_ITEM_EXECUTION_RESULT)

  return {
    getOrgHasUser,
    updateOrgHasUser,
    listOrg,
    getWorksetHasUser,
    restoreOrg,
    getItemWithPermission,
    resetUserPassword,
    listAlerts,
    listAudits,

    listWorkset,
    listWorksetWithFullPermission,
    listWorksetInOrgWithFullPermission,

    apiAddMemberToWorkset,
    apiAddGroupToWorkset,
    apiUpdateMemberOfWorkset,
    apiUpdateGroupOfWorkset,
    apiRemoveMemberFromWorkset,
    apiRemoveGroupFromWorkset,
    apiUpdateWorksetFavorite,

    listWorksetGroupInOrg,
    createWorksetGroup,
    createWorksetGroupHasWorkset,
    removeWorksetGroupHasWorkset,
    listWorksetGroupHasWorkset,
    listWorksetGroupHasWorksetInOrg,
    getWorksetGroup,

    // Project
    getProject,
    listProject,
    listProjectInOrg,
    createProject,
    updateProject,
    removeProject,
    removePermanentProject,
    updateProjectMetaData,
    getProjectMetaData,
    listItemExecution,
    assignItemExecutionToAssignee,
    assignItemExecutionToReviewer,
    confirmItemExecution,
    unlockItemExecution,
    cancelRemoveProject,

    // Project Has User
    listProjectHasUser,
    removeProjectHasUser,

    // Project Has Workset
    listProjectHasWorkset,
    removeProjectHasWorkset,

    // Project MetaData
    listProjectMetaData: listProjectMetaDataPreset,
    createProjectMetaDataPreset,
    updateProjectMetaDataPreset,
    getProjectMetaDataPreset,
    removeProjectMetaDataPreset,

    checkProjectHasUser,

    // itemExecutionResult
    listItemExecutionResult
  }
}
