import classNames from 'classnames'
import * as React from 'react'
import { Modal } from 'reactstrap'

export enum ODModalTheme {
  Primary = 'Primary',
}

export enum ODModalSize {
  Small = 'Small',
  Normal = 'Normal',
  Big = 'Big',
  Full = 'Full',
  TCF = 'TCF',
}

type ODModalProps = {
  theme?: ODModalTheme
  size?: ODModalSize
  isOpen: boolean
  fade?: boolean
  toggle: () => void
  style?: object
}

export const ODModal: React.FC<ODModalProps> = ({ children, ...props }) => {
  const { isOpen, toggle, fade = true, size = ODModalSize.Normal, style } = props

  const sizeCls = {
    'modal-sm': size === ODModalSize.Small,
    'modal-900': size === ODModalSize.Big,
    'modal-tcf': size === ODModalSize.TCF,
    'modal-tcf-inside-full': size === ODModalSize.Full,
  }

  return (
    <Modal
      fade={fade}
      isOpen={isOpen}
      toggle={toggle}
      className={classNames('modal-primary', 'modal-rect', sizeCls)}
      style={style}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>{children}</div>
    </Modal>
  )
}
