import { WORKSET_USER_PERM, worksetUserPermissionToString } from '@cils/common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import {
  GQLDateTime,
  GQLWorkset,
  GQLWORKSET_PERM,
  GQLWorksetHasUserGroupPropsInput,
  GQLWorksetHasUserPropsInput,
} from '../../@types/server'
import { GQLSingleIDInput } from '../../agent'
import { useODQuery2 } from '../../context/ODCommon'
import { useAPIs } from '../../context/useAPIs'
import { useCILSStore } from '../../di/configureRootStore'
import { GQL_GET_WORKSET } from '../../gqls'
import { Utils } from '../../utils'
import { BlockingLoadBox } from '../BlockingLoadBox'
import { ChangeMemberPermModal } from '../ChangeMemberPermModal'
import { ChangePermModal } from '../ChangePermModal'
import { ODIcon, ODIcons } from '../ODIcon'
import { SetExpirationModal } from '../SetExpirationModal'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalChangeWorksetPerm } from './ODModalChangeWorksetPerm'
import { ODModalHeader } from './ODModalHeader'
import { SelectWorksetMemberAndPermission } from './SelectWorksetMemberAndPermission'

type ODModalShareList = {
  name: string
  userId?: number | null
  groupId?: number | null
  permission: number | null // WORKSET_USER_PERM
  isDefaultPerm: boolean
  canBeChanged: boolean
  canBeExpired: boolean
  canBeDeleted: boolean
  expireAt: GQLDateTime | null
  desc?: string
  defaultPerm?: GQLWORKSET_PERM
}

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`
const PrivacyWrapper = styled.div`
  display: flex;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  flex-direction: column;
`

const PrivacyOrgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 47px;
  border-radius: 2px;
  background-color: #e4e7ea;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
`
const PrivacyName = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2e343a;
`
const PrivacyDesc = styled.span`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #73818f;
`
const SimplePermissionText = styled.span`
  font-size: 12px;
  color: #73818f;
  margin-right: 20px;
`

type ODModalShareProps = {
  wsId: number
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  title?: string
}

type SelectOptionType = {
  value: number
  key: string
  label: string
}

export type ShareModalDropDownProps = {
  isMenuOpen: string | null
  setIsMenuOpen: (v: string | null) => void
}

export const ODModalShare: React.FC<ODModalShareProps> = observer(props => {
  const { wsId, isOpen, setIsOpen, title = 'Share' } = props

  const {
    apiAddMemberToWorkset: apiAddMember,
    apiAddGroupToWorkset: apiAddGroup,
    apiUpdateMemberOfWorkset: apiUpdateMember,
    apiUpdateGroupOfWorkset: apiUpdateGroup,
    apiRemoveMemberFromWorkset: apiRemoveMember,
    apiRemoveGroupFromWorkset: apiRemoveGroup,
  } = useAPIs()

  const { data, loading: loadingData, refetch } = useODQuery2<GQLSingleIDInput, GQLWorkset>(GQL_GET_WORKSET, {
    pickFirstKey: true,
    skip: false,
    variables: { id: wsId },
  })

  const [loading, setLoading] = React.useState(false)
  const [isMenuOpen, setIsMenuOpen] = React.useState<string | null>(null)

  // const worksetName = data?.name ?? ''
  const store = useCILSStore()
  const updateMemberPermission = React.useCallback(
    async (id: number, update: Partial<GQLWorksetHasUserPropsInput>, msgSuccessful: string, userName: string) => {
      try {
        setLoading(true)
        // await apiUpdateMember({ wsId, userId: id, ...update })
        const isSuccess = await store.worksetShareStore.changeUserPerm({ wsId, userId: id, ...update }, userName)

        if (isSuccess) {
          Utils.showSuccess(msgSuccessful, 'Success')
        }
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        await refetch()
      }
    },
    [apiUpdateMember, setLoading, wsId, refetch]
  )

  const updateGroupPermission = React.useCallback(
    async (id: number, update: Partial<GQLWorksetHasUserGroupPropsInput>, msgSuccessful: string) => {
      try {
        setLoading(true)
        await apiUpdateGroup({ wsId, groupId: id, ...update })
        Utils.showSuccess(msgSuccessful, 'Success')
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        await refetch()
      }
    },
    [apiUpdateGroup, setLoading, wsId, refetch]
  )

  const removePermission = React.useCallback(
    async (id: number, msgSuccessful: string, isUser: boolean, userName?: string) => {
      try {
        setLoading(true)
        if (isUser) {
          // await apiRemoveMember({ firstId: wsId, secondId: id })
          const isSuccess = await store.worksetShareStore.removeUserPerm(
            { firstId: wsId, secondId: id },
            userName ?? ''
          )
          if (isSuccess) {
            Utils.showSuccess(msgSuccessful, 'Success')
          }
        } else {
          await apiRemoveGroup({ firstId: wsId, secondId: id })
          Utils.showSuccess(msgSuccessful, 'Success')
        }
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        await refetch()
      }
    },
    [setLoading, wsId, apiRemoveMember, apiRemoveGroup, refetch]
  )

  const addMember = React.useCallback(
    async (userId: number, permLevel: number, msgSuccessful: string) => {
      try {
        setLoading(true)
        await apiAddMember({ wsId, userId, permLevel })
        Utils.showSuccess(msgSuccessful, 'Success')
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        await refetch()
      }
    },
    [apiAddMember, setLoading, wsId, refetch]
  )
  const addGroup = React.useCallback(
    async (groupId: number, permLevel: number, msgSuccessful: string) => {
      try {
        setLoading(true)
        await apiAddGroup({ wsId, groupId, permLevel })
        Utils.showSuccess(msgSuccessful, 'Success')
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        await refetch()
      }
    },
    [apiAddGroup, setLoading, wsId, refetch]
  )

  const userList: Array<ODModalShareList> | null =
    data?.members?.map(member => {
      return {
        userId: member.user.userId,
        groupId: null,
        name: member.user.name || member.user.username || member.user.email || `User(${member.user.userId})`,
        isDefaultPerm: false,
        canBeChanged: member.permLevel !== WORKSET_USER_PERM.Owner,
        canBeExpired: member.permLevel !== WORKSET_USER_PERM.Owner,
        canBeDeleted: member.permLevel !== WORKSET_USER_PERM.Owner,
        expireAt: member.expireAt,
        permission: member.permLevel,
      }
    }) || null
  const userGroupList: Array<ODModalShareList> | null =
    data?.memberGroups?.map(group => {
      return {
        userId: null,
        groupId: group.groupId,
        name: group.group.name || '',
        isDefaultPerm: false,
        canBeChanged: group.permLevel !== WORKSET_USER_PERM.Owner,
        canBeExpired: group.permLevel !== WORKSET_USER_PERM.Owner,
        canBeDeleted: group.permLevel !== WORKSET_USER_PERM.Owner,
        expireAt: group.expireAt,
        permission: group.permLevel,
      }
    }) || null

  const list = userList?.concat(userGroupList || []) || null

  if (data?.defaultPerm && list) {
    // TODO: default 권한이 실제로 적용된다면, 변경되어야 함.
    const name = 'Private'
    list.unshift({
      name,
      userId: null,
      groupId: null,
      isDefaultPerm: true,
      canBeChanged: false,
      canBeExpired: false,
      canBeDeleted: false,
      expireAt: null,
      permission: null,
      defaultPerm: data.defaultPerm,
      desc: `Only white-listed users can access this workset.`,
    })
  }

  const handleModalClose = async (v: boolean) => {
    if (v) {
      setIsOpen(true)
    } else if (!v) {
      setIsOpen(false)
    }
  }

  const handleMemberPermSave = (id: number, permLevel: number, isUser: boolean, userName?: string) => {
    if (isUser) {
      // noinspection JSIgnoredPromiseFromCall
      updateMemberPermission(id, { permLevel }, 'Updated permission', userName ?? '-')
    } else {
      // noinspection JSIgnoredPromiseFromCall
      updateGroupPermission(id, { permLevel }, 'Updated permission')
    }
  }

  const handleOrgPermSave = (v: string) => {
    console.log(39112, 'Org Permission: ', v)
  }

  const handleExpirationSave = (v: ODModalShareList, date: string, isUser: boolean, userName?: string) => {
    if (isUser) {
      // noinspection JSIgnoredPromiseFromCall
      updateMemberPermission(v.userId!, { expireAt: date }, 'Set expiration date.', userName ?? '-')
    } else {
      // noinspection JSIgnoredPromiseFromCall
      updateGroupPermission(v.groupId!, { expireAt: date, permLevel: v.permission }, 'Set expiration date.')
    }
  }

  const handleAddMember = async (memberToAdd: SelectOptionType, worksetUserPerm: number) => {
    if (memberToAdd.key.includes('group_')) {
      await addGroup(memberToAdd.value, worksetUserPerm, 'Added a group to the workset.')
    } else {
      await addMember(memberToAdd.value, worksetUserPerm, 'Added a member to the workset.')
    }
  }

  const handleDelete = (v: ODModalShareList, userName?: string) => {
    if (v.userId) {
      // noinspection JSIgnoredPromiseFromCall
      removePermission(v.userId!, 'Removed a user.', true, userName ?? '-')
    } else {
      // noinspection JSIgnoredPromiseFromCall
      removePermission(v.groupId!, 'Removed a group.', false)
    }
  }

  const handleRemoveExpirationDate = (v: ODModalShareList, isUser: boolean, userName?: string) => {
    if (isUser) {
      // noinspection JSIgnoredPromiseFromCall
      updateMemberPermission(v.userId!, { expireAt: null }, 'Removed expiration date.', userName ?? '-')
    } else {
      // noinspection JSIgnoredPromiseFromCall
      updateGroupPermission(v.groupId!, { expireAt: null, permLevel: v.permission }, 'Removed expiration date.')
    }
  }

  const handleCloseModal = (name: string) => {
    if (isMenuOpen === name) {
      setIsMenuOpen(null)
    } else {
      setIsMenuOpen(name)
    }
  }

  const PrivacyList = (() => {
    return (
      list &&
      list.map((v, i) => {
        const showDeleteIcon = !v.isDefaultPerm && v.canBeDeleted

        return (
          <PrivacyOrgWrapper
            key={i}
            style={{
              borderBottom: i === list.length ? 0 : '1px solid #c8ced3',
              backgroundColor: v.isDefaultPerm ? '#e4e7ea' : '#ffffff',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <PrivacyName>{v.name}</PrivacyName>
                {v.canBeExpired && (
                  <SetExpirationModal
                    index={i}
                    initialValue={v.expireAt}
                    handleSave={(date: string) => {
                      if (v.userId) {
                        handleExpirationSave(v, date, true, v.name ?? '-')
                      } else {
                        handleExpirationSave(v, date, false)
                      }
                    }}
                    onDelete={() => {
                      if (v.userId) {
                        handleRemoveExpirationDate(v, true, v.name ?? '-')
                      } else {
                        handleRemoveExpirationDate(v, false)
                      }
                    }}
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={setIsMenuOpen}
                    handleCloseModal={handleCloseModal}
                  />
                )}
              </div>
              <PrivacyDesc style={{ marginTop: 4 }}>{v.desc}</PrivacyDesc>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {v.canBeChanged && (
                <ChangeMemberPermModal
                  initialValue={v.permission!}
                  index={i}
                  handleSave={(perm: number) => {
                    if (v.userId) {
                      handleMemberPermSave(v.userId!, perm, true, v.name ?? '-')
                    } else {
                      handleMemberPermSave(v.groupId!, perm, false)
                    }
                  }}
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  handleCloseModal={handleCloseModal}
                />
              )}
              {!v.canBeChanged && !v.isDefaultPerm && (
                <SimplePermissionText>{worksetUserPermissionToString(v.permission!)}</SimplePermissionText>
              )}
              {/* 우리는 우선 기본 퍼미션을 변경할 수 없도록 할 것이다. */}
              {v.isDefaultPerm && false && <ChangePermModal handleSave={handleOrgPermSave} />}
              {showDeleteIcon && (
                <ODIcon
                  icon={ODIcons.CoreX}
                  style={{ fontSize: 14, color: '#73818f', cursor: 'pointer' }}
                  onClick={() => handleDelete(v, v.name ?? '-')}
                />
              )}
              {!showDeleteIcon && <div style={{ width: 14 }} />}
            </div>
          </PrivacyOrgWrapper>
        )
      })
    )
  })()

  return (
    <ODModal isOpen={isOpen} toggle={() => handleModalClose(false)}>
      {(loading || loadingData) && <BlockingLoadBox show />}
      {store.worksetShareStore.isOpenChangeWorksetPerm && <ODModalChangeWorksetPerm refresh={refetch} />}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={title} onClose={() => handleModalClose(false)} />
        <ODModalBody>
          <Title style={{ marginBottom: 20 }}>Privacy</Title>
          <PrivacyWrapper style={{ marginTop: 12 }}>{PrivacyList}</PrivacyWrapper>
          <SelectWorksetMemberAndPermission onSubmit={handleAddMember} isRemoving={false} disabled={loading} />
        </ODModalBody>
        {/*<ODModalFooter>*/}
        {/*  <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleSave}>*/}
        {/*    Save*/}
        {/*  </ODButton>*/}
        {/*</ODModalFooter>*/}
      </div>
    </ODModal>
  )
})
