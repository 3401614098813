import { ODEntity, ODEntityInput } from '@odc/od-react-belt'
import React from 'react'
import { GQLAppConfig, GQLAppConfigUpdateInput } from '../../../@types/server'
import { useODEntityAPI } from '../../utils/odGQLEntityLoader'
import { AppConfigPrimaryKey, ICTAppConfig } from './AppConfigCommon'
import { GQL_READ_APP_CONFIG, GQL_READ_LATEST_APP_CONFIG, GQL_UPDATE_APP_CONFIG } from './AppConfigGQL'

interface IAppConfigEntityCommonProps {
  itemId: number | string | null
  viewMode?: boolean
}

const DEFAULT_APP_CONFIG_DATA: ICTAppConfig = {
  agentManualLink: ``,
}

// const convertAppConfigDBT2ICTSchema: ObjectTransformerSchema = JSON.parse(``)
// const convertAppConfigICT2CITSchema: ObjectTransformerSchema = JSON.parse(``)
// const convertAppConfigICT2UITSchema: ObjectTransformerSchema = JSON.parse(``)

export const AppConfigEntityCommon: React.FC<IAppConfigEntityCommonProps> = props => {
  //  const [mapClientDataToServerCreate] = React.useState(() => createValueTransformer(convertAppConfigDBT2ICTSchema))

  const apis = useODEntityAPI<GQLAppConfig, ICTAppConfig, void, GQLAppConfigUpdateInput>({
    updateGQL: GQL_UPDATE_APP_CONFIG,
    readGQL: GQL_READ_APP_CONFIG,
    readLatestGQL: GQL_READ_LATEST_APP_CONFIG,
    primaryKeyName: AppConfigPrimaryKey,
    //    mapClientDataToServerCreate,
  })

  return (
    <ODEntity
      isSingleton
      api={apis}
      saveButtonName="Save"
      titleUpdate="System Setting"
      updateSuccessTitle="Success"
      updateSuccessMessage="Setting saved."
      defaultCreateClientData={DEFAULT_APP_CONFIG_DATA}
      urlAfterCreation={(item: ICTAppConfig) => `/admin/systemSetting`}
      urlAfterUpdate={(item: ICTAppConfig) => `/admin/systemSetting`}
    >
      <ODEntityInput
        name="agentManualLink"
        label="Agent Manual Link"
        placeholder="Enter agent manual link"
        inputType="text"
        keyPath="agentManualLink"
      />
    </ODEntity>
  )
}
