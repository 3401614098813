import { Slider, Tooltip, withStyles } from '@material-ui/core'
import React from 'react'
import { LOCAL_STORAGE_KEY_IMAGE_SIZE } from '../../common'

type RangeSliderProps = {
  children: React.ReactElement
  open: boolean
  value: number
}

type Props = {
  imageSize: number
  setImageSize: (v: number) => void
  customStyle?: object
}

const RangeSlider = withStyles({
  root: {
    width: 360,
    color: '#01b9cd',
    height: 6,
  },
  thumb: {
    width: 16,
    height: 16,
    border: 'solid 1px #01b9cd',
    backgroundColor: '#ffffff',
    marginTop: -6,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 6,
  },
  rail: {
    height: 6,
    backgroundColor: '#e4e7ea',
  },
})(Slider)

const RangeToolTip = withStyles({
  tooltip: {
    backgroundColor: '#29b6ca',
    fontSize: 10,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.36,
    textAlign: 'center',
    color: '#ffffff',
  },
})(Tooltip)

export const RangeInput: React.FC<Props> = props => {
  const { imageSize, setImageSize, customStyle = {} } = props

  const ValueLabelComponent = (props: RangeSliderProps) => {
    const { children, open, value } = props

    return (
      <RangeToolTip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </RangeToolTip>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', ...customStyle }}>
      <RangeSlider
        ValueLabelComponent={ValueLabelComponent}
        aria-label="custom thumb label"
        value={imageSize}
        step={50}
        max={500}
        min={100}
        onChange={(e, v) => {
          if (typeof v === 'number') {
            localStorage.setItem(LOCAL_STORAGE_KEY_IMAGE_SIZE, v.toString())
            setImageSize(v)
          } else return null
        }}
      />
    </div>
  )
}
