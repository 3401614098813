import React from 'react'
import { GQLBulkAddWorksetHasItemInput, GQLItem, GQLListableItem, GQLListableItemInput } from '../../../@types/server'
import { GQLOkResponse } from '../../../agent'
import { GQL_ITEM_SIMPLE_SNAPSHOT } from '../../../context/ODAppContext'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { GQL_CREATE_WORKSET_HAS_ITEM_MULTIPLE } from '../../../gqls'
import { ODListableResponseType } from '../../../ODListable/ODListableContext'
import { Utils } from '../../../utils'
import { ItemDataLoaderOption, ItemsContainer } from '../TCFItems/ItemsContainer'

type Props = {
  directoryPath: string
  orgId: number
}

const GQL_LIST_ITEMS = `
query listItem($data: ListableItemInput!) {
  listItem(data: $data) {
    list {
      ${GQL_ITEM_SIMPLE_SNAPSHOT}
    }
    page
    pageSize
    totalCount
  }
}
`

export const DirectoryViewItemsContainer: React.FC<Props> = props => {
  const { directoryPath } = props
  const apiList = useODQuery<Partial<GQLListableItemInput>, GQLListableItem>(GQL_LIST_ITEMS)
  const apiAddToWorkset = useODMutation<Partial<GQLBulkAddWorksetHasItemInput>, GQLOkResponse>(
    GQL_CREATE_WORKSET_HAS_ITEM_MULTIPLE
  )
  const { orgId } = useODSingleOrgContext()

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemDataLoaderOption
    ): Promise<ODListableResponseType<GQLItem>> {
      return apiList({
        page,
        pageSize,
        filter: options.filter,
        orgId,
        directoryPath,
        tagIds: options.tagIds,
        hasAttachment: options.hasAttachment,
        timeLapseOnly: options.timeLapseOnly,
        tcfImageTypes: options.tcfImageTypes,
        // @ts-ignore
        sortBy: options.sortBy,
        modifiedTimeRange: options.modifiedTimeRange,
        categoryIds: options.categoryIds,
        afterKey,
        hidden: options.hidden,
        addedToWorkset: options.addedToWorkset,
      })
    },
    [directoryPath, apiList, orgId]
  )

  const handleOnFastAddToWorkset = React.useCallback(
    async function(wsIds: Array<number>, options: ItemDataLoaderOption): Promise<any> {
      try {
        const r = await apiAddToWorkset({
          wsIds,
          listOptions: {
            viewAllInOrg: null,
            page: null,
            pageSize: null,
            filter: options.filter,
            orgId,
            directoryPath,
            tagIds: options.tagIds,
            hasAttachment: options.hasAttachment,
            timeLapseOnly: options.timeLapseOnly,
            tcfImageTypes: options.tcfImageTypes,
            sortBy: null,
            modifiedTimeRange: options.modifiedTimeRange,
            categoryIds: options.categoryIds,
            afterKey: null,
            hidden: options.hidden,
            addedToWorkset: options.addedToWorkset,
            inWorkset: null,
          },
        })
        const count = r.ok
        Utils.showSuccess(
          `Added ${count} item${count > 1 ? 's' : ''}  to ${wsIds.length} workset${wsIds.length > 1 ? 's' : ''}.`,
          'Success'
        )
      } catch (ex) {
        Utils.showError(ex)
      }
      return true
    },
    [apiAddToWorkset, directoryPath, orgId]
  )

  return (
    <ItemsContainer
      showToolbar
      showFastAddToWorkset
      onFastAddToWorkset={handleOnFastAddToWorkset}
      dataLoader={dataLoader}
    />
  )
}
