import { PROGRESS_STATUS } from '@cils/common'
import { ODHSpace, ODRow, useODModalConfirm, useTimer } from '@odc/od-react-belt'
import { Decimal } from 'decimal.js'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import styled from 'styled-components'
import { GQLAgentProgressSort, GQLProgressWithWorkset, GQLUploadOrDownloadInfo } from '../../../agent'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { ODColors } from '../../../global-styles'
import { createODListableContext, ODListableResponseType } from '../../../ODListable/ODListableContext'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'
import { AgentProgressStatus } from './AgentProgressStatus'
import { AgentUploadDownloadListSortDropdown } from './AgentUploadDownloadListSortDropdown'
import {
  getProgressStatusFromProgressWithWorkset,
  IAgentProgressListableOption,
  SelectedWorkset,
  SetSelectedWorkset,
  useAgentProgressDataAPI,
} from './common'

interface IAgentProgressListableTableProps {
  isDownload: boolean
  selectedWorkset: SelectedWorkset | undefined
  setSelectedWorkset: SetSelectedWorkset
}

const { Provider, Context } = createODListableContext<GQLProgressWithWorkset, IAgentProgressListableOption>()

const TitleText = styled.span`
  max-width: 200px;
  font-size: 14px;
  color: #708393;
`

const Wrapper = styled.div`
  width: 39px;
  height: 35px;
  border-radius: 2px;
  color: white;
  font-size: 16px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const WorksetNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: ${ODColors.Primary};
  cursor: pointer;
`

const PriorityButton = ({ up, onClick }: { up: boolean; onClick: () => void }) => {
  return (
    <div className="rounded-full cursor-pointer" style={{ width: 20, height: 20 }} onClick={onClick}>
      <ODIcon icon={up ? ODIcons.CoreArrowCircleTop : ODIcons.CoreArrowCircleBottom} />
    </div>
  )
}

const ActionButton = ({
  backgroundColor,
  icon,
  onClick,
}: {
  backgroundColor: string
  icon: ODIcons
  onClick: () => void
}) => {
  return (
    <Wrapper style={{ backgroundColor }} onClick={onClick}>
      <ODIcon icon={icon} />
    </Wrapper>
  )
}

const WorksetName = (props: { worksetName: string; onClick: () => void }) => {
  const { worksetName, onClick } = props

  return (
    <WorksetNameWrapper onClick={onClick}>
      <ODIcon icon={ODIcons.SimpleLineIconsFolder} />
      <ODHSpace w={8} />
      {worksetName}
    </WorksetNameWrapper>
  )
}

const Line1 = styled.div`
  font-size: 14px;
  text-align: center;
  color: #2f353a;
`

const Warning = styled.div`
  color: #f86c6b;
`

const AbortMessage = ({ line1, warning }: { line1: string; warning: string }) => {
  return (
    <>
      <Line1>
        {line1}
        <br />
        <br />
        <b>Continue?</b>
        <br />
        <br />
        {warning && (
          <b>
            <Warning>{warning}</Warning>
          </b>
        )}
      </Line1>
    </>
  )
}

const filterItem = (filter: string) => (v: GQLUploadOrDownloadInfo) => {
  const sf = filter.toLowerCase()
  if (sf.trim().length === 0) {
    return true
  }

  return v.title.toLowerCase().includes(sf)
}

const filterItemWithWorkset = (filter: string) => (v: GQLProgressWithWorkset) => {
  const sf = filter.toLowerCase()
  if (sf.trim().length === 0) {
    return true
  }

  return (
    v.itemFolderName.toLowerCase().includes(sf) ||
    (v.isWorkset
      ? v.worksetProgress!.worksetName.toLowerCase().includes(sf) || v.worksetProgress?.childItems.find(filterItem(sf))
      : v.itemProgress?.title.toLowerCase().includes(sf))
  )
}

export const AgentProgressListableTable: React.FC<IAgentProgressListableTableProps> = props => {
  const { isDownload, selectedWorkset, setSelectedWorkset } = props
  const {
    changeDownloadPriority,
    removeDownloadItem,
    queueReDownloadItem,
    openFolder,
    removeDownloadQueue,
  } = useCILSAgentContext()
  const { orgId } = useODSingleOrgContext()

  const refreshToken = useTimer(1000)
  const { worksetId } = selectedWorkset || {}
  const apiList = useAgentProgressDataAPI()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IAgentProgressListableOption
    ): Promise<ODListableResponseType<GQLProgressWithWorkset>> {
      const { filter } = options
      const sortOrder = (options.sortOrder as unknown) as GQLAgentProgressSort
      const r = await apiList({ page: 1, pageSize: 100, isDownload, sortOrder })
      if (worksetId) {
        const wp = r.list.find(i => i.isWorkset && i.worksetProgress?.worksetId === worksetId)
        if (!wp) {
          return { list: [], page: 1, pageSize: 100, totalCount: 0 }
        }

        const items = wp.worksetProgress!.childItems
        const list = items.filter(filterItem(filter ?? '')).map(i => ({
          infoId: i.infoId,
          isWorkset: false,
          itemFolderName: i.itemFolderName,
          itemProgress: i,
          priority: i.priority,
          worksetProgress: null,
          isProject: false,
        }))
        return { list, page: 1, pageSize: 100, totalCount: list.length }
      }
      const list = r.list.filter(filterItemWithWorkset(filter ?? ''))
      return { list, page: 1, pageSize: 100, totalCount: list.length }
    },
    [apiList, isDownload, worksetId]
  )

  const { Component, confirm, props: confirmProps } = useODModalConfirm({ longClickTime: 2000 })

  const onRemoveItem = React.useCallback(
    async (v: GQLProgressWithWorkset) => {
      const title = v.isWorkset ? 'Delete Workset!' : 'Delete Data!'
      const isChildOfWorkset = !!v.itemProgress?.asChildOfWorksetId

      const message = v.isWorkset ? (
        <AbortMessage
          line1="Deleting workset folder will delete the root directory of the downloaded workset, and this cannot be undone."
          warning="You need to hold the click for 2 seconds to delete the workset."
        />
      ) : isChildOfWorkset ? (
        <AbortMessage
          line1="Deleting TCF inside workset will delete contents of the downloaded TCF directory, and leave a small hidden file to stop being downloaded again."
          warning="You need to hold the click for 2 seconds to delete the TCF directory."
        />
      ) : (
        <AbortMessage
          line1="Deleting top level TCF file will delete the the root directory of the downloaded TCF directory."
          warning="You need to hold the click for 2 seconds to delete the TCF directory."
        />
      )

      if (await confirm({ title, message, yes: 'Delete', no: 'Cancel' })) {
        await removeDownloadItem({ itemFolderName: v.itemFolderName })
      }
    },
    [confirm, removeDownloadItem]
  )

  const onRemoveQueue = React.useCallback(
    async (v: GQLProgressWithWorkset) => {
      const message = (
        <AbortMessage
          line1="Cancel download and this cannot be undone"
          warning="You need to hold the click for 2 seconds to cancel download"
        />
      )
      if (await confirm({ title: 'Cancel Download!', message, yes: 'Yes', no: 'No' })) {
        await removeDownloadQueue({ infoId: v.infoId })
      }
    },
    [confirm, removeDownloadQueue]
  )

  const onOpenLink = React.useCallback(
    (v: GQLProgressWithWorkset) => {
      // TODO: 여기서 orgId 는 실제 아이템의 orgId 가 되어야 할 것으로 보이며, 이는 서버에서 판단해서 보내주도록 하는 것이 가장 좋을 것으로 보인다.
      let link = v.isWorkset
        ? SiteUrls.User.Org.Workset.Detail(v.worksetProgress!.worksetId)(orgId)
        : SiteUrls.User.Org.Item.SingleTCF(v.itemProgress!.itemId)(orgId)

      if (v.isProject) {
        link = SiteUrls.User.Org.Project.Detail(v.worksetProgress!.worksetId)(orgId)
      }

      window.open(link, '_blank')
    },
    [orgId]
  )

  const handleChangePriority = async (v: GQLProgressWithWorkset, change: number) => {
    await changeDownloadPriority({ itemFolderName: v.itemFolderName, change })
  }

  const TableDefinition: ODListableTableDefinition<GQLProgressWithWorkset, IAgentProgressListableOption> = [
    {
      id: 'title',
      title: 'Title',
      transform: v => {
        return v.isWorkset ? (
          <WorksetName
            worksetName={v.worksetProgress?.worksetName ?? 'Unnamed'}
            onClick={() => {
              setSelectedWorkset({
                worksetId: v.worksetProgress!.worksetId,
                worksetName: v.worksetProgress!.worksetName,
                worksetDirName: v.worksetProgress!.itemFolderName,
                isProject: !!v.isProject,
              })
            }}
          />
        ) : (
          <TitleText>{v.itemProgress?.title ?? 'Unnamed'}</TitleText>
        )
      },
      thClass: 'text-left width-200',
      className: 'text-left user-td vcenter',
    },
    {
      id: 'dataId',
      title: 'Data ID',
      transform: v => v.itemProgress?.title ?? '',
      thClass: 'text-left width-200',
      className: 'text-left user-td vcenter',
    },
    {
      id: 'size',
      title: 'Size',
      transform: v => {
        const finishedSize = v.isWorkset ? v.worksetProgress?.finishedSize ?? '0' : v.itemProgress?.finishedSize ?? '0'
        const fullSize = v.isWorkset ? v.worksetProgress?.size ?? '0' : v.itemProgress?.size ?? '0'
        return `${prettyBytes(new Decimal(finishedSize).toNumber())} / ${prettyBytes(new Decimal(fullSize).toNumber())}`
      },
      thClass: 'text-left width-200',
      className: 'text-left user-td vcenter',
    },
    {
      id: 'status',
      title: 'Status',
      transform: v => (
        <div style={{ padding: '0 10px 0 0' }}>
          <AgentProgressStatus item={v} isDownload={isDownload} />
        </div>
      ),
      thClass: 'text-left',
      className: 'text-center vcenter',
    },
    {
      id: 'priority',
      title: 'Priority',
      transform: v => {
        const shouldShow = ([PROGRESS_STATUS.InProgress, PROGRESS_STATUS.Pending] as string[]).includes(
          (v.isWorkset ? v.worksetProgress?.progressStatus : v.itemProgress?.progressStatus)!
        )

        if (!shouldShow) {
          return null
        }

        return (
          <ODRow style={{ color: '#73818f', fontSize: 20 }}>
            <PriorityButton up onClick={() => handleChangePriority(v, -99)} />
            <ODHSpace w={14} />
            <PriorityButton up={false} onClick={() => handleChangePriority(v, 99)} />
            {/*{v.priority}*/}
          </ODRow>
        )
      },
      thClass: 'text-left width-100',
      className: 'text-left user-td vcenter',
    },
    {
      id: 'action',
      title: 'Action',
      transform: v => {
        const isDownloaded = getProgressStatusFromProgressWithWorkset(v) === 'Finished'
        const isDeleted = getProgressStatusFromProgressWithWorkset(v) === 'Deleted'
        return (
          <ODRow>
            <ActionButton backgroundColor={ODColors.Primary} icon={ODIcons.CoreLink} onClick={() => onOpenLink(v)} />
            {isDownload && !isDownloaded && (
              <ActionButton backgroundColor="#73818f" icon={ODIcons.CoreTrash} onClick={() => onRemoveQueue(v)} />
            )}
            {isDownload && isDownloaded && (
              <ActionButton
                backgroundColor="#4dbd74"
                icon={ODIcons.CoreFolderOpen}
                onClick={() => openFolder({ type: 'openDownloadFolder', path: v.itemFolderName })}
              />
            )}
            {isDownload && isDownloaded && (
              <ActionButton backgroundColor="#73818f" icon={ODIcons.CoreTrash} onClick={() => onRemoveItem(v)} />
            )}
            {isDownload && isDeleted && (
              <ActionButton
                backgroundColor="#4dbd74"
                icon={ODIcons.CoreDataTransferDown}
                onClick={() => queueReDownloadItem({ itemFolderName: v.itemFolderName })}
              />
            )}
          </ODRow>
        )
      },
      thClass: 'text-left width-200',
      className: 'text-left user-td vcenter',
    },
  ]

  return (
    <FlexContentsContainer>
      <Component {...confirmProps} />
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v.itemFolderName.toString()}
        pageSize={10}
        onDataLoaderError={Utils.showError}
        refreshToken={refreshToken.toString()}
        searchOnLoad
      >
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, justifyContent: 'space-between' }}>
          <ODListableSearchBox
            listableContext={Context}
            placeholder="Search by title"
            style={{ flexGrow: 6, maxWidth: 600 }}
            resetKeywordOnLocationChange
          />
          {!isDownload && (
            <RowWrapper>
              <SortByText style={{ marginRight: 16 }}>Sort by</SortByText>
              <AgentUploadDownloadListSortDropdown
                listableContext={Context}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
              />
            </RowWrapper>
          )}
        </div>
        <ODListablePaginatedTable
          fields={TableDefinition}
          listableContext={Context}
          renderLoading={() => 'Loading..'}
          renderEmpty={() => 'No items found.'}
        />
      </Provider>
    </FlexContentsContainer>
  )
}

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  margin-top: -5px;
`

const SortByText = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #2f353a;
`
