import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 20px 17px;
  height: 41px;
  background-color: #2e343a;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #70839388;
`

type ODAppSidebarNavSeparatorProps = {}

export const ODAppSidebarNavSeparator: React.FC<ODAppSidebarNavSeparatorProps> = props => {
  return (
    <Wrapper>
      <Line />
    </Wrapper>
  )
}
