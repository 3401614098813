import { WORKSET_ACTION } from '@cils/common/lib/permission/worksetPermConsts'
import React from 'react'
import { GQLAttachmentType, GQLItemAttachment } from '../../@types/server'
import { AddAttachmentBox } from '../../containers/User/DetailContainerComponent/AddAttachmentBox'
import { ThumbnailInfo } from '../../containers/User/DetailContainerComponent/convertItemAttachmentToThumbnailInfo'
import { ItemAttachmentList } from '../../containers/User/DetailContainerComponent/ItemAttachmentList'
import { useWorksetPerm } from '../../context/useWorksetPerm'
import { useDetectOutsideClick } from '../../utils/useDetectOutsideClick'
import { DragAndDropComponent } from '../DragAndDropComponent'
import { ODModal, ODModalSize } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { AttachmentEditData } from './ODModalEditAttachment'
import { ODModalHeader } from './ODModalHeader'

type ODModalAttachmentListProps = {
  itemId: number
  onClose: () => void
  onAttachmentAdded: (image: File, preview: string) => void
  onSetAttachmentAsMain: (attachment: GQLItemAttachment) => Promise<any>
  onSaveAttachment: (thumbnail: ThumbnailInfo, changed: AttachmentEditData) => Promise<any>
  onRemove: (v: GQLItemAttachment) => any
  listRefreshToken: string
  attachmentTypes: Array<GQLAttachmentType>
  inWorksetId?: number
}

export const ODModalAttachmentList: React.FC<ODModalAttachmentListProps> = props => {
  const {
    itemId,
    onClose,
    onAttachmentAdded,
    onSetAttachmentAsMain,
    onSaveAttachment,
    onRemove,
    listRefreshToken,
    attachmentTypes,
    inWorksetId,
  } = props
  const [isOpenFileUploadView, setIsOpenFileUploadView] = React.useState(false)
  const dndRef = React.useRef<HTMLDivElement>(null)
  const wsPermUtils = useWorksetPerm(inWorksetId ?? 0)
  const canAddAttachment = wsPermUtils?.isAllowed(WORKSET_ACTION.AddAttachment)
  useDetectOutsideClick(() => setIsOpenFileUploadView(false), dndRef, isOpenFileUploadView)

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Full}>
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        ref={dndRef}
        onDragEnter={() => {
          if (canAddAttachment) {
            setIsOpenFileUploadView(true)
          }
        }}
      >
        <DragAndDropComponent
          style={{ width: '100%', height: '100%', padding: 40 }}
          isOpen={isOpenFileUploadView}
          toggle={() => {
            setIsOpenFileUploadView(false)
          }}
          onClose={() => setIsOpenFileUploadView(false)}
          onChange={(image, preview) => {
            onAttachmentAdded(image, preview)
          }}
        />
        <ODModalHeader title="Attachment List" onClose={onClose} />
        <ODModalBody>
          {canAddAttachment && <AddAttachmentBox onClick={() => setIsOpenFileUploadView(true)} />}
          <ItemAttachmentList
            itemId={itemId}
            onSetAttachmentAsMain={onSetAttachmentAsMain}
            onUpdateAttachment={onSaveAttachment}
            onRemove={onRemove}
            refreshToken={listRefreshToken}
            attachmentTypes={attachmentTypes}
            wsPermUtils={wsPermUtils}
          />
        </ODModalBody>
      </div>
    </ODModal>
  )
}
