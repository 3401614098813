const createFields = (fieldArray: string[]) => fieldArray.join('\n')

const COMMON_USER_FIELDS = [
  'userId',
  'name',
  'username',
  'email',
  'lastLoginToken',
  'lastAccessTime',
  'unread',
  'priv',
  'createdAt',
  'updatedAt',
  'mainOrgId',
]

const COMMON_ITEM_FIELDS = ['itemId', 'num', 'itemType', 'hashId', 'guid', 'meta', 'title', 'desc', 'dateTaken']
const COMMON_FILE_FIELDS = ['fileId', 'hashId', 'fileName', 'mimeType', 'encoding', 'deletedAt', 'link', 'fileType']
const COMMON_ORG_FIELDS = [
  'orgId',
  'name',
  'color',
  'createdAt',
  'updatedAt',
  'supportEmail',
  'deletedAt',
  'willDeleteAt',
]
const COMMON_CATEGORY_FIELDS = ['categoryId', 'name', 'color', 'createdAt']
const COMMON_SERVICE_STAT_DAILY_FIELDS = [
  'dateToken',
  'uniqueVisit',
  'signup',
  'tcfUpload',
  'workset',
  'researcher',
  'agent',
]
const COMMON_STAT_ORG_DAILY_FIELDS = [
  'dateToken',
  'uniqueVisit',
  'signup',
  'tcfUpload',
  'workset',
  'agent',
  'researcher',
  'storageUsage',
]
const COMMON_USER_GROUP_FIELDS = ['groupId', 'name', 'orgId']
const COMMON_MACHINE_FIELDS = [
  'machineId',
  'machineName',
  'agentVersion',
  'orgId',
  'lastLoggedInUserId',
  'lastMessage',
  'lastAccessedTime',
  'lastSyncedTime',
  'agentStatus',
  'createdAt',
  'hidden',
]

export const USER_FULL_SNAPSHOT = `
  ${createFields(COMMON_USER_FIELDS)}
`

export const ORG_FULL_SNAPSHOT = `
  ${createFields(COMMON_ORG_FIELDS)}
`

export const ME_FULL_SNAPSHOT = `
  ${createFields(COMMON_USER_FIELDS)}
  mainOrg {
    ${ORG_FULL_SNAPSHOT}
  }
  organizations {
    joinId
    org {
      ${ORG_FULL_SNAPSHOT}
    }
    orgUserPriv
    permSchema
  }
`

const FILE_FULL_SNAPSHOT = `
  ${createFields(COMMON_FILE_FIELDS)}
`

const ITEM_FULL_SNAPSHOT = `
  ${createFields(COMMON_ITEM_FIELDS)}
  imageFile {
    ${FILE_FULL_SNAPSHOT}
  }
`

const CATEGORY_FULL_SNAPSHOT = `
  ${createFields(COMMON_CATEGORY_FIELDS)}
  org {
    ${ORG_FULL_SNAPSHOT}
  }
`

export const MACHINE_FULL_SNAPSHOT = `
  ${createFields(COMMON_MACHINE_FIELDS)}
  org {
    ${ORG_FULL_SNAPSHOT}
  }
  lastLoggedInUser {
    userId
    name
    email
    owners {
      userId
      name
      email
    }
  }
`

const ORG_HAS_USER_SNAPSHOT = `
  joinId
  user {
    userId
    name
  }
  org {
    orgId
    name
  }
  orgUserPriv
`

const WORKSET_SNAPSHOT_WITH_FULL_PERMISSION = `
      wsId
      name
      numItems
      defaultPerm
      lastAddedAt
      createdAt
      organization {
        orgId
        name
      }
      defaultPerm
      members {
        userId
        groupId
        permLevel
        expireAt
        user {
          ${USER_FULL_SNAPSHOT}
        }
      }
      memberGroups {
        groupId
        permLevel
        expireAt
        group {
          groupId
          name
        }
      }
      myPerm
      worksetGroups {
        wsgId
        name
        worksets {
         wsId
         name
        }
     }
     projects {
       projectId
       name
     } 
`

export const STAT_DAILY_FULL_SNAPSHOT = createFields(COMMON_SERVICE_STAT_DAILY_FIELDS)
export const STAT_ORG_DAILY_FULL_SNAPSHOT = createFields(COMMON_STAT_ORG_DAILY_FIELDS)

const COMMON_WORKSET_GROUP_FIELDS = ['createdAt', 'updatedAt', 'wsgId', 'name', 'ownerOrgId']
export const WORKSET_GROUP_FULL_SNAPSHOT = `
  ${createFields(COMMON_WORKSET_GROUP_FIELDS)}
  worksets {
    wsId
    name
  }
`

const COMMON_WORKSET_GROUP_HAS_WORKSET_FIELDS = ['createdAt', 'updatedAt', 'wsgId', 'wsId']
export const WORKSET_GROUP_HAS_WORKSET_FULL_SNAPSHOT = `
  ${createFields(COMMON_WORKSET_GROUP_HAS_WORKSET_FIELDS)}
  workset {
    ${WORKSET_SNAPSHOT_WITH_FULL_PERMISSION}
  }
`

// Project
const COMMON_PROJECT_FIELDS = [
  'createdAt',
  'updatedAt',
  'projectId',
  'name',
  'type',
  'ownerOrgId',
  'deletedAt',
  'numItems',
  'myPerm',
]
export const PROJECT_FULL_SNAPSHOT = `
  ${createFields(COMMON_PROJECT_FIELDS)}
  numConfirmedItems
  owner {
   ${USER_FULL_SNAPSHOT}
  }
  organization {
   ${ORG_FULL_SNAPSHOT}
  }
`

const COMMON_ITEM_EXECUTION_FIELDS = [
  'createdAt',
  'updatedAt',
  'ieId',
  'itemId',
  'dataId',
  'projectId',
  'assigneeId',
  'assignStatus',
  'reviewerId',
  'reviewStatus',
  'lockerId',
  'confirmedAt',
  'isConfirmed',
  'hasResults',
]

export const ITEM_EXECUTION_FULL_SNAPSHOT = `
  ${createFields(COMMON_ITEM_EXECUTION_FIELDS)}
  item {
    itemId
    dataId
    title
    inWorksets {
      wsId
      name
    }
  }
  assignee {
    userId
    name
  }
  reviewer {
    userId
    name
  }
  locker {
    userId
    name
  }
`

// Project Has User
export const PROJECT_HAS_USER_FULL_SNAPSHOT = `
  createdAt
  updatedAt
  projectId
  userId
  user {
    ${USER_FULL_SNAPSHOT}
    mainOrg {
      name
    }
  }
  permLevel
`

// Project Has Workset
export const PROJECT_HAS_WORKSET_FULL_SNAPSHOT = `
  createdAt
  updatedAt
  projectId
  worksetId
  workset {
    wsId
    numItems
    lastAddedAt
    name
    createdAt
    owner {
      userId
      name
    }
  }
`

// Project MetaData
const COMMON_PROJECT_METADATA_PRESET_FIELDS = ['createdAt', 'updatedAt', 'psId', 'name', 'rawData']

export const PROJECT_METADATA_PRESET_FULL_SNAPSHOT = `
  ${createFields(COMMON_PROJECT_METADATA_PRESET_FIELDS)}
`

const COMMON_ITEM_EXECUTION_RESULT_FIELDS = [
  'createdAt',
  'updatedAt',
  'ierId',
  'status',
  'statusMessage',
  'finishedAt',
  'ieId',
  'userId',
  'uploadedAt',
  'version',
]

// item execution result
export const ITEM_EXECUTION_RESULT_FULL_SNAPSHOT = `
  ${createFields(COMMON_ITEM_EXECUTION_RESULT_FIELDS)}
  user {
    ${USER_FULL_SNAPSHOT}
  }
  item {
    meta
  }
`

export const GQL_APP_CONFIG = `
query appConfig {
  appConfig {
    configId
    agentVersion
    agentDownloadLink
    agentManualLink
  }
}`

export const GQL_GET_USER_PROFILE = `
query {
  getUser {
    ${ME_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_USER = `
mutation updateUser($data: UserPropsInput!) {
  updateUser(data: $data) {
    ${ME_FULL_SNAPSHOT}
  }
}
`

export const GQL_USER_LOGIN = `
mutation loginUser($data: UsernameLogin!) {
  loginUser(data: $data) {
    me {
      ${ME_FULL_SNAPSHOT}
    }
    token
  }
}`

export const GQL_USER_LOGOUT = `
mutation {
  logoutUser {
    ok
  }
}
`

export const GQL_LIST_USERS = `
query users($data: ListableUserInput!) {
  listUser(data: $data) {
    list {
      ${USER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_CREATE_ITEM = `
mutation createItem($data: ItemPropsInput!) {
  createItem(data: $data) {
    ${ITEM_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_ITEM = `
mutation updateItem($data: ItemPropsInput!) {
  updateItem(data: $data) {
    ${ITEM_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_ITEM = `
query getItem($data: SingleIDInput!) {
  getItem(data: $data) {
    ${ITEM_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_ITEM = `
mutation removeItem($data: SingleIDInput!) {
  removeItem(data: $data) {
    ok
  }
}
`

export const GQL_LIST_ITEMS = `
query items($data: ListableItemInput!) {
  items(data: $data) {
    list {
      ${ITEM_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_CHANGE_USER_PRIV = `
mutation changeUserPriv($data: InputChangeUserPriv!) {
  changeUserPriv(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

export const GQL_CREATE_ORG = `
mutation createOrg($data: OrgPropsInput!) {
  createOrg(data: $data) {
    ${ORG_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_ORG = `
query getOrg($data: SingleIDInput!) {
  getOrg(data: $data) {
    ${ORG_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_ORG = `
mutation updateOrg($data: OrgPropsInput!) {
  updateOrg(data: $data) {
    ${ORG_FULL_SNAPSHOT}
  }
}
`

export const GQL_REMOVE_ORG = `
mutation removeOrg($data: SingleIDInput!) {
  removeOrg(data: $data) {
    ok
  }
}
`

export const GQL_LIST_ORG = `
query listOrg($data: ListableOrgInput!) {
  listOrg(data: $data) {
    list {
      ${ORG_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_ASSIGN_USER_TO_ORG = `
mutation assignUserToOrg($data: AssignUserToOrgInput!) {
  assignUserToOrg(data: $data) {
    ${ORG_HAS_USER_SNAPSHOT}
  }
}
`

export const GQL_LIST_ORG_HAS_USER = `
query listOrgHasUser($data: ListableOrgHasUserInput!) {
  listOrgHasUser(data: $data) {
    list {
      ${ORG_HAS_USER_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}`

export const GQL_GET_ORG_HAS_USER = `
query getOrgHasUser($data: SingleIDInput!) {
  getOrgHasUser(data: $data) {
    ${ORG_HAS_USER_SNAPSHOT}
  }
}`

export const GQL_UPDATE_ORG_HAS_USER = `
mutation updateOrgHasUser($data: UpdateOrgHasUserInput!) {
  updateOrgHasUser(data: $data) {
    ${ORG_HAS_USER_SNAPSHOT}
  }
}
`

export const GQL_CREATE_CATEGORY = `
mutation createCategory($data: CategoryPropsInput!) {
  createCategory(data: $data) {
    ${CATEGORY_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_CATEGORY = `
mutation updateCategory($data: CategoryPropsInput!) {
  updateCategory(data: $data) {
    ${CATEGORY_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_CATEGORY = `
query getCategory($data: SingleIDInput!) {
  getCategory(data: $data) {
    ${CATEGORY_FULL_SNAPSHOT}
  }
}`

export const GQL_REMOVE_CATEGORY = `
mutation removeCategory($data: SingleIDInput!) {
  removeCategory(data: $data) {
    ok
  }
}
`

export const GQL_LIST_CATEGORIES = `
query listCategory($data: ListableCategoryInput!) {
  listCategory(data: $data) {
    list {
      ${CATEGORY_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_SERVICE_DAILY_STATS = `
query getAdminStatDailyInRange($data: StatDateRange!) {
  getAdminStatDailyInRange(data: $data) {
    ${STAT_DAILY_FULL_SNAPSHOT}
  }
}
`

export const GQL_CREATE_WORKSET_HAS_ITEM_MULTIPLE = `
mutation createWorksetHasItems($data: BulkAddWorksetHasItemInput!) {
  createWorksetHasItems(data: $data) {
    ok
  }
}
`

export const GQL_CREATE_ITEM_SHARE = `
mutation createItemShare($data: ItemSharePropsInput!) {
  createItemShare(data: $data) {
    shareToken
  }
}
`

export const USER_GROUP_HAS_USER_FULL_SNAPSHOT = `
  userId
  user {
    ${USER_FULL_SNAPSHOT}
  }
`

export const USER_GROUP_FULL_SNAPSHOT = `
  ${createFields(COMMON_USER_GROUP_FIELDS)}
  members {
    userId
    name
    email
  }
  worksets {
    wsId
    name
  }
`

export const WORKSET_HAS_USER_GROUP_FULL_SNAPSHOT = `
  groupId
  permLevel
  expireAt
  group {
    groupId
    name
  }
`

export const GQL_LIST_USER_GROUP = `
query listUserGroup($data: ListableUserGroupInput!) {
  listUserGroup(data: $data) {
    list {
      ${USER_GROUP_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_USERS_FOR_WORKSET_PERM = `
query users($data: ListableUserInput!) {
  listUser(data: $data) {
    list {
      ${USER_FULL_SNAPSHOT}
      organizations {
        joinId
        org {
          ${ORG_FULL_SNAPSHOT}
        }
        orgUserPriv
      }
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_WORKSET = `
query getWorkset($data: SingleIDInput!) {
  getWorkset(data: $data) {
    name
    organization {
      name
    }
    defaultPerm
    members {
      userId
      groupId
      permLevel
      expireAt
      user {
        ${USER_FULL_SNAPSHOT}
      }
    }
    memberGroups {
      groupId
      permLevel
      expireAt
      group {
        groupId
        name
      }
    }
  }
}
`

export const GQL_LIST_WORKSET = `
query listWorkset($data: ListableWorksetInput!) {
  listWorkset(data: $data) {
    list {
      wsId
      name
      numItems
      defaultPerm
      lastAddedAt
      createdAt
      members {
        permLevel
        user {
          name
          email
        }
      }
      organization {
        orgId
        name
      }
      myPerm
      isNew
      worksetGroups {
        wsgId
        name
        worksets {
          wsId
          name
        }
      }
      projects {
        projectId
        name
      }
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_WORKSET_WITH_FULL_PERMISSION = `
query listWorkset($data: ListableWorksetInput!) {
  listWorkset(data: $data) {
    list {
      ${WORKSET_SNAPSHOT_WITH_FULL_PERMISSION}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_WORKSET_IN_ORG_WITH_FULL_PERMISSION = `
query listWorksetInOrg($data: ListableWorksetInput!) {
  listWorksetInOrg(data: $data) {
    list {
      ${WORKSET_SNAPSHOT_WITH_FULL_PERMISSION}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_UPDATE_WORKSET_HAS_USER = `
mutation updateWorksetHasUser($data: WorksetHasUserPropsInput!) {
  updateWorksetHasUser(data: $data) {
    userId
    groupId
    permLevel
    expireAt
    user {
      ${USER_FULL_SNAPSHOT}
    }
  }
}
`

export const GQL_UPDATE_WORKSET_HAS_USER_GROUP = `
mutation updateWorksetHasUserGroup($data: WorksetHasUserGroupPropsInput!) {
  updateWorksetHasUserGroup(data: $data) {
    ${WORKSET_HAS_USER_GROUP_FULL_SNAPSHOT}
  }
}
`

export const GQL_REMOVE_WORKSET_HAS_USER = `
mutation removeWorksetHasUser($data: DualIDInput!) {
  removeWorksetHasUser(data: $data) {
    ok
  }
}
`

export const GQL_REMOVE_WORKSET_HAS_USER_GROUP = `
mutation removeWorksetHasUserGroup($data: DualIDInput!) {
  removeWorksetHasUserGroup(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_FAVOR_WORKSET = `
mutation updateFavorWorkset($data: UpdateFavorWorksetInput!) {
  updateFavorWorkset(data: $data) {
    ok
  }
}
`

export const GQL_CREATE_WORKSET_HAS_USER = `
mutation createWorksetHasUser($data: WorksetHasUserPropsInput!) {
  createWorksetHasUser(data: $data) {
    userId
    groupId
    permLevel
    expireAt
    user {
      ${USER_FULL_SNAPSHOT}
    }
  }
}
`

export const GQL_CREATE_WORKSET_HAS_USER_GROUP = `
mutation createWorksetHasUserGroup($data: WorksetHasUserGroupPropsInput!) {
  createWorksetHasUserGroup(data: $data) {
    ${WORKSET_HAS_USER_GROUP_FULL_SNAPSHOT}
  }
}
`

export const GQL_GET_WORKSET_GROUP = `
query getWorksetGroup($data: SingleIDInput!) {
  getWorksetGroup(data: $data) {
    ${WORKSET_GROUP_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_WORKSET_GROUP_IN_ORG = `
query listWorksetGroupInOrg($data: ListableWorksetGroupInput!) {
  listWorksetGroupInOrg(data: $data) {
    list {
      ${WORKSET_GROUP_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_WORKSET_GROUP = `
mutation createWorksetGroup($data: WorksetGroupPropsInput!) {
  createWorksetGroup(data: $data) {
    ${WORKSET_GROUP_FULL_SNAPSHOT}
  }
}
`

export const GQL_CREATE_WORKSET_GROUP_HAS_WORKSET = `
mutation createWorksetGroupHasWorkset($data: WorksetGroupHasWorkSetPropsInput!) {
  createWorksetGroupHasWorkset(data: $data) {
    ok
  }  
}
`

export const GQL_LIST_WORKSET_GROUP_HAS_WORKSET = `
query listWorksetGroupHasWorkset($data: ListableWorksetGroupHasWorksetInput!) {
  listWorksetGroupHasWorkset(data: $data) {
    list {
      ${WORKSET_GROUP_HAS_WORKSET_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_WORKSET_GROUP_HAS_WORKSET_IN_ORG = `
query listWorksetGroupHasWorksetInOrg($data: ListableWorksetGroupHasWorksetInOrgInput!) {
  listWorksetGroupHasWorksetInOrg(data: $data) {
    list {
      ${WORKSET_GROUP_HAS_WORKSET_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_REMOVE_WORKSET_GROUP_HAS_WORKSET = `
mutation removeWorksetGroupHasWorkset($data: WorksetGroupHasWorksetRemoveInput!) {
  removeWorksetGroupHasWorkset(data: $data) {
    ok
  }
}
`
//
// Project
//

export const GQL_GET_PROJECT = `
query getProject($data: SingleIDInput!) {
  getProject(data: $data) {
    ${PROJECT_FULL_SNAPSHOT}
  }
}
`

export const GQL_LIST_PROJECT = `
query listProject($data: ListableProjectInput!) {
  listProject(data: $data) {
    list {
      ${PROJECT_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_LIST_PROJECT_IN_ORG = `
query listProjectInOrg($data: ListableProjectInOrgInput!) {
  listProjectInOrg(data: $data) {
    list {
      ${PROJECT_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_CREATE_PROJECT = `
mutation createProject($data: ProjectPropsInput!) {
  createProject(data: $data) {
    ${PROJECT_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_PROJECT = `
mutation updateProject($data: ProjectPropsInput!) {
  updateProject(data: $data) {
    ${PROJECT_FULL_SNAPSHOT}
  }
}
`

export const GQL_REMOVE_PROJECT = `
mutation removeProject($data: ProjectRemoveInput!) {
  removeProject(data: $data) {
    ok
  }
}
`

export const GQL_PERMANENT_REMOVE_PROJECT = `
mutation removePermanentProject($data: ProjectRemoveInput!) {
  removePermanentProject(data: $data) {
    ok
  }
}
`

export const GQL_CANCEL_REMOVE_PROJECT = `
mutation cancelRemoveProject($data: SingleIDInput!) {
  cancelRemoveProject(data: $data) {
    ok
  }
}
`

export const GQL_UPDATE_PROJECT_METADATA = `
mutation updateProjectMetaData($data: ProjectMetaDataUpdateInput!) {
  updateProjectMetaData(data: $data) {
    projectId
    rawData
  }
}
`

export const GQL_GET_PROJECT_METADATA = `
query getProjectMetaData($data: SingleIDInput!) {
  getProjectMetaData(data: $data) {
    projectId
    rawData
  }
}
`

export const GQL_LIST_ITEM_EXECUTION = `
query listItemExecution($data: ListableItemExecutionInput!) {
  listItemExecution(data: $data) {
    list {
      ${ITEM_EXECUTION_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_ASSIGN_ITEM_TO_ASSIGNEE = `
mutation assignItemExecutionToAssignee($data: AssignItemExecutionToUserInput!) {
  assignItemExecutionToAssignee(data: $data) {
    ok
  }
}
`

export const GQL_ASSIGN_ITEM_TO_REVIEWER = `
mutation assignItemExecutionToReviewer($data: AssignItemExecutionToUserInput!) {
  assignItemExecutionToReviewer(data: $data) {
    ok
  }
}
`

export const GQL_CONFIRM_ITEM_EXECUTION = `
mutation confirmItemExecution($data: ConfirmItemExecutionsInput!) {
  confirmItemExecution(data: $data) {
    ok
  }
}
`

export const GQL_UNLOCK_ITEM_EXECUTION = `
mutation unlockItemExecution($data: UnlockItemExecutionInput!) {
  unlockItemExecution(data: $data) {
    ok
  }
}
`

//
// Project Has User
//

export const GQL_LIST_PROJECT_HAS_USER = `
query listProjectHasUser($data: ListableProjectHasUserInput!) {
  listProjectHasUser(data: $data) {
    list {
      ${PROJECT_HAS_USER_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_REMOVE_PROJECT_HAS_USER = `
mutation removeProjectHasUser($data: ProjectHasUserRemoveInput!) {
  removeProjectHasUser(data: $data) {
    ok
  }
}
`

//
// Project Has Workset
//

export const GQL_LIST_PROJECT_HAS_WORKSET = `
query listProjectHasWorkset($data: ListableProjectHasWorksetInput!) {
  listProjectHasWorkset(data: $data) {
    list {
      ${PROJECT_HAS_WORKSET_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_REMOVE_PROJECT_HAS_WORKSET = `
mutation removeProjectHasWorkset($data: ProjectHasWorksetPropsInput!) {
  removeProjectHasWorkset(data: $data) {
    ok
  }
}
`

//
// Project MetaData
//

export const GQL_CREATE_PROJECT_METADATA_PRESET = `
mutation createProjectMetaDataPreset($data: ProjectMetaDataPresetCreationInput!) {
  createProjectMetaDataPreset(data: $data) {
    ${PROJECT_METADATA_PRESET_FULL_SNAPSHOT}
  }
}
`

export const GQL_UPDATE_PROJECT_METADATA_PRESET = `
mutation updateProjectMetaDataPreset($data: ProjectMetaDataPresetUpdateInput!) {
  updateProjectMetaDataPreset(data: $data) {
    ${PROJECT_METADATA_PRESET_FULL_SNAPSHOT}
  }
}
`

export const GQL_REMOVE_PROJECT_METADATA_PRESET = `
mutation removeProjectMetaDataPreset($data: SingleIDInput!) {
  removeProjectMetaDataPreset(data: $data) {
    ok
  }
}
`

export const GQL_LIST_PROJECT_METADATA_PRESET = `
query listProjectMetaDataPreset($data: ListableProjectMetaDataPresetInput!) {
  listProjectMetaDataPreset(data: $data) {
    list {
      ${PROJECT_METADATA_PRESET_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_PROJECT_METADATA_PRESET = `
query getProjectMetaDataPreset($data: SingleIDInput!) {
  getProjectMetaDataPreset(data: $data) {
    ${PROJECT_METADATA_PRESET_FULL_SNAPSHOT}
  }
}
`

export const GQL_CHECK_PROJECT_HAS_USER = `
query checkProjectHasUser($data: CheckProjectHasUserInput!) {
  checkProjectHasUser(data: $data) {
    list {
      workset {
        ${WORKSET_SNAPSHOT_WITH_FULL_PERMISSION} 
      }
      myWorksetPerm
      targetWorksetPerm
    }
  }
}
`

// itemExecutionResult
export const GQL_LIST_ITEM_EXECUTION_RESULT = `
query listItemExecutionResult($data: ListableItemExecutionResultInput!) {
  listItemExecutionResult(data: $data) {
    list {
      ${ITEM_EXECUTION_RESULT_FULL_SNAPSHOT} 
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_ITEM_EXECUTION_RESULT_METADATA = `
query getItemExecutionResultMetaData($data: GetItemExecutionResultMetaDataInput!) {
  getItemExecutionResultMetaData(data: $data) {
    ieId
    dataId
    title
    metaData
  }
}
`

export const GQL_LIST_ITEM_EXECUTION_RESULT_METADATA = `
query listItemExecutionResultMetaData($data: ListableItemExecutionResultMetaDataInput!) {
  listItemExecutionResultMetaData(data: $data) {
    list {
      ieId
      dataId
      title
      metaData
    }
    totalCount
    page
    pageSize
  }
}
`
