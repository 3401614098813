import { ORG_ROLE, PROJECT_USER_PERM, projectUserPermissionToString } from '@cils/common'
import { ODHSpace, ODListableResponseType, ODModalSize } from '@odc/od-react-belt'
import moment from 'moment'
import React from 'react'
import { GQLProject } from '../../../@types/server'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { useAPIs } from '../../../context/useAPIs'
import { useOrgPerm } from '../../../context/useOrgPerm'
import { ODColors } from '../../../global-styles'
import { ODListableOption } from '../../../ODListable/ODListableContext'
import { useWorksetPickerTableModal } from '../WorksetGroup/useWorksetPickerTableModal'
import { IProjectListableOption } from './ProjectCommon'

export interface IProjectPickerListableOption extends ODListableOption {
  filter: string
}

const THeads = ['Project name', 'Project Type', 'Items', 'Owner', 'Project Created', 'Your Permission']

export function useProjectPickerModal() {
  const { orgId } = useODSingleOrgContext()
  const { listProject, listProjectInOrg } = useAPIs()

  // Manage Organization Project 권한인지?
  const isManageOrganizationProject = useOrgPerm(orgId)?.isAllowed(ORG_ROLE.EDIT_ORG_PROJECT) || false

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectListableOption
    ): Promise<ODListableResponseType<GQLProject>> {
      if (isManageOrganizationProject) {
        const r = await listProjectInOrg({
          page,
          pageSize,
          ...options,
          orgId: orgId,
        })
        return r as ODListableResponseType<GQLProject>
      } else {
        const r = await listProject({
          page,
          pageSize,
          ...options,
        })
        return r as ODListableResponseType<GQLProject>
      }
    },
    [listProjectInOrg, listProject, isManageOrganizationProject, orgId]
  )

  const confirmButtonTitle = 'Next'
  const confirmButtonLeftText = 'Only Project with Owner, Admin, Editor permission can be selected.\n'
  return useWorksetPickerTableModal<GQLProject, IProjectListableOption>({
    size: ODModalSize.XLarge,
    pageSpan: 10,
    rounded: false,
    hasCurrentPageToggle: true,
    disabledSubmit: true,
    pickDefaultOptions: {
      confirmButtonTitle,
    },
    dataLoader: dataLoader,
    keyExtractor: v => v.projectId,
    viewerExtractor: v => v.myPerm === PROJECT_USER_PERM.Viewer, // 해당 워크셋에서 나의 권한이 뷰어인지
    numColumns: 7,
    renderConfirmButtonLeftText: () => (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
        <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 14, color: ODColors.Primary }} />
        <ODHSpace w={6} />
        <span style={{ fontSize: 14, color: ODColors.Primary }}>{confirmButtonLeftText}</span>
      </div>
    ),
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, v: GQLProject) => {
      switch (index) {
        case 0:
          return v.name ?? '-'
        case 1:
          return v.type ?? '-'
        case 2:
          return v.numItems ?? 0
        case 3:
          const owner = v.owner
          return <div>{owner?.name || '-'}</div>
        case 4:
          return v.createdAt ? moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'
        case 5:
          return v.myPerm ? projectUserPermissionToString(v.myPerm) : '-'
      }
    },
  })
}
