import { AGENT_LOG_CATEGORY, LOG_LEVEL } from '@cils/common'
import React from 'react'
import { LazyLog } from 'react-lazylog'
import { Card, CardBody, Label } from 'reactstrap'
import styled from 'styled-components'
import { GQLLogNotification } from '../../../agent'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { DropdownMenu, ODMultiSelectDropdown } from '../../../components/ODComponents/ODMultiSelectDropdown'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { ODSearchBox } from '../../../components/ODSearchBox'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import { useODSubscription } from '../../../context/ODCommon'

type SyncLiveLogTableProps = {}

const GQL_SUBSCRIBE_LOGS = `
subscription onNewLogs {
  onNewLogs {
    log
    level
    category
  }
}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 99;
  height: 100%;
`

const AutoScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 0;
`
const AutoScrollLabel = styled(Label)`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`
const AutoScrollInput = styled.input`
  margin-right: 7px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
`

const INITIAL_TEXT = 'Waiting for logs...\n\n\n'
const MAX_LINES = 1000

const DROPDOWN_LOG_LEVELS: Array<DropdownMenu<number>> = [
  { key: 1, value: LOG_LEVEL.DEBUG, name: 'DEBUG' },
  { key: 2, value: LOG_LEVEL.INFO, name: 'INFO' },
  { key: 3, value: LOG_LEVEL.WARN, name: 'WARN' },
  { key: 4, value: LOG_LEVEL.ERROR, name: 'ERROR' },
]

const DROPDOWN_CATEGORY: Array<DropdownMenu<string>> = [
  { key: 1, value: AGENT_LOG_CATEGORY.DEFAULT, name: 'Default' },
  { key: 2, value: AGENT_LOG_CATEGORY.DOWNLOAD, name: 'Download' },
  { key: 3, value: AGENT_LOG_CATEGORY.SCAN, name: 'Scan' },
  { key: 4, value: AGENT_LOG_CATEGORY.UPLOAD, name: 'Upload' },
]

export const SyncLiveLogTable: React.FC<SyncLiveLogTableProps> = () => {
  const { client } = useCILSAgentContext()
  const { data } = useODSubscription<void, GQLLogNotification>(GQL_SUBSCRIBE_LOGS, { client })
  const [autoScroll, setAutoScroll] = React.useState(false)
  const [pauseUpdate, setPauseUpdate] = React.useState(false)
  const [list, setList] = React.useState<Array<GQLLogNotification>>([])
  const [text, setText] = React.useState(INITIAL_TEXT)
  const [filter, setFilter] = React.useState('starting')
  const [logLevels, setLogLevels] = React.useState<Array<number>>([
    LOG_LEVEL.DEBUG,
    LOG_LEVEL.INFO,
    LOG_LEVEL.WARN,
    LOG_LEVEL.ERROR,
  ])
  const [category, setCategory] = React.useState<Array<string>>([
    AGENT_LOG_CATEGORY.DEFAULT,
    AGENT_LOG_CATEGORY.SCAN,
    AGENT_LOG_CATEGORY.DOWNLOAD,
    AGENT_LOG_CATEGORY.UPLOAD,
  ])

  const mapItemToText = React.useCallback(
    (item: GQLLogNotification) => {
      if (logLevels.indexOf(item.level) < 0) {
        return ''
      }

      if (category.indexOf(item.category) < 0) {
        return ''
      }

      if (filter.length > 0) {
        if (!item.log.includes(filter)) {
          return ''
        }
      }
      return item.log + '\n'
    },
    [filter, logLevels, category]
  )

  React.useEffect(() => {
    if (data?.onNewLogs) {
      const log = data!.onNewLogs
      setList(list => {
        if (list.length > MAX_LINES) {
          const [, ...oneLittleArr] = list
          return [...oneLittleArr, log]
        }
        return [...list, log]
      })
    }
  }, [data, data?.onNewLogs])

  React.useEffect(() => {
    if (!pauseUpdate) {
      setText(list.reduce((txt, item) => txt + mapItemToText(item), INITIAL_TEXT))
    }
  }, [list, mapItemToText, pauseUpdate])

  return (
    <Wrapper>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2, height: '100%' }}>
        <CardBody>
          <FlexContentsContainer style={{ height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <ODSearchBox
                placeholder="Filter with keyword"
                style={{ flexGrow: 8, maxWidth: 600 }}
                value={filter}
                onChange={e => setFilter(e)}
              />
              <div style={{ flexGrow: 2, minWidth: 150, marginLeft: 12 }}>
                <ODMultiSelectDropdown
                  title="Log levels"
                  items={DROPDOWN_LOG_LEVELS}
                  checkedItems={logLevels}
                  setCheckedItems={setLogLevels}
                />
              </div>
              <div style={{ flexGrow: 2, minWidth: 150, marginLeft: 12 }}>
                <ODMultiSelectDropdown
                  title="Scopes"
                  items={DROPDOWN_CATEGORY}
                  checkedItems={category}
                  setCheckedItems={setCategory}
                />
              </div>
              <AutoScrollWrapper>
                <AutoScrollLabel>
                  <AutoScrollInput
                    type="checkbox"
                    value="Auto reload"
                    checked={autoScroll}
                    onChange={(e: any) => setAutoScroll(e.target.checked)}
                  />
                  Auto scroll
                </AutoScrollLabel>
              </AutoScrollWrapper>
              <AutoScrollWrapper>
                <AutoScrollLabel>
                  <AutoScrollInput
                    type="checkbox"
                    value="Pause update"
                    checked={pauseUpdate}
                    onChange={(e: any) => setPauseUpdate(e.target.checked)}
                  />
                  Pause update
                </AutoScrollLabel>
              </AutoScrollWrapper>
              <div style={{ fontSize: 14, padding: 12, cursor: 'pointer' }}>
                <ODIcon icon={ODIcons.CoreX} onClick={() => setList([])} />
              </div>
              <div style={{ fontSize: 14, padding: 12, cursor: 'pointer' }}>
                <ODIcon icon={ODIcons.CoreDataTransferDown} onClick={() => console.log(text)} />
              </div>
            </div>
            <div style={{ flexGrow: 99 }}>
              <LazyLog text={text} extraLines={1} follow={autoScroll} />
            </div>
          </FlexContentsContainer>
        </CardBody>
      </Card>
    </Wrapper>
  )
}
