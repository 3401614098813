import React from 'react'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { TCFDetailModal } from '../TCFDetailModal'

type Props = {
  itemId: string
  snapshotId?: string
}

export const SingleTCFViewContainer: React.FC<Props> = ({ ...props }) => {
  const { itemId /*, snapshotId */ } = props
  const [itemOpened, setItemOpened] = React.useState(true)
  const [lastTCFUpdated, setLastTCFUpdated] = React.useState(0)

  // TODO: snapshotId

  return (
    <FlexContentsContainer>
      <TCFDetailModal
        isOpen={itemOpened}
        toggle={() => setItemOpened(false)}
        itemId={parseInt(itemId, 10)}
        lastTCFUpdatedTime={lastTCFUpdated}
        onTCFItemChanged={() => setLastTCFUpdated(0)}
        onTCFItemUpdated={() => setLastTCFUpdated(new Date().getTime())}
        onLoadMore={() => null}
      />
    </FlexContentsContainer>
  )
}
