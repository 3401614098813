import React from 'react'
import styled from 'styled-components'
import NoIcoImage from '../assets/img/ic-noitems.png'

type NoImageDivProps = {
  style?: object
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2e343a;
  width: 100%;
  height: 100%;
`

export const NoImageDiv: React.FC<NoImageDivProps> = props => {
  const { style = {} } = props

  return (
    <Wrapper style={style}>
      <img src={NoIcoImage} width={74} height={79} alt="No data" />
    </Wrapper>
  )
}
