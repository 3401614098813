import { PROJECT_TYPE } from '@cils/common'
import { ODVSpace } from '@odc/od-react-belt'
import React, { useRef } from 'react'
import Select from 'react-select'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { Utils } from '../../../utils'
import { ODButton, ODButtonTheme } from '../../ODButton'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalFooter } from '../../ODModal/ODModalFooter'
import { ODModalHeader } from '../../ODModal/ODModalHeader'
import { ODToastType, showODToast } from '../../ODToast'

interface SelectProjectType {
  id: number
  label: string
  value: PROJECT_TYPE
}

interface IODModalCreateProject {
  isOpen: boolean
  onClose: () => void
  onCreate: (name: string, projectType: PROJECT_TYPE) => Promise<void>
  isEdit?: boolean
  existingName?: string
}

const createProjectTypes = Object.values(PROJECT_TYPE).map((item, index) => {
  return {
    id: index,
    label: Utils.formatProjectType(item),
    value: item,
  }
})

export const ODModalCreateProject: React.FC<IODModalCreateProject> = props => {
  const { isOpen, onClose, onCreate, isEdit, existingName = '' } = props
  const [projectName, setProjectName] = React.useState(existingName || '')
  const [projectTypes] = React.useState<SelectProjectType[]>(() => createProjectTypes)
  const [selectedProjectType, setSelectedProjectType] = React.useState<SelectProjectType>(() => createProjectTypes[0])
  const selectFocus = useRef<HTMLInputElement>(null)

  const handleCreate = React.useCallback(async () => {
    if (!projectName || projectName === '' || projectName.trim().length < 2 || projectName.trim().length > 40) {
      showODToast(ODToastType.Error, 'Error', 'Name must be 2 to 40 characters long.')
      return
    }
    await onCreate(projectName, selectedProjectType.value)
    setProjectName('')
    onClose()
  }, [projectName, onCreate, selectedProjectType])

  const modalTitle = isEdit ? 'Edit Project Information' : 'Create Project'
  const inputName = isEdit ? 'Project Name' : 'Project Name *'
  const inputName2 = 'Project Type *'
  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal} style={{ borderRadius: 4 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={modalTitle} onClose={onClose} />
        <ODModalBody>
          <div>
            <InputNameRow>{inputName}</InputNameRow>
            <ODVSpace h={9} />
            <Input type="text" placeholder="" onChange={e => setProjectName(e.target.value)} value={projectName} />
          </div>
          <ODVSpace h={30} />
          <div>
            <InputNameRow>{inputName2}</InputNameRow>
            <ODVSpace h={9} />
            <CustomSelectBox
              isDisabled={projectTypes.length === 1}
              name="project Type"
              options={projectTypes}
              value={selectedProjectType}
              onChange={(e: React.SetStateAction<SelectProjectType>) => {
                setSelectedProjectType(e)
              }}
              ref={selectFocus}
            />
          </div>
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleCreate}>
            {isEdit ? 'Save' : 'Create'}
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

const InputNameRow = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #73818f;
`

const CustomSelectBox = styled(Select)`
  width: 100%;
`
