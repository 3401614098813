import { ODFlexContentsContainer, ODHSpace, ODHSpread, ODPillbox, ODRow, ODVLine } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { useCILSAgentContext } from '../../context/CILSAgentContext'
import { useODAppContext } from '../../context/ODAppContext'
import { ODColors } from '../../global-styles'
import { ODIcon, ODIcons } from '../ODIcon'

interface IAgentStatusBarProps {
  agentDownloadLink?: string
  agentVersion?: string
  agentManualLink?: string
}

const VSplit = styled.div`
  width: 1px;
  height: 13px;
  border: solid 1px #c8ced3;
`

const StatusSpan = styled.span`
  font-size: 11px;
  line-height: 1.64;
  font-weight: bold;
  color: #708190;
`

const DownloaderSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: bold;
  color: ${ODColors.Primary};
`

const AgentButton = styled(ODPillbox)`
  height: 28px;
  font-size: 12px;
  color: ${ODColors.Primary};
  border: solid 1px ${ODColors.Primary};
`

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${ODColors.Primary};
  color: white;
`

const OrgName = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  color: #708190;
`

const InfoGrayText = styled.span`
  font-size: 12px;
  color: #73818f;
`

const OnOffBadge = ({ on }: { on: boolean }) => {
  return (
    <ODPillbox
      bg_color={on ? '#4dbd74' : '#c8ced3'}
      border_radius={4}
      padding_lr="8px"
      padding_td="1px"
      style={{ color: on ? 'white' : '#2f353a', fontSize: 10, minWidth: 40 }}
    >
      {on ? 'ON' : 'OFF'}
    </ODPillbox>
  )
}

export const AgentStatusBar: React.FC = () => {
  const {
    state: { appConfig },
  } = useODAppContext()
  const {
    state: { connected, loginInfo },
  } = useCILSAgentContext()

  const agentDownloadLink = appConfig?.agentDownloadLink ?? ''
  const agentManualLink = appConfig?.agentManualLink ?? ''
  const agentVersion = appConfig?.agentVersion ?? ''
  const isDownloadOnly = !loginInfo?.isAgentAccount

  return (
    <ODFlexContentsContainer>
      <div style={{ backgroundColor: 'white', height: 45, justifyContent: 'center', display: 'flex' }}>
        <ODHSpread>
          <ODRow style={{ marginLeft: 16, fontSize: 13, color: '#000000' }}>
            Agent
            <ODHSpace w={12} />
            <ODPillbox bg_color="#f0f3f5" border_radius={16} padding_lr="12px" padding_td="2px" style={{ height: 28 }}>
              <StatusSpan>Status :</StatusSpan>
              <ODHSpace w={4} />
              <OnOffBadge on={connected} />
              {connected && (
                <>
                  <ODHSpace w={7} />
                  <ODVLine color="#c8ced3" height={13} />
                  <ODHSpace w={8} />
                  {isDownloadOnly && (
                    <DownloaderSpan>
                      <span style={{ marginTop: 2, marginRight: 3 }}>
                        <ODIcon icon={ODIcons.CoreDataTransferDown} />
                      </span>
                      Downloader
                    </DownloaderSpan>
                  )}
                  {!isDownloadOnly && (
                    <DownloaderSpan>
                      <span style={{ marginTop: 2, marginRight: 3 }}>
                        <ODIcon icon={ODIcons.CoreSync} />
                      </span>
                      Agent
                    </DownloaderSpan>
                  )}
                </>
              )}
            </ODPillbox>
            {connected && (
              <>
                <ODHSpace w={10} />
                <OrgName>{loginInfo?.orgName ?? 'Unknown'}</OrgName>
                <ODHSpace w={8} />
                <VSplit />
                <ODHSpace w={7} />
                <InfoGrayText>
                  {loginInfo?.name ?? '-'} ({loginInfo?.email ?? '-'}) / Machine ID : {loginInfo?.machineId ?? '-'}
                </InfoGrayText>
              </>
            )}
          </ODRow>
          <ODRow style={{ marginLeft: 24, marginRight: 24 }}>
            <a href={agentDownloadLink} target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'none' }}>
              <AgentButton bg_color="#dff4f7" border_radius={25} padding_lr={4} padding_td={2}>
                <ODHSpace w={10} />
                Agent Latest Ver.{agentVersion}
                <ODHSpace w={10} />
                <IconWrapper>
                  <ODIcon icon={ODIcons.CoreDataTransferDown} />
                </IconWrapper>
              </AgentButton>
            </a>
            <ODHSpace w={13} />
            <a href={agentManualLink} target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'none' }}>
              <AgentButton bg_color="#dff4f7" border_radius={25} padding_lr={16} padding_td={2}>
                Agent Manual
              </AgentButton>
            </a>
          </ODRow>
        </ODHSpread>
      </div>
    </ODFlexContentsContainer>
  )
}
