import React from 'react'
import styled from 'styled-components'

type SelectOptionsProps = {
  buttonWidth?: number
  height?: number
  value: Array<string>
  name: string
  onChange?: (v: string) => void
  onClick?: () => void
  selectedValue?: string
}

const Wrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #f0f3f5;
`

export const OptionButton = styled.input`
  &[type='radio'] {
    display: none;
  }

  &[type='radio'] + label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #73818f;
    border: 0;
    background-color: #f0f3f5;
    cursor: pointer;
  }

  &[type='radio']:checked + label {
    color: #01b9cd;
    border: solid 1px #01b9cd;
    background-color: #ffffff;
    cursor: pointer;
  }
`

export const SelectOptions: React.FC<SelectOptionsProps> = props => {
  const { buttonWidth = 120, height = 34, value, name, onClick, onChange, selectedValue } = props

  return (
    <Wrapper style={{ width: buttonWidth * value.length, height }}>
      {value.map((v, i) => {
        return (
          <React.Fragment key={i}>
            <OptionButton
              type="radio"
              name={name}
              id={v}
              key={i}
              checked={v === selectedValue}
              onClick={onClick}
              onChange={() => (onChange ? onChange(v) : console.log(3910, v))}
            />
            <label htmlFor={v}>{v}</label>
          </React.Fragment>
        )
      })}
    </Wrapper>
  )
}
