import { ODHSpace, ODVSpace } from '@odc/od-react-belt'
import React, { useRef, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { GQLWorkset } from '../../../@types/server'
import { ODButton, ODButtonTheme } from '../../../components/ODButton'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { FilterButton } from '../../../components/ODImageGrid/FilterButton'
import { ODModalBody } from '../../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../../components/ODModal/ODModalHeader'
import { CategoryOption, useWorksetGroupList } from '../../../context/ODCILSHooks'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { useAPIs } from '../../../context/useAPIs'
import { ODColors } from '../../../global-styles'
import { Utils } from '../../../utils'

interface IOdModalWorksetGroupBody {
  onClose: () => void
  onSave: (wsgIds: number[]) => Promise<void>
  title: string
  data: GQLWorkset
}

export const OdModalWorksetGroupBody: React.FC<IOdModalWorksetGroupBody> = props => {
  const { onClose, onSave, title, data } = props
  const { orgId } = useODSingleOrgContext()
  const { removeWorksetGroupHasWorkset } = useAPIs()
  const { categories: worksetGroups } = useWorksetGroupList(orgId)
  const [worksetGroup, setWorksetGroup] = useState<CategoryOption | null>(null)
  // 이미 데이터가 존재한다면, 삭제를 할 때 remove api를 요청해야 하기 때문에 아래의 state에 존재하는 데이터를 담고, 이미 데이터베이스에 있는 id를 삭제
  // 할 경우 remove api를 요청하고 해당 id를 지운다.
  // const [existingGroupIds, setExistingGroupIds] = React.useState<number[]>(() => {
  //   if (data) {
  //     return data.worksetGroups.map(group => group.wsgId)
  //   } else {
  //     return []
  //   }
  // })

  const [selectedWorksetGroups, setSelectedWorksetGroups] = React.useState<CategoryOption[]>(() => {
    if (data) {
      return data.worksetGroups.map(group => ({
        categoryId: group.wsgId,
        value: group.wsgId.toString(),
        label: group.name ?? '-',
      }))
    } else {
      return []
    }
  })
  const selectFocus = useRef<HTMLInputElement>(null)

  return (
    <ModalContainer>
      <ODModalHeader title={title} onClose={onClose} />
      <ODModalBody style={{ paddingBottom: 30 }}>
        <GroupBox>
          <GroupBoxHeader>
            <IconBox>
              <ODIcon icon={ODIcons.CoreObjectGroup} style={{ color: ODColors.Tealish, fontSize: 18 }} />
            </IconBox>
            <ODHSpace w={26} />
            <PrimaryText>Group</PrimaryText>
          </GroupBoxHeader>
          <GroupBoxBody>
            {selectedWorksetGroups.length === 0 && (
              <NoGroupsBox>
                <ODIcon icon={ODIcons.CoreFrown} style={{ fontSize: 34, color: ODColors.Primary }} />
                <ODVSpace h={4} />
                <PrimaryText>No Groups</PrimaryText>
              </NoGroupsBox>
            )}
            {selectedWorksetGroups.length > 0 &&
              selectedWorksetGroups.map((group, index) => {
                return (
                  <FilterButton
                    key={group.categoryId}
                    onRemove={async () => {
                      try {
                        await removeWorksetGroupHasWorkset({ wsgId: group.categoryId, worksetId: data.wsId })
                        setSelectedWorksetGroups(p => p.filter(g => g.categoryId !== group.categoryId))
                      } catch (ex) {
                        Utils.showError(ex)
                      }
                    }}
                    color={ODColors.Tealish2}
                    isRemove
                    isClearAll={false}
                    label={group.label}
                  />
                )
              })}
          </GroupBoxBody>
        </GroupBox>
      </ODModalBody>
      <ODModalFooter>
        <FooterColumnContainer>
          <SelectName>Add to other Workset Group</SelectName>
          <ODVSpace h={12} />
          <CustomSelectBox
            name="category"
            options={worksetGroups}
            value={worksetGroup}
            onChange={(e: React.SetStateAction<CategoryOption | null>) => {
              setWorksetGroup(e)
            }}
            ref={selectFocus}
          />
          <ODVSpace h={6} />
          <SelectSubName>Type Workset Group Name</SelectSubName>
          <ODVSpace h={20} />
          <ODButton
            theme={ODButtonTheme.Primary}
            onClick={async () => {
              if (!worksetGroup) {
                return
              }

              const find = selectedWorksetGroups.find(existing => existing.value === worksetGroup.value)

              if (find) {
                Utils.showInfo('Already exists.')
                return
              }
              try {
                await onSave([worksetGroup.categoryId])

                setSelectedWorksetGroups(p => [...p, worksetGroup])
              } catch (ex) {
                Utils.showError(ex)
              }
            }}
            style={{
              width: '100%',
              height: 35,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Add
          </ODButton>
        </FooterColumnContainer>
      </ODModalFooter>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ODColors.White};
`

const GroupBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 182px;
`

const GroupBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  width: 100%;
  height: 55px;
  background-color: ${ODColors.PaleGrey2};
  border: 1px solid ${ODColors.Primary};
`

const GroupBoxBody = styled.div`
  display: flex;
  flex: 1;
  border-left: 1px solid ${ODColors.Silver};
  border-right: 1px solid ${ODColors.Silver};
  border-bottom: 1px solid ${ODColors.Silver};
  padding: 14px;
  flex-wrap: wrap;
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${ODColors.LightBlueGrey};
  padding: 14px;
`

const NoGroupsBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const PrimaryText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.Primary};
  font-weight: bold;
`

const FooterColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 37px 40px;
  background-color: ${ODColors.PaleGrey};
`

const SelectName = styled.span`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0;
  color: ${ODColors.Steel};
`

const CustomSelectBox = styled(Select)`
  width: 100%;
  margin-top: 9px;
`

const SelectSubName = styled.span`
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0;
  color: ${ODColors.Steel};
`
