import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODAppContext } from '../../../context/ODAppContext'
import { SiteUrls } from '../../../urls'

const Title = styled.span`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #29b6ca;
`

const Key = styled.span`
  width: 116px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #73818f;
`

const Value = styled.span`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #2e343a;
`

const Width = styled.div`
  width: 116px;
  margin-right: 51px;
`

// const Line = styled.div`
//   display: flex;
//   flex: 1;
//   height: 1px;
//   background-color: #c8ced3;
//   margin-top: 40px;
//   margin-bottom: 32px;
// `

const Button = styled.div`
  padding: 5px 8px;
  border-radius: 3px;
  border: solid 1px #29b6ca;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.21px;
  text-align: center;
  color: #29b6ca;
  cursor: pointer;
`
const NoteIcon = styled.i`
  font-size: 16px;
  letter-spacing: 0.34px;
  color: #29b6ca;
  cursor: pointer;
`
// const ResearchValue = styled.span`
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: 0.5px;
//   color: #73818f;
// `
const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export const MyPageContainer: React.FC = props => {
  const {
    state: { userProfile },
  } = useODAppContext()

  // const [isOpenPopover, setIsOpenPopover] = React.useState<boolean>(false)

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 26, flexGrow: 2 }}>
        <CardHeader>My Page</CardHeader>
        <CardBody>
          <div style={{ marginBottom: 10 }}>
            <Title style={{ marginRight: 8 }}>Account</Title>
            <StyledLink to={SiteUrls.User.MyPage.EditProfile}>
              <NoteIcon className="icon-note icons" />
            </StyledLink>
            <div style={{ marginTop: 26, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Width>
                <Key>User ID</Key>
              </Width>
              <Value>{userProfile?.username || ''}</Value>
            </div>
            <div style={{ marginTop: 26, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Width>
                <Key>Username</Key>
              </Width>
              <Value>{userProfile?.name || 'None'}</Value>
            </div>
            <div style={{ marginTop: 23, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Width>
                <Key>Email</Key>
              </Width>
              <Value>{userProfile?.email || 'None'}</Value>
            </div>
            <div style={{ marginTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Width>
                <Key>Password</Key>
              </Width>
              <StyledLink to={SiteUrls.User.MyPage.ChangePassword}>
                <Value>
                  <Button>Change Password</Button>
                </Value>
              </StyledLink>
            </div>
            {/*<Line />*/}
            {/*<Title>Research</Title>*/}
            {/*<div style={{ marginTop: 26, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>*/}
            {/*  <Width>*/}
            {/*    <Key>Organization</Key>*/}
            {/*  </Width>*/}
            {/*  <ResearchValue>{organizations[0].org.name || 'None'}</ResearchValue>*/}
            {/*</div>*/}
            {/*<div style={{ marginTop: 23, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>*/}
            {/*  <Width>*/}
            {/*    <Key>Permissions</Key>*/}
            {/*  </Width>*/}
            {/*  <ResearchValue style={{ marginRight: 8 }}>*/}
            {/*    {ORG_USER_PRIV_TO_STRING[organizations[0].orgUserPriv as ORG_USER_PRIV] || 'None'}*/}
            {/*  </ResearchValue>*/}
            {/*  <HelpOutlineOutlinedIcon*/}
            {/*    style={{ fontSize: 19, color: '#2dadd3', letterSpacing: 0.41 }}*/}
            {/*    id="popover"*/}
            {/*    onMouseEnter={() => setIsOpenPopover(true)}*/}
            {/*    onMouseLeave={() => setIsOpenPopover(false)}*/}
            {/*  />*/}
            {/*  <Popover placement="right-start" isOpen={isOpenPopover} target="popover">*/}
            {/*    <PopoverHeader>General Researcher</PopoverHeader>*/}
            {/*    <PopoverBody>*/}
            {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer cursus massa sed felis congue*/}
            {/*      facilisis. Praesent aliquet, lorem at mollis vulputate, ligula mauris luctus tellus, eget fringilla*/}
            {/*      metus sapien et ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer cursus massa*/}
            {/*      sed felis congue facilisis. Praesent aliquet, lorem at mollis vulputate, ligula mauris luctus tellus,*/}
            {/*      eget fringilla metus sapien et ante.*/}
            {/*    </PopoverBody>*/}
            {/*  </Popover>*/}
            {/*</div>*/}
          </div>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
