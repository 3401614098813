import { projectUserPermissionToString, worksetUserPermissionToString } from '@cils/common'
import { BlockingLoadBox, ODHSpace, ODModalFooter, ODVSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { GQLWorkset } from '../../../@types/server'
import { useCILSStore } from '../../../di/configureRootStore'
import { ODColors } from '../../../global-styles'
import { SiteUrls } from '../../../urls'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalHeader } from '../../ODModal/ODModalHeader'
import { ODModalShare } from '../../ODModal/ODModalShare'

interface IODModalAddWorksetsToProjectResult {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  orgId: number
  openAddWorkset?: () => void
  openSelectProject?: () => void
}

export const ODModalAddWorksetsToProjectResult: React.FC<IODModalAddWorksetsToProjectResult> = observer(props => {
  const { isOpen, setIsOpen, orgId, openAddWorkset, openSelectProject } = props
  const store = useCILSStore()
  const [worksetToShare, setWorksetToShare] = React.useState<GQLWorkset | null>(null)

  const handleDone = () => {
    setIsOpen(false)
  }

  const handleShareWorkset = async (workset: GQLWorkset) => {
    setWorksetToShare(workset)
  }

  const handleOpenAddModal = () => {
    if (openAddWorkset) {
      openAddWorkset()
    }
    if (openSelectProject) {
      openSelectProject()
    }
    setIsOpen(false)
  }

  const list = store.addWorksetToProjectStore.submitResults

  const title = 'Result'
  return (
    <ODModal isOpen={isOpen} toggle={() => setIsOpen(false)} size={ODModalSize.Big} style={{ maxWidth: 1210 }}>
      {worksetToShare && (
        <ODModalShare
          isOpen
          setIsOpen={() => setWorksetToShare(null)}
          wsId={worksetToShare!.wsId}
          title={`Share setting for ${worksetToShare!.name}`}
        />
      )}
      <BlockingLoadBox show={store.addWorksetToProjectStore.isSubmitting} />
      <ODModalHeader title={title} onClose={() => setIsOpen(false)} />
      <ODModalBody style={{ padding: 0, paddingLeft: 36, paddingRight: 36 }}>
        <Table responsive striped style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}>
          <thead>
            <tr>
              {openSelectProject && <th>Project Name</th>}
              <th style={{ maxWidth: 314 }}>Workset name</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {list.map((res, index) => {
              const worksetId = res.workset.wsId
              const worksetName = res.workset.name

              return (
                <tr key={worksetId}>
                  {openSelectProject && store.addWorksetToProjectStore.projectId && (
                    <td style={{ fontWeight: 'bold' }}>
                      <a
                        href={SiteUrls.User.Org.Project.Detail(store.addWorksetToProjectStore.projectId)(orgId)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {store.addWorksetToProjectStore.projectName ?? '-'}
                      </a>
                    </td>
                  )}
                  <td style={{ maxWidth: 314, textDecoration: 'none', fontSize: 14, fontWeight: 'bold' }}>
                    <a
                      href={SiteUrls.User.Org.Workset.Detail(worksetId)(orgId)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {worksetName ?? '-'}
                    </a>
                  </td>
                  <td>
                    {res.isSuccess && (
                      <ColumnRow>
                        <StatusBox>Success</StatusBox>
                        {res.messages.length > 0 && (
                          <>
                            <ODVSpace h={11} />
                            <ResultText>
                              Automatically added workset after changing user permission of {worksetName ?? '-'}.
                            </ResultText>
                            <ODVSpace h={10} />
                          </>
                        )}
                        {res.messages.map(item => {
                          console.log(item.user.name)
                          console.log(item.beforeProjectPerm)
                          console.log(item.beforePermLevel)
                          console.log(item.afterProjectPerm)
                          console.log(item.afterPermLevel)
                          return (
                            <Row>
                              <UserNameText>{item.user.name} :</UserNameText>
                              {item.afterPermLevel && (
                                <>
                                  <ODHSpace w={6} />
                                  <UserNameText>(workset)</UserNameText>
                                  <UserPermText>
                                    {item.beforePermLevel
                                      ? worksetUserPermissionToString(item.beforePermLevel)
                                      : 'Not in workset'}
                                  </UserPermText>
                                  <ODHSpace w={3} />
                                  <ODIcon
                                    icon={ODIcons.MaterialArrowForwardIcon}
                                    style={{
                                      width: 12,
                                      height: 12,
                                      fontSize: 12,
                                      color: ODColors.Primary,
                                      position: 'relative',
                                      top: 1,
                                    }}
                                  />
                                  <ODHSpace w={3} />
                                  <UserChangedPermText>
                                    {item.afterPermLevel ? worksetUserPermissionToString(item.afterPermLevel) : '-'}
                                  </UserChangedPermText>
                                </>
                              )}
                              {item.afterProjectPerm && item.beforeProjectPerm !== item.afterProjectPerm && (
                                <>
                                  <ODHSpace w={6} />
                                  <UserNameText>(project)</UserNameText>
                                  <UserPermText>
                                    {item.beforeProjectPerm
                                      ? projectUserPermissionToString(item.beforeProjectPerm)
                                      : 'Not in Project'}
                                  </UserPermText>
                                  <ODHSpace w={3} />
                                  <ODIcon
                                    icon={ODIcons.MaterialArrowForwardIcon}
                                    style={{
                                      width: 12,
                                      height: 12,
                                      fontSize: 12,
                                      color: ODColors.Primary,
                                      position: 'relative',
                                      top: 1,
                                    }}
                                  />
                                  <ODHSpace w={3} />
                                  <UserChangedPermText>
                                    {item.afterProjectPerm ? projectUserPermissionToString(item.afterProjectPerm) : '-'}
                                  </UserChangedPermText>
                                </>
                              )}
                            </Row>
                          )
                        })}

                        {/* 해당 사용자의 Workset 권한을 조회하고 수정할 수 있는 기능 */}
                        {res.messages.length > 0 && (
                          <>
                            <ODVSpace h={10} />
                            <Row
                              style={{ alignItems: 'center', cursor: 'pointer' }}
                              onClick={() => handleShareWorkset(res.workset)}
                            >
                              <ODIcon icon={ODIcons.CoreShieldAlt} style={{ fontSize: 14, color: ODColors.Primary }} />
                              <ODHSpace w={6} />
                              <span style={{ fontSize: 14, color: ODColors.Primary }}>Check workset permission</span>
                            </Row>
                          </>
                        )}
                      </ColumnRow>
                    )}
                    {!res.isSuccess && (
                      <ColumnRow>
                        <StatusBox style={{ backgroundColor: ODColors.Salmon }}>Failed</StatusBox>
                        <ODVSpace h={10} />
                        <ResultText>You cannot change permission of {worksetName ?? '-'}</ResultText>
                        <ODVSpace h={10} />
                        {/* 해당 사용자의 Workset 권한을 조회하고 수정할 수 있는 기능 */}
                        <Row
                          style={{ alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => handleShareWorkset(res.workset)}
                        >
                          <ODIcon icon={ODIcons.CoreShieldAlt} style={{ fontSize: 14, color: ODColors.Primary }} />
                          <ODHSpace w={6} />
                          <span style={{ fontSize: 14, color: ODColors.Primary }}>Check workset permission</span>
                        </Row>
                        <ODVSpace h={7} />
                        {/* 해당 유저를 프로젝트에 추가하는 것을 다시 시도 */}
                        <Row style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => res.retry()}>
                          <ODIcon icon={ODIcons.CoreReload} style={{ fontSize: 14, color: ODColors.Primary }} />
                          <ODHSpace w={6} />
                          <span style={{ fontSize: 14, color: ODColors.Primary }}>Retry to add this workset</span>
                        </Row>
                      </ColumnRow>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ODModalBody>
      <ODVSpace h={10} />
      <ODModalFooter>
        <ButtonsBox style={{ width: '100%' }}>
          {openSelectProject && store.addWorksetToProjectStore.projectId && (
            <>
              <BorderPrimaryButton>
                <a
                  href={SiteUrls.User.Org.Project.Detail(store.addWorksetToProjectStore.projectId)(orgId)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to the project
                </a>
              </BorderPrimaryButton>
              <BorderPrimaryButton style={{ marginLeft: 10, marginRight: 'auto' }} onClick={handleOpenAddModal}>
                Add to other project
              </BorderPrimaryButton>
            </>
          )}
          {openAddWorkset && <BorderPrimaryButton onClick={handleOpenAddModal}>Add more Worksets</BorderPrimaryButton>}{' '}
          <ODHSpace w={10} />
          <DoneButton onClick={handleDone}>Done</DoneButton>
        </ButtonsBox>
      </ODModalFooter>
      <ODVSpace h={30} />
    </ODModal>
  )
})

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const StatusBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 16px;
  border-radius: 4px;
  background-color: ${ODColors.DarkMint};

  font-size: 10px;
  font-weight: bold;
  color: ${ODColors.White};
`

const ResultText = styled.span`
  font-size: 12px;
  color: ${ODColors.CharcoalGrey};
`

const UserNameText = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${ODColors.CharcoalGrey};
`

const UserPermText = styled.span`
  font-size: 12px;
  color: ${ODColors.Steel};
`

const UserChangedPermText = styled.span`
  font-size: 12px;
  color: ${ODColors.CharcoalGrey};
`

const ButtonsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 36px;
  padding-right: 36px;
`

const BorderPrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 176px;
  height: 35px;
  border-radius: 3px;
  border: 1px solid ${ODColors.Primary};
  background-color: ${ODColors.White};

  font-size: 15px;
  color: ${ODColors.Primary};
  letter-spacing: 0;
`

const DoneButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 176px;
  height: 35px;
  border-radius: 3px;
  background-color: ${ODColors.Primary};

  font-size: 15px;
  color: ${ODColors.White};
  letter-spacing: 0;
`
