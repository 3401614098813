import { ErrorCode } from '@cils/common'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { GQLLoginResponse, GQLUsernameSignup } from '../@types/server'
import { LOCAL_STORAGE_KEY_TOKEN } from '../common'
import { BlockingLoadBox } from '../components/BlockingLoadBox'
import { LoginSignupPage } from '../components/LoginSignupPage/LoginSignupPage'
import { LOGIN_STATE, useODAppContext } from '../context/ODAppContext'
import { useODMutation } from '../context/ODCommon'
import { ME_FULL_SNAPSHOT } from '../gqls'
import { SiteUrls } from '../urls'
import { Utils } from '../utils'

type LoginSignupContainerProps = {
  initialStateSignup?: boolean
}

const gqlRegister = `
mutation registerUser($data: UsernameSignup!) {
  registerUser(data: $data) {
    me {
      ${ME_FULL_SNAPSHOT}
    }
    token
  }
}
`

export const LoginSignupContainer: React.FC<LoginSignupContainerProps> = ({ initialStateSignup }) => {
  const {
    state: { loginState },
    loginUser,
    setLoggedIn,
  } = useODAppContext()

  const apiSignup = useODMutation<GQLUsernameSignup, GQLLoginResponse>(gqlRegister)
  const [requesting, setRequesting] = React.useState(false)
  const [redirect, setRedirect] = React.useState('')

  const handleLogin = async (username: string, pw: string) => {
    try {
      setRequesting(true)
      await loginUser({ username, password: pw })
    } catch (ex) {
      const errorCode = Utils.parseErrorCode(ex)
      if (errorCode === ErrorCode.OrganizationIsDeletedOrWillBeDeleted) {
        setRedirect(SiteUrls.WaitingForAcceptRegistration)
        return
      }

      Utils.showError(ex)
      setRequesting(false)
    }
  }

  const handleRegister = async (username: string, name: string, email: string, password: string, orgId: number) => {
    try {
      setRequesting(true)
      const r = await apiSignup({ username, email, name, password, orgId })
      await localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, r.token)
      setLoggedIn(r.me)
      setRedirect(SiteUrls.User.Root)
    } catch (ex) {
      Utils.showError(ex)
      setRequesting(false)
    }
  }

  if (loginState === LOGIN_STATE.LoggedIn) {
    return <Redirect to={SiteUrls.User.Root} />
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <div className="app flex-row align-items-center" style={{ placeContent: 'center', backgroundColor: '#dde4e5' }}>
      <BlockingLoadBox show={requesting} />
      <LoginSignupPage
        onSubmitLogin={handleLogin}
        onSubmitSignup={handleRegister}
        initialStateSignup={initialStateSignup}
      />
    </div>
  )
}
