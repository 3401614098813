import React, { FormEvent } from 'react'
import { Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import { useTestData } from '../../context/useTestData'
import { ODButton, ODButtonSize, ODButtonTheme } from '../ODButton'

type LoginBodyProps = {
  onSubmitLogin: (email: string, password: string) => void
}

export const LoginBody: React.FC<LoginBodyProps> = ({ onSubmitLogin }) => {
  // const [email, setEmail] = React.useState(useTestData('', 'cils@odcode.com'))
  const [username, setUsername] = React.useState(useTestData('', 'cilsadmin'))
  const [password, setPassword] = React.useState(useTestData('', '12345678'))
  const onClickLogin = () => onSubmitLogin(username, password)
  const handleSubmit = (e: FormEvent) => {
    onClickLogin()
    e.preventDefault()
  }
  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-user" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          placeholder="User ID"
          autoComplete="id"
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-lock" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          onChange={e => setPassword(e.target.value)}
          value={password}
        />
      </InputGroup>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 24 }}>
        {/*  <Button color="link" className="px-0">*/}
        {/*    Forgot password?*/}
        {/*  </Button>*/}
      </div>
      <Row>
        <Col>
          <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} fullWidth onClick={onClickLogin}>
            LOG IN
          </ODButton>
        </Col>
      </Row>
    </Form>
  )
}
