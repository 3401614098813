import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { GQLAttachmentType, GQLListableAttachmentType, GQLListableAttachmentTypeInput } from '../../../@types/server'
import { Clickable } from '../../../components/Clickable'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODQuery } from '../../../context/ODCommon'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

interface AttachmentTypeDataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLAttachmentType, AttachmentTypeDataLoaderOption>()

const GQL_LIST_ATTACHMENT_TYPE = `
query listAttachmentType($data: ListableAttachmentTypeInput!) {
  listAttachmentType(data: $data) {
    list {
      typeId
      name
    }
    totalCount
    page
    pageSize
  }
}
`

export const AttachmentTypeListContainer: React.FC = () => {
  const apiList = useODQuery<GQLListableAttachmentTypeInput, GQLListableAttachmentType>(GQL_LIST_ATTACHMENT_TYPE)

  const dataLoader = React.useCallback(
    async function AttachmentTypeDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AttachmentTypeDataLoaderOption
    ): Promise<ODListableResponseType<GQLAttachmentType>> {
      const r = await apiList({ page, pageSize, filter: options.filter || null })
      return r as ODListableResponseType<GQLAttachmentType>
    },
    [apiList]
  )
  const TableDefinition: ODListableTableDefinition<GQLAttachmentType, AttachmentTypeDataLoaderOption> = [
    {
      id: 'name',
      title: 'Name',
      transform: v => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'setting',
      title: 'Action',
      transform: v => {
        return (
          <Link to={SiteUrls.Admin.Metadata.AttachmentType.Edit(v.typeId)}>
            <Clickable>Setting</Clickable>
          </Link>
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Attachment File Type</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.typeId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <div>
                <Link to={SiteUrls.Admin.Metadata.AttachmentType.Add} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    New Attachment File Type
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
