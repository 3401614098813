import React from 'react'
import { GQLAudit, GQLAUDIT_TYPE, GQLDateTimeRange } from '../../../@types/server'
import { useAPIs } from '../../../context/useAPIs'
import { ODListableOption, ODListableResponseType } from '../../../ODListable/ODListableContext'

export interface AuditDataLoaderOption extends ODListableOption {
  filter: string | null
  orgId: number | null
  dateRange: GQLDateTimeRange | null
  auditTypes: Array<GQLAUDIT_TYPE> | null
}

export function useAuditDataLoader(options: AuditDataLoaderOption) {
  const { listAudits } = useAPIs()
  return React.useCallback(
    async function loader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      optionsIn: AuditDataLoaderOption
    ): Promise<ODListableResponseType<GQLAudit>> {
      const r = await listAudits({
        page,
        pageSize,
        ...options,
        filter: optionsIn.filter,
      })
      return r as ODListableResponseType<GQLAudit>
    },
    [options]
  )
}
