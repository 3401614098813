import { ITEM_EXECUTION_STATUS, PROJECT_ITEM_USER_STATUS, PROJECT_USER_PERM } from '@cils/common'
import { ODListableOption } from '@odc/od-react-belt'
import {
  GQLITEM_EXECUTION_STATUS,
  GQLPROJECT_ITEM_USER_STATUS,
  GQLPROJECT_SORT_OPTION,
  GQLPROJECT_STATUS_OPTION,
} from '../../../@types/server'
import { ODColors } from '../../../global-styles'

export interface IProjectListableOption extends ODListableOption {
  filter: string
  orgId: number
  sortBy: GQLPROJECT_SORT_OPTION // min permission >= Editor
  status?: GQLPROJECT_STATUS_OPTION | null
}

// 사용하지 않는 Status 리스트
export const DEPRECATED_PROJECT_ITEM_USER_STATUS_LIST = [
  PROJECT_ITEM_USER_STATUS.Queued,
  PROJECT_ITEM_USER_STATUS.Downloading,
  PROJECT_ITEM_USER_STATUS.Downloaded,
  PROJECT_ITEM_USER_STATUS.Uploading,
  PROJECT_ITEM_USER_STATUS.FinishedSuccess,
  PROJECT_ITEM_USER_STATUS.FinishedFailed,
  PROJECT_ITEM_USER_STATUS.FinishedError,
]

export const PROJECT_ITEM_USER_STATUS_STRING: { [key: string]: string } = {
  [PROJECT_ITEM_USER_STATUS.NotStarted]: 'Not Started',
  [PROJECT_ITEM_USER_STATUS.Queued]: 'Queued', // Deprecated
  [PROJECT_ITEM_USER_STATUS.Downloading]: 'Downloading', // Deprecated
  [PROJECT_ITEM_USER_STATUS.Downloaded]: 'Downloaded', // Deprecated
  [PROJECT_ITEM_USER_STATUS.Executing]: 'Executing',
  [PROJECT_ITEM_USER_STATUS.Executed]: 'Executed',
  [PROJECT_ITEM_USER_STATUS.UploadFailed]: 'Upload(Failed)',
  [PROJECT_ITEM_USER_STATUS.Uploading]: 'Uploading', // Deprecated
  [PROJECT_ITEM_USER_STATUS.FinishedSuccess]: 'Finished(Success)', // Deprecated
  [PROJECT_ITEM_USER_STATUS.FinishedFailed]: 'Finished(Failed)', // Deprecated
  [PROJECT_ITEM_USER_STATUS.FinishedError]: 'Finished(Error)', // Deprecated
  [ITEM_EXECUTION_STATUS.Executing]: 'Executing',
  [ITEM_EXECUTION_STATUS.Uploading]: 'Uploading',
  [ITEM_EXECUTION_STATUS.Finished]: 'Finished(Success)',
}

export const selectColorForProjectStatus = (
  status:
    | PROJECT_ITEM_USER_STATUS
    | GQLPROJECT_ITEM_USER_STATUS
    | GQLITEM_EXECUTION_STATUS
    | ITEM_EXECUTION_STATUS
    | undefined
) => {
  switch (status) {
    case PROJECT_ITEM_USER_STATUS.NotStarted:
      return ODColors.Silver
    case PROJECT_ITEM_USER_STATUS.Queued: // Deprecated
      return ODColors.Marigold
    case PROJECT_ITEM_USER_STATUS.Downloading: // Deprecated
    case PROJECT_ITEM_USER_STATUS.Downloaded: // Deprecated
      return ODColors.DustyOrange
    case ITEM_EXECUTION_STATUS.Executing:
    case ITEM_EXECUTION_STATUS.Uploading:
    case PROJECT_ITEM_USER_STATUS.Executing:
    case PROJECT_ITEM_USER_STATUS.Uploading: // Deprecated
      return ODColors.DarkSkyBlue
    case ITEM_EXECUTION_STATUS.Finished:
    case PROJECT_ITEM_USER_STATUS.FinishedSuccess: // Deprecated
    case PROJECT_ITEM_USER_STATUS.Executed:
      return ODColors.DarkMint
    case PROJECT_ITEM_USER_STATUS.FinishedFailed: // Deprecated
    case PROJECT_ITEM_USER_STATUS.FinishedError: // Deprecated
    case PROJECT_ITEM_USER_STATUS.UploadFailed:
      return ODColors.SalmonTwo
    default:
      return ''
  }
}

export const selectFontColorForProjectStatus = (
  status:
    | PROJECT_ITEM_USER_STATUS
    | GQLPROJECT_ITEM_USER_STATUS
    | GQLITEM_EXECUTION_STATUS
    | ITEM_EXECUTION_STATUS
    | undefined
) => {
  switch (status) {
    case PROJECT_ITEM_USER_STATUS.NotStarted:
    case PROJECT_ITEM_USER_STATUS.Queued: // Deprecated
      return ODColors.CharcoalGrey
    default:
      return ODColors.White
  }
}

export const PROJECT_USER_PERM_STRING: { [key: string]: string } = {
  [PROJECT_USER_PERM.Owner]: 'Owner',
  [PROJECT_USER_PERM.Admin]: 'Admin',
  [PROJECT_USER_PERM.Editor]: 'Editor',
  [PROJECT_USER_PERM.Viewer]: 'Viewer',
}

export interface ProjectSelectOptionType<T> {
  value: T
  key: number
  label: string
}

export interface ItemExecutionOptionFilter<T> {
  type: T | null
  userId: number | null
}
