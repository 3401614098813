import React, { DependencyList } from 'react'
import { BlockingLoadBox } from '../components/BlockingLoadBox'
import { CILSErrorBox } from '../components/CommonComponents/CILSErrorBox'
import { Utils } from '../utils'

export function useSingleQueryAPI<T>(callable: () => Promise<T>, deps: DependencyList) {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [data, setData] = React.useState<T | null>(null)

  const wrapped = React.useCallback(async () => {
    if (loading) {
      return
    }

    try {
      setLoading(true)
      const r = await callable()
      setData(r)
    } catch (ex) {
      setError(ex)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  React.useEffect(() => {
    setError(null)
    setLoading(false)
    setData(null)
    // noinspection JSIgnoredPromiseFromCall
    wrapped()
  }, [wrapped])

  return { loading, error, data, setData }
}

interface ISingleQueryWrapperProps<T, IN> {
  children: (input: { data: T; update: (input: IN) => any }) => React.ReactNode
  deps: DependencyList
  getter: () => Promise<T>
  update?: (input: IN) => Promise<T>
  showLoading?: string
}

export function SingleQueryWrapper<T, IN>(props: ISingleQueryWrapperProps<T, IN>) {
  const { children, getter, update, deps, showLoading } = props
  const { data, loading, error, setData } = useSingleQueryAPI<T>(getter, deps)
  const [isUpdating, setIsUpdating] = React.useState(false)

  const updateFunc = React.useCallback(
    async (input: IN) => {
      try {
        if (update) {
          const updated = await update?.(input)
          setData(updated)
          return true
        }
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setIsUpdating(false)
      }
      return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deps]
  )

  if (error) {
    return <CILSErrorBox error={error} />
  }

  if (loading || !data) {
    return <BlockingLoadBox show message="loading.." />
  }

  return (
    <>
      {isUpdating && <BlockingLoadBox show message="updating.." />}
      {showLoading && <BlockingLoadBox show message={showLoading} />}
      {children({ data: data!, update: updateFunc })}
    </>
  )
}
