export const orgRoot = (subPath: string) => (orgId: string | number = ':orgId') =>
  `/organization/${orgId}/admin${subPath}`
export const adminRoot = (subPath: string) => `/admin${subPath}`
export const userRoot = (subPath: string) => `${subPath}`
export const orgUserRoot = (subPath: string) => (orgId: string | number = ':orgId') =>
  `/organization/${orgId}${subPath}`

export const SiteUrls = {
  Login: '/login',
  Register: '/register',
  WaitingForAcceptRegistration: '/register/waiting',
  Logout: '/logout',
  Test: '/test',
  Detail: '/detail',
  OrgAdmin: {
    Root: orgRoot(''),
    Dashboard: {
      Main: orgRoot('/dashboard'),
    },
    Alert: {
      List: orgRoot('/alert'),
    },
    User: {
      List: orgRoot('/user'),
      Add: orgRoot('/user/add'),
      Edit: (id: number | string) => orgRoot(`/user/${id}/edit`),
    },
    UserGroup: {
      List: orgRoot(`/userGroup`),
      Add: orgRoot(`/userGroup/add`),
      Edit: (id: number | string) => orgRoot(`/userGroup/${id}/edit`),
    },
    Agent: {
      List: orgRoot('/agent'),
      Add: orgRoot('/agent/add'),
      Edit: (id: number | string) => orgRoot(`/agent/${id}/edit`),
    },
    Category: {
      List: orgRoot('/category'),
      Add: orgRoot('/category/add'),
      Edit: (id: number | string) => orgRoot(`/category/${id}/edit`),
    },
    Storage: {
      List: orgRoot('/storage'),
      Add: orgRoot('/storage/add'),
      Edit: (id: number | string) => orgRoot(`/storage/${id}/edit`),
    },
    Audit: {
      List: orgRoot('/audit'),
    },
    Projects: {
      List: orgRoot('/projects'),
    },
    ProjectMetadata: {
      List: orgRoot('/projectMetadata'),
      Add: orgRoot('/projectMetadata/add'),
      Edit: (id: number | string) => orgRoot(`/projectMetadata/${id}/edit`),
    },
  },
  Admin: {
    Root: adminRoot(''),
    Dashboard: {
      Main: adminRoot('/dashboard'),
    },
    Alert: {
      List: adminRoot('/alert'),
    },
    User: {
      List: adminRoot('/user'),
      Add: adminRoot('/user/add'),
    },
    Organization: {
      List: adminRoot('/organization'),
      Add: adminRoot('/organization/add'),
      Edit: (orgId: number | string) => adminRoot(`/organization/${orgId}`),
    },
    API: {
      List: adminRoot('/apiKey'),
      Add: adminRoot('/apiKey/add'),
      Edit: (id: number | string) => adminRoot(`/apiKey/${id}`),
    },
    Metadata: {
      AttachmentType: {
        List: adminRoot('/metadata/attachmentType'),
        Add: adminRoot('/metadata/attachmentType/add'),
        Edit: (id: number | string) => adminRoot(`/metadata/attachmentType/${id}/edit`),
      },
    },
    Agent: {
      Main: adminRoot('/agent'),
      DirStatus: (machineId: number | string) => adminRoot(`/agent/dir/${machineId}`),
    },
    AgentLogs: {
      Main: adminRoot('/agentLogs'),
      LogOfPid: (pid: string) => adminRoot(`/agentLogs/pid/${pid}`),
    },
    AppConfig: {
      Main: adminRoot('/systemSetting'),
      Update: adminRoot('/systemSetting/edit'),
      List: adminRoot('/systemSetting'),
    },
    Audit: {
      List: adminRoot('/audit'),
    },
  },
  User: {
    Root: userRoot('/'),
    Org: {
      Root: orgUserRoot('/'),
      Item: {
        All: orgUserRoot('/item'),
        AllInOrg: orgUserRoot('/itemInOrg'),
        Local: orgUserRoot('/local'),
        SingleTCF: (itemId: number | string, snapshotId?: number | string) =>
          orgUserRoot(`/tcfItem/${itemId}${!snapshotId ? '' : `?snapshot=${snapshotId}`}`),
      },
      Workset: {
        Main: orgUserRoot('/workset'),
        Favorites: orgUserRoot('/favoriteWorksets'),
        Detail: (worksetId: number | string) => orgUserRoot(`/workset/${worksetId}`),
        Org: orgUserRoot('/worksets'),
      },
      Project: {
        Main: orgUserRoot('/project'),
        Detail: (projectId: number | string, tabIndex?: number) =>
          orgUserRoot(`/project/${projectId}${tabIndex ? `?tab=${tabIndex}` : ''}`),
        DataDetail: (projectId: number | string, projectDataId: number | string, dataTitle: string) =>
          orgUserRoot(`/project/${projectId}/data/${projectDataId}/${dataTitle}`),
        Org: orgUserRoot('/projects'),
      },
      WorksetGroup: {
        main: orgUserRoot('/worksetGroup'),
        Detail: (worksetGroupId: number | string) => orgUserRoot(`/worksetGroup/${worksetGroupId}`),
      },
      Sync: {
        Main: orgUserRoot('/sync'),
        Live: orgUserRoot('/sync/live'),
        Workset: (worksetId: number | string) => orgUserRoot(`/sync/workset/${worksetId}`),
      },
      Favorite: {
        Main: orgUserRoot('/favorite'),
      },
      Category: {
        Main: orgUserRoot('/category'),
        ItemList: (catId: number | string) => orgUserRoot(`/category/${catId}`),
      },
      Tag: {
        Main: orgUserRoot('/tag'),
        ItemList: (tagId: number | string) => orgUserRoot(`/tag/${tagId}`),
      },
      DirectoryView: {
        Main: orgUserRoot('/directory'),
      },
    },
    MyPage: {
      Root: userRoot('/profile'),
      Main: userRoot('/profile/view'),
      EditProfile: userRoot('/profile/edit'),
      ChangePassword: userRoot('/profile/changePassword'),
    },
  },
  SharedTCF: (token: string, orgId: number | string) => `/share/${orgId}/${token}`,
  SyncWithoutLogin: `/sync`,
}
