import { ODListableOption, ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLItemExecutionResult } from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'

export interface IItemExecutionResultListableOption extends ODListableOption {
  ieId: number
}

export function useItemExecutionResultListDataLoader(ieId: number) {
  const { listItemExecutionResult } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IItemExecutionResultListableOption
    ): Promise<ODListableResponseType<GQLItemExecutionResult>> {
      const r = await listItemExecutionResult({
        page,
        pageSize,
        ieId,
      })
      return r as ODListableResponseType<GQLItemExecutionResult>
    },
    [listItemExecutionResult, ieId]
  )
}
