import React from 'react'
import VizSensor from 'react-visibility-sensor'
import styled from 'styled-components'
import { ODListableContextType } from './ODListableContext'

type ODListableAutoLoaderProps = {
  noMoreComponent?: React.ComponentClass<any> | React.FunctionComponent<any>
  loadingComponent?: React.ComponentClass<any> | React.FunctionComponent<any>
  listableContext: React.Context<ODListableContextType<any, any>>
  retryOnClick?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
`

export const ODListableAutoLoader: React.FC<ODListableAutoLoaderProps> = props => {
  const { noMoreComponent: NoMoreComponent, loadingComponent: LoadingComponent, listableContext, retryOnClick } = props
  const {
    state: { loading, hasMore, totalCount, lastLoadErrorAt },
    loadMore,
  }: ODListableContextType<any, any> = React.useContext(listableContext)
  const [isVisible, setIsVisible] = React.useState(false)

  const handleChange = (isVisible: boolean) => {
    setIsVisible(isVisible)
  }

  React.useEffect(() => {
    if (!loading && isVisible && hasMore) {
      if (lastLoadErrorAt <= 0) {
        loadMore()
      }
    }
  }, [isVisible, loading, loadMore, hasMore, lastLoadErrorAt])

  return (
    <VizSensor onChange={handleChange} partialVisibility>
      <Wrapper>
        {loading && LoadingComponent && <LoadingComponent />}
        {!hasMore && totalCount > 0 && NoMoreComponent && retryOnClick && (
          <div
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => {
              loadMore(true)
            }}
          >
            <NoMoreComponent />
          </div>
        )}
        {!hasMore && totalCount > 0 && NoMoreComponent && !retryOnClick && <NoMoreComponent />}
      </Wrapper>
    </VizSensor>
  )
}
