import { ORG_ROLE, ORG_USER_PRIV, PermUtils } from '@cils/common'
import { EventEmitter } from 'events'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { BlockingLoadBox } from '../components/BlockingLoadBox'
import { LOGIN_STATE, useODAppContext } from '../context/ODAppContext'
import { SiteUrls } from '../urls'

interface Props {
  minPriv: number
  shouldAdminOfOrg?: number
  redirect?: (priv?: number) => string
  emitter: EventEmitter
}

export const Authorized: React.FC<Props> = props => {
  const { emitter, children, minPriv, shouldAdminOfOrg, redirect } = props
  const {
    state: { loginState, userProfile },
  } = useODAppContext()

  //
  // 매우 hackish 한 방법이지만, it just works.
  // apollo client
  //
  const [authError, setAuthError] = React.useState(false)
  React.useEffect(() => {
    emitter.on('ServerUnauthorized', () => {
      setAuthError(true)
    })
    return () => {
      emitter.removeAllListeners()
    }
  }, [emitter, setAuthError])

  if (authError) {
    return <Redirect to={SiteUrls.Logout} />
  }

  switch (loginState) {
    case LOGIN_STATE.Checking:
      return <BlockingLoadBox show />
    case LOGIN_STATE.LoggingIn:
      return <BlockingLoadBox show />
    case LOGIN_STATE.LoggingOut:
      return <BlockingLoadBox show />
    case LOGIN_STATE.LoggedIn: {
      const mainOrgId = userProfile?.mainOrgId ?? 0
      const mainOrg = userProfile?.organizations?.find(o => o.org.orgId === mainOrgId)
      const isApprovedByMainOrg = (mainOrg?.orgUserPriv ?? ORG_USER_PRIV.Rejected) >= ORG_USER_PRIV.Guest
      const isMainOrgDeleted = mainOrg?.org?.willDeleteAt || mainOrg?.org?.deletedAt

      if (isMainOrgDeleted) {
        return <Redirect to={SiteUrls.WaitingForAcceptRegistration} />
      }

      // useOrgPerm 을 사용하고 싶으나 한 틱 늦게 render path 에 들어오는 문제가 있어 직접 parsing
      const ohu = userProfile?.organizations.find(o => o.org.orgId === shouldAdminOfOrg)
      const hasConsoleAccess = ohu
        ? new PermUtils(ohu.orgUserPriv, ohu.permSchema).isAllowed(ORG_ROLE.ACCESS_ORG_ADMIN_CONSOLE)
        : false

      if ((userProfile?.priv || 0) >= minPriv) {
        if (!isApprovedByMainOrg) {
          return <Redirect to={SiteUrls.WaitingForAcceptRegistration} />
        }

        if (!shouldAdminOfOrg || hasConsoleAccess) {
          return <>{children}</>
        }
      }

      if (redirect) {
        const urlToRedirect = redirect(userProfile?.priv)
        return <Redirect to={urlToRedirect} />
      }

      return <Redirect to={SiteUrls.Login} />
    }
    case LOGIN_STATE.OrgDeleted: {
      return <Redirect to={SiteUrls.WaitingForAcceptRegistration} />
    }
    case LOGIN_STATE.LoggedOut:
      return <Redirect to={SiteUrls.Login} />
  }

  return null
}
