import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Input, InputGroup } from 'reactstrap'
import styled from 'styled-components'
import { GQLUser, GQLUserPropsInput } from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODAppContext } from '../../../context/ODAppContext'
import { useODMutation } from '../../../context/ODCommon'
import { GQL_UPDATE_USER } from '../../../gqls'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

const Key = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2f353a;
`

const RowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const Line = styled.div`
  display: flex;
  flex: 1;
  height: 1px;
  background-color: #c8ced3;
  margin-top: 40px;
  margin-bottom: 22px;
`

const SaveButton = styled.div`
  width: 135px;
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`
const CancelButton = styled.div`
  width: 70px;
  height: 35px;
  border-radius: 3px;
  background-color: #c8ced3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2f353a;
  cursor: pointer;
`
const KeyWrapper = styled.div`
  width: 158px;
  margin-right: 30px;
`

export const ChangePasswordContainer: React.FC = () => {
  const {
    state: { userProfile },
    refreshProfile,
  } = useODAppContext()

  const { name } = userProfile!

  const history = useHistory()

  const [currentPassword, setCurrentPassword] = React.useState<string>('')
  const [newPassword, setNewPassword] = React.useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>('')

  const apiUpdateUserProfile = useODMutation<Partial<GQLUserPropsInput>, GQLUser>(GQL_UPDATE_USER)
  const handleChangePassword = async () => {
    if (!currentPassword) {
      Utils.showError('Current password is required')
      return
    }

    if (!newPassword) {
      Utils.showError('Password is required')
      return
    }

    if (confirmNewPassword !== newPassword) {
      Utils.showError('Passwords must match')
      return
    }

    try {
      await apiUpdateUserProfile({ name: name, password: confirmNewPassword, oldPassword: currentPassword })
      await refreshProfile()
      await history.push(SiteUrls.User.MyPage.Main)
      Utils.showSuccess('Password changed', 'Success')
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 26 }}>
        <CardHeader>Change Password</CardHeader>
        <CardBody>
          <RowWrapper>
            <KeyWrapper>
              <Key>Current Password</Key>
            </KeyWrapper>
            <div style={{ display: 'flex', flex: 1 }}>
              <Col md={5}>
                <InputGroup>
                  <Input
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    placeholder="Current password"
                    value={currentPassword}
                    onChange={e => setCurrentPassword(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </div>
          </RowWrapper>
          <RowWrapper style={{ marginTop: 25 }}>
            <KeyWrapper>
              <Key>New Password</Key>
            </KeyWrapper>
            <div style={{ display: 'flex', flex: 1 }}>
              <Col md={5}>
                <InputGroup>
                  <Input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </div>
          </RowWrapper>
          <RowWrapper style={{ marginTop: 25 }}>
            <KeyWrapper>
              <Key>Confirm New Password</Key>
            </KeyWrapper>
            <div style={{ display: 'flex', flex: 1 }}>
              <Col md={5}>
                <InputGroup>
                  <Input
                    type="password"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={e => setConfirmNewPassword(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </div>
          </RowWrapper>
          <Line />
          <RowWrapper style={{ justifyContent: 'flex-end' }}>
            <CancelButton onClick={() => history.goBack()} style={{ marginRight: 10 }}>
              Cancel
            </CancelButton>
            <SaveButton onClick={handleChangePassword}>Save</SaveButton>
          </RowWrapper>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
