import React from 'react'
import { Redirect } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import styled from 'styled-components'
import { GQLDualIDInput } from '../@types/server'
import { GQLOkResponse } from '../agent'
import { useODMutation } from '../context/ODCommon'
import { useODSingleOrgContext } from '../context/ODSingleOrgContext'
import { SiteUrls } from '../urls'
import { Utils } from '../utils'
import { ODIcon, ODIcons } from './ODIcon'
import { useModalConfirm } from './ODModal/ODModalConfirm'

type TagSettingDropdownProps = {
  tagId: number
}

const CustomSettingDropdown = styled(DropdownToggle)`
  background-color: #ffffff;
  border: 0;
  &:hover {
    background-color: #ffffff;
  }
`

const regexpCheckTagDetail = new RegExp(/tag\/([\d]+)$/)

export function getTagDetailId(path: string): number | null {
  const match = path.match(regexpCheckTagDetail)
  return match ? parseInt(match[1], 10) : null
}

const GQL_REMOVE_TAG = `
mutation removeTag($data: DualIDInput!) {
  removeTag(data: $data) {
    ok
  }
}
`

export const TagSettingDropdown: React.FC<TagSettingDropdownProps> = ({ tagId }) => {
  const { orgId } = useODSingleOrgContext()
  const apiRemoveTag = useODMutation<GQLDualIDInput, GQLOkResponse>(GQL_REMOVE_TAG)
  const {
    Component: RemoveTagConfirmComponent,
    confirm: confirmRemoveTag,
    props: removeTagConfirmComponentProps,
  } = useModalConfirm({
    title: 'Remove Tag',
    message: 'Are you sure you want to remove this tag?',
    yes: 'Remove',
    no: 'Cancel',
  })

  const [redirect, setRedirect] = React.useState('')

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const handleRemove = async () => {
    if (await confirmRemoveTag()) {
      try {
        await apiRemoveTag({ firstId: orgId, secondId: tagId })
        Utils.showSuccess('Removed a tag.', 'Success')
        setRedirect(SiteUrls.User.Org.Tag.Main(orgId))
      } catch (ex) {
        Utils.showError(ex)
      }
    }
  }

  return (
    <div>
      <RemoveTagConfirmComponent {...removeTagConfirmComponentProps} />
      <UncontrolledDropdown setActiveFromChild>
        <CustomSettingDropdown>
          <ODIcon
            icon={ODIcons.CoreSettings}
            style={{ fontSize: 14, color: '#73818f', cursor: 'pointer', marginBottom: 2 }}
          />
        </CustomSettingDropdown>
        <DropdownMenu>
          <DropdownItem onClick={() => handleRemove()}>Remove</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}
