import { TCF_META_DETAIL } from '@cils/common'
import { ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import {
  GQLAttachmentType,
  GQLBulkAddWorksetHasItemInput,
  GQLItemHasImage,
  GQLItemPermission,
  GQLItemSharePropsInput,
  GQLItemSnapshotUploadStatus,
  GQLShareToken,
  GQLUser,
  GQLWorkset,
} from '../../@types/server'
import { GQLOkResponse } from '../../agent'
import TOMLogo from '../../assets/img/brand/logo-symbol-color.png'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { CategoryEditComponent } from '../../components/CategoryEditComponent'
import { Clickable } from '../../components/Clickable'
import { DragAndDropComponent } from '../../components/DragAndDropComponent'
import { EditValueComponent } from '../../components/EditValueComponent'
import { FlexContentsContainer } from '../../components/FlexContentsContainer'
import { ImageSlider } from '../../components/ImageSlider'
import { ODButton, ODButtonSize, ODButtonTheme } from '../../components/ODButton'
import { ODIcon, ODIcons } from '../../components/ODIcon'
import { SelectOptions } from '../../components/ODImageGrid/SelectOptions'
import { ODModalAddToWorkset } from '../../components/ODModal/ODModalAddToWorkset'
import { ODModalAttachmentList } from '../../components/ODModal/ODModalAttachmentList'
import { ODModalCreateShareLink } from '../../components/ODModal/ODModalCreateShareLink'
import { AttachmentEditData, EditAttachmentModal } from '../../components/ODModal/ODModalEditAttachment'
import { ODModalShowShareLink } from '../../components/ODModal/ODModalShowShareLink'
import { TCFUploadStatus } from '../../components/TCFUploadStatus'
import { ThumbnailOverlay } from '../../components/ThumbnailOverlay'
import { useODAppContext } from '../../context/ODAppContext'
import { CategoryOption } from '../../context/ODCILSHooks'
import { useODMutation } from '../../context/ODCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { ODColors } from '../../global-styles'
import { GQL_CREATE_ITEM_SHARE, GQL_CREATE_WORKSET_HAS_ITEM_MULTIPLE } from '../../gqls'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils'
import { useDetectOutsideClick } from '../../utils/useDetectOutsideClick'
import {
  convertItemAttachmentToThumbnailInfo,
  ThumbnailInfo,
} from './DetailContainerComponent/convertItemAttachmentToThumbnailInfo'

export enum METADATA_EDIT_TYPE {
  TITLE = 'TITLE',
  CATEGORY = 'CATEGORY',
  TAG = 'TAG',
  EXPLANATION = 'EXPLANATION',
}

const Header = styled.div`
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const HeaderTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: #2f353a;
`

const Line = styled.div`
  display: flex;
  width: 100%;
  height: 2px;
  background-color: #2e343a;
`
const TitleLine = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #acb4bc;
`

const ThumbnailsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: 591px;
`

const InformationWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2f353a;
`
const KeyValueWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1;
  // align-items: center;
  flex-direction: row;
`
const MetaDataKeyValueWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`

const KeyWrapper = styled.div`
  width: 52px;
  min-width: 52px;
  margin-right: 69px;
`
const Key = styled.span`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #2f353a;
`
const LinkValue = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #29b6ca;
`
const Value = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #73818f;
`
const CustomIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`
const CustomToolTip = styled(UncontrolledTooltip)`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #ffffff;
`
const ShareUserTag = styled.span`
  padding-left: 15px;
  padding-right: 15px;
  height: 19px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #73818f;
  font-size: 10px;
  border: solid 1px #4dbd74;
  margin: 2px;
`
const FlexWrap = styled.div`
  display: flex;
  max-width: 417px;
  flex-wrap: wrap;
  margin: -2px;
`
const ShareButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #2fbed0;
  padding: 2px 14px 3px 14px;
  font-size: 12px;
  letter-spacing: 0.17px;
  color: #ffffff;
  cursor: pointer;
`

type PageArrowProps = {
  disabled: boolean
}

const PageArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 80px;
  cursor: ${(p: PageArrowProps) => (p.disabled ? 'auto' : 'pointer')};

  &:hover {
    background-color: ${(p: PageArrowProps) => (p.disabled ? 'transparent' : '#29b6ca')};
  }

  & > svg:hover {
    color: ${(p: PageArrowProps) => (p.disabled ? 'black' : '#ffffff')};
  }
`

const ViewInTomoAnalysisButton = styled.div`
  border-radius: 25px;
  border: solid 1px ${ODColors.Primary};
  background-color: #dff4f7;
  padding: 4px 9px 6px 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${ODColors.Primary};
`

export type GeneralInfoType = {
  itemId: number
  mainImage: string
  dataId: string
  link: string
  created: string
  modified: string
  metadata: any
  size: string
  title: string
  explanation: string
  status: string
  attachments: Array<ThumbnailInfo>
  categories: Array<CategoryOption>
  tcfSize: string
  uploadStatus: GQLItemSnapshotUploadStatus
  permission: GQLItemPermission
  accessibleUsers?: Array<GQLUser>
  inWorksets: Array<Partial<GQLWorkset>>
  errorMessage?: string
  itemHasImages: GQLItemHasImage[]
}

type ShareType = {
  owner: string
  org: string
}

type DetailContainerProps = {
  generalInfo: GeneralInfoType
  Share: ShareType
  downloadDisabled: boolean
  downloadDisabledReason: string | null
  onClose: () => void
  onClickDownload: () => void
  onChangeMetadata: (type: METADATA_EDIT_TYPE, value: string) => Promise<any>
  // 서버에서 제공하는 CategoryOption[]
  categoryOptions: Array<CategoryOption>
  // 서버에 저장된 태그 데이터 (comma-separated text) : 변경된 tags 는 onChangeMetadata 로 전달된다.
  tags: string
  onSetAttachmentAsMain: (attachmentId: string) => Promise<any>
  attachmentTypes: Array<GQLAttachmentType>
  onSaveAttachment: (thumbnail: ThumbnailInfo, changed: AttachmentEditData) => Promise<any>
  hasPrev: boolean
  onClickPrev: () => Promise<any>
  hasNext: boolean
  onClickNext: () => Promise<any>
  isFavorite: boolean
  onChangeFavorite: (added: boolean) => Promise<any>
  onAddAttachment: (file: File, data: AttachmentEditData) => Promise<any>
  onRemoveAttachment: (thumbnail: ThumbnailInfo) => Promise<void>
  movieLink?: string
  connectedShareLink?: boolean
  onViewInTomoAnalysis: () => void
  inWorksetId?: number
  isMarkedReloadMetadata: boolean
  onChangeMarkReloadMeta: (marked: boolean) => Promise<any>
}

export const ToolTipIcon = (props: {
  id: string
  icon: ODIcons
  message: string
  style?: object
  onClick?: () => void
  wrapperStyle?: object
}) => {
  const { id, icon, message, style, onClick, wrapperStyle } = props

  return (
    <div onClick={onClick} style={wrapperStyle || {}}>
      <CustomIcon id={id} style={style}>
        <ODIcon icon={icon} />
      </CustomIcon>
      <CustomToolTip placement="bottom" target={id}>
        {message}
      </CustomToolTip>
    </div>
  )
}

export const DetailContainer: React.FC<DetailContainerProps> = ({ ...props }) => {
  const {
    generalInfo,
    Share,
    onClose,
    onClickDownload,
    onChangeMetadata,
    downloadDisabled,
    downloadDisabledReason,
    tags,
    onSetAttachmentAsMain,
    attachmentTypes,
    onSaveAttachment,
    hasNext,
    hasPrev,
    onClickNext,
    onClickPrev,
    isFavorite,
    onChangeFavorite,
    onAddAttachment,
    onRemoveAttachment,
    categoryOptions,
    movieLink,
    connectedShareLink,
    onViewInTomoAnalysis,
    inWorksetId,
    isMarkedReloadMetadata,
    onChangeMarkReloadMeta,
  } = props

  // console.log(255, generalInfo)

  const [selectedThumbnailId, setSelectedThumbnailId] = React.useState<string>(generalInfo.attachments[0].id)
  const [loading, setLoading] = React.useState(false)
  const [isOpenFileUploadView, setIsOpenFileUploadView] = React.useState<boolean>(false)
  const [isOpenFileUploadModal, setIsFileUploadModal] = React.useState<boolean>(false)
  const [isOpenCreateShareLinkModal, setIsOpenCrateShareLinkModal] = React.useState<boolean>(false)
  const [isOpenAddToWorksetModal, setIsOpenAddToWorksetModal] = React.useState<boolean>(false)
  const [shareLinkToShow, setShareLinkToShow] = React.useState<string | null>(null)
  const [imageToAdd, setImageToAdd] = React.useState<File | null>(null)
  const [previewImage, setPreviewImage] = React.useState<string>('')
  const [metaTypes, setMetaTypes] = React.useState<Array<string>>([])
  const [selectedMetaType, setSelectedMetaType] = React.useState<string>('')
  const [isCreatingShareLink, setIsCreatingShareLink] = React.useState(false)
  const [isOpenAttachmentListModal, setIsOpenAttachmentListModal] = React.useState(false)
  const [attachmentListRefreshToken, setAttachmentListRefreshToken] = React.useState(0)

  const apiCreateShareLink = useODMutation<GQLItemSharePropsInput, GQLShareToken>(GQL_CREATE_ITEM_SHARE)
  const { orgId } = useODSingleOrgContext()
  const {
    appOptions: { siteAddress },
  } = useODAppContext()

  const dndRef = React.useRef<HTMLDivElement>(null)

  useDetectOutsideClick(() => setIsOpenFileUploadView(false), dndRef, isOpenFileUploadView)

  const metaDataList = Utils.getDetailMetadata(generalInfo.metadata) || []
  const metaData: TCF_META_DETAIL = metaDataList.find(v => v.source === selectedMetaType) || ({} as TCF_META_DETAIL)

  //
  // Modality 에 따른 이미지 타입 처리하기
  //
  // 첨부파일의 썸네일과 Image Type 에 의한 썸네일 중 어떤게 보여져야 하는지가 애매하므로 이 상태를 추가한다.
  const [showThumbnailByType, setShowThumbnailByType] = React.useState(false)
  // 선택된 이미지 타입에 따라 어떤 Modality 의 썸네일을 보여줄지 확인하여 해당 링크를 갖는다. 단, showThumbnailByType 이 false 면 링크 x
  const existingModalities = generalInfo.itemHasImages.map(i => i.modality)
  const selectedMetaTypeThumbnail: GQLItemHasImage | undefined = showThumbnailByType
    ? generalInfo.itemHasImages.find(
        ii => ii.modality === Utils.mapImageTypeToThumbnailModality(selectedMetaType, existingModalities)
      )
    : undefined

  console.log(391, selectedMetaTypeThumbnail)

  React.useEffect(() => {
    const types = Utils.getDetailMetadata(generalInfo.metadata) || []
    setMetaTypes(types.map(s => s.source))
    setSelectedMetaType(types[0]?.source || '')
  }, [generalInfo.metadata])

  const apiAddToWorkset = useODMutation<Partial<GQLBulkAddWorksetHasItemInput>, GQLOkResponse>(
    GQL_CREATE_WORKSET_HAS_ITEM_MULTIPLE
  )

  // console.log(274, Utils.getDetailMetadata(generalInfo.metadata))

  const handleSetAsMain = () => {
    onSetAttachmentAsMain(selectedThumbnailId)
  }

  const setLoadingFalse = () => setLoading(false)
  const handlePrev = () => {
    setLoading(true)
    onClickPrev().then(setLoadingFalse, setLoadingFalse)
  }

  const handleNext = async () => {
    setLoading(true)
    onClickNext().then(setLoadingFalse, setLoadingFalse)
  }

  // generalInfo 가 변경되면 선택된 썸네일을 변경한다.
  React.useEffect(() => {
    setSelectedThumbnailId(generalInfo.attachments[0].id)
  }, [JSON.stringify(generalInfo.attachments), setSelectedThumbnailId]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectedThumbnail = generalInfo.attachments.find(v => v.id === selectedThumbnailId)

  const isSelectedFirstThumbnail = selectedThumbnailId === generalInfo.attachments[0].id

  const handleAddAttachment = async (data: AttachmentEditData) => {
    await onAddAttachment(imageToAdd!, data)
    setPreviewImage('')
    setImageToAdd(null)
    setIsFileUploadModal(false)
    setAttachmentListRefreshToken(v => v + 1)
  }

  const handleRemoveAttachment = async (data: ThumbnailInfo) => {
    await onRemoveAttachment(data)
    // setSelectedThumbnailId(null)
  }

  const handleCategoryChange = (v: Array<CategoryOption>) => {
    return onChangeMetadata(METADATA_EDIT_TYPE.CATEGORY, v.map(co => co.value).join(','))
  }

  const handleWorksetSave = async (wsIds: Array<number>) => {
    if (wsIds.length === 0) {
      Utils.showInfo('No workset is selected.')
      return false
    }

    await apiAddToWorkset({ wsIds, itemIds: [generalInfo.itemId] })
    Utils.showSuccess(`Added this item to ${wsIds.length} workset${wsIds.length > 1 ? 's' : ''}.`, 'Success')
    return true
  }

  const onCreateShareLink = async (canDownload: boolean, expireAfterDays: number) => {
    try {
      setIsCreatingShareLink(true)
      const { shareToken } = await apiCreateShareLink({ itemId: generalInfo.itemId, expireAfterDays, canDownload })
      setIsOpenCrateShareLinkModal(false)
      setShareLinkToShow(`${siteAddress}${SiteUrls.SharedTCF(shareToken, orgId)}`)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setIsCreatingShareLink(false)
    }
  }

  const onClickShowAttachmentList = () => {
    setIsOpenAttachmentListModal(true)
  }

  // #235
  const uploadCompleted =
    generalInfo.uploadStatus && generalInfo.uploadStatus.totalBytes <= generalInfo.uploadStatus.uploadedBytes
  // "{"totalBytes":1800000000,"uploadedBytes":4539899260,"rawData":"[{\"createdAt\":\"2020-05-22T06:26:53.000Z\",\"updatedAt\":\"2020-05-22T06:26:53.000Z\",\"snapshotId\":15,\"itemId\":47,\"guid\":\"c41c61d9a769c011f2e316d4bec00240\",\"size\":\"4539899260\",\"uploadedSize\":\"1800000000\",\"remaining\":2,\"uploaderId\":1,\"shfId\":28,\"cilsFileId\":28,\"path\":\"004-HT-Timelapse.TCF\"},{\"createdAt\":\"2020-05-22T06:26:53.000Z\",\"updatedAt\":\"2020-05-22T06:26:53.000Z\",\"snapshotId\":15,\"itemId\":47,\"guid\":\"c41c61d9a769c011f2e316d4bec00240\",\"size\":\"4539899260\",\"uploadedSize\":\"1800000000\",\"remaining\":2,\"uploaderId\":1,\"shfId\":29,\"cilsFileId\":29,\"path\":\"004-HT-Timelapse.TIFF\"}]","__typename":"ItemSnapshotUploadStatus"}"

  return (
    <FlexContentsContainer>
      {(loading || isCreatingShareLink) && <BlockingLoadBox show />}
      {isOpenCreateShareLinkModal && (
        <ODModalCreateShareLink
          onClose={() => setIsOpenCrateShareLinkModal(false)}
          onCreateShareLink={onCreateShareLink}
        />
      )}
      {shareLinkToShow !== null && !isOpenCreateShareLinkModal && (
        <ODModalShowShareLink onClose={() => setShareLinkToShow(null)} shareLink={shareLinkToShow} />
      )}
      {isOpenAddToWorksetModal && (
        <ODModalAddToWorkset onClose={() => setIsOpenAddToWorksetModal(false)} onSave={handleWorksetSave} />
      )}
      {isOpenAttachmentListModal && (
        <ODModalAttachmentList
          attachmentTypes={attachmentTypes}
          itemId={generalInfo.itemId}
          onClose={() => setIsOpenAttachmentListModal(false)}
          onAttachmentAdded={(image, preview) => {
            setImageToAdd(image)
            setPreviewImage(preview)
            setIsFileUploadModal(true)
          }}
          listRefreshToken={attachmentListRefreshToken.toString()}
          onSetAttachmentAsMain={v => onSetAttachmentAsMain(v.fileId.toString())}
          onSaveAttachment={onSaveAttachment}
          inWorksetId={inWorksetId}
          onRemove={v => handleRemoveAttachment(convertItemAttachmentToThumbnailInfo(v, null))}
        />
      )}
      {isOpenFileUploadModal && (
        <EditAttachmentModal
          imageFile={imageToAdd}
          previewImage={previewImage}
          attachmentTypes={attachmentTypes}
          onDismiss={() => setIsFileUploadModal(false)}
          onSave={handleAddAttachment}
          isUpload
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#ffffff',
          paddingLeft: 24,
          paddingRight: 24,
        }}
        onDragEnter={() => {
          if (generalInfo.permission.canAddAttachment) {
            setIsOpenFileUploadView(true)
          }
        }}
      >
        <PageArrow disabled={!hasPrev}>
          <ODIcon
            icon={ODIcons.MaterialArrowLeft}
            style={{ fontSize: 40, opacity: !hasPrev ? 0.3 : 1.0 }}
            className="materialArrowLeft"
            onClick={handlePrev}
          />
        </PageArrow>
        <div
          style={{
            display: 'flex',
            flex: 1,
            backgroundColor: '#ffffff',
            marginTop: 40,
            flexDirection: 'column',
            paddingBottom: 60,
          }}
        >
          <Header>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {connectedShareLink ? (
                <img src={TOMLogo} alt="CILS Logo" />
              ) : (
                <ODIcon
                  icon={ODIcons.CoreXCircle}
                  style={{ color: '#2f353a', fontSize: 24, cursor: 'pointer' }}
                  onClick={onClose}
                />
              )}
              {generalInfo.errorMessage && (
                <ToolTipIcon
                  id="errorMessage"
                  icon={ODIcons.MaterialInfoOutline}
                  message={generalInfo.errorMessage}
                  wrapperStyle={{ display: 'inline-block', marginTop: 2 }}
                  style={{ color: ODColors.Redish, fontSize: 28, cursor: 'pointer', marginTop: -10, marginLeft: 5 }}
                  onClick={() => null}
                />
              )}
            </div>
            <HeaderTitle>{generalInfo.title}</HeaderTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {generalInfo.permission.canDownload && (
                <ViewInTomoAnalysisButton onClick={onViewInTomoAnalysis}>View in TomoAnalysis</ViewInTomoAnalysisButton>
              )}
              {generalInfo.permission.canModifyMetadata && (
                <ToolTipIcon
                  id="reloadMeta"
                  icon={ODIcons.CoreReload}
                  message={
                    isMarkedReloadMetadata
                      ? 'Meta-data will be updated if agent is available.'
                      : 'Force update meta-data'
                  }
                  style={{
                    fontSize: 17,
                    color: isMarkedReloadMetadata ? '#ffc107' : '#73818f',
                    cursor: 'pointer',
                  }}
                  onClick={() => onChangeMarkReloadMeta(!isMarkedReloadMetadata)}
                />
              )}
              {generalInfo.permission.canFavorite && !connectedShareLink && (
                <ToolTipIcon
                  id="favorite"
                  icon={isFavorite ? ODIcons.FontAwesomeStar : ODIcons.FontAwesomeStarOutlined}
                  message="Favorite"
                  style={{
                    fontSize: 17,
                    color: isFavorite ? '#ffc107' : '#73818f',
                    cursor: 'pointer',
                  }}
                  onClick={() => onChangeFavorite(!isFavorite)}
                />
              )}
              {generalInfo.permission.canAddToWorkset && !connectedShareLink && (
                <ToolTipIcon
                  id="addToWorkset"
                  icon={ODIcons.CoreLibraryAdd}
                  message="Add to workset"
                  style={{
                    fontSize: 16,
                    color: '#73818f',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsOpenAddToWorksetModal(true)}
                />
              )}
              {generalInfo.permission.canDownload && uploadCompleted && (
                <ToolTipIcon
                  id="Download"
                  icon={ODIcons.CoreDataTransferDown}
                  message={downloadDisabledReason || 'Download'}
                  style={{
                    fontSize: 16,
                    color: downloadDisabled ? '#c9c9c9' : ODColors.Primary,
                    cursor: downloadDisabled ? 'auto' : 'pointer',
                  }}
                  onClick={downloadDisabled ? undefined : onClickDownload}
                />
              )}
              {generalInfo.permission.canDownload && !uploadCompleted && (
                <TCFUploadStatus status={generalInfo.uploadStatus} />
              )}
              {/*<ToolTipIcon*/}
              {/*  id="lock"*/}
              {/*  icon={ODIcons.CoreHttps}*/}
              {/*  message="Lock"*/}
              {/*  style={{*/}
              {/*    fontSize: 16,*/}
              {/*    color: '#73818f',*/}
              {/*    cursor: 'pointer',*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          </Header>
          <Line />
          <div
            ref={dndRef}
            style={{
              marginTop: 59,
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-around',
            }}
          >
            <DragAndDropComponent
              isOpen={isOpenFileUploadView}
              toggle={() => {
                setIsOpenFileUploadView(false)
                setIsFileUploadModal(true)
              }}
              onClose={() => setIsOpenFileUploadView(false)}
              onChange={(image, preview) => {
                setImageToAdd(image)
                setPreviewImage(preview)
              }}
            />
            {(selectedThumbnail || selectedMetaTypeThumbnail) && (
              <ThumbnailsWrapper>
                <ReactPlayer
                  style={{
                    display:
                      (movieLink && isSelectedFirstThumbnail) || selectedMetaTypeThumbnail?.timeLapse ? 'flex' : 'none',
                  }}
                  url={selectedMetaTypeThumbnail?.timeLapse?.link ?? movieLink}
                  controls
                  width={551}
                  height={551}
                  playing={!!(isSelectedFirstThumbnail || selectedMetaTypeThumbnail)}
                />
                {(movieLink && isSelectedFirstThumbnail) || selectedMetaTypeThumbnail?.timeLapse ? null : (
                  <ThumbnailOverlay
                    editable={connectedShareLink ? false : generalInfo.permission.canEditAttachment}
                    thumbnail={
                      selectedMetaTypeThumbnail
                        ? {
                            id: selectedMetaTypeThumbnail!.itemHasImageId.toString(),
                            isAttachment: false,
                            link: selectedMetaTypeThumbnail!.image.link,
                            fileName: '',
                            isMain: false,
                            description: '',
                          }
                        : selectedThumbnail!
                    }
                    onSetAsMain={handleSetAsMain}
                    attachmentTypes={attachmentTypes}
                    onSaveAttachment={onSaveAttachment}
                    onRemoveAttachment={handleRemoveAttachment}
                    canDeleteAttachment={generalInfo.permission.canAddAttachment}
                  />
                )}
                {generalInfo.permission.canEditAttachment && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      width: '100%',
                      marginRight: 40,
                      marginTop: 9,
                      marginBottom: 14,
                    }}
                  >
                    <ODButton
                      theme={ODButtonTheme.Primary}
                      size={ODButtonSize.XSmall}
                      style={{ borderRadius: 4, paddingLeft: 10, paddingRight: 10 }}
                      onClick={onClickShowAttachmentList}
                    >
                      <ODIcon icon={ODIcons.CoreList} />
                      &nbsp;&nbsp; Attachments
                    </ODButton>
                  </div>
                )}
                {!generalInfo.permission.canAddAttachment && <ODVSpace h={10} />}
                <ImageSlider
                  editable={connectedShareLink ? false : generalInfo.permission.canAddAttachment}
                  selectedId={
                    selectedMetaTypeThumbnail
                      ? 'no-select-because-meta-type-thumbnail-is-on'
                      : selectedThumbnail?.id ?? 'not-selected'
                  }
                  thumbnails={generalInfo.attachments}
                  onClick={v => {
                    setSelectedThumbnailId(v.id)
                    setShowThumbnailByType(false)
                  }}
                  onOpenFileUploadView={() => setIsOpenFileUploadView(true)}
                />
              </ThumbnailsWrapper>
            )}
            <InformationWrapper style={{ maxWidth: 577 }}>
              {/* GENERAL INFO */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Title>GENERAL INFO</Title>
                <TitleLine style={{ marginTop: 8 }} />
                <KeyValueWrapper style={{ marginTop: 15 }}>
                  <KeyWrapper>
                    <Key>Data ID</Key>
                  </KeyWrapper>
                  <LinkValue>{generalInfo.dataId}</LinkValue>
                </KeyValueWrapper>
                {connectedShareLink ? null : (
                  <KeyValueWrapper>
                    <KeyWrapper>
                      <Key>Link</Key>
                    </KeyWrapper>
                    <LinkValue>
                      <CopyToClipboard text={generalInfo.link} onCopy={() => Utils.showInfo('Permalink copied!')}>
                        <Clickable>{generalInfo.link}</Clickable>
                      </CopyToClipboard>
                    </LinkValue>
                  </KeyValueWrapper>
                )}
                <KeyValueWrapper>
                  <KeyWrapper>
                    <Key>Created</Key>
                  </KeyWrapper>
                  <Value>{generalInfo.created}</Value>
                </KeyValueWrapper>
                <KeyValueWrapper>
                  <KeyWrapper>
                    <Key>Modified</Key>
                  </KeyWrapper>
                  <Value>{generalInfo.modified}</Value>
                </KeyValueWrapper>
                {metaTypes.length > 0 && (
                  <KeyValueWrapper>
                    <KeyWrapper>
                      <Key>Type</Key>
                    </KeyWrapper>
                    <Value style={{ paddingTop: 7 }}>
                      <SelectOptions
                        value={metaTypes}
                        name="TCFDetailType"
                        height={22}
                        buttonWidth={84}
                        onClick={() => setShowThumbnailByType(true)}
                        onChange={v => setSelectedMetaType(v)}
                        selectedValue={selectedMetaType}
                      />
                    </Value>
                  </KeyValueWrapper>
                )}
                <KeyValueWrapper style={{ alignItems: 'flex-start' }}>
                  <KeyWrapper>
                    <Key>Image size</Key>
                  </KeyWrapper>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 3 }}>
                    <Value style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 'bold' }}>Dimension</span> {metaData.dimension || '-'}
                    </Value>
                    <Value>
                      <span style={{ fontWeight: 'bold' }}>Resolution</span> {metaData.resolution || '-'}
                    </Value>
                  </div>
                </KeyValueWrapper>
                <KeyValueWrapper>
                  <KeyWrapper>
                    <Key>File size</Key>
                  </KeyWrapper>
                  <Value>
                    {generalInfo.size} / TCF Size : {generalInfo.tcfSize}
                  </Value>
                </KeyValueWrapper>
              </div>
              {/* SHARE */}
              {
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 47 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Title>SHARE</Title>
                    {!connectedShareLink ? (
                      <ShareButton onClick={() => setIsOpenCrateShareLinkModal(true)}>
                        <ODIcon
                          icon={ODIcons.SimpleLineIconsShareAlt}
                          style={{ marginRight: 5, fontSize: 12, color: '#ffffff', letterSpacing: 0.17 }}
                        />
                        Share
                      </ShareButton>
                    ) : null}
                  </div>
                  <TitleLine style={{ marginTop: 8 }} />
                  {generalInfo.permission.canShare && (
                    <>
                      <KeyValueWrapper style={{ marginTop: 15 }}>
                        <KeyWrapper>
                          <Key>Owner</Key>
                        </KeyWrapper>
                        <Value>
                          {Share.owner} ({Share.org})
                        </Value>
                      </KeyValueWrapper>
                      <KeyValueWrapper>
                        <KeyWrapper>
                          <Key>Workset</Key>
                        </KeyWrapper>
                        <FlexWrap>
                          {(generalInfo.inWorksets || []).map(w => {
                            return (
                              <Link
                                key={w.wsId!}
                                to={SiteUrls.User.Org.Workset.Detail(w.wsId!)(w.ownerOrgId!)}
                                style={{ textDecoration: 'none' }}
                              >
                                <ShareUserTag key={w.wsId} style={{ borderColor: '#f86c6b' }} id={`workset_${w.wsId}`}>
                                  {w.name}
                                </ShareUserTag>
                                <CustomToolTip placement="top" target={`workset_${w.wsId}`}>
                                  Workset owner : {w.owner?.name} ({w.owner?.email})
                                </CustomToolTip>
                              </Link>
                            )
                          })}
                          {!generalInfo.inWorksets.length && <span>-</span>}
                        </FlexWrap>
                      </KeyValueWrapper>
                      <KeyValueWrapper>
                        <KeyWrapper>
                          <Key>Access</Key>
                        </KeyWrapper>
                        <FlexWrap>
                          {(generalInfo.accessibleUsers || []).map(u => {
                            if (u.name === Share.owner) {
                              return (
                                <ShareUserTag key={u.userId} style={{ borderColor: '#739eec' }}>
                                  {u.name}
                                </ShareUserTag>
                              )
                            }

                            return <ShareUserTag key={u.userId}>{u.name}</ShareUserTag>
                          })}
                        </FlexWrap>
                      </KeyValueWrapper>
                    </>
                  )}
                </div>
              }
              {/* METADATA */}
              {generalInfo.permission.canModifyMetadata && (
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 47 }}>
                  <Title>METADATA</Title>
                  <TitleLine style={{ marginTop: 8 }} />
                  <MetaDataKeyValueWrapper>
                    <EditValueComponent
                      editable={connectedShareLink ? false : generalInfo.permission.canModifyMetadata}
                      title="Title"
                      type={METADATA_EDIT_TYPE.TITLE}
                      initialValue={generalInfo.title}
                      onChange={v => onChangeMetadata(METADATA_EDIT_TYPE.TITLE, v)}
                    />
                  </MetaDataKeyValueWrapper>
                  <TitleLine style={{ backgroundColor: '#e4e7ea', marginTop: 5 }} />
                  <MetaDataKeyValueWrapper>
                    <CategoryEditComponent
                      editable={connectedShareLink ? false : generalInfo.permission.canModifyMetadata}
                      categories={categoryOptions}
                      onChange={handleCategoryChange}
                      initialValue={generalInfo.categories}
                    />
                  </MetaDataKeyValueWrapper>
                  <TitleLine style={{ backgroundColor: '#e4e7ea', marginTop: 5 }} />
                  <MetaDataKeyValueWrapper>
                    <EditValueComponent
                      editable={connectedShareLink ? false : generalInfo.permission.canModifyMetadata}
                      title="Tag"
                      type={METADATA_EDIT_TYPE.TAG}
                      initialValue={tags}
                      onChange={v => onChangeMetadata(METADATA_EDIT_TYPE.TAG, v)}
                    />
                  </MetaDataKeyValueWrapper>
                  <TitleLine style={{ backgroundColor: '#e4e7ea', marginTop: 5 }} />
                  <MetaDataKeyValueWrapper style={{ paddingTop: 3, alignItems: 'flex-start' }}>
                    <EditValueComponent
                      editable={connectedShareLink ? false : generalInfo.permission.canModifyMetadata}
                      title="Explanation"
                      type={METADATA_EDIT_TYPE.EXPLANATION}
                      initialValue={generalInfo.explanation}
                      onChange={v => onChangeMetadata(METADATA_EDIT_TYPE.EXPLANATION, v)}
                    />
                  </MetaDataKeyValueWrapper>
                </div>
              )}
            </InformationWrapper>
          </div>
        </div>
        <PageArrow disabled={!hasNext}>
          <ODIcon
            icon={ODIcons.MaterialArrowRight}
            style={{ fontSize: 40, opacity: !hasNext ? 0.3 : 1.0 }}
            className="materialArrowRight"
            onClick={handleNext}
          />
        </PageArrow>
      </div>
    </FlexContentsContainer>
  )
}
