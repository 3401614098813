import { ORG_USER_PRIV, USER_PRIV } from '@cils/common'
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react'
import preval from 'preval.macro'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Nav } from 'reactstrap'
import styled from 'styled-components'
import { GQLUser } from '../@types/server'
import logo from '../assets/img/brand/logo-tomocube-cils.png'
import { useCILSAgentContext } from '../context/CILSAgentContext'
import { useCILSHelper } from '../context/ODCILSHooks'
import { SiteUrls } from '../urls'
import { ODTooltip } from './ODTooltip'
import { ProfileDropdown } from './ProfileDropdown'

const buildTime = preval`module.exports = new Date().toLocaleString();`

interface Props {
  name?: string | null
  profile: GQLUser | null
  isInAdmin: boolean // 현재 슈퍼 관리자 페이지 안에 있나?
  isInOrgAdmin: number | null | false // 현재 특정 기관 관리자 페이지 안에 있나? (orgId)
}

const VertSeparator = styled.div`
  height: 100%;
  min-height: 13px;
  background-color: #c8ced3;
  width: 1px;
  margin-left: 12px;
`

const MyOrgName = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #708190;
`

const ServerStatus = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #708190;
`

const BuildTime = styled.div`
  font-size: 12px;
  color: #c9c9c9;
  margin-right: 10px;
`

const CustomBadge = styled(Badge)`
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
`

const ORG_ADMIN_BADGE_ID = 'org-admin-badge'
const ORG_SYSTEM_ADMIN_TOOLTIP_HEADER = 'System Admin'
const ORG_DATA_ADMIN_TOOLTIP_HEADER = 'Data Admin'
const ORG_SYSTEM_ADMIN_TOOLTIP_BODY = 'System Admin can manage user and organization but can’t manage agent'
const ORG_DATA_ADMIN_TOOLTIP_BODY = 'Data Admin can manage agent but can’t manage user and organization'

export const ODHeader: React.FC<Props> = ({ name, profile, isInAdmin, isInOrgAdmin }) => {
  const isAdmin = (profile?.priv || 0) >= USER_PRIV.SuperAdmin
  const { currentOrg, isInsideCILSAdmin } = useCILSHelper()
  const isSystemAdmin = (currentOrg?.orgUserPriv || 0) === ORG_USER_PRIV.SystemAdmin
  const isDataAdmin = (currentOrg?.orgUserPriv || 0) === ORG_USER_PRIV.DataAdmin
  const isAdminOfCurrentOrg = isSystemAdmin || isDataAdmin
  const hasBadge = isAdminOfCurrentOrg || (isInsideCILSAdmin && isAdmin)

  const [isOpenTooltip, setOpenTooltip] = React.useState<boolean>(false)

  const { state } = useCILSAgentContext()
  const { initializing, connected } = state
  const status = initializing ? 'Initializing' : connected ? 'On' : 'Off'
  const urlHome = (() => {
    if (!currentOrg) {
      return SiteUrls.User.Root
    }
    return SiteUrls.User.Org.Root(currentOrg!.org!.orgId)
  })()

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <Link to={urlHome}>
        <AppNavbarBrand
          style={{ width: 179, marginLeft: 21, marginTop: -5 }}
          full={{ src: logo, width: 179, height: 36, alt: 'Logo' }}
          minimized={{ src: logo, width: 179, height: 36, alt: 'Logo' }}
        />
      </Link>
      <Nav className="ml-auto" navbar>
        <BuildTime>Web build : {buildTime}</BuildTime>
        <ServerStatus style={{ marginRight: 15 }}>CILSAgent Status : {status}</ServerStatus>
        {/*<DefaultHeaderDropdown notif />*/}
        {currentOrg && <MyOrgName>{currentOrg?.org?.name || ''}</MyOrgName>}
        {isInsideCILSAdmin && <MyOrgName>CILS Admin</MyOrgName>}
        <VertSeparator style={{ marginRight: hasBadge ? 12 : 3 }} />
        {isAdminOfCurrentOrg && (
          <>
            <CustomBadge
              id={ORG_ADMIN_BADGE_ID}
              pill
              style={{ backgroundColor: isSystemAdmin ? '#f86c6b' : '#4dbd74', color: '#fff' }}
              onMouseEnter={() => setOpenTooltip(true)}
              onMouseLeave={() => setOpenTooltip(false)}
            >
              {isSystemAdmin ? 'System Admin' : 'Data Admin'}
            </CustomBadge>
            <ODTooltip
              header={isSystemAdmin ? ORG_SYSTEM_ADMIN_TOOLTIP_HEADER : ORG_DATA_ADMIN_TOOLTIP_HEADER}
              body={isSystemAdmin ? ORG_SYSTEM_ADMIN_TOOLTIP_BODY : ORG_DATA_ADMIN_TOOLTIP_BODY}
              isOpen={isOpenTooltip}
              toggle={() => setOpenTooltip(v => !v)}
              targetElementId={ORG_ADMIN_BADGE_ID}
            />
          </>
        )}
        {isInsideCILSAdmin && isAdmin && (
          <CustomBadge pill color="danger">
            CILS Admin
          </CustomBadge>
        )}
        <ProfileDropdown name={name} isAdmin={isAdmin} />
      </Nav>
    </React.Fragment>
  )
}
