import { USER_TYPE } from '@cils/common'
import React from 'react'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import { GQLListableOrgHasUser, GQLListableOrgHasUserInput, GQLUser } from '../../../@types/server'
import { ODButton, ODButtonTheme } from '../../../components/ODButton'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { ODModal } from '../../../components/ODModal/ODModal'
import { ODModalBody } from '../../../components/ODModal/ODModalBody'
import { ODModalFooter } from '../../../components/ODModal/ODModalFooter'
import { ODModalHeader } from '../../../components/ODModal/ODModalHeader'
import { useODQuery } from '../../../context/ODCommon'
import { USER_FULL_SNAPSHOT } from '../../../gqls'
import { Utils } from '../../../utils'

const CustomSelectBox = styled(AsyncSelect)`
  width: 322px;
`

const UserListWrapper = styled.div`
  display: flex;
  border-radius: 3px;
  background-color: #ffffff;
  flex-direction: column;
`

const InviteDesc = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #73818f;
`

type ODModalShareProps = {
  initialUsers: Array<GQLUser>
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  title: string
  placeholder?: string
  bottomDescription?: string
  makeName?: (user: GQLUser) => string
  buttonName: string
  orgId: number
  onConfirm: (users: Array<GQLUser>) => void
}

const GQL_LIST_ORG_USERS = `
query listOrgHasUser($data: ListableOrgHasUserInput!) {
  listOrgHasUser(data: $data) {
    list {
      user {
        ${USER_FULL_SNAPSHOT}
      }
      joinId
      orgUserPriv
      createdAt
      lastLoginToken
    }
    totalCount
    page
    pageSize
  }
}
`

type SelectOptionType = {
  value: number
  key: string
  label: string
  user: GQLUser
}

const UserRowSpan = styled.span`
  margin: 0 5px 15px 5px;
`

const UserRow: React.FC<{ name: string; onClick: () => void; noIcon?: boolean }> = props => {
  return (
    <UserRowSpan>
      {props.name}
      {!props.noIcon && (
        <ODIcon icon={ODIcons.CoreTrash} onClick={props.onClick} style={{ cursor: 'pointer', marginLeft: 15 }} />
      )}
    </UserRowSpan>
  )
}

const defaultMakeName = (user: GQLUser) => `${user.name} : ${user.email}`

export const ODModalUserPicker: React.FC<ODModalShareProps> = props => {
  const {
    initialUsers,
    isOpen,
    setIsOpen,
    title,
    placeholder,
    bottomDescription,
    makeName = defaultMakeName,
    buttonName,
    orgId,
    onConfirm,
  } = props

  const apiFindUser = useODQuery<Partial<GQLListableOrgHasUserInput>, GQLListableOrgHasUser>(GQL_LIST_ORG_USERS)

  const [users, setUsers] = React.useState<Array<GQLUser>>(initialUsers)
  const [membersToAdd, setMembersToAdd] = React.useState<Array<SelectOptionType>>([])

  const findUser = React.useCallback(
    async (inputValue: string): Promise<Array<SelectOptionType>> => {
      const r = await apiFindUser({
        filter: inputValue,
        page: 1,
        pageSize: 30,
        userType: USER_TYPE.Normal,
        userId: null,
        orgId,
      })
      return r.list.map(ohu => {
        const user = ohu.user

        return { value: user.userId, key: user.userId.toString(), label: makeName(user), user }
      })
    },
    [apiFindUser, orgId, makeName]
  )

  const handleModalClose = async (v: boolean) => {
    if (v) {
      setIsOpen(true)
    } else if (!v) {
      setIsOpen(false)
    }
  }

  const handleAddMemberChange = (v: SelectOptionType) => {
    if (membersToAdd.find(existing => existing.value === v.value) || users.find(u => u.userId === v.value)) {
      Utils.showInfo('Already exists.')
      return
    }

    setMembersToAdd(existing => [...existing, v])
  }

  const handleSave = () => {
    onConfirm([...users, ...membersToAdd.map(v => v.user)])
  }

  const removeUser = (userId: number) => {
    const i = membersToAdd.findIndex(existing => existing.value === userId)
    if (i >= 0) {
      membersToAdd.splice(i, 1)
      setMembersToAdd([...membersToAdd])
      return
    }

    const i2 = users.findIndex(u => u.userId === userId)
    if (i2 >= 0) {
      users.splice(i2, 1)
      setUsers([...users])
    }
  }

  return (
    <ODModal isOpen={isOpen} toggle={() => handleModalClose(false)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={title} onClose={() => handleModalClose(false)} />
        <ODModalBody>
          <UserListWrapper style={{ marginBottom: 12 }}>
            {users.map(user => (
              <UserRow key={user.userId.toString()} name={makeName(user)} onClick={() => removeUser(user.userId)} />
            ))}
            {membersToAdd.map(option => (
              <UserRow key={option.value.toString()} name={option.label} onClick={() => removeUser(option.value)} />
            ))}
            {users.length === 0 && membersToAdd.length === 0 && <UserRow name="No users" onClick={() => null} noIcon />}
          </UserListWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <CustomSelectBox
                defaultOptions
                placeholder={placeholder}
                cacheOptions={false}
                // TODO: 이상하게 multi 처리시 unique key error 가 난다. 우선 급하므로 단일 사용자 추가를 하도록 한다.
                // isMulti
                loadOptions={findUser}
                onChange={handleAddMemberChange}
                value={membersToAdd}
              />
            </div>
            <InviteDesc>{bottomDescription}</InviteDesc>
          </div>
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleSave}>
            {buttonName}
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
