import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLProjectMetaDataPreset } from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'
import { IProjectMetadataListableOption } from '../ProjectMetadataCommon'

export function useProjectMetaDataListDataLoader(orgId: number) {
  const { listProjectMetaData } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectMetadataListableOption
    ): Promise<ODListableResponseType<GQLProjectMetaDataPreset>> {
      const r = await listProjectMetaData({
        page,
        pageSize,
        orgId,
        filter: options.filter,
      })
      return r as ODListableResponseType<GQLProjectMetaDataPreset>
    },
    [listProjectMetaData, orgId]
  )
}
