import * as React from 'react'
import { AdminSiteNavs } from '../../routes/AdminNavs'
import { AdminRoutes } from '../../routes/AdminRoutes'
import { SiteUrls } from '../../urls'
import { CommonMainLayout } from '../CommonMainLayout'

export const AdminMainLayout: React.FC = () => {
  return (
    <CommonMainLayout
      routes={AdminRoutes}
      navs={AdminSiteNavs}
      defaultRedirect={SiteUrls.Admin.Dashboard.Main}
      isAdminLayout
      isInOrgAdmin={false}
    />
  )
}
