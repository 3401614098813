import { ORG_ROLE, PROJECT_TYPE } from '@cils/common'
import { ODHSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import { GQLProject } from '../../../../@types/server'
import { BlockingLoadBox } from '../../../../components/BlockingLoadBox'
import { DROPDOWN_MENU } from '../../../../components/ODImageGrid/ODImageGridSearchToolBar'
import { ODSearchBox } from '../../../../components/ODSearchBox'
import { ProjectStatusInOrgAdminDropDown } from '../../../../components/project/dropdown/ProjectStatusInOrgAdminDropDown'
import { ODModalAddUserToProject } from '../../../../components/project/modal/ODModalAddUserToProject'
import { ODModalAddWorksetsToProjectResult } from '../../../../components/project/modal/ODModalAddWorksetsToProjectResult'
import { ODModalCreateProject } from '../../../../components/project/modal/ODModalCreateProject'
import { useAPIs } from '../../../../context/useAPIs'
import { useOrgPerm } from '../../../../context/useOrgPerm'
import { useCILSStore } from '../../../../di/configureRootStore'
import { ODListableContextType, ODListableOption } from '../../../../ODListable/ODListableContext'
import { Utils } from '../../../../utils'
import { IProjectListableOption } from '../ProjectCommon'
import { useProjectPickerModal } from '../useProjectPickerModal'
import { useWorksetsToProjectPickerModal } from '../useWorksetsToProjectPickerModal'
import { SortByProjectDropdown } from './SortByProjectDropdown'

interface IProjectSearch<T, O extends ODListableOption> {
  listableContext: React.Context<ODListableContextType<T, O>>
  orgId: number
  refresh: () => void
  showInOrg?: boolean
  showInOrgAdmin?: boolean
}

export const ProjectSearch: React.FC<IProjectSearch<GQLProject, IProjectListableOption>> = observer(props => {
  const { listableContext, orgId, refresh, showInOrg, showInOrgAdmin } = props
  const store = useCILSStore()
  const { createProject } = useAPIs()
  const {
    updateLoadOption,
    state: { loadOption },
  }: ODListableContextType<GQLProject, IProjectListableOption> = React.useContext(listableContext)
  const [loading, setLoading] = React.useState(false)
  const [keyword, setKeyword] = React.useState('')
  const [isSortMenuOpen, setIsSortMenuOpen] = React.useState<DROPDOWN_MENU | null>(null)
  const [isStatusMenuOpen, setIsStatusMenuOpen] = React.useState<DROPDOWN_MENU | null>(null)
  const [isCreateProjectOpen, setIsCreateProjectOpen] = React.useState(false)
  const [isAddUserOpen, setIsAddUserOpen] = React.useState(false)
  const [isAddWorksetsResultOpen, setIsAddWorksetsResultOpen] = React.useState(false)
  const permUtils = useOrgPerm(orgId)
  const canCreateProject = permUtils?.isAllowed(ORG_ROLE.CREATE_PROJECT) ?? false

  const {
    Component: PickerTableComponent,
    props: pickerTableProps,
    pick: pickWorksetsToProject,
  } = useWorksetsToProjectPickerModal(null)

  const handleMultipleWorksetsToProject = React.useCallback(async () => {
    const title = `Add Worksets`
    const searchPlaceholder = 'Search by workset name, project name'
    try {
      const selectedWorksetIds = await pickWorksetsToProject({
        showSearch: true,
        searchPlaceholder,
        returnFullValue: false,
        title,
      })

      const createdProjectId = [...store.addUserToProjectStore.projectIds]

      if (!selectedWorksetIds || !createdProjectId[0]) {
        return
      }

      setLoading(true)
      store.addWorksetToProjectStore.setProjectId(createdProjectId[0])
      store.addWorksetToProjectStore.addWorksetIds(selectedWorksetIds as number[])
      await store.addWorksetToProjectStore.submit()
      setIsAddWorksetsResultOpen(true)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
      refresh()
    }
  }, [pickWorksetsToProject])

  const {
    Component: ProjectPickerTableComponent,
    props: projectPickerTableProps,
    pick: pickProject,
  } = useProjectPickerModal()

  const handleMultipleSelectProject = React.useCallback(async () => {
    const title = `Select Project`
    const searchPlaceholder = 'Search by project name, username'
    try {
      const selectedProjectIds = await pickProject({
        showSearch: true,
        searchPlaceholder,
        returnFullValue: false,
        title,
      })

      if (!selectedProjectIds) {
        return
      }

      setLoading(true)
      store.addUserToProjectStore.addProjectIds(selectedProjectIds as number[])
      setIsAddUserOpen(true)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
      refresh()
    }
  }, [pickProject])

  const handleChange = (v: string) => {
    setKeyword(v)
    updateLoadOption({ ...loadOption, filter: v })
  }

  const onCreateProject = React.useCallback(
    async (name: string, projectType: PROJECT_TYPE) => {
      setLoading(true)

      try {
        const project = await createProject({
          name,
          type: Utils.formatProjectTypeForSubmit(projectType),
          ownerOrgId: orgId,
        })
        store.addUserToProjectStore.addProjectIds([project.projectId])
        setIsAddUserOpen(true)
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        refresh()
      }
    },
    [createProject]
  )

  const onAddUserToProject = async () => {
    try {
      setIsAddUserOpen(false)
      await handleMultipleWorksetsToProject()
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
      refresh()
    }
  }

  const containerPadding = showInOrgAdmin ? {} : { padding: '18px 30px 20px' }
  return (
    <Container
      fluid
      style={{
        backgroundColor: 'white',
        borderBottom: '1px solid #c8ced3',
        ...containerPadding,
      }}
    >
      <PickerTableComponent {...pickerTableProps} />
      <ProjectPickerTableComponent {...projectPickerTableProps} />
      <ODModalCreateProject
        isOpen={isCreateProjectOpen}
        onClose={() => {
          setIsCreateProjectOpen(false)
        }}
        onCreate={onCreateProject}
      />
      <ODModalAddUserToProject
        isOpen={isAddUserOpen}
        close={() => setIsAddUserOpen(false)}
        orgId={orgId}
        onAdd={onAddUserToProject}
      />
      <ODModalAddWorksetsToProjectResult
        orgId={orgId}
        isOpen={isAddWorksetsResultOpen}
        setIsOpen={setIsAddWorksetsResultOpen}
        openAddWorkset={() => handleMultipleWorksetsToProject()}
      />
      <BlockingLoadBox show={loading} />
      <div>
        <Row>
          <Col md={8} className="vcenter">
            <RowWrapper style={{ alignItems: 'center' }}>
              <ODSearchBox
                placeholder="Search by Project name"
                value={keyword}
                onChange={handleChange}
                style={{ width: '100%', maxWidth: 602, paddingTop: 12 }}
              />
              {!showInOrgAdmin && (
                <>
                  <SortByText style={{ marginLeft: 20 }}>Sort by</SortByText>
                  <ODHSpace w={15} />
                  <SortByProjectDropdown
                    isMenuOpen={isSortMenuOpen}
                    setIsMenuOpen={setIsSortMenuOpen}
                    listableContext={listableContext}
                  />
                </>
              )}
            </RowWrapper>
          </Col>
          <Col md={4} className="vcenter">
            <RowWrapper style={{ justifyContent: 'flex-end' }}>
              {!showInOrgAdmin && canCreateProject && (
                <>
                  {!showInOrg && (
                    <AddUserToTheProjectButton onClick={() => handleMultipleSelectProject()}>
                      <ButtonText>Add user to the project</ButtonText>
                    </AddUserToTheProjectButton>
                  )}
                  <ODHSpace w={6} />
                  <CreateProjectButton onClick={() => setIsCreateProjectOpen(true)}>
                    <ButtonText>Create Project</ButtonText>
                  </CreateProjectButton>
                </>
              )}
              {showInOrgAdmin && (
                <>
                  <ProjectStatusInOrgAdminDropDown
                    listableContext={listableContext}
                    isMenuOpen={isStatusMenuOpen}
                    setIsMenuOpen={setIsStatusMenuOpen}
                  />
                  <ODHSpace w={10} />
                  <SortByProjectDropdown
                    isMenuOpen={isSortMenuOpen}
                    setIsMenuOpen={setIsSortMenuOpen}
                    listableContext={listableContext}
                  />
                </>
              )}
            </RowWrapper>
          </Col>
        </Row>
      </div>
    </Container>
  )
})

const AddUserToTheProjectButton = styled.div`
  width: 190px;
  min-width: 190px;
  max-width: 190px;
  height: 35px;
  padding: 6px 0 7px 1px;
  border-radius: 3px;
  background-color: #29b6ca;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const CreateProjectButton = styled.div`
  width: 135px;
  min-width: 135px;
  max-width: 135px;
  height: 35px;
  padding: 6px 0 7px 1px;
  border-radius: 3px;
  background-color: #29b6ca;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 72px;
  background-color: #ffffff;
`
const SortByText = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #2f353a;
`
