import { DOAddUserToProjectResult, worksetUserPermissionToString } from '@cils/common'
import {
  AddUserToProjectResultType,
  IAddUserToProjectResultSuccessMessage,
} from '@cils/common/lib/CILSStore/projectView/IAddUserToProjectStore'
import { ODHSpace, ODVSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { GQLWorkset } from '../../../@types/server'
import { CheckImg, UncheckImg } from '../../../assets/images'
import { PROJECT_USER_PERM_STRING } from '../../../containers/User/Project/ProjectCommon'
import { useCILSStore } from '../../../di/configureRootStore'
import { ODColors } from '../../../global-styles'
import { ODIcon, ODIcons } from '../../ODIcon'

interface IAddUserToProjectResultTr {
  item: DOAddUserToProjectResult
  handleRemoveUser: (result: DOAddUserToProjectResult) => void
  setIsCheckPermissionOpen: (item: DOAddUserToProjectResult) => void
  handleShareWorkset: (workset: GQLWorkset) => void
}

export const AddUserToProjectResultTr: React.FC<IAddUserToProjectResultTr> = observer(props => {
  const { item, handleRemoveUser, setIsCheckPermissionOpen, handleShareWorkset } = props
  const store = useCILSStore()
  const addUserStore = store.addUserToProjectStore
  // const [openTooltip, setOpenTooltip] = React.useState(false)

  const SuccessText = 'Success'
  const FailedText = 'Failed'
  return (
    <tr>
      <td
        style={{ cursor: 'pointer' }}
        onClick={() => {
          addUserStore.toggleResultChecked(item)
        }}
      >
        <CheckboxImage src={addUserStore.isResultChecked(item) ? CheckImg : UncheckImg} alt="" />
      </td>
      <td>{item.user.username}</td>
      <td>{item.user.name}</td>
      <td>{item.user.email}</td>
      <td>{item.confirmedProjectPerm ? PROJECT_USER_PERM_STRING[item.confirmedProjectPerm] : '-'}</td>
      <td>
        {item.type === AddUserToProjectResultType.Success && (
          <ColumnRow>
            <SuccessBadge>{SuccessText}</SuccessBadge>
            {item.messages.length > 0 && (
              <>
                <ODVSpace h={11} />
                <span style={{ color: ODColors.CharcoalGrey, fontSize: 12 }}>
                  Automatically added users after
                  <br />
                  changing user permission of worksets.
                </span>
                <ODVSpace h={10} />
                {item.messages.map(mes => {
                  const message = mes as IAddUserToProjectResultSuccessMessage
                  return (
                    <Row key={mes.workset.wsId} style={{ alignItems: 'center' }}>
                      <UnderLineText>{message.workset.name ?? '-'}</UnderLineText>
                      <ODHSpace w={10} />
                      <OriginPermText>
                        {message.beforeWorksetPerm
                          ? worksetUserPermissionToString(message.beforeWorksetPerm)
                          : 'Not in workset'}
                      </OriginPermText>
                      <ODHSpace w={3} />
                      <ODIcon
                        icon={ODIcons.MaterialArrowForwardIcon}
                        style={{
                          width: 12,
                          height: 12,
                          fontSize: 12,
                          color: ODColors.Primary,
                          position: 'relative',
                          top: 2,
                        }}
                      />
                      <ODHSpace w={3} />
                      <ChangedPermText>
                        {message.afterWorksetPerm ? worksetUserPermissionToString(message.afterWorksetPerm) : '-'}
                      </ChangedPermText>
                    </Row>
                  )
                })}
              </>
            )}
            <ODVSpace h={10} />
            <RemoveButton onClick={() => handleRemoveUser(item)}>
              <ODIcon icon={ODIcons.CoreTrash} style={{ fontSize: 14 }} />
              <ODHSpace w={6} />
              Remove this user
            </RemoveButton>
          </ColumnRow>
        )}
        {item.type === AddUserToProjectResultType.Fail && (
          <ColumnRow>
            <FailBadge>{FailedText}</FailBadge>
            <ODVSpace h={10} />
            {item.messages.length > 0 && (
              <>
                <span style={{ color: ODColors.CharcoalGrey, fontSize: 12 }}>You cannot change permission of</span>
                <ODVSpace h={2} />
                <Row style={{ flexWrap: 'wrap' }}>
                  {item.messages.map((mes, index) => {
                    return (
                      <UnderLineText key={mes.workset.wsId} onClick={() => handleShareWorkset(mes.workset)}>
                        {index !== 0 && ', '}
                        {mes.workset.name}
                      </UnderLineText>
                    )
                  })}
                </Row>
                <ODVSpace h={14} />
                {/* 해당 사용자의 Workset 권한을 조회하고 수정할 수 있는 기능 */}
                <Row style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => setIsCheckPermissionOpen(item)}>
                  <ODIcon icon={ODIcons.CoreShieldAlt} style={{ fontSize: 14, color: ODColors.Primary }} />
                  <ODHSpace w={6} />
                  <span style={{ fontSize: 14, color: ODColors.Primary }}>Check workset permission</span>
                </Row>
                <ODVSpace h={7} />
                {/* 해당 유저를 프로젝트에 추가하는 것을 다시 시도 */}
                <Row style={{ alignItems: 'center', cursor: 'pointer' }} onClick={() => addUserStore.retryUser(item)}>
                  <ODIcon icon={ODIcons.CoreReload} style={{ fontSize: 14, color: ODColors.Primary }} />
                  <ODHSpace w={6} />
                  <span style={{ fontSize: 14, color: ODColors.Primary }}>Retry to add this user</span>
                </Row>
                <ODVSpace h={7} />
              </>
            )}
            {/* Failed 된 유저는 팝업을 띄우지 않고 바로 삭제해야 함! */}
            <RemoveButton onClick={() => handleRemoveUser(item)}>
              <ODIcon icon={ODIcons.CoreTrash} style={{ fontSize: 14 }} />
              <ODHSpace w={6} />
              Delete
            </RemoveButton>
          </ColumnRow>
        )}

        {/*{item.result === 'Failed' && (*/}
        {/*  <ColumnRow>*/}
        {/*
        {/*  </ColumnRow>*/}
        {/*)}*/}
      </td>
    </tr>
  )
})

const CheckboxImage = styled.img`
  position: relative;
  top: 5px;
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
`

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  color: ${ODColors.Salmon};
  font-size: 14px;

  &:active {
    border: none;
  }
`

// const HasTooltipTd = styled.td`
//   &:hover {
//     text-decoration: underline;
//     color: ${ODColors.PurplyBlue};
//   }
// `

const SuccessBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ODColors.DarkMint};
  border-radius: 4px;
  width: 60px;
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  color: ${ODColors.White};
`

const FailBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ODColors.Salmon};
  border-radius: 4px;
  width: 60px;
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  color: ${ODColors.White};
`

const Row = styled.div`
  display: flex;
`

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`

const UnderLineText = styled.span`
  font-size: 12px;
  text-decoration: underline;
  color: ${ODColors.Primary};
  font-weight: bold;
  cursor: pointer;
`

const OriginPermText = styled.span`
  font-size: 12px;
  color: ${ODColors.Steel};
`

const ChangedPermText = styled.span`
  font-size: 12px;
  color: ${ODColors.CharcoalGrey};
`
