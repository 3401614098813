import { IMAGE_SORT_OPTION } from '@cils/common'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import Sticky from 'react-sticky-el'
import styled from 'styled-components'
import { GQLItem } from '../../@types/server'
import { ItemDataLoaderOption } from '../../containers/User/TCFItems/ItemsContainer'
import { ODListableContextType } from '../../ODListable/ODListableContext'
import { NoItemsFoundBox } from '../NoItemsFoundBox'
import { GridItem, GridItemProps } from './GridItem'
import { ImageAction, ImageActionProps } from './ImageAction'
import { ODImageGridDateSeparator } from './ODImageGridDateSeparator'

type ODImageGridProps = {
  imageSize: number
  listableContext: React.Context<ODListableContextType<GQLItem, ItemDataLoaderOption>>
  extractGridItemProps: (item: GQLItem) => Omit<GridItemProps, 'size' | 'onClick' | 'onCheckClick'>
  onClickItem: (item: GQLItem) => void
  imageGridRef: any
  contextMenuComponent: JSX.Element | null
  imageActionProps: ImageActionProps
}

const Wrapper = styled.div`
  //position: relative;
  display: flex;
  //justify-content: space-between;
  padding: 20px 30px 30px 30px;
  flex-wrap: wrap;
`

type Separator = {
  type: 'SEPARATOR'
  name: string
  date: string
  itemsToSelect: Array<number> // Array<itemId> to select on click, remove on un-click.
}

export function ODImageGrid(props: ODImageGridProps) {
  const {
    imageSize,
    listableContext,
    extractGridItemProps,
    onClickItem,
    imageGridRef,
    contextMenuComponent,
    imageActionProps,
  } = props

  const { state, addToSelected, removeFromSelected, keyExtractor } = React.useContext(listableContext)
  const { selectedItems, loading, list, loadOption } = state

  // const isSortByDate = updateL
  const isSortByDate =
    !loadOption ||
    !loadOption.sortBy ||
    loadOption.sortBy === IMAGE_SORT_OPTION.CreatedDateAscending ||
    loadOption.sortBy === IMAGE_SORT_OPTION.CreatedDateDescending

  const listWithSeparator: Array<GQLItem | Separator> = (origList => {
    if (!isSortByDate) {
      return origList
    }

    const newList: Array<GQLItem | Separator> = []
    let lastDateToken = ''
    let arrToAddItemIds: Array<number> = []

    for (const item of origList) {
      // console.log(item)
      // const dateToken = moment(item.recordingTime).format('YYYY.MM.DD')
      const dateToken = moment(item.recordingTime.split('_')[0], 'YYYY-MM-DD').format('YYYY.MM.DD')
      if (dateToken !== lastDateToken) {
        arrToAddItemIds = []
        newList.push({
          type: 'SEPARATOR',
          name: dateToken,
          date: item.recordingTime,
          itemsToSelect: arrToAddItemIds,
        })
        lastDateToken = dateToken
      }
      newList.push(item)
      arrToAddItemIds.push(item.itemId)
    }

    return newList
  })(list)

  const [lastSelectedItem, setLastSelectedItem] = React.useState<GQLItem | null>(null)
  const [isShiftDown, setIsShiftDown] = React.useState<boolean>(false)

  const handleKeyUp = (e: any) => {
    if (e.key === 'Shift') {
      setIsShiftDown(false)
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Shift') {
      setIsShiftDown(true)
    }
  }

  const handleOnClick = (isSelected: boolean, item: GQLItem) => {
    setLastSelectedItem(item)
    // const hasBeenSelected = !selectedItems.has(item.itemId)
    const hasBeenSelected = selectedItems.findIndex(v => v.itemId === item.itemId) < 0

    if (isShiftDown) {
      const newSelectedItems = getNewSelectedItems(item)
      const selections = _.uniqBy([...selectedItems, ...newSelectedItems], v => v.itemId)

      if (!hasBeenSelected) {
        // return selections.filter(item => !newSelectedItems.includes(item))
        return selections.filter(item => newSelectedItems.findIndex(v => v.itemId === item.itemId) < 0)
      }

      addToSelected(selections)
    } else {
      if (selectedItems.findIndex(v => v.itemId === item.itemId) >= 0) {
        removeFromSelected([item])
      } else {
        addToSelected([item])
      }
    }
  }

  const getNewSelectedItems = (v: GQLItem) => {
    const currentSelectedIndex = list.findIndex(item => parseInt(keyExtractor(item), 10) === v.itemId)
    const lastSelectedIndex = list.findIndex(item => parseInt(keyExtractor(item), 10) === lastSelectedItem?.itemId)

    return list.slice(
      Math.min(lastSelectedIndex, currentSelectedIndex),
      Math.max(lastSelectedIndex, currentSelectedIndex) + 1
    )
    // .map(item => parseInt(keyExtractor(item), 10))
  }

  React.useEffect(() => {
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const actionBarShown = selectedItems.length > 0

  return (
    <Wrapper ref={imageGridRef}>
      <Sticky
        className={actionBarShown ? 'stickyActionBarNormal' : 'stickyActionBarHidden'}
        stickyClassName="stickyActionBar"
      >
        <ImageAction {...imageActionProps} />
      </Sticky>
      {!loading && list.length === 0 && <NoItemsFoundBox />}
      {selectedItems.length !== 0 && contextMenuComponent}
      {listWithSeparator &&
        listWithSeparator.map(itemOrSep => {
          if ((itemOrSep as Separator).type === 'SEPARATOR') {
            const sep = itemOrSep as Separator
            return <ODImageGridDateSeparator key={sep.name} name={sep.name} />
          }

          const item = itemOrSep as GQLItem
          const gridItemProps = extractGridItemProps(item)
          const id = parseInt(keyExtractor(item), 10)
          const isSelected = !!selectedItems.find(v => v.itemId === id)

          return (
            <GridItem
              key={keyExtractor(item)}
              selected={isSelected}
              size={imageSize}
              spacing={Math.max(3, Math.min(12, imageSize / 25))}
              {...gridItemProps}
              onClick={() => onClickItem(item)}
              onCheckClick={() => handleOnClick(isSelected, item)}
              isHidden={item.hidden}
              itemId={id}
            />
          )
        })}
    </Wrapper>
  )
}
