import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import { GQLWorksetGroup } from '../../../@types/server'
import { BlockingLoadBox } from '../../../components/BlockingLoadBox'
import { DROPDOWN_MENU } from '../../../components/ODImageGrid/ODImageGridSearchToolBar'
import { ODModalCreateWorksetGroup } from '../../../components/ODModal/ODModalCreateWorksetGroup'
import { ODSearchBox } from '../../../components/ODSearchBox'
import { useAPIs } from '../../../context/useAPIs'
import { ODListableContextType, ODListableOption } from '../../../ODListable/ODListableContext'
import { Utils } from '../../../utils'
import { SortByWorksetGroupDropdown } from './SortByWorksetGroupDropdown'
import { useWorksetsToGroupPickerModal } from './useWorksetsToGroupPickerModal'
import { IWorksetGroupListableOption } from './WorksetGroupCommon'

interface IODWorksetGroupSearch<T, O extends ODListableOption> {
  listableContext: React.Context<ODListableContextType<T, O>>
  orgId: number
  refresh: () => void
}

export const ODWorksetGroupSearch: React.FC<IODWorksetGroupSearch<
  GQLWorksetGroup,
  IWorksetGroupListableOption
>> = props => {
  const { listableContext, orgId, refresh } = props
  const { createWorksetGroup, createWorksetGroupHasWorkset } = useAPIs()
  const {
    updateLoadOption,
    state: { loadOption },
  }: ODListableContextType<GQLWorksetGroup, IWorksetGroupListableOption> = React.useContext(listableContext)
  const [loading, setLoading] = React.useState(false)
  const [keyword, setKeyword] = React.useState('')
  const [isMenuOpen, setIsMenuOpen] = React.useState<DROPDOWN_MENU | null>(null)
  const [isCreateGroupOpen, setIsCreateGroupOpen] = React.useState(false)
  const {
    Component: PickerTableComponent,
    props: pickerTableProps,
    pick: pickWorksetsToGroup,
  } = useWorksetsToGroupPickerModal(null)
  const wsgId = React.useRef<null | number>(null)

  const handleChange = (v: string) => {
    setKeyword(v)
    updateLoadOption({ ...loadOption, filter: v })
  }

  const handleMultipleWorksetsToGroup = React.useCallback(
    async (wsgId: number, groupName: string) => {
      const title = `Add Worksets to ${groupName}`
      const searchPlaceholder = 'Search by workset name, project name, Workset group name'
      try {
        const selectedWorksetIds = await pickWorksetsToGroup({
          showSearch: true,
          searchPlaceholder,
          returnFullValue: false,
          title,
        })

        if (!selectedWorksetIds) {
          return
        }

        setLoading(true)
        await createWorksetGroupHasWorkset({ wsgId, worksetIds: selectedWorksetIds as number[] })
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        refresh()
      }
    },
    [pickWorksetsToGroup, createWorksetGroupHasWorkset, refresh]
  )

  const onCreateWorkSetGroup = React.useCallback(
    async (groupName: string) => {
      setLoading(true)

      try {
        const wsg = await createWorksetGroup({ name: groupName, ownerOrgId: orgId })
        wsgId.current = wsg.wsgId
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
        refresh()
      }

      if (wsgId.current) {
        try {
          await handleMultipleWorksetsToGroup(wsgId.current, groupName)
        } catch (ex) {
          Utils.showError(ex)
        }
      }
    },
    [handleMultipleWorksetsToGroup, createWorksetGroup, orgId, refresh]
  )

  return (
    <Container
      fluid
      style={{
        backgroundColor: 'white',
        paddingTop: 18,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 20,
        borderBottom: '1px solid #c8ced3',
      }}
    >
      <PickerTableComponent {...pickerTableProps} />
      <ODModalCreateWorksetGroup
        isOpen={isCreateGroupOpen}
        onClose={() => {
          setIsCreateGroupOpen(false)
        }}
        onCreate={onCreateWorkSetGroup}
      />
      <BlockingLoadBox show={loading} />
      <div>
        <Row>
          <Col md={8} className="vcenter">
            <RowWrapper style={{ alignItems: 'center' }}>
              <ODSearchBox
                placeholder="Search by Workset Group, Workset"
                value={keyword}
                onChange={handleChange}
                style={{ width: '100%', maxWidth: 602, paddingTop: 12 }}
              />
            </RowWrapper>
          </Col>
          <Col md={4} className="vcenter">
            <RowWrapper style={{ justifyContent: 'flex-end' }}>
              <SortByText style={{ marginRight: 16 }}>Sort by</SortByText>
              <SortByWorksetGroupDropdown
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                listableContext={listableContext}
              />
              <CreateWorksetGroupButton onClick={() => setIsCreateGroupOpen(true)}>
                <CreateWorksetGroupButtonText>New Workset group</CreateWorksetGroupButtonText>
              </CreateWorksetGroupButton>
            </RowWrapper>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

const CreateWorksetGroupButton = styled.div`
  margin-left: 15px;
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  height: 35px;
  padding: 6px 0 7px 1px;
  border-radius: 3px;
  background-color: #29b6ca;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const CreateWorksetGroupButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 72px;
  background-color: #ffffff;
`
const SortByText = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #2f353a;
`
