import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import { GQLListableUserGroup, GQLListableUserGroupInput, GQLUserGroup } from '../../@types/server'
import { FlexContentsContainer } from '../../components/FlexContentsContainer'
import { ODTabBarButton, ODTabBarContainer } from '../../components/ODTabBarButton'
import { useODQuery } from '../../context/ODCommon'
import { USER_GROUP_FULL_SNAPSHOT } from '../../gqls'
import { createODListableContext, ODListableOption, ODListableResponseType } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils'

type UserGroupContainerProps = {
  orgId: number | string
}

export interface UserGroupLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLUserGroup, UserGroupLoaderOption>()

const GQL_LIST_USER_GROUP = `
query listUserGroup($data: ListableUserGroupInput!) {
  listUserGroup(data: $data) {
    list {
      ${USER_GROUP_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

export const UserGroupContainer: React.FC<UserGroupContainerProps> = ({ orgId }) => {
  const [selected, setSelected] = React.useState(0)

  const apiList = useODQuery<GQLListableUserGroupInput, GQLListableUserGroup>(GQL_LIST_USER_GROUP)

  const dataLoader = React.useCallback(
    async function OrgDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: UserGroupLoaderOption
    ): Promise<ODListableResponseType<GQLUserGroup>> {
      const r = await apiList({ orgId: parseInt(orgId.toString(), 10), page, pageSize, filter: options.filter || null })
      return r as ODListableResponseType<GQLUserGroup>
    },
    [orgId, apiList]
  )
  const TableDefinition: ODListableTableDefinition<GQLUserGroup, UserGroupLoaderOption> = [
    {
      id: 'group',
      title: 'Group Name',
      transform: v => {
        return <Link to={SiteUrls.OrgAdmin.UserGroup.Edit(v.groupId)(orgId)}>{v.name}</Link>
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'member',
      title: 'Member',
      transform: v => {
        return v.members.map(v => {
          return (
            <React.Fragment key={v.userId}>
              <span>
                {v.name} ({v.email})
              </span>
              <br />
            </React.Fragment>
          )
        })
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'workset',
      title: 'Workset',
      transform: v => {
        return v.worksets.map(v => {
          return (
            <React.Fragment key={v.wsId}>
              <span>{v.name}</span>
              <br />
            </React.Fragment>
          )
        })
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <ODTabBarContainer>
        <ODTabBarButton active={selected === 0} onClick={() => setSelected(0)}>
          <strong>All</strong>
        </ODTabBarButton>
      </ODTabBarContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.groupId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search: Group Name, name, email"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <div>
                <Link to={SiteUrls.OrgAdmin.UserGroup.Add(orgId)} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    Add Group
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
