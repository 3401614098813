export enum ODDashboardCardTheme {
  Blue,
  Yellow,
  Red,
}

export const ODDashboardCardBackgroundColorForTheme = {
  [ODDashboardCardTheme.Blue]: '#20a8d8',
  [ODDashboardCardTheme.Yellow]: '#ffc107',
  [ODDashboardCardTheme.Red]: '#f86c6b',
}

export const ODDashboardCardBorderColorForTheme = {
  [ODDashboardCardTheme.Blue]: '#187da0',
  [ODDashboardCardTheme.Yellow]: '#ffc107',
  [ODDashboardCardTheme.Red]: '#f52e2e',
}
