import { useODModalConfirm } from '@odc/od-react-belt'
import { Decimal } from 'decimal.js'
import moment from 'moment'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import {
  GQLItem,
  GQLItemAttachmentPropsInput,
  GQLItemPropsInput,
  GQLOkResponse,
  GQLSetMetadataUpdateRequiredInput,
  GQLUserFavorItem,
  GQLUserFavorItemPropsInput,
} from '../../@types/server'
import { GQLSingleIDInput } from '../../agent'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { NotEnoughStorageBody } from '../../components/ODModal/NotEnoughStorageBody'
import { AttachmentEditData } from '../../components/ODModal/ODModalEditAttachment'
import { useCILSAgentContext } from '../../context/CILSAgentContext'
import { GQL_ITEM_SNAPSHOT, useODAppContext } from '../../context/ODAppContext'
import { useCILSAttachmentTypes, useCILSCategory, useGQLGetItem } from '../../context/ODCILSHooks'
import { useODMutation } from '../../context/ODCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils'
import { DetailContainer, GeneralInfoType, METADATA_EDIT_TYPE } from './DetailContainer'
import {
  convertItemAttachmentToThumbnailInfo,
  ThumbnailInfo,
} from './DetailContainerComponent/convertItemAttachmentToThumbnailInfo'
import { OpeningTomoAnalysisModal } from './DetailContainerComponent/OpeningTomoAnalysisModal'
import { WaitingDownloadForAnalysisModal } from './DetailContainerComponent/WaitingDownloadForAnalysisModal'

type TCFDetailWrapperProps = {
  tcfItem: GQLItem
  onClose: () => void
  onTCFItemUpdated: (updated: GQLItem) => void
  hasPrev: boolean
  onClickPrev: () => Promise<any>
  hasNext: boolean
  onClickNext: () => Promise<any>
  accessByShareToken?: boolean
  inWorksetId?: number
}

const GQL_UPDATE_TCF_ITEM = `
mutation updateItem($data: ItemPropsInput!) {
  updateItem(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}
`

const GQL_MAKE_MAIN_ATTACHMENT = `
mutation makeItemAttachmentMain($data: SingleIDInput!) {
  makeItemAttachmentMain(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}
`

const GQL_CREATE_ITEM_ATTACHMENT = `
mutation createItemAttachment($data: ItemAttachmentPropsInput!) {
  createItemAttachment(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}
`

const GQL_UPDATE_ITEM_ATTACHMENT = `
mutation updateItemAttachment($data: ItemAttachmentPropsInput!) {
  updateItemAttachment(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}
`

const GQL_CREATE_USER_FAVOR_ITEM = `
mutation createUserFavorItem($data: UserFavorItemPropsInput!) {
  createUserFavorItem(data: $data) {
    item {
      ${GQL_ITEM_SNAPSHOT}
    }
  }
}
`

const GQL_REMOVE_ITEM_ATTACHMENT = `
mutation removeItemAttachment($data: SingleIDInput!) {
  removeItemAttachment(data: $data) {
    ok
  }
}
`

const GQL_REMOVE_USER_FAVOR_ITEM = `
mutation removeUserFavorItem($data: SingleIDInput!) {
  removeUserFavorItem(data: $data) {
    ok
  }
}
`

const GQL_SET_METADATA_UPDATE_REQUIRED = `
mutation setMetadataUpdateRequired($data: SetMetadataUpdateRequiredInput!) {
  setMetadataUpdateRequired(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}
`

const sanitizeCreatedTime = (tcfRecordingTime: string): string => {
  const parsed = moment(tcfRecordingTime, 'YY-MM-DD HH:mm:ss.SSS')
  if (parsed.isValid()) {
    return parsed.format('YYYY-MM-DD HH:mm:ss')
  }
  return tcfRecordingTime
}

export const TCFDetailWrapper: React.FC<TCFDetailWrapperProps> = props => {
  const {
    tcfItem,
    onClose,
    onTCFItemUpdated,
    hasNext,
    hasPrev,
    onClickPrev,
    onClickNext,
    accessByShareToken,
    inWorksetId,
  } = props
  const { appOptions } = useODAppContext()
  const {
    queueDownload,
    getFreeDiskSpaceForDownload,
    state: { connected, initializing },
  } = useCILSAgentContext()
  const { updateTags } = useODSingleOrgContext()

  const apiUpdateItem = useODMutation<Partial<GQLItemPropsInput>, GQLItem>(GQL_UPDATE_TCF_ITEM)
  const apiSetAttachmentAsMain = useODMutation<GQLSingleIDInput, GQLItem>(GQL_MAKE_MAIN_ATTACHMENT)
  const apiCreateAttachment = useODMutation<Partial<GQLItemAttachmentPropsInput>, GQLItem>(GQL_CREATE_ITEM_ATTACHMENT)
  const apiUpdateAttachment = useODMutation<Partial<GQLItemAttachmentPropsInput>, GQLItem>(GQL_UPDATE_ITEM_ATTACHMENT)
  const apiRemoveAttachment = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE_ITEM_ATTACHMENT)
  const apiFavorItem = useODMutation<Partial<GQLUserFavorItemPropsInput>, GQLUserFavorItem>(GQL_CREATE_USER_FAVOR_ITEM)
  const apiUnfavorItem = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE_USER_FAVOR_ITEM)
  const apiSetMetadataUpdateRequired = useODMutation<GQLSetMetadataUpdateRequiredInput, GQLItem>(
    GQL_SET_METADATA_UPDATE_REQUIRED
  )
  const [queueDownloading, setQueueDownloading] = React.useState(false)
  const [showCannotConnectDownloader, setShowCannotConnectDownloader] = React.useState(false)
  const [showWaitingDownload, setShowWaitingDownload] = React.useState(false)

  const apiGetTCFItem = useGQLGetItem()

  const { categories: categoryOptions } = useCILSCategory(tcfItem.orgId)
  const { types } = useCILSAttachmentTypes()

  const {
    dataId,
    itemId,
    currentSnapshot,
    uploader,
    title,
    desc,
    meta,
    tags,
    imageFile: { fileId, link },
    mainAttachmentId,
    attachmentFiles,
    isFavorite,
    categories,
    permission,
    timeLapse,
    accessibleUsers,
    inWorksets,
    errors,
    itemHasImages,
  } = tcfItem

  const { link: movieLink } = timeLapse || {}

  const { siteAddress } = appOptions || {}
  const { size, tcfSize, uploadStatus } = currentSnapshot || {}
  const { name } = uploader || {}

  const tcfPermalink = `${siteAddress}${SiteUrls.User.Org.Item.SingleTCF(itemId)(tcfItem.orgId)}`
  const unavailable = !connected || initializing
  const downloadDisabledReason = unavailable
    ? !connected
      ? 'Cannot connect the downloader'
      : 'Downloader is initializing..'
    : null

  const attachments: Array<ThumbnailInfo> = attachmentFiles.map(f =>
    convertItemAttachmentToThumbnailInfo(f, mainAttachmentId)
  )
  attachments.unshift({
    id: `i_${fileId}`,
    isAttachment: false,
    link,
  })

  const metadata = Utils.parseJSONSafe<any>(meta, {})
  const created = sanitizeCreatedTime(metadata['/']?.['RecordingTime']?.[0] || '-')
  const modified = metadata['/']?.['CreateDate']?.[0] || '-'

  const converted: GeneralInfoType = {
    itemId,
    dataId,
    link: tcfPermalink,
    created,
    modified,
    metadata,
    size: prettyBytes(new Decimal(size || 0).toNumber()),
    title: title || '',
    explanation: desc || '',
    status: '1',
    mainImage: link,
    attachments,
    categories: categories.map(v => ({
      categoryId: v.categoryId,
      value: v.name!,
      label: v.name!,
    })),
    tcfSize: prettyBytes(new Decimal(tcfSize || 0).toNumber()),
    permission,
    accessibleUsers,
    inWorksets,
    uploadStatus: uploadStatus!,
    errorMessage: errors ?? '',
    itemHasImages,
  }

  const Share = {
    owner: name || '',
    org: 'Tomocube',
  }

  const {
    Component: DownloadConfirmComponent,
    confirm: confirmDownload,
    props: DownloadConfirmComponentProps,
  } = useODModalConfirm({ title: 'Not enough storage', yes: 'Download', no: 'Cancel' })

  const handleDownload = async () => {
    try {
      let available = new Decimal(999999999999)
      try {
        const freeSpace = await getFreeDiskSpaceForDownload()
        available = new Decimal(freeSpace.output)
      } catch (ex) {
        Utils.showError('PC 의 용량 체크에 실패하였습니다.')
        console.error(ex)
      }

      if (available.lt(new Decimal(size || 0))) {
        if (
          !(await confirmDownload({
            message: (
              <NotEnoughStorageBody
                downloadSize={prettyBytes(parseInt(size || '0', 10))}
                freeSpace={prettyBytes(parseInt(available.toString(), 10))}
              />
            ),
          }))
        ) {
          return
        }
      }

      setQueueDownloading(true)
      await queueDownload({ dataId })
      Utils.showSuccess('Download has been queued.')
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setQueueDownloading(false)
    }
  }

  const handleChangeMetadata = React.useCallback(
    async (type: METADATA_EDIT_TYPE, v: string) => {
      const updates: Partial<GQLItemPropsInput> = { id: itemId }
      let msg = ''
      switch (type) {
        case METADATA_EDIT_TYPE.TITLE:
          msg = 'Title saved.'
          updates.title = v
          break
        case METADATA_EDIT_TYPE.CATEGORY:
          msg = 'Category saved.'
          updates.categories = v.split(',')
          break
        case METADATA_EDIT_TYPE.TAG:
          msg = 'Tags saved.'
          updates.tags = v
            .split(',')
            .map(v => v.trim())
            .filter(v => v)
          break
        case METADATA_EDIT_TYPE.EXPLANATION:
          msg = 'Explanation saved.'
          updates.desc = v
          break
      }

      const r = await apiUpdateItem(updates)
      Utils.showSuccess(msg, 'Success')
      onTCFItemUpdated(r)

      if (type === METADATA_EDIT_TYPE.TAG) {
        // noinspection ES6MissingAwait => when tags are changed, update known org's tags list.
        updateTags()
      }
    },
    [apiUpdateItem, itemId, onTCFItemUpdated, updateTags]
  )

  const handleSetAttachmentAsMain = React.useCallback(
    async (attachmentId: string) => {
      const fileId = attachmentId.includes('_') ? parseInt(attachmentId.split('_')[1], 10) : parseInt(attachmentId, 10)
      const r = await apiSetAttachmentAsMain({ id: fileId })
      Utils.showSuccess('Changed main attachment file.', 'Success')
      onTCFItemUpdated(r)
    },
    [apiSetAttachmentAsMain, onTCFItemUpdated]
  )

  const handleSaveAttachment = React.useCallback(
    async (attachment: ThumbnailInfo, updated: AttachmentEditData) => {
      const fileId = parseInt(attachment.id.split('_')[1], 10)
      const r = await apiUpdateAttachment({
        id: fileId,
        attachmentTypeId: updated.attachmentTypeId,
        description: updated.description,
        fileName: updated.fileName,
      })
      Utils.showSuccess('Saved attachment file info.', 'Success')
      onTCFItemUpdated(r)
    },
    [apiUpdateAttachment, onTCFItemUpdated]
  )

  const handleFavorItem = React.useCallback(
    async (itemId: number) => {
      const r = await apiFavorItem({ itemId })
      // Utils.showSuccess('Added to favorite.', 'Success')
      onTCFItemUpdated(r.item)
    },
    [apiFavorItem, onTCFItemUpdated]
  )

  const handleUnfavorItem = React.useCallback(
    async (itemId: number) => {
      await apiUnfavorItem({ id: itemId })
      const r = await apiGetTCFItem(itemId)
      // Utils.showSuccess('Remove from favorite.', 'Success')
      onTCFItemUpdated(r)
    },
    [apiGetTCFItem, apiUnfavorItem, onTCFItemUpdated]
  )

  const handleAddAttachment = React.useCallback(
    async (file: File, data: AttachmentEditData) => {
      const r = await apiCreateAttachment({
        itemId,
        file: file,
        attachmentTypeId: data.attachmentTypeId,
        description: data.description,
        fileName: data.fileName,
      })
      Utils.showSuccess('Saved attachment file info.', 'Success')
      onTCFItemUpdated(r)
    },
    [itemId, onTCFItemUpdated, apiCreateAttachment]
  )

  const handleRemoveAttachment = React.useCallback(
    async (data: ThumbnailInfo) => {
      const id = parseInt(data.id.split('_')[1], 10)
      await apiRemoveAttachment({ id })
      Utils.showSuccess('Removed attachment file.', 'Success')
      const r = await apiGetTCFItem(itemId)
      onTCFItemUpdated(r)
    },
    [itemId, apiGetTCFItem, onTCFItemUpdated, apiRemoveAttachment]
  )

  const handleViewInTomoAnalysis = React.useCallback(() => {
    if (!connected || initializing) {
      setShowCannotConnectDownloader(true)
      return
    }

    setShowWaitingDownload(true)
  }, [connected, initializing])

  const handleSetMetadataUpdateRequired = React.useCallback(
    async (marked: boolean) => {
      try {
        const r = await apiSetMetadataUpdateRequired({ id: itemId, marked })
        onTCFItemUpdated(r)
      } catch (ex) {
        Utils.showError(ex)
      }
    },
    [itemId, onTCFItemUpdated, apiSetMetadataUpdateRequired]
  )

  React.useEffect(() => {
    if (showCannotConnectDownloader) {
      if (connected && !initializing) {
        setShowCannotConnectDownloader(false)
        setShowWaitingDownload(true)
        return
      }
    }
  }, [connected, initializing, showCannotConnectDownloader, setShowCannotConnectDownloader])

  // console.log(157, { attachments, tcfItem })

  return (
    <>
      <BlockingLoadBox show={queueDownloading} message="Start downloading.." />
      <DownloadConfirmComponent {...DownloadConfirmComponentProps} />
      <OpeningTomoAnalysisModal
        isOpen={showCannotConnectDownloader}
        onClose={() => setShowCannotConnectDownloader(false)}
      />
      {showWaitingDownload && (
        <WaitingDownloadForAnalysisModal
          itemId={itemId}
          dataId={dataId}
          onClose={() => setShowWaitingDownload(false)}
        />
      )}
      <DetailContainer
        generalInfo={converted}
        Share={Share}
        onClose={onClose}
        downloadDisabled={unavailable}
        downloadDisabledReason={downloadDisabledReason}
        onClickDownload={handleDownload}
        onChangeMetadata={handleChangeMetadata}
        categoryOptions={categoryOptions}
        tags={tags.map(t => t.name).join(',')}
        onSetAttachmentAsMain={handleSetAttachmentAsMain}
        attachmentTypes={types}
        onSaveAttachment={handleSaveAttachment}
        hasPrev={hasPrev}
        hasNext={hasNext}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
        isFavorite={isFavorite}
        onChangeFavorite={added => (added ? handleFavorItem(itemId) : handleUnfavorItem(itemId))}
        onAddAttachment={handleAddAttachment}
        onViewInTomoAnalysis={handleViewInTomoAnalysis}
        onRemoveAttachment={handleRemoveAttachment}
        movieLink={movieLink}
        connectedShareLink={accessByShareToken}
        inWorksetId={inWorksetId}
        isMarkedReloadMetadata={tcfItem.markedMetaUpdateRequired}
        onChangeMarkReloadMeta={handleSetMetadataUpdateRequired}
      />
    </>
  )
}
