"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AUDIT_TYPE = exports.ALERT_TYPE = exports.AGENT_PUBSUB_TOPIC = exports.AGENT_LOG_CATEGORY = exports.LOG_LEVEL = exports.ITEM_META_VERSION = exports.AGENT_VERSION_HEADER = exports.worksetUserPermissionToString = exports.WORKSET_USER_PERM = exports.WORKSET_PERM = exports.TCF_IMAGE_TYPE = exports.TCF_IMAGE_TYPE_CILS = exports.WorksetAutoAction = exports.ITEM_EXECUTION_CONFIRM_FILTER = exports.ITEM_EXECUTION_ASSIGN_FILTER = exports.ITEM_EXECUTION_LOCK_FILTER = exports.ITEM_EXECUTION_STATUS = exports.ITEM_EXECUTION_SORT_OPTION = exports.PROJECT_ITEM_USER_STATUS = exports.PROJECT_STATUS_OPTION = exports.PROJECT_SORT_OPTION = exports.projectUserPermissionToString = exports.PROJECT_USER_PERM = exports.PROJECT_TYPE = exports.WORKSET_GROUP_SORT_OPTION = exports.WORKSET_SORT_OPTION = exports.IMAGE_SORT_OPTION = exports.ImageTagType = exports.PROGRESS_STATUS = exports.PROGRESS_TYPE = exports.SUPPORT_EMAIL = exports.ORG_USER_PRIV_TO_STRING = exports.MACHINE_STATUS = exports.ORG_USER_PRIV = exports.USER_PRIV_TO_STRING = exports.USER_TYPE = exports.USER_PRIV = exports.CILS_DIRNAME = exports.FILE_TYPE = exports.ITEM_TYPE = exports.SITE_COPYRIGHT = void 0;

var lodash_1 = require("lodash");

var moment = require("moment");

var year = moment().year();
exports.SITE_COPYRIGHT = "".concat(year, " Tomocube.");
var ITEM_TYPE;

(function (ITEM_TYPE) {
  ITEM_TYPE["TCFImage"] = "TCFImage";
  ITEM_TYPE["ProjectOutput"] = "ProjectOutput";
})(ITEM_TYPE = exports.ITEM_TYPE || (exports.ITEM_TYPE = {}));

var FILE_TYPE;

(function (FILE_TYPE) {
  FILE_TYPE["Unspecified"] = "Unspecified";
  FILE_TYPE["ItemImage"] = "ItemImage";
  FILE_TYPE["TCFThumbnail"] = "TCFThumbnail";
  FILE_TYPE["TCFTimeLapse"] = "TCFTimeLapse";
})(FILE_TYPE = exports.FILE_TYPE || (exports.FILE_TYPE = {}));

exports.CILS_DIRNAME = '.cils';
exports.USER_PRIV = {
  Rejected: 0,
  Pending: 1,
  Normal: 5,
  SuperAdmin: 10
};
exports.USER_TYPE = {
  Normal: 0,
  Agent: 1
};
exports.USER_PRIV_TO_STRING = {
  [exports.USER_PRIV.Rejected]: 'Rejected',
  [exports.USER_PRIV.Pending]: 'Pending',
  [exports.USER_PRIV.Normal]: 'Researcher',
  [exports.USER_PRIV.SuperAdmin]: 'CILS Admin'
};
var ORG_USER_PRIV;

(function (ORG_USER_PRIV) {
  ORG_USER_PRIV[ORG_USER_PRIV["Rejected"] = 0] = "Rejected";
  ORG_USER_PRIV[ORG_USER_PRIV["Pending"] = 1] = "Pending";
  ORG_USER_PRIV[ORG_USER_PRIV["Agent"] = 2] = "Agent";
  ORG_USER_PRIV[ORG_USER_PRIV["Guest"] = 3] = "Guest";
  ORG_USER_PRIV[ORG_USER_PRIV["Normal"] = 5] = "Normal";
  ORG_USER_PRIV[ORG_USER_PRIV["DataAdmin"] = 8] = "DataAdmin";
  ORG_USER_PRIV[ORG_USER_PRIV["SystemAdmin"] = 10] = "SystemAdmin";
})(ORG_USER_PRIV = exports.ORG_USER_PRIV || (exports.ORG_USER_PRIV = {}));

var MACHINE_STATUS;

(function (MACHINE_STATUS) {
  MACHINE_STATUS["running"] = "running";
  MACHINE_STATUS["stopped"] = "stopped";
})(MACHINE_STATUS = exports.MACHINE_STATUS || (exports.MACHINE_STATUS = {}));

exports.ORG_USER_PRIV_TO_STRING = {
  [ORG_USER_PRIV.Rejected]: 'Blocked',
  [ORG_USER_PRIV.Pending]: 'Pending',
  [ORG_USER_PRIV.Agent]: 'Agent',
  [ORG_USER_PRIV.Normal]: 'Researcher',
  [ORG_USER_PRIV.Guest]: 'Guest Researcher',
  [ORG_USER_PRIV.DataAdmin]: 'Data Admin',
  [ORG_USER_PRIV.SystemAdmin]: 'System Admin'
};
exports.SUPPORT_EMAIL = 'cils@tomocube.com';
var PROGRESS_TYPE;

(function (PROGRESS_TYPE) {
  PROGRESS_TYPE["Upload"] = "Upload";
  PROGRESS_TYPE["Download"] = "Download";
})(PROGRESS_TYPE = exports.PROGRESS_TYPE || (exports.PROGRESS_TYPE = {}));

var PROGRESS_STATUS;

(function (PROGRESS_STATUS) {
  PROGRESS_STATUS["Pending"] = "Pending";
  PROGRESS_STATUS["Paused"] = "Paused";
  PROGRESS_STATUS["InProgress"] = "InProgress";
  PROGRESS_STATUS["Merging"] = "Merging";
  PROGRESS_STATUS["Finished"] = "Finished";
  PROGRESS_STATUS["Deleted"] = "Deleted";
})(PROGRESS_STATUS = exports.PROGRESS_STATUS || (exports.PROGRESS_STATUS = {}));
/**
 * TCF 이미지의 종류
 */


var ImageTagType;

(function (ImageTagType) {
  ImageTagType[ImageTagType["Type_2D"] = 0] = "Type_2D";
  ImageTagType[ImageTagType["Type_3D"] = 1] = "Type_3D";
  ImageTagType[ImageTagType["Type_B"] = 2] = "Type_B";
  ImageTagType[ImageTagType["Type_F"] = 3] = "Type_F";
  ImageTagType[ImageTagType["Type_TimeLapse"] = 4] = "Type_TimeLapse";
  ImageTagType[ImageTagType["Type_C"] = 5] = "Type_C";
})(ImageTagType = exports.ImageTagType || (exports.ImageTagType = {}));
/**
 * TCF 이미지 리스팅 정렬 옵션
 */


var IMAGE_SORT_OPTION;

(function (IMAGE_SORT_OPTION) {
  IMAGE_SORT_OPTION["CreatedDateAscending"] = "CreatedDateAscending";
  IMAGE_SORT_OPTION["CreatedDateDescending"] = "CreatedDateDescending";
  IMAGE_SORT_OPTION["NameAscending"] = "NameAscending";
  IMAGE_SORT_OPTION["NameDescending"] = "NameDescending";
})(IMAGE_SORT_OPTION = exports.IMAGE_SORT_OPTION || (exports.IMAGE_SORT_OPTION = {}));
/**
 * 워크셋 리스팅 정렬 옵션
 */


var WORKSET_SORT_OPTION;

(function (WORKSET_SORT_OPTION) {
  WORKSET_SORT_OPTION["CreatedDateAscending"] = "CreatedDateAscending";
  WORKSET_SORT_OPTION["CreatedDateDescending"] = "CreatedDateDescending";
  WORKSET_SORT_OPTION["NameAscending"] = "NameAscending";
  WORKSET_SORT_OPTION["NameDescending"] = "NameDescending";
  WORKSET_SORT_OPTION["LastAddedAscending"] = "LastAddedAscending";
  WORKSET_SORT_OPTION["LastAddedDescending"] = "LastAddedDescending";
})(WORKSET_SORT_OPTION = exports.WORKSET_SORT_OPTION || (exports.WORKSET_SORT_OPTION = {}));
/**
 * 워크셋 리스팅 정렬 옵션
 */


var WORKSET_GROUP_SORT_OPTION;

(function (WORKSET_GROUP_SORT_OPTION) {
  WORKSET_GROUP_SORT_OPTION["CreatedDateAscending"] = "CreatedDateAscending";
  WORKSET_GROUP_SORT_OPTION["CreatedDateDescending"] = "CreatedDateDescending";
  WORKSET_GROUP_SORT_OPTION["NameAscending"] = "NameAscending";
  WORKSET_GROUP_SORT_OPTION["NameDescending"] = "NameDescending";
})(WORKSET_GROUP_SORT_OPTION = exports.WORKSET_GROUP_SORT_OPTION || (exports.WORKSET_GROUP_SORT_OPTION = {}));
/**
 * 프로젝트 타입의 종류
 */


var PROJECT_TYPE;

(function (PROJECT_TYPE) {
  PROJECT_TYPE["GENERIC"] = "GENERIC";
})(PROJECT_TYPE = exports.PROJECT_TYPE || (exports.PROJECT_TYPE = {}));

exports.PROJECT_USER_PERM = {
  Viewer: 1,
  Editor: 5,
  Admin: 7,
  Owner: 10
};
var projectUserPermInverted = lodash_1.invert(exports.PROJECT_USER_PERM);

exports.projectUserPermissionToString = function (perm) {
  return projectUserPermInverted[perm] || "Unhandled perm : ".concat(perm);
};

var PROJECT_SORT_OPTION;

(function (PROJECT_SORT_OPTION) {
  PROJECT_SORT_OPTION["CreatedDateAscending"] = "CreatedDateAscending";
  PROJECT_SORT_OPTION["CreatedDateDescending"] = "CreatedDateDescending";
  PROJECT_SORT_OPTION["NameAscending"] = "NameAscending";
  PROJECT_SORT_OPTION["NameDescending"] = "NameDescending";
})(PROJECT_SORT_OPTION = exports.PROJECT_SORT_OPTION || (exports.PROJECT_SORT_OPTION = {}));

var PROJECT_STATUS_OPTION;

(function (PROJECT_STATUS_OPTION) {
  PROJECT_STATUS_OPTION["All"] = "All";
  PROJECT_STATUS_OPTION["Active"] = "Active";
  PROJECT_STATUS_OPTION["Deleted"] = "Deleted";
})(PROJECT_STATUS_OPTION = exports.PROJECT_STATUS_OPTION || (exports.PROJECT_STATUS_OPTION = {}));
/**
 * 프로젝트 데이터의 작업 담당자, 리뷰어 상태
 */


var PROJECT_ITEM_USER_STATUS;

(function (PROJECT_ITEM_USER_STATUS) {
  PROJECT_ITEM_USER_STATUS["NotStarted"] = "NotStarted";
  PROJECT_ITEM_USER_STATUS["Queued"] = "Queued";
  PROJECT_ITEM_USER_STATUS["Downloading"] = "Downloading";
  PROJECT_ITEM_USER_STATUS["Downloaded"] = "Downloaded";
  PROJECT_ITEM_USER_STATUS["Executing"] = "Executing";
  PROJECT_ITEM_USER_STATUS["Executed"] = "Executed";
  PROJECT_ITEM_USER_STATUS["UploadFailed"] = "UploadFailed";
  PROJECT_ITEM_USER_STATUS["Uploading"] = "Uploading";
  PROJECT_ITEM_USER_STATUS["FinishedSuccess"] = "FinishedSuccess";
  PROJECT_ITEM_USER_STATUS["FinishedFailed"] = "FinishedFailed";
  PROJECT_ITEM_USER_STATUS["FinishedError"] = "FinishedError";
})(PROJECT_ITEM_USER_STATUS = exports.PROJECT_ITEM_USER_STATUS || (exports.PROJECT_ITEM_USER_STATUS = {}));

var ITEM_EXECUTION_SORT_OPTION;

(function (ITEM_EXECUTION_SORT_OPTION) {
  ITEM_EXECUTION_SORT_OPTION["NameAscending"] = "NameAscending";
  ITEM_EXECUTION_SORT_OPTION["NameDescending"] = "NameDescending";
})(ITEM_EXECUTION_SORT_OPTION = exports.ITEM_EXECUTION_SORT_OPTION || (exports.ITEM_EXECUTION_SORT_OPTION = {}));

var ITEM_EXECUTION_STATUS;

(function (ITEM_EXECUTION_STATUS) {
  ITEM_EXECUTION_STATUS["Executing"] = "Executing";
  ITEM_EXECUTION_STATUS["Uploading"] = "Uploading";
  ITEM_EXECUTION_STATUS["Finished"] = "Finished";
})(ITEM_EXECUTION_STATUS = exports.ITEM_EXECUTION_STATUS || (exports.ITEM_EXECUTION_STATUS = {}));

var ITEM_EXECUTION_LOCK_FILTER;

(function (ITEM_EXECUTION_LOCK_FILTER) {
  ITEM_EXECUTION_LOCK_FILTER["Unlocked"] = "Unlocked";
  ITEM_EXECUTION_LOCK_FILTER["LockedByUser"] = "LockedByUser";
})(ITEM_EXECUTION_LOCK_FILTER = exports.ITEM_EXECUTION_LOCK_FILTER || (exports.ITEM_EXECUTION_LOCK_FILTER = {}));

var ITEM_EXECUTION_ASSIGN_FILTER;

(function (ITEM_EXECUTION_ASSIGN_FILTER) {
  ITEM_EXECUTION_ASSIGN_FILTER["NoAssigned"] = "NoAssigned";
  ITEM_EXECUTION_ASSIGN_FILTER["AssignedByUser"] = "AssignedByUser";
})(ITEM_EXECUTION_ASSIGN_FILTER = exports.ITEM_EXECUTION_ASSIGN_FILTER || (exports.ITEM_EXECUTION_ASSIGN_FILTER = {}));

var ITEM_EXECUTION_CONFIRM_FILTER;

(function (ITEM_EXECUTION_CONFIRM_FILTER) {
  ITEM_EXECUTION_CONFIRM_FILTER["Confirmed"] = "Confirmed";
  ITEM_EXECUTION_CONFIRM_FILTER["Unconfirmed"] = "Unconfirmed";
})(ITEM_EXECUTION_CONFIRM_FILTER = exports.ITEM_EXECUTION_CONFIRM_FILTER || (exports.ITEM_EXECUTION_CONFIRM_FILTER = {}));
/**
 * 프로젝트에 유저 또는 워크셋을 추가할 경우 상황에 따라 자동으로 유저 권한을 변경 케이스
 */


var WorksetAutoAction;

(function (WorksetAutoAction) {
  WorksetAutoAction["None"] = "None";
  WorksetAutoAction["UpgradeNoneToViewer"] = "UpgradeNoneToViewer";
  WorksetAutoAction["UpgradeNoneToEditor"] = "UpgradeNoneToEditor";
  WorksetAutoAction["UpgradeNoneToAdmin"] = "UpgradeNoneToAdmin";
  WorksetAutoAction["UpgradeViewerToEditor"] = "UpgradeViewerToEditor";
  WorksetAutoAction["UpgradeViewerToAdmin"] = "UpgradeViewerToAdmin";
  WorksetAutoAction["UpgradeEditorToAdmin"] = "UpgradeEditorToAdmin";
})(WorksetAutoAction = exports.WorksetAutoAction || (exports.WorksetAutoAction = {}));
/**
 * CILS 기준으로 검색용 TCF Image Types
 */


var TCF_IMAGE_TYPE_CILS;

(function (TCF_IMAGE_TYPE_CILS) {
  TCF_IMAGE_TYPE_CILS["I_3DHT"] = "I_3DHT";
  TCF_IMAGE_TYPE_CILS["I_FL"] = "I_FL";
  TCF_IMAGE_TYPE_CILS["I_2D"] = "I_2D";
  TCF_IMAGE_TYPE_CILS["I_BF"] = "I_BF";
  TCF_IMAGE_TYPE_CILS["I_PC"] = "I_PC";
})(TCF_IMAGE_TYPE_CILS = exports.TCF_IMAGE_TYPE_CILS || (exports.TCF_IMAGE_TYPE_CILS = {}));

var TCF_IMAGE_TYPE;

(function (TCF_IMAGE_TYPE) {
  TCF_IMAGE_TYPE["I_3DHT"] = "I_3DHT";
  TCF_IMAGE_TYPE["I_2DHT"] = "I_2DHT";
  TCF_IMAGE_TYPE["I_3DFL"] = "I_3DFL";
  TCF_IMAGE_TYPE["I_2DFL"] = "I_2DFL";
  TCF_IMAGE_TYPE["I_PC"] = "I_PC"; // I_2DMIP = '2DMIP',
  // I_2DHR = '2DHR',
  // I_2D = '2D',
  // I_3DFL = '3DFL',
  // I_2DFL = '2DFL',
})(TCF_IMAGE_TYPE = exports.TCF_IMAGE_TYPE || (exports.TCF_IMAGE_TYPE = {}));

var WORKSET_PERM;

(function (WORKSET_PERM) {
  WORKSET_PERM["Public"] = "Public";
  WORKSET_PERM["Organization"] = "Organization";
  WORKSET_PERM["Private"] = "Private";
})(WORKSET_PERM = exports.WORKSET_PERM || (exports.WORKSET_PERM = {}));

exports.WORKSET_USER_PERM = {
  Viewer: 1,
  Collaborator: 5,
  Editor: 7,
  Owner: 10
};
var inverted = lodash_1.invert(exports.WORKSET_USER_PERM);

exports.worksetUserPermissionToString = function (perm) {
  return inverted[perm] || "Unhandled perm : ".concat(perm);
};

exports.AGENT_VERSION_HEADER = 'agent-version'; // Current Item.metaVersion column.
// 아이템 버전은 지우지 말고 로그를 남겨두도록 합니다.
//
//  - No Version : 최초 버전
//  - 20200405   : TimeLapse 썸네일 및 3D,2D,TL 등 파싱이 추가된 버전 (서버 기준)
//

exports.ITEM_META_VERSION = 20200405;
exports.LOG_LEVEL = {
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4
};
/**
 * 에이전트 로그를 종류별로 나누어 볼 수 있도록 카테고리 분류
 */

var AGENT_LOG_CATEGORY;

(function (AGENT_LOG_CATEGORY) {
  AGENT_LOG_CATEGORY["DEFAULT"] = "DEFAULT";
  AGENT_LOG_CATEGORY["DOWNLOAD"] = "DOWNLOAD";
  AGENT_LOG_CATEGORY["UPLOAD"] = "UPLOAD";
  AGENT_LOG_CATEGORY["SCAN"] = "SCAN";
})(AGENT_LOG_CATEGORY = exports.AGENT_LOG_CATEGORY || (exports.AGENT_LOG_CATEGORY = {}));
/**
 * 에이전트 서버의 PUBSUB Topic
 */


exports.AGENT_PUBSUB_TOPIC = {
  LOG: 'LOG'
};
var ALERT_TYPE;

(function (ALERT_TYPE) {
  ALERT_TYPE["AGENT_UPLOAD_FAILURE"] = "AGENT_UPLOAD_FAILURE";
  ALERT_TYPE["AGENT_THUMBNAIL_FAILURE"] = "AGENT_THUMBNAIL_FAILURE";
})(ALERT_TYPE = exports.ALERT_TYPE || (exports.ALERT_TYPE = {}));

var AUDIT_TYPE;

(function (AUDIT_TYPE) {
  AUDIT_TYPE["DATA_DOWNLOAD"] = "DATA_DOWNLOAD";
  AUDIT_TYPE["DATA_UPLOAD"] = "DATA_UPLOAD";
  AUDIT_TYPE["DATA_DELETION"] = "DATA_DELETION";
})(AUDIT_TYPE = exports.AUDIT_TYPE || (exports.AUDIT_TYPE = {}));