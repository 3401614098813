import { CategoryPropsInputValidation, populateCategoryPropsInput } from '@cils/common'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { GQLCategory, GQLCategoryPropsInput, GQLOkResponse, GQLSingleIDInput } from '../../../@types/server'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { ColorBall } from '../../../ODEntityEditor/FormComponents/ODEntityColorPicker'
import { ODEntityColorPickerInput } from '../../../ODEntityEditor/FormComponents/ODEntityColorPickerInput'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type OrgCategoryEditContainerProps = {
  orgId: number
  idEditing?: number
}

const GQL_CREATE = `
mutation createCategory($data: CategoryPropsInput!) {
  createCategory(data: $data) {
    categoryId
  }
}
`

const GQL_UPDATE = `
mutation updateCategory($data: CategoryPropsInput!) {
  updateCategory(data: $data) {
    categoryId
    name
    color
  }
}
`

const GQL_GET = `
query getCategory($data: SingleIDInput!) {
  getCategory(data: $data) {
    categoryId
    name
    color
  }
}
`

const GQL_REMOVE = `
mutation removeCategory($data: SingleIDInput!) {
  removeCategory(data: $data) {
    ok
  }
}
`

type PropsInput = GQLCategoryPropsInput
type Entity = GQLCategory

const colors = ['#f86c6bff', '#ffa06fff', '#ffc107ff', '#4dbd74ff', '#63c2deff', '#6f42c1ff', '#acb4bcff', '#2f353aff']

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: CategoryPropsInputValidation.name.required('Name is required.'),
    color: CategoryPropsInputValidation.color.required('Color is required.'),
  })
}

const PlaceholderLike = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`

export const OrgCategoryEditContainer: React.FC<OrgCategoryEditContainerProps> = props => {
  const { idEditing, orgId } = props
  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_CREATE)
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_UPDATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          return {
            id: null,
            name: '',
            color: '',
          }
        }
        return {
          id: data.categoryId,
          name: data.name,
          color: data.color,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (idEditing) {
          await apiUpdate({ id: idEditing, ...data })
          Utils.showSuccess('Updated a category', 'Success')
        } else {
          await apiCreate({ name: data.name, color: data.color, orgId })
          Utils.showSuccess('Added a new category', 'Success')
        }
        return SiteUrls.OrgAdmin.Category.List(orgId)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateCategoryPropsInput,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('Removed a category.', 'Success')
        }
        return SiteUrls.OrgAdmin.Category.List(orgId)
      },
    }),
    [idEditing, apiCreate, apiGet, apiUpdate, apiRemove, orgId]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New Category' : 'Edit Category'

  return (
    <Provider title={title}>
      <ODEntityInput name="name" label="Name of the category" placeholder="Enter name" inputType="text" />
      <ODEntityColorPickerInput
        elementId="color-picker-category-edit"
        colors={colors}
        renderChildren={color => {
          if (!color) {
            return <PlaceholderLike style={{ marginTop: 10 }}>Select color</PlaceholderLike>
          }
          return (
            <div style={{ display: 'flex', marginTop: 6 }}>
              <ColorBall selected={false} code={`#${color}`} />
              <span style={{ marginLeft: 10, marginTop: 1 }}>#{color}</span>
            </div>
          )
        }}
      />
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
      />
    </Provider>
  )
}
