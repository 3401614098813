import React from 'react'
import { GQLItem, GQLListableItem, GQLListableItemInput } from '../../../@types/server'
import { GQL_ITEM_IN_LIST_SNAPSHOT } from '../../../context/ODAppContext'
import { useODQuery } from '../../../context/ODCommon'
import { ODListableResponseType } from '../../../ODListable/ODListableContext'
import { ItemDataLoaderOption, ItemsContainer } from './ItemsContainer'

type TaggedItemsContainerProps = {
  categoryId: number
}

const GQL_LIST_ITEMS = `
query listItem($data: ListableItemInput!) {
  listItem(data: $data) {
    list {
      ${GQL_ITEM_IN_LIST_SNAPSHOT}
    }
    page
    pageSize
    totalCount
  }
}
`

export const CategoryItemsContainer: React.FC<TaggedItemsContainerProps> = props => {
  const { categoryId } = props
  const apiList = useODQuery<Partial<GQLListableItemInput>, GQLListableItem>(GQL_LIST_ITEMS)

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemDataLoaderOption
    ): Promise<ODListableResponseType<GQLItem>> {
      const r = await apiList({
        page,
        pageSize,
        filter: options.filter,
        orgId: options.orgId,
        categoryIds: [categoryId],
        tagIds: options.tagIds,
        hasAttachment: options.hasAttachment,
        timeLapseOnly: options.timeLapseOnly,
        tcfImageTypes: options.tcfImageTypes,
        // @ts-ignore
        sortBy: options.sortBy,
        modifiedTimeRange: options.modifiedTimeRange,
        hidden: options.hidden,
      })
      return r
    },
    [categoryId, apiList]
  )

  return <ItemsContainer showToolbar={true} dataLoader={dataLoader} />
}
