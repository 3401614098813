import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { createChartOptions, ODChartData, ODChartDesignType } from './ODDashboardChartOptions'
import {
  ODDashboardCardBackgroundColorForTheme,
  ODDashboardCardBorderColorForTheme,
  ODDashboardCardTheme,
} from './ODDashboardCommon'

type ODDashboardCardWithGraphProps = {
  chartData: ODChartData
  chartType: ODChartDesignType
  theme: ODDashboardCardTheme
  title: string
  icon: React.ReactNode
  value: string
}

const Value = styled.div`
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-bottom: 6px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
`

export const ODDashboardCardWithGraph: React.FC<ODDashboardCardWithGraphProps> = props => {
  const { theme, title, value, icon, chartData, chartType } = props
  const borderColor = ODDashboardCardBorderColorForTheme[theme]
  const backgroundColor = ODDashboardCardBackgroundColorForTheme[theme]

  const [chartOptions, setChartOptions] = React.useState<any>()

  React.useEffect(() => {
    setChartOptions(createChartOptions(chartData, chartType))
  }, [chartData, setChartOptions, chartType])

  const height = 60
  const shouldGraphFillTheCard = chartType === ODChartDesignType.Bezier || chartType === ODChartDesignType.Bar
  const Chart = () => {
    if (chartType === ODChartDesignType.Bar) {
      return (
        <div className="chart-wrapper mt-3 mx-3" style={{ height: `${height}px` }}>
          <Bar data={chartData} options={chartOptions} height={height} />
        </div>
      )
    }
    return (
      <div className="chart-wrapper mt-3" style={{ height: `${height}px` }}>
        <Line data={chartData} options={chartOptions} height={height} />
      </div>
    )
  }

  return (
    <Card
      className="text-white"
      style={{
        backgroundColor,
        border: `1px solid ${borderColor}`,
      }}
    >
      <CardBody
        style={{
          borderRadius: 3,
          paddingLeft: 23,
          paddingTop: 23,
          paddingBottom: 18,
          paddingRight: 23,
          // border: `1px solid ${borderColor}`,
          backgroundColor,
        }}
      >
        <Value>{value}</Value>
        <Title>{title}</Title>
        {!shouldGraphFillTheCard && <Chart />}
        <div style={{ position: 'absolute', right: 22, top: 27 }}>{icon}</div>
      </CardBody>
      {shouldGraphFillTheCard && <Chart />}
    </Card>
  )
}
