import React from 'react'
import { GQLOrgHasUser, GQLUpdateOrgHasUserInput } from '../../../../@types/server'
import { FlexContentsContainer } from '../../../../components/FlexContentsContainer'
import { ODModal, ODModalSize } from '../../../../components/ODModal/ODModal'
import { ODModalBody } from '../../../../components/ODModal/ODModalBody'
import { ODModalHeader } from '../../../../components/ODModal/ODModalHeader'
import { ChangeUserOrgPermissionPage } from './ChangeUserOrgPermissionPage'

interface IChangeUserOrgPermissionOnNewUserModalProps {
  show: boolean
  value: GQLOrgHasUser
  onChange: (input: GQLUpdateOrgHasUserInput) => Promise<boolean>
  onClose: () => void
}

export const ChangeUserOrgPermissionOnNewUserModal: React.FC<IChangeUserOrgPermissionOnNewUserModalProps> = props => {
  const { show, onClose, value, onChange } = props

  return (
    <ODModal isOpen={show} toggle={() => onClose()} size={ODModalSize.Full}>
      <FlexContentsContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title="Change User Permission" onClose={() => onClose()} headerBottomPadding={30} />
          <ODModalBody style={{ flexGrow: 99, paddingLeft: 45, paddingRight: 45 }}>
            <ChangeUserOrgPermissionPage orgHasUser={value} onUpdate={onChange} onClose={onClose} noSuccessToast />
          </ODModalBody>
        </div>
      </FlexContentsContainer>
    </ODModal>
  )
}
