import { ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import styled from 'styled-components'
import { ODColors } from '../../../global-styles'
import { ODButton, ODButtonTheme } from '../../ODButton'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalFooter } from '../../ODModal/ODModalFooter'
import { ODModalHeader } from '../../ODModal/ODModalHeader'

interface IODModalConfirmData {
  isOpen: boolean
  onClose: () => void
  onConfirm: (isUnConfirm: boolean) => void
}

export const ODModalConfirmData: React.FC<IODModalConfirmData> = props => {
  const { isOpen, onClose, onConfirm } = props
  const [isConfirmed, setIsConfirmed] = React.useState(false)

  const handleConfirm = async () => {
    await onConfirm(!isConfirmed)
  }

  const modalTitle = 'Confirm Data'
  return (
    <ODModal
      isOpen={isOpen}
      toggle={onClose}
      size={ODModalSize.Normal}
      style={{ borderRadius: 4, maxWidth: 422, boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={modalTitle} onClose={onClose} />
        <ODModalBody>
          <RadioBoxTitle>Confirm Data</RadioBoxTitle>
          <ODVSpace h={18} />
          <FormGroup check>
            <Label check>
              <Input type={'radio'} checked={isConfirmed === false} onChange={() => setIsConfirmed(false)} />
              Unconfirmed
            </Label>
          </FormGroup>
          <ODVSpace h={10} />
          <FormGroup check>
            <Label check>
              <Input type={'radio'} checked={isConfirmed === true} onChange={() => setIsConfirmed(true)} />
              Confirmed
            </Label>
          </FormGroup>
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleConfirm}>
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

const RadioBoxTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: ${ODColors.Steel};
`
