import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../ODIcon'

type FilterButtonType = {
  label?: string
  onRemove: () => void
  color: string
  isClearAll: boolean
  isRemove: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  border-radius: 4px;
  background-color: #20a8d8;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`

const LabelText = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`

export const FilterButton: React.FC<FilterButtonType> = props => {
  const { label, onRemove, color, isClearAll, isRemove } = props

  return (
    <Wrapper
      style={{ backgroundColor: color, cursor: isRemove ? 'pointer' : 'default' }}
      onClick={() => isRemove && onRemove()}
    >
      {isRemove && (
        <ODIcon
          icon={isClearAll ? ODIcons.MaterialRefresh : ODIcons.CoreX}
          style={{ fontSize: 10, color: '#ffffff', marginRight: 8 }}
        />
      )}
      <LabelText>{label}</LabelText>
    </Wrapper>
  )
}
