import { ORG_ROLE, PROJECT_USER_PERM } from '@cils/common'
import { useOrgPerm } from '../context/useOrgPerm'

export const useProjectPerm = (orgId: number) => {
  const perm = useOrgPerm(orgId)

  const isSameOrGreaterThanAdmin = (projectPerm: number) => {
    const isSameOrGreater = projectPerm >= PROJECT_USER_PERM.Admin || perm?.isAllowed(ORG_ROLE.EDIT_ORG_PROJECT)
    return !!isSameOrGreater
  }

  const isSameOrGreaterThanEditor = (projectPerm: number) => {
    const isSameOrGreater = projectPerm >= PROJECT_USER_PERM.Editor || perm?.isAllowed(ORG_ROLE.EDIT_ORG_PROJECT)
    return !!isSameOrGreater
  }

  return {
    isSameOrGreaterThanAdmin,
    isSameOrGreaterThanEditor,
  }
}
