import {
  ALERT_TYPE,
  AUDIT_TYPE,
  PROJECT_ITEM_USER_STATUS,
  PROJECT_TYPE,
  TCF_IMAGING_KEY,
  TCF_META_DETAIL,
} from '@cils/common'
import { isFunction, isObject, isString, startsWith } from 'lodash'
import moment from 'moment'
import { ToastOptions } from 'react-toastify'
import { GQLALERT_TYPE, GQLAUDIT_TYPE, GQLPROJECT_TYPE } from './@types/server'
import { AppOptions } from './AppOptions'
import { ODToastType, showODToast } from './components/ODToast'
import { ErrorStringMap, ServerErrors } from './i18n/res'

const isErrorCannotConnectToServer = (e: any) => e.message === 'Failed to fetch'
const graphQLErrorPrefix = 'GraphQL error: '
let lastServerConnectionError: number | null = null

export const Utils = {
  extractGQLResponse(r: any) {
    if (!isObject(r.data)) {
      return undefined
    }
    return r.data[Object.keys(r.data)[0]]
  },
  parseErrorCode(e: Error) {
    if (startsWith(e.message, graphQLErrorPrefix)) {
      const msg = e.message.substring(graphQLErrorPrefix.length)
      if (msg.startsWith('CE-')) {
        return parseInt(msg.split(' ')[0].substring(3), 10)
      }
      return null
    }
    return null
  },
  parseErrorMessage(error: Error) {
    return (e => {
      try {
        if (isErrorCannotConnectToServer(e)) {
          if (lastServerConnectionError && lastServerConnectionError + 2000 > new Date().getTime()) {
            return null // don't show duplicated error. (using throttle with rx-js would be better)
          }
          lastServerConnectionError = new Date().getTime()
          return 'Cannot connect to server.'
        }

        if (startsWith(e.message, graphQLErrorPrefix)) {
          return e.message.substring(graphQLErrorPrefix.length)
        }

        return e.message || e
      } catch (ex) {
        return e.message || e
      }
    })(error)
  },
  /**
   * 오류의 종류에 따라서 적절하게 오류를 보여준다. 해당 컨테이너/컴포넌트에서 자체 오류 처리가 없는
   * 경우 전역적으로 활용한다.
   */
  showError(
    error: Error | string,
    title: string = '',
    options: Partial<ToastOptions> = {},
    customErrorMap?: ErrorStringMap
  ) {
    console.error(error)

    if (isString(error)) {
      showODToast(ODToastType.Error, title, error)
      return
    }

    let msg = (Utils.parseErrorMessage(error) || 'Unknown error') as string
    if (startsWith(msg, 'CE-')) {
      const errorCode = parseInt(msg.split(' ')[0].split('CE-')[1], 10)
      const m = ServerErrors[errorCode] || customErrorMap?.[errorCode]
      if (isFunction(m)) {
        msg = m(msg)
      } else {
        msg = m ? (m as string) : msg
      }
    }

    if (msg) {
      showODToast(ODToastType.Error, 'Error', msg, { ...AppOptions.defaultErrorToastOptions, ...options })
    }
  },
  showInfo(message: string, title: string = '', options: Partial<ToastOptions> = {}) {
    showODToast(ODToastType.Info, title, message, { ...AppOptions.defaultInfoToastOptions, ...options })
  },
  showSuccess(message: string, title: string = '', options: Partial<ToastOptions> = {}) {
    showODToast(ODToastType.Success, title, message, { ...AppOptions.defaultSuccessToastOptions, ...options })
  },
  formatDate(data: any): string {
    return moment(data).format('lll')
  },
  noop(...args: Array<any>) {
    // nothing.
  },
  colorsForDecay(days: number): string {
    if (days >= -30) {
      return 'green'
    }

    if (days < -60) {
      return 'red'
    }
    return 'gray'
  },
  /**
   * 주어진 JSON 데이터를 파싱하나, 실패하는 경우에도 디폴트값을 반환한다.
   */
  parseJSONSafe<T>(s: string, defValue: T): T {
    try {
      return JSON.parse(s)
    } catch (ex) {
      console.warn('Parsing json failed', s)
      return defValue
    }
  },
  /**
   * TCF Metadata 정보를 파싱하여 dimension, resolution 을 계산하여 반환한다.
   */
  getDetailMetadata(meta: any): Array<TCF_META_DETAIL> {
    return ['3D', '3DFL', '2D', /*'2DMIP', */ '2DHR', '2DFL']
      .map(attr => {
        const v = meta.Data?.[attr]
        if (!v) {
          return null
        }

        const getSafeResolution = (axis: string) => {
          try {
            return v[`Resolution${axis.toUpperCase()}`].toFixed(4)
          } catch (ex) {
            return null
          }
        }

        const getSafeDimension = (axis: string) => {
          try {
            const res = v[`Resolution${axis.toUpperCase()}`]
            const size = v[`Size${axis.toUpperCase()}`]
            if (!res || !size) {
              return null
            }
            return (res * size).toFixed(4)
          } catch (ex) {
            return null
          }
        }

        const resolution = ['X', 'Y', 'Z']
          .map(getSafeResolution)
          .filter(v => v)
          .map(v => `${v} um`)
          .join(' x ')

        const dimension = ['X', 'Y', 'Z']
          .map(getSafeDimension)
          .filter(v => v)
          .map(v => `${v} um`)
          .join(' x ')

        const r: TCF_META_DETAIL = {
          source: attr as TCF_IMAGING_KEY,
          dimension,
          resolution,
        }
        return r
      })
      .filter(v => !!v) as Array<TCF_META_DETAIL>
  },
  formatAlertType(alertType: ALERT_TYPE | GQLALERT_TYPE) {
    // @ts-ignore
    return {
      [ALERT_TYPE.AGENT_UPLOAD_FAILURE]: 'Upload Failure',
      [ALERT_TYPE.AGENT_THUMBNAIL_FAILURE]: 'Thumbnail Creation Failure',
    }[alertType]
  },
  formatAuditType(auditType: AUDIT_TYPE | GQLAUDIT_TYPE) {
    // @ts-ignore
    return {
      [AUDIT_TYPE.DATA_DOWNLOAD]: 'Data Download',
      [AUDIT_TYPE.DATA_UPLOAD]: 'Data Upload',
      [AUDIT_TYPE.DATA_DELETION]: 'Data Deletion',
    }[auditType]
  },
  /**
   * TCF 상세화면에서 Type 선택에 따라 어떤 modality 의 이미지 썸네일을 보여줄 것인지 처리
   */
  mapImageTypeToThumbnailModality(imageType: string, modalities: string[]) {
    // 2021-04-29 기준 라이브 서버에 존재하는 모든 이미지 타입
    // 2D,2DMIP,2DHR,3D,2DFLMIP,3DFL,BF
    const MODALITIES_FOR_IMAGE_TYPE = {
      '2D': ['PC', 'HT'],
      '2DMIP': ['HT'],
      '2DHR': ['HT'],
      '2DFL': ['FL'],
      '2DFLMIP': ['FL'],
      '3D': ['HT'],
      '3DFL': ['FL'],
      BF: ['BF'],
    }
    // @ts-ignore
    const m: string[] = MODALITIES_FOR_IMAGE_TYPE[imageType] || []
    return m.find(v => modalities.find(m => m === v))
  },
  /**
   * YYYY-MM-DD HH:mm 형식으로 변환한다
   */
  formatDate2(time: any) {
    return moment(time).format('YYYY-MM-DD HH:mm')
  },
  formatProjectType(projectType: PROJECT_TYPE | GQLPROJECT_TYPE) {
    return {
      [PROJECT_TYPE.GENERIC]: 'Generic Project',
    }[projectType]
  },
  formatProjectTypeForSubmit(projectType: PROJECT_TYPE): GQLPROJECT_TYPE {
    // GQL Enum 으로 타입 정의하는 것까지는 되지만, return 값에 포함시키는 경우 오류가 생김
    // 해당 오류가 해결되기 전까지는 @ts-ignore 을 이용하여 타입을 속이는 방법을 써야 할 듯?
    // @ts-ignore
    return {
      [PROJECT_TYPE.GENERIC]: PROJECT_TYPE.GENERIC,
    }[projectType]
  },
  convertProjectItemUserStatus(status: PROJECT_ITEM_USER_STATUS) {
    if (status === PROJECT_ITEM_USER_STATUS.FinishedSuccess) {
      return {
        label: 'Finished(Success)',
        value: status,
      }
    }
    if (status === PROJECT_ITEM_USER_STATUS.FinishedFailed) {
      return {
        label: 'Finished(Failed)',
        value: status,
      }
    }
    if (status === PROJECT_ITEM_USER_STATUS.FinishedError) {
      return {
        label: 'Finished(Error)',
        value: status,
      }
    }

    return {
      label: status,
      value: status,
    }
  },
}
