import { observer } from 'mobx-react-lite'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ResizeObserver from 'rc-resize-observer'
import { useCILSStore } from '../../di/configureRootStore'
import { SiteUrls } from '../../urls'
import { ODIcon, ODIcons } from '../ODIcon'
import { AddDirectoriesToWorksetButton } from './AddDirectoriesToWorksetButton'
import { DirectoryItem } from './DirectoryItem'

interface IDirectoryTreeViewProps {
  dimensions?: IDimensions
}

export interface IDimensions {
  width: string | number
  height: string | number
}

export const DirectoryTreeView: React.FC<IDirectoryTreeViewProps> = observer(props => {
  const { dimensions = { width: 0, height: 0 } } = props
  const directoryViewRef = React.useRef<HTMLDivElement | null>(null)
  const store = useCILSStore().directoryViewStore
  const orgId = store.orgIdLoaded
  const nodeList = store.nodeList

  return (
    <Wrapper ref={directoryViewRef} style={{ width: dimensions.width ?? 280 }}>
      <ResizeObserver
        onResize={() => {
          if (directoryViewRef.current) {
            store.setDirectoryViewWidth(directoryViewRef.current.offsetWidth)
          }
        }}
      >
        {/* resize 감지용 태그 */}
        <div style={{ width: '100%' }} />
      </ResizeObserver>
      <Header>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ODIcon icon={ODIcons.CoreFolderOpen} style={{ fontSize: 16, color: '#ffffff', marginRight: 13 }} />
          <HeaderText>Directory View</HeaderText>
        </div>

        <Link to={SiteUrls.User.Org.Item.All(orgId)}>
          <ODIcon icon={ODIcons.CoreX} style={{ color: '#ffffff', fontSize: 13, cursor: 'pointer' }} />
        </Link>
      </Header>
      <AddDirectoriesToWorksetButton />
      {nodeList.length === 0 && <div>No Data(0)</div>}
      {nodeList.map(v => (
        <DirectoryItem key={v.path} node={v} />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-width: 280px;
  height: calc(100vh - 45px); // minus header-size
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: #f0f3f5;
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
  background-color: #29b6ca;
  padding-left: 9px;
  padding-right: 9px;
`
const HeaderText = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: #ffffff;
`
