import {
  ODButton,
  ODButtonSize,
  ODButtonTheme,
  ODListablePaginatedTable,
  ODListablePagination,
  ODListableTableDefinition,
  ODWebUtils,
  useODListableContext,
} from '@odc/od-react-belt'
import { IODListableUISchema } from '@odc/odbelt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLAppConfig } from '../../../@types/server'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'
import { AppConfigPrimaryKey, IAppConfigListableOption } from './AppConfigCommon'
import { useAppConfigListDataLoader } from './AppConfigListDataLoader'

interface IAppConfigListableTableProps {}

interface IAppConfigListableOptionalContext {}

const ListableUISchema: IODListableUISchema = JSON.parse(
  `{"emptyText":"No data","loadingText":"Loading history..","columns":[{"key":"configId","title":"Config ID","selector":{"type":"PropSelector","transformerSchemas":[],"key":"configId"},"renderer":{"component":"StringRenderer"}},{"key":"agentManualLink","title":"Agent manual link","selector":{"type":"PropSelector","transformerSchemas":[],"key":"agentManualLink"},"renderer":{"component":"StringRenderer"}},{"key":"createdAt","title":"Updated at","selector":{"type":"PropSelector","transformerSchemas":[],"key":"createdAt"},"renderer":{"component":"DateTimeRenderer","format":"lll"}}]}`
)

const TableDefinition: ODListableTableDefinition<
  GQLAppConfig,
  IAppConfigListableOption,
  IAppConfigListableOptionalContext
> = [
  {
    id: 'configId',
    title: 'Config ID',
    transform: v => v.configId,
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'agentManualLink',
    title: 'Agent Manual Link',
    transform: v => v.agentManualLink ?? '-',
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'agentVersion',
    title: 'Agent Version',
    transform: v => v.agentVersion ?? '-',
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'agentDownloadLink',
    title: 'Agent download link',
    transform: v => v.agentDownloadLink ?? '-',
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'createdAt',
    title: 'Deployed at',
    transform: v => Utils.formatDate(v.createdAt),
    thClass: 'text-left',
    className: 'text-left user-td',
  },
]

const Loading = ListableUISchema.loadingText
const Empty = ListableUISchema.emptyText

export const AppConfigListableTable: React.FC<IAppConfigListableTableProps> = () => {
  const { Provider, Context } = useODListableContext<GQLAppConfig, IAppConfigListableOption>()
  const dataLoader = useAppConfigListDataLoader()
  const toolbarStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: 15 }
  const addButtonStyle = { minWidth: 135 }
  const linkStyle = { textDecoration: 'none' }

  const eventParentContext: IAppConfigListableOptionalContext = {}

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v[AppConfigPrimaryKey].toString()}
      pageSize={10}
      onDataLoaderError={ODWebUtils.showError}
      searchOnLoad
    >
      <Card style={{ flexGrow: 1 }}>
        <CardBody>
          <div style={toolbarStyle}>
            <div />
            <Link to={SiteUrls.Admin.AppConfig.Update} style={linkStyle}>
              <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} rounded style={addButtonStyle}>
                Change settings
              </ODButton>
            </Link>
          </div>
          <ODListablePaginatedTable
            fields={TableDefinition}
            listableContext={Context}
            renderLoading={() => Loading}
            renderEmpty={() => Empty}
            eventParentContext={eventParentContext}
          />
          <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}
