import { ORG_USER_PRIV, ORG_USER_PRIV_TO_STRING, USER_PRIV, USER_PRIV_TO_STRING } from '@cils/common'
import { useODModalConfirm, useWrappedAPI } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Button, Card, CardBody } from 'reactstrap'
import {
  GQLInputChangeUserPriv,
  GQLListableUser,
  GQLListableUserInput,
  GQLSystemStat,
  GQLUser,
} from '../../@types/server'
import { Clickable } from '../../components/Clickable'
import { FlexContentsContainer } from '../../components/FlexContentsContainer'
import { LastLoginToken } from '../../components/LastLoginToken'
import { useModalSelect } from '../../components/ODModal/ODModalSelector'
import { ODTabBarButton, ODTabBarContainer } from '../../components/ODTabBarButton'
import { useCounter, useODMutation, useODQuery, useODQuery2 } from '../../context/ODCommon'
import { useAPIs } from '../../context/useAPIs'
import { ODColors } from '../../global-styles'
import { GQL_CHANGE_USER_PRIV, ORG_FULL_SNAPSHOT, USER_FULL_SNAPSHOT } from '../../gqls'
import { createODListableContext, ODListableOption, ODListableResponseType } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils'

interface Props {}

export interface UserDataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLUser, UserDataLoaderOption>()

const GQL_LIST_USERS = `
query users($data: ListableUserInput!) {
  listUser(data: $data) {
    list {
      ${USER_FULL_SNAPSHOT}
      organizations {
        joinId
        org {
          ${ORG_FULL_SNAPSHOT}
        }
        orgUserPriv
      }
    }
    totalCount
    page
    pageSize
  }
}
`

const GQL_GET_PENDING_USERS = `
query getPendingUsers {
  systemStat {
    numPendingUsers
  }
}
`

export const UsersContainer: React.FC<Props> = ({ ...props }) => {
  const listUsers = useODQuery<GQLListableUserInput, GQLListableUser>(GQL_LIST_USERS)
  const changeUserPriv = useODMutation<GQLInputChangeUserPriv, GQLUser>(GQL_CHANGE_USER_PRIV)
  const { resetUserPassword: apiResetUserPassword } = useAPIs()
  const { data } = useODQuery2<void, GQLSystemStat>(GQL_GET_PENDING_USERS, {
    skip: false,
    pickFirstKey: true,
    refreshInterval: 5000,
  })
  const numPendingUsers = data?.numPendingUsers || 0

  const [loading, setLoading] = React.useState(false)
  const [selected, setSelected] = React.useState(0)
  const [token, increaseToken] = useCounter()

  const resetUserPassword = useWrappedAPI(
    (userId: number) => apiResetUserPassword({ id: userId }),
    loading,
    setLoading,
    {
      onSuccess: async output => {
        const { password } = output
        alert(`Generated password : \n\n${password}`)
      },
      successMessage: 'Password has been successfully reset.',
    }
  )

  const { Component, choose, props: componentProps } = useModalSelect({
    okTitle: 'Confirm',
    selects: [
      {
        title: 'Rejected',
        description: '연구자의 가입을 거절, 또는 이미 가입된 사용자의 로그인을 제한합니다.',
        value: USER_PRIV.Rejected,
      },
      {
        title: 'Researcher',
        description:
          '연구자는 시스템 설정을 수정하거나, 사용자 권한 변경 등을 할 수 없습니다. 연구와 관련한 권한은 각 소속기관의 권한에 따릅니다.',
        value: USER_PRIV.Normal,
      },
      {
        title: 'CILS Administrator',
        description: 'CILS Administrator can change system settings, user permissions, organizations, etc.',
        value: USER_PRIV.SuperAdmin,
      },
    ],
    title: 'Change User Permission',
  })

  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})

  const handleChangeUserPriv = async (v: GQLUser) => {
    const priv = await choose(v.priv)
    if (priv !== null && priv !== v.priv) {
      await changeUserPriv({ id: v.userId, priv }).then(() => {
        Utils.showSuccess('Changed user permission.', 'Success')
        increaseToken()
      }, Utils.showError)
    }
  }

  const dataLoader = React.useCallback(
    async function UserDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: UserDataLoaderOption
    ): Promise<ODListableResponseType<GQLUser>> {
      const r = await listUsers({
        page,
        pageSize,
        filter: options.filter || null,
        priv: selected === 0 ? [USER_PRIV.Normal, USER_PRIV.SuperAdmin] : [USER_PRIV.Pending],
      })
      return r as ODListableResponseType<GQLUser>
    },
    [listUsers, selected]
  )

  const TableDefinition: ODListableTableDefinition<GQLUser, UserDataLoaderOption> = [
    {
      id: 'userId',
      title: 'User Number',
      transform: v => v.userId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'username',
      title: 'Id',
      transform: v => (v.username ?? '').toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'name',
      title: 'Name',
      transform: v => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'email',
      title: 'Email',
      transform: v => v.email,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'regDate',
      title: 'Registration Time',
      transform: v => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'perm',
      title: 'Permission',
      transform: v => <Clickable onClick={() => handleChangeUserPriv(v)}>{USER_PRIV_TO_STRING[v.priv]}</Clickable>,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'org',
      title: 'Organization',
      transform: v => v.organizations?.[0]?.org?.name || '-',
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'orgPerm',
      title: 'Permission',
      transform: v => {
        const priv = v.organizations?.[0]?.orgUserPriv
        return priv ? ORG_USER_PRIV_TO_STRING[priv as ORG_USER_PRIV] : '-'
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'lastLogin',
      title: 'Last Visit',
      transform: v => <LastLoginToken dateToken={v.lastAccessTime} />,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  const handleResetPassword = async (user: GQLUser) => {
    if (
      await confirm({
        title: `Reset password`,
        message: `Reset password for user ${user.name}?`,
        yes: 'Reset password',
        no: 'Cancel',
      })
    ) {
      await resetUserPassword(user.userId)
    }
  }

  if (selected === 0) {
    TableDefinition.push({
      id: 'resetPassword',
      title: 'Reset Password',
      transform: v => (
        <span style={{ cursor: 'pointer', color: ODColors.Primary }} onClick={() => handleResetPassword(v)}>
          Reset
        </span>
      ),
      thClass: 'text-center',
      className: 'text-center user-td',
    })
  }

  return (
    <FlexContentsContainer>
      <Component {...componentProps} />
      <ConfirmComponent {...confirmProps} />
      <ODTabBarContainer>
        <ODTabBarButton active={selected === 0} onClick={() => setSelected(0)}>
          <strong>All</strong>
        </ODTabBarButton>
        <ODTabBarButton active={selected === 1} onClick={() => setSelected(1)}>
          <strong>Pending</strong>
          {numPendingUsers > 0 && (
            <Badge color="danger" style={{ borderRadius: 8, minWidth: 23, minHeight: 16, float: 'right' }}>
              <div>{numPendingUsers}</div>
            </Badge>
          )}
        </ODTabBarButton>
      </ODTabBarContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.userId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name, email, organization"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <div>
                <Link to={SiteUrls.Admin.User.Add} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 176 }}>
                    Add User
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => (selected === 0 ? 'No registered user.' : 'No pending user.')}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
