import { ODHSpace } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { GQLQueueProjectDownloadInput } from '../../../agent'
import { ODColors } from '../../../global-styles'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODToastType, showODToast } from '../../ODToast'

interface IProjectDataSelectMenu {
  numSelectedItem: number
  clearSelectedId: () => void
  handleDataUnlock: () => void
  setAssignee: () => void
  setReviewer: () => void
  setConfirm: () => void
  handleQueueDownload: (
    input: { hasSelectedIds?: boolean } & Partial<Pick<GQLQueueProjectDownloadInput, 'withResults' | 'onlyResults'>>
  ) => Promise<void>
}

export const ProjectDataSelectMenu: React.FC<IProjectDataSelectMenu> = props => {
  const {
    numSelectedItem,
    clearSelectedId,
    setConfirm,
    setAssignee,
    setReviewer,
    handleDataUnlock,
    handleQueueDownload,
  } = props
  return (
    <Container>
      <ODIcon
        onClick={clearSelectedId}
        icon={ODIcons.CoreX}
        style={{ fontSize: 18, color: ODColors.Primary, cursor: 'pointer' }}
      />
      <ODHSpace w={14} />
      <NumSelectedItemText>{numSelectedItem} Selected</NumSelectedItemText>
      <ODHSpace w={44} />
      <ButtonWithOutline onClick={() => handleDataUnlock()}>Unlock</ButtonWithOutline>
      <ODHSpace w={8} />
      <ButtonWithOutline onClick={setAssignee}>Assignee</ButtonWithOutline>
      <ODHSpace w={8} />
      <ButtonWithOutline onClick={setReviewer}>Reviewer</ButtonWithOutline>
      <ODHSpace w={8} />
      <ButtonWithOutline onClick={setConfirm}>Confirm</ButtonWithOutline>
      <ODHSpace w={10} />
      {/* 데이터 선택된 상황에서 Download items and results
          - 클릭 시 Agent를 통해 선택한 데이터 자체와 데이터 중 Finished(Success), Confirmed 상태의 프로젝트 결과 산출물 모두 다운로드 시작
       */}
      <PrimaryButton onClick={() => handleQueueDownload({ hasSelectedIds: true, withResults: true })}>
        Download items and results
      </PrimaryButton>
      <ODHSpace w={8} />
      {/*
          데이터 선택된 상황에서 Download results
          - 클릭 시 Agent를 통해 선택한 데이터 중 Finished(Success) 와 Confirmed 상태의 데이터의 프로젝트 결과 산출물만 다운로드 시작
      */}
      <PrimaryButton
        onClick={() => handleQueueDownload({ hasSelectedIds: true, onlyResults: true })}
        style={{ maxWidth: 144 }}
      >
        Download results
      </PrimaryButton>
      <ODHSpace w={8} />
      {/*
         데이터 선택된 상황에서 Download Item
         - 선택한 데이터의 데이터 자체만 다운로드하는 버튼
         - 클릭 시 Agent를 통해 데이터만 다운로드 시작
      */}
      <PrimaryButton
        onClick={() => handleQueueDownload({ hasSelectedIds: true, onlyResults: false, withResults: false })}
        style={{ maxWidth: 134 }}
      >
        Download Item
      </PrimaryButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border: 2px solid ${ODColors.Primary};
  margin-bottom: 30px;
  padding-left: 26px;
  padding-right: 13px;
  border-radius: 5px;
`

const NumSelectedItemText = styled.span`
  font-size: 15px;
  color: ${ODColors.Primary};
  font-weight: bold;
`

const ButtonWithOutline = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${ODColors.DarkSkyBlueTwo};
  color: ${ODColors.DarkSkyBlueTwo};
  font-size: 15px;
  width: 100%;
  max-width: 100px;
  height: 34px;
  border-radius: 3px;
`

const PrimaryButton = styled.button`
  width: 100%;
  max-width: 220px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  font-size: 15px;
  color: ${ODColors.White};
`
