import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Label, Row } from 'reactstrap'
import { GQLTCF_IMAGE_TYPE_CILS } from '../../@types/server'
import { useODAppContext } from '../../context/ODAppContext'
import { useCILSCategory } from '../../context/ODCILSHooks'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { GQLAgentListableContextType, GQLItemsListableContextType } from '../../ODListable/ODListableContext'
import { FilterButton } from './FilterButton'

type Props = {
  listableContext: React.Context<GQLItemsListableContextType> | React.Context<GQLAgentListableContextType>
}

interface Filter {
  key: string
  name: string
  onRemove: (v: Filter) => void
  color: string
  isRemove: boolean
}

interface TagFilter extends Filter {
  tagId: number
  name: string
}

interface CategoryFilter extends Filter {
  categoryId: number
  name: string
}

interface ImageTypeFilter extends Filter {
  type: GQLTCF_IMAGE_TYPE_CILS
  name: string
}

export const ActiveFilter: React.FC<Props> = props => {
  const { listableContext } = props

  // @ts-ignore
  const { state, updateLoadOption } = React.useContext(listableContext)

  const {
    categoryIds,
    tagIds,
    tcfImageTypes,
    timeLapseOnly,
    hasAttachment,
    modifiedTimeRange,
    addedToWorkset,
  } = state.loadOption
  const { state: { tags = [] } = {}, orgId } = useODSingleOrgContext()
  const { categories } = useCILSCategory(orgId)
  const { currentWorksetName } = useODAppContext()
  const { catId, tagId } = useParams()

  const removeCategory = (categoryId: number) => {
    const categoryIds = state.loadOption.categoryIds || []
    updateLoadOption({ ...state.loadOption, categoryIds: categoryIds.filter((id: number) => id !== categoryId) })
  }

  const removeTag = (tagId: number) => {
    const tagIds = state.loadOption.tagIds || []
    updateLoadOption({ ...state.loadOption, tagIds: tagIds.filter((id: number) => id !== tagId) })
  }

  const removeImageType = (imageType: GQLTCF_IMAGE_TYPE_CILS) => {
    const imageTypes = state.loadOption.tcfImageTypes || []
    updateLoadOption({
      ...state.loadOption,
      tcfImageTypes: imageTypes.filter((type: GQLTCF_IMAGE_TYPE_CILS) => type !== imageType),
    })
  }

  const removeTimeLapse = () => {
    updateLoadOption({ ...state.loadOption, timeLapseOnly: false })
  }

  const removeHasAttachment = () => {
    updateLoadOption({ ...state.loadOption, hasAttachment: null })
  }

  const removeModifiedTimeRange = () => {
    updateLoadOption({ ...state.loadOption, modifiedTimeRange: null })
  }

  const removeAddedToWorkset = () => {
    updateLoadOption({ ...state.loadOption, addedToWorkset: null })
  }

  const removeAllFilters = () => {
    updateLoadOption({
      ...state.loadOption,
      categoryIds: null,
      tagIds: null,
      tcfImageTypes: [],
      timeLapseOnly: false,
      hasAttachment: null,
      modifiedTimeRange: null,
      addedToWorkset: null,
    })
  }

  const worksetFilter = (() => {
    if (!currentWorksetName) {
      return []
    }

    return [
      {
        name: currentWorksetName,
        key: currentWorksetName,
        onRemove: () => null,
        color: '#7fc18d',
        isRemove: false,
      },
    ]
  })()

  const categoryFilter = (() => {
    if (catId) {
      return [
        {
          categoryId: parseInt(catId, 10),
          name:
            categories.find(category => category.categoryId === parseInt(catId, 10))?.label || `Category ID: ${catId}`,
          key: `category_${catId}`,
          onRemove: (filter: Filter) => removeCategory((filter as CategoryFilter).categoryId),
          color: '#e07442',
          isRemove: false,
        },
      ]
    } else if (categoryIds) {
      return categoryIds.map((v: number) => {
        return {
          categoryId: v,
          name: categories.find(category => category.categoryId === v)?.label || `Category ID: ${v}`,
          key: `category_${v}`,
          onRemove: (filter: Filter) => removeCategory((filter as CategoryFilter).categoryId),
          color: '#e07442',
          isRemove: true,
        }
      })
    } else {
      return []
    }
  })()

  const tagFilters = (() => {
    if (tagId) {
      return [
        {
          tagId: parseInt(tagId, 10),
          name: tags.find(tag => tag.tagId === parseInt(tagId, 10))?.name || `Tag ID: ${tagId}`,
          key: `tag_${tagId}`,
          onRemove: (filter: Filter) => removeTag((filter as TagFilter).tagId),
          color: '#20a8d8',
          isRemove: false,
        },
      ]
    } else if (tagIds) {
      return tagIds.map((v: number) => {
        return {
          tagId: v,
          name: tags.find(tag => tag.tagId === v)?.name || `Tag ID: ${v}`,
          key: `tag_${v}`,
          onRemove: (filter: Filter) => removeTag((filter as TagFilter).tagId),
          color: '#20a8d8',
          isRemove: true,
        }
      })
    } else {
      return []
    }
  })()

  const imageTypeFilter = (tcfImageTypes || []).map((imageType: GQLTCF_IMAGE_TYPE_CILS) => {
    return {
      type: imageType,
      name: imageType.toString().substring(2),
      key: `imageType_${imageType}`,
      onRemove: (filter: Filter) => removeImageType((filter as ImageTypeFilter).type),
      color: '#6a92c7',
      isRemove: true,
    }
  })

  const timeLapseFilter = (() => {
    if (!timeLapseOnly) {
      return []
    }

    return [
      {
        name: timeLapseOnly ? 'Timelapse' : '',
        key: `timeLapse_${timeLapseOnly}`,
        onRemove: () => removeTimeLapse(),
        color: '#76c7ba',
        isRemove: true,
      },
    ]
  })()

  const hasAttachmentFilter = (() => {
    if (hasAttachment === null || hasAttachment === undefined) {
      return []
    }

    return [
      {
        type: hasAttachment,
        name: hasAttachment ? 'Attachment: Y' : 'Attachment: N',
        key: `hasAttachment_${hasAttachment}`,
        onRemove: () => removeHasAttachment(),
        color: '#c77d61',
        isRemove: true,
      },
    ]
  })()

  const modifiedTimeRangeFilter = (() => {
    if (modifiedTimeRange === null || modifiedTimeRange === undefined) {
      return []
    }

    const date = modifiedTimeRange.start + ' ~ ' + modifiedTimeRange.end

    return [
      {
        type: modifiedTimeRange,
        name: date,
        key: `modifiedTimeRange_${modifiedTimeRange.toString()}`,
        onRemove: () => removeModifiedTimeRange(),
        color: '#91c76d',
        isRemove: true,
      },
    ]
  })()

  const addedToWorksetFilter = (() => {
    if (addedToWorkset === null || addedToWorkset === undefined) {
      return []
    }

    return [
      {
        name: addedToWorkset ? 'Workset : Added' : 'Workset : Not added',
        key: `addedToWorkset_${addedToWorkset}`,
        onRemove: () => removeAddedToWorkset(),
        color: '#29bb98',
        isRemove: true,
      },
    ]
  })()

  const filters: Array<Filter> = [
    ...tagFilters,
    ...imageTypeFilter,
    ...timeLapseFilter,
    ...hasAttachmentFilter,
    ...modifiedTimeRangeFilter,
    ...categoryFilter,
    ...worksetFilter,
    ...addedToWorksetFilter,
  ]

  const onlyCategoryOrTagFilter = (() => {
    if (filters.find(v => !v.isRemove)) {
      if (filters.length === 1) {
        return true
      }
    }
    return false
  })()

  if (filters.length === 0) {
    return null
  }

  return (
    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Col
        md={12}
        className="vcenter"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            width: 101,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginRight: 20,
          }}
        >
          <Label htmlFor="select" style={{ fontWeight: 'bold' }}>
            Active Filters
          </Label>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
          {filters.map(filter => {
            return (
              <FilterButton
                isClearAll={false}
                color={filter.color}
                key={filter.key}
                label={filter.name}
                onRemove={() => filter.onRemove(filter)}
                isRemove={filter.isRemove}
              />
            )
          })}
          {!onlyCategoryOrTagFilter && (
            <FilterButton isRemove isClearAll color="#f87170" label="Clear All" onRemove={() => removeAllFilters()} />
          )}
        </div>
      </Col>
    </Row>
  )
}
