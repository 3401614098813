import {
  GQLListableProgressWithWorkset,
  GQLListableProgressWithWorksetInput,
  GQLProgressWithWorkset,
} from '../../../agent'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import { useODQuery2 } from '../../../context/ODCommon'
import { ODListableOption } from '../../../ODListable/ODListableContext'

export type SelectedWorkset = {
  worksetId: number
  worksetName: string
  worksetDirName: string
  isProject?: boolean
}

export type SetSelectedWorkset = (selectedWorkset: SelectedWorkset | undefined) => void

// agent/progress/common.ts 와 일치시키기.
export enum AgentProgressSort {
  StartedAtAscending = 'StartedAtAscending', // 늦게 시작한 게 뒤로,
  StartedAtDescending = 'StartedAtDescending', // 늦게 시작한 게 앞으로,
}

export interface IAgentProgressListableOption extends ODListableOption {
  filter: string | null
  sortOrder?: AgentProgressSort | null
}

export const GQL_LIST_PROGRESS_WITH_WORKSET = `
query listProgressWithWorkset($data: ListableProgressWithWorksetInput!) {
  listProgressWithWorkset(data: $data) {
    list {
      infoId
      isWorkset
      itemFolderName
      priority
      isProject
      itemProgress {
        infoId
        itemFolderName
        finishedSize
        size
        itemId
        title
        dataId
        progressType
        progressStatus
        percentage
        asChildOfWorksetId
        hasError
      }
      worksetProgress {
        worksetId
        worksetName
        itemFolderName
        size
        finishedSize
        progressType
        progressStatus
        percentage
        childItems {
          itemFolderName
          priority
          finishedSize
          size
          itemId
          title
          dataId
          progressType
          progressStatus
          percentage
          asChildOfWorksetId
        }
      }
    }
    totalCount
    page
    pageSize
  }
}
`

export function useAgentProgressDataAPI() {
  const { client } = useCILSAgentContext()
  const { api: apiList } = useODQuery2<GQLListableProgressWithWorksetInput, GQLListableProgressWithWorkset>(
    GQL_LIST_PROGRESS_WITH_WORKSET,
    { client, skip: true, pickFirstKey: true }
  )

  return apiList
}

export const getProgressStatusFromProgressWithWorkset = (v: GQLProgressWithWorkset) => {
  return v.isWorkset ? v.worksetProgress!.progressStatus : v.itemProgress!.progressStatus
}
