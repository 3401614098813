import gql from 'graphql-tag'

export const GQL_READ_APP_CONFIG = gql`
  query getAppConfig($data: SingleIDInput!) {
    getAppConfig(data: $data) {
      configId
      agentManualLink
      createdAt
    }
  }
`

export const GQL_READ_LATEST_APP_CONFIG = gql`
  query getLatestAppConfig($data: EmptyInput!) {
    getLatestAppConfig(data: $data) {
      configId
      agentManualLink
      createdAt
    }
  }
`

export const GQL_UPDATE_APP_CONFIG = gql`
  mutation updateAppConfig($data: AppConfigUpdateInput!) {
    updateAppConfig(data: $data) {
      configId
      agentManualLink
      createdAt
    }
  }
`

export const GQL_LIST_APP_CONFIG = gql`
  query listAppConfig($data: ListableAppConfigInput!) {
    listAppConfig(data: $data) {
      list {
        configId
        agentManualLink
        agentVersion
        agentDownloadLink
        createdAt
      }
      totalCount
      page
      pageSize
    }
  }
`
