import React from 'react'
import styled from 'styled-components'

interface INotEnoughStorageBodyProps {
  downloadSize: string
  freeSpace: string
}

const BoldText = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  text-align: center;
  color: #2e343a;
`

export const NotEnoughStorageBody: React.FC<INotEnoughStorageBodyProps> = props => {
  const { downloadSize, freeSpace } = props

  return (
    <div>
      <BoldText>
        Data to Download : {downloadSize}
        <br />
        Storage available : {freeSpace}
      </BoldText>
      <p />
      <div>
        There is not enough storage available. <br /> Are you sure you want download?
      </div>
    </div>
  )
}
