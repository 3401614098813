import { ORG_ROLE } from '@cils/common'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useOrgPerm } from '../../context/useOrgPerm'
import { OrgAdminSiteNavs } from '../../routes/OrgAdminNavs'
import { OrgAdminRoutes } from '../../routes/OrgAdminRoutes'
import { SiteUrls } from '../../urls'
import { CommonMainLayout } from '../CommonMainLayout'

interface Props {
  orgId: number
}

export const OrgAdminMainLayout: React.FC<Props> = ({ orgId }) => {
  const permUtils = useOrgPerm(orgId)
  const location = useLocation()

  const isInProjectsPage = SiteUrls.OrgAdmin.Projects.List(orgId).includes(location.pathname)
  const isInProjectMetaDataPage = SiteUrls.OrgAdmin.ProjectMetadata.List(orgId).includes(location.pathname)
  const isRemoveLayoutMargin = isInProjectsPage || isInProjectMetaDataPage
  return (
    <CommonMainLayout
      routes={OrgAdminRoutes}
      navs={OrgAdminSiteNavs(
        orgId.toString(),
        permUtils?.isAllowed(ORG_ROLE.VIEW_ORG_CATEGORY) ?? false,
        permUtils?.isAllowed(ORG_ROLE.VIEW_AGENT) ?? false,
        permUtils?.isAllowed(ORG_ROLE.VIEW_ALERT_LOGS) ?? false,
        permUtils?.isAllowed(ORG_ROLE.VIEW_AUDIT_LOGS) ?? false,
        permUtils?.isAllowed(ORG_ROLE.EDIT_ORG_PROJECT) ?? false,
        permUtils?.isAllowed(ORG_ROLE.EDIT_ORG_PROJECT) ?? false
      )}
      defaultRedirect={SiteUrls.OrgAdmin.Dashboard.Main(orgId.toString())}
      isAdminLayout={false}
      isInOrgAdmin={orgId}
      isRemoveLayoutMargin={isRemoveLayoutMargin}
    />
  )
}
