import React from 'react'
import { GQLListableCategory, GQLListableCategoryInput } from '../../@types/server'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { ODCategoryCard } from '../../components/ODImageCard/ODCategoryCard'
import { useODQuery2 } from '../../context/ODCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { SiteUrls } from '../../urls'
import { FlexContentsContainer } from '../../components/FlexContentsContainer'

const GQL_LIST_CATEGORIES = `
query listCategory($data: ListableCategoryInput!) {
  listCategory(data: $data) {
    list {
      categoryId
      name
      color
      numFiles
      createdAt
      mainItems {
        imageFile {
          link
        }
      }
    }
    totalCount
    page
    pageSize
  }
}
`
export const CategoryContainer: React.FC = () => {
  const { orgId } = useODSingleOrgContext()
  const { data, loading } = useODQuery2<GQLListableCategoryInput, GQLListableCategory>(GQL_LIST_CATEGORIES, {
    pickFirstKey: true,
    skip: false,
    variables: { page: 1, pageSize: 1000, filter: '', orgId },
  })

  const contents = data?.list?.map(v => ({
    title: v.name!,
    numItems: v.numFiles,
    color: `#${v.color!}`,
    link: SiteUrls.User.Org.Category.ItemList(v.categoryId)(orgId),
    images: v.mainItems.map(v => v.imageFile.link),
  }))

  if (!contents && loading) {
    return <BlockingLoadBox show />
  }

  if (!contents) {
    return <div>No contents?</div>
  }

  return (
    <FlexContentsContainer>
      <div style={{ padding: 17, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <ODCategoryCard contents={contents} size={88} space={2} />
      </div>
    </FlexContentsContainer>
  )
}
