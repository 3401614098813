import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLWorkset, GQLWORKSET_SORT_OPTION } from '../../../@types/server'
import { useAPIs } from '../../../context/useAPIs'
import { IWorksetListableOption } from './WorksetCommon'

export function useWorksetListDataLoader(options: {
  orgId: number | null
  permMoreThanEditor: boolean | null
  sortBy: GQLWORKSET_SORT_OPTION | null
}) {
  const { orgId, permMoreThanEditor, sortBy } = options

  const { listWorksetWithFullPermission } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IWorksetListableOption
    ): Promise<ODListableResponseType<GQLWorkset>> {
      const r = await listWorksetWithFullPermission({
        page,
        pageSize,
        orgId: orgId,
        sortBy: sortBy,
        onlyAddable: permMoreThanEditor,
        ...options,
      })
      return r as ODListableResponseType<GQLWorkset>
    },
    [listWorksetWithFullPermission, orgId, permMoreThanEditor, sortBy]
  )
}

export function useWorksetInOrgListDataLoader(options: {
  orgId: number | null
  permMoreThanEditor: boolean | null
  sortBy: GQLWORKSET_SORT_OPTION | null
}) {
  const { orgId, permMoreThanEditor, sortBy } = options

  const { listWorksetInOrgWithFullPermission } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IWorksetListableOption
    ): Promise<ODListableResponseType<GQLWorkset>> {
      const r = await listWorksetInOrgWithFullPermission({
        page,
        pageSize,
        orgId: orgId,
        sortBy: sortBy,
        onlyAddable: permMoreThanEditor,
        ...options,
      })
      return r as ODListableResponseType<GQLWorkset>
    },
    [listWorksetInOrgWithFullPermission, orgId, permMoreThanEditor, sortBy]
  )
}
