import React from 'react'
import styled from 'styled-components'
import { ODColors } from '../../global-styles'
import { ODListableContextType, ODListableOption } from '../../ODListable/ODListableContext'
import { ODIcon, ODIcons } from '../ODIcon'

type ImageCountsProps<T, O extends ODListableOption> = {
  listableContext: React.Context<ODListableContextType<T, O>>
}

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #708190;

  .bold {
    font-weight: bold;
    color: #29b6ca;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export function ImageCounts<T, O extends ODListableOption>(props: ImageCountsProps<T, O>) {
  const { listableContext } = props
  const {
    state: { totalCount, list },
  }: ODListableContextType<T, O> = React.useContext(listableContext)

  return (
    <div style={{ display: 'inline-block' }}>
      <Wrapper>
        <ODIcon icon={ODIcons.MaterialFilter} style={{ color: ODColors.Primary }} />
        <div style={{ width: 9 }} />
        <Text>
          <span className="bold">{list.length} </span> / {totalCount} images
        </Text>
      </Wrapper>
    </div>
  )
}
