import { useODMutation } from '@odc/od-react-belt'
import React from 'react'
import { useRecoilState } from 'recoil'
import { GQLItem, GQLListableItem, GQLListableItemInput, GQLOkResponse, GQLWorkset } from '../../../@types/server'
import { GQLSingleIDInput } from '../../../agent'
import { WORKSET_DETAIL_STATE, worksetDetailState } from '../../../context/atoms'
import { GQL_ITEM_IN_LIST_SNAPSHOT, useODAppContext } from '../../../context/ODAppContext'
import { useODQuery, useODQuery2 } from '../../../context/ODCommon'
import { ODListableResponseType } from '../../../ODListable/ODListableContext'
import { ItemDataLoaderOption, ItemsContainer } from './ItemsContainer'

const GQL_LIST_ITEMS = `
query listItem($data: ListableItemInput!) {
  listItem(data: $data) {
    list {
      ${GQL_ITEM_IN_LIST_SNAPSHOT}
    }
    page
    pageSize
    totalCount
  }
}
`

const GQL_GET_WORKSET = `
query getWorkset($data: SingleIDInput!) {
  getWorkset(data: $data) {
    wsId
    name
    myPerm
    isFavorite
    isNew
    worksetGroups {
      wsgId
      name
    }
  }
}
`

export const GQL_MARK_WORKSET_AS_READ = `
mutation markWorksetAsRead($data: SingleIDInput!) {
  markWorksetAsRead(data: $data) {
    ok
  }
}
`

type Props = {
  wsId: number
}

export const WorksetItemsContainer: React.FC<Props> = ({ wsId }) => {
  const apiList = useODQuery<Partial<GQLListableItemInput>, GQLListableItem>(GQL_LIST_ITEMS)
  const { data } = useODQuery2<GQLSingleIDInput, GQLWorkset>(GQL_GET_WORKSET, {
    pickFirstKey: true,
    variables: { id: wsId },
  })
  const apiMarkAsRead = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_MARK_WORKSET_AS_READ, 0, 'markWorksetAsRead')
  const [, setWorksetDetail] = useRecoilState<WORKSET_DETAIL_STATE>(worksetDetailState)

  React.useEffect(() => {
    if (data) {
      setWorksetDetail({ wsId: data.wsId, perm: data.myPerm, favorite: data.isFavorite })
      if (data.isNew) {
        // noinspection JSIgnoredPromiseFromCall
        apiMarkAsRead({ id: wsId })
      }
    } else {
      setWorksetDetail({ wsId: null, perm: null, favorite: null })
    }
  }, [data, setWorksetDetail])

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemDataLoaderOption
    ): Promise<ODListableResponseType<GQLItem>> {
      const r = await apiList({
        page,
        pageSize,
        inWorkset: wsId,
        afterKey,
        filter: options.filter,
        orgId: null,
        tagIds: options.tagIds,
        hasAttachment: options.hasAttachment,
        timeLapseOnly: options.timeLapseOnly,
        tcfImageTypes: options.tcfImageTypes,
        // @ts-ignore
        sortBy: options.sortBy,
        modifiedTimeRange: options.modifiedTimeRange,
        categoryIds: options.categoryIds,
        hidden: options.hidden,
      })
      return r
    },
    [apiList, wsId]
  )

  const { setCurrentWorksetName } = useODAppContext()
  React.useEffect(() => {
    if (data) {
      setCurrentWorksetName(data?.name || '')
    }
    return () => setCurrentWorksetName('')
  }, [setCurrentWorksetName, data])

  return <ItemsContainer showToolbar={true} dataLoader={dataLoader} inWorksetId={wsId} />
}
