import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ThumbnailInfo } from '../containers/User/DetailContainerComponent/convertItemAttachmentToThumbnailInfo'
import { ItemAttachmentImage } from './ItemAttachmentImage'
import { ODIcon, ODIcons } from './ODIcon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 551px;
  flex-wrap: nowrap;
`

const AddImageBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 79px;
  height: 79px;
  border: dashed 1px #c8ced3;
  background-color: rgba(115, 129, 143, 0.1);
  cursor: pointer;
  justify-content: center;
  align-items: center;
`

type ImageSliderProps = {
  selectedId: string
  thumbnails: Array<ThumbnailInfo>
  onClick: (thumbnail: ThumbnailInfo) => void
  onOpenFileUploadView: () => void
  editable: boolean
}

export const ImageSlider: React.FC<ImageSliderProps> = props => {
  const { thumbnails, onClick, selectedId, onOpenFileUploadView, editable } = props

  const imageRef = useRef<HTMLDivElement>(null)
  // const thumbnailImageRef = useRef<HTMLImageElement>(null)
  const [left, setLeft] = useState(false)
  const [right, setRight] = useState(true)

  useEffect(() => {
    if (imageRef.current !== null) {
      setLeft(imageRef.current.scrollLeft !== 0)
    }
  }, [setLeft, imageRef])

  const moveScroll = useCallback(
    (v: number) => {
      if (imageRef.current !== null) {
        const width = imageRef.current.offsetWidth
        const pos = imageRef.current.scrollLeft + 94 * v
        imageRef.current.scrollTo({ left: pos, behavior: 'auto' })
        setLeft(imageRef.current.scrollLeft !== 0)
        setRight(width + imageRef.current.scrollLeft < imageRef.current.scrollWidth)
      }
    },
    [imageRef]
  )

  return (
    <Wrapper>
      <KeyboardArrowLeftIcon
        onClick={() => moveScroll(-1)}
        style={{
          color: '#2f353a',
          opacity: left ? 1 : 0.3,
          fontSize: 30,
          cursor: left ? 'pointer' : 'default',
        }}
      />
      <ImageWrapper ref={imageRef}>
        {thumbnails.map((v, i) => {
          return (
            <ItemAttachmentImage
              isSelected={v.id === selectedId}
              item={v}
              key={i.toString()}
              onClick={() => onClick(v)}
            />
          )
        })}
        {editable && (
          <AddImageBox onClick={onOpenFileUploadView}>
            <ODIcon icon={ODIcons.CorePlus} style={{ fontSize: 16, color: '#73818f' }} />
          </AddImageBox>
        )}
      </ImageWrapper>
      <KeyboardArrowRightIcon
        onClick={() => moveScroll(1)}
        style={{
          color: '#2f353a',
          opacity: right ? 1 : 0.3,
          fontSize: 30,
          cursor: right ? 'pointer' : 'default',
        }}
      />
    </Wrapper>
  )
}
