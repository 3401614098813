import { ORG_ROLE } from '@cils/common'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { GQLCategory, GQLListableCategory, GQLListableCategoryInput } from '../../../@types/server'
import { Clickable } from '../../../components/Clickable'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODQuery } from '../../../context/ODCommon'
import { useOrgPerm } from '../../../context/useOrgPerm'
import { ColorBall } from '../../../ODEntityEditor/FormComponents/ODEntityColorPicker'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type Props = {
  orgId: number
}

interface CategoryDataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLCategory, CategoryDataLoaderOption>()

const GQL_LIST_CATEGORY = `
query listCategory($data: ListableCategoryInput!) {
  listCategory(data: $data) {
    list {
      categoryId
      name
      color
      numFiles
    }
    totalCount
    page
    pageSize
  }
}
`

export const OrgCategoryListContainer: React.FC<Props> = ({ orgId }) => {
  const apiList = useODQuery<GQLListableCategoryInput, GQLListableCategory>(GQL_LIST_CATEGORY)
  const permUtils = useOrgPerm(orgId)
  const canEditCategory = permUtils?.isAllowed(ORG_ROLE.EDIT_ORG_CATEGORY)
  const canAddCategory = permUtils?.isAllowed(ORG_ROLE.CREATE_ORG_CATEGORY)

  const dataLoader = React.useCallback(
    async function CategoryDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: CategoryDataLoaderOption
    ): Promise<ODListableResponseType<GQLCategory>> {
      const r = await apiList({ page, pageSize, filter: options.filter || null, orgId })
      return r as ODListableResponseType<GQLCategory>
    },
    [orgId, apiList]
  )

  const TableDefinition: ODListableTableDefinition<GQLCategory, CategoryDataLoaderOption> = [
    {
      id: 'name',
      title: 'Name',
      transform: v => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'numFiles',
      title: 'TCF Files',
      transform: v => v.numFiles.toLocaleString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'color',
      title: 'Color',
      transform: v => <ColorBall selected={false} code={`#${v.color}`} />,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  if (canEditCategory) {
    TableDefinition.push({
      id: 'setting',
      title: 'Action',
      transform: v => {
        return (
          <Link to={SiteUrls.OrgAdmin.Category.Edit(v.categoryId)(orgId)}>
            <Clickable>Setting</Clickable>
          </Link>
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    })
  }

  const canViewCategory = permUtils?.isAllowed(ORG_ROLE.VIEW_ORG_CATEGORY)
  if (!canViewCategory) {
    return null
  }

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Category</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.categoryId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              {canAddCategory && (
                <div>
                  <Link to={SiteUrls.OrgAdmin.Category.Add(orgId)} style={{ textDecoration: 'none' }}>
                    <Button block color="primary" style={{ minWidth: 135 }}>
                      New Category
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
