import { ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { ODColors } from '../../../global-styles'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalFooter } from '../../ODModal/ODModalFooter'
import { ODModalHeader } from '../../ODModal/ODModalHeader'

interface IODModalDeleteProject {
  isOpen: boolean
  projectName: string
  onClose: () => void
  onDelete: (password: string) => Promise<void>
}

export const ODModalDeleteProject: React.FC<IODModalDeleteProject> = props => {
  const { isOpen, onClose, onDelete, projectName } = props
  const [password, setPassword] = React.useState('')

  const handleDelete = async () => {
    await onDelete(password)
  }

  const modalTitle = 'Delete Project!'

  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal} style={{ borderRadius: 4, maxWidth: 422 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={modalTitle} onClose={onClose} />
        <ODModalBody>
          <TextBox>
            Are you sure you want to delete <BoldText>“{projectName}”?</BoldText> <br />
            This cannot be undone. <br />
            <br />
            To continue, please re-enter your password.
          </TextBox>
          <ODVSpace h={20} />
          <Input
            placeholder={'Password'}
            type={'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </ODModalBody>
        <ODModalFooter>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton onClick={() => handleDelete()}>Delete Project</DeleteButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

const TextBox = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
`

const BoldText = styled.span`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  font-weight: bold;
`

const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.21px;
  color: ${ODColors.CharcoalGreyTwo};
  width: 100%;
  max-width: 140px;
  height: 47px;
  background-color: ${ODColors.Silver};
`
const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.21px;
  color: ${ODColors.White};
  flex: 1;
  height: 47px;
  background-color: ${ODColors.Primary};
`
