import { useCounter } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLWorksetGroup } from '../../../../@types/server'
import { FlexContentsContainer } from '../../../../components/FlexContentsContainer'
import { createODListableContext, ODListableStyle } from '../../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../../../urls'
import { IWorksetGroupListableOption } from '../WorksetGroupCommon'
import { useWorksetGroupListDataLoader } from './useWorksetGroupListDataLoader'
import { ODWorksetGroupSearch } from '../ODWorksetGroupSearch'

interface Props {
  orgId: number
}

const { Provider, Context } = createODListableContext<GQLWorksetGroup, IWorksetGroupListableOption>()

export const WorksetGroupListPage: React.FC<Props> = props => {
  const { orgId } = props
  const dataLoader = useWorksetGroupListDataLoader(orgId)
  const [token, refresh] = useCounter()

  const TableDefinition: ODListableTableDefinition<GQLWorksetGroup, IWorksetGroupListableOption> = [
    {
      id: 'worksetGroupName',
      title: 'Workset Group Name',
      transform: v => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link to={SiteUrls.User.Org.WorksetGroup.Detail(v.wsgId)(orgId)}>{v.name ?? '-'}</Link>
        </div>
      ),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'workset',
      title: 'Workset',
      transform: v => {
        if (!v.worksets || v.worksets.length === 0) {
          return '-'
        }

        return (
          <div>
            {v.worksets.map(workset => (
              <div key={workset.wsId}>{workset.name ?? '-'}</div>
            ))}
          </div>
        )
      },
      thClass: 'text-left',
      className: 'text-left',
    },
  ]

  return (
    <FlexContentsContainer>
      <Provider
        dataLoader={dataLoader}
        // WorksetGroup으로 교체 필요
        keyExtractor={v => v.wsgId.toString()}
        pageSize={12}
        searchOnLoad
        style={ODListableStyle.TableStyle}
        refreshToken={token.toString()}
      >
        <ODWorksetGroupSearch listableContext={Context} orgId={orgId} refresh={refresh} />
        <div style={{ padding: 17 }}>
          <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
            <CardBody>
              <ODListablePaginatedTable
                fields={TableDefinition}
                listableContext={Context}
                renderLoading={() => 'Loading..'}
                renderEmpty={() => 'No Result.'}
              />
              <ODListablePagination hideIfSinglePage listableContext={Context} />
            </CardBody>
          </Card>
        </div>
      </Provider>
    </FlexContentsContainer>
  )
}
