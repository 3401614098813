import { ODVSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { useWorksetGroupListHasWorksetIds, WorksetGroupOption } from '../../context/ODCILSHooks'
import { useCILSStore } from '../../di/configureRootStore'
import { ODColors } from '../../global-styles'
import { ODButton, ODButtonSize, ODButtonTheme } from '../ODButton'
import { ODIcon, ODIcons } from '../ODIcon'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

interface IODModalAddWorksetGroup {
  isOpen: boolean
  setOpen: (open: boolean) => void
  orgId: number
  setOpenResult: () => void
  refresh?: () => void
}

export const ODModalAddWorksetGroup: React.FC<IODModalAddWorksetGroup> = observer(props => {
  const { isOpen, setOpen, orgId, setOpenResult, refresh } = props
  const store = useCILSStore()
  const { worksetGroups } = useWorksetGroupListHasWorksetIds(orgId)
  const [selectedValue, setSelectedValue] = React.useState<WorksetGroupOption | null>(null)
  const selectFocus = useRef<HTMLInputElement>(null)

  return (
    <ODModal isOpen={isOpen} toggle={() => setOpen(false)}>
      <ODModalHeader title={'Add Workset Group'} />
      <ODModalBody
        style={{ paddingLeft: 37, paddingRight: 37, paddingBottom: 42, display: 'flex', flexDirection: 'column' }}
      >
        <Label>Select Workset Group</Label>
        <ODVSpace h={14} />
        <CustomSelectBox
          name="listWsg"
          options={worksetGroups}
          value={selectedValue}
          onChange={(e: WorksetGroupOption) => setSelectedValue(e)}
          ref={selectFocus}
        />
        <div style={{ display: 'flex', marginTop: 25 }}>
          <ODIcon
            icon={ODIcons.MaterialError}
            style={{ position: 'relative', top: 4, fontSize: 14, color: ODColors.Primary, marginRight: 14 }}
          />
          <PrimaryText>
            Only worksets in the Workset Group with <br /> Owner, Editor, Collaborator permission can <br /> be added
          </PrimaryText>
        </div>
      </ODModalBody>
      <ODModalFooter>
        <ODButton
          theme={ODButtonTheme.Primary}
          fullWidth
          size={ODButtonSize.Normal}
          onClick={async () => {
            if (selectedValue) {
              store.addWorksetToProjectStore.addWorksetIds(selectedValue?.valueForSubmit)
              await store.addWorksetToProjectStore.submit()
              setOpenResult()
              refresh?.()
              setOpen(false)
            }
          }}
        >
          Add
        </ODButton>
      </ODModalFooter>
    </ODModal>
  )
})

const Label = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${ODColors.Steel};
`

const CustomSelectBox = styled(Select)`
  width: 100%;
`

const PrimaryText = styled.span`
  font-size: 14px;
  color: ${ODColors.Primary};
`
