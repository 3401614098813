"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CilsStoragePropsInputValidation = exports.UserGroupPropsInputValidation = exports.WorksetPropsInputValidation = exports.SpecificDateTimeValidation = exports.SpecificDateValidation = exports.AttachmentTypePropsInputValidation = exports.ApiKeyPropsInputValidation = exports.UpdateOrgHasUserInputValidation = exports.CategoryPropsInputValidation = exports.OrgPropsInputValidation = exports.UserPropsInputValidation = exports.UserValidation = exports.CategoryValidation = exports.ColorValidation = void 0;

var lodash_1 = require("lodash");

var Yup = require("yup");

var constants_1 = require("./constants");

var makeNumberEnumTester = function makeNumberEnumTester(enumObject) {
  var values = Object.values(enumObject).filter(function (v) {
    return lodash_1.isNumber(v);
  });
  return function (value) {
    return values.indexOf(value) >= 0;
  };
};

var regHex8ColorCode = /^[0-9A-Fa-f]{8}$/i;
exports.ColorValidation = Yup.string().matches(regHex8ColorCode, 'Color code must be 8 characters long hex code, including alpha channel.');
exports.CategoryValidation = {
  NameMinLength: 2,
  NameMaxLength: 40
};
exports.UserValidation = {
  NameMinLength: 2,
  NameMaxLength: 20
};
var regExpPassword = /^[a-zA-Z0-9!@#$%^&*]{6,20}$/;
var regExpUsername = /^[a-z0-9]{3,20}$/;
exports.UserPropsInputValidation = {
  name: Yup.string().min(exports.UserValidation.NameMinLength, "Name must be at least ".concat(exports.UserValidation.NameMinLength, " characters long.")).max(exports.UserValidation.NameMaxLength, "Name must be shorter than ".concat(exports.UserValidation.NameMaxLength, " characters.")),
  username: Yup.string().matches(regExpUsername, "ID must be 3~20 characters long and only lower-cased alphabets and numbers are allowed."),
  password: Yup.string().matches(regExpPassword, "Length of the password must be more than ".concat(6, ", less than ", 20, ", and only alphanumeric, and special characters are allowed.")),
  email: Yup.string().email('Invalid email address.'),
  priv: Yup.number().oneOf(Object.values(constants_1.USER_PRIV), 'Invalid permission value.')
};
exports.OrgPropsInputValidation = {
  name: Yup.string().min(2, 'Name must be at least 2 characters long.').max(40, 'Name must be shorter than 40 characters.'),
  supportEmail: Yup.string().email('Invalid email address.'),
  color: exports.ColorValidation
};
exports.CategoryPropsInputValidation = {
  color: exports.ColorValidation,
  name: Yup.string().min(exports.CategoryValidation.NameMinLength, "Name must be at least ".concat(exports.CategoryValidation.NameMinLength, " characters long.")).max(exports.CategoryValidation.NameMaxLength, "Name must be shorter than ".concat(exports.CategoryValidation.NameMaxLength, " characters."))
};
exports.UpdateOrgHasUserInputValidation = {
  orgUserPriv: Yup.number().test('orgUserPriv', 'Invalid organization permission level.', makeNumberEnumTester(constants_1.ORG_USER_PRIV))
};
exports.ApiKeyPropsInputValidation = {
  name: Yup.string().min(2, 'Name must be at least 2 characters long.').max(40, 'Name must be shorter than 40 characters.')
};
exports.AttachmentTypePropsInputValidation = {
  name: Yup.string().min(2, 'Name must be at least 2 characters long.').max(40, 'Name must be shorter than 40 characters.')
};
var regExpDate = /^[\d]{4}-[\d]{2}-[\d]{2}$/;
exports.SpecificDateValidation = Yup.string().matches(regExpDate, 'Date must be in (YYYY-MM-DD).');
var regExpDateTime = /^[\d]{4}-[\d]{2}-[\d]{2} [\d]{2}:[\d]{2}:[\d]{2}$/;
exports.SpecificDateTimeValidation = Yup.string().matches(regExpDateTime, 'DateTime must be in (YYYY-MM-DD HH:mm:ss).');
exports.WorksetPropsInputValidation = {
  name: Yup.string().min(2, 'Name must be at least 2 characters long.').max(140, 'Name must be shorter than 140 characters.')
};
exports.UserGroupPropsInputValidation = {
  name: Yup.string().min(2, 'Name must be at least 2 characters long.').max(140, 'Name must be shorter than 140 characters.')
};
exports.CilsStoragePropsInputValidation = {
  name: Yup.string().min(2, 'Name must be at least 2 characters long.').max(40, 'Name must be shorter than 40 characters.')
};