import { ALERT_TYPE } from '@cils/common'
import moment, { Moment } from 'moment-timezone'
import React, { useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { Card, CardBody, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import { GQLAlert } from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import {
  ODDropdownComponent,
  ODDropdownItem,
  renderDropdownItemNoFilter,
} from '../../../components/ODDropdownComponent'
import { OrgDropdownComponent } from '../../../components/OrgDropdownComponent'
import { createODListableContext } from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { Utils } from '../../../utils'
import { AlertDataLoaderOption, useAlertDataLoader } from './AlertDataLoader'

type Props = {
  noCardWrap?: boolean
  orgId: number | null
  userId: number | null
}

const { Provider, Context } = createODListableContext<GQLAlert, AlertDataLoaderOption>()

type ALERT_EVENT_TYPE_ITEM = ODDropdownItem & { value: ALERT_TYPE | null }

const EVENT_TYPE_DROPDOWN_ITEMS: Array<ALERT_EVENT_TYPE_ITEM> = [
  {
    id: 0,
    text: 'All Events',
    value: null,
  },
  {
    id: 1,
    text: 'Upload Failure',
    value: ALERT_TYPE.AGENT_UPLOAD_FAILURE,
  },
  {
    id: 2,
    text: 'Thumbnail Creation Failure',
    value: ALERT_TYPE.AGENT_THUMBNAIL_FAILURE,
  },
]

const createTableDefinition = (showOrg: boolean): ODListableTableDefinition<GQLAlert, AlertDataLoaderOption> => {
  const arr: ODListableTableDefinition<GQLAlert, AlertDataLoaderOption> = [
    {
      id: 'alertId',
      title: 'ID',
      transform: v => v.alertId,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'type',
      title: 'Event',
      transform: v => Utils.formatAlertType(v.alertType),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'description',
      title: 'Description',
      transform: v => v.description,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'user',
      title: 'User (Agent)',
      transform: v => (
        <span>
          {v.user?.name ?? '-'} ({v.user?.email ?? '-'})
        </span>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'date',
      title: 'Date',
      transform: v => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  if (showOrg) {
    arr.splice(2, 0, {
      id: 'org',
      title: 'Organization',
      transform: v => <span>{v.org?.name ?? '-'}</span>,
      thClass: 'text-left',
      className: 'text-left user-td',
    })
  }

  return arr
}

export const AlertLogsListContainer: React.FC<Props> = props => {
  const { orgId, userId, noCardWrap = false } = props
  const [selectedOrgId, setSelectedOrgId] = React.useState<number | null>(orgId)
  const [updateToken, setUpdateToken] = React.useState(0)
  const [selectedAlertType, setSelectedAlertType] = React.useState<ALERT_TYPE | null>(null)
  const [tableDefinition] = React.useState(() => createTableDefinition(!orgId && !userId))
  const [startDateTime, setStartDateTime] = useState<Moment>(
    moment()
      .subtract(10, 'days')
      .startOf('isoWeek')
  )
  const [endDateTime, setEndDateTime] = useState<Moment>(moment())
  const [startDateFocused, setStartDateFocused] = useState<boolean | null>(false)
  const [endDateFocused, setEndDateFocused] = useState<boolean | null>(false)

  const dataLoader = useAlertDataLoader(userId, selectedOrgId, selectedAlertType ? [selectedAlertType!] : null, {
    start: startDateTime.format('YYYY-MM-DD'),
    end: endDateTime.format('YYYY-MM-DD'),
  })

  const handleChangeOrg = (v: Array<number> | null) => {
    setSelectedOrgId(v?.[0] || null)
  }

  const handleChangeType = (v: ALERT_EVENT_TYPE_ITEM) => {
    setSelectedAlertType(v.value)
  }

  const contents = (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.alertId.toString()}
      pageSize={10}
      onDataLoaderError={Utils.showError}
      searchOnLoad
      refreshToken={updateToken.toString()}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODListableSearchBox
          listableContext={Context}
          placeholder="Search by description."
          style={{ flexGrow: 6, maxWidth: 600 }}
        />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div
            style={{
              minWidth: 76,
              paddingTop: 5,
              marginRight: 20,
            }}
          >
            <Label htmlFor="select" style={{ fontWeight: 'bold' }}>
              Filter
            </Label>
          </div>
          {!orgId && !userId && (
            <div style={{ marginRight: 20 }}>
              <OrgDropdownComponent onChange={handleChangeOrg} />
            </div>
          )}
          <div style={{ marginRight: 20 }}>
            <ODDropdownComponent
              list={EVENT_TYPE_DROPDOWN_ITEMS}
              onChange={handleChangeType}
              renderItem={renderDropdownItemNoFilter}
              textNotSelected="All Events"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
            <Label htmlFor="select" style={{ fontWeight: 'bold', marginTop: 7, marginRight: 15 }}>
              Date
            </Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: 0,
              }}
            >
              <CustomDate style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <SingleDatePicker
                  displayFormat="YYYY.MM.DD"
                  small
                  id="modified_date_start"
                  date={startDateTime}
                  onDateChange={date => date && setStartDateTime(date)}
                  readOnly={false}
                  focused={!!startDateFocused}
                  onFocusChange={({ focused }) => {
                    setStartDateFocused(focused)
                    if (!focused) {
                      setEndDateFocused(true)
                    }
                  }}
                  isOutsideRange={() => false}
                />
              </CustomDate>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                <span style={{ fontSize: 14, color: '#73818f', letterSpacing: 0.3 }}>~</span>
              </div>
              <CustomDate style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <SingleDatePicker
                  displayFormat="YYYY.MM.DD"
                  small
                  id="modified_date_end"
                  date={endDateTime}
                  onDateChange={date => date && setEndDateTime(date)}
                  readOnly={false}
                  focused={!!endDateFocused}
                  onFocusChange={({ focused }) => {
                    setEndDateFocused(focused)
                  }}
                  isOutsideRange={date => date.isBefore(startDateTime)}
                />
              </CustomDate>
            </div>
          </div>
          {/*<ODDropdownComponent*/}
          {/*  list={HiddenDropdownList}*/}
          {/*  onChange={handleChangeHiddenFilter}*/}
          {/*  renderItem={renderDropdownItemNoFilter}*/}
          {/*  textNotSelected="Hide hidden"*/}
          {/*/>*/}
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          {/*<ActiveFilter listableContext={Context} />*/}
        </div>
      </div>
      <ODListablePaginatedTable
        fields={tableDefinition}
        listableContext={Context}
        renderLoading={() => 'Loading..'}
        renderEmpty={() => 'No result.'}
      />
      <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
    </Provider>
  )

  if (noCardWrap) {
    return contents
  }

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>{contents}</CardBody>
      </Card>
    </FlexContentsContainer>
  )
}

const Label = styled.label`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #73818f;
`

export const AutoReloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 48px;
  margin-top: 6px;
`

const CustomDate = styled.div`
  .SingleDatePickerInput {
    width: 130px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #c8ced3;
  }
`
