import { PROJECT_ITEM_USER_STATUS } from '@cils/common'
import React from 'react'
import { GQLPROJECT_ITEM_USER_STATUS } from '../../../@types/server'
import { DEPRECATED_PROJECT_ITEM_USER_STATUS_LIST } from '../../../containers/User/Project/ProjectCommon'
import { ODColors } from '../../../global-styles'
import { Utils } from '../../../utils'
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick'
import { DropButton, DropDown, DropDownContent, OptionWrapper, Value } from '../../Dropdown/DropdownCommon'
import { ODIcon, ODIcons } from '../../ODIcon'

export enum ProjectStatus {
  NotStarted = 'NotStarted',
  Queued = 'Queued',
  Downloading = 'Downloading',
  Downloaded = 'Downloaded',
  Executing = 'Executing',
  Uploading = 'Uploading',
  FinishedSuccess = 'Finished(Success)',
  FinishedFailed = 'Finished(Failed)',
  FinishedError = 'Finished(Error)',
  Confirmed = 'Confirmed',
}

interface IProjectStatusDropDown {
  dropDownName: string
  value: PROJECT_ITEM_USER_STATUS | null
  setValue: (v: GQLPROJECT_ITEM_USER_STATUS | null) => void
}

export const ProjectStatusDropDown: React.FC<IProjectStatusDropDown> = props => {
  const { dropDownName, value, setValue } = props
  const menuRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const onChangeValues = (status: PROJECT_ITEM_USER_STATUS | null) => {
    if (!status) {
      return {
        label: dropDownName,
        value: null,
      }
    }
    return Utils.convertProjectItemUserStatus(status)
  }

  const dropdownMenus = Object.values(PROJECT_ITEM_USER_STATUS)
    .filter(s => !DEPRECATED_PROJECT_ITEM_USER_STATUS_LIST.includes(s))
    .map(item => onChangeValues(item))

  useDetectOutsideClick(() => setIsOpen(false), menuRef, isOpen)

  const onClick = (v: GQLPROJECT_ITEM_USER_STATUS | PROJECT_ITEM_USER_STATUS | null) => {
    if (!v) {
      setValue(null)
      setIsOpen(false)
      return
    }
    setValue(v as GQLPROJECT_ITEM_USER_STATUS)
    setIsOpen(false)
  }

  return (
    <DropDown ref={menuRef} style={{ width: 150 }}>
      <DropButton onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))} style={{ width: 150 }}>
        <span
          style={{ display: 'block', width: 130, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {onChangeValues(value).label}
        </span>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none', width: 186, marginTop: 5 }}>
        <OptionWrapper onClick={() => onClick(null)} style={{ minHeight: 41 }}>
          <Value style={{ fontWeight: 'bold', color: ODColors.CharcoalGrey }}>All Status</Value>
        </OptionWrapper>
        {dropdownMenus.map((menu, index) => {
          return (
            <OptionWrapper key={menu.label} onClick={() => onClick(menu.value)} style={{ minHeight: 41 }}>
              <Value>{menu.label}</Value>
            </OptionWrapper>
          )
        })}
      </DropDownContent>
    </DropDown>
  )
}
