import { computed, makeObservable, observable, runInAction } from 'mobx'
import { DOMetaDataInput } from './DOMetaDataInput'
import { MetaDataInputState } from './IDOMetaDataInput'
import { IMetaData } from './IMetaData'

export class DOMetaDataEditor {
  inputs: Array<DOMetaDataInput> = []

  constructor(existingData?: IMetaData[]) {
    this.setInputs(existingData)

    makeObservable(this, {
      inputs: observable,

      canAdd: computed,
      canSubmit: computed,
      dataForSubmit: computed,
    })
  }

  // edit page 에서 existingData 가 바로 들어오지 않기 때문에
  // setInputs 를 만들어 existingData 가 존재할 때 받아올 수 있도록 하기 위함
  setInputs(existingData?: IMetaData[]) {
    runInAction(() => {
      if (existingData && existingData.length > 0) {
        const inputs = existingData.map(item => {
          return new DOMetaDataInput(this, item)
        })
        this.inputs = [...inputs, new DOMetaDataInput(this)]
      } else {
        this.inputs = [new DOMetaDataInput(this)]
      }
    })
  }

  autoAddInput() {
    if (this.canAdd) {
      runInAction(() => {
        this.inputs = [...this.inputs, new DOMetaDataInput(this)]
      })
    }
  }

  autoRemoveInput() {
    if (this.inputs.length === 1) {
      return
    }
    runInAction(() => {
      this.inputs = this.inputs.filter(
        item => !!item.key || !!item.columnName || item.state === MetaDataInputState.Created
      )
    })
  }

  hasKeyAndColumnName(inputs: DOMetaDataInput[]) {
    const booleans = inputs.map(item => {
      if (item.key && item.columnName) {
        return true
      } else {
        return false
      }
    })
    return !booleans.includes(false)
  }

  get canAdd() {
    const lastInput = this.inputs[this.inputs.length - 1]
    if (!lastInput.key || !lastInput.columnName) {
      return false
    }

    return this.hasKeyAndColumnName([...this.inputs])
  }

  get canSubmit() {
    const lastIndex = this.inputs.length - 1
    const lastInput = this.inputs[lastIndex]

    const temp = [...this.inputs]
    temp.splice(lastIndex, 1)

    if (this.inputs.length === 1) {
      return true
    }

    const booleans = [this.hasKeyAndColumnName(temp)]

    if (!lastInput.key && !lastInput.columnName) {
      booleans.push(true)
    } else {
      booleans.push(false)
    }

    return !booleans.includes(false)
  }

  get dataForSubmit() {
    const temp = [...this.inputs]
    const lastIndex = temp.length - 1
    temp.splice(lastIndex, 1)

    const convertedTemp: IMetaData[] = temp.map(item => {
      return {
        key: item.key,
        columnName: item.columnName,
      }
    })

    return JSON.stringify(convertedTemp)
  }
}
