"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GQL_UPDATE_PROJECT_HAS_USER_WITH_RESOLVE = exports.GQL_UPDATE_WORKSET_HAS_USER_WITH_RESOLVE = exports.GQL_REMOVE_WORKSET_HAS_USER2 = exports.GQL_UPDATE_WORKSET_HAS_USER2 = exports.GQL_CREATE_PROJECT_HAS_WORKSET = exports.GQL_REMOVE_PROJECT_HAS_USER = exports.GQL_CREATE_PROJECT_HAS_USER = exports.GQL_LIST_USER_GROUP = exports.GQL_LIST_USERS_FOR_WORKSET_PERM = exports.GQL_CREATE_PROJECT = exports.GQL_UPDATE_WORKSET_HAS_USER = exports.GQL_CREATE_WORKSET_HAS_USER = exports.GQL_ADD_DIRECTORIES_TO_WORKSETS = exports.GQL_LIST_DIRECTORY_NODES = exports.USER_GROUP_FULL_SNAPSHOT = exports.ORG_FULL_SNAPSHOT = exports.USER_FULL_SNAPSHOT = exports.PROJECT_FULL_SNAPSHOT = void 0;

var createFields = function createFields(fieldArray) {
  return fieldArray.join('\n');
};

var COMMON_USER_FIELDS = ['userId', 'name', 'username', 'email', 'lastLoginToken', 'lastAccessTime', 'unread', 'priv', 'createdAt', 'updatedAt', 'mainOrgId'];
var COMMON_USER_GROUP_FIELDS = ['groupId', 'name', 'orgId'];
var COMMON_ORG_FIELDS = ['orgId', 'name', 'color', 'createdAt', 'updatedAt', 'supportEmail', 'deletedAt', 'willDeleteAt']; // Project

var COMMON_PROJECT_FIELDS = ['createdAt', 'updatedAt', 'projectId', 'name', 'type', 'ownerOrgId', 'deletedAt', 'numItems', 'myPerm'];
exports.PROJECT_FULL_SNAPSHOT = "\n  ".concat(createFields(COMMON_PROJECT_FIELDS), "\n");
exports.USER_FULL_SNAPSHOT = "\n  ".concat(createFields(COMMON_USER_FIELDS), "\n");
exports.ORG_FULL_SNAPSHOT = "\n  ".concat(createFields(COMMON_ORG_FIELDS), "\n");
exports.USER_GROUP_FULL_SNAPSHOT = "\n  ".concat(createFields(COMMON_USER_GROUP_FIELDS), "\n  members {\n    userId\n    name\n    email\n  }\n  worksets {\n    wsId\n    name\n  }\n");
var WORKSET_HAS_USER_FULL_SNAPSHOT = "\n  userId\n  permLevel\n  expireAt\n  user {\n    ".concat(exports.USER_FULL_SNAPSHOT, "\n  }\n");
exports.GQL_LIST_DIRECTORY_NODES = "\nquery listDirectoryNodes($data: SingleIDInput!) {\n  listDirectoryNodes(data: $data) {\n    path\n    name\n    depth\n    count\n    hasChild\n  }\n}";
exports.GQL_ADD_DIRECTORIES_TO_WORKSETS = "\nmutation addDirectoriesToWorkset($data: AddDirectoriesToWorksetInput!) {\n  addDirectoriesToWorkset(data: $data) {\n    ok\n  }\n}\n";
exports.GQL_CREATE_WORKSET_HAS_USER = "\nmutation createWorksetHasUser($data: WorksetHasUserPropsInput!) {\n  createWorksetHasUser(data: $data) {\n    ".concat(WORKSET_HAS_USER_FULL_SNAPSHOT, "\n  }\n}\n");
exports.GQL_UPDATE_WORKSET_HAS_USER = "\nmutation updateWorksetHasUser($data: WorksetHasUserPropsInput!) {\n  updateWorksetHasUser(data: $data) {\n    ".concat(WORKSET_HAS_USER_FULL_SNAPSHOT, "\n  }\n}\n");
exports.GQL_CREATE_PROJECT = "\n  mutation createProject($data: ProjectPropsInput!) {\n    createProject(data: $data) {\n      createdAt\n      projectId\n      name\n      type\n    }\n  }\n";
exports.GQL_LIST_USERS_FOR_WORKSET_PERM = "\nquery users($data: ListableUserInput!) {\n  listUser(data: $data) {\n    list {\n      ".concat(exports.USER_FULL_SNAPSHOT, "\n      organizations {\n        joinId\n        org {\n          ").concat(exports.ORG_FULL_SNAPSHOT, "\n        }\n        orgUserPriv\n      }\n    }\n    totalCount\n    page\n    pageSize\n  }\n}\n");
exports.GQL_LIST_USER_GROUP = "\nquery listUserGroup($data: ListableUserGroupInput!) {\n  listUserGroup(data: $data) {\n    list {\n      ".concat(exports.USER_GROUP_FULL_SNAPSHOT, "\n    }\n    totalCount\n    page\n    pageSize\n  }\n}\n");
exports.GQL_CREATE_PROJECT_HAS_USER = "\nmutation createProjectHasUser($data: ProjectHasUserCreationInput!) {\n  createProjectHasUser(data: $data) {\n    project {\n      projectId\n      name\n    }\n    isSuccess\n    targetUser {\n      ".concat(exports.USER_FULL_SNAPSHOT, "\n    }\n    messages {\n      workset {\n        wsId\n        name\n      }\n      beforeTagetWorksetPerm\n      afterTagetWorksetPerm\n      myWorksetPerm\n    }\n  }\n}\n");
exports.GQL_REMOVE_PROJECT_HAS_USER = "\nmutation removeProjectHasUser($data: ProjectHasUserRemoveInput!) {\n  removeProjectHasUser(data: $data) {\n    ok\n  }\n}\n";
exports.GQL_CREATE_PROJECT_HAS_WORKSET = "\nmutation createProjectHasWorkset($data: ProjectHasWorksetPropsInput!) {\n  createProjectHasWorkset(data: $data) {\n    isSuccess\n    workset {\n      wsId\n      name\n    }\n    messages {\n      user {\n        ".concat(exports.USER_FULL_SNAPSHOT, "\n      }\n      beforePermLevel\n      afterPermLevel\n      beforeProjectPerm\n      afterProjectPerm\n    }\n  }\n}\n");
exports.GQL_UPDATE_WORKSET_HAS_USER2 = "\nmutation updateWorksetHasUser2($data: WorksetHasUserPropsInput!) {\n  updateWorksetHasUser2(data: $data) {\n    isSuccess\n    message {\n      project {\n        ".concat(exports.PROJECT_FULL_SNAPSHOT, "\n      }\n      permLevel\n    }\n  }\n}\n");
exports.GQL_REMOVE_WORKSET_HAS_USER2 = "\nmutation removeWorksetHasUser2($data: DualIDInput!) {\n  removeWorksetHasUser2(data: $data) {\n    isSuccess\n    message {\n      project {\n        ".concat(exports.PROJECT_FULL_SNAPSHOT, "\n      }\n      permLevel\n    }\n  }\n}\n");
exports.GQL_UPDATE_WORKSET_HAS_USER_WITH_RESOLVE = "\nmutation updateWorksetHasUserWithResolve($data: WorksetHasUserUpdateWithResolveInput!) {\n  updateWorksetHasUserWithResolve(data: $data) {\n    ok\n  }\n}\n";
exports.GQL_UPDATE_PROJECT_HAS_USER_WITH_RESOLVE = "\nmutation updateProjectHasUserWithResolve($data: ProjectHasUserUpdateWithResolveInput!) {\n  updateProjectHasUserWithResolve(data: $data) {\n    ok\n  }\n}\n";