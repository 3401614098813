import {
  IODListableEventContext,
  ODButton,
  ODButtonTheme,
  ODIcon,
  ODIcons,
  ODListableContextType,
  ODListableDataLoadFunc,
  ODListableOption,
  ODListablePaginatedTable2,
  ODListablePagination,
  ODListableSearchBox,
  ODModal,
  ODModalBody,
  ODModalHeader,
  ODModalSize,
  useODListableContext,
} from '@odc/od-react-belt'
import { range } from 'lodash'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { CheckImg, CheckIndeterminateImg, UncheckImg } from '../../../assets/images'
import { Utils } from '../../../utils'

export interface IPickerTableModalOptions<T, O extends ODListableOption> {
  pickDefaultOptions?: Partial<IPickFuncOptions<T, O>>
  rounded?: boolean
  size?: ODModalSize
  dataLoader: ODListableDataLoadFunc<T, O>
  keyExtractor: (v: T) => number
  numColumns: number // 테이블에서 (체크박스 제외하고) 몇 개의 컬럼이 존재할 것인가?
  renderTH: (index: number) => ReactNode // <th></th> 사이에 포함될 컨텐츠 렌더링
  renderTD: (index: number, value: T) => ReactNode // <td></td> 사이에 포함될 컨텐츠 렌더링
  singleSelection?: boolean
  pageSpan?: number
  renderSearchFilter?: () => ReactNode
  renderSearchRightFilter?: (context: React.Context<ODListableContextType<T, O>>) => ReactNode
  addedItemExtractor?: (v: T) => boolean // 해당 아이템이 이미 추가 되었는지?
  viewerExtractor?: (v: T) => boolean // 해당 워크셋에서 나의 권한이 뷰어 권한인지? => 이름을 disabledItemExtractor 로 바꾸어야 할지도?
  hasCurrentPageToggle?: boolean // 현재 보고 있는 리스트의 아이템들을 모두 추가 또는 모두 해제
  renderActiveFilter?: (context: React.Context<ODListableContextType<T, O>>) => ReactNode
  renderConfirmButtonLeftText?: () => ReactNode
  disabledSubmit?: boolean
  removePick?: boolean
  removeConfirmButton?: boolean
  renderSelectCol?: (v: T, onClose: () => void) => ReactNode
}

export interface IPickFuncOptions<T, O extends ODListableOption> {
  title: string
  confirmButtonTitle: string
  showSearch?: boolean
  searchPlaceholder?: string
  selectedItems: (number | T)[] // returnFullValue = true 이면 T[] 되어야 함.
  returnFullValue?: boolean // true 이면 selected item 의 index 만 반환하는게 아니라, dataLoader 의 T 값의 어레이를 반환한다.
}

export interface IPickerTableModalProps<T, O extends ODListableOption> {
  title: string
  confirmButtonTitle: string
  isOpen: boolean
  setIsOpen: (open: boolean) => any
  size: ODModalSize
  rounded: boolean
  dataLoader: ODListableDataLoadFunc<T, O>
  showSearch: boolean
  searchPlaceholder: string
  selectedItems: (number | T)[] // returnFullValue = true 이면 T[] 되어야 함.
  setSelectedItems: (value: ((prevState: number[]) => number[]) | number[]) => void
  pageSpan?: number
}

export function useWorksetPickerTableModal<T, O extends ODListableOption>(options: IPickerTableModalOptions<T, O>) {
  const {
    pickDefaultOptions,
    size,
    rounded,
    keyExtractor,
    numColumns,
    renderTD,
    renderTH,
    dataLoader,
    singleSelection = false,
    pageSpan,
    renderSearchFilter,
    addedItemExtractor = (v: T) => false,
    viewerExtractor = (v: T) => false,
    hasCurrentPageToggle = false,
    renderSearchRightFilter,
    renderActiveFilter,
    renderConfirmButtonLeftText,
    disabledSubmit,
    removePick,
    removeConfirmButton,
    renderSelectCol,
  } = options

  const [isOpen, setIsOpen] = React.useState(false)
  const onClickYes = React.useRef<() => void>(() => undefined)
  const onClickNo = React.useRef<() => void>(() => undefined)
  const [title, setTitle] = React.useState('')
  const [confirmButtonTitle, setConfirmButtonTitle] = React.useState('Confirm')
  const [showSearch, setShowSearch] = React.useState(false)
  const [searchPlaceholder, setSearchPlaceholder] = React.useState('')
  const [selectedItemIds, setSelectedItemIds] = React.useState<number[]>([])
  const selectedItemIdsRef = React.useRef<number[]>([])
  const selectedItemsRef = React.useRef<T[]>([])
  const [, setReturnIdsOnly] = React.useState(true)
  const { Provider, Context } = useODListableContext<T, O>()

  const pick = React.useCallback((pickerOptions: Partial<IPickFuncOptions<T, O>> = {}): Promise<
    number[] | T[] | false
  > => {
    const {
      // tslint:disable-next-line:no-shadowed-variable
      title,
      // tslint:disable-next-line:no-shadowed-variable
      confirmButtonTitle = 'Confirm',
      // tslint:disable-next-line:no-shadowed-variable
      showSearch = false,
      // tslint:disable-next-line:no-shadowed-variable
      searchPlaceholder = '',
      // tslint:disable-next-line:no-shadowed-variable
      selectedItems = [],
      // tslint:disable-next-line:no-shadowed-variable
      returnFullValue = false,
    } = {
      ...pickerOptions,
      ...pickDefaultOptions,
    }

    setTitle(title ?? '-')
    setConfirmButtonTitle(confirmButtonTitle)
    setShowSearch(showSearch)
    setSearchPlaceholder(searchPlaceholder)
    if (returnFullValue) {
      setSelectedItemIds(!singleSelection ? selectedItems.map((v: any) => keyExtractor(v)) || [] : [])
      selectedItemsRef.current = selectedItems as T[]
    } else {
      // @ts-ignore
      setSelectedItemIds(!singleSelection ? selectedItems || [] : [])
    }
    setReturnIdsOnly(!returnFullValue)

    return new Promise(resolve => {
      onClickYes.current = () => {
        setIsOpen(false)
        if (returnFullValue) {
          resolve(selectedItemsRef.current)
        } else {
          resolve(selectedItemIdsRef.current)
        }
      }
      onClickNo.current = () => {
        setIsOpen(false)
        resolve(false)
      }
      setIsOpen(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [Component] = React.useState(() => {
    return (modalProps: IPickerTableModalProps<T, O>) => {
      // tslint:disable-next-line:no-shadowed-variable
      const { selectedItems, setSelectedItems, pageSpan } = modalProps

      const toggleItem = (value: T) => {
        if (removePick) {
          return
        }
        const key = keyExtractor(value)

        if (singleSelection) {
          setSelectedItems([key])
          selectedItemsRef.current = [value]
        } else {
          setSelectedItems(arr => {
            const index = arr.indexOf(key)
            if (index >= 0) {
              arr.splice(index, 1)
              return [...arr]
            }
            arr.push(key)
            return [...arr]
          })

          const ref = selectedItemsRef.current
          const indexFound = ref.findIndex(v => keyExtractor(v) === key)
          if (indexFound >= 0) {
            ref.splice(indexFound, 1)
          } else {
            ref.push(value)
          }
        }
      }

      const toggleCurrentPageItems = (values: T[], isCheckedAll: boolean) => {
        if (removePick) {
          return
        }
        values.forEach(value => {
          const key = keyExtractor(value)
          setSelectedItems(arr => {
            const index = arr.indexOf(key)
            if (index >= 0) {
              if (isCheckedAll) {
                arr.splice(index, 1)
              }
              return [...arr]
            }
            arr.push(key)
            return [...arr]
          })

          const ref = selectedItemsRef.current
          const indexFound = ref.findIndex(v => keyExtractor(v) === key)
          if (indexFound >= 0) {
            if (isCheckedAll) {
              ref.splice(indexFound, 1)
            }
          } else {
            ref.push(value)
          }
        })
      }

      const isSelected = (key: number) => {
        return selectedItems.indexOf(key) >= 0
      }

      const toolbarStyle = { display: 'flex' }
      return (
        <ODModal isOpen={modalProps.isOpen} toggle={() => onClickNo.current()} size={size} rounded={rounded}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ODModalHeader title={modalProps.title} onClose={() => onClickNo.current()} headerBottomPadding={30}>
              <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 60 }} />
            </ODModalHeader>
            <ODModalBody style={{ paddingBottom: 20 }}>
              <Provider
                dataLoader={modalProps.dataLoader}
                keyExtractor={v => keyExtractor(v).toString()}
                pageSize={10}
                onDataLoaderError={Utils.showError}
                searchOnLoad
              >
                <div style={toolbarStyle}>
                  {!!renderSearchFilter && renderSearchFilter()}
                  {modalProps.showSearch && (
                    <ODListableSearchBox
                      listableContext={Context}
                      placeholder={modalProps.searchPlaceholder}
                      style={{ flexGrow: 6 }}
                    />
                  )}
                  {!!renderSearchRightFilter && renderSearchRightFilter(Context)}
                </div>
                {!!renderActiveFilter && (
                  <div style={{ ...toolbarStyle, marginBottom: 5 }}>{renderActiveFilter(Context)}</div>
                )}
                <ODListablePaginatedTable2
                  numColumns={numColumns + 1}
                  listableContext={Context}
                  renderHeader={() => (
                    <tr>
                      {hasCurrentPageToggle && (
                        <th style={{}}>
                          <CurrentPageToggleBox
                            listableContext={Context}
                            onClick={toggleCurrentPageItems}
                            keyExtractor={keyExtractor}
                            addedItemExtractor={addedItemExtractor}
                            viewerExtractor={viewerExtractor}
                            selectedItemIds={selectedItemIdsRef.current}
                          />
                        </th>
                      )}
                      {!hasCurrentPageToggle && !removePick && <th style={{ width: 100 }} />}
                      {range(0, numColumns).map(index => (
                        <th key={index}>{renderTH(index)}</th>
                      ))}
                      {!!renderSelectCol && <th>Action</th>}
                    </tr>
                  )}
                  renderRow={(value: T) => {
                    const key = keyExtractor(value)
                    const isAdded = addedItemExtractor(value)
                    const isViewer = viewerExtractor(value)

                    return (
                      <TableTR
                        key={key}
                        isAdded={isAdded}
                        isViewer={!isAdded && isViewer}
                        isSelected={isSelected(key)}
                        onClick={() => !isAdded && !isViewer && toggleItem(value)}
                      >
                        {!removePick && (
                          <td
                            style={
                              hasCurrentPageToggle
                                ? {}
                                : { width: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }
                            }
                          >
                            {isAdded && <AddedItemTextBox style={{ borderRadius: 4 }}>Added</AddedItemTextBox>}
                            {!isAdded && isViewer && (
                              <input
                                style={{ marginTop: 3, cursor: 'pointer' }}
                                type="checkbox"
                                disabled={true}
                                onChange={() => {}}
                              />
                            )}
                            {!isAdded && !isViewer && (
                              <>
                                {hasCurrentPageToggle && isSelected(key) && (
                                  <img
                                    style={{ position: 'relative', top: 5, width: 12, height: 12 }}
                                    src={CheckImg}
                                    alt=""
                                  />
                                )}
                                {hasCurrentPageToggle && !isSelected(key) && (
                                  <img
                                    style={{ position: 'relative', top: 5, width: 12, height: 12 }}
                                    src={UncheckImg}
                                    alt=""
                                  />
                                )}
                                {!hasCurrentPageToggle && (
                                  <input
                                    style={{ marginTop: 3, cursor: 'pointer' }}
                                    type="checkbox"
                                    value={isSelected(key) ? 1 : 0}
                                    checked={isSelected(key)}
                                    onChange={() => {}}
                                  />
                                )}
                              </>
                            )}
                          </td>
                        )}
                        {range(0, numColumns).map(index => (
                          <td key={index}>{renderTD(index, value)}</td>
                        ))}
                        {!!renderSelectCol && renderSelectCol(value, () => setIsOpen(false))}
                      </TableTR>
                    )
                  }}
                  eventParentContext={{}}
                />
                <BottomWrapper>
                  <div style={{ flexGrow: 8 }}>
                    <ODListablePagination hideIfSinglePage={false} listableContext={Context} pageSpan={pageSpan} />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {renderConfirmButtonLeftText && renderConfirmButtonLeftText()}
                    {!removeConfirmButton && (
                      <ODButton
                        type="button"
                        style={{
                          float: 'right',
                          width: 176,
                          height: 35,
                          insetTop: 10,
                          borderRadius: 3,
                        }}
                        theme={ODButtonTheme.Primary}
                        onClick={() => {
                          if (disabledSubmit && selectedItemIdsRef.current.length === 0) {
                            return
                          } else {
                            onClickYes.current()
                          }
                        }}
                      >
                        {modalProps.confirmButtonTitle}
                      </ODButton>
                    )}
                  </div>
                </BottomWrapper>
              </Provider>
            </ODModalBody>
          </div>
        </ODModal>
      )
    }
  })

  selectedItemIdsRef.current = selectedItemIds

  const props: IPickerTableModalProps<T, O> = {
    isOpen,
    setIsOpen: (v: boolean) => {
      setIsOpen(v)
    },
    dataLoader: dataLoader!,
    title,
    confirmButtonTitle,
    size: ODModalSize.Normal,
    rounded: false,
    searchPlaceholder,
    showSearch,
    selectedItems: selectedItemIds,
    setSelectedItems: setSelectedItemIds,
    pageSpan,
  }

  return { Component, pick, props }
}

interface ICurrentPageToggleBox<T, O extends ODListableOption, ParentContextType extends IODListableEventContext<T>> {
  listableContext: React.Context<ODListableContextType<T, O>>
  selectedItemIds: number[]
  keyExtractor: (v: T) => number
  onClick: (values: T[], isCheckedAll: boolean) => void
  addedItemExtractor: (v: T) => boolean
  viewerExtractor: (v: T) => boolean
}

export enum CHECKED_STATE {
  NONE = 'NONE',
  NOT_ALL = 'NOT_ALL',
  ALL = 'ALL',
}

export function CurrentPageToggleBox<
  T,
  O extends ODListableOption,
  ParentContextType extends IODListableEventContext<T>
>(props: ICurrentPageToggleBox<T, O, ParentContextType>) {
  const { listableContext, onClick, selectedItemIds, keyExtractor, addedItemExtractor, viewerExtractor } = props
  const [checkedState, setCheckedState] = React.useState<CHECKED_STATE>(CHECKED_STATE.ALL)

  const { state }: ODListableContextType<T, O> = React.useContext(listableContext)
  const result = state.list.filter(item => selectedItemIds.includes(keyExtractor(item)))
  const canCheckList = state.list.filter(item => !addedItemExtractor(item)).filter(item => !viewerExtractor(item))

  React.useEffect(() => {
    if (result.length === 0) {
      setCheckedState(CHECKED_STATE.NONE)
      return
    }

    if (result.length < canCheckList.length) {
      setCheckedState(CHECKED_STATE.NOT_ALL)
      return
    }

    if (result.length === canCheckList.length) {
      setCheckedState(CHECKED_STATE.ALL)
      return
    }
  }, [state.list, selectedItemIds, canCheckList.length, result.length])

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => onClick(canCheckList, checkedState === CHECKED_STATE.ALL)}>
      {checkedState === CHECKED_STATE.ALL && <CheckBoxImg src={CheckImg} alt="" />}
      {checkedState === CHECKED_STATE.NOT_ALL && <CheckBoxImg src={CheckIndeterminateImg} alt="" />}
      {checkedState === CHECKED_STATE.NONE && <CheckBoxImg src={UncheckImg} alt="" />}
    </div>
  )
}

const CheckBoxImg = styled.img`
  position: relative;
  bottom: 3px;
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  height: 12px;
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

// @ts-ignore
const TableTR = styled.tr`
  cursor: pointer;

  ${(p: { isAdded?: boolean; isViewer?: boolean; isSelected?: boolean }) => {
    if (p.isAdded) {
      return css`
        background-color: #f0f3f5;

        :hover {
          background-color: #f0f3f5 !important;
        }
      `
    } else if (p.isViewer) {
      return css``
    } else if (p.isSelected) {
      return css`
        background-color: #e9f7f9 !important;
      `
    } else {
      return css`
        :hover {
          background-color: #e9f7f9 !important;
        }
      `
    }
  }}
`

const AddedItemTextBox = styled.div`
  width: 40px;
  height: 16px;
  background-color: #73818f;
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  line-height: 16px;
`
