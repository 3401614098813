import moment from 'moment'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { GQLStatDaily, GQLStatDateRange, GQLSystemStat } from '../../@types/server'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { ODDashboardCardWithGraph } from '../../components/ODDashboardCard/ODDashboardCardWithGraph'
import { ODChartData, ODChartDesignType } from '../../components/ODDashboardCard/ODDashboardChartOptions'
import { ODDashboardCardTheme } from '../../components/ODDashboardCard/ODDashboardCommon'
import { ODIcon, ODIcons } from '../../components/ODIcon'
import { useODAppContext } from '../../context/ODAppContext'
import { useODQuery2 } from '../../context/ODCommon'
import { STAT_DAILY_FULL_SNAPSHOT } from '../../gqls'

const GQL_DASHBOARD = `
query getDashboard($data: StatDateRange!) {
  getAdminStatDailyInRange(data: $data) {
    ${STAT_DAILY_FULL_SNAPSHOT}
  }
  systemStat {
    numResearchers
    numTCFFiles
    numOrganizations
    numWorksets
    numAgents
    numTags
  }
}
`

type DashboardResponse = {
  getAdminStatDailyInRange: Array<GQLStatDaily>
  systemStat: GQLSystemStat
}

const createDataset = (
  response: DashboardResponse,
  key: keyof GQLStatDaily,
  label: string,
  backgroundColor: string,
  borderColor: string
): ODChartData => {
  return {
    labels: response.getAdminStatDailyInRange.map(v => v.dateToken),
    datasets: [
      {
        label,
        backgroundColor,
        borderColor,
        data: response.getAdminStatDailyInRange.map(v => v[key] as number),
      },
    ],
  }
}

export const DashboardContainer: React.FC = () => {
  const { appOptions } = useODAppContext()
  const [variables] = React.useState<GQLStatDateRange>(() => {
    const now = moment()
    const end = now.format('YYYY-MM-DD')
    const start = now.add(-6, 'day').format('YYYY-MM-DD')
    return { start, end }
  })

  const { data, loading } = useODQuery2<GQLStatDateRange, DashboardResponse>(GQL_DASHBOARD, {
    simulateDelay: appOptions?.SIMULATE_DELAY,
    variables,
  })

  if (!data) {
    return <BlockingLoadBox show={loading} />
  }

  return (
    <Container fluid>
      <BlockingLoadBox show={loading} />
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.Doctor} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Researchers"
              value={data?.systemStat?.numResearchers.toString() || '-'}
              chartData={createDataset(data, 'uniqueVisit', 'Unique visits', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreBuilding} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Agents"
              value={data?.systemStat?.numAgents.toString() || '-'}
              chartData={createDataset(data, 'agent', 'New Agent', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreViewModule} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Worksets"
              value={data?.systemStat?.numWorksets.toString() || '-'}
              chartData={createDataset(data, 'workset', 'New Workset', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreList} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All TCF Files"
              value={data?.systemStat?.numTCFFiles.toString() || '-'}
              chartData={createDataset(data, 'tcfUpload', 'New TCF Uploads', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreTags} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Tags"
              value={data?.systemStat?.numTags.toString() || '-'}
              chartData={{
                labels: ['-'],
                datasets: [
                  {
                    label: '-',
                    backgroundColor: '#20A8D8',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: [],
                  },
                ],
              }}
            />
          </Col>
        </Row>
      </div>
    </Container>
  )
}
