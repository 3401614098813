import classNames from 'classnames'
import * as React from 'react'
import { Modal } from 'reactstrap'
import styled from 'styled-components'
import { GQLAttachmentType } from '../../@types/server'
import ArrowImage from '../../assets/img/dropdown.png'
import { ThumbnailInfo } from '../../containers/User/DetailContainerComponent/convertItemAttachmentToThumbnailInfo'
import { ODIcon, ODIcons } from '../ODIcon'

export enum ODModalTheme {
  Primary = 'Primary',
}

export enum ODModalSize {
  Small = 'Small',
  Normal = 'Normal',
}

type MouseMoveType = {
  x: number
  y: number
}

export type AttachmentEditData = {
  fileName: string
  attachmentTypeId: number
  description: string
}

type ODModalProps = {
  theme?: ODModalTheme
  size?: ODModalSize
  fade?: boolean
  mouseMove?: MouseMoveType
  attachmentTypes: Array<GQLAttachmentType>
  initialValue?: ThumbnailInfo
  onSave: (changed: AttachmentEditData) => void
  onDismiss: () => void
  isUpload: boolean
  previewImage?: string
  imageFile?: File | null
}

const CustomModal = styled(Modal)`
  position: fixed;
  top: ${p => (!p.isUpload ? `${p.mousemove.y - 200}px` : '50%')};
  right: ${p => (!p.isUpload ? `${p.mousemove.x - 900}px` : 0)};
  left: ${p => (p.isUpload ? '50%' : 0)};
  transform: ${p => (p.isUpload ? 'translate(-50%, -50%)!important' : 'none')};
  bottom: 0;
`

const Wrapper = styled.div`
  display: flex;
  padding: 15px 22px 20px 22px;
  flex-direction: column;
`
const Title = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #73818f;
`
const Key = styled.span`
  width: 70px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2f353a;
  margin-right: 36px;
`
const InputBox = styled.input`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`
const TextArea = styled.textarea`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
  height: 152px;
  resize: none;
`
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const SaveButton = styled.div`
  height: 35px;
  background-color: #29b6ca;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 8px;
  cursor: pointer;
`
const SaveButtonText = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`
const SelectBox = styled.select`
  display: flex;
  flex: 1;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${ArrowImage}) no-repeat 97% 50%;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`
const Line = styled.div`
  display: flex;
  width: 100%;
  height: 0.5px;
  background-color: #acb4bc;
`

export function EditAttachmentModal(options: ODModalProps) {
  const {
    fade = true,
    size = ODModalSize.Normal,
    mouseMove,
    attachmentTypes,
    initialValue,
    onSave,
    onDismiss,
    isUpload,
    previewImage,
    imageFile,
  } = options

  const [fileName, setFileName] = React.useState(initialValue?.fileName || '')
  const [description, setDescription] = React.useState(initialValue?.description || '')
  const [attachmentTypeId, setAttachmentTypeId] = React.useState<number>(
    initialValue?.attachmentType?.typeId || attachmentTypes[0]?.typeId || 0
  )
  const [isError, setIsError] = React.useState<boolean>(false)

  const fileNameFocus = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    fileNameFocus.current && fileNameFocus.current.focus()
  }, [])

  React.useEffect(() => {
    if (imageFile) {
      setFileName(imageFile.name)
    }
  }, [imageFile])

  const sizeCls = { 'modal-sm': size === ODModalSize.Small }

  const handleSave = () => {
    if (attachmentTypeId === 0) {
      alert('Sorry, attachment type is invalid. Please select again.')
      return
    }
    onSave({ fileName, attachmentTypeId, description })
  }

  return (
    <CustomModal
      fade={fade}
      isOpen
      toggle={onDismiss}
      className={classNames('modal-primary', 'modal-rect', sizeCls)}
      mousemove={mouseMove}
      isUpload={isUpload}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Wrapper>
          <Row style={{ marginBottom: 11 }}>
            <Title>{isUpload ? 'Attachment' : 'Edit Attachment'}</Title>
            <ODIcon
              icon={ODIcons.CoreX}
              style={{ fontSize: 18, color: '#bdbdbd', cursor: 'pointer' }}
              onClick={() => onDismiss()}
            />
          </Row>
          {isUpload && !isError ? (
            <Row>
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <img
                  src={previewImage}
                  style={{ width: 79, height: 79 }}
                  alt="Preview"
                  onError={() => setIsError(true)}
                  onLoad={() => setIsError(false)}
                />
                <Line style={{ marginTop: 13, marginBottom: 23 }} />
              </div>
            </Row>
          ) : null}
          {/*{isUpload && isError ? (*/}
          {/*  <Row>*/}
          {/*    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>*/}
          {/*      <img src="https://picsum.photos/79" style={{ width: 79, height: 79 }} alt="Preview" />*/}
          {/*      <Line style={{ marginTop: 13, marginBottom: 23 }} />*/}
          {/*    </div>*/}
          {/*  </Row>*/}
          {/*) : null}*/}
          <Row style={{ marginBottom: 10, marginTop: isUpload && isError ? 10 : 0 }}>
            <Key>FileName</Key>
            <InputBox type="text" value={fileName} onChange={v => setFileName(v.target.value)} ref={fileNameFocus} />
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Key>Type</Key>
            <SelectBox
              placeholder="Select.."
              value={attachmentTypeId}
              onChange={v => setAttachmentTypeId(parseInt(v.target.value, 10))}
            >
              {attachmentTypes.map(v => {
                return (
                  <option key={v.typeId} value={v.typeId.toString()}>
                    {v.name}
                  </option>
                )
              })}
            </SelectBox>
          </Row>
          <Row style={{ marginBottom: 20, alignItems: 'flex-start' }}>
            <Key style={{ marginTop: 5 }}>Description</Key>
            <TextArea
              placeholder="Add Description"
              value={description}
              onChange={v => setDescription(v.target.value)}
            />
          </Row>
          <SaveButton onClick={() => handleSave()}>
            <SaveButtonText>Save</SaveButtonText>
          </SaveButton>
        </Wrapper>
      </div>
    </CustomModal>
  )
}
