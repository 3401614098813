import React from 'react'
import styled from 'styled-components'
import { Utils } from '../../utils'
import { ODButton, ODButtonTheme } from '../ODButton'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`
const CustomTextArea = styled.textarea`
  width: 100%;
  height: 197px;
  border-radius: 4px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  resize: none;
  margin-top: 9px;
  margin-bottom: 6px;
  padding-top: 10px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`
const WarningText = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #f87170; // #73818f;
`

type ODModalExplanationInBulkProps = {
  onClose: () => void
  onSave: (v: { explanation: string }) => Promise<void>
  title: string
}

export const ODModalExplanationInBulk: React.FC<ODModalExplanationInBulkProps> = props => {
  const { onClose, onSave, title } = props

  const [explanation, setExplanation] = React.useState<string>('')

  const handleModalClose = async (v: { explanation: string }) => {
    if (!v.explanation) {
      Utils.showError('설명을 입력해주세요.')
      return
    }

    await onSave({ explanation: v.explanation })
    onClose()
  }

  return (
    <ODModal isOpen toggle={() => null}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={title} onClose={onClose} />
        <ODModalBody>
          <Title>Explanation</Title>
          <CustomTextArea value={explanation} onChange={e => setExplanation(e.target.value)} />
          <WarningText>Replace all explanation</WarningText>
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={() => handleModalClose({ explanation })}>
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
