import { ODListableOption } from '@odc/od-react-belt'
import { GQLAppConfig } from '../../../@types/server'

export interface IAppConfigListableOption extends ODListableOption {
}

export const AppConfigPrimaryKey = 'configId'

// modifiable fields only.
export interface ICTAppConfig extends Pick<GQLAppConfig, 'agentManualLink'> {
  // more fields, mapped (if needed)
}
