import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { ReactNodeLike } from 'prop-types'
import React from 'react'
import { InputAdapter, TextMask } from 'react-text-mask-hoc'
import { Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'
import { InputType } from 'reactstrap/lib/Input'
import { ODIcon, ODIcons } from '../../components/ODIcon'

export type ODEntityInputProps<C> = {
  required?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  useValidChecker?: boolean
  name: string
  label: string
  placeholder: string
  inputType?: InputType
  autoComplete?: string
  icon?: ODIcons
  iconAppend?: boolean // 뒤에 붙일 것인가?
  children?: ReactNodeLike
  prependChildren?: boolean
  appendChildren?: boolean
  mask?: Array<string | RegExp>
  inputProps?: object
  iconProps?: object
  options?: ReactNodeLike
}

type InputWithIconProps = {
  icon?: ODIcons
  iconAppend?: boolean // 뒤에 붙일 것인가?
  iconProps?: object
}

export const InputWithIcon: React.FC<InputWithIconProps> = props => {
  const { children, icon, iconAppend, iconProps = {} } = props

  if (icon) {
    const IconElement = (
      <InputGroupAddon addonType={iconAppend ? 'append' : 'prepend'}>
        <InputGroupText>
          <ODIcon icon={icon} {...iconProps} />
        </InputGroupText>
      </InputGroupAddon>
    )

    return (
      <InputGroup>
        {!iconAppend && IconElement}
        {children}
        {iconAppend && IconElement}
      </InputGroup>
    )
  }
  return <>{children}</>
}

export function ODEntityInput<C>(props: ODEntityInputProps<C>) {
  const {
    name,
    label,
    placeholder,
    inputType = 'text',
    autoComplete,
    icon,
    iconAppend,
    required,
    readOnly,
    autoFocus,
    useValidChecker,
    appendChildren,
    prependChildren,
    children,
    mask,
    inputProps = {},
    iconProps = {},
    options,
  } = props
  const formikContext = useFormikContext()

  if (!formikContext) {
    return null
  }

  const { errors, touched, handleChange, handleBlur, values, isSubmitting } = formikContext

  // @ts-ignore
  const value = values[name]
  // @ts-ignore
  const error = errors[name]
  // @ts-ignore
  const touchedElement = !!touched[name]

  const inputItem = (() => {
    if (mask) {
      return (
        <InputWithIcon icon={icon} iconAppend={iconAppend} iconProps={iconProps}>
          <TextMask
            id={`${name}-input`}
            name={name}
            mask={mask}
            Component={InputAdapter}
            className={classNames('form-control', { 'is-invalid': touchedElement && !!error })}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            disabled={isSubmitting}
            {...inputProps}
          />
          {error && <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>}
        </InputWithIcon>
      )
    }

    return (
      <InputWithIcon icon={icon} iconAppend={iconAppend} iconProps={iconProps}>
        <Input
          readOnly={readOnly}
          type={inputType}
          id={`${name}-input`}
          name={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          valid={useValidChecker && touchedElement && !error}
          invalid={touchedElement && !!error}
          autoFocus={!!autoFocus}
          required={!!required}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled={isSubmitting}
          {...inputProps}
        >
          {options}
        </Input>
        {error && <FormFeedback>{error}</FormFeedback>}
      </InputWithIcon>
    )
  })()

  return (
    <FormGroup row>
      <Col md="3">
        <Label htmlFor={`${name}-input`} style={{ paddingTop: 7 }}>
          {label}
        </Label>
      </Col>
      <Col xs="12" md="9">
        {children && (
          <div style={{ display: 'flex', width: '100%' }}>
            {prependChildren && children}
            {inputItem}
            {appendChildren && children}
          </div>
        )}
        {!children && inputItem}
      </Col>
    </FormGroup>
  )
}
