import { ODHSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { DOMetaDataEditor } from '../../../containers/User/Project/store/metadata/DOMetaDataEditor'
import { IMetaData } from '../../../containers/User/Project/store/metadata/IMetaData'
import { ODColors } from '../../../global-styles'
import { ODButton, ODButtonSize, ODButtonTheme } from '../../ODButton'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalFooter } from '../../ODModal/ODModalFooter'
import { ODModalHeader } from '../../ODModal/ODModalHeader'
import { ODToastType, showODToast } from '../../ODToast'

interface IODModalEditMetadata {
  isOpen: boolean
  onClose: () => void
  onSave: (rawData: string) => void
  existingMetaData: IMetaData[]
}

export const ODModalEditMetadata: React.FC<IODModalEditMetadata> = observer(props => {
  const { isOpen, onClose, existingMetaData, onSave } = props
  const [metaDataEditor] = React.useState(() => {
    return new DOMetaDataEditor(existingMetaData)
  })

  const modalTitle = 'Edit Metadata'

  const handleSubmit = () => {
    if (!metaDataEditor.canSubmit) {
      showODToast(ODToastType.Error, ODToastType.Error, 'Key and column name must be entered in pairs.')
      return
    }
    onSave(metaDataEditor.dataForSubmit)
    console.log(metaDataEditor.dataForSubmit)
  }

  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal} style={{ borderRadius: 4, maxWidth: 422 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={modalTitle} onClose={onClose} />
        <ODModalBody style={{ paddingLeft: 37, paddingRight: 37 }}>
          <Row style={{ marginBottom: 9 }}>
            <Label>Key</Label>
            <ODHSpace w={8} />
            <Label>Column name</Label>
          </Row>
          {metaDataEditor.inputs.map((item, index) => {
            return (
              <div style={{ display: 'flex', marginBottom: 10 }} key={index}>
                <Input
                  type="text"
                  placeholder="Key"
                  value={item.key}
                  onChange={e => item.setKey(e.target.value)}
                  style={{ flex: 1 }}
                />
                <ODHSpace w={8} />
                <Input
                  type="text"
                  placeholder="Column name"
                  value={item.columnName}
                  onChange={e => item.setColumnName(e.target.value)}
                  style={{ flex: 1 }}
                />
              </div>
            )
          })}
        </ODModalBody>
        <ODModalFooter>
          <ODButton theme={ODButtonTheme.Primary} fullWidth size={ODButtonSize.Normal} onClick={handleSubmit}>
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
})

const Row = styled.div`
  display: flex;
`

const Label = styled.div`
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  color: ${ODColors.Steel};
`
