import { DEFAULT_ROLES_PER_ORG_PRIV, ORG_ROLE, ORG_USER_PRIV_TO_STRING, PermUtils } from '@cils/common'
import React from 'react'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { GQLOrgHasUser } from '../../../../@types/server'

interface IOrgPermissionTableProps {
  isEditing: boolean
  orgHasUser: GQLOrgHasUser
  permUtils: PermUtils
  onPermChanged: () => void
}

const PermissionColumn = styled.td`
  width: 250px;
  font-size: 12px;
  color: #73818f;
`

const DefaultPermissionColumn = styled.td`
  min-width: 160px;
  font-size: 12px;
  text-align: center;
`

const EditColumn = styled.td`
  width: 70px;
`

const PermissionName = styled.div`
  margin-top: 0;
  margin-bottom: 6px;
  height: 15px;
`

const OrgPrivName = styled.span`
  font-size: 14px;
  color: var(--primary);
`

const EditColumnRow = styled.div`
  height: 15px;
  max-height: 15px;
  margin-bottom: 6px;
  text-align: center;
`

const ResetColumn = styled.td`
  width: 120px;
`

const ResetColumnRow = styled.div`
  height: 15px;
  font-size: 12px;
  max-height: 15px;
  margin-bottom: 6px;
  text-align: center;
`

const CategoryName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #73818f;
`

const PermYes = () => <PermissionName style={{ color: '#4dbd74', height: 15 }}>O</PermissionName>
const PermNo = () => <PermissionName style={{ color: '#f86c6b', height: 15 }}>X</PermissionName>

const DefaultPermRow = (props: { perm: PermUtils; role: ORG_ROLE }) => {
  const { perm, role } = props
  const isOn = DEFAULT_ROLES_PER_ORG_PRIV[perm.userPriv][role]
  return isOn ? <PermYes /> : <PermNo />
}

const CurrentPermRow = (props: { perm: PermUtils; role: ORG_ROLE }) => {
  const { perm, role } = props
  const isOn = perm.isAllowed(role)
  return isOn ? <PermYes /> : <PermNo />
}

const PermEditCheckbox = (props: { perm: PermUtils; role: ORG_ROLE; onPermChanged: () => void }) => {
  const { perm, onPermChanged, role } = props
  const isOn = perm.isAllowed(props.role)

  return (
    <EditColumnRow>
      <input
        type="checkbox"
        id={role.toString()}
        name="radios"
        value={isOn ? 1 : 0}
        checked={isOn}
        onChange={() => {
          perm.useOverride(role, true, !isOn)
          onPermChanged()
        }}
      />
    </EditColumnRow>
  )
}

const ResetLink = styled.span`
  font-size: 12px;
  color: var(--primary);
  cursor: pointer;
`

const PermResetBox = (props: { perm: PermUtils; role: ORG_ROLE; onPermChanged: () => void }) => {
  const { perm, role, onPermChanged } = props
  const isOn = perm.isOverridden(role)

  return (
    <ResetColumnRow>
      {isOn && (
        <ResetLink
          onClick={() => {
            perm.useOverride(role, false)
            onPermChanged()
          }}
        >
          Reset to default
        </ResetLink>
      )}
    </ResetColumnRow>
  )
}

const PERM_MAP = [
  {
    category: 'Admin Organization',
    permissions: [
      { name: 'Access Organization Admin Console', role: ORG_ROLE.ACCESS_ORG_ADMIN_CONSOLE },
      { name: 'Manage Organization', role: ORG_ROLE.EDIT_ORG },
      { name: 'Manage Organization Category', role: ORG_ROLE.EDIT_ORG_CATEGORY },
      { name: 'Create Organization Category', role: ORG_ROLE.CREATE_ORG_CATEGORY },
      { name: 'View Alerts', role: ORG_ROLE.VIEW_ALERT_LOGS },
      { name: 'View Audit Logs', role: ORG_ROLE.VIEW_AUDIT_LOGS },
    ],
  },
  {
    category: 'Admin User',
    permissions: [
      { name: 'Manage User Permission', role: ORG_ROLE.EDIT_USER_PERM },
      { name: 'Create User', role: ORG_ROLE.CREATE_USER },
    ],
  },
  {
    category: 'Admin Agent',
    permissions: [
      { name: 'View Agent', role: ORG_ROLE.VIEW_AGENT },
      { name: 'Manage Agent', role: ORG_ROLE.EDIT_AGENT },
      { name: 'Create Agent', role: ORG_ROLE.CREATE_AGENT },
    ],
  },
  {
    category: 'Admin Data',
    permissions: [
      { name: 'View Organization Data', role: ORG_ROLE.VIEW_ORG_DATA },
      { name: 'Manage Organization Data', role: ORG_ROLE.EDIT_ORG_DATA },
    ],
  },
  {
    category: 'Admin Workset',
    permissions: [
      { name: 'View Organization Workset', role: ORG_ROLE.VIEW_ORG_WORKSET },
      { name: 'Manage Organization Workset', role: ORG_ROLE.EDIT_ORG_WORKSET },
    ],
  },
  {
    category: 'Admin Project',
    permissions: [
      { name: 'View Organization Project', role: ORG_ROLE.VIEW_ORG_PROJECT },
      { name: 'Manage Organization Project', role: ORG_ROLE.EDIT_ORG_PROJECT },
    ],
  },
  {
    category: 'Organization',
    permissions: [
      { name: 'View Organization Category', role: ORG_ROLE.VIEW_ORG_CATEGORY },
      { name: 'View Organization Tag', role: ORG_ROLE.VIEW_ORG_TAG },
    ],
  },
  {
    category: 'Workset',
    permissions: [{ name: 'Create Workset', role: ORG_ROLE.CREATE_WORKSET }],
  },
  {
    category: 'Project',
    permissions: [{ name: 'Create Project', role: ORG_ROLE.CREATE_PROJECT }],
  },
]

export const OrgPermissionTable: React.FC<IOrgPermissionTableProps> = props => {
  const { isEditing, orgHasUser, permUtils, onPermChanged } = props
  // @ts-ignore
  const roleName = ORG_USER_PRIV_TO_STRING[orgHasUser.orgUserPriv]

  return (
    <Table striped responsive style={{ borderBottom: '1px solid #c8ced3' }}>
      <thead>
        <tr>
          <th style={{ verticalAlign: 'top' }}>Category</th>
          <th style={{ verticalAlign: 'top' }}>Permission</th>
          <th style={{ textAlign: 'center', verticalAlign: 'top' }}>
            <OrgPrivName>[{roleName}]</OrgPrivName> Default
          </th>
          {!isEditing && <th style={{ textAlign: 'center' }}>Current</th>}
          {isEditing && <th style={{ textAlign: 'center' }}>Override</th>}
          {isEditing && <th style={{ textAlign: 'center' }}>Reset</th>}
        </tr>
      </thead>
      <tbody>
        {PERM_MAP.map(permRow => {
          const { category, permissions } = permRow
          return (
            <tr key={category}>
              <td>
                <CategoryName>{category}</CategoryName>
              </td>
              <PermissionColumn>
                {permissions.map(p => (
                  <PermissionName key={p.name}>{p.name}</PermissionName>
                ))}
              </PermissionColumn>
              <DefaultPermissionColumn>
                {permissions.map(p => (
                  <DefaultPermRow key={p.name} perm={permUtils} role={p.role} />
                ))}
              </DefaultPermissionColumn>
              {!isEditing && (
                <DefaultPermissionColumn>
                  {permissions.map(p => (
                    <CurrentPermRow key={p.name} perm={permUtils} role={p.role} />
                  ))}
                </DefaultPermissionColumn>
              )}
              {isEditing && (
                <EditColumn>
                  {permissions.map(p => (
                    <PermEditCheckbox key={p.name} perm={permUtils} role={p.role} onPermChanged={onPermChanged} />
                  ))}
                </EditColumn>
              )}
              {isEditing && (
                <ResetColumn>
                  {permissions.map(p => (
                    <PermResetBox key={p.name} perm={permUtils} role={p.role} onPermChanged={onPermChanged} />
                  ))}
                </ResetColumn>
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
