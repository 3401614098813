import { ITEM_EXECUTION_SORT_OPTION } from '@cils/common'
import React, { useState } from 'react'
import { GQLITEM_EXECUTION_SORT_OPTION } from '../../../@types/server'
import {
  DropButton,
  DropDown,
  DropDownContent,
  OptionWrapper,
  Value,
} from '../../../components/Dropdown/DropdownCommon'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick'

const SortByName: { [key: string]: JSX.Element } = {
  [ITEM_EXECUTION_SORT_OPTION.NameAscending]: (
    <Value>
      Title <span style={{ color: '#2f353a' }}>A</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
      <span style={{ color: '#2f353a' }}>Z</span>
    </Value>
  ),
  [ITEM_EXECUTION_SORT_OPTION.NameDescending]: (
    <Value>
      Title <span style={{ color: '#2f353a' }}>Z</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
      <span style={{ color: '#2f353a' }}>A</span>
    </Value>
  ),
}

type Props = {
  setFilterSort: (value: GQLITEM_EXECUTION_SORT_OPTION | null) => void
}

export const ProjectSortDropDown: React.FC<Props> = props => {
  const { setFilterSort } = props

  const [value, setValue] = useState<string>(ITEM_EXECUTION_SORT_OPTION.NameAscending)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  useDetectOutsideClick(() => setIsOpen(false), menuRef, isOpen)

  const onClick = (v: ITEM_EXECUTION_SORT_OPTION) => {
    setValue(v)
    setFilterSort((v as unknown) as GQLITEM_EXECUTION_SORT_OPTION)
    setIsOpen(false)
  }

  return (
    <DropDown ref={menuRef} style={{ width: 120 }}>
      <DropButton onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))} style={{ width: 120 }}>
        {SortByName[value]}
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none', width: 120, marginTop: 5 }}>
        <OptionWrapper onClick={() => onClick(ITEM_EXECUTION_SORT_OPTION.NameAscending)}>
          <Value>
            Title <span style={{ color: '#2f353a' }}>A</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
            <span style={{ color: '#2f353a' }}>Z</span>
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(ITEM_EXECUTION_SORT_OPTION.NameDescending)}>
          <Value>
            Title <span style={{ color: '#2f353a' }}>Z</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
            <span style={{ color: '#2f353a' }}>A</span>
          </Value>
        </OptionWrapper>
      </DropDownContent>
    </DropDown>
  )
}
