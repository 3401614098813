import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Utils } from '../utils'
import { ODIcon, ODIcons } from './ODIcon'

type WrapperType = {
  isOpen: boolean
}

const Wrapper = styled.div`
  visibility: ${(p: WrapperType) => (p.isOpen ? 'visible' : 'hidden')};
  position: absolute;
  width: 90%;
  height: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  border: dashed 4px #29b6ca;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.span`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  text-align: center;
  color: #28b5c9;
`

type DragAndDropComponentProps = {
  isOpen: boolean
  onChange: (file: File, previewUri: string) => void
  toggle: () => void
  onClose: () => void
  style?: object
}

export const DragAndDropComponent: React.FC<DragAndDropComponentProps> = props => {
  const { isOpen, onChange, toggle, onClose, style = {} } = props

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: v => {
      if (v.length === 0) {
        Utils.showError('Please attach one file at a time.')
        onClose()
        return
      }

      v.forEach(file => {
        onChange(file, URL.createObjectURL(file))
        toggle()
      })
    },
  })

  // 특정 시점 이후 props 관련하여 ts 오류가 발생한다. 회피를 위해 타입을 강제로 any 로 변환하였다.
  return (
    <Wrapper isOpen={isOpen} style={style} {...(getRootProps() as any)}>
      <input {...(getInputProps() as any)} />
      <ODIcon icon={ODIcons.CoreImagePlus} style={{ fontSize: 50, color: '#28b5c9', marginBottom: 25 }} />
      <Title>Click or Drop files here to upload attachment</Title>
    </Wrapper>
  )
}
