import { PROJECT_USER_PERM } from '@cils/common'
import React from 'react'
import styled from 'styled-components'
import { GQLProjectHasUser } from '../../../../@types/server'
import { ODSearchBox } from '../../../../components/ODSearchBox'
import { ODModalAddUserToProject } from '../../../../components/project/modal/ODModalAddUserToProject'
import { ODColors } from '../../../../global-styles'
import { useProjectPerm } from '../../../../hooks/useProjectPerm'
import { IProjectHasUserListableOption } from '../list/useProjectHasUserListDataLoader'
import { ODListableContextType } from '@odc/od-react-belt'

interface IProjectUserSearch {
  orgId: number
  projectId: number
  listableContext: React.Context<ODListableContextType<GQLProjectHasUser, IProjectHasUserListableOption>>
  myProjectPerm: number
  refresh: () => void
}

export const ProjectUserSearch: React.FC<IProjectUserSearch> = props => {
  const { orgId, listableContext, myProjectPerm, refresh } = props
  const projectPerm = useProjectPerm(orgId)
  const [keyword, setKeyword] = React.useState('')
  const [isAddUserOpen, setIsAddUserOpen] = React.useState(false)
  const {
    updateLoadOption,
    state: { loadOption },
  }: ODListableContextType<GQLProjectHasUser, IProjectHasUserListableOption> = React.useContext(listableContext)

  const handleChange = (v: string) => {
    setKeyword(v)
    updateLoadOption({ ...loadOption, filter: v })
  }

  return (
    <Container>
      <ODModalAddUserToProject
        isOpen={isAddUserOpen}
        close={() => {
          setIsAddUserOpen(false)
          refresh()
        }}
        orgId={orgId}
        // projectId={projectId}
        inUserPage
      />
      <ODSearchBox
        placeholder="Search by ID, Name, Email"
        value={keyword}
        onChange={handleChange}
        style={{ width: '100%', maxWidth: 595, height: 34, backgroundColor: ODColors.White }}
      />
      {/* 프로젝트 내 권한이 Owner, Admin인 유저에게만 버튼이 표시 됨 */}
      {projectPerm.isSameOrGreaterThanAdmin(myProjectPerm) && (
        <AddUserButton onClick={() => setIsAddUserOpen(true)}>Add User</AddUserButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const AddUserButton = styled.button`
  margin-left: auto;
  width: 100%;
  max-width: 135px;
  height: 35px;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${ODColors.White};
`
