import { WORKSET_ACTION } from '@cils/common/lib/permission/worksetPermConsts'
import React from 'react'
import { Col, Row } from 'reactstrap'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { GQLItem } from '../../@types/server'
import { SHOW_ADD_TO_WORKSET_TYPE } from '../../containers/User/TCFItems/ItemsList'
import { globalLoadingState } from '../../context/atoms'
import { useCILSAgentContext } from '../../context/CILSAgentContext'
import { useODAppContext } from '../../context/ODAppContext'
import { useAPIs } from '../../context/useAPIs'
import { ODColors } from '../../global-styles'
import { GQLItemsListableContextType } from '../../ODListable/ODListableContext'
import { Utils } from '../../utils'
import { BlockingLoadBox } from '../BlockingLoadBox'
import { EditDropDownComponent } from '../EditDropDownComponent'
import { ODIcon, ODIcons } from '../ODIcon'

export enum EDIT_TYPE {
  add = 'add',
  replace = 'replace',
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  border: solid 2px #29b6ca;
  background-color: #ffffff;
  flex-direction: row;
  align-items: center;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 21px;
  padding-bottom: 21px;
`
const SelectedText = styled.span`
  min-width: 80px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.54px;
  color: #29b6ca;
  margin-left: 14px;
  margin-right: 52px;
`
const Button = styled.div`
  width: 186px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 14px;
`
const ButtonText = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`
// const Select = styled(Input)`
//   height: 34px;
//   border-radius: 4px;
//   border: solid 1px #c8ced3;
//   background-color: #ffffff;
// `

type handlingImageAction = {
  handleRemoveFromWorkset: Function
  setIsOpenCategoryInBulkModal: Function
  setIsOpenAddToWorksetModal: Function
  setIsOpenExplanationInBulkModal: Function
  setIsOpenTagInBulkModal: Function
  handleUpdateHideItems: Function
  handleDownloadItems: () => void
  handleOpenItem: (item: GQLItem) => void
}

export type ImageActionProps = {
  inWorksetId?: number
  listableContext: React.Context<GQLItemsListableContextType>
  removing: boolean
  handlingImageAction: handlingImageAction
}

export enum EDIT_DROPDOWN_MENU {
  Category,
  Tag,
  Explanation,
  Show,
  Hide,
}

export const ImageAction: React.FC<ImageActionProps> = props => {
  const { inWorksetId, listableContext, removing, handlingImageAction } = props

  const [, setChecking] = useRecoilState(globalLoadingState)

  const {
    state: { selectedItems },
    deselectAll,
  } = React.useContext(listableContext)

  const { getItemWithPermission } = useAPIs()

  React.useEffect(() => {
    const handler = (ev: any) => {
      if (ev.keyCode === 27) {
        // esc
        deselectAll()
      }
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [deselectAll])

  const { state } = useODAppContext()
  const {
    state: { connected, initializing },
  } = useCILSAgentContext()

  React.useEffect(() => {
    deselectAll()
  }, [state.directoryNodeCounter, deselectAll])

  const checkPermission = React.useCallback(
    async (selectedItems: Array<GQLItem>, action: WORKSET_ACTION) => {
      setChecking({ show: true, message: 'Checking permission' })
      for (const item of selectedItems) {
        // await new Promise(resolve => setTimeout(resolve, 555000))
        let hasPerm = false
        try {
          const r = await getItemWithPermission({ id: item.itemId })
          switch (action) {
            case WORKSET_ACTION.DownloadItem:
              hasPerm = r.permission.canDownload
              break
            case WORKSET_ACTION.AddToWorkset:
              hasPerm = r.permission.canAddToWorkset
              break
            case WORKSET_ACTION.AddAttachment:
              hasPerm = r.permission.canAddAttachment
              break
            case WORKSET_ACTION.DeleteAttachment:
              hasPerm = false
              break
            case WORKSET_ACTION.EditMeta:
              hasPerm = r.permission.canModifyMetadata
              break
            case WORKSET_ACTION.EditAttachmentMeta:
              hasPerm = r.permission.canEditAttachment
              break
            case WORKSET_ACTION.MakeMain:
              hasPerm = r.permission.canEditAttachment
              break
          }

          if (!hasPerm) {
            Utils.showError('You need permission to perform this action.')
            setChecking({ show: false, message: null })
            return false
          }
        } catch (ex) {
          Utils.showError(ex)
          setChecking({ show: false, message: null })
          return false
        }
      }
      setChecking({ show: false, message: null })
      return true
    },
    [getItemWithPermission]
  )

  const handleModal = async (v: EDIT_DROPDOWN_MENU) => {
    if (!(await checkPermission(selectedItems, WORKSET_ACTION.EditMeta))) {
      return
    }

    switch (v) {
      case EDIT_DROPDOWN_MENU.Category:
        handlingImageAction.setIsOpenCategoryInBulkModal(true)
        return
      case EDIT_DROPDOWN_MENU.Tag:
        handlingImageAction.setIsOpenTagInBulkModal(true)
        return
      case EDIT_DROPDOWN_MENU.Explanation:
        handlingImageAction.setIsOpenExplanationInBulkModal(true)
        return
      case EDIT_DROPDOWN_MENU.Hide:
        await handlingImageAction.handleUpdateHideItems(true)
        return
      case EDIT_DROPDOWN_MENU.Show:
        await handlingImageAction.handleUpdateHideItems(false)
        return
      default:
        return
    }
  }

  const agentAvailable = connected && !initializing

  if (selectedItems.length === 0) {
    return null
  }

  const handleClickDownload = async () => {
    if (!(await checkPermission(selectedItems, WORKSET_ACTION.DownloadItem))) {
      return
    }

    if (agentAvailable) {
      handlingImageAction.handleDownloadItems()
      return
    }

    const agentUnavailableReason = !connected ? 'Cannot connect the downloader' : 'Downloader is initializing..'
    Utils.showInfo(agentUnavailableReason, 'Cannot download')
  }

  const handleAddToWorkset = async () => {
    if (!(await checkPermission(selectedItems, WORKSET_ACTION.AddToWorkset))) {
      return
    }

    handlingImageAction.setIsOpenAddToWorksetModal(SHOW_ADD_TO_WORKSET_TYPE.SHOW_SELECTED)
  }

  const handleRemoveFromWorkset = async () => {
    if (!(await checkPermission(selectedItems, WORKSET_ACTION.AddToWorkset))) {
      return
    }

    handlingImageAction.handleRemoveFromWorkset()
  }

  return (
    <div>
      <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        {removing && <BlockingLoadBox show />}
        {/*<Component {...componentProps} />*/}
        {/*<TagComponent {...tagComponentProps} />*/}
        {/*<ExplanationComponent {...explanationComponentProps} />*/}
        <Col
          md={12}
          className="vcenter"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Wrapper>
            <ODIcon
              icon={ODIcons.CoreX}
              style={{ fontSize: 18, color: '#29b6ca', cursor: 'pointer' }}
              onClick={deselectAll}
            />
            <SelectedText>{selectedItems.length} Selected</SelectedText>
            <Button
              style={{ marginRight: 10, ...(agentAvailable ? {} : { backgroundColor: '#c9c9c9' }) }}
              onClick={() => handleClickDownload()}
            >
              <ODIcon icon={ODIcons.MaterialFileDownload} style={{ fontSize: 14, color: '#73818f', marginRight: 14 }} />
              <ButtonText>Download</ButtonText>
            </Button>
            <Button style={{ marginRight: 10 }} onClick={() => handleAddToWorkset()}>
              <ButtonText>Add To Workset</ButtonText>
            </Button>
            <div style={{ display: 'flex', alignItems: 'center', width: 186, marginLeft: 10, marginRight: 10 }}>
              <EditDropDownComponent onChange={handleModal} inWorksetId={inWorksetId} />
            </div>
            {inWorksetId && (
              <Button style={{ marginRight: 10 }} onClick={() => handleRemoveFromWorkset()}>
                <ButtonText>Remove from Workset</ButtonText>
              </Button>
            )}
            {selectedItems.length === 1 && (
              <Button
                style={{ marginRight: 10, backgroundColor: ODColors.Primary, border: 0 }}
                onClick={() => handlingImageAction.handleOpenItem(selectedItems[0]!)}
              >
                <ODIcon icon={ODIcons.CoreImagePlus} style={{ fontSize: 14, color: '#ffffff', marginRight: 14 }} />
                <ButtonText style={{ color: '#ffffff' }}>View</ButtonText>
              </Button>
            )}
          </Wrapper>
        </Col>
      </Row>
    </div>
  )
}
