"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_ORG_USER_PERM_SCHEMA = exports.DEFAULT_ROLES_PER_ORG_PRIV = exports.ORG_ROLE = exports.CURRENT_PERM_VERSION = exports.ORG_PERM_VERSION = void 0;
/**
 * 권한과 관련된 설정이 변경될 것을 대비하여, 권한 json 의 버전을 관리한다.
 * 주석을 최대한 상세히 달도록 한다.
 */

var constants_1 = require("../constants");

var ORG_PERM_VERSION;

(function (ORG_PERM_VERSION) {
  ORG_PERM_VERSION["V001_2020_1015"] = "V001_2020_1015";
})(ORG_PERM_VERSION = exports.ORG_PERM_VERSION || (exports.ORG_PERM_VERSION = {}));

exports.CURRENT_PERM_VERSION = ORG_PERM_VERSION.V001_2020_1015;
/**
 * 기관에 대하여 개별 사용자가 가질 수 있는 권한 매트릭스
 */

var ORG_ROLE;

(function (ORG_ROLE) {
  ORG_ROLE["ACCESS_ORG_ADMIN_CONSOLE"] = "ACCESS_ORG_ADMIN_CONSOLE";
  ORG_ROLE["EDIT_ORG"] = "EDIT_ORG";
  ORG_ROLE["VIEW_ORG_CATEGORY"] = "VIEW_ORG_CATEGORY";
  ORG_ROLE["EDIT_ORG_CATEGORY"] = "EDIT_ORG_CATEGORY";
  ORG_ROLE["CREATE_ORG_CATEGORY"] = "CREATE_ORG_CATEGORY";
  ORG_ROLE["EDIT_USER_PERM"] = "EDIT_USER_PERM";
  ORG_ROLE["CREATE_USER"] = "CREATE_USER";
  ORG_ROLE["VIEW_AGENT"] = "VIEW_AGENT";
  ORG_ROLE["EDIT_AGENT"] = "EDIT_AGENT";
  ORG_ROLE["CREATE_AGENT"] = "CREATE_AGENT";
  ORG_ROLE["VIEW_ORG_DATA"] = "VIEW_ORG_DATA";
  ORG_ROLE["EDIT_ORG_DATA"] = "EDIT_ORG_DATA";
  ORG_ROLE["VIEW_ORG_WORKSET"] = "VIEW_ORG_WORKSET";
  ORG_ROLE["EDIT_ORG_WORKSET"] = "EDIT_ORG_WORKSET";
  ORG_ROLE["CREATE_WORKSET"] = "CREATE_WORKSET";
  ORG_ROLE["VIEW_ORG_TAG"] = "VIEW_ORG_TAG";
  ORG_ROLE["VIEW_AUDIT_LOGS"] = "VIEW_AUDIT_LOGS";
  ORG_ROLE["VIEW_ALERT_LOGS"] = "VIEW_ALERT_LOGS";
  ORG_ROLE["VIEW_ORG_PROJECT"] = "VIEW_ORG_PROJECT";
  ORG_ROLE["EDIT_ORG_PROJECT"] = "EDIT_ORG_PROJECT";
  ORG_ROLE["CREATE_PROJECT"] = "CREATE_PROJECT";
})(ORG_ROLE = exports.ORG_ROLE || (exports.ORG_ROLE = {}));
/**
 * 개별 권한 레벨의 디폴트 권한이다. true 로 지정되지 않은 모든 권한은 미허용이다.
 */


exports.DEFAULT_ROLES_PER_ORG_PRIV = {
  [constants_1.ORG_USER_PRIV.SystemAdmin]: {
    [ORG_ROLE.ACCESS_ORG_ADMIN_CONSOLE]: true,
    [ORG_ROLE.EDIT_ORG]: true,
    [ORG_ROLE.EDIT_ORG_CATEGORY]: true,
    [ORG_ROLE.CREATE_ORG_CATEGORY]: true,
    [ORG_ROLE.EDIT_USER_PERM]: true,
    [ORG_ROLE.CREATE_USER]: true,
    [ORG_ROLE.VIEW_AGENT]: true,
    [ORG_ROLE.VIEW_ORG_DATA]: true,
    [ORG_ROLE.VIEW_ORG_WORKSET]: true,
    [ORG_ROLE.VIEW_ORG_CATEGORY]: true,
    [ORG_ROLE.VIEW_ORG_TAG]: true,
    [ORG_ROLE.VIEW_AUDIT_LOGS]: true,
    [ORG_ROLE.VIEW_ALERT_LOGS]: true,
    [ORG_ROLE.VIEW_ORG_PROJECT]: true
  },
  [constants_1.ORG_USER_PRIV.DataAdmin]: {
    [ORG_ROLE.ACCESS_ORG_ADMIN_CONSOLE]: true,
    [ORG_ROLE.VIEW_AGENT]: true,
    [ORG_ROLE.EDIT_AGENT]: true,
    [ORG_ROLE.CREATE_AGENT]: true,
    [ORG_ROLE.VIEW_ORG_DATA]: true,
    [ORG_ROLE.EDIT_ORG_DATA]: true,
    [ORG_ROLE.VIEW_ORG_WORKSET]: true,
    [ORG_ROLE.EDIT_ORG_WORKSET]: true,
    [ORG_ROLE.VIEW_ORG_CATEGORY]: true,
    [ORG_ROLE.VIEW_ORG_TAG]: true,
    [ORG_ROLE.CREATE_WORKSET]: true,
    [ORG_ROLE.VIEW_ORG_PROJECT]: true,
    [ORG_ROLE.EDIT_ORG_PROJECT]: true,
    [ORG_ROLE.CREATE_PROJECT]: true
  },
  [constants_1.ORG_USER_PRIV.Normal]: {
    [ORG_ROLE.VIEW_ORG_CATEGORY]: true,
    [ORG_ROLE.VIEW_ORG_TAG]: true,
    [ORG_ROLE.CREATE_WORKSET]: true,
    [ORG_ROLE.CREATE_PROJECT]: true
  },
  [constants_1.ORG_USER_PRIV.Guest]: {
    [ORG_ROLE.CREATE_WORKSET]: true,
    [ORG_ROLE.CREATE_PROJECT]: true
  },
  [constants_1.ORG_USER_PRIV.Rejected]: {},
  [constants_1.ORG_USER_PRIV.Pending]: {}
};
exports.DEFAULT_ORG_USER_PERM_SCHEMA = {
  version: exports.CURRENT_PERM_VERSION,
  overrides: {}
};