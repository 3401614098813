import React from 'react'
import { GQLItem, GQLListableItem, GQLListableItemInput } from '../../../@types/server'
import { GQL_ITEM_IN_LIST_SNAPSHOT } from '../../../context/ODAppContext'
import { useODQuery } from '../../../context/ODCommon'
import { ODListableResponseType } from '../../../ODListable/ODListableContext'
import { ItemDataLoaderOption, ItemsContainer } from './ItemsContainer'

const GQL_LIST_ITEMS = `
query listItem($data: ListableItemInput!) {
  listItem(data: $data) {
    list {
      ${GQL_ITEM_IN_LIST_SNAPSHOT}
    }
    page
    pageSize
    totalCount
  }
}
`

export const AllItemsContainer: React.FC = () => {
  const apiList = useODQuery<Partial<GQLListableItemInput>, GQLListableItem>(GQL_LIST_ITEMS)

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemDataLoaderOption
    ): Promise<ODListableResponseType<GQLItem>> {
      return apiList({
        page,
        pageSize,
        filter: options.filter,
        orgId: options.orgId,
        tagIds: options.tagIds,
        hasAttachment: options.hasAttachment,
        timeLapseOnly: options.timeLapseOnly,
        // @ts-ignore
        tcfImageTypes: options.tcfImageTypes,
        // @ts-ignore
        sortBy: options.sortBy,
        modifiedTimeRange: options.modifiedTimeRange,
        categoryIds: options.categoryIds,
        addedToWorkset: options.addedToWorkset,
        afterKey,
        hidden: options.hidden,
      })
    },
    [apiList]
  )

  return <ItemsContainer showToolbar dataLoader={dataLoader} pageSize={200} />
}
