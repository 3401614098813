import { DOAddUserToProjectResult } from '@cils/common'
import { ProjectUserPerm, SearchUserResultType } from '@cils/common/lib/CILSStore/projectView/IAddUserToProjectStore'
import { ODHSpace, ODVSpace, useODModalConfirm } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Select from 'react-select'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { GQLWorkset } from '../../../@types/server'
import { CheckImg, CheckIndeterminateImg, UncheckImg } from '../../../assets/images'
import { useCILSStore } from '../../../di/configureRootStore'
import { ODColors } from '../../../global-styles'
import { Utils } from '../../../utils'
import { ODButton, ODButtonTheme } from '../../ODButton'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalFooter } from '../../ODModal/ODModalFooter'
import { ODModalHeader } from '../../ODModal/ODModalHeader'
import { ODModalShare } from '../../ODModal/ODModalShare'
import { SelectOptionType } from '../../ODModal/SelectWorksetMemberAndPermission'
import { NoResultBox } from '../list/NoResultBox'
import { AddUserToProjectResultTr } from './AddUserToProjectResultTr'
import { ODModalCheckWorksetPermissionToAddUser } from './ODModalCheckWorksetPermissionToAddUser'

interface IODModalAddUserToProject {
  isOpen: boolean
  close: () => void
  orgId: number
  onAdd?: () => Promise<void>
  inUserPage?: boolean
}

const USER_PERM_OPTIONS: SelectOptionType[] = [
  { key: ProjectUserPerm.Viewer.toString(), label: 'Viewer', value: ProjectUserPerm.Viewer },
  { key: ProjectUserPerm.Editor.toString(), label: 'Editor', value: ProjectUserPerm.Editor },
  { key: ProjectUserPerm.Admin.toString(), label: 'Admin', value: ProjectUserPerm.Admin },
  // { key: WORKSET_USER_PERM.Owner, label: 'Owner', value: WORKSET_USER_PERM.Owner },
]

export const ODModalAddUserToProject: React.FC<IODModalAddUserToProject> = observer(props => {
  const { isOpen, close, onAdd, inUserPage } = props
  const store = useCILSStore()
  const addUserStore = store.addUserToProjectStore

  const [isCheckPermissionOpen, setIsCheckPermissionOpen] = React.useState<DOAddUserToProjectResult | null>(null)
  const [worksetToShare, setWorksetToShare] = React.useState<GQLWorkset | null>(null)

  const [, setLoading] = React.useState(false)

  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})

  const handleShareWorkset = async (workset: GQLWorkset) => {
    setWorksetToShare(workset)
  }

  const handleRemoveUser = async (result: DOAddUserToProjectResult) => {
    if (
      await confirm({
        title: `Remove User`,
        message: (
          <>
            Even if the permission is changed when adding a <br />
            user, it does not revert to the previous <br />
            permission when removing a user.
            <br />
            <br />
            Are you sure you want to remove <br />
            <span style={{ color: ODColors.Primary }}>
              {result.user.name ?? '-'}({result.user.email ?? '-'})
            </span>{' '}
            from the project? <br />
            This cannot be undone.
          </>
        ),
        yes: 'Remove',
        no: 'Cancel',
      })
    ) {
      try {
        setLoading(true)
        addUserStore.deleteAddUserResult(result)
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
      // }
    }
  }

  const handleRemoveUsers = async () => {
    if (
      await confirm({
        title: `Remove Users`,
        message: (
          <>
            Are you sure you want to remove users from the
            <br />
            project?
            <br />
            This cannot be undone.
          </>
        ),
        yes: 'Remove',
        no: 'Cancel',
      })
    ) {
      // if (item.myPerm === WORKSET_USER_PERM.Viewer) {
      //   showODToast(ODToastType.Error, ODToastType.Error, 'Permission이 Viewer인 경우에는 삭제 할 수 없습니다.')
      // } else {
      try {
        setLoading(true)
        addUserStore.deleteSelectedUsers()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
      // }
    }
  }

  const handleAddMemberChange = (v: any, actionMeta: any) => {
    switch (actionMeta.action) {
      case 'select-option': {
        store.addUserToProjectStore.addSearchResult(actionMeta.option.realValue)
        return
      }
      case 'remove-value': {
        store.addUserToProjectStore.removeSearchResult(actionMeta.removedValue.realValue)
        return
      }
      case 'clear': {
        store.addUserToProjectStore.clearSearchResult()
        return
      }
    }
  }

  const title = 'Add User'
  const addBoxTitle = 'Add Member and Group to Project'
  const selectGuideText = 'Type Name, Email or Group Name'
  const addBoxGuideText = 'If member or group you select is already in Project, their permission will be replaced.'
  const resultTableBoxTitle = 'Result'
  const noResultsText = 'No results found'

  const allCheckBoxIcon = React.useMemo(() => {
    const selectedResults = addUserStore.checkedSubmitResults
    if (selectedResults.length === 0) {
      return UncheckImg
    }
    if (selectedResults.length > 0 && selectedResults.length !== addUserStore.submitResults.length) {
      return CheckIndeterminateImg
    }
    if (selectedResults.length === addUserStore.submitResults.length) {
      return CheckImg
    }
  }, [addUserStore.checkedSubmitResults.length, addUserStore.submitResults.length])

  // 렌더 될때 전체 유저 리스트를 받아오기 위함
  React.useEffect(() => {
    store.addUserToProjectStore.setSearchText('')
  }, [])

  const onClose = () => {
    addUserStore.clearAfterSubmit()
    close()
  }

  const thStyle = { opacity: addUserStore.checkedSubmitResults.length > 0 ? 0 : 1 }
  const selectedPriv = store.addUserToProjectStore.selectedPriv
  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Big} style={{ maxWidth: 774 }}>
      <ConfirmComponent {...confirmProps} />
      {worksetToShare && (
        <ODModalShare
          isOpen
          setIsOpen={() => setWorksetToShare(null)}
          wsId={worksetToShare!.wsId}
          title={`Share setting for ${worksetToShare!.name}`}
        />
      )}
      {isCheckPermissionOpen && (
        <ODModalCheckWorksetPermissionToAddUser
          isOpen={!!isCheckPermissionOpen}
          onClose={() => setIsCheckPermissionOpen(null)}
          data={isCheckPermissionOpen}
          showGuideText
          inUserPage={inUserPage}
        />
      )}
      <ODModalHeader title={title} onClose={onClose} />
      <ODModalBody style={{ padding: 0 }}>
        <AddBoxWrapper style={{ width: '100%' }}>
          <AddBoxTitle>{addBoxTitle}</AddBoxTitle>
          <ODVSpace h={10} />
          <AddBoxButtonsRow>
            <CustomUserSelectBox
              cacheOptions={false}
              defaultOptions
              isMulti={true}
              options={store.addUserToProjectStore.searchResults.map(item => {
                if (item.type === SearchUserResultType.User) {
                  return {
                    key: `${item.user.name}:${item.user.userId}`,
                    label: `${item.user.name ?? '-'} : ${item.user.email ?? '-'}`,
                    value: item.user.userId,
                    realValue: item,
                  }
                } else {
                  return {
                    key: `${item.type}:${item.name}`,
                    label: `Group : ${item.name}`,
                    value: item.members.map(v => v.userId),
                    realValue: item,
                  }
                }
              })}
              onInputChange={(text: string) => {
                store.addUserToProjectStore.setSearchText(text)
              }}
              onChange={handleAddMemberChange}
            />
            <ODHSpace w={5} />
            <UserPermSelectBox
              cacheOptions={false}
              isMulti={false}
              options={USER_PERM_OPTIONS}
              value={USER_PERM_OPTIONS.find(v => v.value === selectedPriv)}
              onChange={(v: SelectOptionType) => {
                store.addUserToProjectStore.setPriv(v.value as ProjectUserPerm)
              }}
            />
            <AddButton
              onClick={() => {
                console.log('hi')
                store.addUserToProjectStore.submit()
              }}
            >
              Add
            </AddButton>
          </AddBoxButtonsRow>
          <ODVSpace h={6} />
          <SelectGuideText>{selectGuideText}</SelectGuideText>
          <ODVSpace h={30} />
          <AddBoxGuideBox>
            <ODIcon
              icon={ODIcons.MaterialError}
              style={{ width: 15, height: 14, fontSize: 14, color: ODColors.Primary }}
            />
            <ODHSpace w={14} />
            <AddBoxGuideText>{addBoxGuideText}</AddBoxGuideText>
          </AddBoxGuideBox>
        </AddBoxWrapper>
        <ResultTableBox>
          <ResultTableBoxTitle>{resultTableBoxTitle}</ResultTableBoxTitle>
          <ODVSpace h={18} />
          <Table responsive style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}>
            <thead>
              <tr>
                <th style={{ cursor: 'pointer' }} onClick={() => addUserStore.toggleAllResultChecked()}>
                  <CheckboxImage src={allCheckBoxIcon} style={{ cursor: 'pointer', top: -4 }} alt="" />
                </th>
                <th style={thStyle}>ID</th>
                <th style={thStyle}>Name</th>
                <th style={thStyle}>Email</th>
                <th style={thStyle}>Permission</th>
                <th style={{ minWidth: 170 }}>
                  {addUserStore.checkedSubmitResults.length > 0 ? (
                    <RemoveButton onClick={() => handleRemoveUsers()}>
                      <ODIcon icon={ODIcons.CoreTrash} style={{ fontSize: 14 }} />
                      <ODHSpace w={6} />
                      Remove users
                    </RemoveButton>
                  ) : (
                    'Result'
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {addUserStore.submitResults.map(item => {
                return (
                  <AddUserToProjectResultTr
                    key={item.user.userId}
                    item={item}
                    handleRemoveUser={handleRemoveUser}
                    setIsCheckPermissionOpen={(item: DOAddUserToProjectResult) => {
                      setIsCheckPermissionOpen(item)
                    }}
                    handleShareWorkset={(workset: GQLWorkset) => handleShareWorkset(workset)}
                  />
                )
              })}
            </tbody>
          </Table>
          {addUserStore.submitResults.length === 0 && <NoResultBox height={265} text={noResultsText} />}
        </ResultTableBox>
      </ODModalBody>
      <ODModalFooter>
        <ODButton
          theme={ODButtonTheme.Primary}
          fullWidth
          onClick={async () => {
            if (onAdd) {
              addUserStore.clearAfterSubmit()
              await onAdd()
            } else {
              onClose()
            }
          }}
        >
          {onAdd ? 'Next' : 'Done'}
        </ODButton>
      </ODModalFooter>
    </ODModal>
  )
})

const AddBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ODColors.PaleGrey};
  padding: 30px 37px 40px;
`

const AddBoxTitle = styled.span`
  font-size: 13px;
  letter-spacing: 0;
  color: ${ODColors.Steel};
  font-weight: bold;
`

const AddBoxButtonsRow = styled.div`
  display: flex;
`

const CustomUserSelectBox = styled(Select)`
  width: 100%;
  max-width: 350px;
`

const UserPermSelectBox = styled(Select)`
  width: 100%;
  max-width: 130px;
`

const AddButton = styled.button`
  width: 100%;
  max-width: 200px;
  height: 38px;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0;
  color: ${ODColors.White};
  margin-left: auto;
`

const SelectGuideText = styled.span`
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0;
  color: ${ODColors.Steel};
`

const AddBoxGuideBox = styled.div`
  display: flex;
  align-items: center;
`

const AddBoxGuideText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.Primary};
`

const ResultTableBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 37px;
`

const ResultTableBoxTitle = styled.span`
  font-size: 12px;
  letter-spacing: 0.26px;
  color: ${ODColors.Steel};
  font-weight: bold;
`

const CheckboxImage = styled.img`
  position: relative;
  top: 5px;
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
`

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  color: ${ODColors.Salmon};
  font-size: 14px;
`
