import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLProject } from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'
import { IProjectListableOption } from '../ProjectCommon'

export function useProjectListDataLoader() {
  const { listProject } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectListableOption
    ): Promise<ODListableResponseType<GQLProject>> {
      const r = await listProject({
        page,
        pageSize,
        filter: options.filter,
        sortBy: options.sortBy,
      })
      return r as ODListableResponseType<GQLProject>
    },
    [listProject]
  )
}

export function useProjectInOrgListDataLoader(orgId: number) {
  const { listProjectInOrg } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectListableOption
    ): Promise<ODListableResponseType<GQLProject>> {
      const r = await listProjectInOrg({
        page,
        pageSize,
        filter: options.filter,
        sortBy: options.sortBy ?? null,
        status: options.status ?? null,
        orgId,
      })
      return r as ODListableResponseType<GQLProject>
    },
    [listProjectInOrg, orgId]
  )
}
