import { ODHSpace } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { ODColors } from '../../../global-styles'
import { ODIcon, ODIcons } from '../../ODIcon'
import { IProjectActiveFilter } from '../../../containers/User/Project/detail/ProjectDataPage'

interface IProjectDataActiveFilters {
  activeFilters: IProjectActiveFilter[]
  clearFilters: () => void
}

export const ProjectDataActiveFilters: React.FC<IProjectDataActiveFilters> = props => {
  const { activeFilters, clearFilters } = props
  return (
    <ActiveFiltersBox>
      <ActiveFiltersText>Active Filters</ActiveFiltersText>
      <ODHSpace w={34} />
      {activeFilters.map(item => {
        return (
          <ActiveFilterBox key={item.label} style={{ backgroundColor: item.color }} onClick={item.onClose}>
            <ODIcon
              icon={ODIcons.CoreX}
              style={{ fontSize: 10, color: ODColors.White, position: 'relative', top: 1 }}
            />
            <ODHSpace w={8} />
            <ActiveFilterText>{item.label}</ActiveFilterText>
          </ActiveFilterBox>
        )
      })}
      <ActiveFilterBox style={{ backgroundColor: ODColors.SalmonTwo }} onClick={() => clearFilters()}>
        <ODIcon
          icon={ODIcons.MaterialRefresh}
          style={{ fontSize: 10, color: ODColors.White, position: 'relative', top: 1 }}
        />
        <ODHSpace w={8} />
        <ActiveFilterText>Clear All</ActiveFilterText>
      </ActiveFilterBox>
    </ActiveFiltersBox>
  )
}

const ActiveFiltersBox = styled.div`
  display: flex;
`

const ActiveFiltersText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.CharcoalGrey};
  font-weight: 500;
`

const ActiveFilterBox = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;

  & + & {
    margin-left: 8px;
  }
`

const ActiveFilterText = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: ${ODColors.White};
`
