import { ODFlexContentsContainer, ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import imgCannotConnect from '../../../assets/img/img-cilsagent-x.png'
import { Wrapper } from './SyncCommon'

interface ICannotConnectAgentProps {}

const RedText = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #f86c6b;
`

const GrayText = styled.div`
  font-size: 14px;
  line-height: 1.64;
  color: #acb4bc;
`

export const CannotConnectAgent: React.FC<ICannotConnectAgentProps> = props => {
  const width = 220
  const height = 161
  return (
    <Wrapper>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Agent</CardHeader>
        <CardBody style={{ display: 'flex', justifyContent: 'center' }}>
          <ODFlexContentsContainer style={{ alignItems: 'center', justifyContent: 'center' }}>
            <img src={imgCannotConnect} width={width} height={height} style={{ width, height }} alt="logo" />
            <ODVSpace h={46} />
            <RedText>Cannot connect to the Agent or Downloader</RedText>
            <ODVSpace h={8} />
            <GrayText>Please check your Agent Status</GrayText>
          </ODFlexContentsContainer>
        </CardBody>
      </Card>
    </Wrapper>
  )
}
