import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLWorksetGroupHasWorkset } from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'
import { IWorksetGroupHasWorksetListableOption } from '../WorksetGroupCommon'

export function useWorksetGroupHasWorksetListDataLoader(
  wsgId: number,
  orgId: number,
  isManageOrganizationWorkset: boolean,
  addWorksets: (worksetIds: number[]) => void
) {
  const { listWorksetGroupHasWorkset, listWorksetGroupHasWorksetInOrg } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IWorksetGroupHasWorksetListableOption
    ): Promise<ODListableResponseType<GQLWorksetGroupHasWorkset>> {
      if (isManageOrganizationWorkset) {
        const r = await listWorksetGroupHasWorksetInOrg({
          page,
          pageSize,
          ...options,
          wsgId,
          orgId,
        })
        addWorksets(r.list.map(ws => ws.wsId))
        return r as ODListableResponseType<GQLWorksetGroupHasWorkset>
      } else {
        const r = await listWorksetGroupHasWorkset({
          page,
          pageSize,
          ...options,
          wsgId,
        })
        addWorksets(r.list.map(ws => ws.wsId))
        return r as ODListableResponseType<GQLWorksetGroupHasWorkset>
      }
    },
    [listWorksetGroupHasWorkset, listWorksetGroupHasWorksetInOrg, wsgId, orgId, isManageOrganizationWorkset]
  )
}
