import { ImageTagType } from '@cils/common'
import React from 'react'
import styled from 'styled-components'

type ImageTagProps = {
  tag: ImageTagType
}

const Styles = {
  [ImageTagType.Type_2D]: { backgroundColor: '#ff9c1d', text: '2' },
  [ImageTagType.Type_3D]: { backgroundColor: '#43cda1', text: '3' },
  [ImageTagType.Type_B]: { backgroundColor: '#ff9c1d', text: 'B' },
  [ImageTagType.Type_F]: { backgroundColor: '#43cda1', text: 'F' },
  [ImageTagType.Type_TimeLapse]: { backgroundColor: '#6185e8', text: 'T' },
  [ImageTagType.Type_C]: { backgroundColor: '#A14EFF', text: 'C' },
}

const TagWrapper = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  display: flex;
  place-content: center;
  min-width: 20px;
`

export const ImageTag: React.FC<ImageTagProps> = ({ tag }) => {
  const { text, ...style } = Styles[tag]

  return <TagWrapper style={style}>{text}</TagWrapper>
}
