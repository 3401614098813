"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CilsDownloadDirInfoFile = exports.CilsTmpDownloadDirInfoFile = exports.AGENT_STATUS_MESSAGE_TYPE = void 0;
var AGENT_STATUS_MESSAGE_TYPE;

(function (AGENT_STATUS_MESSAGE_TYPE) {
  AGENT_STATUS_MESSAGE_TYPE["SERVER_STATUS_CHANGED"] = "SERVER_STATUS_CHANGED";
  AGENT_STATUS_MESSAGE_TYPE["UPDATE_SYNC_STATUS"] = "UPDATE_SYNC_STATUS";
  AGENT_STATUS_MESSAGE_TYPE["UPDATE_SYNC_DIRS"] = "UPDATE_SYNC_DIRS";
})(AGENT_STATUS_MESSAGE_TYPE = exports.AGENT_STATUS_MESSAGE_TYPE || (exports.AGENT_STATUS_MESSAGE_TYPE = {})); //
//
//

/**
 * 다운로드가 진행중인 디렉토리에 존재하는 파일
 */


exports.CilsTmpDownloadDirInfoFile = '.cils.download.json';
/**
 * 다운로드가 완료된 디렉토리에 존재하는 파일 CilsDownloadDirInfoFile 의 내용
 */

exports.CilsDownloadDirInfoFile = '.cils.dn.json';