import { ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { ODColors } from '../../../global-styles'
import { ODIcon, ODIcons } from '../../ODIcon'

interface INoResultBox {
  height: number | string
  text: string
}

export const NoResultBox: React.FC<INoResultBox> = props => {
  const { height, text } = props
  return (
    <div
      style={{
        width: '100%',
        height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${ODColors.Silver}`,
      }}
    >
      <ODIcon icon={ODIcons.CoreFrown} style={{ width: 40, height: 41, fontSize: 40, color: ODColors.Primary }} />
      <ODVSpace h={10} />
      <NoResultText>{text}</NoResultText>
    </div>
  )
}

const NoResultText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${ODColors.Primary};
`
