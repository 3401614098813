import { ODHSpace, ODVSpace } from '@odc/od-react-belt'
import React, { useRef } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { useProjectMetaDataPresetInOrgList } from '../../../context/ODCILSHooks'
import { ODColors } from '../../../global-styles'
import { ODButton, ODButtonTheme } from '../../ODButton'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalFooter } from '../../ODModal/ODModalFooter'
import { ODModalHeader } from '../../ODModal/ODModalHeader'
import { SelectOptionType } from '../../ODModal/SelectWorksetMemberAndPermission'
import { ODToastType, showODToast } from '../../ODToast'

interface IODModalCopyFromPreset {
  orgId: number
  isOpen: boolean
  onClose: () => void
  onConfirm: (presetId: number) => void
}

export const ODModalCopyFromPreset: React.FC<IODModalCopyFromPreset> = props => {
  const { orgId, isOpen, onClose, onConfirm } = props
  const { list } = useProjectMetaDataPresetInOrgList(orgId)
  const [selectedPreset, setSelectedPreset] = React.useState<SelectOptionType | null>(null)
  const selectFocus = useRef<HTMLInputElement>(null)

  const handleConfirm = async () => {
    if (!selectedPreset) {
      showODToast(ODToastType.Error, ODToastType.Error, 'Please select a Preset.')
      return
    }
    await onConfirm(selectedPreset.value)
  }

  const modalTitle = 'Copy from Preset'
  return (
    <ODModal
      isOpen={isOpen}
      toggle={onClose}
      size={ODModalSize.Normal}
      style={{ borderRadius: 4, maxWidth: 422, boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={modalTitle} onClose={onClose} />
        <ODModalBody>
          <CustomSelectBox
            name="preset"
            options={list}
            value={selectedPreset}
            placeholder={list.length === 0 ? 'No result.' : 'Select'}
            onChange={(e: React.SetStateAction<SelectOptionType | null>) => {
              setSelectedPreset(e)
            }}
            ref={selectFocus}
          />
          <ODVSpace h={25} />
          <Row>
            <ODIcon
              icon={ODIcons.MaterialError}
              style={{ fontSize: 14, color: ODColors.Primary, position: 'relative', top: 3 }}
            />
            <ODHSpace w={14} />
            <GuideText>
              If metadata is already in Project, meatdata <br /> will be replaced.
            </GuideText>
          </Row>
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleConfirm}>
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

const CustomSelectBox = styled(Select)`
  width: 100%;
`

const Row = styled.div`
  display: flex;
`

const GuideText = styled.span`
  font-size: 14px;
  color: ${ODColors.Primary};
`
