import { ODWebUtils } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex'
import 'react-reflex/styles.css'

import { BlockingLoadBox } from '../../../components/BlockingLoadBox'
import { DirectoryTreeView } from '../../../components/DirectoryView/DirectoryTreeView'
import { ODModalAddToWorkset } from '../../../components/ODModal/ODModalAddToWorkset'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { useCILSStore } from '../../../di/configureRootStore'
import { Utils } from '../../../utils'
import { DirectoryViewItemsContainer } from './DirectoryViewItemsContainer'

type DirectoryViewContainerProps = {}

export const DirectoryViewContainer: React.FC<DirectoryViewContainerProps> = observer(() => {
  const { orgId } = useODSingleOrgContext()

  const store = useCILSStore().directoryViewStore

  React.useEffect(() => {
    store.resetUI()
    store.loadDirectoryNode(orgId).catch(ex => ODWebUtils.showError(ex))
  }, [orgId])

  const loading = store.isLoading
  const selectedPath = store.selectedItemPath

  const handleOnSave = async (wsIds: number[]) => {
    try {
      await store.submitAddDirectoriesToWorkset(wsIds)
      Utils.showSuccess('Added items successfully.')
    } catch (ex) {
      Utils.showError(ex)
      return false
    }
    return true
  }

  return (
    <ReflexContainer orientation="vertical">
      {loading && <BlockingLoadBox show />}
      {store.showAddToWorkset && (
        <ODModalAddToWorkset onClose={() => store.setShowAddToWorkset(false)} onSave={handleOnSave} />
      )}
      <ReflexElement
        className="left-pane"
        flex={0.1}
        minSize={280}
        propagateDimensionsRate={200}
        propagateDimensions={true}
      >
        <DirectoryTreeView />
      </ReflexElement>

      <ReflexSplitter />

      <ReflexElement className="right-pane">
        {selectedPath && (
          <div style={{ display: 'flex', flex: 1 }}>
            <DirectoryViewItemsContainer directoryPath={selectedPath} orgId={orgId} />
          </div>
        )}
      </ReflexElement>
    </ReflexContainer>
  )
})
