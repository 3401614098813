"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WORKSET_ACTION = void 0;
var WORKSET_ACTION;

(function (WORKSET_ACTION) {
  WORKSET_ACTION["DownloadItem"] = "DownloadItem";
  WORKSET_ACTION["AddToWorkset"] = "AddToWorkset";
  WORKSET_ACTION["EditMeta"] = "EditMeta";
  WORKSET_ACTION["AddAttachment"] = "AddAttachment";
  WORKSET_ACTION["DeleteAttachment"] = "DeleteAttachment";
  WORKSET_ACTION["EditAttachmentMeta"] = "EditAttachmentMeta";
  WORKSET_ACTION["MakeMain"] = "MakeMain";
  WORKSET_ACTION["AddWorksetGroupInWorkset"] = "AddWorksetGroupInWorkset";
  WORKSET_ACTION["AddToProject"] = "AddtoProject";
})(WORKSET_ACTION = exports.WORKSET_ACTION || (exports.WORKSET_ACTION = {}));