import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../ODIcon'
import { Label } from './Common'
import { WORKSET_FILTER_MENU } from './ODImageGridSearchToolBar'
import { OptionButton } from './SelectOptions'

type WorksetFilterToggleProps = {
  worksetFilter: WORKSET_FILTER_MENU
  handleChange: (v: string) => void
}

const ButtonWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #f0f3f5;
  width: 360px;
  height: 34px;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const WorksetFilterToggle: React.FC<WorksetFilterToggleProps> = props => {
  const { worksetFilter, handleChange } = props

  const list = [WORKSET_FILTER_MENU.All, WORKSET_FILTER_MENU.NotAdded, WORKSET_FILTER_MENU.Added]
  const name = 'WorksetFilterToggle'

  const WorksetFilterIcon = (v: string) => {
    if (v === WORKSET_FILTER_MENU.All) {
      return ODIcons.CoreLibrary
    } else if (v === WORKSET_FILTER_MENU.NotAdded) {
      return ODIcons.CoreGridSlash
    } else {
      return ODIcons.CoreGrid
    }
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <RowWrapper>
        <Label style={{ fontWeight: 'bold', minWidth: 106 }}>Workset</Label>
        <ButtonWrapper>
          {list.map((v, i) => {
            return (
              <React.Fragment key={i}>
                <OptionButton
                  type="radio"
                  name={name}
                  id={v}
                  key={i}
                  checked={v === worksetFilter.toString()}
                  onChange={() => handleChange(v)}
                />
                <label htmlFor={v}>
                  <ODIcon icon={WorksetFilterIcon(v)} style={{ fontSize: 14, marginRight: 8 }} />
                  {v}
                </label>
              </React.Fragment>
            )
          })}
        </ButtonWrapper>
      </RowWrapper>
    </div>
  )
}
