import { ORG_ROLE, ORG_USER_PRIV, ORG_USER_PRIV_TO_STRING, USER_TYPE } from '@cils/common'
import { useODModalConfirm, useWrappedAPI } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Badge, Button, Card, CardBody } from 'reactstrap'
import {
  GQLListableOrgHasUser,
  GQLListableOrgHasUserInput,
  GQLOkResponse,
  GQLOrgHasUser,
  GQLSingleIDInput,
  GQLUser,
} from '../../@types/server'
import { Clickable } from '../../components/Clickable'
import { FlexContentsContainer } from '../../components/FlexContentsContainer'
import { LastLoginToken } from '../../components/LastLoginToken'
import { ODTabBarButton, ODTabBarContainer } from '../../components/ODTabBarButton'
import { useCounter, useODQuery, useODQuery2 } from '../../context/ODCommon'
import { useAPIs } from '../../context/useAPIs'
import { useOrgPerm } from '../../context/useOrgPerm'
import { ODColors } from '../../global-styles'
import { USER_FULL_SNAPSHOT } from '../../gqls'
import { createODListableContext, ODListableOption, ODListableResponseType } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils'
import { ChangeUserOrgPermissionModal } from './User/PermissionModal/ChangeUserOrgPermissionModal'

type Props = {
  orgId: number | string
  showAgent?: boolean
  showAddUser?: boolean
}

interface DataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLOrgHasUser, DataLoaderOption>()

const GQL_LIST_USERS = `
query listOrgHasUser($data: ListableOrgHasUserInput!) {
  listOrgHasUser(data: $data) {
    list {
      user {
        ${USER_FULL_SNAPSHOT}
        owners {
          userId
          name
          email
        }
      }
      joinId
      orgUserPriv
      createdAt
      lastLoginToken
    }
    totalCount
    page
    pageSize
  }
}
`

export const GQL_GET_NUM_PENDING_USERS_OF_ORG = `
query getNumPendingUsersOfOrg($data: SingleIDInput!) {
  getNumPendingUsersOfOrg(data: $data) {
    ok
  }
}`

export const OrgHasUserListContainer: React.FC<Props> = ({ orgId, showAgent = false, showAddUser = false }) => {
  const listOrgHasUsers = useODQuery<GQLListableOrgHasUserInput, GQLListableOrgHasUser>(GQL_LIST_USERS)
  const permUtils = useOrgPerm(parseInt(orgId.toString(), 10))
  const { resetUserPassword: apiResetUserPassword } = useAPIs()
  const { data, refetch: refetchPendingUsers } = useODQuery2<GQLSingleIDInput, GQLOkResponse>(
    GQL_GET_NUM_PENDING_USERS_OF_ORG,
    {
      skip: false,
      pickFirstKey: true,
      refreshInterval: 5000,
      variables: {
        id: parseInt(orgId.toString(), 10),
      },
    }
  )
  const numPendingUsers = data?.ok || 0

  const [loading, setLoading] = React.useState(false)
  const [selected, setSelected] = React.useState(0)
  const [token, increaseToken] = useCounter()
  const [permissionEditing, setPermissionEditing] = React.useState(0)

  const resetUserPassword = useWrappedAPI(
    (userId: number) => apiResetUserPassword({ id: userId }),
    loading,
    setLoading,
    {
      onSuccess: async output => {
        const { password } = output
        alert(`Generated password : \n\n${password}`)
      },
      successMessage: 'Password has been successfully reset.',
    }
  )

  const handleChangeOrgHasUserPriv = async (v: GQLOrgHasUser) => {
    setPermissionEditing(v.user.userId)
  }

  const dataLoader = React.useCallback(
    async function OrgHasUserDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: DataLoaderOption
    ): Promise<ODListableResponseType<GQLOrgHasUser>> {
      const priv = showAgent
        ? null
        : selected === 0
        ? [
            ORG_USER_PRIV.Rejected,
            ORG_USER_PRIV.Normal,
            ORG_USER_PRIV.Guest,
            ORG_USER_PRIV.DataAdmin,
            ORG_USER_PRIV.SystemAdmin,
          ]
        : [ORG_USER_PRIV.Pending]

      const r = await listOrgHasUsers({
        orgId: parseInt(orgId.toString(), 10),
        userId: null,
        page,
        pageSize,
        filter: options.filter || null,
        userType: showAgent ? USER_TYPE.Agent : USER_TYPE.Normal,
        priv,
      })
      return r as ODListableResponseType<GQLOrgHasUser>
    },
    [listOrgHasUsers, orgId, showAgent, selected]
  )

  const TableDefinition: ODListableTableDefinition<GQLOrgHasUser, DataLoaderOption> = [
    {
      id: 'userId',
      title: 'User Number',
      transform: v => v.user.userId,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'username',
      title: showAgent ? 'Agent ID' : 'ID',
      transform: v => (v.user.username ?? '').toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'name',
      title: showAgent ? 'Agent Name' : 'Name',
      transform: v => {
        if (showAgent) {
          return <Link to={SiteUrls.OrgAdmin.Agent.Edit(v.user.userId)(orgId)}>{v.user.name}</Link>
        }
        return v.user.name
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'email',
      title: showAgent ? 'Agent Email' : 'Email',
      transform: v => v.user.email,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'agentOwners',
      title: 'Agent Owners',
      transform: v => {
        if (v.user.owners!.length === 0) {
          return <span>No owner</span>
        }

        return (
          <div>
            {v.user.owners!.map((owner: GQLUser) => (
              <div>
                {owner.name} ({owner.email})
              </div>
            ))}
          </div>
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
      hide: !showAgent,
    },
    {
      id: 'regDate',
      title: 'Registration Time',
      transform: v => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'perm',
      title: 'Permission',
      transform: v => (
        <Clickable onClick={() => handleChangeOrgHasUserPriv(v)}>
          {ORG_USER_PRIV_TO_STRING[v.orgUserPriv as ORG_USER_PRIV]}
        </Clickable>
      ),
      thClass: 'text-left',
      className: 'text-left user-td',
      hide: showAgent,
    },
    {
      id: 'lastLogin',
      title: showAgent ? 'Last Access' : 'Last Visit',
      transform: v => <LastLoginToken dateToken={v.user.lastAccessTime} />,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})

  const handleResetPassword = async (user: GQLOrgHasUser) => {
    if (
      await confirm({
        title: `Reset password`,
        message: `Reset password for user ${user.user.name}?`,
        yes: 'Reset password',
        no: 'Cancel',
      })
    ) {
      await resetUserPassword(user.user.userId)
    }
  }

  if (selected === 0) {
    TableDefinition.push({
      id: 'resetPassword',
      title: 'Reset Password',
      transform: v => (
        <span style={{ cursor: 'pointer', color: ODColors.Primary }} onClick={() => handleResetPassword(v)}>
          Reset
        </span>
      ),
      thClass: 'text-center',
      className: 'text-center user-td',
    })
  }

  return (
    <FlexContentsContainer>
      <ConfirmComponent {...confirmProps} />
      {orgId && (
        <ChangeUserOrgPermissionModal
          show={permissionEditing > 0}
          onClose={updated => {
            setPermissionEditing(0)
            if (updated) {
              increaseToken()
              refetchPendingUsers()
            }
          }}
          orgId={parseInt(orgId.toString(), 10)}
          userId={permissionEditing}
        />
      )}
      <ODTabBarContainer>
        <ODTabBarButton active={selected === 0} onClick={() => setSelected(0)}>
          <strong>{showAgent ? 'Agent' : 'All'}</strong>
        </ODTabBarButton>
        {!showAgent && (
          <ODTabBarButton active={selected === 1} onClick={() => setSelected(1)}>
            <strong>Pending</strong>
            {numPendingUsers > 0 && (
              <Badge color="danger" style={{ borderRadius: 8, minWidth: 23, minHeight: 16, float: 'right' }}>
                <div>{numPendingUsers}</div>
              </Badge>
            )}
          </ODTabBarButton>
        )}
      </ODTabBarContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.joinId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={token.toString()}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name, email"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              {showAddUser && permUtils?.isAllowed(ORG_ROLE.CREATE_USER) && (
                <div>
                  <Link to={SiteUrls.OrgAdmin.User.Add(orgId)} style={{ textDecoration: 'none' }}>
                    <Button block color="primary" style={{ minWidth: 176 }}>
                      Add User
                    </Button>
                  </Link>
                </div>
              )}
              {showAgent && permUtils?.isAllowed(ORG_ROLE.CREATE_AGENT) && (
                <div style={{ marginLeft: 30 }}>
                  <Link to={SiteUrls.OrgAdmin.Agent.Add(orgId)} style={{ textDecoration: 'none' }}>
                    <Button block color="primary" style={{ minWidth: 135 }}>
                      New Agent
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => (selected === 0 ? 'No registered user.' : 'No pending user.')}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
