import React from 'react'
import styled from 'styled-components'
import logo from '../assets/img/brand/logo-symbol-w.png'

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 134px;
  border-radius: 67px;
  object-fit: contain;
  background-color: #0000004c;
`

const SpinningImage = styled.img`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 4s linear infinite;
`

const MessageSpan = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
`

type SmallLoadingProps = {
  message?: string
}

export const SmallLoading: React.FC<SmallLoadingProps> = ({ message = 'loading' }) => {
  return (
    <Circle>
      <SpinningImage alt="waiting icon" src={logo} width={46} height={46} />
      <div style={{ height: 14 }} />
      <MessageSpan>{message}</MessageSpan>
    </Circle>
  )
}
