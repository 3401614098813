import { AttachmentTypePropsInputValidation, populateAttachmentTypePropsInput } from '@cils/common'
import React from 'react'
import * as Yup from 'yup'
import { GQLAttachmentType, GQLAttachmentTypePropsInput, GQLOkResponse, GQLSingleIDInput } from '../../../@types/server'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type AttachmentTypeEditContainerProps = {
  idEditing?: number
}

const GQL_CREATE = `
mutation createAttachmentType($data: AttachmentTypePropsInput!) {
  createAttachmentType(data: $data) {
    typeId
  }
}
`

const GQL_UPDATE = `
mutation updateAttachmentType($data: AttachmentTypePropsInput!) {
  updateAttachmentType(data: $data) {
    typeId
    name
  }
}
`

const GQL_GET = `
query getAttachmentType($data: SingleIDInput!) {
  getAttachmentType(data: $data) {
    typeId
    name
  }
}
`

const GQL_REMOVE = `
mutation removeAttachmentType($data: SingleIDInput!) {
  removeAttachmentType(data: $data) {
    ok
  }
}
`

type PropsInput = GQLAttachmentTypePropsInput
type Entity = GQLAttachmentType

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: AttachmentTypePropsInputValidation.name.required('Name is required.'),
  })
}

export const AttachmentTypeEditContainer: React.FC<AttachmentTypeEditContainerProps> = props => {
  const { idEditing } = props
  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_CREATE)
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_UPDATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          return {
            id: null,
            name: '',
          }
        }
        return {
          id: data.typeId,
          name: data.name,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (idEditing) {
          await apiUpdate({ id: idEditing, ...data })
          Utils.showSuccess('Updated an attachment file type', 'Success')
        } else {
          await apiCreate({ name: data.name })
          Utils.showSuccess('Added a new attachment file type', 'Success')
        }
        return SiteUrls.Admin.Metadata.AttachmentType.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateAttachmentTypePropsInput,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('Removed an attachment file type.', 'Success')
        }
        return SiteUrls.Admin.Metadata.AttachmentType.List
      },
    }),
    [idEditing, apiCreate, apiGet, apiUpdate, apiRemove]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New Attachment File Type' : 'Edit Attachment File Type'

  return (
    <Provider title={title}>
      <ODEntityInput name="name" label="Name of the AttachmentType" placeholder="Enter name" inputType="text" />
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
      />
    </Provider>
  )
}
