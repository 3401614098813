import React from 'react'
import { GQLItem, GQLListableUserFavorItem, GQLListableUserFavorItemInput } from '../../../@types/server'
import { GQL_ITEM_SIMPLE_SNAPSHOT } from '../../../context/ODAppContext'
import { useODQuery } from '../../../context/ODCommon'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { ODListableResponseType } from '../../../ODListable/ODListableContext'
import { ItemDataLoaderOption, ItemsContainer } from './ItemsContainer'

const GQL_LIST_ITEMS = `
query listUserFavorItem($data: ListableUserFavorItemInput!) {
  listUserFavorItem(data: $data) {
    list {
      favorId
      item {
        ${GQL_ITEM_SIMPLE_SNAPSHOT}
      }
    }
    totalCount
    page
    pageSize
  }
}
`

export const FavoriteItemsContainer: React.FC = () => {
  const { orgId } = useODSingleOrgContext()
  const apiList = useODQuery<GQLListableUserFavorItemInput, GQLListableUserFavorItem>(GQL_LIST_ITEMS)

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemDataLoaderOption
    ): Promise<ODListableResponseType<GQLItem>> {
      const r = await apiList({ page, pageSize, orgId })
      return { list: r.list.map(v => v.item), page: r.page, pageSize: r.pageSize, totalCount: r.totalCount }
    },
    [apiList, orgId]
  )

  return <ItemsContainer showToolbar={false} dataLoader={dataLoader} />
}
