import { WORKSET_PERM } from '@cils/common'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { GQLWorkset, GQLWorksetPropsInput } from '../../@types/server'
import { GQLOkResponse, GQLSingleIDInput } from '../../agent'
import { useODMutation, useODQuery2 } from '../../context/ODCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils'
import { BlockingLoadBox } from '../BlockingLoadBox'
import { ODButton, ODButtonTheme } from '../ODButton'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { useModalConfirm } from './ODModalConfirm'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`
const CustomInput = styled(Input)`
  border-radius: 4px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  margin-bottom: 33px;
`
// const DeleteButton = styled.div`
//   width: 135px;
//   height: 35px;
//   border-radius: 3px;
//   border: solid 1px #f86c6b;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
// `
// const DeleteButtonText = styled.span`
//   font-size: 15px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: 0.21px;
//   text-align: center;
//   color: #f86c6b;
// `
// const SelectWrapper = styled.div`
//   display: flex;
//   padding-top: 5px;
//   padding-bottom: 10px;
// `
// const SelectsWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `
// const SelectableItemTitle = styled.div`
//   height: 20px;
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   padding-top: 4px;
//   margin-bottom: 14px;
//   color: #2e343a;
// `
// const SelectableItemDescription = styled.div`
//   font-size: 12px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: #2f353a;
// `

type ODModalEditWorksetProps = {
  isOpen: boolean
  onClose: () => void
  onSave: (v: { name: string; perm: WORKSET_PERM }) => Promise<boolean> // return false NOT to close.
  title: string
  isDelete: boolean
  handleRemoveWorkset: () => void
  initialWorkset?: GQLWorkset
}

export const ODModalEditWorkset: React.FC<ODModalEditWorksetProps> = props => {
  const { onSave, title, isDelete, isOpen, onClose, handleRemoveWorkset, initialWorkset } = props

  const [name, setName] = useState<string>(initialWorkset?.name || '')
  const [perm, setPerm] = useState<WORKSET_PERM>((initialWorkset?.defaultPerm || WORKSET_PERM.Private) as WORKSET_PERM)
  const inputRef = useRef()

  useEffect(() => {
    setName(initialWorkset?.name || '')
    setPerm((initialWorkset?.defaultPerm || WORKSET_PERM.Private) as WORKSET_PERM)
  }, [isOpen, initialWorkset])

  useEffect(() => {
    if (isOpen) {
      const handler = setTimeout(() => {
        document.getElementById('edit_workset_name')?.focus()
      }, 100)
      return () => clearTimeout(handler)
    }
  }, [isOpen])

  const handleModalClose = async (v: { name: string; perm: WORKSET_PERM } | boolean) => {
    if (v === true) {
      onClose()
    } else if (v === false) {
      onClose()
    } else {
      if (await onSave({ name, perm })) {
        onClose()
      }
    }
  }

  return (
    <ODModal isOpen={isOpen} toggle={() => null} fade={false}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={title} onClose={() => handleModalClose(false)} />
        <ODModalBody>
          <Title>Name</Title>
          <CustomInput
            ref={inputRef}
            type="text"
            id="edit_workset_name"
            name="name"
            placeholder="Name ..."
            autoFocus
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.keyCode === 13) {
                handleModalClose({ name, perm })
              }
            }}
            style={{ marginTop: 9 }}
          />
          {/*<Title style={{ marginBottom: 20 }}>Privacy</Title>*/}
          {/*<SelectsWrapper>*/}
          {/*  <SelectWrapper>*/}
          {/*    <FormGroup check className="radio">*/}
          {/*      <Input*/}
          {/*        className="form-check-input"*/}
          {/*        type="radio"*/}
          {/*        id="public"*/}
          {/*        name="privacy"*/}
          {/*        value={WORKSET_PERM.Public}*/}
          {/*        checked={perm === WORKSET_PERM.Public}*/}
          {/*        disabled*/}
          {/*        onChange={() => onClickRadio(WORKSET_PERM.Public)}*/}
          {/*      />*/}
          {/*      <Label check className="form-check-label" htmlFor="privacy">*/}
          {/*        <SelectableItemTitle>Public</SelectableItemTitle>*/}
          {/*        <SelectableItemDescription>Anyone on the CILS can find and access.</SelectableItemDescription>*/}
          {/*      </Label>*/}
          {/*    </FormGroup>*/}
          {/*  </SelectWrapper>*/}
          {/*  <SelectWrapper>*/}
          {/*    <FormGroup check className="radio">*/}
          {/*      <Input*/}
          {/*        className="form-check-input"*/}
          {/*        type="radio"*/}
          {/*        id="company"*/}
          {/*        name="privacy"*/}
          {/*        value={WORKSET_PERM.Organization}*/}
          {/*        checked={perm === WORKSET_PERM.Organization}*/}
          {/*        disabled*/}
          {/*        onChange={() => onClickRadio(WORKSET_PERM.Organization)}*/}
          {/*      />*/}
          {/*      <Label check className="form-check-label" htmlFor="privacy">*/}
          {/*        <SelectableItemTitle>Organization</SelectableItemTitle>*/}
          {/*        <SelectableItemDescription>*/}
          {/*          Anyone at {orgProfile?.name || 'your organization'} can find and access.*/}
          {/*        </SelectableItemDescription>*/}
          {/*      </Label>*/}
          {/*    </FormGroup>*/}
          {/*  </SelectWrapper>*/}
          {/*  <SelectWrapper onClick={() => onClickRadio(WORKSET_PERM.Private)}>*/}
          {/*    <FormGroup check className="radio">*/}
          {/*      <Input*/}
          {/*        className="form-check-input"*/}
          {/*        type="radio"*/}
          {/*        id="private"*/}
          {/*        name="privacy"*/}
          {/*        value={WORKSET_PERM.Private}*/}
          {/*        checked={perm === WORKSET_PERM.Private}*/}
          {/*        onChange={() => onClickRadio(WORKSET_PERM.Private)}*/}
          {/*      />*/}
          {/*      <Label check className="form-check-label" htmlFor="privacy">*/}
          {/*        <SelectableItemTitle>Private</SelectableItemTitle>*/}
          {/*        <SelectableItemDescription>*/}
          {/*          Only you and CILS Administer can watch and download Private TCF Files*/}
          {/*        </SelectableItemDescription>*/}
          {/*      </Label>*/}
          {/*    </FormGroup>*/}
          {/*  </SelectWrapper>*/}
          {/*</SelectsWrapper>*/}
        </ODModalBody>
        <ODModalFooter>
          {isDelete ? (
            <ODButton theme={ODButtonTheme.Cancel} onClick={() => handleRemoveWorkset()} style={{ width: '35%' }}>
              Delete
            </ODButton>
          ) : null}
          <ODButton
            theme={ODButtonTheme.Primary}
            onClick={() => handleModalClose({ name, perm })}
            style={{ width: isDelete ? '65%' : '100%' }}
          >
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

type Props = {
  wsId: number
  onClose: () => void
}

const GQL_GET_WORKSET = `
query getWorkset($data: SingleIDInput!) {
  getWorkset(data: $data) {
    organization {
      name
    }
    name
    defaultPerm
  }
}
`

const GQL_UPDATE_WORKSET = `
mutation updateWorkset($data: WorksetPropsInput!) {
  updateWorkset(data: $data) {
    wsId
  }
}
`

export const GQL_REMOVE_WORKSET = `
mutation removeWorkset($data: SingleIDInput!) {
  removeWorkset(data: $data) {
    ok
  }
}
`

export const ODModalUpdateWorkset: React.FC<Props> = ({ wsId, onClose }) => {
  const { data: workset, error, loading } = useODQuery2<GQLSingleIDInput, GQLWorkset>(GQL_GET_WORKSET, {
    pickFirstKey: true,
    variables: { id: wsId },
  })
  const [shouldRedirect, setShouldRedirect] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const { orgId } = useODSingleOrgContext()
  const apiUpdateWorkset = useODMutation<Partial<GQLWorksetPropsInput>, GQLWorkset>(GQL_UPDATE_WORKSET)
  const apiRemoveWorkset = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE_WORKSET)

  const updateWorkset = React.useCallback(
    async (name: string, perm: WORKSET_PERM) => {
      try {
        setUpdating(true)
        // @ts-ignore
        await apiUpdateWorkset({ wsId, name, defaultPerm: perm })
        Utils.showSuccess('Updated workset')
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setUpdating(false)
      }
    },
    [apiUpdateWorkset, setUpdating, wsId]
  )

  const { Component, confirm, props: confirmComponentProps } = useModalConfirm({
    title: 'Delete workset!',
    message: 'This cannot be undone. Are you sure? ',
    yes: 'Confirm',
    no: 'Cancel',
  })

  React.useEffect(() => {
    if (error) {
      Utils.showError(error)
    }
  }, [error])

  const handleRemoveWorkset = async () => {
    await confirm()
    try {
      await apiRemoveWorkset({ id: wsId })
      Utils.showSuccess('Removed a workset', 'Success')
      setShouldRedirect(true)
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  const handleUpdateWorkset = async ({ name, perm }: { name: string; perm: WORKSET_PERM }) => {
    return updateWorkset(name, perm).then(
      () => true,
      () => false
    )
  }

  if (shouldRedirect) {
    return <Redirect to={SiteUrls.User.Org.Workset.Main(orgId)} />
  }

  if (loading) {
    return <BlockingLoadBox show />
  }

  return (
    <>
      <Component {...confirmComponentProps} />
      {updating && <BlockingLoadBox show />}
      <ODModalEditWorkset
        isDelete
        isOpen
        handleRemoveWorkset={handleRemoveWorkset}
        onSave={handleUpdateWorkset}
        onClose={onClose}
        title="Edit Workset"
        initialWorkset={workset}
      />
    </>
  )
}
