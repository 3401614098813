import { LOG_LEVEL } from '@cils/common'
import moment from 'moment'
import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { GQLAgentLog, GQLListableAgentLog, GQLListableAgentLogInput } from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { NoMoreComponent } from '../../../components/NoMoreComponent'
import { SmallLoading } from '../../../components/SmallLoading'
import { useODQuery } from '../../../context/ODCommon'
import { ODListableAutoLoader } from '../../../ODListable/ODListableAutoLoader'
import {
  createODListableContext,
  ODListableOption,
  ODListableReducerState,
  ODListableResponseType,
  ODListableStyle,
} from '../../../ODListable/ODListableContext'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { Utils } from '../../../utils'

export interface AgentLogsLoaderOption extends ODListableOption {
  pid: string
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLAgentLog, AgentLogsLoaderOption>()

const GQL_LIST_AGENT_LOGS = `
query listAgentLogs($data: ListableAgentLogInput!) {
  listAgentLogs(data: $data) {
    list {
      logId
      pid
      message
      level
      user {
        userId
        email
        name
      }
      ts
    }
    totalCount
    page
    pageSize
  }
}
`

type Props = {
  pid: string
}

const COLORS_PER_LEVEL = {
  [LOG_LEVEL.DEBUG]: 'black',
  [LOG_LEVEL.INFO]: 'gray',
  [LOG_LEVEL.WARN]: 'yellow',
  [LOG_LEVEL.ERROR]: 'red',
}

const LEVEL_NAME = {
  [LOG_LEVEL.DEBUG]: 'DEBUG',
  [LOG_LEVEL.INFO]: 'INFO',
  [LOG_LEVEL.WARN]: 'WARN',
  [LOG_LEVEL.ERROR]: 'ERROR',
}

export const AgentLogsContainer: React.FC<Props> = ({ pid }) => {
  const apiList = useODQuery<Partial<GQLListableAgentLogInput>, GQLListableAgentLog>(GQL_LIST_AGENT_LOGS)

  const dataLoader = React.useCallback(
    async function OrgDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AgentLogsLoaderOption
    ): Promise<ODListableResponseType<GQLAgentLog>> {
      const r = await apiList({
        page,
        pageSize,
        filter: options.filter || null,
        pid,
        isAsc: true,
        afterKey: afterKey ? parseInt(afterKey, 10) : null,
      })
      return r as ODListableResponseType<GQLAgentLog>
    },
    [apiList, pid]
  )
  const TableDefinition: ODListableTableDefinition<GQLAgentLog, AgentLogsLoaderOption> = [
    {
      id: 'id',
      title: 'LogId',
      transform: v => v.logId.toString(),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'userId',
      title: 'User',
      transform: v => `${v.user?.userId || ''} (${v.user?.name || ''})`,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'time',
      title: 'Time',
      transform: v => {
        return moment(new Date(parseInt(v.ts, 10))).format('YYYY-MM-DD HH:mm:ss.SSS')
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'level',
      title: 'Level',
      transform: v => {
        return <span style={{ color: COLORS_PER_LEVEL[v.level] }}>{LEVEL_NAME[v.level]}</span>
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'logs',
      title: 'Logs',
      transform: v => {
        return v.message || ''
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  const afterKeyExtractor = React.useCallback(
    (lastItem: GQLAgentLog | null, state: ODListableReducerState<GQLAgentLog, AgentLogsLoaderOption>) => {
      return lastItem?.logId?.toString() || null
    },
    []
  )

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Agent Logs (pid: {pid})</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.logId.toString()}
            pageSize={100}
            searchOnLoad
            style={ODListableStyle.TimelineStyle}
            onDataLoaderError={Utils.showError}
            afterKeyExtractor={afterKeyExtractor}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderEmpty={() => 'No result.'}
            />
            <ODListableAutoLoader
              retryOnClick
              listableContext={Context}
              loadingComponent={SmallLoading}
              noMoreComponent={NoMoreComponent}
            />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
