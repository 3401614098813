import React, { useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import styled from 'styled-components'
import { ODIcon, ODIcons } from './ODIcon'

const TextBox = styled.div`
  display: flex;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
`
const Modal = styled.div`
  position: relative;
  display: inline-block;
`
const ModalContent = styled.div`
  display: none;
  position: absolute;
  width: 422px;
  height: 309px;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  border: 1px solid #ececec;
  z-index: 9999;
  flex-direction: column;
  padding: 24px;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SaveButton = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const SaveButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`
const CloseWrapper = styled.div`
  position: absolute;
  right: 22px;
  top: 18px;
  cursor: pointer;
  z-index: 9999;
`

enum PrivacyValue {
  Public = 'Public',
  Company = 'Company',
  Private = 'Private',
}

type Props = {
  handleSave: (v: PrivacyValue) => void
}

export const ChangePermModal: React.FC<Props> = props => {
  const { handleSave } = props

  const [isShow, setIsShow] = useState(false)
  const [value, setValue] = useState(PrivacyValue.Public)

  const onSave = () => {
    handleSave(value)
    setIsShow(false)
  }

  return (
    <Modal>
      <TextBox onClick={() => setIsShow(!isShow)}>
        <ODIcon icon={ODIcons.CorePencil} style={{ fontSize: 14, color: '#73818f', cursor: 'pointer' }} />
      </TextBox>
      <ModalContent style={{ display: isShow ? 'flex' : 'none' }}>
        <CloseWrapper onClick={() => setIsShow(!isShow)}>
          <ODIcon icon={ODIcons.CoreX} style={{ color: '#bdbdbd', fontSize: 18, cursor: 'pointer' }} />
        </CloseWrapper>
        <ColumnWrapper>
          <FormGroup check>
            <Input
              className="form-check-input"
              type="radio"
              id={PrivacyValue.Public}
              name="perm"
              value={PrivacyValue.Public}
              checked={value === PrivacyValue.Public}
              onChange={() => setValue(PrivacyValue.Public)}
            />
            <Label className="form-check-label" htmlFor="perm" style={{ fontWeight: 'bold' }}>
              Public
            </Label>
            <p>Anyone on the CILS can find and access.</p>
          </FormGroup>
          <FormGroup check>
            <Input
              className="form-check-input"
              type="radio"
              id={PrivacyValue.Company}
              name="perm"
              value={PrivacyValue.Company}
              checked={value === PrivacyValue.Company}
              onChange={() => setValue(PrivacyValue.Company)}
            />
            <Label className="form-check-label" htmlFor="perm" style={{ fontWeight: 'bold' }}>
              Tomocube Inc.
            </Label>
            <p>Anyone at Tomocube Inc. can find and access.</p>
          </FormGroup>
          <FormGroup check>
            <Input
              className="form-check-input"
              type="radio"
              id={PrivacyValue.Private}
              name="perm"
              value={PrivacyValue.Private}
              checked={value === PrivacyValue.Private}
              onChange={() => setValue(PrivacyValue.Private)}
            />
            <Label className="form-check-label" htmlFor="perm" style={{ fontWeight: 'bold' }}>
              Private
            </Label>
            <p>Only you and CILS Administer can watch and download Private TCF Files</p>
          </FormGroup>
          <RowWrapper style={{ marginTop: 20 }}>
            <SaveButton onClick={onSave}>
              <SaveButtonText>Save</SaveButtonText>
            </SaveButton>
          </RowWrapper>
        </ColumnWrapper>
      </ModalContent>
    </Modal>
  )
}
