import React from 'react'
import styled from 'styled-components'

export type ContextMenuList = {
  title: string
  callback: (v: any) => void
}

type useContextMenuProps = {
  list: Array<ContextMenuList | null>
  ref: any
}

const ContextMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 200px;
  min-height: 1px;
  box-shadow: 0px 2px 10px #999999;
  border-radius: 3px;
`
const ContextMenuItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 3px;

  &:hover {
    background-color: #ececec;
  }
`

export function useContextMenu({ list, ref }: useContextMenuProps) {
  const [x, setX] = React.useState<number>(0)
  const [y, setY] = React.useState<number>(0)
  const [showMenu, setShowMenu] = React.useState<boolean>(false)

  const handleClick = () => {
    setShowMenu(false)
  }

  const handleContextMenu = (e: any) => {
    e.preventDefault()

    if (ref && ref.current.contains(e.target)) {
      setX(e.pageX)
      setY(e.pageY)
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  const MenuComponent = (() => {
    if (!showMenu) {
      return null
    }

    return (
      <ContextMenuWrapper style={{ position: 'absolute', top: y + 5 + 'px', left: x + 10 + 'px', zIndex: 3 }}>
        {list.map((v, i) => {
          if (v === null) {
            return null
          }

          return (
            <ContextMenuItems
              key={i}
              onClick={() => {
                v.callback(v)
                setShowMenu(false)
              }}
            >
              {v.title}
            </ContextMenuItems>
          )
        })}
      </ContextMenuWrapper>
    )
  })()

  React.useEffect(() => {
    document.addEventListener('click', handleClick)
    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  })

  return { MenuComponent }
}
