/**
 * ODEntityEditorContext 는 오디코드에서 사용하는 하나의 Entity 에 대한 생성 및 수정을 처리할 수 있도록
 * 만든 기초 컨텍스트이다.
 *
 * Formik 과 yup 을 이용한 validation 및 type-graphql 을 이용한 서버 entity type 과 강하게
 * binding 되어 구현되어 있다.
 *
 * 향후 기능이 계속 추가될 가능성이 있다.
 *
 * T => Original item type which is shared with server.
 * C => Converted item type which is only used with client. T => C (converted) => T
 */
import { Formik } from 'formik'
import { FormikErrors } from 'formik/dist/types'
import { Draft, produce } from 'immer'
import { clone, pick } from 'lodash'
import React, { createContext, ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { ObjectSchema, Shape } from 'yup'
import { BlockingLoadBox } from '../components/BlockingLoadBox'
import { FormikReactstrapForm } from './FormComponents/FormikReactstrapForm'
import { ODEntityEditorHeader } from './FormComponents/ODEntityEditorHeader'

/**
 * 새로 만들 때, 최초 값을 만들어주는 함수이다.
 * 통상적으로 synchronous code 를 사용하게 되나, 서버에서 최초 값을 받아온다거나 하는 경우 promise 를 이용할 수 있다.
 *
 * 최초 생성시에는 null 을 반환하고 ServerValueToClientValueMapper 에서 null 에 해당하는 client value 를 주면 된다.
 */
type InitialValueLoader<T> = () => Promise<T | null>

type ServerValueToClientValueMapper<T, C> = (serverValue: T | null) => Promise<C>

// 클라이언트 value C 를 서버에 저장하고 redirect 할 url 을 반환한다. redirect 하지 않을 땐 null 을 반환하며 된다.
type InsertClientValueToServer<C> = (changedClientValue: C) => Promise<string | null>
type UpdateClientValueToServer<C> = (
  changedClientValue: Partial<C>,
  clientValue?: C,
  originalValue?: C
) => Promise<string | null>

// type ODEntityEditorReducerOptions<T, C> = {}

//
// Actions
//
enum ODEntityEditorContextActionType {
  TYPE_SET_LOADING = 'ODEntityEditorContext/TYPE_SET_LOADING',
  TYPE_INITIAL_VALUE_LOADED = 'ODEntityEditorContext/TYPE_INITIAL_VALUE_LOADED',
  TYPE_SET_URL_TO_REDIRECT = 'ODEntityEditorContext/TYPE_SET_URL_TO_REDIRECT',
  TYPE_SET_SAVING = 'ODEntityEditorContext/TYPE_SET_SAVING',
  TYPE_SET_DELETING = 'ODEntityEditorContext/TYPE_SET_DELETING',
}

type ActionSetLoading = {
  type: ODEntityEditorContextActionType.TYPE_SET_LOADING
  loading: boolean
}

const actionSetLoading = (loading: boolean): ActionSetLoading => ({
  type: ODEntityEditorContextActionType.TYPE_SET_LOADING,
  loading,
})

type ActionInitialValueLoaded<T, C> = {
  type: ODEntityEditorContextActionType.TYPE_INITIAL_VALUE_LOADED
  serverValue: T | null
  clientValue: C
}

const actionInitialValueLoaded = <T extends {}, C>(
  serverValue: T | null,
  clientValue: C
): ActionInitialValueLoaded<T, C> => ({
  type: ODEntityEditorContextActionType.TYPE_INITIAL_VALUE_LOADED,
  serverValue,
  clientValue,
})

type ActionSetUrlToRedirect = {
  type: ODEntityEditorContextActionType.TYPE_SET_URL_TO_REDIRECT
  urlToRedirect: string
}

const actionSetUrlToRedirect = (urlToRedirect: string): ActionSetUrlToRedirect => ({
  type: ODEntityEditorContextActionType.TYPE_SET_URL_TO_REDIRECT,
  urlToRedirect,
})

type ActionSetSaving = {
  type: ODEntityEditorContextActionType.TYPE_SET_SAVING
  saving: boolean
}

const actionSetSaving = (saving: boolean): ActionSetSaving => ({
  type: ODEntityEditorContextActionType.TYPE_SET_SAVING,
  saving,
})

type ActionSetDeleting = {
  type: ODEntityEditorContextActionType.TYPE_SET_DELETING
  deleting: boolean
}

const actionSetDeleting = (deleting: boolean): ActionSetDeleting => ({
  type: ODEntityEditorContextActionType.TYPE_SET_DELETING,
  deleting,
})

export type ODEntityEditorReducerAction<T, C> =
  | ActionSetLoading
  | ActionInitialValueLoaded<T, C>
  | ActionSetUrlToRedirect
  | ActionSetSaving
  | ActionSetDeleting

interface ODEntityEditorReducer<T, C>
  extends React.Reducer<ODEntityEditorReducerState<T, C>, ODEntityEditorReducerAction<T, C>> {}

export type ODEntityEditorReducerState<T, C> = {
  loading: boolean
  initialValueLoaded: boolean
  serverValue: T | null
  clientValue: C | null
  initialValues: C | null
  urlToRedirect: string | null
  saving: boolean
  deleting: boolean
}

//
// Context APIs
//

export type ODEntityEditorContextType<T, C> = {
  state: ODEntityEditorReducerState<T, C>
  onSubmit: (data: C) => Promise<void>
  onDelete: () => Promise<void>
  validate: (values: C) => void | object | Promise<FormikErrors<C>>
}

function createEntityEditorReducer<T, C>(): ODEntityEditorReducer<T, C> {
  return function(
    state: ODEntityEditorReducerState<T, C>,
    action: ODEntityEditorReducerAction<T, C>
  ): ODEntityEditorReducerState<T, C> {
    return produce(state, draft => {
      switch (action.type) {
        case ODEntityEditorContextActionType.TYPE_SET_LOADING:
          draft.loading = action.loading
          break
        case ODEntityEditorContextActionType.TYPE_INITIAL_VALUE_LOADED:
          draft.loading = false
          draft.serverValue = action.serverValue as Draft<T>
          draft.clientValue = action.clientValue as Draft<C>
          draft.initialValues = clone(action.clientValue) as Draft<C>
          draft.initialValueLoaded = true
          break
        case ODEntityEditorContextActionType.TYPE_SET_URL_TO_REDIRECT:
          draft.urlToRedirect = action.urlToRedirect
          break
        case ODEntityEditorContextActionType.TYPE_SET_SAVING:
          draft.saving = action.saving
          break
        case ODEntityEditorContextActionType.TYPE_SET_DELETING:
          draft.deleting = action.deleting
          break
        default:
          break
      }
    })
  }
}

interface ODEntityEditorContextProps<T, C> {
  title: string
  renderInitialValueNotReady?: () => ReactNode
}

function createInitialReducerState<T, C>(): ODEntityEditorReducerState<T, C> {
  return {
    loading: true,
    initialValueLoaded: false,
    serverValue: null,
    clientValue: null,
    initialValues: null,
    urlToRedirect: null,
    saving: false,
    deleting: false,
  }
}

export type ODEntityEditorContextOptions<T, C> = {
  /**
   * 신규 아이템 생성시 => null 리턴
   * 기존 아이템 수정시 => 서버 아이템 T 를 반환
   */
  initialValueLoader: InitialValueLoader<T>
  /**
   * 신규 아이템 생성시 => 최초 Form 데이터 C 반환
   * 기존 아이템 수정시 => 서버에서 받아온 T 를 이용하여 C 를 mapping 반환
   */
  mapServerValueToClient: ServerValueToClientValueMapper<T, C>
  /**
   * 신규 아이템 생성시 => InsertClientValueToServer<C> 를 구현
   * 기존 아이템 수정시 => UpdateClientValueToServer<C> 를 구현
   *
   * 반환값으로는 저장 완료 후 redirect 할 주소를 반환할 수 있다.
   */
  saveClientValueToServer: InsertClientValueToServer<C> | UpdateClientValueToServer<C>
  /**
   * 기존 아이템 수정시에는 UpdateClientValueToServer 에서 변경사항만 반환되는데,
   * 그 때 사용할 diff 알고리즘을 따로 제공할 수 있다.
   */
  diffClientValue?: (updated: C, original: C) => Partial<C>
  /**
   * 데이터 처리 과정에서 예외 발생시 호출되는 함수
   */
  onUnexpectedError: (ex: Error) => void
  /**
   * yup 을 이용한 formik validation 룰을 반환한다.
   * common package 의 내용을 이용하도록 하여 서버/클라이언트가 공통된 validation 을 사용할 수 있도록 한다.
   */
  getValidationSchema: (values: C) => ObjectSchema<Shape<object, object>>
  /**
   * appOptions.enableDevOptions = true 인 경우, 헤더를 클릭하여 랜덤 데이터를 생성하도록 할 수 있다.
   */
  populateDevData?: () => C
  /**
   * 아이템을 삭제하는 경우
   * 반환값은 redirection 할 url
   */
  deleteItem?: () => Promise<string>
}

const DefaultInitialValueNotReady = () => {
  return <div>Loading..</div>
}

export function createODEntityEditorContext<T, C>(options: ODEntityEditorContextOptions<T, C>) {
  const Context: React.Context<ODEntityEditorContextType<T, C>> = createContext<ODEntityEditorContextType<T, C>>(
    {} as ODEntityEditorContextType<T, C>
  )

  function defaultDiffClientValue(updated: C, original: C): Partial<C> {
    const keysChanged: Array<string> = []
    Object.keys(original).forEach(key => {
      // @ts-ignore
      if (updated[key] !== original[key]) {
        keysChanged.push(key)
      }
    })

    return pick(updated, ...keysChanged)
  }

  const {
    initialValueLoader,
    mapServerValueToClient,
    saveClientValueToServer,
    onUnexpectedError,
    diffClientValue = defaultDiffClientValue,
    getValidationSchema,
    populateDevData,
    deleteItem,
  } = options

  const Provider: React.FC<ODEntityEditorContextProps<T, C>> = props => {
    const { children, title, renderInitialValueNotReady = () => <DefaultInitialValueNotReady /> } = props

    const [state, dispatch] = React.useReducer<ODEntityEditorReducer<T, C>>(
      createEntityEditorReducer<T, C>(),
      createInitialReducerState()
    )

    const getInitialValue = React.useCallback(async () => {
      dispatch(actionSetLoading(true))
      try {
        const initialServerValue: T | null = await initialValueLoader()
        const initialClientValue = await mapServerValueToClient(initialServerValue)
        dispatch(actionInitialValueLoaded(initialServerValue, initialClientValue))
      } catch (ex) {
        dispatch(actionSetLoading(false))
      }
    }, [dispatch])

    React.useEffect(() => {
      getInitialValue().catch(onUnexpectedError)
    }, [getInitialValue])

    const onSubmit = React.useCallback(
      async (data: C) => {
        dispatch(actionSetSaving(true))
        try {
          let urlToRedirect: string | null
          if (state.serverValue) {
            const diff = diffClientValue(data, state.initialValues!)
            urlToRedirect = await (saveClientValueToServer as UpdateClientValueToServer<C>)(
              diff,
              data,
              state.initialValues!
            )
          } else {
            urlToRedirect = await (saveClientValueToServer as InsertClientValueToServer<C>)(data)
          }

          if (urlToRedirect) {
            dispatch(actionSetUrlToRedirect(urlToRedirect))
          } else {
            dispatch(actionSetSaving(false))
          }
        } catch (ex) {
          dispatch(actionSetSaving(false))
          onUnexpectedError(ex)
        }
      },
      [state, dispatch]
    )

    const onDelete = React.useCallback(async () => {
      if (!deleteItem) {
        console.warn('no deleteItem callback passed.')
        return
      }

      dispatch(actionSetDeleting(true))
      try {
        const urlToRedirect = await deleteItem()
        if (urlToRedirect) {
          dispatch(actionSetUrlToRedirect(urlToRedirect))
        }
      } catch (ex) {
        dispatch(actionSetDeleting(false))
        onUnexpectedError(ex)
      }
    }, [dispatch])

    const getErrorsFromValidationError = React.useCallback((validationError: any) => {
      const FIRST_ERROR = 0
      // @ts-ignore
      return validationError.inner.reduce((errors, error) => {
        return {
          ...errors,
          [error.path]: error.errors[FIRST_ERROR],
        }
      }, {})
    }, [])

    const validate = React.useCallback(
      (values: C) => {
        const validationSchema = getValidationSchema(values)
        try {
          validationSchema.validateSync(values, { abortEarly: false })
          return {}
        } catch (error) {
          return getErrorsFromValidationError(error)
        }
      },
      [getErrorsFromValidationError]
    )

    const context: ODEntityEditorContextType<T, C> = {
      state,
      onSubmit,
      onDelete,
      validate,
    }

    if (state.urlToRedirect) {
      return <Redirect to={state.urlToRedirect} />
    }

    return (
      <Context.Provider value={context}>
        <BlockingLoadBox show={state.loading || state.saving} />
        <Container style={{ padding: 0 }}>
          <Row>
            <Col xs="12" md="12">
              <Card>
                {state.initialValues && (
                  <Formik validate={validate} initialValues={state.initialValues!} onSubmit={onSubmit}>
                    <>
                      <CardHeader>
                        <ODEntityEditorHeader title={title} populateDevData={populateDevData} />
                      </CardHeader>
                      <CardBody>
                        <FormikReactstrapForm noValidate name="simpleForm">
                          {children}
                        </FormikReactstrapForm>
                      </CardBody>
                    </>
                  </Formik>
                )}
                {!state.initialValues && renderInitialValueNotReady()}
              </Card>
            </Col>
          </Row>
        </Container>
      </Context.Provider>
    )
  }

  return { Context, Provider }
}
