export const ODColors = {
  Primary: '#29b6ca',
  Black: '#000',
  DimmedText: '#393939',
  DefaultIconColor: '',
  ContentAreaBackground: '#f2f2f2',
  Redish: '#F86C6B',
  DropdownArrow: '#73818f',
  White: '#fff',
  PaleGrey: '#f0f3f5',
  Silver: '#c8ced3',
  PaleGrey2: '#e9f7f9',
  LightBlueGrey: '#c2e9ef',
  Tealish: '#28b5c9',
  Steel: '#73818f',
  Tealish2: '#29bb98',
  Salmon: '#f86c6b',
  PurplyBlue: '#6610f2',
  DarkMint: '#4dbd74',
  CharcoalGrey: '#2f353a',
  CoolGrey: '#8f9ba6',
  GRAY500: '#8f9ba6',
  WhiteF7: '#f7f7f7',
  DarkPeriwinkle: '#6873d2',
  FadedBlue: '#6a92c7',
  Marigold: '#ffc107',
  DustyOrange: '#f4813b',
  DarkSkyBlue: '#20a8d8',
  SalmonTwo: '#f87170',
  SteelTwo: '#708393',
  DarkSkyBlueTwo: '#63c2de',
  WaterBlue: '#13a0d1',
  PinkishGrey: '#bdbdbd',
  CharcoalGreyTwo: '#2e343a',
  OffBlue: '#587fb2',
  BluishGreen: '#10a27f',
}
