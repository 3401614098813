import * as React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import styled from 'styled-components'
import { useODAppContext } from '../context/ODAppContext'
import { useCILSHelper } from '../context/ODCILSHooks'
import { SiteUrls } from '../urls'
import { ODIcon, ODIcons } from './ODIcon'
import { ODTextIcon } from './ODTextIcon'

interface Props {
  name?: string | null
  isAdmin?: boolean
}

type MenuHeaderProps = {
  title: string
}

export const MenuHeader: React.FC<MenuHeaderProps> = props => {
  return (
    <DropdownItem header tag="div" className="text-center">
      <strong>{props.title}</strong>
    </DropdownItem>
  )
}

const CenterBoldMenuItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #2f353a;
`

const menuTextIconStyle = {
  marginLeft: -6,
  marginRight: 6,
}

type ColorDotProps = {
  color: string
}

const ColorDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  float: right;
  background-color: ${(p: ColorDotProps) => p.color};
  margin-top: 6px;
`

const RightArrow = () => (
  <ColorDot color="transparent">
    <ODIcon
      icon={ODIcons.MaterialArrowRight}
      style={{ fontSize: 12, color: '#73818f', marginLeft: -1, marginTop: -10 }}
    />
  </ColorDot>
)

export const ProfileDropdown: React.FC<Props> = props => {
  const { name, isAdmin = false } = props
  const [isOpen, setIsOpen] = React.useState(false)
  const {
    state: { userProfile, appConfig },
  } = useODAppContext()

  const { currentOrg, isInsideCILSAdmin } = useCILSHelper()
  const { agentVersion = '', agentDownloadLink = '' } = appConfig || {}

  const userMenuItems = (() => {
    const comps = [<MenuHeader key="header" title="Account" />]

    comps.push(
      <Link key="user" to={SiteUrls.User.MyPage.Main}>
        <DropdownItem>My Page</DropdownItem>
      </Link>
    )

    return comps
  })()

  const orgAdminMenuItems =
    userProfile?.organizations?.map(orgHasUser => {
      const org = orgHasUser.org
      const color = org.color || 'black'
      const isInside = currentOrg?.org?.orgId === org.orgId
      return (
        <Link
          key={org.orgId}
          style={{ textDecoration: 'none', color: 'black' }}
          to={SiteUrls.User.Org.Root(org.orgId.toString())}
        >
          <DropdownItem>
            <ODTextIcon style={menuTextIconStyle} backgroundColor={color}>
              {(org.name || 'A')[0].toUpperCase()}
            </ODTextIcon>{' '}
            {org.name}
            {isInside && <ColorDot color={color} />}
            {!isInside && <RightArrow />}
          </DropdownItem>
        </Link>
      )
    }) || []

  if (orgAdminMenuItems.length > 0) {
    orgAdminMenuItems.unshift(<MenuHeader key="header" title="Organization" />)
  }

  const cilsAdminMenuItems = (() => {
    if (!isAdmin) {
      return null
    }

    const comps = [<MenuHeader key="header" title="CILS Admin" />]

    comps.push(
      <Link key="cils" to={SiteUrls.Admin.Dashboard.Main}>
        <DropdownItem>
          <ODTextIcon style={menuTextIconStyle} backgroundColor="#73818f">
            <ODIcon icon={ODIcons.MaterialSettings} style={{ fontSize: 14, marginTop: -4 }} />
          </ODTextIcon>{' '}
          CILS Admin
          {isInsideCILSAdmin && <ColorDot color="#73818f" />}
          {!isInsideCILSAdmin && <RightArrow />}
        </DropdownItem>
      </Link>
    )

    return comps
  })()

  return (
    <Dropdown nav isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="sm">
      <DropdownToggle
        caret
        style={{
          fontWeight: 'bold',
          fontSize: 13,
          paddingRight: 10,
          marginRight: 16,
          backgroundColor: 'white',
          borderColor: 'white',
        }}
      >
        {name}&nbsp;&nbsp;
      </DropdownToggle>
      <DropdownMenu right>
        {/*<MenuHeader title="Account" />*/}
        {/*<Link to={SiteUrls.Test}>*/}
        {/*  <ODDropdownItem>*/}
        {/*    <ODTextIcon style={menuTextIconStyle} backgroundColor="#73818f">*/}
        {/*      <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 14, marginTop: -4 }} />*/}
        {/*    </ODTextIcon>{' '}*/}
        {/*    Test*/}
        {/*  </ODDropdownItem>*/}
        {/*</Link>*/}
        {/*<Link to={SiteUrls.Detail}>*/}
        {/*  <ODDropdownItem>*/}
        {/*    <ODTextIcon style={menuTextIconStyle} backgroundColor="#73818f">*/}
        {/*      <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 14, marginTop: -4 }} />*/}
        {/*    </ODTextIcon>{' '}*/}
        {/*    Detail*/}
        {/*  </ODDropdownItem>*/}
        {/*</Link>*/}
        {userMenuItems}
        {orgAdminMenuItems}
        {cilsAdminMenuItems}
        <MenuHeader key="etc" title="Etc" />
        <a href={agentDownloadLink} target="_blank" rel="noopener noreferrer">
          <DropdownItem>
            <CenterBoldMenuItem>Download CILSAgent ({agentVersion})</CenterBoldMenuItem>
          </DropdownItem>
        </a>
        <Link to={SiteUrls.Logout}>
          <DropdownItem>
            <CenterBoldMenuItem>Logout</CenterBoldMenuItem>
          </DropdownItem>
        </Link>
      </DropdownMenu>
    </Dropdown>
  )
}
