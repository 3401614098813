import { CILS_AGENT_SCAN_INFO, Utils } from '@cils/common'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import { produce } from 'immer'
import * as React from 'react'
import {
  GQLAgentLoginInfo,
  GQLChangeDownloadTaskPriority,
  GQLListableProgressInput,
  GQLListableWorkspaceItem,
  GQLOkResponse,
  GQLOpenFolderInput,
  GQLOpenTomoAnalysisInput,
  GQLQueueDownloadInput,
  GQLQueueProjectDownloadInput,
  GQLQueueWorksetDownloadInput,
  GQLRemoveDownloadedItemInput,
  GQLRemoveDownloadQueueInput,
  GQLSimpleStringOutput,
  GQLWorkspaceItem,
} from '../agent'
import { useODQuery2 } from './ODCommon'

type CILSAgentContextType = {
  client: ApolloClient<any>
  state: CILSAgentReducerState
  refreshProgress: () => Promise<void>
  queueDownload: (input: GQLQueueDownloadInput) => Promise<GQLOkResponse>
  queueWorksetDownload: (input: GQLQueueWorksetDownloadInput) => Promise<GQLOkResponse>
  checkWorksetDownload: (input: GQLQueueWorksetDownloadInput) => Promise<GQLSimpleStringOutput>
  startScan: (input: void) => Promise<void>
  getScanningInfo: (input: void) => Promise<GQLSimpleStringOutput>
  getFreeDiskSpaceForDownload: (input: void) => Promise<GQLSimpleStringOutput>
  changeDownloadPriority: (input: GQLChangeDownloadTaskPriority) => Promise<GQLOkResponse>
  removeDownloadItem: (input: GQLRemoveDownloadedItemInput) => Promise<GQLOkResponse>
  queueReDownloadItem: (input: GQLRemoveDownloadedItemInput) => Promise<GQLOkResponse>
  openFolder: (input: GQLOpenFolderInput) => Promise<void>
  openTomoAnalysis: (input: GQLOpenTomoAnalysisInput) => Promise<void>
  queueProjectDownload: (input: Partial<GQLQueueProjectDownloadInput>) => Promise<GQLOkResponse>
  checkProjectDownload: (input: Partial<GQLQueueProjectDownloadInput>) => Promise<GQLSimpleStringOutput>
  removeDownloadQueue: (input: GQLRemoveDownloadQueueInput) => Promise<GQLOkResponse>
}

type CILSAgentContextProviderProps = {
  localClient?: ApolloClient<NormalizedCacheObject>
  enable: boolean
}

type CILSAgentReducerState = {
  loading: boolean
  updateCount: number
  connected: boolean // 서버가 접속 가능한가? (모를 때는 일단 true)
  initializing: boolean
  // progress: Array<GQLProgress>
  progress: Array<any>
  downloadQueued: { [dataId: string]: Date } // 실수로 인한 중복 다운로드를 막기 위해 reducer 에서 다운로드된 녀석들 저장
  workspaceItems: Array<GQLWorkspaceItem>
  scanInfo: CILS_AGENT_SCAN_INFO | null
  loginInfo: GQLAgentLoginInfo | null
}

enum CILSAgentActionType {
  SetLoading = 'odApp/SetLoading',
  SetProgress = 'odApp/SetProgress',
  SetCannotConnectServer = 'odApp/SetCannotConnectServer',
  SetDownloadQueued = 'odApp/SetDownloadQueued',
  SetLoginInfo = 'odApp/SetLoginInfo',
}

type CILSAgentActionSetLoading = { type: CILSAgentActionType.SetLoading; loading: boolean }
type CILSAgentActionSetProgress = { type: CILSAgentActionType.SetProgress; response: MixedResponse }
type CILSAgentActionSetCannotConnectServer = { type: CILSAgentActionType.SetCannotConnectServer }
type CILSAgentActionSetDownloadQueued = { type: CILSAgentActionType.SetDownloadQueued; dataId: string }
type CILSAgentActionSetLoginInfo = { type: CILSAgentActionType.SetLoginInfo; loginInfo: GQLAgentLoginInfo | null }

type CILSAgentReducerAction =
  | CILSAgentActionSetLoading
  | CILSAgentActionSetProgress
  | CILSAgentActionSetCannotConnectServer
  | CILSAgentActionSetDownloadQueued
  | CILSAgentActionSetLoginInfo

interface CILSAgentReducer extends React.Reducer<CILSAgentReducerState, CILSAgentReducerAction> {}

function createAppReducer(): CILSAgentReducer {
  return function(state: CILSAgentReducerState, action: CILSAgentReducerAction): CILSAgentReducerState {
    return produce(state, draft => {
      switch (action.type) {
        case CILSAgentActionType.SetLoading:
          draft.loading = action.loading
          break
        case CILSAgentActionType.SetProgress:
          // console.log(41, action.response)
          if (action.response) {
            draft.initializing = action.response.listProgress.initializing
            draft.updateCount += 1
            draft.connected = true
            draft.progress = action.response.listProgress.list
            draft.workspaceItems = action.response.listWorkspaceItem.list
            draft.scanInfo = JSON.parse(action.response.listProgress.scanningInfo)
          }
          break
        case CILSAgentActionType.SetCannotConnectServer:
          draft.connected = false
          draft.updateCount += 1
          break
        case CILSAgentActionType.SetDownloadQueued:
          draft.downloadQueued[action.dataId] = new Date()
          break
        case CILSAgentActionType.SetLoginInfo:
          draft.loginInfo = action.loginInfo
          if (draft.loginInfo?.userId) {
            draft.connected = true
          } else {
            draft.connected = false
          }
          break
        default:
          return
      }
    })
  }
}

const actionSetLoading = (loading: boolean): CILSAgentActionSetLoading => ({
  type: CILSAgentActionType.SetLoading,
  loading,
})
const actionSetProgress = (response: MixedResponse): CILSAgentActionSetProgress => ({
  type: CILSAgentActionType.SetProgress,
  response,
})
const actionSetCannotConnectServer = (): CILSAgentActionSetCannotConnectServer => ({
  type: CILSAgentActionType.SetCannotConnectServer,
})
const actionSetLoginInfo = (loginInfo: GQLAgentLoginInfo | null): CILSAgentActionSetLoginInfo => ({
  type: CILSAgentActionType.SetLoginInfo,
  loginInfo,
})

function createInitialAppReducerState(): CILSAgentReducerState {
  return {
    loading: false,
    initializing: false,
    connected: false,
    updateCount: 0,
    progress: [],
    downloadQueued: {},
    workspaceItems: [],
    scanInfo: null,
    loginInfo: null,
  }
}

const Context: React.Context<CILSAgentContextType> = React.createContext<CILSAgentContextType>(
  {} as CILSAgentContextType
)

const GQL_LIST_PROGRESS = `
query listProgress($data: ListableProgressInput!) {
  listProgress(data: $data) {
    list {
      dataId
      progressType
      progressStatus
      priority
      percentage
      fullSize
      finishedSize
      thumbnail
      localPath
      title
    }
    initializing
    totalCount
    page
    pageSize
    scanningInfo
  }
  listWorkspaceItem {
    list {
      dataId
      localPath
      thumbnail
      size
      guid
      stable
    }
  }
}`

const GQL_QUEUE_DOWNLOAD = `
mutation queueDownload($data: QueueDownloadInput!) {
  queueDownload(data: $data) {
    ok
  }
}
`

const GQL_QUEUE_WORKSET_DOWNLOAD = `
mutation queueWorksetDownload($data: QueueWorksetDownloadInput!) {
  queueWorksetDownload(data: $data) {
    ok
  }
}
`

const GQL_OPEN_FOLDER = `
mutation openFolder($data: OpenFolderInput!) {
  openFolder(data: $data) {
    ok
  }
}
`

const GQL_GET_LOGIN_INFO = `
query {
  getAgentLoginInfo  {
    userId
    orgName
    name
    email
    machineId
    isAgentAccount
    downloadRoot
    uploadRoot
  }
}`

const GQL_START_SCAN = `
mutation {
  startScan {
    ok
  }
}
`

const GQL_GET_SCAN_INFO = `
query {
  getScanningInfo {
    output
  }
}
`

const GQL_GET_FREE_DISK_SPACE_FOR_DOWNLOAD = `
query {
  getFreeDiskSpaceForDownload {
    output
  }
}`

const GQL_CHECK_WORKSET_DOWNLOAD = `
query checkWorksetDownload($data: QueueWorksetDownloadInput!) {
  checkWorksetDownload(data: $data) {
    output
  }
}`

const GQL_CHANGE_DOWNLOAD_PRIORITY = `
mutation changeDownloadPriority($data: ChangeDownloadTaskPriority!) {
  changeDownloadPriority(data: $data) {
    ok
  }
}
`

const GQL_REMOVE_DOWNLOAD_ITEM = `
mutation removeDownloadItem($data: RemoveDownloadedItemInput!) {
  removeDownloadItem(data: $data) {
    ok
  }
}
`

const GQL_RE_DOWNLOAD_ITEM = `
mutation reDownloadItem($data: RemoveDownloadedItemInput!) {
  reDownloadItem(data: $data) {
    ok
  }
}
`

const GQL_OPEN_TOMO_ANALYSIS = `
mutation openTomoAnalysis($data: OpenTomoAnalysisInput!) {
  openTomoAnalysis(data: $data) {
    ok
  }
}
`

// project
const GQL_QUEUE_PROJECT_DOWNLOAD = `
mutation queueProjectDownload($data: QueueProjectDownloadInput!) {
  queueProjectDownload(data: $data) {
    ok
  }
}
`

const GQL_CHECK_PROJECT_DOWNLOAD = `
query checkProjectDownload($data: QueueProjectDownloadInput!) {
  checkProjectDownload(data: $data) {
    output
  }
}
`

const GQL_REMOVE_DOWNLOAD_QUEUE = `
mutation removeDownloadQueue($data: RemoveDownloadQueueInput!) {
  removeDownloadQueue(data: $data) {
    ok
  }
}
`

type MixedResponse = {
  listProgress: any // GQLListableProgress
  listWorkspaceItem: GQLListableWorkspaceItem
}

const Provider: React.FC<CILSAgentContextProviderProps> = props => {
  const { children, localClient: client, enable } = props

  const { api: apiGetStatus } = useODQuery2<GQLListableProgressInput, MixedResponse>(GQL_LIST_PROGRESS, {
    pickFirstKey: false,
    client,
    skip: true,
  })
  const { api: apiGetLoginInfo } = useODQuery2<void, GQLAgentLoginInfo>(GQL_GET_LOGIN_INFO, {
    pickFirstKey: true,
    client,
    skip: true,
  })
  const { api: apiGetScanningInfo } = useODQuery2<void, GQLSimpleStringOutput>(GQL_GET_SCAN_INFO, {
    pickFirstKey: true,
    client,
    skip: true,
  })
  const { api: apiGetFreeDiskSpaceForDownload } = useODQuery2<void, GQLSimpleStringOutput>(
    GQL_GET_FREE_DISK_SPACE_FOR_DOWNLOAD,
    {
      pickFirstKey: true,
      client,
      skip: true,
    }
  )
  const { api: apiCheckWorksetDownload } = useODQuery2<GQLQueueWorksetDownloadInput, GQLSimpleStringOutput>(
    GQL_CHECK_WORKSET_DOWNLOAD,
    {
      pickFirstKey: true,
      client,
      skip: true,
    }
  )
  const { api: apiCheckProjectDownload } = useODQuery2<Partial<GQLQueueProjectDownloadInput>, GQLSimpleStringOutput>(
    GQL_CHECK_PROJECT_DOWNLOAD,
    {
      pickFirstKey: true,
      client,
      skip: true,
    }
  )

  const apiStartScan = React.useCallback(
    async (input: void): Promise<void> => {
      await client!.mutate({ mutation: gql(GQL_START_SCAN) })
    },
    [client]
  )

  // useMutation 이 client 를 설정하면 제대로 동작하지 않는다.
  const openFolder = React.useCallback(
    async (data: GQLOpenFolderInput) => {
      await client!.mutate({ mutation: gql(GQL_OPEN_FOLDER), variables: { data } })
    },
    [client]
  )
  // useODMutation<GQLOpenFolderInput, GQLOkResponse>(GQL_OPEN_FOLDER, 0, { client })

  // NOTE: useMutation 에 client 를 넣어도 이상하게 제대로 동작하지 않는다.
  const apiQueueDownload = React.useCallback(
    async (input: GQLQueueDownloadInput): Promise<GQLOkResponse> => {
      const r = await client!.mutate({ variables: { data: input }, mutation: gql(GQL_QUEUE_DOWNLOAD) })
      return r.data.queueDownload
    },
    [client]
  )

  const apiQueueWorksetDownload = React.useCallback(
    async (input: GQLQueueWorksetDownloadInput): Promise<GQLOkResponse> => {
      const r = await client!.mutate({ variables: { data: input }, mutation: gql(GQL_QUEUE_WORKSET_DOWNLOAD) })
      return r.data.queueWorksetDownload
    },
    [client]
  )

  const apiChangeDownloadPriority = React.useCallback(
    async (input: GQLChangeDownloadTaskPriority): Promise<GQLOkResponse> => {
      const r = await client!.mutate({ variables: { data: input }, mutation: gql(GQL_CHANGE_DOWNLOAD_PRIORITY) })
      return r.data.changeDownloadPriority
    },
    [client]
  )

  const apiRemoveDownloadItem = React.useCallback(
    async (input: GQLRemoveDownloadedItemInput): Promise<GQLOkResponse> => {
      const r = await client!.mutate({ variables: { data: input }, mutation: gql(GQL_REMOVE_DOWNLOAD_ITEM) })
      return r.data.changeDownloadPriority
    },
    [client]
  )

  const apiQueueReDownloadItem = React.useCallback(
    async (input: GQLRemoveDownloadedItemInput): Promise<GQLOkResponse> => {
      const r = await client!.mutate({ variables: { data: input }, mutation: gql(GQL_RE_DOWNLOAD_ITEM) })
      return r.data.changeDownloadPriority
    },
    [client]
  )

  const apiOpenTomoAnalysis = React.useCallback(
    async (input: GQLOpenTomoAnalysisInput): Promise<void> => {
      await client!.mutate({ variables: { data: input }, mutation: gql(GQL_OPEN_TOMO_ANALYSIS) })
    },
    [client]
  )

  const apiQueueProjectDownload = React.useCallback(
    async (input: Partial<GQLQueueProjectDownloadInput>): Promise<GQLOkResponse> => {
      const r = await client!.mutate({ variables: { data: input }, mutation: gql(GQL_QUEUE_PROJECT_DOWNLOAD) })
      return r.data.queueProjectDownload
    },
    [client]
  )

  const apiRemoveDownloadQueue = React.useCallback(
    async (input: GQLRemoveDownloadQueueInput): Promise<GQLOkResponse> => {
      const r = await client!.mutate({ variables: { data: input }, mutation: gql(GQL_REMOVE_DOWNLOAD_QUEUE) })
      return r.data.removeDownloadQueue
    },
    [client]
  )

  const [state, dispatch] = React.useReducer<CILSAgentReducer>(createAppReducer(), createInitialAppReducerState())

  const getLoginInfo = React.useCallback(async () => {
    try {
      const response = await apiGetLoginInfo()
      dispatch(actionSetLoginInfo(response))
    } catch (ex) {
      if (Utils.isServerConnectionError(ex)) {
        dispatch(actionSetCannotConnectServer())
        return
      }
      console.error(ex)
    }
  }, [apiGetLoginInfo])

  const refreshProgress: () => Promise<void> = React.useCallback(async () => {
    dispatch(actionSetLoading(true))
    try {
      const response = await apiGetStatus({ filter: '', page: 1, pageSize: 1000 })
      // console.log(100, response)
      dispatch(actionSetProgress(response))
    } catch (ex) {
      if (Utils.isServerConnectionError(ex)) {
        dispatch(actionSetCannotConnectServer())
        return
      }
      console.error(ex)
    } finally {
      dispatch(actionSetLoading(false))
    }
  }, [apiGetStatus, dispatch])

  // React.useEffect(() => {
  //   if (!enable) {
  //     return
  //   }
  //
  //   // noinspection JSIgnoredPromiseFromCall
  //   refreshProgress()
  //
  //   const handler = setInterval(() => refreshProgress(), 1000)
  //   return () => clearInterval(handler)
  // }, [enable, refreshProgress])

  React.useEffect(() => {
    if (!enable) {
      return
    }

    // noinspection JSIgnoredPromiseFromCall
    getLoginInfo()

    const handler = setInterval(() => getLoginInfo(), 15000)
    return () => clearInterval(handler)
  }, [enable, apiGetLoginInfo, getLoginInfo])

  const context: CILSAgentContextType = {
    client: client!,
    state,
    refreshProgress,
    queueDownload: apiQueueDownload,
    queueWorksetDownload: apiQueueWorksetDownload,
    startScan: apiStartScan,
    getScanningInfo: apiGetScanningInfo,
    getFreeDiskSpaceForDownload: apiGetFreeDiskSpaceForDownload,
    checkWorksetDownload: apiCheckWorksetDownload,
    changeDownloadPriority: apiChangeDownloadPriority,
    removeDownloadItem: apiRemoveDownloadItem,
    queueReDownloadItem: apiQueueReDownloadItem,
    openTomoAnalysis: apiOpenTomoAnalysis,
    queueProjectDownload: apiQueueProjectDownload,
    checkProjectDownload: apiCheckProjectDownload,
    openFolder,
    removeDownloadQueue: apiRemoveDownloadQueue,
  }
  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const CILSAgentProvider = Provider

export function useCILSAgentContext(): CILSAgentContextType {
  return React.useContext(Context)
}
