import { DOAddUserToProjectResult, PROJECT_USER_PERM } from '@cils/common'
import { BlockingLoadBox, ODVSpace, useCounter, useODListableContext, useODModalConfirm } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { GQLProject, GQLProjectHasUser } from '../../../../@types/server'
import { ODIcon, ODIcons } from '../../../../components/ODIcon'
import { ODModalCheckWorksetPermissionToAddUser } from '../../../../components/project/modal/ODModalCheckWorksetPermissionToAddUser'
import { useAPIs } from '../../../../context/useAPIs'
import { useCILSStore } from '../../../../di/configureRootStore'
import { ODColors } from '../../../../global-styles'
import { useProjectPerm } from '../../../../hooks/useProjectPerm'
import { ODListableStyle } from '../../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable2 } from '../../../../ODListable/ODListablePaginationTable2'
import { Utils } from '../../../../utils'
import { IProjectHasUserListableOption, useProjectHasUserListDataLoader } from '../list/useProjectHasUserListDataLoader'
import { PROJECT_USER_PERM_STRING } from '../ProjectCommon'
import { ProjectUserSearch } from './ProjectUserSearch'

interface IProjectUserPage {
  projectId: number
  orgId: number
  ownerOrgId: number
  myProjectPerm: number
  project: GQLProject
}

export const ProjectUserPage: React.FC<IProjectUserPage> = observer(props => {
  const { projectId, orgId, myProjectPerm, project } = props
  const { removeProjectHasUser, checkProjectHasUser } = useAPIs()
  const store = useCILSStore()
  const [loading, setLoading] = React.useState(false)
  const [isOpenChangeProjectPerm, setIsOpenChangeProjectPerm] = React.useState<DOAddUserToProjectResult | null>(null)
  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})
  const dataLoader = useProjectHasUserListDataLoader(projectId)
  const { Provider, Context } = useODListableContext<GQLProjectHasUser, IProjectHasUserListableOption>()
  const [token, refresh] = useCounter()

  const openChangePerm = async (user: GQLProjectHasUser) => {
    setLoading(true)
    try {
      const res = await checkProjectHasUser({ projectId, userId: user.userId })

      const data = {
        project,
        isSuccess: false,
        targetUser: user.user,
        messages: res.list.map(item => {
          return {
            workset: item.workset,
            myWorksetPerm: item.myWorksetPerm,
            beforeTagetWorksetPerm: item.targetWorksetPerm,
            afterTagetWorksetPerm: 0,
          }
        }),
      }

      setIsOpenChangeProjectPerm(new DOAddUserToProjectResult(store.addUserToProjectStore, data, user.permLevel))
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
    }
  }

  const handleRemoveUser = async (name: string, email: string, projectId: number, userId: number) => {
    if (
      await confirm({
        title: `Remove User`,
        message: (
          <>
            Are you sure you want to remove{' '}
            <span style={{ color: ODColors.Primary }}>
              {name}({email})
            </span>{' '}
            from the
            <br />
            project?
            <br />
            This cannot be undone.
          </>
        ),
        yes: 'Remove',
        no: 'Cancel',
      })
    ) {
      try {
        setLoading(true)
        await removeProjectHasUser({ projectId, userId })
        refresh()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
      // }
    }
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.projectId.toString()}
      pageSize={10}
      searchOnLoad
      style={ODListableStyle.TableStyle}
      refreshToken={token.toString()}
    >
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <BlockingLoadBox show={loading} />
        <ConfirmComponent {...confirmProps} />
        {isOpenChangeProjectPerm && (
          <ODModalCheckWorksetPermissionToAddUser
            isOpen={!!isOpenChangeProjectPerm}
            onClose={() => {
              setIsOpenChangeProjectPerm(null)
            }}
            data={isOpenChangeProjectPerm}
            inUserPage
            refresh={refresh}
          />
        )}
        <CardBody>
          <ProjectUserSearch
            orgId={orgId}
            projectId={projectId}
            refresh={refresh}
            listableContext={Context}
            myProjectPerm={myProjectPerm}
          />
          <ODVSpace h={30} />
          <ODListablePaginatedTable2
            numColumns={12}
            renderHeader={() => (
              <tr>
                <th>Data ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Organization</th>
                <th>Permission</th>
                <th>Action</th>
              </tr>
            )}
            renderRow={(value: GQLProjectHasUser, context) => {
              return (
                <ProjectUserTableTr
                  key={value.userId}
                  value={value}
                  myProjectPerm={myProjectPerm}
                  setIsOpenChangeProjectPerm={openChangePerm}
                  handleRemoveUser={handleRemoveUser}
                  orgId={orgId}
                />
              )
            }}
            listableContext={Context}
            eventParentContext={{}}
          />
          {/* 추후 od-react-belt 연동 전까지는 ts-ignore 사용 */}
          {/*@ts-ignore*/}
          <ODListablePagination listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
})

interface IProjectUserTableTr {
  value: GQLProjectHasUser
  myProjectPerm: number
  setIsOpenChangeProjectPerm: (user: GQLProjectHasUser) => Promise<void>
  handleRemoveUser: (name: string, email: string, projectId: number, userId: number) => void
  orgId: number
}

export const ProjectUserTableTr: React.FC<IProjectUserTableTr> = props => {
  const { value, myProjectPerm, setIsOpenChangeProjectPerm, handleRemoveUser, orgId } = props
  const projectPerm = useProjectPerm(orgId)
  // const [openTooltip, setOpenTooltip] = React.useState(false)

  const canRemoveOrChangePerm =
    (value?.permLevel ?? 0) !== PROJECT_USER_PERM.Owner && projectPerm.isSameOrGreaterThanAdmin(myProjectPerm)
  return (
    <tr key={value.userId}>
      <td>{value.user.username ?? '-'}</td>
      <td>{value.user.name ?? '-'}</td>
      <td>{value.user.email ?? '-'}</td>
      <td>{value.user.mainOrg?.name ?? '-'}</td>
      <td>
        {canRemoveOrChangePerm && (
          <div style={{ cursor: 'pointer' }} onClick={() => setIsOpenChangeProjectPerm(value)}>
            <span>{PROJECT_USER_PERM_STRING[value.permLevel]}</span>
            <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ fontSize: 24, color: ODColors.Steel }} />
          </div>
        )}
        {!canRemoveOrChangePerm && (PROJECT_USER_PERM_STRING[value.permLevel] ?? '-')}
      </td>
      <td>
        {canRemoveOrChangePerm && (
          <RemoveBox
            onClick={() =>
              handleRemoveUser(value.user.name ?? '-', value.user.email ?? '-', value.projectId, value.userId)
            }
          >
            <ODIcon icon={ODIcons.CoreXCircle} style={{ fontSize: 16, color: ODColors.White }} />
          </RemoveBox>
        )}
        {!canRemoveOrChangePerm && ''}
      </td>
    </tr>
  )
}

const RemoveBox = styled.div`
  width: 39px;
  height: 35px;
  border-radius: 2px;
  background-color: ${ODColors.Salmon};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

// const HasTooltipSpan = styled.span`
//   &:hover {
//     text-decoration: underline;
//     color: ${ODColors.PurplyBlue};
//   }
// `
