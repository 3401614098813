import { WORKSET_GROUP_SORT_OPTION } from '@cils/common'
import React, { useState } from 'react'
import { GQLWorksetGroup } from '../../../@types/server'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import {
  DropButton,
  DropDown,
  DropDownContent,
  OptionWrapper,
  Value,
} from '../../../components/Dropdown/DropdownCommon'
import { ODListableContextType } from '../../../ODListable/ODListableContext'
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick'
import { IWorksetGroupListableOption } from './WorksetGroupCommon'
import { DROPDOWN_MENU, DropDownMenuProps } from '../../../components/ODImageGrid/ODImageGridSearchToolBar'

const SortByName: { [key: string]: JSX.Element } = {
  [WORKSET_GROUP_SORT_OPTION.CreatedDateDescending]: (
    <Value>
      Created <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [WORKSET_GROUP_SORT_OPTION.CreatedDateAscending]: (
    <Value>
      Created <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [WORKSET_GROUP_SORT_OPTION.NameAscending]: (
    <Value>
      Name <span style={{ color: '#2f353a' }}>A</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
      <span style={{ color: '#2f353a' }}>Z</span>
    </Value>
  ),
  [WORKSET_GROUP_SORT_OPTION.NameDescending]: (
    <Value>
      Name <span style={{ color: '#2f353a' }}>Z</span>{' '}
      <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
      <span style={{ color: '#2f353a' }}>A</span>
    </Value>
  ),
}

type Props = {
  listableContext: React.Context<ODListableContextType<GQLWorksetGroup, IWorksetGroupListableOption>>
}

export const SortByWorksetGroupDropdown: React.FC<Props & DropDownMenuProps> = props => {
  const { isMenuOpen, setIsMenuOpen, listableContext } = props

  const [value, setValue] = useState<string>(WORKSET_GROUP_SORT_OPTION.CreatedDateDescending)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const isOpen = isMenuOpen === DROPDOWN_MENU.SortByWorksetGroup

  const {
    state: { loadOption },
    updateLoadOption,
  } = React.useContext(listableContext)

  useDetectOutsideClick(() => setIsMenuOpen(null), menuRef, isOpen)

  const onClick = (v: WORKSET_GROUP_SORT_OPTION) => {
    // @ts-ignore
    updateLoadOption({ ...loadOption, sortBy: v })
    setValue(v)
    setIsMenuOpen(null)
  }

  return (
    <DropDown ref={menuRef}>
      <DropButton onClick={() => (isOpen ? setIsMenuOpen(null) : setIsMenuOpen(DROPDOWN_MENU.SortByWorksetGroup))}>
        {SortByName[value]}
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        <OptionWrapper onClick={() => onClick(WORKSET_GROUP_SORT_OPTION.CreatedDateDescending)}>
          <Value>
            Created <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(WORKSET_GROUP_SORT_OPTION.CreatedDateAscending)}>
          <Value>
            Created <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(WORKSET_GROUP_SORT_OPTION.NameAscending)}>
          <Value>
            Name <span style={{ color: '#2f353a' }}>A</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
            <span style={{ color: '#2f353a' }}>Z</span>
          </Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onClick(WORKSET_GROUP_SORT_OPTION.NameDescending)}>
          <Value>
            Name <span style={{ color: '#2f353a' }}>Z</span>{' '}
            <ODIcon icon={ODIcons.MaterialArrowForwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />{' '}
            <span style={{ color: '#2f353a' }}>A</span>
          </Value>
        </OptionWrapper>
      </DropDownContent>
    </DropDown>
  )
}
