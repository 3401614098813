import React from 'react'
import styled from 'styled-components'
import logoSymbolColor from '../../assets/img/brand/logo-symbol-color.png'

type CILSLoadingProps = {}

const Wrapper = styled.div`
  width: 73px;
  height: 73px;
  border-radius: 9999px;
  background-color: #dff4f7;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpinningImage = styled.img`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 4s linear infinite;
`

export const CILSLoading: React.FC<CILSLoadingProps> = props => {
  return (
    <Wrapper>
      <SpinningImage src={logoSymbolColor} width={32} height={32} />
    </Wrapper>
  )
}
