import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { useCILSStore } from '../../di/configureRootStore'
import { ODIcon, ODIcons } from '../ODIcon'

interface IAddDirectoriesToWorksetButtonProps {}

export const AddDirectoriesToWorksetButton: React.FC<IAddDirectoriesToWorksetButtonProps> = observer(props => {
  const store = useCILSStore().directoryViewStore
  const isChecking = store.isCheckMode
  const maxWidth = store.directoryViewWidth

  return (
    <ButtonWrapper
      style={{ maxWidth, cursor: 'pointer' }}
      onClick={() => {
        if (!isChecking) {
          store.setCheckMode(!isChecking)
        }
      }}
    >
      <span style={{ width: 25, height: 25 }}>
        <ODIcon icon={ODIcons.MaterialAddBox} style={{ transform: 'scale(0.65)' }} />
      </span>
      Add Directories to Worksets
      {isChecking && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
          <AddAllItemsToWorksetWrapper
            onClick={() => {
              if (store.isCheckMode && store.numberOfSelectedItems > 0) {
                store.setShowAddToWorkset(true)
              }
            }}
          >
            <AddAllItemsToWorksetIconBox>
              <ODIcon
                icon={ODIcons.MaterialCreateNewFolder}
                style={{ fontSize: 17, color: '#fff', letterSpacing: 0.24 }}
              />
            </AddAllItemsToWorksetIconBox>
            <GreenButtonTextWrapper>
              {store.numberOfSelectedItems} item(s) selected
              <br />
              Add Directories to Worksets
            </GreenButtonTextWrapper>
          </AddAllItemsToWorksetWrapper>
          <CancelButton onClick={() => store.setCheckMode(false)}>Cancel</CancelButton>
        </div>
      )}
    </ButtonWrapper>
  )
})

const ButtonWrapper = styled.div`
  max-width: 100%;
  margin: 10px 10px 8px;
  padding: 10px 10px 10px 10px;
  border: solid 1px var(--primary);
  background-color: #e9f7f9;

  font-size: 14px;
  font-weight: bold;
  color: var(--primary);
`

const AddAllItemsToWorksetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 45px;
  border-radius: 4px;
  background-color: #0d92a5;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
`

const AddAllItemsToWorksetIconBox = styled.div`
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 60px; /* height 100% 로 동작하지 않는 이유를 잘 모르겠음. */
  background-color: #108090;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

const GreenButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  flex: 1;
`

const CancelButton = styled.div`
  height: 35px;
  margin: 6px 0 0;
  padding: 9px 0 8px 2px;
  border-radius: 3px;
  background-color: #c8ced3;
  color: #2f353a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
`
