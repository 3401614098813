import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SiteUrls } from '../../../urls'
import { ChangePasswordContainer } from './ChangePasswordContainer'
import { MyPageContainer } from './MyPageContainer'
import { ProfileContainer } from './ProfileContainer'

export const MyPageRootContainer: React.FC = () => {
  return (
    <div style={{ marginTop: 60 }}>
      <Switch>
        <Route path={SiteUrls.User.MyPage.Main} exact component={MyPageContainer} />
        <Route path={SiteUrls.User.MyPage.EditProfile} exact component={ProfileContainer} />
        <Route path={SiteUrls.User.MyPage.ChangePassword} exact component={ChangePasswordContainer} />
        <Redirect to={SiteUrls.User.MyPage.Main} />
      </Switch>
    </div>
  )
}
