import { WORKSET_USER_PERM, worksetUserPermissionToString } from '@cils/common'
import React, { useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../utils/useDetectOutsideClick'
import { ODIcon, ODIcons } from './ODIcon'
import { DROPDOWN_MENU } from './ODImageGrid/ODImageGridSearchToolBar'
import { ShareModalDropDownProps } from './ODModal/ODModalShare'

const TextBox = styled.div`
  display: flex;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
`
const Modal = styled.div`
  position: relative;
  display: inline-block;
`
const ModalContent = styled.div`
  display: none;
  position: absolute;
  width: 422px;
  height: 219px;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  border: 1px solid #ececec;
  z-index: 9999;
  flex-direction: column;
  padding: 24px;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SaveButton = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const SaveButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`
const PermText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #73818f;
`
const CloseWrapper = styled.div`
  position: absolute;
  right: 22px;
  top: 18px;
  cursor: pointer;
  z-index: 9999;
`

type Props = {
  index?: number
  handleSave: (v: number) => void // WORKSET_USER_PERM
  name?: string
  initialValue: number // WORKSET_USER_PERM
  handleCloseModal: (v: string) => void
}

const UserPermCheckItem = (props: {
  name: string
  index: number
  perm: number
  value: number
  setValue: (perm: number) => void
  desc?: string
}) => {
  const { name, index, perm, value, setValue, desc = '' } = props
  return (
    <FormGroup check>
      <Input
        className="form-check-input"
        type="radio"
        // id={perm}
        name={name + `_` + index}
        value={perm}
        checked={value === perm}
        onChange={() => setValue(perm)}
      />
      <Label className="form-check-label" htmlFor={name + `_` + index} style={{ fontWeight: 'bold' }}>
        {worksetUserPermissionToString(perm)}
      </Label>
      <p>{desc}</p>
    </FormGroup>
  )
}

export const ChangeMemberPermModal: React.FC<Props & ShareModalDropDownProps> = props => {
  const {
    index = 0,
    handleSave,
    name = 'memberPerm',
    initialValue,
    isMenuOpen,
    setIsMenuOpen,
    handleCloseModal,
  } = props

  const [value, setValue] = useState(WORKSET_USER_PERM.Viewer)
  const modalRef = React.useRef<HTMLDivElement>(null)
  const isModalOpen = isMenuOpen === DROPDOWN_MENU.MemberPermission + '_' + index

  useDetectOutsideClick(() => setIsMenuOpen(null), modalRef, isModalOpen)

  React.useEffect(() => {
    setValue(initialValue)
  }, [setValue, initialValue, isMenuOpen])

  const onSave = () => {
    handleSave(value)
    setIsMenuOpen(null)
  }

  return (
    <Modal ref={modalRef}>
      <TextBox onClick={() => handleCloseModal(DROPDOWN_MENU.MemberPermission + '_' + index)}>
        <PermText>{worksetUserPermissionToString(initialValue)}</PermText>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </TextBox>
      <ModalContent style={{ display: isModalOpen ? 'flex' : 'none' }}>
        <CloseWrapper onClick={() => handleCloseModal(DROPDOWN_MENU.MemberPermission + '_' + index)}>
          <ODIcon icon={ODIcons.CoreX} style={{ color: '#bdbdbd', fontSize: 18, cursor: 'pointer' }} />
        </CloseWrapper>
        <ColumnWrapper>
          <UserPermCheckItem
            name={name}
            index={index}
            perm={WORKSET_USER_PERM.Editor}
            value={value}
            setValue={setValue}
          />
          <UserPermCheckItem
            name={name}
            index={index}
            perm={WORKSET_USER_PERM.Collaborator}
            value={value}
            setValue={setValue}
          />
          <UserPermCheckItem
            name={name}
            index={index}
            perm={WORKSET_USER_PERM.Viewer}
            value={value}
            setValue={setValue}
          />
          <RowWrapper>
            <SaveButton onClick={onSave}>
              <SaveButtonText>Save</SaveButtonText>
            </SaveButton>
          </RowWrapper>
        </ColumnWrapper>
      </ModalContent>
    </Modal>
  )
}
