import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export type ODChartData = {
  labels: Array<string>
  datasets: Array<{
    label: string
    backgroundColor: string
    borderColor: string
    data: Array<number>
  }>
}

export enum ODChartDesignType {
  PointLine,
  PointBezier,
  Bezier,
  Bar,
}

export function createChartOptions(chartData: ODChartData, designType: ODChartDesignType): any {
  switch (designType) {
    case ODChartDesignType.PointLine:
      return {
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'transparent',
                zeroLineColor: 'transparent',
              },
              ticks: {
                fontSize: 2,
                fontColor: 'transparent',
              },
            },
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                display: false,
                min: Math.min(Math.min.apply(Math, chartData.datasets[0].data) - 5, 0),
                max: Math.max(Math.max.apply(Math, chartData.datasets[0].data) + 5, 10),
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0.00001,
            borderWidth: 1,
          },
          point: {
            radius: 4,
            hitRadius: 10,
            hoverRadius: 4,
          },
        },
      }
    case ODChartDesignType.PointBezier:
      return {
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'transparent',
                zeroLineColor: 'transparent',
              },
              ticks: {
                fontSize: 2,
                fontColor: 'transparent',
              },
            },
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                display: false,
                min: Math.min(Math.min.apply(Math, chartData.datasets[0].data) - 5, 0),
                max: Math.max(Math.max.apply(Math, chartData.datasets[0].data) + 5, 10),
              },
            },
          ],
        },
        elements: {
          line: {
            borderWidth: 1,
          },
          point: {
            radius: 4,
            hitRadius: 10,
            hoverRadius: 4,
          },
        },
      }

    case ODChartDesignType.Bezier:
      return {
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: false,
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
        elements: {
          line: {
            borderWidth: 2,
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
          },
        },
      }
    case ODChartDesignType.Bar:
      return {
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: false,
              barPercentage: 0.6,
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
      }
  }
}
