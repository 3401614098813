import { ODIcons } from '../components/ODIcon'
import { ODSiteNav } from '../context/ODCommon'
import { Strings } from '../i18n/res'
import { SiteUrls } from '../urls'

export const AdminSiteNavs: ODSiteNav = {
  items: [
    {
      name: Strings.Dashboard,
      url: SiteUrls.Admin.Dashboard.Main,
      icon: ODIcons.Dashboard,
      iconSpanStyle: { position: 'relative', top: 2 },
    },
    {
      name: 'Alerts',
      url: SiteUrls.Admin.Alert.List,
      icon: ODIcons.CoreWarning,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1 },
    },
    {
      name: Strings.User,
      url: SiteUrls.Admin.User.List,
      icon: ODIcons.CorePeople,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1, paddingLeft: 2, marginRight: 10 },
    },
    {
      name: 'Agent Status',
      url: SiteUrls.Admin.Agent.Main,
      icon: ODIcons.CoreGrid,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1, paddingLeft: 2, marginRight: 10 },
    },
    // {
    //   name: Strings.AgentLogs,
    //   url: SiteUrls.Admin.AgentLogs.Main,
    //   icon: ODIcons.CoreBuilding,
    //   iconStyle: { fontSize: '1rem' },
    //   iconSpanStyle: { position: 'relative', top: 1, paddingLeft: 2, marginRight: 10 },
    // },
    {
      name: Strings.Organization,
      url: SiteUrls.Admin.Organization.List,
      icon: ODIcons.CoreSiteMap,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1, paddingLeft: 2, marginRight: 10 },
    },
    {
      name: Strings.APIKey,
      url: SiteUrls.Admin.API.List,
      icon: ODIcons.CoreHttps,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1, paddingLeft: 2, marginRight: 10 },
    },
    {
      name: Strings.Metadata,
      url: SiteUrls.Admin.Metadata.AttachmentType.List,
      icon: ODIcons.CoreStorage,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 2 },
    },
    {
      name: 'System Setting',
      url: SiteUrls.Admin.AppConfig.Main,
      icon: ODIcons.CoreSettings,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 2 },
    },
    {
      name: 'Audit Log',
      url: SiteUrls.Admin.Audit.List,
      icon: ODIcons.CoreSpreadsheet,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1 },
    },
  ],
}
