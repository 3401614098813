import { useFormikContext } from 'formik'
import React, { ReactNode } from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { UseConfirmModalOptions, useModalConfirm } from '../components/ODModal/ODModalConfirm'
import { ODEntityEditorContextType } from './ODEntityEditorContext'

const FormButtonsWrapper = styled.div`
  display: flex;
  place-content: flex-end flex-end;
`

const FormButtonsLeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

type ODEntityEditorFooterProps<T, C> = {
  context: React.Context<ODEntityEditorContextType<T, C>>
  saveButtonName?: string
  deleteButtonName?: string
  deleteConfirmOptions?: UseConfirmModalOptions | null // null 을 주면 직접 confirm popup 을 처리하지 않는다.
  deleteButtonLeft?: boolean
  renderDeleteButton?: () => ReactNode
  onDeleted?: () => void
  disabled?: boolean
}

const defaultDeleteConfirmOptions: UseConfirmModalOptions = {
  message: (
    <>
      Are you sure to delete?
      <br />
      This cannot be undone.
    </>
  ),
  yes: 'Confirm',
  no: 'Cancel',
}

export function ODEntityEditorFooter<T, C>(props: ODEntityEditorFooterProps<T, C>) {
  const {
    saveButtonName = 'Save',
    deleteButtonName,
    context,
    deleteConfirmOptions,
    onDeleted,
    renderDeleteButton,
    deleteButtonLeft = false,
    disabled = false,
  } = props
  const { isSubmitting } = useFormikContext()
  const { onDelete } = React.useContext(context)

  const { Component, confirm, props: confirmComponentProps } = useModalConfirm(
    deleteConfirmOptions || defaultDeleteConfirmOptions
  )

  const handleDelete = React.useCallback(async () => {
    if (deleteConfirmOptions === null || (await confirm())) {
      await onDelete()
      onDeleted?.()
    }
  }, [confirm, onDelete, onDeleted, deleteConfirmOptions])

  const Node = deleteButtonLeft ? FormButtonsLeftWrapper : FormButtonsWrapper

  return (
    <Node>
      <Component {...confirmComponentProps} />
      {renderDeleteButton && renderDeleteButton()}
      {deleteButtonName && (
        <Button
          type="button"
          color="danger"
          style={{ minWidth: 135, marginRight: 13 }}
          outline
          disabled={isSubmitting || disabled}
          onClick={handleDelete}
        >
          {deleteButtonName}
        </Button>
      )}
      <Button type="submit" color="primary" style={{ minWidth: 135 }} disabled={disabled || isSubmitting}>
        {saveButtonName}
      </Button>
    </Node>
  )
}
