import { IMAGE_SORT_OPTION } from '@cils/common'
import React from 'react'
import { GQLDateTimeRange, GQLItem, GQLTCF_IMAGE_TYPE_CILS, GQLWorksetGroup } from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import {
  createODListableContext,
  ODListableDataLoadFunc,
  ODListableOption,
  ODListableReducerState,
  ODListableResponseType,
  ODListableStyle,
} from '../../../ODListable/ODListableContext'
import { Utils as WebUtils } from '../../../utils'
import { ItemsList } from './ItemsList'

export interface ItemDataLoaderOption extends ODListableOption {
  orgId: number | null
  filter: string
  hasAttachment: boolean | null
  timeLapseOnly: boolean | null
  tcfImageTypes: Array<GQLTCF_IMAGE_TYPE_CILS> | null
  sortBy: IMAGE_SORT_OPTION | null
  modifiedTimeRange: GQLDateTimeRange | null
  categoryIds: Array<number> | null
  tagIds: Array<number> | null
  addedToWorkset: boolean | null
  hidden: boolean | null
}

const { Provider, Context } = createODListableContext<GQLItem, ItemDataLoaderOption>()

type Props = {
  showToolbar: boolean
  showFastAddToWorkset?: boolean // showToolbar must be true to enable this.
  onFastAddToWorkset?: (wsIds: Array<number>, loadOption: ItemDataLoaderOption) => Promise<any>
  dataLoader: ODListableDataLoadFunc<GQLItem, ItemDataLoaderOption>
  inWorksetId?: number
  pageSize?: number
}

export const ItemsContainer: React.FC<Props> = props => {
  const { showToolbar, showFastAddToWorkset, onFastAddToWorkset, dataLoader, inWorksetId, pageSize = 100 } = props

  const { orgId } = useODSingleOrgContext()

  const loader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemDataLoaderOption
    ): Promise<ODListableResponseType<GQLItem>> {
      return dataLoader(page, pageSize, afterKey, { ...options, orgId: null })
    },
    [dataLoader]
  )

  const afterKeyExtractor = React.useCallback(
    (lastItem: GQLItem | null, state: ODListableReducerState<GQLItem, ItemDataLoaderOption>) => {
      if (!lastItem) {
        return null
      }

      switch (state.loadOption.sortBy) {
        case IMAGE_SORT_OPTION.NameAscending:
        case IMAGE_SORT_OPTION.NameDescending:
          return lastItem.title?.toString() || ''
        case IMAGE_SORT_OPTION.CreatedDateAscending:
        case IMAGE_SORT_OPTION.CreatedDateDescending:
        default:
          return lastItem.recordingTime
      }
    },
    []
  )

  return (
    <FlexContentsContainer>
      <Provider
        dataLoader={loader}
        keyExtractor={v => v.itemId.toString()}
        pageSize={pageSize}
        searchOnLoad
        style={ODListableStyle.TimelineStyle}
        afterKeyExtractor={afterKeyExtractor}
        onDataLoaderError={WebUtils.showError}
        initialLoadOptions={{ hidden: false }}
      >
        <ItemsList
          showToolbar={showToolbar}
          showFastAddToWorkset={showFastAddToWorkset}
          onFastAddToWorkset={onFastAddToWorkset}
          context={Context}
          inWorksetId={inWorksetId}
          orgId={orgId}
        />
      </Provider>
    </FlexContentsContainer>
  )
}
