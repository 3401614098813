import React from 'react'
import styled from 'styled-components'
import logo from '../assets/img/brand/logo-symbol-w.png'

type BlockingLoadBoxProps = {
  show: boolean
  message?: string
}

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 134px;
  border-radius: 67px;
  object-fit: contain;
  background-color: #0000004c;
`

const MessageSpan = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
`

const SpinningImage = styled.img`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 4s linear infinite;
`

export const BlockingLoadBox: React.FC<BlockingLoadBoxProps> = props => {
  const { show, message = 'Wait..' } = props

  const [showContents, setShowContents] = React.useState(false)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setShowContents(true)
    }, 500)
    return () => clearTimeout(handler)
  }, [showContents])

  return (
    <div
      style={{
        visibility: show ? 'visible' : 'hidden',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {showContents && (
        <Circle>
          <SpinningImage alt="waiting icon" src={logo} width={46} height={46} />
          <div style={{ height: 14 }} />
          <MessageSpan>{message}</MessageSpan>
        </Circle>
      )}
    </div>
  )
}
