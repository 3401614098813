import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import styled from 'styled-components'
import { GQLUser, GQLUserPropsInput } from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODAppContext } from '../../../context/ODAppContext'
import { useODMutation } from '../../../context/ODCommon'
import { GQL_UPDATE_USER } from '../../../gqls'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

const Key = styled.span`
  width: 116px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #2f353a;
`

const RowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const Line = styled.div`
  display: flex;
  flex: 1;
  height: 1px;
  background-color: #c8ced3;
  margin-top: 40px;
  margin-bottom: 22px;
`

const SaveButton = styled.div`
  width: 135px;
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`
const CancelButton = styled.div`
  width: 70px;
  height: 35px;
  border-radius: 3px;
  background-color: #c8ced3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #2f353a;
  cursor: pointer;
`

export const ProfileContainer: React.FC = props => {
  const {
    state: { userProfile },
    refreshProfile,
  } = useODAppContext()

  const history = useHistory()

  const [name, setName] = React.useState<string>(userProfile?.name || '')
  const [email, setEmail] = React.useState<string>(userProfile?.email || '')

  const apiUpdateUserProfile = useODMutation<Partial<GQLUserPropsInput>, GQLUser>(GQL_UPDATE_USER)
  const handleUpdateFields = async () => {
    if (!name) {
      Utils.showError('Name is required.')
      return
    }

    try {
      await apiUpdateUserProfile({ name, email })
      await refreshProfile()
      await history.push(SiteUrls.User.MyPage.Main)
      Utils.showSuccess('Updated.', 'Success')
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 26 }}>
        <CardHeader>Profile</CardHeader>
        <CardBody>
          <RowWrapper>
            <div style={{ width: 116 }}>
              <Key>Username</Key>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <Col md={5}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" style={{ fontSize: 13 }} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </div>
          </RowWrapper>
          <RowWrapper style={{ marginTop: 15 }}>
            <div style={{ width: 116 }}>
              <Key>Email</Key>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <Col md={5}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-envelope" style={{ fontSize: 13 }} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </div>
          </RowWrapper>
          <Line />
          <RowWrapper style={{ justifyContent: 'flex-end' }}>
            <CancelButton onClick={() => history.push(SiteUrls.User.MyPage.Main)} style={{ marginRight: 10 }}>
              Cancel
            </CancelButton>
            <SaveButton onClick={handleUpdateFields}>Save</SaveButton>
          </RowWrapper>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
