import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col } from 'reactstrap'
import styled from 'styled-components'
import { GQLIMAGE_SORT_OPTION, GQLListableTag, GQLListableTagInput, GQLTag } from '../../@types/server'
import { FlexContentsContainer } from '../../components/FlexContentsContainer'
import { ODIcon, ODIcons } from '../../components/ODIcon'
import { ODImageCard } from '../../components/ODImageCard/ODImageCard'
import { DROPDOWN_MENU } from '../../components/ODImageGrid/ODImageGridSearchToolBar'
import { OptionButton } from '../../components/ODImageGrid/SelectOptions'
import { SortByTagDropdown } from '../../components/SortByTagDropdown'
import { useODQuery } from '../../context/ODCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { createODListableContext, ODListableOption, ODListableResponseType } from '../../ODListable/ODListableContext'
import { ODListablePagination } from '../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../urls'

export interface TagDataLoaderOption extends ODListableOption {
  filter: string
  sortBy: GQLIMAGE_SORT_OPTION | null
}

export const GQL_LIST_TAG = `
query listTag($data: ListableTagInput!) {
  listTag(data: $data) {
    list {
      tagId
      name
      num
      createdAt
      mainItems {
        itemId
        imageFile {
          link
        }
      }
    }
    totalCount
    page
    pageSize
  }
}`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 72px;
  background-color: #ffffff;
`
const SortByText = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #2f353a;
`

enum TAG_VIEW_TYPE {
  Grid = 'Grid',
  List = 'List',
}

const { Provider, Context } = createODListableContext<GQLTag, TagDataLoaderOption>()

export const TagContainer: React.FC = () => {
  const apiList = useODQuery<GQLListableTagInput, GQLListableTag>(GQL_LIST_TAG)
  const { orgId } = useODSingleOrgContext()

  const [selectedValue, _setSelectedValue] = React.useState<TAG_VIEW_TYPE>(
    () => (localStorage.getItem('TAG_LIST_TYPE') || TAG_VIEW_TYPE.Grid) as TAG_VIEW_TYPE
  )
  const [isMenuOpen, setIsMenuOpen] = React.useState<DROPDOWN_MENU | null>(null)
  const setSelectedValue = React.useCallback(
    (v: TAG_VIEW_TYPE) => {
      localStorage.setItem('TAG_LIST_TYPE', v)
      _setSelectedValue(v)
    },
    [_setSelectedValue]
  )

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: TagDataLoaderOption
    ): Promise<ODListableResponseType<GQLTag>> {
      return apiList({ page, pageSize, filter: options.filter, orgId, sortBy: options.sortBy })
    },
    [orgId, apiList]
  )

  const TableDefinition: ODListableTableDefinition<GQLTag, TagDataLoaderOption> = [
    {
      id: 'tag',
      title: 'Tag',
      transform: v => <Link to={SiteUrls.User.Org.Tag.ItemList(v.tagId)(orgId)}>{v.name}</Link>,
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'item',
      title: 'Item',
      transform: v => v.num,
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'created',
      title: 'Tag Created',
      transform: v => (v.createdAt ? moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
      thClass: 'text-left',
      className: 'text-left',
    },
  ]

  return (
    <FlexContentsContainer>
      <Provider dataLoader={dataLoader} keyExtractor={v => v.tagId.toString()} pageSize={100} searchOnLoad>
        <RowWrapper style={{ borderBottom: '1px solid #c8ced3' }}>
          <Col md={7} className="vcenter">
            <RowWrapper>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by tag"
                style={{ flexGrow: 6, maxWidth: 600, marginTop: 10 }}
              />
            </RowWrapper>
          </Col>
          <Col md={5} className="vcenter">
            <RowWrapper style={{ justifyContent: 'flex-end' }}>
              <SortByText style={{ marginRight: 16 }}>Sort by</SortByText>
              <SortByTagDropdown isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} listableContext={Context} />
              <div
                style={{
                  display: 'flex',
                  borderRadius: 4,
                  backgroundColor: '#f0f3f5',
                  marginLeft: 14,
                  width: 100,
                  height: 34,
                }}
              >
                <React.Fragment>
                  <OptionButton
                    type="radio"
                    name={'TagView'}
                    id={TAG_VIEW_TYPE.Grid}
                    checked={selectedValue === TAG_VIEW_TYPE.Grid}
                    onChange={() => setSelectedValue(TAG_VIEW_TYPE.Grid)}
                  />
                  <label htmlFor={TAG_VIEW_TYPE.Grid}>
                    <ODIcon icon={ODIcons.CoreViewModule} style={{ fontSize: 18 }} />
                  </label>
                </React.Fragment>
                <React.Fragment>
                  <OptionButton
                    type="radio"
                    name={'TagView'}
                    id={TAG_VIEW_TYPE.List}
                    checked={selectedValue === TAG_VIEW_TYPE.List}
                    onChange={() => setSelectedValue(TAG_VIEW_TYPE.List)}
                  />
                  <label htmlFor={TAG_VIEW_TYPE.List}>
                    <ODIcon icon={ODIcons.MaterialViewList} style={{ fontSize: 18 }} />
                  </label>
                </React.Fragment>
              </div>
            </RowWrapper>
          </Col>
        </RowWrapper>
        {selectedValue === TAG_VIEW_TYPE.Grid && (
          <div style={{ padding: 17, display: 'flex', flexWrap: 'wrap' }}>
            <ODImageCard listableContext={Context} size={88} space={2} />
          </div>
        )}
        {selectedValue === TAG_VIEW_TYPE.List && (
          <div style={{ padding: 17 }}>
            <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
              <CardBody>
                <ODListablePaginatedTable
                  fields={TableDefinition}
                  listableContext={Context}
                  renderLoading={() => 'Loading..'}
                  renderEmpty={() => 'No Result.'}
                />
                <ODListablePagination hideIfSinglePage listableContext={Context} />
              </CardBody>
            </Card>
          </div>
        )}
      </Provider>
    </FlexContentsContainer>
  )
}
