import {
  DOAddUserToProjectResult,
  DOAddUserToProjectResultFailMessage,
  WORKSET_USER_PERM,
  worksetUserPermissionToString,
} from '@cils/common'
import { ProjectUserPerm, WorksetUserPerm } from '@cils/common/lib/CILSStore/projectView/IAddUserToProjectStore'
import { ODHSpace, ODVSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import Select from 'react-select'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { ODColors } from '../../../global-styles'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalHeader } from '../../ODModal/ODModalHeader'
import { SelectOptionType } from '../../ODModal/SelectWorksetMemberAndPermission'
import { ODToastType, showODToast } from '../../ODToast'

interface IODModalCheckWorksetPermissionToAddUser {
  isOpen: boolean
  onClose: () => void
  data: DOAddUserToProjectResult
  showGuideText?: boolean
  inUserPage?: boolean
  refresh?: () => void
}

const PROJECT_PERM_OPTIONS: SelectOptionType[] = [
  { key: 'Admin', label: 'Admin', value: ProjectUserPerm.Admin },
  { key: 'Editor', label: 'Editor', value: ProjectUserPerm.Editor },
  { key: 'Viewer', label: 'Viewer', value: ProjectUserPerm.Viewer },
  // { key: WORKSET_USER_PERM.Owner, label: 'Owner', value: WORKSET_USER_PERM.Owner },
]

const WORKSET_PERM_OPTIONS: SelectOptionType[] = [
  { key: WORKSET_USER_PERM.Viewer.toString(), label: 'Viewer', value: WORKSET_USER_PERM.Viewer },
  { key: WORKSET_USER_PERM.Collaborator.toString(), label: 'Collaborator', value: WORKSET_USER_PERM.Collaborator },
  { key: WORKSET_USER_PERM.Editor.toString(), label: 'Editor', value: WORKSET_USER_PERM.Editor },
  // { key: WORKSET_USER_PERM.Owner, label: 'Owner', value: WORKSET_USER_PERM.Owner },
]

export const ODModalCheckWorksetPermissionToAddUser: React.FC<IODModalCheckWorksetPermissionToAddUser> = observer(
  props => {
    const { isOpen, onClose, showGuideText, data, inUserPage, refresh } = props
    // const store = useCILSStore()
    const [editor] = React.useState(() => data)
    const selectFocus = useRef<HTMLInputElement>(null)

    const handleConfirm = async () => {
      if (!editor.canSubmit) {
        return showODToast(ODToastType.Error, ODToastType.Error, 'You must resolve conflicts to change permission.')
      }
      await editor.submit()
      refresh?.()
      onClose()
    }

    const title = inUserPage ? `Change Project Permission` : 'Check [workset] permission to add user'
    return (
      <ODModal isOpen={isOpen} toggle={() => onClose()} size={ODModalSize.Big} style={{ maxWidth: 1210 }}>
        <ODModalHeader title={title} onClose={() => onClose()} headerBottomPadding={20} />
        <ODModalBody style={{ paddingLeft: 36, paddingRight: 38 }}>
          {showGuideText && (
            <>
              <DescBox>
                <DescText>
                  {!inUserPage && (
                    <span>
                      To add a user to the project, the permissions of the user you select must be equal to or higher
                      than the permissions in all worksets added to the <br />
                      project. Modify the permissions of the project or workset to add a user.
                    </span>
                  )}
                  {inUserPage && (
                    <span>
                      To change the permission of the user in the project, the permissions of the user you select must
                      be equal to or higher than the permissions in all <br /> worksets added to the project. Modify the
                      permissions of the project or workset to add a user.
                    </span>
                  )}
                </DescText>
              </DescBox>
              <ODVSpace h={40} />
            </>
          )}
          <UserBox>
            <UserInfoBox>
              <ODIcon icon={ODIcons.MaterialAccountCircle} style={{ fontSize: 28, color: ODColors.Tealish }} />
              <ODHSpace w={11} />
              <UserNameText>{editor.user.name ?? '-'}</UserNameText>
            </UserInfoBox>
            <CustomSelectBox
              name="category"
              options={PROJECT_PERM_OPTIONS}
              value={PROJECT_PERM_OPTIONS.find(v => v.value === editor.tryProjectPerm)}
              onChange={(e: SelectOptionType) => {
                if (!e.value) {
                  return
                }
                editor.setProjectPerm(e.value)
              }}
              ref={selectFocus}
            />
          </UserBox>
          <ODVSpace h={10} />
          <UserDescBox>
            <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 14, color: ODColors.Primary }} />
            <ODHSpace w={6} />
            <UserDescText>As permission of Project, the permission you can select in worksets may change.</UserDescText>
          </UserDescBox>
          <ODVSpace h={40} />
          <Table striped style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}>
            <thead>
              <tr>
                <th>Worksets in Project</th>
                <th>Your Permission</th>
                <th>
                  <PrimaryText>[{editor.user.name}]</PrimaryText> Permission
                </th>
                <th>Add to Project</th>
                <th>Change [workset] permission</th>
              </tr>
            </thead>
            <tbody>
              {editor.messages.map(meg => {
                const message = meg as DOAddUserToProjectResultFailMessage
                return (
                  <tr key={message.workset.wsId}>
                    <td>
                      <PrimaryText>{message.workset.name}</PrimaryText>
                    </td>
                    <td>{message.myWorksetPerm ? worksetUserPermissionToString(message.myWorksetPerm) : '-'}</td>
                    <td>
                      {message.targetUserWorksetPerm
                        ? worksetUserPermissionToString(message.targetUserWorksetPerm)
                        : '-'}
                    </td>
                    <td>
                      {message.isAvailable || message.targetUserWorksetPerm === WorksetUserPerm.Owner ? (
                        <AvailableBadge>Available</AvailableBadge>
                      ) : (
                        <NotAvailableBadge>Not Available</NotAvailableBadge>
                      )}
                    </td>
                    <td>
                      {message.targetUserWorksetPerm === WorksetUserPerm.Owner && <span>{'Owner'}</span>}
                      {message.myWorksetPerm >= WorksetUserPerm.Editor &&
                        message.targetUserWorksetPerm !== WorksetUserPerm.Owner && (
                          <CustomSelectBox
                            style={{ width: 100 }}
                            name="category"
                            options={
                              editor.tryProjectPerm > ProjectUserPerm.Viewer
                                ? WORKSET_PERM_OPTIONS.filter(item => item.value !== WORKSET_USER_PERM.Viewer)
                                : WORKSET_PERM_OPTIONS
                            }
                            value={WORKSET_PERM_OPTIONS.find(
                              option => option.value === message.targetUserWorksetPermToChange
                            )}
                            onChange={(e: SelectOptionType) => {
                              if (!e.value) {
                                return
                              }

                              message.changeWorksetPerm(e.value)
                            }}
                            ref={selectFocus}
                          />
                        )}
                      {message.myWorksetPerm < WorksetUserPerm.Editor &&
                        message.targetUserWorksetPerm !== WorksetUserPerm.Owner && (
                          <span style={{ color: ODColors.Salmon }}>
                            Only Owner or Editor can change permission, so you cannot change the permission
                          </span>
                        )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
        </ODModalBody>
      </ODModal>
    )
  }
)

const DescBox = styled.div`
  display: flex;
  justify-content: center;
`

const DescText = styled.span`
  text-align: center;
  font-size: 14px;
  line-height: 1.43;
  color: ${ODColors.CoolGrey};
`

const UserBox = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 21px;
  padding-right: 15px;
  border: 1px solid ${ODColors.Primary};
  background-color: ${ODColors.PaleGrey2};
`

const UserInfoBox = styled.div`
  display: flex;
  align-items: center;
`

const UserNameText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${ODColors.Primary};
`

const CustomSelectBox = styled(Select)`
  width: 269px;
`

const UserDescBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const UserDescText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.Primary};
`

const PrimaryText = styled.span`
  color: ${ODColors.Primary};
  font-weight: bold;
`

const AvailableBadge = styled.span`
  width: 100px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ODColors.DarkMint};
  color: white;
  font-size: 10px;
`
const NotAvailableBadge = styled.div`
  width: 100px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ODColors.Salmon};
  color: white;
  font-size: 10px;
`

const ConfirmButton = styled.button`
  width: 100%;
  max-width: 176px;
  height: 35px;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${ODColors.White};
  margin-left: auto;
`
