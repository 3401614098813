import { DEFAULT_ORG_USER_PERM_SCHEMA, ORG_USER_PRIV } from '@cils/common'
import { useODModalConfirm } from '@odc/od-react-belt'
import React, { useState } from 'react'
import { GQLUpdateOrgHasUserInput } from '../../../../@types/server'
import { FlexContentsContainer } from '../../../../components/FlexContentsContainer'
import { ODModal, ODModalSize } from '../../../../components/ODModal/ODModal'
import { ODModalBody } from '../../../../components/ODModal/ODModalBody'
import { ODModalHeader } from '../../../../components/ODModal/ODModalHeader'
import { useAPIs } from '../../../../context/useAPIs'
import { SingleQueryWrapper } from '../../../../context/useSingleQueryAPI'
import { Utils } from '../../../../utils'
import { ChangeUserOrgPermissionPage } from './ChangeUserOrgPermissionPage'

type Props = {
  show: boolean
  onClose: (updated: boolean) => void
  userId: number
  orgId: number
}

export const ChangeUserOrgPermissionModal: React.FC<Props> = props => {
  const { show, onClose, userId, orgId } = props
  const { getOrgHasUser, updateOrgHasUser } = useAPIs()
  const [rejecting, setRejecting] = useState(false)

  const { Component, props: confirmProps, confirm } = useODModalConfirm({})

  const handleReject = async () => {
    const confirmed = await confirm({
      title: 'Block user',
      message: (
        <div>
          Block this user? <br />
          This user will not be able to login after blocked.
        </div>
      ),
      yes: 'Block',
      no: 'Cancel',
    })

    if (!confirmed) {
      return
    }

    try {
      await updateOrgHasUser({
        orgId,
        userId,
        orgUserPriv: ORG_USER_PRIV.Rejected,
        permSchema: JSON.stringify(DEFAULT_ORG_USER_PERM_SCHEMA),
      })
      Utils.showSuccess('Changed user permission.', 'Success')
      setTimeout(() => {
        onClose(true)
      }, 100)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setRejecting(false)
    }
  }

  return (
    <ODModal isOpen={show} toggle={() => onClose(false)} size={ODModalSize.Full}>
      <Component {...confirmProps} />
      <FlexContentsContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title="Change User Permission" onClose={() => onClose(false)} headerBottomPadding={30} />
          <ODModalBody style={{ flexGrow: 99, paddingLeft: 45, paddingRight: 45 }}>
            <SingleQueryWrapper
              showLoading={rejecting ? 'Loading...' : ''}
              deps={[orgId, userId]}
              getter={() => getOrgHasUser({ firstId: orgId, secondId: userId })}
              update={(input: GQLUpdateOrgHasUserInput) => updateOrgHasUser(input)}
            >
              {({ data, update }) => (
                <ChangeUserOrgPermissionPage
                  orgHasUser={data}
                  onUpdate={update}
                  onReject={handleReject}
                  onClose={onClose}
                />
              )}
            </SingleQueryWrapper>
          </ODModalBody>
        </div>
      </FlexContentsContainer>
    </ODModal>
  )
}
