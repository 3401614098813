import { ErrorCode, WORKSET_USER_PERM, worksetUserPermissionToString } from '@cils/common'
import { ODListableResponseType } from '@odc/od-react-belt'
import produce from 'immer'
import { cloneDeep, flatten, startsWith, uniqBy } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { GQLWorkset } from '../../../@types/server'
import { Clickable } from '../../../components/Clickable'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { ODModal, ODModalSize } from '../../../components/ODModal/ODModal'
import { ODModalBody } from '../../../components/ODModal/ODModalBody'
import { ODModalHeader } from '../../../components/ODModal/ODModalHeader'
import {
  SelectOptionType,
  SelectWorksetMemberAndPermission,
} from '../../../components/ODModal/SelectWorksetMemberAndPermission'
import { useAPIs } from '../../../context/useAPIs'
import { ErrorStringMap } from '../../../i18n/res'
import { createODListableContext, ODListableOption, ODListableStyle } from '../../../ODListable/ODListableContext'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODColors } from '../../../styles/ODColors'
import { Utils } from '../../../utils'

interface IMultiWorksetShareModalProps {
  worksets: GQLWorkset[]
  isOpen: boolean
  onClose: () => void
  isLowerThanEditorCanNotControll?: boolean
}

const { Provider, Context } = createODListableContext<GQLWorkset, ODListableOption>()

interface IOperation {
  isUser: boolean
  name: string
  permissionToAdd: number | null // WORKSET_USER_PERM to add, null to remove.
}

enum OperationStatus {
  Pending = 'Pending',
  SuccessAdded = 'SuccessAdded',
  SuccessReplaced = 'SuccessReplaced',
  SuccessRemoved = 'SuccessRemoved',
  SuccessNoChange = 'SuccessNoChange',
  Fail = 'Fail',
}

interface IOperationResult {
  worksetId: number
  status: OperationStatus
  error: Error | null
}

const MULTI_WORKSET_ERROR_MAP: ErrorStringMap = {
  [ErrorCode.ServerItemNotFound]: 'Permission out of sync. (Item Not Found) Please refresh the page and retry.',
  [ErrorCode.WorksetUserAlreadyExists]:
    'Permission out of sync. (WorksetUserAlreadyExists) Please refresh the page and retry.',
  [ErrorCode.WorksetOwnerCannotBeAdded]: 'Owner cannot be added.',
  [ErrorCode.WorksetOwnerCannotBeRemoved]: 'Owner cannot be removed.',
}

export const MultiWorksetShareModal: React.FC<IMultiWorksetShareModalProps> = props => {
  const { worksets: worksetsIn, isOpen, onClose, isLowerThanEditorCanNotControll } = props

  const [worksets, setWorksets] = React.useState<GQLWorkset[]>(
    () => produce(cloneDeep(worksetsIn), draft => draft) as GQLWorkset[]
  )

  const getTotalUserAndGroups: (worksets: GQLWorkset[]) => SelectOptionType[] = React.useCallback(
    (worksets: GQLWorkset[]) => {
      return uniqBy(
        flatten(
          worksets.map(ws => {
            const userList = ws.members
              .filter(whu => whu.user.priv !== WORKSET_USER_PERM.Owner)
              .map(whu => {
                const user = whu.user
                return {
                  value: user.userId,
                  key: 'user_' + user.userId.toString(),
                  label: `${user.name} : ${user.email}`,
                }
              })

            const groupList = ws.memberGroups.map(whug => {
              const group = whug.group
              return { value: group.groupId, key: 'group_' + group.groupId.toString(), label: `Group: ${group.name}` }
            })
            return [...userList, ...groupList]
          })
        ),
        v => v.key
      )
    },
    []
  )

  const [totalUserAndGroups, setTotalUserAndGroups] = React.useState<SelectOptionType[]>(() =>
    getTotalUserAndGroups(worksetsIn)
  )

  const updateTotalUserAndGroups = React.useCallback(
    (worksets: GQLWorkset[]) => {
      setTotalUserAndGroups(getTotalUserAndGroups(worksets))
    },
    [setTotalUserAndGroups, getTotalUserAndGroups]
  )

  const [currentOperation, setCurrentOperation] = React.useState<IOperation | null>(null)
  const [currentOperationProgress, setCurrentOperationProgress] = React.useState<IOperationResult[]>(
    produce([], draft => draft)
  )
  const [isWorking, setIsWorking] = React.useState(false)
  const {
    apiAddGroupToWorkset,
    apiAddMemberToWorkset,
    apiUpdateMemberOfWorkset,
    apiUpdateGroupOfWorkset,
    apiRemoveGroupFromWorkset,
    apiRemoveMemberFromWorkset,
  } = useAPIs()

  const TableDefinition: ODListableTableDefinition<GQLWorkset, ODListableOption> = [
    {
      id: 'name',
      title: 'Workset name',
      // transform: v => <Link to={SiteUrls.User.Org.Workset.Detail(v.wsId)(v.ownerOrgId)}>{v.name}</Link>,
      transform: v => v.name,
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'opTarget',
      title: 'Share Member or Group',
      transform: () => {
        return currentOperation?.name ?? '-'
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'perm',
      title: 'Permission',
      transform: () => {
        if (!currentOperation) {
          return '-'
        }

        if (!currentOperation!.permissionToAdd) {
          return 'Remove'
        }

        return worksetUserPermissionToString(currentOperation!.permissionToAdd)
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'status',
      title: 'Status',
      transform: v => {
        const progress = currentOperationProgress.find(p => p.worksetId === v.wsId)
        if (!progress) {
          return '-'
        }

        switch (progress.status) {
          case OperationStatus.Pending:
            return 'Waiting..'
          case OperationStatus.SuccessAdded:
            return <SuccessSpan>Success (Added)</SuccessSpan>
          case OperationStatus.SuccessReplaced:
            return <SuccessSpan>Success (Replaced)</SuccessSpan>
          case OperationStatus.SuccessRemoved:
            return <SuccessSpan>Success (Removed)</SuccessSpan>
          case OperationStatus.SuccessNoChange:
            return <SuccessSpan>Success (No change)</SuccessSpan>
          case OperationStatus.Fail:
            const handleFail = () => {
              if (progress.error) {
                Utils.showError(progress.error, '', undefined, MULTI_WORKSET_ERROR_MAP)
              } else {
                alert('Unknown error')
              }
            }

            const reasonText = (() => {
              const errorCode = progress.error && Utils.parseErrorCode(progress.error)
              if (!errorCode) {
                return ''
              }
              switch (errorCode) {
                case ErrorCode.WorksetOwnerCannotBeRemoved:
                case ErrorCode.WorksetOwnerCannotBeAdded:
                  return ' (Owner)'
                case ErrorCode.WorksetNoPrivilege:
                  return ' (No permission)'
              }
            })()

            return (
              <Clickable style={{ color: ODColors.Redish }} onClick={handleFail}>
                Fail{reasonText}
              </Clickable>
            )
        }
      },
      thClass: 'text-left',
      className: 'text-left',
    },
  ]

  const dataLoader = React.useCallback(
    async function dataLoader(): Promise<ODListableResponseType<GQLWorkset>> {
      return { list: worksets, page: 1, pageSize: worksets.length, totalCount: worksets.length }
    },
    [worksets]
  )

  const handleAddPermission = async (member: SelectOptionType, worksetUserPermToAdd: number) => {
    const isRemoving = worksetUserPermToAdd === 0
    const memberIsUser = startsWith(member.key, 'user')
    const id = member.value

    if (isWorking) {
      return
    }

    setIsWorking(true)
    setCurrentOperation(curOp =>
      produce(curOp, () => {
        const op: IOperation = {
          permissionToAdd: isRemoving ? null : worksetUserPermToAdd,
          isUser: memberIsUser,
          name: member.label,
        }
        return op
      })
    )
    setCurrentOperationProgress(progress =>
      produce(progress, () => {
        return worksets.map(ws => {
          const progress: IOperationResult = {
            worksetId: ws.wsId,
            status: OperationStatus.Pending,
            error: null,
          }
          return progress
        })
      })
    )

    for (const workset of worksets) {
      const updateWorksetProgress = (status: OperationStatus, error: Error | null) => {
        setCurrentOperationProgress(progress =>
          produce(progress, draft => {
            draft[draft.findIndex(w => w.worksetId === workset.wsId)] = {
              worksetId: workset.wsId,
              status,
              error,
            }
            return draft
          })
        )
      }

      // 현재 워크셋 그룹 상세에서 해당 모달이 표시 되었을때는 요청한 유저에 대한 워크셋 권한이 에디터 보다 낮다면 추가 및 삭제가 불가 하여야 한다.
      if (isLowerThanEditorCanNotControll && !!workset.myPerm && workset.myPerm < WORKSET_USER_PERM.Editor) {
        updateWorksetProgress(
          OperationStatus.Fail,
          Error('Only Owner or Editor can change permission, so you cannot change the permission')
        )
      } else if (!isRemoving) {
        if (memberIsUser) {
          // 사용자를 추가하고자 한다.
          const existingIndex = workset.members.findIndex(mem => mem.userId === id)
          if (existingIndex >= 0) {
            try {
              const res = await apiUpdateMemberOfWorkset({
                wsId: workset.wsId,
                expireAt: null,
                permLevel: worksetUserPermToAdd,
                userId: id,
              })
              setWorksets(data => {
                const updated = produce(data, draft => {
                  draft.find(w => w.wsId === workset.wsId)!.members[existingIndex] = res
                  return draft
                })
                updateTotalUserAndGroups(updated)
                return updated
              })
              updateWorksetProgress(OperationStatus.SuccessReplaced, null)
            } catch (ex) {
              updateWorksetProgress(OperationStatus.Fail, ex)
            }
          } else {
            try {
              const res = await apiAddMemberToWorkset({
                wsId: workset.wsId,
                expireAt: null,
                permLevel: worksetUserPermToAdd,
                userId: id,
              })
              setWorksets(data => {
                const updated = produce(data, draft => {
                  draft.find(w => w.wsId === workset.wsId)!.members.push(res)
                  return draft
                })
                updateTotalUserAndGroups(updated)
                return updated
              })
              updateWorksetProgress(OperationStatus.SuccessAdded, null)
            } catch (ex) {
              updateWorksetProgress(OperationStatus.Fail, ex)
            }
          }
        } else {
          // 사용자 그룹을 추가하고자 한다.
          const existingIndex = workset.memberGroups.findIndex(mem => mem.groupId === id)
          if (existingIndex >= 0) {
            try {
              const res = await apiUpdateGroupOfWorkset({
                wsId: workset.wsId,
                expireAt: null,
                permLevel: worksetUserPermToAdd,
                groupId: id,
              })
              setWorksets(data => {
                const updated = produce(data, draft => {
                  draft.find(w => w.wsId === workset.wsId)!.memberGroups[existingIndex] = res
                  return draft
                })
                updateTotalUserAndGroups(updated)
                return updated
              })
              updateWorksetProgress(OperationStatus.SuccessRemoved, null)
            } catch (ex) {
              updateWorksetProgress(OperationStatus.Fail, ex)
            }
          } else {
            try {
              const res = await apiAddGroupToWorkset({
                wsId: workset.wsId,
                expireAt: null,
                permLevel: worksetUserPermToAdd,
                groupId: id,
              })
              setWorksets(data => {
                const updated = produce(data, draft => {
                  draft.find(w => w.wsId === workset.wsId)!.memberGroups.push(res)
                  return draft
                })
                updateTotalUserAndGroups(updated)
                return updated
              })
              updateWorksetProgress(OperationStatus.SuccessAdded, null)
            } catch (ex) {
              updateWorksetProgress(OperationStatus.Fail, ex)
            }
          }
        }
      } else {
        if (memberIsUser) {
          // 사용자를 삭제하고자 한다.
          const existingIndex = workset.members.findIndex(mem => mem.userId === id)

          if (existingIndex >= 0) {
            try {
              await apiRemoveMemberFromWorkset({
                firstId: workset.wsId,
                secondId: id,
              })
              setWorksets(data => {
                const updated = produce(data, draft => {
                  const ws: GQLWorkset = draft.find(w => {
                    return w.wsId === workset.wsId
                  })!
                  ws.members.splice(existingIndex, 1)
                  return draft
                })
                updateTotalUserAndGroups(updated)
                return updated
              })
              updateWorksetProgress(OperationStatus.SuccessRemoved, null)
            } catch (ex) {
              updateWorksetProgress(OperationStatus.Fail, ex)
            }
          } else {
            updateWorksetProgress(OperationStatus.SuccessNoChange, null)
          }
        } else {
          // 사용자 그룹을 삭제하고자 한다.
          const existingIndex = workset.memberGroups.findIndex(mem => mem.groupId === id)
          if (existingIndex >= 0) {
            try {
              await apiRemoveGroupFromWorkset({
                firstId: workset.wsId,
                secondId: id,
              })
              setWorksets(data => {
                const updated = produce(data, draft => {
                  const ws: GQLWorkset = draft.find(w => w.wsId === workset.wsId)!
                  ws.memberGroups.splice(existingIndex, 1)
                  return draft
                })
                updateTotalUserAndGroups(updated)
                return updated
              })
              updateWorksetProgress(OperationStatus.SuccessReplaced, null)
            } catch (ex) {
              updateWorksetProgress(OperationStatus.Fail, ex)
            }
          } else {
            updateWorksetProgress(OperationStatus.SuccessNoChange, null)
          }
        }
      }
    }
    setIsWorking(false)
  }

  return (
    <ODModal isOpen={isOpen} toggle={() => null} size={ODModalSize.Big}>
      <FlexContentsContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title="Share Worksets" onClose={() => onClose()} headerBottomPadding={30} />
          <ODModalBody style={{ flexGrow: 99, paddingLeft: 0, paddingRight: 0 }}>
            <Provider
              dataLoader={dataLoader}
              keyExtractor={v => v.wsId.toString()}
              pageSize={12}
              searchOnLoad
              style={ODListableStyle.TableStyle}
            >
              <div style={{ paddingLeft: 45, paddingRight: 45 }}>
                <ODListablePaginatedTable
                  fields={TableDefinition}
                  listableContext={Context}
                  renderLoading={() => 'Loading..'}
                  renderEmpty={() => 'No Result.'}
                />
              </div>
            </Provider>
            <BottomGrayWrapper>
              <BottomGraySection>
                <SelectWorksetMemberAndPermission
                  onSubmit={handleAddPermission}
                  isRemoving={false}
                  disabled={isWorking}
                />
              </BottomGraySection>
              <VertLine />
              <BottomGraySection>
                <SelectWorksetMemberAndPermission
                  onSubmit={handleAddPermission}
                  isRemoving
                  disabled={isWorking}
                  options={totalUserAndGroups}
                />
              </BottomGraySection>
            </BottomGrayWrapper>
            <div
              style={{
                display: 'flex',
                color: ODColors.Primary,
                fontSize: 12,
                paddingTop: 7,
                alignItems: 'center',
                paddingLeft: 40,
              }}
            >
              <ODIcon icon={ODIcons.MaterialError} style={{ transform: 'scale(0.8)' }} />
              &nbsp;If member or group you select is already in Workset, their permission will be replaced.
            </div>
          </ODModalBody>
        </div>
      </FlexContentsContainer>
    </ODModal>
  )
}

const BottomGrayWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 30px 0;
  background-color: #f0f3f5;
`

const VertLine = styled.div`
  width: 1px;
  padding-top: 36px;
  padding-bottom: 30px;
  background-color: #c8ced3;
`

const BottomGraySection = styled.div`
  flex-grow: 1;
  padding: 0 60px 30px 45px;
`

const SuccessSpan = styled.span`
  color: ${ODColors.Primary};
`
