import { BlockingLoadBox, ODHSpace, ODVSpace, useCounter, useODModalConfirm } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { GQLProject } from '../../../../@types/server'
import { FlexContentsContainer } from '../../../../components/FlexContentsContainer'
import { ODIcon, ODIcons } from '../../../../components/ODIcon'
import { ODModalDeleteProject } from '../../../../components/project/modal/ODModalDeleteProject'
import { useAPIs } from '../../../../context/useAPIs'
import { ODColors } from '../../../../global-styles'
import { createODListableContext, ODListableStyle } from '../../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../../../urls'
import { Utils } from '../../../../utils'
import { IProjectListableOption } from '../ProjectCommon'
import { ProjectSearch } from './ProjectSearch'
import { useProjectInOrgListDataLoader, useProjectListDataLoader } from './useProjectListDataLoader'

interface Props {
  orgId: number
  showInOrg?: boolean // 해당 옵션이 true면 기관 내 모든 프로젝트 조회
  showInOrgAdmin?: boolean // 기관 어드민에서 보여지는지
}

const { Provider, Context } = createODListableContext<GQLProject, IProjectListableOption>()

export const ProjectListPage: React.FC<Props> = observer(props => {
  const { orgId, showInOrg, showInOrgAdmin } = props
  const [token, refresh] = useCounter()
  const [isOpenDeleteProject, setIsOpenDeleteProject] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const dataLoader = useProjectListDataLoader()
  const InOrgDataLoader = useProjectInOrgListDataLoader(orgId)
  const selectedDataLoader = showInOrg || showInOrgAdmin ? InOrgDataLoader : dataLoader
  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})
  const { cancelRemoveProject, removePermanentProject } = useAPIs()
  const selectedProject = React.useRef<{ projectId: number; projectName: string } | null>(null)

  const handleRestoreProject = async (name: string, id: number) => {
    if (
      await confirm({
        title: <span style={{ color: ODColors.Primary }}>Remove Project</span>,
        message: (
          <span style={{ fontSize: 14, color: ODColors.CharcoalGrey }}>
            Are you sure you want to restore <br />{' '}
            <span style={{ fontSize: 14, color: ODColors.CharcoalGreyTwo, fontWeight: 'bold' }}>"{name}"</span>?
          </span>
        ),
        yes: 'Restore Project',
        no: 'Cancel',
      })
    ) {
      try {
        setLoading(true)
        await cancelRemoveProject({ id })
        refresh()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
    }
  }

  const TableDefinition: ODListableTableDefinition<GQLProject, IProjectListableOption> = [
    {
      id: 'ProjectName',
      title: 'Project name',
      transform: v => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link to={SiteUrls.User.Org.Project.Detail(v.projectId)(orgId)}>{v.name ?? '-'}</Link>
        </div>
      ),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'ProjectType',
      title: 'Project Type',
      transform: v => {
        return v.type ? Utils.formatProjectType(v.type) : '-'
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Items',
      title: 'Items',
      transform: v => {
        return v.numItems ?? 0
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Owner',
      title: 'Owner',
      transform: v => {
        return v.owner.name ?? '-'
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'ProjectCreated',
      title: 'Project Created',
      transform: v => {
        return <div>{v.createdAt ? Utils.formatDate2(v.createdAt) : '-'}</div>
      },
      thClass: 'text-left',
      className: 'text-left',
    },
  ]

  const AddTableDefinitionInOrgAdmin: ODListableTableDefinition<GQLProject, IProjectListableOption> = [
    {
      id: 'Status',
      title: 'Status',
      transform: v => {
        const isDeleted = !!v.deletedAt
        const deletedDate = v.deletedAt
        return (
          <div>
            <StatusBox style={{ backgroundColor: isDeleted ? ODColors.Salmon : ODColors.DarkSkyBlue }}>
              {isDeleted ? 'Deleted' : 'Active'}
            </StatusBox>
            <ODVSpace h={3} />
            {isDeleted && (
              <DeletedText>
                Project will be deleted at <br /> {Utils.formatDate(moment(deletedDate).add(90, 'days'))}
              </DeletedText>
            )}
          </div>
        )
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Action',
      title: 'Action',
      transform: v => {
        const isDeleted = !!v.deletedAt

        return (
          // 아래의 버튼들은 삭제 유예상태인 프로젝트에만 보여짐
          <div>
            {isDeleted && (
              <>
                <Row
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    selectedProject.current = {
                      projectId: v.projectId,
                      projectName: v.name,
                    }
                    setIsOpenDeleteProject(true)
                  }}
                >
                  <ODIcon
                    icon={ODIcons.CoreTrash}
                    style={{ fontSize: 14, color: ODColors.Salmon, position: 'relative', top: 2 }}
                  />
                  <ODHSpace w={6} />
                  <ActionText>Permanent Delete</ActionText>
                </Row>
                <ODVSpace h={8} />
                <Row style={{ cursor: 'pointer' }} onClick={() => handleRestoreProject(v.name, v.projectId)}>
                  <ODIcon
                    icon={ODIcons.CoreReload}
                    style={{ fontSize: 14, color: ODColors.Primary, position: 'relative', top: 2 }}
                  />
                  <ODHSpace w={6} />
                  <ActionText style={{ color: ODColors.Primary }}>Restore</ActionText>
                </Row>
              </>
            )}
          </div>
        )
      },
      thClass: 'text-left',
      className: 'text-left',
    },
  ]

  const SelectedTableDefinition = showInOrgAdmin
    ? TableDefinition.concat(AddTableDefinitionInOrgAdmin)
    : TableDefinition
  return (
    <FlexContentsContainer>
      <BlockingLoadBox show={loading} />
      <ODModalDeleteProject
        isOpen={isOpenDeleteProject}
        projectName={selectedProject.current?.projectName ?? '-'}
        onClose={() => setIsOpenDeleteProject(false)}
        onDelete={async password => {
          if (selectedProject.current) {
            try {
              const projectId = selectedProject.current.projectId
              await removePermanentProject({ projectId, password })
              selectedProject.current = null
              refresh()
              setIsOpenDeleteProject(false)
            } catch (ex) {
              Utils.showError(ex)
            }
          }
        }}
      />
      <ConfirmComponent {...confirmProps} />
      <Provider
        dataLoader={selectedDataLoader}
        keyExtractor={v => v.projectId.toString()}
        pageSize={12}
        searchOnLoad
        style={ODListableStyle.TableStyle}
        refreshToken={token.toString()}
      >
        <ProjectSearch
          listableContext={Context}
          orgId={orgId}
          refresh={refresh}
          showInOrg={showInOrg}
          showInOrgAdmin={showInOrgAdmin}
        />
        <div style={{ padding: 17 }}>
          <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
            <CardBody>
              <ODListablePaginatedTable
                fields={SelectedTableDefinition}
                listableContext={Context}
                renderLoading={() => 'Loading..'}
                renderEmpty={() => 'No Result.'}
              />
              <ODListablePagination hideIfSinglePage listableContext={Context} />
            </CardBody>
          </Card>
        </div>
      </Provider>
    </FlexContentsContainer>
  )
})

const StatusBox = styled.div`
  width: 60px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  position: relative;
  top: 3px;
  color: ${ODColors.White};
  background-color: ${ODColors.DarkSkyBlue};
`

const DeletedText = styled.span`
  font-size: 13px;
  color: ${ODColors.CoolGrey};
`

const Row = styled.div`
  display: flex;
`

const ActionText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.Salmon};
`
