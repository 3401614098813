import { ODHSpace } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { GQLWorkset, GQLWorksetGroupHasWorkset } from '../../@types/server'
import { IWorksetGroupHasWorksetListableOption } from '../../containers/User/WorksetGroup/WorksetGroupCommon'
import { ODListableContextType } from '../../ODListable/ODListableContext'
import { ODButton, ODButtonTheme } from '../ODButton'
import { ODToastType, showODToast } from '../ODToast'

interface IWorksetGroupDetailButtonsRow {
  listableContext: React.Context<
    ODListableContextType<GQLWorksetGroupHasWorkset, IWorksetGroupHasWorksetListableOption>
  >
  addAndRemoveUsers: (worksets: GQLWorkset[]) => Promise<void>
  addWorksetsToThisGroup: () => Promise<void>
  handleSelectWorksetsToProject: () => Promise<void>
}

export const WorksetGroupDetailButtonsRow: React.FC<IWorksetGroupDetailButtonsRow> = props => {
  const { addAndRemoveUsers, addWorksetsToThisGroup, listableContext, handleSelectWorksetsToProject } = props

  const {
    state,
  }: ODListableContextType<GQLWorksetGroupHasWorkset, IWorksetGroupHasWorksetListableOption> = React.useContext(
    listableContext
  )

  return (
    <ButtonsRow>
      <div style={{ width: '100%', maxWidth: 190 }}>
        <Button onClick={() => addAndRemoveUsers(state.list.map(item => item.workset))}>
          <ButtonText>Add/Remove Users</ButtonText>
        </Button>
      </div>
      <ODHSpace w={12} />
      <div style={{ width: '100%', maxWidth: 250 }}>
        <Button onClick={addWorksetsToThisGroup}>
          <ButtonText>Add Worksets to this Group</ButtonText>
        </Button>
      </div>
      <ODHSpace w={12} />
      <div style={{ width: '100%', maxWidth: 250 }}>
        <Button onClick={() => handleSelectWorksetsToProject()}>
          <ButtonText>Add All Worksets to Project</ButtonText>
        </Button>
      </div>
    </ButtonsRow>
  )
}

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 22px;
  padding-left: 22px;
`

const Button = styled.div`
  margin-left: 15px;
  width: 100%;
  height: 35px;
  padding: 6px 0 7px 1px;
  border-radius: 3px;
  background-color: #29b6ca;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const ButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`
