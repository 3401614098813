import { BlockingLoadBox, ODHSpace, ODVSpace, useODListableContext, useODModalConfirm } from '@odc/od-react-belt'
import { Decimal } from 'decimal.js'
import { isArray } from 'lodash'
import moment from 'moment-timezone'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import { GQLItemExecutionResult } from '../../../../@types/server'
import { GQLQueueProjectDownloadInput } from '../../../../agent'
import { FlexContentsContainer } from '../../../../components/FlexContentsContainer'
import { NotEnoughStorageBody } from '../../../../components/ODModal/NotEnoughStorageBody'
import { useCILSAgentContext } from '../../../../context/CILSAgentContext'
import { useODAppContext } from '../../../../context/ODAppContext'
import { useAPIs } from '../../../../context/useAPIs'
import { useProjectPerm } from '../../../../hooks/useProjectPerm'
import { ODListableStyle } from '../../../../ODListable/ODListableContext'
import { ODListablePaginatedTable2 } from '../../../../ODListable/ODListablePaginationTable2'
import { ODColors } from '../../../../styles/ODColors'
import { Utils } from '../../../../utils'
import { TCFDetailModal } from '../../TCFDetailModal'
import {
  IItemExecutionResultListableOption,
  useItemExecutionResultListDataLoader,
} from '../list/useItemExecutionResultListDataLoader'
import {
  PROJECT_ITEM_USER_STATUS_STRING,
  selectColorForProjectStatus,
  selectFontColorForProjectStatus,
} from '../ProjectCommon'
import { IMetaData } from '../store/metadata/IMetaData'

interface IProjectDataDetailPage {
  orgId: number
  projectId: number
  dataId: number // ieId 를 의미한다.
  dataTitle: string
}

export const ProjectDataDetailPage: React.FC<IProjectDataDetailPage> = props => {
  const { orgId, dataId, dataTitle, projectId } = props
  const { getProjectMetaData } = useAPIs()
  const location = useLocation<{ itemId: number; myProjectPerm: number; projectName: string }>()
  const { setCurrentProjectName, setCurrentProjectDataName } = useODAppContext()
  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})
  const dataLoader = useItemExecutionResultListDataLoader(dataId)
  const { Provider, Context } = useODListableContext<GQLItemExecutionResult, IItemExecutionResultListableOption>()
  const { queueProjectDownload, checkProjectDownload, getFreeDiskSpaceForDownload } = useCILSAgentContext()
  const projectPerm = useProjectPerm(orgId)

  const itemId = location.state.itemId

  const [loading, setLoading] = React.useState(false)
  const [projectMetadata, setProjectMetadata] = React.useState('')
  const [queueDownloading, setQueueDownloading] = React.useState(false)
  const [itemOpened, setItemOpened] = React.useState(false)
  const [lastTCFUpdated, setLastTCFUpdated] = React.useState(0)

  const getProjectMetadata = React.useCallback(async (projectId: number) => {
    try {
      const res = await getProjectMetaData({ id: projectId })
      setProjectMetadata(res.rawData)
    } catch (ex) {
      Utils.showError(ex)
    }
  }, [])

  React.useEffect(() => {
    if (projectId) {
      getProjectMetadata(projectId).catch()
    }
  }, [projectId])

  // const handleCompleteExecuting = async (dataTitle: string) => {
  //   if (
  //     await confirm({
  //       title: `Complete executing`,
  //       message: (
  //         <>
  //           Are you sure you want to complete your execution
  //           <br />
  //           of <span style={{ color: ODColors.Primary }}>{dataTitle}</span>?
  //         </>
  //       ),
  //       yes: 'Complete',
  //       no: 'Cancel',
  //     })
  //   ) {
  //     try {
  //       setLoading(true)
  //       //
  //     } catch (ex) {
  //       Utils.showError(ex)
  //     } finally {
  //       setLoading(false)
  //     }
  //     // }
  //   }
  // }

  React.useEffect(() => {
    setCurrentProjectName(location.state.projectName ?? '-')
    setCurrentProjectDataName(dataTitle)
    return () => {
      setCurrentProjectDataName('')
    }
  }, [setCurrentProjectName, setCurrentProjectDataName, dataTitle])

  const parsedProjectMetadata: IMetaData[] = React.useMemo(() => {
    if (!projectMetadata) {
      return []
    }

    const parsed = JSON.parse(projectMetadata) ?? []

    if (!isArray(parsed)) {
      return []
    }

    return JSON.parse(projectMetadata) ?? []
  }, [projectMetadata])

  const AddedThForMetadata = parsedProjectMetadata.map(meta => {
    return <th key={meta.key}>{meta.columnName}</th>
  })

  const addTdForMetadata = (meta: string) => {
    return parsedProjectMetadata.map(metadata => {
      if (!meta) {
        return <td key={metadata.key}>{'-'}</td>
      }

      const parsed = JSON.parse(meta)

      return <td key={metadata.key}>{parsed[metadata.key] ?? '-'}</td>
    })
  }

  const {
    Component: DownloadConfirmComponent,
    confirm: confirmDownload,
    props: DownloadConfirmComponentProps,
  } = useODModalConfirm({ title: 'Not enough storage', yes: 'Download', no: 'Cancel' })

  const handleQueueDownload = async (
    input: Partial<Pick<GQLQueueProjectDownloadInput, 'withResults' | 'onlyResults'>>
  ) => {
    const queueDownloadInput = {
      projectId,
      selectedIds: [dataId],
      withResults: input.withResults ?? false,
      onlyResults: input.onlyResults ?? false,
    }

    try {
      setQueueDownloading(true)
      const checkedSize = await checkProjectDownload(queueDownloadInput)
      let availableSize = new Decimal(999999999999)

      try {
        const freeSpace = await getFreeDiskSpaceForDownload()
        availableSize = new Decimal(freeSpace.output)
      } catch (ex) {
        Utils.showError('PC 의 용량 체크에 실패하였습니다.')
        console.error(ex)
      }
      const requiredSize = new Decimal(checkedSize.output)

      setQueueDownloading(false)

      if (requiredSize.gt(availableSize)) {
        if (
          !(await confirmDownload({
            message: (
              <NotEnoughStorageBody
                downloadSize={prettyBytes(requiredSize.toNumber())}
                freeSpace={prettyBytes(availableSize.toNumber())}
              />
            ),
          }))
        ) {
          return
        }
      }

      setQueueDownloading(true)

      await queueProjectDownload(queueDownloadInput)
      Utils.showSuccess('Queued 1 items for download.')
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setQueueDownloading(false)
    }
  }

  return (
    <>
      {itemOpened && (
        <TCFDetailModal
          isOpen={itemOpened}
          toggle={() => setItemOpened(false)}
          itemId={itemId}
          lastTCFUpdatedTime={lastTCFUpdated}
          onTCFItemChanged={() => setLastTCFUpdated(0)}
          onTCFItemUpdated={() => setLastTCFUpdated(new Date().getTime())}
          onLoadMore={() => null}
        />
      )}
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v.ierId.toString()}
        pageSize={10}
        searchOnLoad
        style={ODListableStyle.TableStyle}
      >
        <DownloadConfirmComponent {...DownloadConfirmComponentProps} />
        <BlockingLoadBox show={queueDownloading} message="Start downloading.." />
        <FlexContentsContainer>
          <ConfirmComponent {...confirmProps} />
          <BlockingLoadBox show={loading} />
          <div style={{ padding: 17 }}>
            <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
              <CardHeader style={{ backgroundColor: ODColors.PaleGrey, height: 45, fontSize: 14, letterSpacing: 0 }}>
                {dataTitle}
              </CardHeader>
              <CardBody>
                <ButtonsRow>
                  {/* 클릭 시 이미지 상세보기 팝업 표시 */}
                  <ButtonWithOutline onClick={() => setItemOpened(true)}>View Data</ButtonWithOutline>
                  {projectPerm.isSameOrGreaterThanEditor(location.state.myProjectPerm) && (
                    <>
                      {/* 클릭 시 Agent를 통해 선택한 데이터 자체와 데이터 중 Finished(Success),
                  Confirmed 상태의 프로젝트 결과 산출물 모두 다운로드 시작 */}
                      <PrimaryButton
                        style={{ marginLeft: 'auto' }}
                        onClick={() => handleQueueDownload({ withResults: true })}
                      >
                        Download item and results
                      </PrimaryButton>
                      <ODHSpace w={11} />
                      {/* 클릭 시 Agent를 통해 선택한 데이터 중 Finished(Success) 와
                  Confirmed 상태의 데이터의 프로젝트 결과 산출물만 다운로드 시작 */}
                      <PrimaryButton
                        style={{ maxWidth: 176 }}
                        onClick={() => handleQueueDownload({ onlyResults: true })}
                      >
                        Download results
                      </PrimaryButton>
                      <ODHSpace w={11} />
                      {/* - 선택한 데이터의 데이터 자체만 다운로드하는 버튼
                  - 클릭 시 Agent를 통해 데이터만 다운로드 시작
               */}
                      <PrimaryButton
                        style={{ maxWidth: 176 }}
                        onClick={() => handleQueueDownload({ onlyResults: false, withResults: false })}
                      >
                        Download Item
                      </PrimaryButton>
                    </>
                  )}
                </ButtonsRow>
                <ODVSpace h={21} />
                <ODListablePaginatedTable2
                  numColumns={4 + parsedProjectMetadata.length}
                  renderHeader={() => (
                    <tr>
                      <th>User</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>마지막 업로드 완료 시간</th>
                      <th>업로드 버전(업로드한 횟수)</th>
                      <th>Status</th>
                      {AddedThForMetadata}
                    </tr>
                  )}
                  renderRow={(item: GQLItemExecutionResult, context) => {
                    return (
                      <tr key={item.ierId}>
                        <td>{item.user.name ?? '-'}</td>
                        <td>{item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                        <td>{item.finishedAt ? moment(item.finishedAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                        <td>{item.uploadedAt ? moment(item.uploadedAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                        <td>{item.version ?? '-'}</td>
                        <td>
                          <StatusBox
                            style={{
                              backgroundColor: selectColorForProjectStatus(item.status),
                              color: selectFontColorForProjectStatus(item.status),
                            }}
                          >
                            {PROJECT_ITEM_USER_STATUS_STRING[item.status] ?? '-'}
                          </StatusBox>
                          {/* Complete executing 팝업 표시 */}
                          {/*{item.status ===*/}
                          {/*  ((ITEM_EXECUTION_STATUS.Executing as unknown) as GQLITEM_EXECUTION_STATUS) && (*/}
                          {/*  <div style={{ width: '100%', maxWidth: 100, textAlign: 'center' }}>*/}
                          {/*    <ODVSpace h={3} />*/}
                          {/*    <PrimaryText*/}
                          {/*      onClick={() => handleCompleteExecuting('test')}*/}
                          {/*      style={{ textDecoration: 'underline' }}*/}
                          {/*    >*/}
                          {/*      Complete*/}
                          {/*    </PrimaryText>*/}
                          {/*  </div>*/}
                          {/*)}*/}
                        </td>
                        {addTdForMetadata(item.item?.meta ?? '')}
                      </tr>
                    )
                  }}
                  listableContext={Context}
                  eventParentContext={{}}
                />
              </CardBody>
            </Card>
          </div>
        </FlexContentsContainer>
      </Provider>
    </>
  )
}

const ButtonsRow = styled.div`
  display: flex;
`

const ButtonWithOutline = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${ODColors.Primary};
  color: ${ODColors.Primary};
  font-size: 15px;
  width: 100%;
  max-width: 176px;
  height: 35px;
  border-radius: 3px;
`

const PrimaryButton = styled.button`
  width: 100%;
  max-width: 250px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  font-size: 15px;
  color: ${ODColors.White};
`

const StatusBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100px;
  height: 16px;
  padding: 1px 0;
  font-size: 10px;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  position: relative;
  top: 4px;
`

const PrimaryText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.Primary};
  cursor: pointer;
`
