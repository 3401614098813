import { AppHeader } from '@coreui/react'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ODHeader } from '../../components/ODHeader'
import { useODAppContext } from '../../context/ODAppContext'
import { ODSingleOrgProvider } from '../../context/ODSingleOrgContext'
import { SiteUrls } from '../../urls'
import { TestContainer } from '../TestContainer'
import { MyPageRootContainer } from './MyPage/MyPageRootContainer'
import { UserDashboardContainer } from './UserDashboardContainer'
import { UserOrgMainLayout } from './UserOrgMainLayout'

export const UserMainLayout: React.FC = (props: any) => {
  const {
    state: { userProfile },
  } = useODAppContext()

  return (
    <div className="app" style={{ margin: 0 }}>
      <AppHeader fixed>
        <ODHeader name={userProfile?.name} profile={userProfile} isInAdmin={false} isInOrgAdmin={false} />
      </AppHeader>
      <Switch>
        <Route path={SiteUrls.Test} component={TestContainer} />
        <Route path={SiteUrls.User.Root} exact component={UserDashboardContainer} />
        <Route path={SiteUrls.User.MyPage.Root} component={MyPageRootContainer} />
        <Route
          path={SiteUrls.User.Org.Root(':orgId')}
          render={(props: any) => {
            const orgId = parseInt(props.match.params.orgId, 10)
            if (orgId > 0) {
              return (
                <ODSingleOrgProvider orgId={orgId}>
                  <UserOrgMainLayout orgId={orgId} />
                </ODSingleOrgProvider>
              )
            }
            return <Redirect to={SiteUrls.User.Root} />
          }}
        />
        <Redirect to={SiteUrls.User.Root} />
      </Switch>
    </div>
  )
}
