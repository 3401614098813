import { BlockingLoadBox, ODVSpace, useCounter, useODModalConfirm } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { GQLProjectHasWorkset } from '../../../../@types/server'
import { ODIcon, ODIcons } from '../../../../components/ODIcon'
import { useAPIs } from '../../../../context/useAPIs'
import { ODColors } from '../../../../global-styles'
import { useProjectPerm } from '../../../../hooks/useProjectPerm'
import { createODListableContext, ODListableStyle } from '../../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../../../urls'
import { Utils } from '../../../../utils'
import {
  IProjectHasWorksetListableOption,
  useProjectHasWorksetListDataLoader,
} from '../list/useProjectHasWorksetListDataLoader'
import { ProjectWorksetSearch } from './ProjectWorksetSearch'

interface IProjectWorksetPage {
  projectId: number
  orgId: number
  ownerOrgId: number
  myProjectPerm: number
}

const { Provider, Context } = createODListableContext<GQLProjectHasWorkset, IProjectHasWorksetListableOption>()

// 프로젝트에 추가된 Workset을 조회
// 권한에 따라 Workset을 추가하거나 제거 할 수 있다.
export const ProjectWorksetPage: React.FC<IProjectWorksetPage> = props => {
  const { projectId, orgId, myProjectPerm } = props
  const { removeProjectHasWorkset } = useAPIs()
  const [loading, setLoading] = React.useState(false)
  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})
  const dataLoader = useProjectHasWorksetListDataLoader(projectId)
  const projectPerm = useProjectPerm(orgId)
  const [token, refresh] = useCounter()

  const handleRemoveWorkset = async (name: string, projectId: number, worksetId: number) => {
    if (
      await confirm({
        title: `Remove Workset`,
        message: (
          <>
            Are you sure you want to remove <span style={{ color: ODColors.Primary }}>{name}</span> from the
            <br />
            project?
            <br />
            This cannot be undone.
          </>
        ),
        yes: 'Remove',
        no: 'Cancel',
      })
    ) {
      try {
        setLoading(true)
        await removeProjectHasWorkset({ projectId, worksetId })
        refresh()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
      // }
    }
  }

  const TableDefinition: ODListableTableDefinition<GQLProjectHasWorkset, IProjectHasWorksetListableOption> = [
    {
      id: 'WorksetName',
      title: 'Workset name',
      transform: v => <Link to={SiteUrls.User.Org.Workset.Detail(v.worksetId)(orgId)}>{v.workset.name ?? '-'}</Link>,
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Items',
      title: 'Items',
      transform: v => {
        return v.workset.numItems ?? '0'
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'LastItemAdded',
      title: 'Last item added',
      transform: v => {
        return v.workset.lastAddedAt ? Utils.formatDate2(v.workset.lastAddedAt) : '-'
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Owner',
      title: 'Owner',
      transform: v => {
        return v.workset.owner.name ?? '-'
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Created',
      title: 'Created',
      transform: v => {
        return v.workset.createdAt ? Utils.formatDate2(v.workset.createdAt) : '-'
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    // 프로젝트 내의 권한이 Admin 이상인 경우에만 삭제 가능
    {
      id: 'Action',
      title: 'Action',
      transform: v => {
        return (
          <div>
            {projectPerm.isSameOrGreaterThanAdmin(myProjectPerm) && (
              <RemoveBox onClick={() => handleRemoveWorkset(v.workset.name ?? '-', v.projectId, v.worksetId)}>
                <ODIcon icon={ODIcons.CoreXCircle} style={{ fontSize: 16, color: ODColors.White }} />
              </RemoveBox>
            )}
          </div>
        )
      },
      thClass: 'text-left',
      className: 'text-left',
    },
  ]

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.worksetId.toString()}
      pageSize={10}
      searchOnLoad
      style={ODListableStyle.TableStyle}
      refreshToken={token.toString()}
    >
      <BlockingLoadBox show={loading} />
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <ConfirmComponent {...confirmProps} />

        <CardBody>
          <ProjectWorksetSearch
            orgId={orgId}
            projectId={projectId}
            listableContext={Context}
            myProjectPerm={myProjectPerm}
            refresh={refresh}
          />
          <ODVSpace h={30} />
          {/* 현재 프로젝트에 있는 유저 리스트 표시 */}
          <ODListablePaginatedTable
            fields={TableDefinition}
            listableContext={Context}
            renderLoading={() => 'Loading..'}
            renderEmpty={() => 'No Result.'}
          />
          <ODListablePagination hideIfSinglePage listableContext={Context} />
        </CardBody>
      </Card>
    </Provider>
  )
}

const RemoveBox = styled.div`
  width: 39px;
  height: 35px;
  border-radius: 2px;
  background-color: ${ODColors.Salmon};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
