import React from 'react'
import styled from 'styled-components'
import { GQLUser } from '../../../@types/server'
import { ODButton, ODButtonSize, ODButtonTheme } from '../../../components/ODButton'
import { ODEntityTextLabel } from '../../../ODEntityEditor/FormComponents/ODEntityTextLabel'
import { ODModalUserPicker } from './ODModalUserPicker'

type AgentUserPickerProps = {
  orgId: number
  owners: Array<GQLUser>
  setOwners: (owners: Array<GQLUser>) => any
  isPickerOpen: boolean
  setIsPickerOpen: (v: boolean) => any
}

const UserRow = styled.div`
  margin-bottom: 15px;
`

const UserListWrapper = styled.div`
  margin-top: 8px;
`

export const AgentUserPicker: React.FC<AgentUserPickerProps> = ({
  orgId,
  owners,
  setOwners,
  isPickerOpen,
  setIsPickerOpen,
}) => {
  return (
    <ODEntityTextLabel name="agentOwner" label="Agent Owner">
      {isPickerOpen && (
        <ODModalUserPicker
          orgId={orgId}
          initialUsers={owners}
          isOpen={isPickerOpen}
          setIsOpen={setIsPickerOpen}
          title="Add Agent Owner"
          placeholder="Search by name or email"
          bottomDescription="Only users in this organization can be added."
          buttonName="Done"
          onConfirm={(users: Array<GQLUser>) => {
            setOwners(users)
            setIsPickerOpen(false)
          }}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <UserListWrapper>
          {owners.map(user => (
            <UserRow key={user.userId}>
              {user.name} ({user.email})
            </UserRow>
          ))}
          {owners.length === 0 && <span>No owners are assigned.</span>}
        </UserListWrapper>
        <div style={{ height: 20 }} />
        <ODButton
          theme={ODButtonTheme.PrimaryInvert}
          size={ODButtonSize.Small}
          onClick={() => setIsPickerOpen(true)}
          style={{ height: 35 }}
        >
          Manage owners
        </ODButton>
      </div>
    </ODEntityTextLabel>
  )
}
