import { useCounter } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { GQLProject } from '../../../../@types/server'
import { FlexContentsContainer } from '../../../../components/FlexContentsContainer'
import { ITabBarIcon } from '../../../../components/ODTabBar'
import { ODTabBarButton, ODTabBarContainer } from '../../../../components/ODTabBarButton'
import { useODAppContext } from '../../../../context/ODAppContext'
import { useAPIs } from '../../../../context/useAPIs'
import { SingleQueryWrapper } from '../../../../context/useSingleQueryAPI'
import { useCILSStore } from '../../../../di/configureRootStore'
import { useProjectPerm } from '../../../../hooks/useProjectPerm'
import { ProjectDataPage } from './ProjectDataPage'
import { ProjectSettings } from './ProjectSettings'
import { ProjectUserPage } from './ProjectUserPage'
import { ProjectWorksetPage } from './ProjectWorksetPage'

interface IProjectDetailPage {
  orgId: number
  projectId: number
}

const Items: ITabBarIcon[] = [
  {
    text: 'Data',
  },
  {
    text: 'User',
  },
  {
    text: 'Workset',
  },
  {
    text: 'Settings',
  },
]

export const ProjectDetailPage: React.FC<IProjectDetailPage> = observer(props => {
  const { orgId, projectId } = props
  const store = useCILSStore()
  const { getProject } = useAPIs()
  const [token, refresh] = useCounter()
  const projectPerm = useProjectPerm(orgId)

  const [tabRaw, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const tab = Math.min(Math.max(tabRaw, 0), Items.length - 1)

  const projectName = React.useRef<string>('')

  // 디테일 화면에 들어오면 아래 두개의 스토어에 projectId 를 set 해준다.
  React.useEffect(() => {
    store.addWorksetToProjectStore.setProjectId(projectId)
    store.addUserToProjectStore.addProjectIds([projectId])

    // 컴포넌트가 언마운트될때 projectId 를 초기화
    return () => {
      store.addWorksetToProjectStore.clearProjectId()
      store.addUserToProjectStore.addProjectIds([])
    }
  }, [projectId])

  return (
    <FlexContentsContainer>
      <SingleQueryWrapper deps={[projectId, token]} getter={() => getProject({ id: projectId })}>
        {({ data }) => {
          projectName.current = data.name
          return (
            <>
              {/*<ODTabBar items={Items} selectedIndex={tab} onClickTab={setTab} />*/}
              <ProjectDetailWrapper project={data}>
                <ODTabBarContainer>
                  <ODTabBarButton active={tab === 0} onClick={() => setTab(0)}>
                    <strong>Data</strong>
                  </ODTabBarButton>
                  <ODTabBarButton active={tab === 1} onClick={() => setTab(1)}>
                    <strong>User</strong>
                  </ODTabBarButton>
                  <ODTabBarButton active={tab === 2} onClick={() => setTab(2)}>
                    <strong>Workset</strong>
                  </ODTabBarButton>
                  {projectPerm.isSameOrGreaterThanAdmin(data.myPerm) && (
                    <ODTabBarButton active={tab === 3} onClick={() => setTab(3)}>
                      <strong>Settings</strong>
                    </ODTabBarButton>
                  )}
                </ODTabBarContainer>
                {tab === 0 && (
                  <ProjectDataPage
                    orgId={orgId}
                    ownerOrgId={data.ownerOrgId}
                    projectId={projectId}
                    myProjectPerm={data.myPerm}
                    projectName={data.name}
                    numConfirmedItems={data.numConfirmedItems}
                    pageRefresh={refresh}
                  />
                )}
                {tab === 1 && (
                  <ProjectUserPage
                    orgId={orgId}
                    ownerOrgId={data.ownerOrgId}
                    projectId={projectId}
                    myProjectPerm={data.myPerm}
                    project={data}
                  />
                )}
                {tab === 2 && (
                  <ProjectWorksetPage
                    orgId={orgId}
                    ownerOrgId={data.ownerOrgId}
                    projectId={projectId}
                    myProjectPerm={data.myPerm}
                  />
                )}
                {/* 해당 프로젝트에서 권한이 Admin이상인 경우에만 접근 가능 */}
                {tab === 3 && projectPerm.isSameOrGreaterThanAdmin(data.myPerm) && (
                  <ProjectSettings
                    orgId={orgId}
                    ownerOrgId={data.ownerOrgId}
                    projectId={projectId}
                    pageRefresh={refresh}
                    projectName={data.name}
                    projectType={data.type}
                  />
                )}
              </ProjectDetailWrapper>
            </>
          )
        }}
      </SingleQueryWrapper>
    </FlexContentsContainer>
  )
})

interface IProjectDetailWrapper {
  project: GQLProject
}

export const ProjectDetailWrapper: React.FC<IProjectDetailWrapper> = props => {
  const { project, children } = props
  const { setCurrentProjectName } = useODAppContext()

  React.useEffect(() => {
    if (project) {
      setCurrentProjectName(project.name || '')
    }

    return () => {
      setCurrentProjectName('')
    }
  }, [setCurrentProjectName, project])

  return <div style={{ display: 'flex', flexDirection: 'column', padding: '25px 30px 30px' }}>{children}</div>
}
