import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../ODIcon'
import { ODToastType, showODToast } from '../ODToast'
import { ODModal } from './ODModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #c8ced3;
  background-color: #ffffff;
`
const Title = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #73818f;
`
const CustomInput = styled(Input)`
  height: 35px;
  border-radius: 3px;
  border: solid 1px #c8ced3;
`
const Button = styled.div`
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const ButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`

type ODModalShowShareLinkProps = {
  onClose: () => void
  shareLink: string
}

export const ODModalShowShareLink: React.FC<ODModalShowShareLinkProps> = props => {
  const { onClose, shareLink } = props

  const onCopyShareLink = () => {
    onClose()
    showODToast(ODToastType.Success, 'Share Link', 'Copied Share Link')
  }

  return (
    <ODModal isOpen toggle={() => null}>
      <Wrapper>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 15 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 12,
            }}
          >
            <Title>Share Link</Title>
            <ODIcon
              icon={ODIcons.CoreX}
              style={{ color: '#bdbdbd', fontSize: 18, cursor: 'pointer' }}
              onClick={onClose}
            />
          </div>
          <CustomInput type="text" name="shareLink" defaultValue={shareLink} readOnly />
          <CopyToClipboard text={shareLink} onCopy={() => onCopyShareLink()}>
            <Button style={{ marginTop: 20 }}>
              <ButtonText>Copy</ButtonText>
            </Button>
          </CopyToClipboard>
        </div>
      </Wrapper>
    </ODModal>
  )
}
