"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLogger = exports.LogNameSpace = void 0;

var Debug = require("debug");

var LogNameSpace;

(function (LogNameSpace) {
  LogNameSpace["FileWatcher"] = "od.FileWatcher";
  LogNameSpace["ServerInfo"] = "od.server";
  LogNameSpace["FileSync"] = "od.FileSync";
})(LogNameSpace = exports.LogNameSpace || (exports.LogNameSpace = {}));

function getLogger(namespace) {
  return {
    debug: Debug("".concat(namespace, ".debug")),
    info: Debug("".concat(namespace, ".info")),
    warn: Debug("".concat(namespace, ".warn")),
    error: Debug("".concat(namespace, ".error"))
  };
}

exports.getLogger = getLogger;