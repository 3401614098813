import styled from 'styled-components'

export const Clickable = styled.span`
  cursor: pointer;
  color: #20a8d8;

  :hover {
    text-decoration: underline;
  }
`
