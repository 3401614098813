import React from 'react'
import { GQLListableOrg, GQLListableOrgInput } from '../@types/server'
import { GQL_LIST_ORG } from '../containers/Admin/Organization/OrganizationListContainer'
import { OrgOption } from '../context/ODCILSHooks'
import { useODQuery } from '../context/ODCommon'
import { ODDropdownComponent, ODDropdownItem, renderDropdownItemWithSearchFilter } from './ODDropdownComponent'

type Props = {
  onChange: (v: Array<number> | null) => void
}

type OrgOptionAsDropdownItem = OrgOption & ODDropdownItem
const ALL_ORG_ID = -1

export const OrgDropdownComponent: React.FC<Props> = ({ onChange }) => {
  const apiListOrg = useODQuery<GQLListableOrgInput, GQLListableOrg>(GQL_LIST_ORG)
  const [orgList, setOrgList] = React.useState<Array<OrgOption> | null>(null)

  React.useEffect(() => {
    apiListOrg({ filter: null, page: 1, pageSize: 1000 }).then(v => {
      const organizations: Array<OrgOption> = v.list.map(o => ({
        orgId: o.orgId,
        label: o.name!,
        value: o.name!,
      }))
      setOrgList(organizations)
    })
  }, [apiListOrg])

  if (orgList === null) {
    return null
  }

  const allOrg: OrgOptionAsDropdownItem = {
    label: 'All Organization',
    id: 0,
    orgId: ALL_ORG_ID,
    text: 'All Organization',
    value: 'All Organization',
  }

  const newList = [
    allOrg,
    ...orgList.map(v => ({
      ...v,
      id: v.orgId,
      text: v.label,
    })),
  ]

  return (
    <ODDropdownComponent
      showFilterBox
      filterBoxPlaceholder="Organization"
      list={newList}
      onChange={(v: OrgOptionAsDropdownItem | null) => onChange(v && v.orgId !== ALL_ORG_ID ? [v.orgId] : null)}
      textNotSelected="Organization"
      filterAlgorithm={(v: OrgOptionAsDropdownItem, keyword: string) => {
        if (v.orgId === ALL_ORG_ID && keyword.length > 0) {
          return false
        }
        return v.label.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
      }}
      renderItem={(v: OrgOptionAsDropdownItem, onClick) => {
        return renderDropdownItemWithSearchFilter(
          v,
          onClick,
          v.orgId === ALL_ORG_ID
            ? {
                fontWeight: 'bold',
                color: '#2f353a',
              }
            : {}
        )
      }}
    />
  )
}
