import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { GQLCilsStorage, GQLListableCilsStorage, GQLListableCilsStorageInput } from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODQuery } from '../../../context/ODCommon'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

interface CilsStorageDataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLCilsStorage, CilsStorageDataLoaderOption>()

const GQL_LIST_CILS_STORAGE = `
query listCilsStorage($data: ListableCilsStorageInput!) {
  listCilsStorage(data: $data) {
    list {
      storageId
      name
      address
      addressOptional
      createdAt
    }
    totalCount
    page
    pageSize
  }
}
`

type Props = {
  orgId: number
}

export const CilsStorageListContainer: React.FC<Props> = ({ orgId }) => {
  const apiList = useODQuery<GQLListableCilsStorageInput, GQLListableCilsStorage>(GQL_LIST_CILS_STORAGE)

  const dataLoader = React.useCallback(
    async function CilsStorageDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: CilsStorageDataLoaderOption
    ): Promise<ODListableResponseType<GQLCilsStorage>> {
      const r = await apiList({ page, pageSize, filter: options.filter || null, orgId })
      return r as ODListableResponseType<GQLCilsStorage>
    },
    [apiList, orgId]
  )
  const TableDefinition: ODListableTableDefinition<GQLCilsStorage, CilsStorageDataLoaderOption> = [
    {
      id: 'storageId',
      title: 'ID',
      transform: v => v.storageId,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'name',
      title: 'Name',
      transform: v => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'address',
      title: 'Main Address',
      transform: v => v.address,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'optionalAddress',
      title: 'Optional addresses',
      transform: v => v.addressOptional,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'regTime',
      title: 'Reg. Time',
      transform: v => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'edit',
      title: 'Edit',
      transform: v => <Link to={SiteUrls.OrgAdmin.Storage.Edit(v.storageId)(orgId)}>Edit</Link>,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>NAS Storage</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.storageId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name, address"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <div>
                <Link to={SiteUrls.OrgAdmin.Storage.Add(orgId)} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    Add NAS Storage
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
