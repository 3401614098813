import { useODAppContext } from './ODAppContext'

/**
 * useTestData 옵션이 켜져있을 때와 아닐 때 다른 값을 반환한다.
 */
export function useTestData<T>(normalValue: T, valueIfTest: T) {
  const { appOptions } = useODAppContext()
  const { useTestData } = appOptions || {}

  return useTestData ? valueIfTest : normalValue
}
