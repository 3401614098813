import React, { FormEvent } from 'react'
import { Form, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { useTestData } from '../../context/useTestData'
import { Utils } from '../../utils'
import { ODButton, ODButtonSize, ODButtonTheme } from '../ODButton'

type SignupBodyProps = {
  onSubmit: (username: string, name: string, email: string, pw: string, orgId: number) => void
}

export const SignupBody: React.FC<SignupBodyProps> = ({ onSubmit }) => {
  const [username, setUsername] = React.useState(useTestData('', 'odcode'))
  const [name, setName] = React.useState(useTestData('', 'Jeff Oh'))
  const [email, setEmail] = React.useState(useTestData('', 'jeff_100@odcode.com'))
  const [password, setPassword] = React.useState(useTestData('', '12345678'))
  const [passwordConfirm, setPasswordConfirm] = React.useState(useTestData('', '12345678'))
  // const [selectedOrg, setSelectedOrg] = React.useState<number | null>(null)
  const [selectedOrg] = React.useState<number | null>(1) // 임시로 토모큐브 강제

  const onClickRegister = () => {
    if (password !== passwordConfirm) {
      Utils.showError('Passwords does not match.')
      return
    }

    if (!selectedOrg) {
      Utils.showError('Select organization.')
      return
    }

    onSubmit(username, name, email, password, selectedOrg)
  }

  const handleSubmit = (e: FormEvent) => {
    onClickRegister()
    e.preventDefault()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-user" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          placeholder="User ID"
          autoComplete="id"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-user" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          placeholder="Username"
          autoComplete="username"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>@</InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          placeholder="Email"
          autoComplete="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-lock" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="password"
          placeholder="Password"
          autoComplete="new-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-lock" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="password"
          placeholder="Repeat password"
          autoComplete="new-password"
          value={passwordConfirm}
          onChange={e => setPasswordConfirm(e.target.value)}
        />
      </InputGroup>
      {/*<OrgSelect theme={OrgSelectTheme.SignUp} selected={selectedOrg} onChange={org => setSelectedOrg(org.orgId)} />*/}
      <ODButton theme={ODButtonTheme.Primary} size={ODButtonSize.Small} fullWidth type="submit">
        Create Account
      </ODButton>
    </Form>
  )
}
