import React from 'react'

type useDoubleClickProps = {
  onClick: (e: any) => void
  onDoubleClick: (e: any) => void
  ref: any
  interval: number
}

export function useDoubleClick({ onClick, onDoubleClick, ref, interval }: useDoubleClickProps) {
  React.useEffect(() => {
    let count = 0
    let handler: any = null
    const handleClick = (e: any) => {
      count += 1

      if (handler) {
        clearTimeout(handler)
        handler = null
      }

      handler = setTimeout(() => {
        if (count === 1) {
          onClick(e)
        } else if (count === 2) {
          onDoubleClick(e)
        }

        count = 0
        handler = null
      }, interval)
    }

    ref!.current.addEventListener('click', handleClick)

    return () => {
      ref!.current.removeEventListener('click', handleClick)
    }
  }, [onClick, onDoubleClick, ref, interval])
}
