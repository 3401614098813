import { ODHSpace, ODVSpace } from '@odc/od-react-belt'
import React, { useRef } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { useUsersInProjectList } from '../../../context/ODCILSHooks'
import { ODColors } from '../../../global-styles'
import { ODButton, ODButtonTheme } from '../../ODButton'
import { ODIcon, ODIcons } from '../../ODIcon'
import { ODModal, ODModalSize } from '../../ODModal/ODModal'
import { ODModalBody } from '../../ODModal/ODModalBody'
import { ODModalFooter } from '../../ODModal/ODModalFooter'
import { ODModalHeader } from '../../ODModal/ODModalHeader'
import { SelectOptionType } from '../../ODModal/SelectWorksetMemberAndPermission'

interface IODModalSetAssigneeOrReviewer {
  isOpen: boolean
  projectId: number
  onClose: () => void
  onConfirm: (userId: number) => void
  target: 'reviewer' | 'assignee'
}

export const ODModalSetAssigneeOrReviewer: React.FC<IODModalSetAssigneeOrReviewer> = props => {
  const { isOpen, onClose, onConfirm, target, projectId } = props
  const { users, refresh } = useUsersInProjectList(projectId)
  const [value, setValue] = React.useState<SelectOptionType | null>(null)
  const selectFocus = useRef<HTMLInputElement>(null)

  const handleConfirm = async () => {
    if (!value) {
      return
    }
    await onConfirm(value.value)
  }

  React.useEffect(() => {
    refresh()
  }, [isOpen])

  const modalTitle = `Set ${target}`
  return (
    <ODModal
      isOpen={isOpen}
      toggle={onClose}
      size={ODModalSize.Normal}
      style={{ borderRadius: 4, maxWidth: 422, boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={modalTitle} onClose={onClose} />
        <ODModalBody>
          <AssigneeSelectLabel>{target.replace(/\b[a-z]/, letter => letter.toUpperCase())}</AssigneeSelectLabel>
          <ODVSpace h={12} />
          <CustomSelectBox
            name="category"
            options={users}
            value={value}
            onChange={(e: React.SetStateAction<SelectOptionType | null>) => {
              if (!e) {
                return
              }
              setValue(e)
            }}
            ref={selectFocus}
          />
          <ODVSpace h={25} />
          <div style={{ display: 'flex' }}>
            <ODIcon
              icon={ODIcons.MaterialError}
              style={{ fontSize: 14, color: ODColors.Primary, position: 'relative', top: 3 }}
            />
            <ODHSpace w={14} />
            <div style={{ fontSize: 14, color: ODColors.Primary }}>
              Only one {target} allowed
              <br />
              If data has {target} already, their {target}
              <br />
              will be replaced.
            </div>
          </div>
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleConfirm}>
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

const AssigneeSelectLabel = styled.span`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: ${ODColors.Steel};
`

const CustomSelectBox = styled(Select)`
  width: 348px;
`
