import { ODIcons } from '../components/ODIcon'
import { ODSiteNav } from '../context/ODCommon'
import { Strings } from '../i18n/res'
import { SiteUrls } from '../urls'

export const OrgAdminSiteNavs = (
  orgId: string,
  canViewCategory: boolean,
  canViewAgent: boolean,
  canViewAlert: boolean,
  canViewAudit: boolean,
  canViewProjects: boolean,
  canViewProjectMetadata: boolean
): ODSiteNav => ({
  // @ts-ignore
  items: [
    {
      name: Strings.Dashboard,
      url: SiteUrls.OrgAdmin.Dashboard.Main(orgId),
      icon: ODIcons.Dashboard,
      iconSpanStyle: { position: 'relative', top: 2 },
    },
    canViewAlert
      ? {
          name: 'Alerts',
          url: SiteUrls.OrgAdmin.Alert.List(orgId),
          icon: ODIcons.CoreWarning,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 1 },
        }
      : null,
    {
      name: Strings.User,
      url: SiteUrls.OrgAdmin.User.List(orgId),
      icon: ODIcons.CoreUser,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 2 },
    },
    {
      name: Strings.UserGroup,
      url: SiteUrls.OrgAdmin.UserGroup.List(orgId),
      icon: ODIcons.CorePeople,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1, paddingLeft: 2, marginRight: 10 },
    },
    canViewAgent
      ? {
          name: 'Agent accounts',
          url: SiteUrls.OrgAdmin.Agent.List(orgId),
          icon: ODIcons.CoreBuilding,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 2 },
        }
      : null,
    {
      name: 'Storages',
      url: SiteUrls.OrgAdmin.Storage.List(orgId),
      icon: ODIcons.CoreStorage,
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 2 },
    },
    canViewCategory
      ? {
          name: Strings.OrgAdminCategory,
          url: SiteUrls.OrgAdmin.Category.List(orgId),
          icon: ODIcons.CoreList,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 2 },
        }
      : null,
    canViewAudit
      ? {
          name: 'Audit Log',
          url: SiteUrls.OrgAdmin.Audit.List(orgId),
          icon: ODIcons.CoreSpreadsheet,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 1 },
        }
      : null,
    canViewProjects
      ? {
          name: 'Projects',
          url: SiteUrls.OrgAdmin.Projects.List(orgId),
          icon: ODIcons.CoreFile,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 1 },
        }
      : null,
    canViewProjectMetadata
      ? {
          name: 'Project Metadata',
          url: SiteUrls.OrgAdmin.ProjectMetadata.List(orgId),
          icon: ODIcons.CoreStorage,
          iconStyle: { fontSize: '1rem' },
          iconSpanStyle: { position: 'relative', top: 1 },
        }
      : null,
    {
      name: '',
      url: '',
      isSeparator: true,
    },
    {
      name: 'Exit Admin',
      url: SiteUrls.User.Org.Root(orgId),
      icon: ODIcons.CoreAccountLogout,
      exact: true,
      class: 'nav-red',
      iconStyle: { fontSize: '1rem' },
      iconSpanStyle: { position: 'relative', top: 1 },
    },
  ].filter(v => v),
})
