import { populateAgentPropsInput, UserPropsInputValidation } from '@cils/common'
import * as React from 'react'
import * as Yup from 'yup'
import {
  GQLAgentUserCreationPropsInput,
  GQLAgentUserUpdatePropsInput,
  GQLSingleIDInput,
  GQLUser,
} from '../../../@types/server'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { USER_FULL_SNAPSHOT } from '../../../gqls'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'
import { AgentUserPicker } from './AgentUserPicker'

type OrgCategoryEditContainerProps = {
  orgId: number
  idEditing?: number
}

const GQL_CREATE = `
mutation registerAgent($data: AgentUserCreationPropsInput!) {
  registerAgent(data: $data) {
    ${USER_FULL_SNAPSHOT}
    owners {
      ${USER_FULL_SNAPSHOT}
    }
  }
}
`

const GQL_UPDATE = `
mutation updateAgent($data: AgentUserUpdatePropsInput!) {
  updateAgent(data: $data) {
    ${USER_FULL_SNAPSHOT}
    owners {
      ${USER_FULL_SNAPSHOT}
    }
  }
}
`

const GQL_GET = `
query getUserOfId($data: SingleIDInput!) {
  getUserOfId(data: $data) {
    ${USER_FULL_SNAPSHOT}
    owners {
      ${USER_FULL_SNAPSHOT}
    }
  }
}
`

type PropsInput = Partial<GQLAgentUserUpdatePropsInput & GQLAgentUserCreationPropsInput>
type Entity = GQLUser

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: UserPropsInputValidation.name.required('Name is required.'),
    // password: UserPropsInputValidation.password.required('Password is required.'),
    email: UserPropsInputValidation.email.required('Login email is required.'),
  })
}

const FAKE_PASSWORD = '@@__THIS_IS_FAKE__@@'

export const OrgAgentEditContainer: React.FC<OrgCategoryEditContainerProps> = props => {
  const { idEditing, orgId } = props
  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_CREATE)
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_UPDATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const owners = React.useRef<Array<GQLUser>>([])
  const [isUserPickerOpen, setIsUserPickerOpen] = React.useState(false)

  const setOwners = React.useCallback((users: Array<GQLUser>) => {
    owners.current.length = 0
    users.forEach(u => owners.current.push(u))
  }, [])

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          setOwners([])
          return {
            id: null,
            name: '',
            username: '',
            email: '',
            password: '',
          }
        }

        if (data.owners) {
          setOwners(data.owners!)
        }
        return {
          id: data.userId,
          name: data.name || '',
          username: data.username || '',
          email: data.email || '',
          password: FAKE_PASSWORD,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (idEditing) {
          await apiUpdate({
            id: idEditing,
            ...data,
            ownerIds: owners.current.map(owner => owner.userId),
          })
          Utils.showSuccess('Updated an agent', 'Success')
        } else {
          await apiCreate({
            name: data.name,
            username: data.username,
            password: data.password,
            email: data.email,
            orgId,
            ownerIds: owners.current.map(owner => owner.userId),
          })
          Utils.showSuccess('Added a agent account.', 'Success')
        }
        return SiteUrls.OrgAdmin.Agent.List(orgId)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateAgentPropsInput,
      deleteItem: async () => {
        // if (idEditing) {
        //   await apiRemove({ id: idEditing })
        //   Utils.showSuccess('Removed a category.', 'Success')
        // }
        return SiteUrls.OrgAdmin.Agent.List(orgId)
      },
    }),
    [idEditing, apiCreate, apiGet, apiUpdate, orgId, setOwners]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New Agent' : 'Edit Agent'

  return (
    <Provider title={title}>
      {!idEditing && (
        <ODEntityInput name="username" label="ID" placeholder="Login ID for this account." inputType="text" />
      )}
      <ODEntityInput
        name="name"
        label="Name"
        placeholder="Human-friendly name for this agent account."
        inputType="text"
      />
      <ODEntityInput name="email" label="Email" placeholder="Email for this agent account." inputType="text" />
      <ODEntityInput
        name="password"
        label="Password"
        placeholder="Password for this agent account."
        inputType="password"
      />
      <AgentUserPicker
        orgId={orgId}
        owners={owners.current}
        setOwners={setOwners}
        isPickerOpen={isUserPickerOpen}
        setIsPickerOpen={setIsUserPickerOpen}
      />
      <hr />
      <ODEntityEditorFooter saveButtonName="Save" deleteButtonName={undefined} context={Context} />
    </Provider>
  )
}
