import { ORG_USER_PRIV, USER_PRIV } from '@cils/common'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import buttonAdminEmail from '../assets/img/wait-approval/bt-admin-email.png'
import lineLink from '../assets/img/wait-approval/line-link.png'
import waitBG from '../assets/img/wait-approval/waiting-bg.png'
import { useODAppContext } from '../context/ODAppContext'
import { useAPIs } from '../context/useAPIs'
import { ODColors } from '../global-styles'
import { SiteUrls } from '../urls'
import { Utils } from '../utils'
import { BlockingLoadBox } from './BlockingLoadBox'

const fullWidth = 470 + 151 + 179

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.79px;
  text-align: center;
  color: #29b6ca;
`

const Subtitle = styled.div`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #73818f;
`

const LoginInfo = styled.span`
  font-size: 11px;
  letter-spacing: 0.5px;
  color: #73818f;
`

const Logout = styled.div`
  font-size: 11px;
  color: #29b6ca;
`

const SupportEmail = styled.a`
  font-size: 11px;
  letter-spacing: 0.39px;
  color: #73818f;
`

const Params = {
  LeftCircleSize: 470,
}

const OrgDeleteNormalUser = (props: { orgSupportEmail: string }) => {
  const { orgSupportEmail } = props
  return (
    <div style={{ textAlign: 'center', marginBottom: 10 }}>
      Your organization was deleted. <br /> To access CILS again, ask your administrator{' '}
      <a style={{ color: ODColors.Primary }} href={`mailto:${orgSupportEmail}`}>
        {orgSupportEmail}
      </a>
    </div>
  )
}

const RestoreLink = styled.div`
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
`

const OrgDeleteSystemAdmin = (props: { onRestore: () => void }) => {
  const { onRestore } = props
  return (
    <div style={{ textAlign: 'center', marginBottom: 10 }}>
      <RestoreLink onClick={onRestore}>Restore your organization</RestoreLink>
    </div>
  )
}

export const WaitForAcceptRegistrationPage: React.FC = () => {
  const {
    state: { userProfile },
    refreshProfile,
  } = useODAppContext()
  const { restoreOrg } = useAPIs()

  React.useEffect(() => {
    const handler = setInterval(() => {
      // noinspection JSIgnoredPromiseFromCall
      refreshProfile()
    }, 10000)
    return () => clearInterval(handler)
  }, [refreshProfile])

  const [redirect, setRedirect] = React.useState('')

  React.useEffect(() => {
    if (!userProfile) {
      return
    }
    const mainOrg = userProfile.organizations?.find(v => v.org.orgId === userProfile.mainOrgId)
    const isMainOrgApproved = (mainOrg?.orgUserPriv ?? ORG_USER_PRIV.Rejected) >= ORG_USER_PRIV.Guest
    const isMainOrgDeleting = mainOrg?.org?.deletedAt || mainOrg?.org?.willDeleteAt

    if ((userProfile?.priv || 0) >= USER_PRIV.Normal && isMainOrgApproved && !isMainOrgDeleting) {
      setRedirect(SiteUrls.User.Root)
    }
  }, [userProfile])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!userProfile) {
    return <BlockingLoadBox show />
  }

  const mainOrg = userProfile.organizations?.find(v => v.org.orgId === userProfile.mainOrgId)
  const isMainOrgRejected = (mainOrg?.orgUserPriv ?? -1) === ORG_USER_PRIV.Rejected
  const isMainOrgDeleting = mainOrg?.org?.deletedAt || mainOrg?.org?.willDeleteAt
  const isSystemAdminOfMainOrg = mainOrg?.orgUserPriv === ORG_USER_PRIV.SystemAdmin
  const isRejected = userProfile?.priv === USER_PRIV.Rejected || isMainOrgRejected
  const orgName = userProfile?.mainOrg?.name ?? '-'
  const orgSupportEmail = userProfile?.mainOrg?.supportEmail ?? '-'

  const handleRestore = async () => {
    try {
      const orgId = mainOrg!.org.orgId
      await restoreOrg({ id: orgId })
      await refreshProfile()
      Utils.showSuccess('Restored your organization.', 'Success')
      setRedirect(SiteUrls.User.Root)
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  const TitleContent = isMainOrgDeleting
    ? 'Your organization was deleted'
    : isRejected
    ? 'Your sign up has been denied'
    : 'Waiting for Approval'
  const SubtitleContent = isMainOrgDeleting ? (
    isSystemAdminOfMainOrg ? (
      <OrgDeleteSystemAdmin onRestore={handleRestore} />
    ) : (
      <OrgDeleteNormalUser orgSupportEmail={orgSupportEmail} />
    )
  ) : isRejected ? (
    <div style={{ textAlign: 'center', marginBottom: 10 }}>
      Send email{' '}
      <a style={{ color: ODColors.Primary }} href={`mailto:${orgSupportEmail}`}>
        {orgSupportEmail}
      </a>
      <br />
      if you have any questions
    </div>
  ) : (
    `You can access CILS after ${orgName} administrator's approval.`
  )

  return (
    <div className="app flex-row align-items-center" style={{ placeContent: 'center', backgroundColor: '#dde4e5' }}>
      <div
        style={{
          width: fullWidth,
          height: Params.LeftCircleSize,
        }}
      >
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', left: 240, top: 115 }}>
            <img alt="design line" src={lineLink} width={446} height={282} />
          </div>
          <div style={{ position: 'absolute', left: 0, top: 0 }}>
            <img alt="waiting background" src={waitBG} width={Params.LeftCircleSize} height={Params.LeftCircleSize} />
          </div>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              placeContent: 'center',
              zIndex: 3,
              width: Params.LeftCircleSize,
              height: Params.LeftCircleSize,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Title style={{ marginBottom: 5 }}>{TitleContent}</Title>
              <Subtitle>{SubtitleContent}</Subtitle>
              <LoginInfo>
                You are logged in as <code style={{ color: '#73818f' }}>{userProfile?.email}</code>
              </LoginInfo>
              <div style={{ position: 'absolute', bottom: 92 }}>
                <Link to={SiteUrls.Logout}>
                  <Logout>Logout</Logout>
                </Link>
              </div>
            </div>
          </div>
          <div style={{ position: 'absolute', left: 572, top: 145 }}>
            <img alt="email to admin button" src={buttonAdminEmail} width={179} height={179} />
            <SupportEmail style={{ position: 'absolute', left: 50, bottom: 43 }} href={`mailto:${orgSupportEmail}`}>
              Need support?
            </SupportEmail>
          </div>
        </div>
      </div>
    </div>
  )
}
