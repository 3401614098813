export interface IDOMetaDataInput {
  key: string
  columnName: string
  state: MetaDataInputState

  setKey(key: string): void
  setColumnName(name: string): void
}

export enum MetaDataInputState {
  Created = 'Created',
  Editing = 'Editing',
  Edited = 'Edited',
}
