import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from './ODIcon'

type NoItemsFoundBoxProps = {
  style?: object
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #29b6ca;
  flex-direction: column;
`

export const NoItemsFoundBox: React.FC<NoItemsFoundBoxProps> = props => {
  const { style = {} } = props
  return (
    <Wrapper style={style}>
      <ODIcon icon={ODIcons.CoreFrown} />
      <span style={{ fontSize: 16, marginTop: 10 }}>No results found.</span>
    </Wrapper>
  )
}
