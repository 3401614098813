import React from 'react'
import { Progress } from 'reactstrap'
import styled from 'styled-components'

export interface IProgressBarComponentProps {
  value: number
  status: SyncProgressStatus
  text?: string // if non-empty text is given, show this text instead.
}

export enum SyncProgressStatus {
  Downloading,
  Uploading,
  Paused,
  Canceled,
  Completed,
  CompletedToClient,
  CompletedToServer,
}

type ProgressStyleType = {
  color?: string
}

export const CustomProgress = styled(Progress)`
  height: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  & > div {
    background-color: ${(p: ProgressStyleType) => p.color};
  }
`
const StatusText = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: #000000;
`
const Percentage = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: right;
  color: #708393;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

export const ProgressBarComponent: React.FC<IProgressBarComponentProps> = props => {
  const { value, status, text } = props

  const ProgressBar = (status: SyncProgressStatus) => {
    switch (status) {
      case SyncProgressStatus.Downloading:
        return (
          <>
            <Wrapper>
              <StatusText>{text || 'Downloading'}</StatusText>
              <Percentage>{value}%</Percentage>
            </Wrapper>
            <CustomProgress value={value} max={100} color="success" />
          </>
        )
      case SyncProgressStatus.Uploading:
        return (
          <>
            <Wrapper>
              <StatusText>{text || 'Uploading'}</StatusText>
              <Percentage>{value}%</Percentage>
            </Wrapper>
            <CustomProgress value={value} max={100} color="success" />
          </>
        )
      case SyncProgressStatus.Paused:
        return (
          <>
            <Wrapper>
              <StatusText>{text || 'Paused'}</StatusText>
              <Percentage>{value}%</Percentage>
            </Wrapper>
            <CustomProgress value={value} max={100} color="#7f8b98" />
          </>
        )
      case SyncProgressStatus.Canceled:
        return (
          <>
            <Wrapper>
              <StatusText style={{ color: '#f86c6b', fontWeight: 'bold' }}>{text || 'Canceled'}</StatusText>
            </Wrapper>
            <CustomProgress value={value} max={100} color="#f86c6b" />
          </>
        )
      case SyncProgressStatus.Completed:
        return (
          <>
            <Wrapper>
              <StatusText style={{ color: '#29b6ca', fontWeight: 'bold' }}>{text || 'Completed'}</StatusText>
            </Wrapper>
            <CustomProgress value={value} max={100} color="#29b6ca" />
          </>
        )
    }
  }

  return <div>{ProgressBar(status)}</div>
}
