import { CILSAPIServer, ICILSDI } from '@cils/common'
import React from 'react'
import { AppOptions } from '../AppOptions'
import { CILSAPIClient } from './client/CILSAPIClient'

export class ReactCILSDI implements ICILSDI {
  server: CILSAPIServer

  constructor() {
    const client = new CILSAPIClient(
      async () => localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
      token => localStorage.setItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN, token),
      AppOptions.SERVER_ADDRESS
    )
    this.server = new CILSAPIServer(client)
  }
}
