import { CilsStoragePropsInputValidation, populateCilsStoragePropsInput } from '@cils/common'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { GQLCilsStorage, GQLCilsStoragePropsInput, GQLOkResponse, GQLSingleIDInput } from '../../../@types/server'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import { ODEntityLabeled } from '../../../ODEntityEditor/FormComponents/ODEntityLabeled'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type CilsStorageEditContainerProps = {
  idEditing?: number
  orgId: number
}

const Description = styled.div`
  padding: 0 5px 10px;
  color: #73818f;
  font-size: smaller;
`

const CILS_STORAGE_SNAPSHOT = ['storageId', 'name', 'address', 'addressOptional'].join('\n')

const GQL_CREATE = `
mutation createCilsStorage($data: CilsStoragePropsInput!) {
  createCilsStorage(data: $data) {
    ${CILS_STORAGE_SNAPSHOT}
  }
}
`

const GQL_UPDATE = `
mutation updateCilsStorage($data: CilsStoragePropsInput!) {
  updateCilsStorage(data: $data) {
    ${CILS_STORAGE_SNAPSHOT}
  }
}
`

const GQL_GET = `
query getCilsStorage($data: SingleIDInput!) {
  getCilsStorage(data: $data) {
    ${CILS_STORAGE_SNAPSHOT}
  }
}
`

const GQL_REMOVE = `
mutation removeCilsStorage($data: SingleIDInput!) {
  removeCilsStorage(data: $data) {
    ok
  }
}
`

type PropsInput = GQLCilsStoragePropsInput
type Entity = GQLCilsStorage

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: CilsStoragePropsInputValidation.name.required('Name is required.'),
    // TODO!
  })
}

const FAKE_SECRET = '__THIS_IS__FAKE__'

export const CilsStorageEditContainer: React.FC<CilsStorageEditContainerProps> = props => {
  const { idEditing, orgId } = props
  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_CREATE)
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_UPDATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          return {
            id: null,
            name: '',
            address: '',
            addressOptional: '',
            orgId,
            secret: '',
          }
        }
        return {
          id: data.storageId,
          name: data.name,
          address: data.address,
          addressOptional: data.addressOptional,
          orgId: data.orgId,
          secret: FAKE_SECRET,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (idEditing) {
          await apiUpdate({ id: idEditing, ...data })
          Utils.showSuccess('Updated a storage', 'Success')
        } else {
          await apiCreate({
            name: data.name,
            address: data.address,
            addressOptional: data.addressOptional,
            secret: data.secret,
            orgId,
          })
          Utils.showSuccess('Added a new storage', 'Success')
        }
        return SiteUrls.OrgAdmin.Storage.List(orgId)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateCilsStoragePropsInput,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('Removed a storage.', 'Success')
        }
        return SiteUrls.OrgAdmin.Storage.List(orgId)
      },
    }),
    [orgId, idEditing, apiCreate, apiGet, apiUpdate, apiRemove]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New Storage' : 'Edit Storage'

  return (
    <Provider title={title}>
      <ODEntityInput name="name" label="Name of the CILS Storage" placeholder="Enter name" inputType="text" />
      <ODEntityInput
        name="address"
        label="Main address"
        placeholder="Main address for the storage. Mostly internal address."
        inputType="text"
      />
      <ODEntityInput
        name="addressOptional"
        label="Optional address"
        placeholder="Optional (Mostly external) addresses for the storage. Can enter multiple address using comma."
        inputType="text"
      />
      <ODEntityLabeled name="address_desc" label="">
        <Description>
          All agent will try Main address first. If agent fails to connect with main NAS address, it will try one of the
          optional addresses. (will be picked randomly if multiple addresses are given.) Normally, You can enter
          internal network IP for the main address, and external IP for the optional address.
        </Description>
      </ODEntityLabeled>
      <ODEntityInput
        name="secret"
        label="Secret Token"
        placeholder="Secret token which must match with NAS server."
        inputType="password"
      />
      <ODEntityLabeled name="secret_desc" label="">
        <Description>
          Secret token is used to communicate NAS server with Main API server. If it doesn't match NAS will not operate
          correctly. Please be sure to enter the correct value given to your organization.
        </Description>
      </ODEntityLabeled>
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        // deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
      />
    </Provider>
  )
}
