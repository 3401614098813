import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from './ODIcon'

const NoMoreLine = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 2px;
  border: solid 1px #c8ced3;
`
export const NoMoreComponent = () => {
  return (
    <div style={{ margin: 30, display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
      <NoMoreLine />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 22, marginRight: 22 }}>
        <ODIcon icon={ODIcons.MaterialReport} style={{ color: '#708393', fontSize: 20, marginRight: 9 }} />
        <span style={{ fontSize: 15, color: '#708190' }}>No more items</span>
      </div>
      <NoMoreLine />
    </div>
  )
}
