import { IAPIClient, IFileDefinition } from '@odc/odbelt'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import { isArray } from 'lodash'
import { createApolloClient } from '../../apolloClientUtils'

export class CILSAPIClient implements IAPIClient {
  private client: ApolloClient<any>
  private readonly serverAddress: string

  constructor(getAuthToken: () => Promise<string | null>, setAuthToken: (token: string) => any, serverAddress: string) {
    const { client } = createApolloClient(getAuthToken, setAuthToken, serverAddress)
    this.client = client
    this.serverAddress = serverAddress
  }

  async mutate<INPUT_TYPE extends object, OUTPUT_TYPE extends object>(
    query: string,
    keyOfFiles: Array<IFileDefinition<INPUT_TYPE>>,
    data: INPUT_TYPE,
    operationName: string = 'unnamed_mutation'
  ): Promise<{ data: OUTPUT_TYPE }> {
    const modifiedData = {
      ...data,
    }

    for (const { fieldName: key, mimeType } of keyOfFiles) {
      if (modifiedData.hasOwnProperty(key)) {
        const fieldData = modifiedData[key]
        if (isArray(fieldData)) {
          // @ts-ignore
          modifiedData[key] = await Promise.all(fieldData.map((f, index) => f))
        } else {
          // TODO:
          modifiedData[key] = fieldData
        }
      }
    }

    const r = await this.client.mutate<OUTPUT_TYPE, { data: INPUT_TYPE }>({
      context: {
        uri: `${this.serverAddress}/graphql/${operationName}`,
      },
      mutation: gql(query),
      variables: { data: modifiedData },
      errorPolicy: 'none',
    })
    return { data: r.data! }
  }

  async query<INPUT_TYPE extends object, OUTPUT_TYPE extends object>(
    query: string,
    data: INPUT_TYPE,
    operationName: string = 'unnamed_query'
  ): Promise<{ data: OUTPUT_TYPE }> {
    const r = await this.client.query<OUTPUT_TYPE, { data: INPUT_TYPE }>({
      context: {
        uri: `${this.serverAddress}/graphql/${operationName}`,
      },
      query: gql(query),
      variables: { data },
      errorPolicy: 'none',
    })
    return { data: r.data! }
  }
}
