import { ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { ODModal, ODModalSize } from '../../../components/ODModal/ODModal'
import { ODModalBody } from '../../../components/ODModal/ODModalBody'
import { ODModalHeader } from '../../../components/ODModal/ODModalHeader'
import { ODColors } from '../../../global-styles'
import { AgentDownloadProgressFilteredList } from '../Sync/AgentDownloadProgressFilteredList'

interface IWaitingDownloadForAnalysisModalProps {
  dataId: string
  itemId: number
  onClose: () => void
}

const DescText = styled.div`
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: #8f9ba6;
`

const WillNotifyText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #5c6873;
  text-align: center;
`

const AlarmIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  background-color: #dff4f7;
  border-radius: 9999px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  color: ${ODColors.Primary};
`

export const WaitingDownloadForAnalysisModal: React.FC<IWaitingDownloadForAnalysisModalProps> = props => {
  const { itemId, dataId, onClose } = props
  const [requiresSelection, setRequiresSelection] = React.useState(false)
  const [openAnalysisCalled, setOpenAnalysisCalled] = React.useState(false)

  return (
    <ODModal isOpen toggle={() => null} size={ODModalSize.Big} style={{ top: `calc((100% - 540px)/2 - 120px)` }}>
      <FlexContentsContainer>
        <div style={{ height: 540, display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title="Waiting for download to complete" onClose={onClose} headerBottomPadding={30} />
          <ODModalBody style={{ flexGrow: 99, backgroundColor: 'red', padding: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div style={{ backgroundColor: 'white', flexGrow: 99 }}>
                {!requiresSelection && (
                  <DescText>
                    After download is completed, TomoAnalysis will open this file.
                    <br />
                    Please wait until the download is complete.
                    <br />
                    You can change download Priority.
                  </DescText>
                )}
                {requiresSelection && (
                  <DescText>There are more than one TCF files in your disk. Select a file to open.</DescText>
                )}
                <div
                  style={{
                    height: '100%',
                    overflow: 'auto',
                    width: '100%',
                    padding: 30,
                  }}
                >
                  <AgentDownloadProgressFilteredList
                    itemId={itemId}
                    dataId={dataId}
                    onClose={onClose}
                    setRequiresSelection={setRequiresSelection}
                    setTomoAnalysisQueued={() => setOpenAnalysisCalled(true)}
                  />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  height: 129,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {openAnalysisCalled && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <AlarmIconWrapper>
                      <ODIcon icon={ODIcons.MaterialNotificationsActive} />
                    </AlarmIconWrapper>
                    <ODVSpace h={14} />
                    <WillNotifyText>
                      TomoAnalysis will notify you once this file is available.
                      <br /> You can close this dialog.
                    </WillNotifyText>
                    <ODVSpace h={50} />
                  </div>
                )}
              </div>
            </div>
          </ODModalBody>
        </div>
      </FlexContentsContainer>
    </ODModal>
  )
}
