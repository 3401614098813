import { ORG_ROLE } from '@cils/common'
import { AppBreadcrumb, AppSidebar, AppSidebarFooter, AppSidebarMinimizer } from '@coreui/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { GQLSingleIDInput } from '../../@types/server'
import { GQLOkResponse } from '../../agent'
import { ODAppSidebarNav } from '../../components/coreuiBase/ODAppSidebarNav'
import { AgentStatusBar } from '../../components/Sync/AgentStatusBar'
import { getTagDetailId, TagSettingDropdown } from '../../components/TagSettingDropdown'
import { WorksetSettingDropdown } from '../../components/WorksetSettingDropdown'
import { useODAppContext } from '../../context/ODAppContext'
import { useODMutation } from '../../context/ODCommon'
import { useOrgPerm } from '../../context/useOrgPerm'
import { useCILSStore } from '../../di/configureRootStore'
import { ODColors } from '../../global-styles'
import { UserSiteNavsOfOrg } from '../../routes/UserNavs'
import { UserRoutesOfOrg } from '../../routes/UserRoutes'
import { SiteUrls } from '../../urls'

type UserOrgMainLayoutProps = {
  orgId: number
}

const GQL_VISIT_ORG_WORKSPACE_DAILY = `
mutation visitOrgWorkspaceDaily($data: SingleIDInput!) {
  visitOrgWorkspaceDaily(data: $data) {
    ok
  }
}
`

export const CustomBreadcrumb = styled(AppBreadcrumb)`
  & .breadcrumb {
    border-bottom: 0;
  }
`

export const CurrentItemName = styled.span`
  color: #73818f;
  padding-left: 0;
  margin-left: -10px;
  z-index: 1;
`

export const BreadcrumbBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #c8ced3;
`

export const ProjectName = styled.span`
  padding-left: 0;
  margin-left: -10px;
  z-index: 1;
  font-size: 13px;
  color: ${ODColors.Black};
`

export const ProjectNameWidthDataName = styled.span`
  padding-left: 0;
  margin-left: -10px;
  z-index: 1;
  font-size: 13px;
  color: ${ODColors.WaterBlue};
`

export const ProjectDataName = styled.span`
  font-size: 13px;
  color: ${ODColors.Black};
`

export const UserOrgMainLayout: React.FC<UserOrgMainLayoutProps> = observer(({ orgId, ...props }) => {
  const { currentWorksetName, currentTagName, currentProjectName, currentProjectDataName } = useODAppContext()

  const perm = useOrgPerm(orgId)
  const isOrgAdmin = !!perm?.isAllowed(ORG_ROLE.ACCESS_ORG_ADMIN_CONSOLE)
  const canViewWorksets = perm?.isAllowed(ORG_ROLE.VIEW_ORG_WORKSET)
  const canViewProjects = perm?.isAllowed(ORG_ROLE.VIEW_ORG_PROJECT) || perm?.isAllowed(ORG_ROLE.EDIT_ORG_PROJECT)
  const navs = UserSiteNavsOfOrg(
    orgId.toString(),
    isOrgAdmin,
    canViewWorksets ?? false,
    perm?.isAllowed(ORG_ROLE.VIEW_ORG_CATEGORY) ?? false,
    perm?.isAllowed(ORG_ROLE.VIEW_ORG_TAG) ?? false,
    perm?.isAllowed(ORG_ROLE.VIEW_ORG_DATA) ?? false,
    canViewProjects ?? false
  )
  const routes = UserRoutesOfOrg(':orgId', isOrgAdmin)
  const defaultRedirect = SiteUrls.User.Org.Workset.Main(orgId) // navs.items[0].url
  const location = useLocation()

  const apiVisit = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_VISIT_ORG_WORKSPACE_DAILY)
  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    apiVisit({ id: orgId })
  }, [orgId, apiVisit])

  // 스토어에서 orgId 를 사용하기 위해서 해당 컴포넌트에서 orgId 를 set 한다.
  const store = useCILSStore()
  React.useEffect(() => {
    store.setOrgId(orgId)
  }, [store, orgId])

  const hasManageProject = perm?.isAllowed(ORG_ROLE.EDIT_ORG_PROJECT) ?? false
  const hasManageWorkset = perm?.isAllowed(ORG_ROLE.EDIT_ORG_WORKSET) ?? false
  React.useEffect(() => {
    store.setManageProject(hasManageProject)
    store.setManageWorkset(hasManageWorkset)
  }, [hasManageProject, hasManageWorkset])

  const tagId = getTagDetailId(location.pathname)
  const isDirectoryView = location.pathname.match('/directory')
  const shouldShowWorksetOptions = !!location.pathname.match('/workset/.+')
  const isAgent = location.pathname.startsWith('/organization') && location.pathname.endsWith('sync')

  return (
    <div className="app-body">
      {isDirectoryView ? null : (
        <AppSidebar fixed display="lg">
          <ODAppSidebarNav navConfig={navs} {...props} />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
      )}
      {/*<SyncComponent {...syncComponentProps} />*/}
      <main
        className="main"
        style={{ display: 'flex', flexDirection: 'column', ...(isDirectoryView ? { marginLeft: 0 } : {}) }}
      >
        <BreadcrumbBox>
          {!isDirectoryView && !isAgent && <CustomBreadcrumb appRoutes={[...routes]} />}
          {isAgent && <AgentStatusBar />}
          {currentWorksetName && <CurrentItemName>/ {currentWorksetName}</CurrentItemName>}
          {currentTagName && <CurrentItemName>/ {currentTagName}</CurrentItemName>}
          {shouldShowWorksetOptions && <WorksetSettingDropdown />}
          {tagId && <TagSettingDropdown tagId={tagId} />}
          {!currentProjectDataName && currentProjectName && <ProjectName>/ {currentProjectName}</ProjectName>}
          {currentProjectName && currentProjectDataName && (
            <>
              <ProjectNameWidthDataName style={{ marginRight: 3 }}>/ {currentProjectName} </ProjectNameWidthDataName>
              <ProjectDataName> / {currentProjectDataName}</ProjectDataName>
            </>
          )}
        </BreadcrumbBox>
        <div
          style={{
            display: 'flex',
            flexGrow: 3,
            marginTop: 0,
            backgroundColor: ODColors.ContentAreaBackground,
            overflowX: 'scroll',
          }}
        >
          <Switch>
            {routes.map((route, idx) => {
              return route.component || route.render ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  // @ts-ignore
                  name={route.name}
                  render={props =>
                    route.component ? <route.component message={route.name} {...props} /> : route.render!(props)
                  }
                />
              ) : null
            })}
            <Redirect to={defaultRedirect} />
          </Switch>
        </div>
      </main>
    </div>
  )
})
