import classNames from 'classnames'
import * as React from 'react'
import { Modal } from 'reactstrap'
import { GQLItem } from '../../@types/server'
import { GQLSingleIDInput } from '../../agent'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { GQL_ITEM_SNAPSHOT } from '../../context/ODAppContext'
import { useODQuery } from '../../context/ODCommon'
import { ODListableContextType } from '../../ODListable/ODListableContext'
import { TCFDetailWrapper } from './TCFDetailWrapper'

type TCFDetailModalProps = {
  isOpen: boolean
  fade?: boolean
  toggle: () => void
  itemId: number
  onTCFItemUpdated: (updated: GQLItem) => void
  onTCFItemChanged: (updated: GQLItem) => void
  itemList?: Array<GQLItem>
  onLoadMore: () => void
  lastTCFUpdatedTime: number // TCF 변경시에 강제로 내부에서 reload 를 하도록 하기 위한 토큰이다.
  inWorksetId?: number
}

type TCFDetailModalInListableContextProps = Omit<TCFDetailModalProps, 'onLoadMore' | 'itemList'> & {
  listableContext: React.Context<ODListableContextType<GQLItem, any>>
  lastTCFUpdatedTime: number // TCF 변경시에 강제로 내부에서 reload 를 하도록 하기 위한 토큰이다.
  inWorksetId?: number
}

export const GQL_GET_ITEM = `
query getItem($data: SingleIDInput!) {
  getItem(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}`

export const TCFDetailModalInListableContext: React.FC<TCFDetailModalInListableContextProps> = p => {
  const { children, listableContext, onTCFItemUpdated, ...props } = p

  const {
    state: { list },
    updateItem,
    loadMore,
  }: ODListableContextType<GQLItem, any> = React.useContext(listableContext)

  const handleItemUpdate = async (item: GQLItem) => {
    onTCFItemUpdated(item)
    updateItem(item)
  }

  return <TCFDetailModal {...props} onTCFItemUpdated={handleItemUpdate} onLoadMore={loadMore} itemList={list} />
}

export const TCFDetailModal: React.FC<TCFDetailModalProps> = ({ children, ...props }) => {
  const {
    fade = false,
    isOpen,
    toggle,
    itemId,
    itemList,
    onTCFItemUpdated,
    onTCFItemChanged,
    onLoadMore,
    lastTCFUpdatedTime,
    inWorksetId,
  } = props
  const sizeCls = { 'modal-tcf': true }

  const [loadedOnLastElement, setLoadedOnLastElement] = React.useState(false)
  const [tcfItem, setTCFItem] = React.useState<GQLItem | null>(null)
  const apiGetTCFItem = useODQuery<GQLSingleIDInput, GQLItem>(GQL_GET_ITEM)

  const loadTCFItem = React.useCallback(
    async (itemId: number) => {
      if (lastTCFUpdatedTime === 0) {
        setTCFItem(null)
      }
      const r = await apiGetTCFItem({ id: itemId })
      setTCFItem(r)
    },
    [setTCFItem, apiGetTCFItem, lastTCFUpdatedTime]
  )

  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    loadTCFItem(itemId)
  }, [itemId, loadTCFItem])

  const itemIndex = itemList?.findIndex(v => v.itemId === tcfItem?.itemId) || 0
  const hasPrev = itemIndex > 0
  const isLastElement = itemList ? itemList.length - 1 === itemIndex : true

  // 더 로드할 수 있는 상황에서 이미지를 next > next 했을 때 문제를 최소화하기 위하여, 마지막 아이템을 조회하고 있을 때 추가 로드를 하도록 한다.
  // 로딩 속도에 따라 문제가 여전히 발생할 수는 있지만, 적당한 수준에서 동작하는 것으로 보인다.
  React.useEffect(() => {
    if (!loadedOnLastElement && isLastElement) {
      onLoadMore()
      setLoadedOnLastElement(true)
    } else if (!isLastElement) {
      setLoadedOnLastElement(false)
    }
  }, [onLoadMore, isLastElement, loadedOnLastElement])

  const handleNext = async () => {
    if (!isLastElement && itemList) {
      onTCFItemChanged(itemList[itemIndex + 1])
    }
  }

  const handlePrev = async () => {
    if (itemIndex > 0 && itemList) {
      onTCFItemChanged(itemList[itemIndex - 1])
    }
  }

  return (
    <Modal fade={fade} isOpen={isOpen} toggle={toggle} className={classNames('modal-primary', 'modal-rect', sizeCls)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <BlockingLoadBox show={!tcfItem} />
        {tcfItem && (
          <TCFDetailWrapper
            tcfItem={tcfItem}
            onClose={toggle}
            onTCFItemUpdated={onTCFItemUpdated}
            hasNext={!!itemList}
            hasPrev={hasPrev}
            onClickNext={handleNext}
            onClickPrev={handlePrev}
            inWorksetId={inWorksetId}
          />
        )}
      </div>
    </Modal>
  )
}
