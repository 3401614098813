import moment from 'moment'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { GQLStatDaily, GQLStatDateRange } from '../../@types/server'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { ODDashboardCardWithGraph } from '../../components/ODDashboardCard/ODDashboardCardWithGraph'
import { ODChartData, ODChartDesignType } from '../../components/ODDashboardCard/ODDashboardChartOptions'
import { ODDashboardCardTheme } from '../../components/ODDashboardCard/ODDashboardCommon'
import { ODIcon, ODIcons } from '../../components/ODIcon'
import { useODAppContext } from '../../context/ODAppContext'
import { useODQuery2 } from '../../context/ODCommon'
import { STAT_ORG_DAILY_FULL_SNAPSHOT } from '../../gqls'

type OrgAdminDashboardProps = {
  orgId: number | string
}

const GQL_DASHBOARD = `
query getOrgStatDailyInRange($start: String!, $end: String!, $orgId: Int!) {
  getOrgStatDailyInRange(data: {id: $orgId, start: $start, end: $end}) {
    ${STAT_ORG_DAILY_FULL_SNAPSHOT}
  }
  getOrg(data: { id: $orgId }) {
    numUser
    numTCFFiles
    numWorksets
    numAgents
    numTags
    storageUsage
    storageTotal
  }
}
`

type DashboardResponse = {
  getOrgStatDailyInRange: Array<GQLStatDaily>
  getOrg: {
    numUser: number
    numTCFFiles: number
    numAgents: number
    numWorksets: number
    numTags: number
    storageUsage: string
    storageTotal: string
  }
}

const createDataset = (
  response: DashboardResponse,
  key: keyof GQLStatDaily,
  label: string,
  backgroundColor: string,
  borderColor: string
): ODChartData => {
  return {
    labels: response.getOrgStatDailyInRange.map(v => v.dateToken),
    datasets: [
      {
        label,
        backgroundColor,
        borderColor,
        data: response.getOrgStatDailyInRange.map(v => v[key] as number),
      },
    ],
  }
}

export const OrgAdminDashboardContainer: React.FC<OrgAdminDashboardProps> = ({ orgId }) => {
  const { appOptions } = useODAppContext()

  const makeVariables = React.useCallback(() => {
    const now = moment()
    const end = now.format('YYYY-MM-DD')
    const start = now.add(-6, 'day').format('YYYY-MM-DD')
    return { orgId, start, end }
  }, [orgId])

  // 새로 조합한 GQL 의 input type 을 만들어야 하는데 any 로 그냥 넘긴다.
  const [variables, setVariables] = React.useState<any>(makeVariables())

  React.useEffect(() => {
    setVariables(makeVariables())
  }, [orgId, makeVariables])

  const { data, loading } = useODQuery2<GQLStatDateRange, DashboardResponse>(GQL_DASHBOARD, {
    simulateDelay: appOptions?.SIMULATE_DELAY,
    variables,
    wrapVariablesWithData: false,
  })

  if (!data) {
    return <BlockingLoadBox show={loading} />
  }

  const storageUsageText = (() => {
    const orgData = data?.getOrg
    if (!orgData) {
      return '-'
    }
    return `${prettyBytes(parseInt(orgData.storageUsage, 10))} / ${prettyBytes(parseInt(orgData.storageTotal, 10))}`
  })()

  return (
    <Container fluid>
      <BlockingLoadBox show={loading} />
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.Doctor} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Researchers"
              value={data?.getOrg.numUser.toLocaleString() || '-'}
              chartData={createDataset(data, 'uniqueVisit', 'Unique visits', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreBuilding} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Agents"
              value={data?.getOrg?.numAgents.toString() || '-'}
              chartData={createDataset(data, 'agent', 'New Agent', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreStorage} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="NAS Storage"
              value={storageUsageText}
              chartData={{
                labels: ['-'],
                datasets: [
                  {
                    label: '-',
                    backgroundColor: '#20A8D8',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: [],
                  },
                ],
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreViewModule} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Worksets"
              value={data?.getOrg?.numWorksets.toString() || '-'}
              chartData={createDataset(data, 'workset', 'New Workset', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreList} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All TCF Files"
              value={data?.getOrg.numTCFFiles.toLocaleString() || '-'}
              chartData={createDataset(data, 'tcfUpload', 'New TCF Uploads', '#20A8D8', 'rgba(255,255,255,.55)')}
            />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <ODDashboardCardWithGraph
              theme={ODDashboardCardTheme.Blue}
              chartType={ODChartDesignType.PointLine}
              icon={<ODIcon icon={ODIcons.CoreTags} style={{ fontSize: 24, opacity: 1.0 }} />}
              title="All Tags"
              value={data?.getOrg?.numTags.toString() || '-'}
              chartData={{
                labels: ['-'],
                datasets: [
                  {
                    label: '-',
                    backgroundColor: '#20A8D8',
                    borderColor: 'rgba(255,255,255,.55)',
                    data: [],
                  },
                ],
              }}
            />
          </Col>
        </Row>
      </div>
    </Container>
  )
}
