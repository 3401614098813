import React from 'react'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import styled from 'styled-components'

type ODTooltipProps = {
  header: string
  body: string
  isOpen: boolean
  toggle: () => void
  targetElementId: string | HTMLElement
}

const CustomPopover = styled(Popover)`
  & > div {
    max-width: 232px;
  }
`
const CustomPopoverHeader = styled(PopoverHeader)`
  font-size: 12px;
  color: #2f353a;
`
const CustomPopoverBody = styled(PopoverBody)`
  font-size: 13px;
  color: #9b9b9b;
`

export const ODTooltip: React.FC<ODTooltipProps> = ({ header, body, isOpen, toggle, targetElementId }) => {
  return (
    <CustomPopover placement="bottom-start" isOpen={isOpen} target={targetElementId} toggle={toggle}>
      <CustomPopoverHeader>{header}</CustomPopoverHeader>
      <CustomPopoverBody>{body}</CustomPopoverBody>
    </CustomPopover>
  )
}
