import { useODQuery } from '@odc/od-react-belt'
import React from 'react'
import { GQLWorkset } from '../../@types/server'
import { GQLSingleIDInput } from '../../agent'
import { OdModalWorksetGroupBody } from '../../containers/User/WorksetGroup/ODModalWorksetGroupBody'
import { SingleQueryWrapper } from '../../context/useSingleQueryAPI'
import { ODModal } from './ODModal'

const GQL_GET_WORKSET = `
query getWorkset($data: SingleIDInput!) {
  getWorkset(data: $data) {
    wsId
    name
    myPerm
    isFavorite
    isNew
    worksetGroups {
      wsgId
      name
    }
  }
}
`

interface IOdModalWorksetGroup {
  onClose: () => void
  onSave: (wsgIds: number[]) => Promise<void>
  title: string
  worksetId: number
}

export const ODModalWorksetGroup: React.FC<IOdModalWorksetGroup> = props => {
  const { onClose, onSave, title, worksetId } = props
  const getWorkset = useODQuery<GQLSingleIDInput, GQLWorkset>(GQL_GET_WORKSET)

  return (
    <ODModal isOpen toggle={() => null} style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)' }}>
      <SingleQueryWrapper deps={[worksetId]} getter={() => getWorkset({ id: worksetId })}>
        {({ data }) => {
          return <OdModalWorksetGroupBody onClose={onClose} onSave={onSave} title={title} data={data} />
        }}
      </SingleQueryWrapper>
    </ODModal>
  )
}
