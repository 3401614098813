import { padEnd } from 'lodash'
import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { ODButton, ODButtonSize, ODButtonTheme } from '../../components/ODButton'
import { ODIcon, ODIcons } from '../../components/ODIcon'

type WrapperProps = {
  noBorder?: boolean
}

type ODEntityColorPickerProps = {
  colors: Array<string>
  initialValue?: string
  noBorder?: boolean
  onChange: (color: string) => void
  onSubmitInvalidColor: () => void
}

const paddingLeft = '23px'
const paddingRight = '23px'
const paddingTop = '14px'
const paddingBottom = '14px'

const Wrapper = styled.div`
  width: 350px;
  border-radius: 3px;
  border: solid ${(p: WrapperProps) => (p.noBorder ? 0 : 1)}px #c8ced3;
  background-color: #ffffff;
`

type ColorBallProps = {
  code: string
  selected: boolean
}

export const ColorBall = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 2px solid ${(p: ColorBallProps) => (p.selected ? '#20a8d8' : 'transparent')};
  background-color: ${(p: ColorBallProps) => p.code};
  display: flex;
  place-content: center;
  color: white;
`

const ColorBallWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${paddingLeft};
  padding-right: ${paddingRight};
  padding-top: ${paddingTop};
  padding-bottom: ${paddingBottom};
`

const ColorCustomInputWrapper = styled.div`
  width: 100%;
  padding-left: ${paddingLeft};
  padding-right: ${paddingLeft};
  padding-top: ${paddingTop};
  padding-bottom: ${paddingBottom};
  border-top: solid 1px #c8ced3;
  border-bottom: solid 1px #c8ced3;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ColorCustomLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2f353a;
`

const HexSpan = styled.span`
  position: absolute;
  top: 8px;
  left: 14px;
  font-size: 14px;
`

const ConfirmWrapper = styled.div`
  padding-left: ${paddingLeft};
  padding-right: ${paddingLeft};
  padding-top: ${paddingTop};
  padding-bottom: ${paddingBottom};
`

const maskForChar = /[\da-fA-F]/
const sharpRemoved = (s: string) => (s.startsWith('#') ? s.substr(1) : s)

export const ODEntityColorPicker: React.FC<ODEntityColorPickerProps> = props => {
  const { colors, initialValue = '', onChange, onSubmitInvalidColor, noBorder } = props
  const [color, setColor] = React.useState(initialValue)

  React.useEffect(() => {
    setColor(initialValue)
  }, [initialValue])

  const handleCustomInput = (e: any) => {
    const text = e.target.value as string
    const final = text
      .split('')
      .filter(v => v.match(maskForChar))
      .join('')
      .toLowerCase()
      .substr(0, 8)
    setColor(final)
  }

  const handleSubmit = () => {
    if (color.length < 8 && color.length >= 6) {
      onChange(padEnd(color, 8, 'f')) // add alpha
    } else if (color.length === 8) {
      onChange(color)
    } else {
      onSubmitInvalidColor()
    }
  }

  const hexMarkColor = color.length >= 6 ? color : '#73818f'

  return (
    <Wrapper noBorder={noBorder}>
      <ColorBallWrapper>
        {colors.map(code => {
          const selected = color === sharpRemoved(code)
          return (
            <ColorBall
              key={code}
              code={code}
              selected={selected}
              onClick={() => setColor(sharpRemoved(code))}
              style={{ cursor: 'pointer' }}
            >
              {selected && <ODIcon icon={ODIcons.CoreCheckAlt} style={{ marginTop: 3 }} />}
            </ColorBall>
          )
        })}
      </ColorBallWrapper>
      <ColorCustomInputWrapper>
        <ColorCustomLabel>Custom</ColorCustomLabel>
        <div style={{ position: 'relative' }}>
          <Input type="text" style={{ paddingLeft: 30 }} value={color} onChange={handleCustomInput} />
          <HexSpan style={{ color: `#${hexMarkColor}` }}>#</HexSpan>
        </div>
      </ColorCustomInputWrapper>
      <ConfirmWrapper>
        <ODButton
          fullWidth
          theme={ODButtonTheme.Primary}
          size={ODButtonSize.Small}
          onClick={handleSubmit}
          style={{ height: 35 }}
        >
          Confirm
        </ODButton>
      </ConfirmWrapper>
    </Wrapper>
  )
}
