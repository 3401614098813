import CloseIcon from '@material-ui/icons/Close'
import * as React from 'react'
import styled from 'styled-components'
import { ODColors } from '../../global-styles'

type ODModalHeaderProps = {
  headerTopPadding?: number
  headerBottomPadding?: number
  closeButtonColor?: string
  closeButtonPadding?: number
  title?: string
  titleColor?: string
  onClose?: () => void
}

const defaultODModalHeaderProps: Partial<ODModalHeaderProps> = {
  headerTopPadding: 44,
  headerBottomPadding: 44,
  closeButtonPadding: 25,
  closeButtonColor: '#bdbdbd',
}

const HeaderContainer = styled.div`
  padding-top: ${(p: ODModalHeaderProps) => p.headerTopPadding}px;
  padding-bottom: ${(p: ODModalHeaderProps) => p.headerBottomPadding}px;
  width: 100%;
`

const CloseButtonWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  padding: ${(p: ODModalHeaderProps) => p.closeButtonPadding}px;
  color: ${(p: ODModalHeaderProps) => p.closeButtonColor};
  cursor: pointer;
`

const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  color: ${(p: ODModalHeaderProps) => p.titleColor || ODColors.Primary};
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
`

export const ODModalHeader: React.FC<ODModalHeaderProps> = ({ children, ...props }) => {
  const p = { ...defaultODModalHeaderProps, ...props }
  const { title, onClose } = p
  return (
    <HeaderContainer {...p}>
      <HeaderTitle {...p}>{title || children}</HeaderTitle>
      {onClose && (
        <CloseButtonWrapper onClick={onClose} {...p}>
          <CloseIcon />
        </CloseButtonWrapper>
      )}
    </HeaderContainer>
  )
}
