import { ORG_ROLE, WORKSET_USER_PERM } from '@cils/common'
import { useWrappedAPI } from '@odc/od-react-belt'
import React from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { WORKSET_DETAIL_STATE, worksetDetailState } from '../context/atoms'
import { useODSingleOrgContext } from '../context/ODSingleOrgContext'
import { useAPIs } from '../context/useAPIs'
import { useOrgPerm } from '../context/useOrgPerm'
import { Utils } from '../utils'
import { ODIcon, ODIcons } from './ODIcon'
import { ODModalUpdateWorkset } from './ODModal/ODModalEditWorkset'
import { ODModalShare } from './ODModal/ODModalShare'

type WorksetSettingDropdownProps = {}

const CustomSettingDropdown = styled(DropdownToggle)`
  background-color: #ffffff;
  border: 0;

  &:hover {
    background-color: #ffffff;
  }
`

const regexpCheckWorksetDetail = new RegExp(/workset\/([\d]+)$/)

export function getWorksetDetailId(path: string): number | null {
  const match = path.match(regexpCheckWorksetDetail)
  return match ? parseInt(match[1], 10) : null
}

export const WorksetSettingDropdown: React.FC<WorksetSettingDropdownProps> = () => {
  const [state, setWorksetState] = useRecoilState<WORKSET_DETAIL_STATE>(worksetDetailState)
  const { orgId } = useODSingleOrgContext()
  const permUtils = useOrgPerm(orgId)
  const { apiUpdateWorksetFavorite } = useAPIs()
  const { wsId, perm, favorite } = state

  const [shareModalOpen, setShareModalOpen] = React.useState(false)
  const [editWorksetOpen, setEditWorksetOpen] = React.useState(false)
  const [isUpdatingFavorite, setIsUpdatingFavorite] = React.useState(false)
  const updateWorksetFavorite = useWrappedAPI(
    ({ worksetId, toFavor }: { worksetId: number; toFavor: boolean }) =>
      apiUpdateWorksetFavorite({ wsId: worksetId, toFavor }),
    isUpdatingFavorite,
    setIsUpdatingFavorite,
    {
      onSuccess: res => {
        const toFavor = res.ok === 1
        setWorksetState(st => {
          return { ...st, favorite: toFavor }
        })

        Utils.showSuccess(toFavor ? 'Added to favorite.' : 'Removed from favorite.', 'Success')
      },
    }
  )

  if (wsId === null) {
    return null
  }

  if (!perm || perm < WORKSET_USER_PERM.Editor) {
    if (!permUtils?.isAllowed(ORG_ROLE.EDIT_ORG_WORKSET)) {
      return null
    }
  }

  return (
    <div>
      {shareModalOpen && <ODModalShare isOpen setIsOpen={setShareModalOpen} wsId={wsId} />}
      {editWorksetOpen && <ODModalUpdateWorkset wsId={wsId} onClose={() => setEditWorksetOpen(false)} />}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {favorite !== null && (
          <div
            style={{ minHeight: 33, padding: '6px 0', marginLeft: 10 }}
            onClick={() => updateWorksetFavorite({ worksetId: wsId!, toFavor: !favorite })}
          >
            <ODIcon
              icon={favorite ? ODIcons.MaterialStar : ODIcons.MaterialStarBorder}
              style={{
                fontSize: 16,
                color: favorite ? 'rgb(255, 193, 7)' : '#73818f',
                cursor: 'pointer',
                marginBottom: 3,
              }}
            />
          </div>
        )}
        <UncontrolledDropdown setActiveFromChild>
          <CustomSettingDropdown>
            <ODIcon
              icon={ODIcons.CoreSettings}
              style={{ fontSize: 14, color: '#73818f', cursor: 'pointer', marginBottom: 2 }}
            />
          </CustomSettingDropdown>
          <DropdownMenu>
            <DropdownItem onClick={() => setEditWorksetOpen(true)}>Setting</DropdownItem>
            {/*<ODDropdownItem onClick={() => handleChooseSync()}>Sync</ODDropdownItem>*/}
            <DropdownItem onClick={() => setShareModalOpen(true)}>Share</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}
