import { ODListableResponseType, useODQuery } from '@odc/od-react-belt'
import React from 'react'
import { GQLAppConfig, GQLListableAppConfig, GQLListableAppConfigInput } from '../../../@types/server'
import { IAppConfigListableOption } from './AppConfigCommon'
import { GQL_LIST_APP_CONFIG } from './AppConfigGQL'

export function useAppConfigListDataLoader () {
  // @ts-ignore
  const listApi = useODQuery<Partial<GQLListableAppConfigInput>, GQLListableAppConfig>(GQL_LIST_APP_CONFIG)
  return React.useCallback(
    async function dataLoader (
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IAppConfigListableOption,
    ): Promise<ODListableResponseType<GQLAppConfig>> {
      const r = await listApi({
        page,
        pageSize,
        ...options,
      })
      return r as ODListableResponseType<GQLAppConfig>
    },
    [listApi],
  )
}
