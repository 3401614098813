import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import styled from 'styled-components'
import { ODButton, ODButtonTheme } from '../ODButton'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

type ODSelectOption<T> = {
  title: string
  description: string
  value: T
}

type ODModalSelectorProps<T> = {
  title: string
  selects: Array<ODSelectOption<T>>
  okTitle?: string
}

const SelectWrapper = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
`

type SelectableItemProps<T> = {
  index: number
  selectedValue: T | null
  option: ODSelectOption<T>
  onClick: (value: T) => void
}

const SelectableItemTitle = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 4px;
  margin-bottom: 14px;
  color: #2e343a;
`

const SelectableItemDescription = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f353a;
`

function SelectableItem<T>({ onClick, index, selectedValue, option }: SelectableItemProps<T>) {
  const name = `option_${index}`
  return (
    <SelectWrapper onClick={() => onClick(option.value)}>
      <FormGroup check className="radio">
        <Input
          className="form-check-input"
          type="radio"
          id={name}
          name="radios"
          value={name}
          checked={selectedValue === option.value}
          onChange={() => onClick(option.value)}
        />
        <Label check className="form-check-label" htmlFor={name}>
          <SelectableItemTitle>{option.title}</SelectableItemTitle>
          {option.description && <SelectableItemDescription>{option.description}</SelectableItemDescription>}
        </Label>
      </FormGroup>
    </SelectWrapper>
  )
}

const SelectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type ODModalCompProps<T> = {
  isOpen: boolean
  selectedValue: T | null
}

export function useModalSelect<T>(options: ODModalSelectorProps<T>) {
  const { title, selects, okTitle = 'Ok' } = options

  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState<T | null>(null)
  const onClickYes = React.useRef<() => void>(() => undefined)
  const onClickNo = React.useRef<() => void>(() => undefined)
  const selectedValueRef = React.useRef<T | null>(null)

  const choose = React.useCallback((initialValue: T | null): Promise<T | null> => {
    return new Promise(resolve => {
      setSelectedValue(initialValue)
      selectedValueRef.current = initialValue
      onClickYes.current = () => {
        setIsOpen(false)
        resolve(selectedValueRef.current)
      }
      onClickNo.current = () => {
        setIsOpen(false)
        resolve(null)
      }
      setIsOpen(true)
    })
  }, [])

  const [Component] = React.useState(() => {
    return (props: ODModalCompProps<T>) => (
      <ODModal isOpen={props.isOpen} toggle={() => onClickNo.current()}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title={title} onClose={() => onClickNo.current()} />
          <ODModalBody>
            <SelectsWrapper>
              {selects.map((select, index) => (
                <SelectableItem
                  key={index}
                  option={select}
                  index={index}
                  selectedValue={props.selectedValue}
                  onClick={v => {
                    setSelectedValue(v)
                    selectedValueRef.current = v
                  }}
                />
              ))}
            </SelectsWrapper>
          </ODModalBody>
          <ODModalFooter>
            <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={() => onClickYes.current()}>
              {okTitle}
            </ODButton>
          </ODModalFooter>
        </div>
      </ODModal>
    )
  })

  return { Component, choose, props: { isOpen, selectedValue } }
}
