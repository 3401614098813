import React, { useEffect, useState } from 'react'
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import styled from 'styled-components'
import { ProjectSelectOptionType } from '../../../containers/User/Project/ProjectCommon'
import { useWorksetInProjectList } from '../../../context/ODCILSHooks'
import { ODColors } from '../../../global-styles'
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick'
import { NoResultDropDown } from '../../NoResultDropDown'
import { DropButtonStyleProps } from '../../ODComponents/ODMultiSelectDropdown'
import { ODIcon, ODIcons } from '../../ODIcon'

const DropButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 14px;
  padding-right: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: ${(props: DropButtonStyleProps) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props: DropButtonStyleProps) => (props.disabled ? 0.6 : 1.0)};
  text-align: left;
`
const DropDown = styled.div`
  position: relative;
  display: inline-block;
  min-width: 116px;
`
const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 198px;
  min-height: 50px;
  max-height: 300px;
  overflow-y: auto;
  border: solid 1px #c8ced3;
  z-index: 1001;
  flex-direction: column;
  padding: 8px;
  margin-top: 5px;
`

const Value = styled.span`
  padding-left: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
  cursor: pointer;

  &:hover {
    background-color: ${ODColors.Primary};
    color: #ffffff !important;
  }
`

interface IWorksetsInProjectDropDown {
  disabled: boolean
  projectId: number
  setValue: (v: ProjectSelectOptionType<number> | null) => void
}

export function WorksetsInProjectDropDown(props: IWorksetsInProjectDropDown) {
  const { disabled, projectId, setValue } = props
  const { worksets: list } = useWorksetInProjectList(projectId)
  const [isOpen, setIsOpen] = React.useState(false)
  const [keyword, setKeyword] = useState<string>('')
  const [result, setResult] = useState<ProjectSelectOptionType<number>[]>([])
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const menuRef = React.useRef<HTMLDivElement>(null)

  // const {
  //   state: { loadOption },
  //   updateLoadOption,
  // } = React.useContext(listableContext)

  useDetectOutsideClick(() => setIsOpen(false), menuRef, isOpen)

  useEffect(() => {
    if (keyword === '') {
      setResult(list)
    } else {
      const results = list && list.filter(item => item.label.toLowerCase().includes(keyword.toLowerCase()))
      setResult(results)
    }
  }, [keyword, list])

  const handleSelectCategory = (worksetGroup: ProjectSelectOptionType<number> | null) => {
    if (worksetGroup === null) {
      setSelectedCategory('')
      setValue(null)
    } else {
      setSelectedCategory(worksetGroup.label)
      setValue(worksetGroup)
    }
    setIsOpen(false)
  }

  return (
    <DropDown ref={menuRef}>
      <DropButton disabled={disabled} onClick={() => !disabled && (isOpen ? setIsOpen(false) : setIsOpen(true))}>
        <span
          style={{ display: 'block', width: 90, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {selectedCategory !== '' ? selectedCategory : 'Workset'}
        </span>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        <FormGroup>
          <InputGroup size="normal">
            <InputGroupAddon addonType="prepend" style={{ width: 30 }}>
              <InputGroupText style={{ backgroundColor: 'transparent', borderRightColor: 'transparent' }}>
                <ODIcon icon={ODIcons.CoreSearch} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="search"
              placeholder="Search : Workset"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
              style={{ borderLeftColor: 'transparent' }}
            />
          </InputGroup>
        </FormGroup>
        {keyword === '' ? (
          <Value style={{ fontWeight: 'bold', color: '#2f353a' }} onClick={() => handleSelectCategory(null)}>
            All worksets
          </Value>
        ) : null}
        {result && result.length === 0 ? (
          <NoResultDropDown />
        ) : (
          result &&
          result.map(item => {
            return (
              <Value key={item.key} onClick={() => handleSelectCategory(item)}>
                {item.label}
              </Value>
            )
          })
        )}
      </DropDownContent>
    </DropDown>
  )
}
