import { ITEM_EXECUTION_ASSIGN_FILTER, ITEM_EXECUTION_LOCK_FILTER } from '@cils/common'
import React from 'react'
import { ItemExecutionOptionFilter, ProjectSelectOptionType } from '../../../containers/User/Project/ProjectCommon'
import { useUsersInProjectList } from '../../../context/ODCILSHooks'
import { ODColors } from '../../../global-styles'
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick'
import { DropButton, DropDown, DropDownContent, OptionWrapper, Value } from '../../Dropdown/DropdownCommon'
import { ODIcon, ODIcons } from '../../ODIcon'
import { GQLITEM_EXECUTION_ASSIGN_FILTER, GQLITEM_EXECUTION_LOCK_FILTER } from '../../../@types/server'

interface IProjectAssigneeDropDown {
  projectId: number
  value: ProjectSelectOptionType<ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>> | null
  setValue: (v: ProjectSelectOptionType<ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>> | null) => void
}

export const ProjectAssigneeDropDown: React.FC<IProjectAssigneeDropDown> = props => {
  const { setValue, value, projectId } = props
  const { users } = useUsersInProjectList(projectId)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  useDetectOutsideClick(() => setIsOpen(false), menuRef, isOpen)

  const onClick = (v: ProjectSelectOptionType<ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>> | null) => {
    setValue(v)
    setIsOpen(false)
  }

  const selectedValue = (() => {
    if (!value) {
      return 'Assignee'
    }
    if (
      value.value.type === ((ITEM_EXECUTION_ASSIGN_FILTER.NoAssigned as unknown) as GQLITEM_EXECUTION_ASSIGN_FILTER)
    ) {
      return 'Assigned to nobody'
    }
    if (value.value.userId) {
      const findUser = users.find(user => user.value === value.value.userId)
      const index = findUser?.label.indexOf(':')
      return findUser?.label.substring(0, index) ?? '-'
    }
  })()

  return (
    <DropDown ref={menuRef} style={{ width: 116 }}>
      <DropButton onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))} style={{ width: 116, height: 34 }}>
        <span
          style={{ display: 'block', width: 90, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {selectedValue}
        </span>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none', width: 306, marginTop: 5 }}>
        <OptionWrapper onClick={() => onClick(null)} style={{ minHeight: 41 }}>
          <Value style={{ fontWeight: 'bold', color: ODColors.CharcoalGrey }}>All</Value>
        </OptionWrapper>
        <OptionWrapper
          onClick={() =>
            onClick({
              label: 'Assigned to nobody',
              key: 1,
              value: {
                type: (ITEM_EXECUTION_ASSIGN_FILTER.NoAssigned as unknown) as GQLITEM_EXECUTION_ASSIGN_FILTER,
                userId: null,
              },
            })
          }
          style={{ minHeight: 41 }}
        >
          <Value>Assigned to nobody</Value>
        </OptionWrapper>
        {users.map(user => {
          const option: ProjectSelectOptionType<ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>> = {
            ...user,
            value: {
              type: (ITEM_EXECUTION_ASSIGN_FILTER.AssignedByUser as unknown) as GQLITEM_EXECUTION_ASSIGN_FILTER,
              userId: user.value,
            },
          }
          return (
            <OptionWrapper key={user.key} onClick={() => onClick(option)} style={{ minHeight: 41 }}>
              <Value>{user.label}</Value>
            </OptionWrapper>
          )
        })}
      </DropDownContent>
    </DropDown>
  )
}
