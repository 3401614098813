import { ODListableOption, ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLProjectHasWorkset } from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'

export interface IProjectHasWorksetListableOption extends ODListableOption {
  filter: string
  projectId: number
}

export function useProjectHasWorksetListDataLoader(projectId: number) {
  const { listProjectHasWorkset } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectHasWorksetListableOption
    ): Promise<ODListableResponseType<GQLProjectHasWorkset>> {
      const r = await listProjectHasWorkset({
        page,
        pageSize,
        filter: options.filter,
        projectId,
      })
      return r as ODListableResponseType<GQLProjectHasWorkset>
    },
    [listProjectHasWorkset, projectId]
  )
}
