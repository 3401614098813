import { ODButton, ODButtonSize, ODButtonTheme, ODHSpace, ODHSpread, ODRow } from '@odc/od-react-belt'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { ODColors } from '../../../global-styles'
import { SiteUrls } from '../../../urls'
import { SelectedWorkset } from './common'

export const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #2f353a;
`

export const RightColumn = styled.div``
export const Wrapper = styled.div`
  margin: 25px 30px 25px 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 99;
`

export const DownloadPathText = styled.span`
  font-size: 14px;
`

export const ClickablePath = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${ODColors.Primary};
  cursor: pointer;
`

export const DirectoryRootInfo = (props: {
  selectedWorkset: SelectedWorkset | undefined
  isDownload: boolean
  rootPath: string
  onClickRoot?: () => void
}) => {
  const { selectedWorkset, isDownload, rootPath, onClickRoot } = props
  const { orgId } = useODSingleOrgContext()
  const { openFolder } = useCILSAgentContext()
  const handleOpenFolder = () => {
    if (selectedWorkset) {
      // noinspection JSIgnoredPromiseFromCall
      openFolder({ path: selectedWorkset?.worksetDirName, type: 'openDownloadFolder' })
    } else {
      // noinspection JSIgnoredPromiseFromCall
      openFolder({ path: '', type: isDownload ? 'openDownloadFolder' : 'openUploadFolder' })
    }
  }

  return (
    <ODRow>
      <ODHSpread style={{ width: '100%' }}>
        <LeftColumn>
          <ODIcon icon={ODIcons.SimpleLineIconsFolder} />
          <ODHSpace w={11} />
          {!selectedWorkset && (
            <DownloadPathText>
              {isDownload ? 'Download Root' : 'Upload Root'} ({rootPath})
            </DownloadPathText>
          )}
          {selectedWorkset && (
            <>
              <DownloadPathText>
                <ClickablePath onClick={onClickRoot}>
                  {isDownload ? 'Download Root' : 'Upload Root'} ({rootPath})
                </ClickablePath>
                <ODIcon icon={ODIcons.MaterialChevronRight} style={{ color: '#73818f', marginTop: -2, fontSize: 18 }} />
                {selectedWorkset.worksetName}
              </DownloadPathText>
            </>
          )}
          <ODHSpace w={21} />
          <ODButton
            theme={ODButtonTheme.PrimaryInvert}
            rounded
            size={ODButtonSize.Small}
            style={{ minWidth: 135 }}
            onClick={() => handleOpenFolder()}
          >
            Open Folder
          </ODButton>
        </LeftColumn>
        <RightColumn>
          <Link to={SiteUrls.User.Org.Sync.Live(orgId)} style={{ textDecoration: 'none' }}>
            <div style={{ color: '#73818f', marginRight: 12, fontSize: 16 }}>
              <ODIcon icon={ODIcons.CoreTerminal} />
            </div>
          </Link>
        </RightColumn>
      </ODHSpread>
    </ODRow>
  )
}
