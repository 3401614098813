import { OrgPropsInputValidation, populateOrgPropsInput } from '@cils/common'
import { useODModalConfirm } from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import * as Yup from 'yup'
import { GQLOrg, GQLOrgPropsInput, GQLSingleIDInput } from '../../../@types/server'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type OrganizationEditContainerProps = {
  idEditing?: number
}

const GQL_CREATE_ORG = `
mutation createOrg($data: OrgPropsInput!) {
  createOrg(data: $data) {
    orgId
  }
}
`

const GQL_UPDATE_ORG = `
mutation updateOrg($data: OrgPropsInput!) {
  updateOrg(data: $data) {
    orgId
  }
}
`

const GQL_GET_ORG = `
query getOrg($data: SingleIDInput!) {
  getOrg(data: $data) {
    orgId
    name
    color
    supportEmail
    willDeleteAt
  }
}
`

const GQL_REMOVE_ORG = `
mutation removeOrg($data: SingleIDInput!) {
  removeOrg(data: $data) {
    orgId
    name
    color
    supportEmail
    willDeleteAt
  }
}
`

const GQL_UN_REMOVE_ORG = `
mutation unRemoveOrg($data: SingleIDInput!) {
  unRemoveOrg(data: $data) {
    orgId
    name
    color
    supportEmail
    willDeleteAt
  }
}
`

type PropsInput = GQLOrgPropsInput
type Entity = GQLOrg

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: OrgPropsInputValidation.name.required('Name is required.'),
    supportEmail: OrgPropsInputValidation.supportEmail.required('Support email is required.'),
  })
}

const WarningMessage = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #f86c6b;
`

const DeleteConfirmMessage = () => (
  <div>
    Are you sure want to delete Organization?
    <br />
    <br />
    Once you’ve deleted the organization, you’ll have 30 days to restore the organization and cancel the deletion.
    During 30 days, users of the deleted organization will not be able to login and use. After 30 days, the deletion
    process will begin and you won’t be able to retrieve any of the data.
    <br />
    <br />
    <WarningMessage>You need to hold the click for 2 seconds to delete the Organization.</WarningMessage>
  </div>
)

const RestoreConfirmMessage = () => (
  <div>
    Are you sure want to restore Organization?
    <br />
    <br />
    <WarningMessage>You need to hold the click for 2 seconds to restore the Organization.</WarningMessage>
  </div>
)

export const OrganizationEditContainer: React.FC<OrganizationEditContainerProps> = props => {
  const { idEditing } = props
  const apiCreateOrg = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_CREATE_ORG)
  const apiUpdateOrg = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_UPDATE_ORG)
  const apiGetOrg = useODQuery<GQLSingleIDInput, Entity>(GQL_GET_ORG)
  const apiRemoveOrg = useODMutation<GQLSingleIDInput, GQLOrg>(GQL_REMOVE_ORG)
  const apiUnRemoveOrg = useODMutation<GQLSingleIDInput, GQLOrg>(GQL_UN_REMOVE_ORG)
  const { Component, confirm, props: confirmProps } = useODModalConfirm({ longClickTime: 2000 })
  const [markedDeleted, setMarkedDeleted] = React.useState(false)
  const [redirect, setRedirect] = React.useState('')

  const onRestore = React.useCallback(async () => {
    if (idEditing) {
      if (
        await confirm({
          title: 'Restore Organization',
          message: <RestoreConfirmMessage />,
          yes: 'Restore',
          no: 'Cancel',
        })
      ) {
        await apiUnRemoveOrg({ id: idEditing })
        Utils.showSuccess('Restored an organization', 'Success')
        setRedirect(SiteUrls.Admin.Organization.List)
      }
    }
  }, [apiUnRemoveOrg, idEditing, confirm])

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          const item = await apiGetOrg({ id: idEditing })
          setMarkedDeleted(!!item.willDeleteAt)
          return item
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          return {
            id: null,
            name: '',
            color: null,
            supportEmail: '',
          }
        }
        return {
          id: data.orgId,
          name: data.name,
          color: data.color,
          supportEmail: data.supportEmail,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        console.log(167, data)
        if (idEditing) {
          await apiUpdateOrg({ id: idEditing, ...data })
          Utils.showSuccess('Updated an organization.', 'Success')
        } else {
          await apiCreateOrg({ name: data.name, supportEmail: data.supportEmail })
          Utils.showSuccess('Added a new organization.', 'Success')
        }
        return SiteUrls.Admin.Organization.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateOrgPropsInput,
      deleteItem: async () => {
        if (idEditing) {
          if (
            await confirm({
              title: 'Delete Organization',
              message: <DeleteConfirmMessage />,
              yes: 'Delete',
              no: 'Cancel',
            })
          ) {
            await apiRemoveOrg({ id: idEditing })
            Utils.showSuccess('Marked an organization as deletion pending.', 'Success')
            return SiteUrls.Admin.Organization.List
          }
        }
        return ''
      },
    }),
    [idEditing, apiCreateOrg, apiGetOrg, apiUpdateOrg, apiRemoveOrg, confirm]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreateOrg])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New Organization' : 'Edit Organization'

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Provider title={title}>
      <ODEntityInput name="name" label="Name of the organization" placeholder="Enter name" inputType="text" />
      <ODEntityInput name="supportEmail" label="Support Email" placeholder="Enter support email" inputType="text" />
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        renderDeleteButton={
          markedDeleted
            ? () => {
                return (
                  <Button
                    type="button"
                    color="primary"
                    style={{ minWidth: 135, marginRight: 13 }}
                    outline
                    onClick={() => onRestore()}
                  >
                    Restore
                  </Button>
                )
              }
            : undefined
        }
        deleteButtonName={idEditing && !markedDeleted ? 'Delete' : undefined}
        deleteConfirmOptions={null}
        deleteButtonLeft
        context={Context}
      />
      <Component {...confirmProps} />
    </Provider>
  )
}
