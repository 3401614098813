import React from 'react'
import { Container } from 'reactstrap'
import { FlexContentsContainer } from '../components/FlexContentsContainer'
import { ODButton, ODButtonTheme } from '../components/ODButton'
import { ODIcons } from '../components/ODIcon'
import { ODModalSize } from '../components/ODModal/ODModal'
import { useModalConfirm } from '../components/ODModal/ODModalConfirm'
import { useModalSelect } from '../components/ODModal/ODModalSelector'
import { ODColors } from '../global-styles'

export const fakeNodeList = [
  {
    path: 'NAS 1',
    text: 'NAS 1 (45)',
    depth: 0,
    isOpen: true,
    hasChild: true,
    isSelected: false,
    isFocused: false,
  },
  {
    path: 'NAS 1/20200301',
    text: '20200301 (45)',
    depth: 1,
    isOpen: true,
    hasChild: true,
    isSelected: false,
    isFocused: false,
  },
  {
    path: 'NAS 1/20200301/algae',
    text: 'algae (12)',
    depth: 2,
    isOpen: false,
    hasChild: false,
    isSelected: true,
    isFocused: false,
  },
  {
    path: 'NAS 1/20200301/Blood_cat',
    text: 'Blood_cat (33)',
    depth: 2,
    isOpen: false,
    hasChild: false,
    isSelected: false,
    isFocused: true,
  },
  {
    path: 'NAS 2',
    text: 'NAS 2 (56)',
    depth: 0,
    isOpen: false,
    isSelected: false,
    hasChild: false,
    isFocused: false,
  },
]

export const TestContainer: React.FC = () => {
  const { Component: ConfirmComponent, confirm, props: confirmComponentProps } = useModalConfirm({
    yes: '네',
    no: '아니오',
    message: (
      <>
        정말로 삭제하시겠습니까?
        <br />
        다시 되돌릴 수 없습니다.
      </>
    ),
    size: ODModalSize.Small,
    icon: ODIcons.MaterialError,
  })

  const { Component: SelectComponent, choose, props: selectComponentProps } = useModalSelect({
    okTitle: 'Confirm',
    selects: [
      {
        title: 'Researcher',
        description:
          '연구자는 시스템 설정을 수정하거나, 사용자 권한 변경 등을 할 수 없습니다. 연구와 관련한 권한은 각 소속기관의 권한에 따릅니다.',
        value: 1,
      },
      {
        title: 'CILS Administrator',
        description: 'CILS Administrator can change system settings, user permissions, organizations, etc.',
        value: 2,
      },
    ],
    title: 'Change User Permission',
  })

  const handleConfirm = async () => {
    const y = await confirm()
    alert(`You clicked ${y ? 'Yes' : 'No'}`)
  }

  const handleChoose = async () => {
    const c = await choose(null) // can set initial value.
    if (!c) {
      alert('You cancelled.')
    } else {
      alert(`You chose ${c}`)
    }
  }

  return (
    <main className="main" style={{ display: 'flex', flexDirection: 'column', marginTop: 100 }}>
      <div
        style={{
          display: 'flex',
          flexGrow: 3,
          marginTop: 0,
          marginBottom: 25,
          marginLeft: 30,
          marginRight: 30,
          backgroundColor: ODColors.ContentAreaBackground,
          // borderRadius: 5,
          // border: 'solid 1px #c8ced2',
          // backgroundColor: '#ffffff',
        }}
      >
        <Container fluid>
          <FlexContentsContainer>
            <ConfirmComponent {...confirmComponentProps} />
            <SelectComponent {...selectComponentProps} />
            {/*<WorksetComponent {...worksetComponentProps} />*/}
            {/*<WorksetSettingComponent {...worksetSettingComponentProps} />*/}
            <ODButton theme={ODButtonTheme.Primary} onClick={handleConfirm}>
              useModalConfirm
            </ODButton>
            <p />
            <ODButton theme={ODButtonTheme.Primary} onClick={handleChoose}>
              useModalSelect
            </ODButton>
          </FlexContentsContainer>
        </Container>
      </div>
    </main>
  )
}
