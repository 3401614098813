import { WORKSET_USER_PERM } from '@cils/common'
import { ODIcon, ODIcons } from '@odc/od-react-belt'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { GQLListableWorkset, GQLListableWorksetInput, GQLWorkset } from '../../../@types/server'
import { Clickable } from '../../../components/Clickable'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { NewMark } from '../../../components/NewMark'
import { ODModalShare } from '../../../components/ODModal/ODModalShare'
import { useODQuery } from '../../../context/ODCommon'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { useAPIs } from '../../../context/useAPIs'
import {
  createODListableContext,
  ODListableResponseType,
  ODListableStyle,
  WorksetDataLoaderOption,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { SiteUrls } from '../../../urls'
import { ODWorksetSearch, WORKSET_VIEW_TYPE } from './ODWorksetSearch'

const createGQLForListWorkset = (favoriteOnly: boolean) => {
  const prefix = !favoriteOnly
    ? `query listWorksetInOrg($data: ListableWorksetInput!) {
  listWorksetInOrg(data: $data) {
`
    : `query listFavoriteWorksets($data: ListableUserFavorWorksetInput!) {
  listFavoriteWorksets(data: $data) {
`

  return `${prefix}
    list {
      wsId
      name
      numItems
      defaultPerm
      lastAddedAt
      createdAt
      members {
        permLevel
        user {
          name
          email
        }
      }
      organization {
        orgId
        name
      }
      myPerm
      isNew
    }
    totalCount
    page
    pageSize
  }
}
`
}

const { Provider, Context } = createODListableContext<GQLWorkset, WorksetDataLoaderOption>()

interface Props {
  showInOrg?: boolean
  favoriteOnly?: boolean
}

export const WorksetContainer: React.FC<Props> = props => {
  const { showInOrg = false, favoriteOnly = false } = props
  const { orgId } = useODSingleOrgContext()

  const { listWorkset: apiList } = useAPIs()
  const apiListInOrg = useODQuery<Partial<GQLListableWorksetInput>, GQLListableWorkset>(
    createGQLForListWorkset(favoriteOnly)
  )
  const selectedValue = WORKSET_VIEW_TYPE.List
  const [worksetToShare, setWorksetToShare] = React.useState<GQLWorkset | null>(null)

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: WorksetDataLoaderOption
    ): Promise<ODListableResponseType<GQLWorkset>> {
      if (showInOrg) {
        return apiListInOrg({ page, pageSize, filter: options.filter, orgId, sortBy: options.sortBy })
      }
      return apiList({ page, pageSize, filter: options.filter, sortBy: options.sortBy })
    },
    [apiList, orgId, showInOrg, apiListInOrg]
  )

  const handleShareWorkset = async (workset: GQLWorkset) => {
    setWorksetToShare(workset)
  }

  const TableDefinition: ODListableTableDefinition<GQLWorkset, WorksetDataLoaderOption> = [
    {
      id: 'name',
      title: 'Workset name',
      transform: v => {
        const newMark = v.isNew ? <NewMark /> : null
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Link to={SiteUrls.User.Org.Workset.Detail(v.wsId)(orgId)}>
              {v.name}
              {newMark}
            </Link>
          </div>
        )
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'item',
      title: 'Items',
      transform: v => v.numItems.toLocaleString(),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'lastAdded',
      title: 'Last item added',
      transform: v => (v.lastAddedAt ? moment(v.lastAddedAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'creator',
      title: 'Creator',
      transform: v => {
        const owner = v.members.find(v => v.permLevel === WORKSET_USER_PERM.Owner)
        return <div>{owner?.user?.name || '-'}</div>
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'org',
      title: 'Organization',
      transform: v => {
        return <div>{v.organization.name}</div>
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'created',
      title: 'Created',
      transform: v => (v.createdAt ? moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'share',
      title: 'Share',
      transform: v => {
        const hasSharePerm = v.myPerm && v.myPerm >= WORKSET_USER_PERM.Editor
        if (hasSharePerm) {
          return (
            <Clickable onClick={() => handleShareWorkset(v)}>
              <ODIcon icon={ODIcons.CoreShareAlt} />
            </Clickable>
          )
        }
        return (
          <span style={{ color: '#e4e7ea' }}>
            <ODIcon icon={ODIcons.CoreShareAlt} />
          </span>
        )
      },
      thClass: 'text-center',
      className: 'text-center',
    },
  ]

  return (
    <FlexContentsContainer>
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v.wsId.toString()}
        pageSize={12}
        searchOnLoad
        style={ODListableStyle.TableStyle}
      >
        {worksetToShare && (
          <ODModalShare
            isOpen
            setIsOpen={() => setWorksetToShare(null)}
            wsId={worksetToShare!.wsId}
            title={`Share setting for ${worksetToShare!.name}`}
          />
        )}
        <ODWorksetSearch
          listableContext={Context}
          selectedViewType={selectedValue}
          // setSelectedViewType={setSelectedValue}
          setSelectedViewType={() => null}
          showInOrg={showInOrg}
        />
        {/*{selectedValue === WORKSET_VIEW_TYPE.Grid && (*/}
        {/*  <div style={{ padding: 17, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>*/}
        {/*    <WorksetList />*/}
        {/*  </div>*/}
        {/*)}*/}
        {selectedValue === WORKSET_VIEW_TYPE.List && (
          <div style={{ padding: 17 }}>
            <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
              <CardBody>
                <ODListablePaginatedTable
                  fields={TableDefinition}
                  listableContext={Context}
                  renderLoading={() => 'Loading..'}
                  renderEmpty={() => 'No Result.'}
                />
                <ODListablePagination hideIfSinglePage listableContext={Context} />
              </CardBody>
            </Card>
          </div>
        )}
      </Provider>
    </FlexContentsContainer>
  )
}
