import { useFormikContext } from 'formik'
import { ReactNodeLike } from 'prop-types'
import React from 'react'
import { Col, FormFeedback, FormGroup, Input, Label, Popover } from 'reactstrap'
import styled from 'styled-components'
import { Utils } from '../../utils'
import { ODEntityColorPicker } from './ODEntityColorPicker'

type ODEntityColorPickerInputProps = {
  elementId: string
  required?: boolean
  placeholder?: string
  colors: Array<string>
  renderChildren?: (value: any) => ReactNodeLike
}

// InputOverlay 는 Input 위에 가상으로 투명 overlay 를 올려서 popper 를 띄우는 역할을 한다.
// 우리는 renderChildren 반환 값을 이 Overlay 에 얹어서 보여준다.
const InputOverlay = styled.div`
  position: absolute;
  left: 12px;
  top: 0;
  //background-color: #29b6ca40;
  width: calc(100% - 24px);
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: start;
  padding-left: 12px;
  padding-right: 12px;
`

const InputWithWhite = styled(Input)`
  :disabled {
    background-color: white;
  }
`

export const ODEntityColorPickerInput: React.FC<ODEntityColorPickerInputProps> = props => {
  const { required = true, elementId, renderChildren } = props
  const name = 'color'

  const formikContext = useFormikContext()
  const [pickerOpened, setPickerOpened] = React.useState(false)

  if (!formikContext) {
    return null
  }

  const { errors, touched, handleChange, handleBlur, values } = formikContext

  // @ts-ignore
  const value = values[name]
  // @ts-ignore
  const error = errors[name]
  // @ts-ignore
  const touchedElement = !!touched[name]

  const onFocus = () => {
    setPickerOpened(v => !v)
  }

  return (
    <FormGroup row>
      <Col md="3">
        <Label htmlFor={elementId} style={{ paddingTop: 7 }}>
          Color
        </Label>
      </Col>
      <Col xs="12" md="9" style={{ position: 'relative' }}>
        <InputOverlay onClick={onFocus}>{renderChildren && renderChildren(value)}</InputOverlay>
        <InputWithWhite
          type="text"
          id={elementId}
          name={name}
          invalid={touchedElement && !!error}
          required={required}
          onChange={() => {
            //
          }}
          onBlur={handleBlur}
          value=""
          disabled
        />
        <Popover
          placement="bottom-start"
          isOpen={pickerOpened}
          target={elementId}
          toggle={() => setPickerOpened(v => !v)}
          hideArrow
          innerClassName="od-popper"
          className="od-popper"
        >
          <ODEntityColorPicker
            noBorder
            colors={props.colors}
            initialValue={value}
            onChange={code => {
              handleChange({ target: { id: elementId, name, value: code } })
              setPickerOpened(false)
            }}
            onSubmitInvalidColor={() => Utils.showError('Invalid color code', 'Error')}
          />
        </Popover>
        {error && <FormFeedback>{error}</FormFeedback>}
      </Col>
    </FormGroup>
  )
}
