import { ORG_ROLE, WORKSET_USER_PERM } from '@cils/common'
import { ODModalSize, usePickerTableModal } from '@odc/od-react-belt'
import moment from 'moment'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import { GQLWorkset } from '../../../@types/server'
import { BlockingLoadBox } from '../../../components/BlockingLoadBox'
import { DROPDOWN_MENU, ODImageGridSearchToolBarProps } from '../../../components/ODImageGrid/ODImageGridSearchToolBar'
import { ODModalEditWorkset } from '../../../components/ODModal/ODModalEditWorkset'
import { ODModalShare } from '../../../components/ODModal/ODModalShare'
import { ODSearchBox } from '../../../components/ODSearchBox'
import { SortByWorksetDropdown } from '../../../components/SortByWorksetDropdown'
import { useCreateWorksetHooks } from '../../../context/ODCILSHooks'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { useOrgPerm } from '../../../context/useOrgPerm'
import { ODListableContextType, WorksetDataLoaderOption } from '../../../ODListable/ODListableContext'
import { MultiWorksetShareModal } from './MultiWorksetShareModal'
import { IWorksetListableOption } from './WorksetCommon'
import { useWorksetInOrgListDataLoader, useWorksetListDataLoader } from './WorksetListDataLoader'

const AddWorksetButton = styled.div`
  margin-left: 15px;
  width: 135px;
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const AddWorksetButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 72px;
  background-color: #ffffff;
`
const SortByText = styled.span`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #2f353a;
`

export enum WORKSET_VIEW_TYPE {
  Grid = 'Grid',
  List = 'List',
}

export type IODWorksetSearchProps = ODImageGridSearchToolBarProps<GQLWorkset, WorksetDataLoaderOption> & {
  selectedViewType: WORKSET_VIEW_TYPE
  setSelectedViewType: (v: WORKSET_VIEW_TYPE) => void
  showInOrg: boolean
}

export function ODWorksetSearch(props: IODWorksetSearchProps) {
  const { listableContext, selectedViewType, setSelectedViewType, showInOrg } = props
  const { orgId } = useODSingleOrgContext()
  const {
    updateLoadOption,
    refresh,
    state: { loadOption },
  }: ODListableContextType<GQLWorkset, WorksetDataLoaderOption> = React.useContext(listableContext)
  const permUtils = useOrgPerm(orgId)
  const canAddWorkset = permUtils?.isAllowed(ORG_ROLE.CREATE_WORKSET) ?? false

  const [keyword, setKeyword] = React.useState('')
  const [modalOpen, setModalOpen] = React.useState(false)
  const [isMenuOpen, setIsMenuOpen] = React.useState<DROPDOWN_MENU | null>(null)
  const [shareModalOpenForId, setShareModalOpenForId] = React.useState<number | null>(null)
  const [shareTitle, setShareTitle] = React.useState<string>('')
  const [worksetsToShare, setWorksetsToShare] = React.useState<GQLWorkset[] | null>(null)

  const handleChange = (v: string) => {
    setKeyword(v)
    updateLoadOption({ ...loadOption, filter: v })
  }

  const { isLoading, handleAddWorksetModal } = useCreateWorksetHooks(orgId, async (wsId: number, name: string) => {
    setShareTitle(`Share setting for ${name}`)
    setShareModalOpenForId(wsId)
    await refresh()
  })

  const worksetInOrgListDataLoader = useWorksetInOrgListDataLoader({ orgId, permMoreThanEditor: true, sortBy: null })
  const worksetListDataLoader = useWorksetListDataLoader({ orgId: null, permMoreThanEditor: true, sortBy: null })

  const { Component: PickerTableComponent, props: pickerTableProps, pick: pickWorksetsToShare } = usePickerTableModal<
    GQLWorkset,
    IWorksetListableOption
  >({
    keyExtractor: v => v.wsId,
    dataLoader: showInOrg ? worksetInOrgListDataLoader : worksetListDataLoader,
    size: ODModalSize.XLarge,
    rounded: false,
    pickDefaultOptions: {
      confirmButtonTitle: 'Next',
      title: 'Select Worksets',
    },
    singleSelection: false,
    numColumns: 6,
    renderTH: index => {
      switch (index) {
        case 0:
          return 'Workset name'
        case 1:
          return 'Items'
        case 2:
          return 'Last item added'
        case 3:
          return 'Creator'
        case 4:
          return 'Organization'
        case 5:
          return 'Created'
        default:
          return '--'
      }
    },
    renderTD: (index, v) => {
      switch (index) {
        case 0:
          return v.name
        case 1:
          return v.numItems.toLocaleString()
        case 2:
          return v.lastAddedAt ? moment(v.lastAddedAt).format('YYYY-MM-DD HH:mm:ss') : '-'
        case 3:
          const owner = v.members.find(v => v.permLevel === WORKSET_USER_PERM.Owner)
          return <div>{owner?.user?.name || '-'}</div>
        case 4:
          return <div>{v.organization.name}</div>
        case 5:
          return v.createdAt ? moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'
        default:
          return '--'
      }
    },
  })

  const handleMultipleWorksetShare = async () => {
    const selectedWorksets = await pickWorksetsToShare({
      showSearch: true,
      searchPlaceholder: 'Search',
      returnFullValue: true,
    })

    if (!selectedWorksets) {
      return
    }

    setWorksetsToShare(selectedWorksets as GQLWorkset[])
  }

  return (
    <Container
      fluid
      style={{
        backgroundColor: 'white',
        paddingTop: 18,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 20,
        borderBottom: '1px solid #c8ced3',
      }}
    >
      <BlockingLoadBox show={isLoading} />
      <PickerTableComponent {...pickerTableProps} />
      <ODModalEditWorkset
        isDelete={false}
        title="Add Workset"
        onSave={handleAddWorksetModal}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        handleRemoveWorkset={() => null}
      />
      {worksetsToShare && (
        <MultiWorksetShareModal
          worksets={worksetsToShare}
          isOpen={!!worksetsToShare}
          onClose={() => setWorksetsToShare(null)}
        />
      )}
      {!!shareModalOpenForId && (
        <ODModalShare
          isOpen
          setIsOpen={() => setShareModalOpenForId(null)}
          wsId={shareModalOpenForId}
          title={shareTitle}
        />
      )}
      <div>
        <Row>
          <Col md={5} className="vcenter">
            <ODSearchBox
              placeholder="Search by name."
              value={keyword}
              onChange={handleChange}
              style={{ maxWidth: 602, paddingTop: 12 }}
            />
          </Col>
          <Col md={7} className="vcenter">
            <RowWrapper style={{ justifyContent: 'flex-end' }}>
              <SortByText style={{ marginRight: 16 }}>Sort by</SortByText>
              <SortByWorksetDropdown
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                listableContext={listableContext}
              />
              {/*<div*/}
              {/*  style={{*/}
              {/*    display: 'flex',*/}
              {/*    borderRadius: 4,*/}
              {/*    backgroundColor: '#f0f3f5',*/}
              {/*    marginLeft: 14,*/}
              {/*    width: 100,*/}
              {/*    height: 34,*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <React.Fragment>*/}
              {/*    <OptionButton*/}
              {/*      type="radio"*/}
              {/*      name={'TagView'}*/}
              {/*      id={WORKSET_VIEW_TYPE.Grid}*/}
              {/*      checked={selectedViewType === WORKSET_VIEW_TYPE.Grid}*/}
              {/*      onChange={() => setSelectedViewType(WORKSET_VIEW_TYPE.Grid)}*/}
              {/*    />*/}
              {/*    <label htmlFor={WORKSET_VIEW_TYPE.Grid}>*/}
              {/*      <ODIcon icon={ODIcons.CoreViewModule} style={{ fontSize: 18 }} />*/}
              {/*    </label>*/}
              {/*  </React.Fragment>*/}
              {/*  <React.Fragment>*/}
              {/*    <OptionButton*/}
              {/*      type="radio"*/}
              {/*      name={'TagView'}*/}
              {/*      id={WORKSET_VIEW_TYPE.List}*/}
              {/*      checked={selectedViewType === WORKSET_VIEW_TYPE.List}*/}
              {/*      onChange={() => setSelectedViewType(WORKSET_VIEW_TYPE.List)}*/}
              {/*    />*/}
              {/*    <label htmlFor={WORKSET_VIEW_TYPE.List}>*/}
              {/*      <ODIcon icon={ODIcons.MaterialViewList} style={{ fontSize: 18 }} />*/}
              {/*    </label>*/}
              {/*  </React.Fragment>*/}
              {/*</div>*/}
              <AddWorksetButton onClick={handleMultipleWorksetShare}>
                <AddWorksetButtonText>Share</AddWorksetButtonText>
              </AddWorksetButton>
              {canAddWorkset && (
                <AddWorksetButton onClick={() => setModalOpen(true)}>
                  <AddWorksetButtonText>Create Workset</AddWorksetButtonText>
                </AddWorksetButton>
              )}
            </RowWrapper>
          </Col>
        </Row>
      </div>
    </Container>
  )
}
