import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { NoImageDiv } from '../NoImageDiv'

type ODImageCardContentsProps = {
  title: string
  numItems: number
  color: string
  link: string
  images: Array<string>
}

type ODImageCardProps = {
  size?: number
  space?: number
  contents: Array<ODImageCardContentsProps>
}

type BoxOfBoxesProps = {
  size: number
  space: number
}

type BoxProps = {
  size: number
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 269px;
  width: 286px;
  border-radius: 3px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  padding: 9px;
  margin-right: 14px;
  margin: 10px;
`

const ImageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const cols = 3
const rows = 2

const BoxOfBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${(p: BoxOfBoxesProps) => p.size * cols + p.space * cols}px;
  height: ${(p: BoxOfBoxesProps) => p.size * rows + p.space * rows}px;
  margin: 0;
  padding: 0;

  div:not(last-child) {
    margin-right: ${(p: BoxOfBoxesProps) => p.space}px;
  }
  img:not(last-child) {
    margin-right: ${(p: BoxOfBoxesProps) => p.space}px;
  }
`

const Box = styled.img`
  width: ${(p: BoxProps) => p.size}px;
  height: ${(p: BoxProps) => p.size}px;
  background-color: #ececec;
`

const TagTitle = styled.span`
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #2e343a;
`

const TagItems = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #73818f;
  margin-top: 4px;
`

export const ODCategoryCard: React.FC<ODImageCardProps> = props => {
  const { size = 88, space = 2, contents } = props
  const boxProps = { size, space }

  return (
    <>
      {contents.map((v, i) => {
        return (
          <Link key={i} to={v.link} style={{ textDecoration: 'none' }}>
            <Wrapper key={i} style={{ borderBottom: `3px solid ${v.color}` }}>
              <ImageBox>
                <BoxOfBoxes {...boxProps}>
                  {v.images.map((link, i) => {
                    return <Box src={link} key={i} size={size} />
                  })}
                  {v.images.length === 0 && <NoImageDiv />}
                </BoxOfBoxes>
              </ImageBox>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                  marginLeft: 7,
                  marginTop: 15,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: 27,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: 18,
                      height: 18,
                      borderRadius: 36,
                      backgroundColor: v.color,
                    }}
                  />
                  <TagTitle style={{ marginLeft: 15, marginTop: 4 }}>{v.title}</TagTitle>
                </div>
                <TagItems style={{ marginLeft: 34 }}>
                  {v.numItems} item{v.numItems > 0 ? 's' : ''}
                </TagItems>
              </div>
            </Wrapper>
          </Link>
        )
      })}
    </>
  )
}
