import React, { KeyboardEventHandler, useEffect, useState } from 'react'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import styled from 'styled-components'
import { ODColors } from '../global-styles'
import { DropButton } from './EditDropDownComponent'
import { NoResultDropDown } from './NoResultDropDown'
import { ODIcon, ODIcons } from './ODIcon'
import { OptionValue, OptionWrapper } from './SortByDropDownComponent'

const ODDropdownItemWithSearch = styled.span`
  padding-left: 8px;
  padding-bottom: 7px;
  padding-top: 7px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
  cursor: pointer;

  &:hover {
    background-color: ${ODColors.Primary};
    color: #ffffff !important;
  }
`

export type ODDropdownItem = {
  id: string | number
  text: string
}

type Props<T extends ODDropdownItem> = {
  list: Array<T>
  onChange: (value: T) => void
  textNotSelected: string
  showFilterBox?: boolean
  filterBoxPlaceholder?: string
  renderItem: (value: T, onClick: (value: T) => void) => React.ReactNode
  filterAlgorithm?: (item: T, keyword: string) => boolean
}

type DropdownFilterBoxProps = {
  placeholder: string
  keyword: string
  setKeyword: (v: string) => void
  onKeyPress?: KeyboardEventHandler
}

export const DropdownFilterBox: React.FC<DropdownFilterBoxProps> = ({
  placeholder,
  keyword,
  setKeyword,
  onKeyPress,
}) => {
  return (
    <FormGroup>
      <InputGroup size="normal">
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{ backgroundColor: 'transparent', borderRightColor: 'transparent' }}>
            <ODIcon icon={ODIcons.CoreSearch} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          onKeyPress={onKeyPress}
          type="search"
          placeholder={placeholder}
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
          style={{ borderLeftColor: 'transparent' }}
        />
      </InputGroup>
    </FormGroup>
  )
}

const defaultFiltering = <T extends ODDropdownItem>(item: T, keyword: string) =>
  item.text.toLowerCase().includes(keyword.toLowerCase())

export const renderDropdownItemNoFilter = <T extends ODDropdownItem>(v: T, onClick: (item: T) => void) => {
  return (
    <OptionWrapper key={v.id} onClick={() => onClick(v)}>
      <OptionValue>{v.text}</OptionValue>
    </OptionWrapper>
  )
}

export const renderDropdownItemWithSearchFilter = <T extends ODDropdownItem>(
  v: T,
  onClick: (item: T) => void,
  style?: object
) => {
  return (
    <ODDropdownItemWithSearch key={v.id} onClick={() => onClick(v)} style={style}>
      {v.text}
    </ODDropdownItemWithSearch>
  )
}

export function ODDropdownComponent<T extends ODDropdownItem>(props: Props<T>) {
  const {
    list,
    onChange,
    textNotSelected,
    showFilterBox,
    filterBoxPlaceholder: placeholder = '',
    renderItem,
    filterAlgorithm = defaultFiltering,
  } = props

  const [value, setValue] = useState<T | null>(null)
  const [keyword, setKeyword] = React.useState('')
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [filteredList, setFilteredList] = React.useState<Array<T>>(list)

  useEffect(() => {
    if (keyword === '') {
      setFilteredList(list)
    } else {
      const results = list.filter(item => filterAlgorithm(item, keyword))
      setFilteredList(results)
    }
  }, [keyword, list, filterAlgorithm])

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const onClick = (v: T) => {
    onChange(v)
    setValue(v)
    toggle()
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      if (filteredList[0]) {
        onClick(filteredList[0])
      }
    }
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="div">
        <DropButton>
          {value === null && (
            <>
              {textNotSelected}
              <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
            </>
          )}
          {value !== null && <OptionValue>{value.text}</OptionValue>}
        </DropButton>
      </DropdownToggle>
      <DropdownMenu>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: showFilterBox ? 8 : 0 }}>
          {showFilterBox && (
            <DropdownFilterBox
              placeholder={placeholder}
              keyword={keyword}
              setKeyword={setKeyword}
              onKeyPress={handleKeyPress}
            />
          )}
          {filteredList.length === 0 && <NoResultDropDown />}
          {filteredList.map(v => {
            return renderItem(v, onClick)
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}
