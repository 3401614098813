import {
  DEFAULT_ORG_USER_PERM_SCHEMA,
  ORG_USER_PRIV,
  populateAgentPropsInput,
  USER_PRIV,
  USER_PRIV_TO_STRING,
  UserPropsInputValidation,
} from '@cils/common'
import { useCounter } from '@odc/od-react-belt'
import * as React from 'react'
import { Button, Col, FormGroup, Label } from 'reactstrap'
import styled from 'styled-components'
import * as Yup from 'yup'
import { GQLAddUserInput, GQLAddUserResponse, GQLSingleIDInput, GQLUser } from '../../../@types/server'
import { OrgSelect, OrgSelectTheme } from '../../../components/CommonComponents/OrgSelect'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useModalSelect } from '../../../components/ODModal/ODModalSelector'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { ODColors } from '../../../styles/ODColors'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type UserEditContainerProps = {
  idEditing?: number
}

const AssignAsAdmin = styled.span`
  font-size: 14px;
  color: #73818f;
`

const USER_FULL_SNAPSHOT = `
  userId
  name
  email
  userPriv
  organizations {
    orgUserPriv
    org {
      name
    }
  }
`

const GQL_CREATE = `
mutation addUser($data: AddUserInput!) {
  addUser(data: $data) {
    password
  }
}
`

const GQL_GET = `
query getUserOfId($data: SingleIDInput!) {
  getUserOfId(data: $data) {
    ${USER_FULL_SNAPSHOT}
  }
}
`

type PropsInput = Partial<GQLAddUserInput>
type Entity = GQLUser

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: UserPropsInputValidation.name.required('Name is required.'),
    email: UserPropsInputValidation.email.required('Login email is required.'),
  })
}

export const UserEditContainer: React.FC<UserEditContainerProps> = props => {
  const { idEditing } = props
  const apiCreate = useODMutation<Partial<PropsInput>, GQLAddUserResponse>(GQL_CREATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const selectedOrg = React.useRef<number>(0)
  const selectedOrgPriv = React.useRef<ORG_USER_PRIV>(ORG_USER_PRIV.Normal)
  const selectedPriv = React.useRef<number>(USER_PRIV.Normal)
  const [token, refresh] = useCounter()

  const { Component, choose, props: componentProps } = useModalSelect({
    okTitle: 'OK',
    selects: [
      {
        title: 'Researcher',
        description:
          'Researchers belong to the Organization and can upload or download data according to their permission within the Organization.',
        value: ORG_USER_PRIV.Normal,
      },
      {
        title: 'CILS Administrator',
        description: 'CILS Administrator can change system settings, user permission, organization, etc.',
        value: ORG_USER_PRIV.SystemAdmin,
      },
    ],
    title: 'Change User Permission',
  })

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          return {
            id: null,
            name: '',
            email: '',
            username: '',
            userPriv: USER_PRIV.Normal,
            mainOrgId: null,
            orgUserPriv: ORG_USER_PRIV.Normal,
            permSchema: JSON.stringify(DEFAULT_ORG_USER_PERM_SCHEMA),
          }
        }

        // Creation only for now.
        return {
          id: data.userId,
          username: data.username || '',
          name: data.name || '',
          email: data.email || '',
          orgUserPriv: data.priv,
          mainOrgId: data.mainOrgId,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        const { password } = await apiCreate({
          email: data.email,
          name: data.name,
          username: data.username || '',
          priv: selectedPriv.current,
          orgId: selectedOrg.current,
          orgUserPriv: selectedOrgPriv.current,
          permSchema: JSON.stringify(DEFAULT_ORG_USER_PERM_SCHEMA),
        })
        Utils.showSuccess('Added a user.', 'Success')
        alert(`Generated password : \n\n${password}`)
        return SiteUrls.Admin.User.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateAgentPropsInput,
      deleteItem: async () => {
        // if (idEditing) {
        //   await apiRemove({ id: idEditing })
        //   Utils.showSuccess('Removed a category.', 'Success')
        // }
        return SiteUrls.Admin.User.List
      },
    }),
    [idEditing, apiCreate, apiGet]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New User' : 'Edit User'

  const handleChangeUserPriv = async () => {
    const selected = await choose(selectedPriv.current)
    if (selected) {
      selectedPriv.current = selected
      refresh()
    }
  }

  const isSystemAdmin = selectedOrgPriv.current === ORG_USER_PRIV.SystemAdmin

  return (
    <Provider title={title}>
      <Component {...componentProps} />
      <ODEntityInput name="username" label="ID" placeholder="Login ID for this account." inputType="text" />
      <ODEntityInput name="name" label="Name" placeholder="Researcher name" inputType="text" />
      <ODEntityInput name="email" label="Email" placeholder="Researcher email" inputType="text" />
      <FormGroup row>
        <Col md="3">
          <Label style={{ paddingTop: 7 }}>Password</Label>
        </Col>
        <Col xs="12" md="9">
          <div style={{ display: 'flex', color: ODColors.Primary, fontSize: 12, paddingTop: 7, alignItems: 'center' }}>
            <ODIcon icon={ODIcons.MaterialError} style={{ transform: 'scale(0.8)' }} />
            Automatically generate a password and send it by email
          </div>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="3">
          <Label style={{ paddingTop: 7 }}>CILS User Permission</Label>
        </Col>
        <Col xs="12" md="9">
          <div style={{ display: 'flex', color: '#73818f', fontSize: 14, paddingTop: 7, alignItems: 'center' }}>
            {USER_PRIV_TO_STRING[selectedPriv.current]}
          </div>
          <div style={{ marginTop: 15 }}>
            <Button type="button" color="primary" outline onClick={handleChangeUserPriv} style={{ minWidth: 135 }}>
              Change
            </Button>
          </div>
        </Col>
      </FormGroup>
      <hr />
      <FormGroup row>
        <Col md="3">
          <Label style={{ paddingTop: 7 }}>Organization</Label>
        </Col>
        <Col xs="12" md="9">
          <OrgSelect
            theme={OrgSelectTheme.UserEdit}
            selected={selectedOrg.current}
            onChange={org => {
              selectedOrg.current = org.orgId
              refresh()
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="3">
          <Label style={{ paddingTop: 7 }}>Organization System Admin</Label>
        </Col>
        <Col xs="12" md="9">
          <div style={{ display: 'flex', color: '#73818f', fontSize: 14, paddingTop: 7, alignItems: 'center' }}>
            <input
              type="checkbox"
              value={isSystemAdmin ? 1 : 0}
              checked={isSystemAdmin}
              onChange={() => {
                selectedOrgPriv.current = isSystemAdmin ? ORG_USER_PRIV.Normal : ORG_USER_PRIV.SystemAdmin
                refresh()
              }}
              style={{ marginRight: 10, paddingTop: 2 }}
            />
            <AssignAsAdmin>Assign as System Administrator</AssignAsAdmin>
          </div>
        </Col>
      </FormGroup>
      <input type="hidden" value={token} />
      <ODEntityEditorFooter saveButtonName="Save" context={Context} disabled={selectedOrg.current === 0} />
    </Provider>
  )
}
