import React, { useRef, useState } from 'react'
import Select from 'react-select'
import { FormGroup, Input, Label } from 'reactstrap'
import styled from 'styled-components'
import { CategoryOption } from '../../context/ODCILSHooks'
import { Utils } from '../../utils'
import { ODButton, ODButtonTheme } from '../ODButton'
import { EDIT_TYPE } from '../ODImageGrid/ImageAction'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`
const CustomSelectBox = styled(Select)`
  width: 424px;
  margin-top: 9px;
`
const SelectWrapper = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
`
const SelectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SelectableItemTitle = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 4px;
  margin-bottom: 14px;
  color: #2e343a;
`
const SelectableItemDescription = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f353a;
`
const DescText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #73818f;
`

type ODModalCategoryInBulkProps = {
  onClose: () => void
  onSave: (v: { categoryId: number; editType: EDIT_TYPE }) => Promise<void>
  title: string
  categories: CategoryOption[]
}

export const ODModalCategoryInBulk: React.FC<ODModalCategoryInBulkProps> = props => {
  const { onSave, title, onClose, categories } = props

  const [category, setCategory] = useState<CategoryOption | null>(null)
  const [type, setType] = useState<EDIT_TYPE>(EDIT_TYPE.add)
  const selectFocus = useRef<HTMLInputElement>(null)

  const handleModalClose = async (v: { category: CategoryOption | null; type: EDIT_TYPE }) => {
    if (!v.category || !v.type) {
      Utils.showError('전부 선택해주세요.')
      return
    }

    await onSave({ categoryId: v.category.categoryId, editType: type })
    onClose()
  }

  return (
    <ODModal isOpen toggle={() => null}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={title} onClose={onClose} />
        <ODModalBody>
          <Title>Category</Title>
          <CustomSelectBox
            name="category"
            options={categories}
            value={category}
            onChange={(e: React.SetStateAction<CategoryOption | null>) => setCategory(e)}
            ref={selectFocus}
          />
          <div style={{ display: 'flex', marginBottom: 33, marginTop: 8 }}>
            <DescText>Only one category allowed</DescText>
          </div>
          <Title>Edit Type</Title>
          <SelectsWrapper style={{ marginTop: 20 }}>
            <SelectWrapper>
              <FormGroup check className="radio">
                <Input
                  className="form-check-input"
                  type="radio"
                  id="add"
                  name="editType"
                  value={EDIT_TYPE.add}
                  checked={type === EDIT_TYPE.add}
                  onChange={() => setType(EDIT_TYPE.add)}
                />
                <Label check className="form-check-label" htmlFor="editType">
                  <SelectableItemTitle>Add Category</SelectableItemTitle>
                  <SelectableItemDescription>Keep current category</SelectableItemDescription>
                </Label>
              </FormGroup>
            </SelectWrapper>
            <SelectWrapper>
              <FormGroup check className="radio">
                <Input
                  className="form-check-input"
                  type="radio"
                  id="replace"
                  name="editType"
                  value={EDIT_TYPE.replace}
                  checked={type === EDIT_TYPE.replace}
                  onChange={() => setType(EDIT_TYPE.replace)}
                />
                <Label check className="form-check-label" htmlFor="editType">
                  <SelectableItemTitle>Replace Category</SelectableItemTitle>
                </Label>
              </FormGroup>
            </SelectWrapper>
          </SelectsWrapper>
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            theme={ODButtonTheme.Primary}
            onClick={() => handleModalClose({ category, type })}
            style={{ width: '100%' }}
          >
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
