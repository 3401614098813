import { makeObservable, observable, runInAction } from 'mobx'
import { DOMetaDataEditor } from './DOMetaDataEditor'
import { IDOMetaDataInput, MetaDataInputState } from './IDOMetaDataInput'
import { IMetaData } from './IMetaData'

export class DOMetaDataInput implements IDOMetaDataInput {
  editor: DOMetaDataEditor
  key: string = ''
  columnName: string = ''
  state: MetaDataInputState = MetaDataInputState.Created
  timeout: NodeJS.Timeout | null = null

  constructor(editor: DOMetaDataEditor, data?: IMetaData) {
    this.editor = editor
    this.key = data?.key ?? ''
    this.columnName = data?.columnName ?? ''
    if (data) {
      this.state = MetaDataInputState.Edited
    }

    makeObservable(this, {
      editor: observable,
      key: observable,
      columnName: observable,
      state: observable,
      timeout: observable,
    })
  }

  clearTimeout() {
    if (this.timeout) {
      runInAction(() => {
        clearTimeout(this.timeout as NodeJS.Timeout)
        this.timeout = null
      })
    }
  }

  autoMangeAfterChange() {
    runInAction(() => {
      this.timeout = setTimeout(() => {
        this.state = MetaDataInputState.Edited
        this.editor.autoRemoveInput()
        this.editor.autoAddInput()
      }, 300)
    })
  }

  setKey(key: string) {
    runInAction(() => {
      this.clearTimeout()

      this.key = key
      this.state = MetaDataInputState.Editing

      this.autoMangeAfterChange()
    })
  }

  setColumnName(name: string) {
    runInAction(() => {
      this.clearTimeout()

      this.columnName = name
      this.state = MetaDataInputState.Editing

      this.autoMangeAfterChange()
    })
  }
}
