import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface FlexContentsContainerProps {
  row?: boolean // default is column, determines flex-direction.
  style?: CSSProperties
}

export const FlexContentsContainer: React.FC<FlexContentsContainerProps> = styled.div`
  display: flex;
  flex-direction: ${(p: FlexContentsContainerProps) => (p.row ? 'row' : 'column')};
  flex-grow: 99;
`
