import React, { useState } from 'react'
import { GQLProgressWithWorkset } from '../../../agent'
import {
  DropButton,
  DropDown,
  DropDownContent,
  OptionWrapper,
  Value,
} from '../../../components/Dropdown/DropdownCommon'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { ODListableContextType } from '../../../ODListable/ODListableContext'
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick'
import { AgentProgressSort, IAgentProgressListableOption } from './common'

interface IAgentUploadDownloadListSortDropdownProps {
  isMenuOpen: boolean
  setIsMenuOpen: (isOpen: boolean) => void
  listableContext: React.Context<ODListableContextType<GQLProgressWithWorkset, IAgentProgressListableOption>>
}

const SORT_OPTIONS = [AgentProgressSort.StartedAtAscending, AgentProgressSort.StartedAtDescending]

const SORT_OPTIONS_RENDERED: { [key: string]: JSX.Element } = {
  [AgentProgressSort.StartedAtDescending]: (
    <Value>
      Recent first <ODIcon icon={ODIcons.MaterialArrowDownwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
  [AgentProgressSort.StartedAtAscending]: (
    <Value>
      Older first <ODIcon icon={ODIcons.MaterialArrowUpwardIcon} style={{ fontSize: 14, color: '#29b6ca' }} />
    </Value>
  ),
}

export const AgentUploadDownloadListSortDropdown: React.FC<IAgentUploadDownloadListSortDropdownProps> = props => {
  const { isMenuOpen, setIsMenuOpen, listableContext } = props

  const {
    state: { loadOption },
    updateLoadOption,
  } = React.useContext(listableContext)

  const [value, setValue] = useState<AgentProgressSort>(loadOption.sortOrder ?? AgentProgressSort.StartedAtDescending)
  const menuRef = React.useRef<HTMLDivElement>(null)

  useDetectOutsideClick(() => setIsMenuOpen(false), menuRef, isMenuOpen)

  const onClick = (v: AgentProgressSort) => {
    // @ts-ignore
    updateLoadOption({ ...loadOption, sortOrder: v })
    setValue(v)
    setIsMenuOpen(false)
  }

  return (
    <DropDown ref={menuRef}>
      <DropButton onClick={() => (isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true))}>
        {SORT_OPTIONS_RENDERED[value]}
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isMenuOpen ? 'flex' : 'none' }}>
        {SORT_OPTIONS.map(op => (
          <OptionWrapper key={op} onClick={() => onClick(op)}>
            {SORT_OPTIONS_RENDERED[op]}
          </OptionWrapper>
        ))}
      </DropDownContent>
    </DropDown>
  )
}
