import React from 'react'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { GQLListableProgressWithWorkset, GQLListableProgressWithWorksetInput } from '../../../agent'
import { BlockingLoadBox } from '../../../components/BlockingLoadBox'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import { useODQuery2 } from '../../../context/ODCommon'
import { Utils } from '../../../utils'
import { CannotConnectAgent } from './CannotConnectAgent'
import { GQL_LIST_PROGRESS_WITH_WORKSET, SelectedWorkset } from './common'
import { SyncAgentContainer } from './SyncAgentContainer'

type SyncRootContainerProps = {}

export const SyncRootContainer: React.FC<SyncRootContainerProps> = props => {
  const {
    state: { connected, loginInfo },
  } = useCILSAgentContext()

  const [wsId, setWsId] = useQueryParam('worksetId', withDefault(NumberParam, undefined))
  const [projectId, setProjectId] = useQueryParam('projectId', withDefault(NumberParam, undefined))
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedWorkset, _setSelectedWorkset] = React.useState<SelectedWorkset | undefined>()
  const setSelectedWorkset = React.useCallback(
    (selectedWorkset: SelectedWorkset | undefined) => {
      if (selectedWorkset?.isProject) {
        setProjectId(selectedWorkset?.worksetId)
        setWsId(undefined)
      } else {
        setWsId(selectedWorkset?.worksetId)
        setProjectId(undefined)
      }
    },
    [setWsId, setProjectId]
  )

  const { client } = useCILSAgentContext()
  const { api: apiList } = useODQuery2<GQLListableProgressWithWorksetInput, GQLListableProgressWithWorkset>(
    GQL_LIST_PROGRESS_WITH_WORKSET,
    { client, skip: true, pickFirstKey: true }
  )
  // const apiList = useAgentProgressDataAPI()

  const getSelectedWorkset = React.useCallback(
    async (wsId: number, isProject?: boolean) => {
      try {
        setIsLoading(true)
        let res = await apiList({ isDownload: true, page: 1, pageSize: 100, sortOrder: null })
        if (!res) {
          // 이건 apollo client 의 버그이거나 뭔가 설정이 잘못된 듯..
          await new Promise(resolve => setTimeout(resolve, 1000))
          res = await apiList({ isDownload: true, page: 1, pageSize: 100, sortOrder: null })
          if (!res) {
            return
          }
        }

        const wp = res.list.find(i => {
          if (isProject) {
            return i.isProject && i.worksetProgress!.worksetId === wsId
          }
          return i.isWorkset && i.worksetProgress!.worksetId === wsId
        })

        if (!wp) {
          const infoName = isProject ? 'project' : 'workset'
          Utils.showError(`Cannot get ${infoName} information from the agent.`)
          setWsId(undefined)
          setProjectId(undefined)
          setIsLoading(false)
          return
        }

        _setSelectedWorkset({
          worksetId: wp.worksetProgress!.worksetId,
          worksetName: wp.worksetProgress!.worksetName,
          worksetDirName: wp.worksetProgress!.itemFolderName,
        })
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setIsLoading(false)
      }
    },
    [apiList]
  )

  React.useEffect(() => {
    if (connected) {
      if (wsId) {
        // noinspection JSIgnoredPromiseFromCall
        getSelectedWorkset(wsId)
      } else if (projectId) {
        // noinspection JSIgnoredPromiseFromCall
        getSelectedWorkset(projectId, true)
      } else {
        _setSelectedWorkset(undefined)
      }
    }
  }, [wsId, projectId, connected, getSelectedWorkset])

  if (!connected || !loginInfo) {
    return <CannotConnectAgent />
  }

  return (
    <>
      <BlockingLoadBox show={isLoading} />
      <SyncAgentContainer selectedWorkset={selectedWorkset} setSelectedWorkset={setSelectedWorkset} />
      {/*{loginInfo?.isAgentAccount && (*/}
      {/*  <SyncAgentContainer selectedWorkset={selectedWorkset} setSelectedWorkset={setSelectedWorkset} />*/}
      {/*)}*/}
      {/*{!loginInfo?.isAgentAccount && (*/}
      {/*  <SyncDownloaderContainer selectedWorkset={selectedWorkset} setSelectedWorkset={setSelectedWorkset} />*/}
      {/*)}*/}
    </>
  )
}
