import React from 'react'
import styled from 'styled-components'
import { GQLUser } from '../../../@types/server'
import { ODButton, ODButtonSize, ODButtonTheme } from '../../../components/ODButton'
import { ODEntityTextLabel } from '../../../ODEntityEditor/FormComponents/ODEntityTextLabel'
import { ODModalUserPicker } from './ODModalUserPicker'

type GroupUserPickerProps = {
  orgId: number
  members: Array<GQLUser>
  setMembers: (owners: Array<GQLUser>) => any
  isPickerOpen: boolean
  setIsPickerOpen: (v: boolean) => any
}

const UserRow = styled.div`
  margin-bottom: 15px;
`

const UserListWrapper = styled.div`
  margin-top: 8px;
`

export const GroupUserPicker: React.FC<GroupUserPickerProps> = ({
  orgId,
  members,
  setMembers,
  isPickerOpen,
  setIsPickerOpen,
}) => {
  return (
    <ODEntityTextLabel name="groupMember" label="Group Member">
      {isPickerOpen && (
        <ODModalUserPicker
          orgId={orgId}
          initialUsers={members}
          isOpen={isPickerOpen}
          setIsOpen={setIsPickerOpen}
          title="Manage Members"
          placeholder="Search by name or email"
          bottomDescription="Only users in this organization can be added."
          buttonName="Done"
          onConfirm={(users: Array<GQLUser>) => {
            setMembers(users)
            setIsPickerOpen(false)
          }}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <UserListWrapper>
          {members.map(user => (
            <UserRow key={user.userId}>
              {user.name} ({user.email})
            </UserRow>
          ))}
          {members.length === 0 && <span>No users in the Group.</span>}
        </UserListWrapper>
        <div style={{ height: 20 }} />
        <ODButton
          theme={ODButtonTheme.PrimaryInvert}
          size={ODButtonSize.Small}
          onClick={() => setIsPickerOpen(true)}
          style={{ height: 35 }}
        >
          Manage members
        </ODButton>
      </div>
    </ODEntityTextLabel>
  )
}
