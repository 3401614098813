import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from './ODIcon'

const Text = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #29b6ca;
  margin-top: 4px;
`

export const NoResultDropDown: React.FC = props => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 109 }}
    >
      <ODIcon icon={ODIcons.CoreFrown} style={{ fontSize: 26, color: '#29b6ca' }} />
      <Text>No Result</Text>
    </div>
  )
}
