import * as React from 'react'
import styled from 'styled-components'

type ODModalBodyProps = {
  style?: object
}

type P = {
  contentsLRPadding: string
  contentsBottomPadding: string
}

const BodyWrapper = styled.div`
  padding-left: ${(p: P) => p.contentsLRPadding};
  padding-right: ${(p: P) => p.contentsLRPadding};
  padding-bottom: ${(p: P) => p.contentsBottomPadding};
`

const defaultODModalBodyProps: P = {
  contentsLRPadding: '37px',
  contentsBottomPadding: '44px',
}

export const ODModalBody: React.FC<ODModalBodyProps> = ({ style = {}, children }) => {
  const p = defaultODModalBodyProps
  return (
    <BodyWrapper {...p} style={style}>
      {children}
    </BodyWrapper>
  )
}
