import React from 'react'
import styled from 'styled-components'
import { ThumbnailInfo } from '../containers/User/DetailContainerComponent/convertItemAttachmentToThumbnailInfo'
import { ODIcon, ODIcons } from './ODIcon'

type ItemAttachmentImageProps = {
  isSelected: boolean
  item: ThumbnailInfo
  onClick: (item: ThumbnailInfo) => void
  size?: number
}

interface DummyMiniImageProps {
  isSelected: boolean
  size: number
}

const DummyMiniImage = styled.img`
  display: flex;
  flex: 0 0 auto;
  width: ${(p: DummyMiniImageProps) => p.size}px;
  height: ${(p: DummyMiniImageProps) => p.size}px;
  margin-right: 15px;
  border: ${(p: DummyMiniImageProps) => (p.isSelected ? 'solid 4px #29b6ca' : '')};
  background-color: #efefef;
  cursor: pointer;
`
const NoImage = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: ${(p: DummyMiniImageProps) => p.size}px;
  height: ${(p: DummyMiniImageProps) => p.size}px;
  background-color: #2e343a;
  cursor: pointer;
`

const imageExts = ['png', 'jpg', 'jpeg']

const isImage = (v: ThumbnailInfo) => {
  const comps = (v.fileName || '').split('.')
  const ext = comps[comps.length - 1].toLowerCase()
  if (imageExts.includes(ext)) {
    return true
  }

  const img = new Image()
  img.src = v.link
  return img.complete && img.height > 0
}

export const ItemAttachmentImage: React.FC<ItemAttachmentImageProps> = props => {
  const { item: v, onClick, isSelected, size = 79 } = props

  if (!isImage(v)) {
    return (
      <NoImage
        isSelected={isSelected}
        size={size}
        style={{ marginRight: 15, boxShadow: isSelected ? '0 0 0 4px #29b6ca inset' : '' }}
        onClick={() => onClick(v)}
      >
        <ODIcon icon={ODIcons.CoreLink} style={{ fontSize: 26, opacity: 0.5, color: '#708393' }} />
      </NoImage>
    )
  }

  return (
    <DummyMiniImage
      size={size}
      // ref={thumbnailImageRef}
      src={v.link}
      onClick={() => onClick(v)}
      isSelected={isSelected}
    />
  )
}
