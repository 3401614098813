import { PROJECT_STATUS_OPTION } from '@cils/common'
import { useDetectOutsideClick } from '@odc/od-react-belt'
import React, { useState } from 'react'
import { GQLProject } from '../../../@types/server'
import { IProjectListableOption } from '../../../containers/User/Project/ProjectCommon'
import { ODListableContextType } from '../../../ODListable/ODListableContext'
import { DropButton, DropDown, DropDownContent, OptionWrapper, Value } from '../../Dropdown/DropdownCommon'
import { ODIcon, ODIcons } from '../../ODIcon'
import { DROPDOWN_MENU, DropDownMenuProps } from '../../ODImageGrid/ODImageGridSearchToolBar'

const SortByName: { [key: string]: JSX.Element } = {
  [PROJECT_STATUS_OPTION.All]: <Value>{PROJECT_STATUS_OPTION.All}</Value>,
  [PROJECT_STATUS_OPTION.Deleted]: <Value>{PROJECT_STATUS_OPTION.Deleted}</Value>,
  [PROJECT_STATUS_OPTION.Active]: <Value>{PROJECT_STATUS_OPTION.Active}</Value>,
}

interface Props {
  listableContext: React.Context<ODListableContextType<GQLProject, IProjectListableOption>>
}

export const ProjectStatusInOrgAdminDropDown: React.FC<Props & DropDownMenuProps> = props => {
  const { isMenuOpen, setIsMenuOpen, listableContext } = props

  const [value, setValue] = useState<PROJECT_STATUS_OPTION>(PROJECT_STATUS_OPTION.All)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const isOpen = isMenuOpen === DROPDOWN_MENU.StatusByOrgAdminProjects

  const {
    state: { loadOption },
    updateLoadOption,
  } = React.useContext(listableContext)

  useDetectOutsideClick(() => setIsMenuOpen(null), menuRef, isOpen)

  const onClick = (v: PROJECT_STATUS_OPTION) => {
    // @ts-ignore
    updateLoadOption({ ...loadOption, status: v })
    setValue(v)
    setIsMenuOpen(null)
  }

  React.useEffect(() => {
    // @ts-ignore
    updateLoadOption({ ...loadOption, status: PROJECT_STATUS_OPTION.All })
  }, [])

  return (
    <DropDown ref={menuRef}>
      <DropButton
        onClick={() => (isOpen ? setIsMenuOpen(null) : setIsMenuOpen(DROPDOWN_MENU.StatusByOrgAdminProjects))}
      >
        {value ? SortByName[value] : 'Status'}
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        {Object.values(PROJECT_STATUS_OPTION).map(item => {
          return (
            <OptionWrapper key={item} onClick={() => onClick(item)}>
              <Value>{item}</Value>
            </OptionWrapper>
          )
        })}
      </DropDownContent>
    </DropDown>
  )
}
