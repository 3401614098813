import { ImageTagType, Utils as CommonUtils } from '@cils/common'
import { WORKSET_ACTION } from '@cils/common/lib/permission/worksetPermConsts'
import { useODModalConfirm } from '@odc/od-react-belt'
import { Decimal } from 'decimal.js'
import { observer } from 'mobx-react-lite'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import styled from 'styled-components'
import {
  GQLBulkAddWorksetHasItemInput,
  GQLBulkRemoveWorksetHasItemInput,
  GQLBulkUpdateCategoryInput,
  GQLBulkUpdateCategoryInWorksetInput,
  GQLBulkUpdateExplanationInput,
  GQLBulkUpdateItemHiddenInput,
  GQLBulkUpdateTagInput,
  GQLBulkUpdateTagInWorksetInput,
  GQLItem,
} from '../../../@types/server'
import { GQLOkResponse } from '../../../agent'
import { LOCAL_STORAGE_KEY_IMAGE_SIZE } from '../../../common'
import { BlockingLoadBox } from '../../../components/BlockingLoadBox'
import { NoMoreComponent } from '../../../components/NoMoreComponent'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { ActiveFilter } from '../../../components/ODImageGrid/ActiveFilter'
import { EDIT_TYPE } from '../../../components/ODImageGrid/ImageAction'
import { ODImageGrid } from '../../../components/ODImageGrid/ODImageGrid'
import { ODImageGridSearchToolBar } from '../../../components/ODImageGrid/ODImageGridSearchToolBar'
import { TCF_MAIN_VIEW_TYPE } from '../../../components/ODImageGrid/ViewToggle'
import { NotEnoughStorageBody } from '../../../components/ODModal/NotEnoughStorageBody'
import { ODModalAddToWorkset } from '../../../components/ODModal/ODModalAddToWorkset'
import { ODModalCategoryInBulk } from '../../../components/ODModal/ODModalCategoryInBulk'
import { useModalConfirm } from '../../../components/ODModal/ODModalConfirm'
import { ODModalExplanationInBulk } from '../../../components/ODModal/ODModalExplanationInBulk'
import { ODModalTagInBulk } from '../../../components/ODModal/ODModalTagInBulk'
import { ODModalWorksetGroup } from '../../../components/ODModal/ODModalWorksetGroup'
import { ODToastType, showODToast } from '../../../components/ODToast'
import { ODModalAddWorksetsToProjectResult } from '../../../components/project/modal/ODModalAddWorksetsToProjectResult'
import { SmallLoading } from '../../../components/SmallLoading'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import { useODAppContext } from '../../../context/ODAppContext'
import { useCILSCategory } from '../../../context/ODCILSHooks'
import { useODMutation } from '../../../context/ODCommon'
import { useAPIs } from '../../../context/useAPIs'
import { useWorksetPerm } from '../../../context/useWorksetPerm'
import { useCILSStore } from '../../../di/configureRootStore'
import { ODColors } from '../../../global-styles'
import { GQL_CREATE_WORKSET_HAS_ITEM_MULTIPLE } from '../../../gqls'
import { useMainServerAPI } from '../../../hooks/useMainServerAPI'
import { ODListableAutoLoader } from '../../../ODListable/ODListableAutoLoader'
import { GQLItemsListableContextType } from '../../../ODListable/ODListableContext'
import { Utils, Utils as WebUtils } from '../../../utils'
import { useContextMenu } from '../../../utils/useContextMenu'
import { useProjectSelectModal } from '../Project/useProjectSelectModal'
import { TCFDetailModalInListableContext } from '../TCFDetailModal'
import { ItemDataLoaderOption } from './ItemsContainer'

const GQL_REMOVE_WORKSET_HAS_ITEMS = `
mutation removeWorksetHasItems($data: BulkRemoveWorksetHasItemInput!) {
  removeWorksetHasItems(data: $data) {
    ok
  }
}
`

const GQL_BULK_UPDATE_CATEGORY_ITEM = `
mutation bulkUpdateCategoryItem($data: BulkUpdateCategoryInput!) {
  bulkUpdateCategoryItem(data: $data) {
    ok
  }
}
`

const GQL_BULK_UPDATE_CATEGORY_IN_WORKSET_ITEM = `
mutation bulkUpdateCategoryItemsInWorkset($data: BulkUpdateCategoryInWorksetInput!) {
  bulkUpdateCategoryItemsInWorkset(data: $data) {
    ok
  }
}
`

const GQL_BULK_UPDATE_EXPLANATION_ITEM = `
mutation bulkUpdateExplanationItem($data: BulkUpdateExplanationInput!) {
  bulkUpdateExplanationItem(data: $data) {
    ok
  }
}
`

const GQL_BULK_UPDATE_TAG_ITEM = `
mutation bulkUpdateTagItem($data: BulkUpdateTagInput!) {
  bulkUpdateTagItem(data: $data) {
    ok
  }
}
`

const GQL_BULK_UPDATE_TAG_ITEM_IN_WORKSET = `
mutation bulkUpdateTagItemsInWorkset($data: BulkUpdateTagInWorksetInput!) {
  bulkUpdateTagItemsInWorkset(data: $data) {
    ok
  }
}
`

const GQL_BULK_UPDATE_ITEMS_HIDDEN = `
mutation bulkUpdateItemHidden($data: BulkUpdateItemHiddenInput!) {
  bulkUpdateItemHidden(data: $data) {
    ok
  }
}
`

const AddAllItemsToWorksetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 375px;
  min-height: 45px;
  border-radius: 4px;
  background-color: #0d92a5;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
`
const AddAllItemsToWorksetIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 100%;
  background-color: #108090;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

type Props = {
  showToolbar: boolean
  showFastAddToWorkset?: boolean // showToolbar must be true to enable this.
  onFastAddToWorkset?: (wsIds: Array<number>, loadOption: ItemDataLoaderOption) => Promise<any>
  context: React.Context<GQLItemsListableContextType>
  inWorksetId?: number
  orgId: number
}

export enum SHOW_ADD_TO_WORKSET_TYPE {
  HIDE,
  SHOW_SELECTED,
  SHOW_ADD_BY_OPTIONS,
}

// Bulk category update 에 대한 enum
enum CATEGORY_BULK_MODAL {
  Hidden = 'Hidden',
  OpenForSelected = 'OpenForSelected',
  OpenForWorkset = 'OpenForWorkset',
}

export const ItemsList: React.FC<Props> = observer(props => {
  const { showToolbar, showFastAddToWorkset, onFastAddToWorkset, inWorksetId, context, orgId } = props
  const { currentWorksetName } = useODAppContext()
  const showTagAllItemsInThisWorkset = !!inWorksetId
  const permUtils = useWorksetPerm(inWorksetId ?? 0)
  const canChangeCategory = permUtils?.isAllowed(WORKSET_ACTION.EditMeta)
  const canTag = permUtils?.isAllowed(WORKSET_ACTION.EditMeta)
  const canDownload = permUtils?.isAllowed(WORKSET_ACTION.DownloadItem)
  const canWorksetGroup = permUtils?.isAllowed(WORKSET_ACTION.AddWorksetGroupInWorkset)
  const canAddProject = permUtils?.isAllowed(WORKSET_ACTION.AddToProject)

  const [itemOpened, setItemOpened] = React.useState<GQLItem | null>(null)
  const [lastTCFUpdated, setLastTCFUpdated] = React.useState(0) // 0 indicates it requires a new loading.
  const [imageSize, setImageSize] = React.useState<number>(200)
  const [imageViewType, setImageViewType] = React.useState<TCF_MAIN_VIEW_TYPE>('TCF')
  const [removing, setRemoving] = React.useState(false)
  const [queueDownloading, setQueueDownloading] = React.useState(false)

  const [isOpenCategoryInBulkModal, setIsOpenCategoryInBulkModal] = React.useState<CATEGORY_BULK_MODAL>(
    CATEGORY_BULK_MODAL.Hidden
  )
  const [isOpenExplanationInBulkModal, setIsOpenExplanationInBulkModal] = React.useState<boolean>(false)
  const [isOpenTagInBulkModal, setIsOpenTagInBulkModal] = React.useState<boolean>(false)
  const [isTagInBulkModalOpenedForWorkset, setIsTagInBulkModalOpenedForWorkset] = React.useState(false)
  const [isOpenAddToWorksetModal, setIsOpenAddToWorksetModal] = React.useState<SHOW_ADD_TO_WORKSET_TYPE>(
    SHOW_ADD_TO_WORKSET_TYPE.HIDE
  )
  const [isOpenWorksetGroupModal, setIsOpenWorksetGroupModal] = React.useState(false)

  const { categories: categoryOptions } = useCILSCategory(orgId)
  const {
    queueDownload,
    queueWorksetDownload,
    getFreeDiskSpaceForDownload,
    checkWorksetDownload,
  } = useCILSAgentContext()

  const { createWorksetGroupHasWorkset } = useAPIs()

  const store = useCILSStore()
  const [isOpenWorksetToProjectResult, setIsOpenWorksetToProjectResult] = React.useState(false)

  const {
    Component: PickerTableComponent,
    props: pickerTableProps,
    pick: pickWorksetsToProject,
  } = useProjectSelectModal(async (projectId, projectName) => {
    store.addWorksetToProjectStore.setProjectId(projectId)
    store.addWorksetToProjectStore.setProjectName(projectName)
    if (inWorksetId) {
      store.addWorksetToProjectStore.addWorksetIds([inWorksetId])
    }
    await store.addWorksetToProjectStore.submit()
    setIsOpenWorksetToProjectResult(true)
  })

  const handleSelectWorksetsToProject = React.useCallback(async () => {
    const title = `Select Project`
    const searchPlaceholder = 'Search by project name, username'
    try {
      await pickWorksetsToProject({
        showSearch: true,
        searchPlaceholder,
        returnFullValue: false,
        title,
      })
    } catch (ex) {
      Utils.showError(ex)
    }
  }, [pickWorksetsToProject])

  const imageGridRef = React.createRef<HTMLDivElement>()

  const {
    state: { selectedItems, loadOption, totalCount },
    removeItems,
    removeFromSelected,
    deselectAll,
    updateLoadOption,
  } = React.useContext(context)

  React.useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEY_IMAGE_SIZE)) {
      setImageSize(parseInt(localStorage.getItem(LOCAL_STORAGE_KEY_IMAGE_SIZE) as string, 10))
    }
  }, [])

  // ImageAction
  const apiAddToWorkset = useODMutation<Partial<GQLBulkAddWorksetHasItemInput>, GQLOkResponse>(
    GQL_CREATE_WORKSET_HAS_ITEM_MULTIPLE
  )
  const apiRemoveFromWorkset = useODMutation<GQLBulkRemoveWorksetHasItemInput, GQLOkResponse>(
    GQL_REMOVE_WORKSET_HAS_ITEMS
  )
  const apiBulkUpdateCategoryItem = useODMutation<Partial<GQLBulkUpdateCategoryInput>, GQLOkResponse>(
    GQL_BULK_UPDATE_CATEGORY_ITEM
  )
  const apiBulkUpdateCategoryInWorksetItem = useODMutation<Partial<GQLBulkUpdateCategoryInWorksetInput>, GQLOkResponse>(
    GQL_BULK_UPDATE_CATEGORY_IN_WORKSET_ITEM
  )

  const apiBulkUpdateExplanationItem = useODMutation<Partial<GQLBulkUpdateExplanationInput>, GQLOkResponse>(
    GQL_BULK_UPDATE_EXPLANATION_ITEM
  )
  const apiBulkUpdateTagItem = useODMutation<Partial<GQLBulkUpdateTagInput>, GQLOkResponse>(GQL_BULK_UPDATE_TAG_ITEM)
  const apiBulkUpdateTagInWorksetItem = useODMutation<Partial<GQLBulkUpdateTagInWorksetInput>, GQLOkResponse>(
    GQL_BULK_UPDATE_TAG_ITEM_IN_WORKSET
  )
  const apiBulkUpdateItemsHidden = useODMutation<Partial<GQLBulkUpdateItemHiddenInput>, GQLOkResponse>(
    GQL_BULK_UPDATE_ITEMS_HIDDEN
  )
  const { apiCalculateItemsSize } = useMainServerAPI()

  const {
    Component: RemoveFromWorksetComponent,
    confirm: removeFromWorksetConfirm,
    props: removeFromWorksetComponentProps,
  } = useModalConfirm({
    title: 'Remove items from Workset',
    message: 'Are you sure you want to remove this files from Workset?',
    yes: 'Remove',
    no: 'Cancel',
  })

  const { Component: ShowItemsComponent, confirm: showItemsConfirm, props: showItemsComponentProps } = useModalConfirm({
    title: 'Show Items',
    message: 'Show selected items?',
    yes: 'Show',
    no: 'Cancel',
  })

  const { Component: HideItemsComponent, confirm: hideItemsConfirm, props: hideItemsComponentProps } = useModalConfirm({
    title: 'Hide Items',
    message: 'Hide selected items?',
    yes: 'Hide',
    no: 'Cancel',
  })

  const handleUpdateHideItems = async (value: boolean) => {
    if (value ? await hideItemsConfirm() : await showItemsConfirm()) {
      try {
        const selectedTCF = Array.from(selectedItems)
        await apiBulkUpdateItemsHidden({ orgId, itemIds: selectedTCF.map(v => v.itemId), hidden: value })
        updateLoadOption({ ...loadOption })
        deselectAll()
        Utils.showSuccess(`Updated ${value ? 'hidden' : 'show'} items`, 'Success')
      } catch (ex) {
        Utils.showError(ex)
      }
    }
  }

  const handleWorksetSave = async (wsIds: Array<number>) => {
    if (wsIds.length === 0) {
      Utils.showInfo('No workset is selected.')
      return false
    }

    if (isOpenAddToWorksetModal === SHOW_ADD_TO_WORKSET_TYPE.SHOW_SELECTED) {
      if (selectedItems.length === 0) {
        Utils.showInfo('No TCF image is selected.')
        return false
      }

      await apiAddToWorkset({ wsIds, itemIds: selectedItems.map(v => v.itemId) })
      Utils.showSuccess(
        `Added ${selectedItems.length} item${selectedItems.length > 1 ? 's' : ''}  to ${wsIds.length} workset${
          wsIds.length > 1 ? 's' : ''
        }.`,
        'Success'
      )
    } else {
      return onFastAddToWorkset!!(wsIds, loadOption)
    }

    return true
  }

  // Ids 지만 하나씩만 저장한다.
  const handleWorksetGroupSave = async (wsgIds: number[]) => {
    if (!wsgIds || !inWorksetId) {
      return
    }

    try {
      await createWorksetGroupHasWorkset({ worksetId: inWorksetId, wsgIds })
      showODToast(ODToastType.Success, ODToastType.Success, 'Added workset to workset group.')
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  const handleRemoveFromWorkset = async () => {
    if (await removeFromWorksetConfirm()) {
      try {
        setRemoving(true)
        const selectedTCF = Array.from(selectedItems)
        await apiRemoveFromWorkset({ wsId: inWorksetId!, itemIds: selectedTCF.map(v => v.itemId) })
        removeItems(selectedTCF.map(v => v.itemId.toString()))
        removeFromSelected(selectedTCF)
        Utils.showSuccess(`Removed item${selectedTCF.length > 1 ? 's' : ''} from this workset.`, 'Success')
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setRemoving(false)
      }
    }
  }

  const {
    Component: DownloadConfirmComponent,
    confirm: confirmDownload,
    props: DownloadConfirmComponentProps,
  } = useODModalConfirm({ title: 'Not enough storage', yes: 'Download', no: 'Cancel' })

  const handleDownloadItems = async () => {
    if (selectedItems.length === 0) {
      Utils.showInfo('No items selected.')
      return
    }

    try {
      const res = await apiCalculateItemsSize({ itemIds: selectedItems.map(i => i.itemId) })
      const fullSize = new Decimal(res.totalSize)
      let availableSize = new Decimal(999999999999)

      try {
        const freeSpace = await getFreeDiskSpaceForDownload()
        availableSize = new Decimal(freeSpace.output)
      } catch (ex) {
        Utils.showError('PC 의 용량 체크에 실패하였습니다.')
        console.error(ex)
      }

      if (availableSize.lt(fullSize)) {
        if (
          !(await confirmDownload({
            message: (
              <NotEnoughStorageBody
                downloadSize={prettyBytes(fullSize.toNumber())}
                freeSpace={prettyBytes(parseInt(availableSize.toString(), 10))}
              />
            ),
          }))
        ) {
          return
        }
      }

      setQueueDownloading(true)
      let success = 0
      let failed = 0
      const increase = () => {
        success += 1
      }
      for (let i = 0; i < selectedItems.length; i += 1) {
        // eslint-disable-next-line no-loop-func
        await queueDownload({ dataId: selectedItems[i].dataId }).then(increase, ex => {
          // Utils.showError(ex)
          console.error(ex)
          failed += 1
        })
      }

      if (success > 0) {
        Utils.showSuccess(`Queued ${success} items for download.`)
      }
      if (failed > 0) {
        Utils.showError(`${failed} items failed to download. (Uploading has not been finished)`)
      }
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setQueueDownloading(false)
    }
  }

  const handleBulkUpdateCategoryItem = async (v: { categoryId: number; editType: EDIT_TYPE }) => {
    try {
      if (isOpenCategoryInBulkModal === CATEGORY_BULK_MODAL.OpenForSelected) {
        await apiBulkUpdateCategoryItem({
          orgId,
          itemIds: selectedItems.map(v => v.itemId),
          categoryId: v.categoryId,
          editType: v.editType,
        })
      } else if (isOpenCategoryInBulkModal === CATEGORY_BULK_MODAL.OpenForWorkset) {
        //
        await apiBulkUpdateCategoryInWorksetItem({
          orgId,
          wsId: inWorksetId!,
          categoryId: v.categoryId,
          editType: v.editType,
        })
      }
      Utils.showSuccess('Success', 'Category saved.')
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  const handleBulkUpdateExplanationItem = async (v: { explanation: string }) => {
    try {
      await apiBulkUpdateExplanationItem({
        orgId,
        itemIds: selectedItems.map(v => v.itemId),
        explanation: v.explanation,
      })
      Utils.showSuccess('Success', 'Explanation saved.')
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  const handleBulkUpdateTagItem = async (v: { tags: string[]; editType: EDIT_TYPE }) => {
    try {
      if (!isTagInBulkModalOpenedForWorkset) {
        await apiBulkUpdateTagItem({
          orgId,
          itemIds: selectedItems.map(v => v.itemId),
          tags: v.tags,
          editType: v.editType,
        })
      } else {
        await apiBulkUpdateTagInWorksetItem({ orgId, wsId: inWorksetId!, tags: v.tags, editType: v.editType })
      }
      Utils.showSuccess('Success', 'Tag saved.')
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  const handleAddAllItemsToWorkset = async () => {
    setIsOpenAddToWorksetModal(SHOW_ADD_TO_WORKSET_TYPE.SHOW_ADD_BY_OPTIONS)
  }

  const handleCategoryAllItemsInWorkset = async () => {
    setIsOpenCategoryInBulkModal(CATEGORY_BULK_MODAL.OpenForWorkset)
  }

  const handleTagAllItemsInWorkset = async () => {
    setIsTagInBulkModalOpenedForWorkset(true)
    setIsOpenTagInBulkModal(true)
  }

  const handleDownloadAllItemsInWorkset = async () => {
    try {
      setQueueDownloading(true)
      const checkedSize = await checkWorksetDownload({ orgId, worksetId: inWorksetId! })
      let availableSize = new Decimal(999999999999)

      try {
        const freeSpace = await getFreeDiskSpaceForDownload()
        availableSize = new Decimal(freeSpace.output)
      } catch (ex) {
        Utils.showError('PC 의 용량 체크에 실패하였습니다.')
        console.error(ex)
      }
      const requiredSize = new Decimal(checkedSize.output)

      setQueueDownloading(false)
      if (requiredSize.gt(availableSize)) {
        if (
          !(await confirmDownload({
            message: (
              <NotEnoughStorageBody
                downloadSize={prettyBytes(requiredSize.toNumber())}
                freeSpace={prettyBytes(availableSize.toNumber())}
              />
            ),
          }))
        ) {
          return
        }
      }

      setQueueDownloading(true)
      await queueWorksetDownload({ worksetId: inWorksetId!, orgId })
      Utils.showSuccess(`Queued workset for download.`)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setQueueDownloading(false)
    }
  }

  const handlingImageAction = {
    handleRemoveFromWorkset,
    setIsOpenCategoryInBulkModal: (v: boolean) => {
      setIsTagInBulkModalOpenedForWorkset(false)
      setIsOpenCategoryInBulkModal(v ? CATEGORY_BULK_MODAL.OpenForSelected : CATEGORY_BULK_MODAL.Hidden)
    },
    setIsOpenAddToWorksetModal,
    setIsOpenExplanationInBulkModal,
    setIsOpenTagInBulkModal,
    handleUpdateHideItems,
    handleDownloadItems,
    handleOpenItem: (item: GQLItem) => {
      setItemOpened(item)
    },
  }

  const ContextMenuList = [
    inWorksetId
      ? {
          title: 'Remove from Workset',
          callback: handleRemoveFromWorkset,
        }
      : {
          title: 'Add To Workset',
          callback: () => setIsOpenAddToWorksetModal(SHOW_ADD_TO_WORKSET_TYPE.SHOW_SELECTED),
        },
    {
      title: 'Bulk Edit - Category',
      callback: () => setIsOpenCategoryInBulkModal(CATEGORY_BULK_MODAL.OpenForSelected),
    },
    {
      title: 'Bulk Edit - Tag',
      callback: () => setIsOpenTagInBulkModal(true),
    },
    {
      title: 'Bulk Edit - Explanation',
      callback: () => setIsOpenExplanationInBulkModal(true),
    },
    inWorksetId
      ? {
          title: 'Hide',
          callback: () => handleUpdateHideItems(true),
        }
      : null,
    inWorksetId
      ? {
          title: 'Show',
          callback: () => handleUpdateHideItems(false),
        }
      : null,
  ]

  const { MenuComponent } = useContextMenu({ ref: imageGridRef, list: ContextMenuList })

  const tcfThumbnail = (item: GQLItem) => {
    const getLinkByModality = (modality: string) =>
      item.itemHasImages.find(ii => ii.modality === modality)?.image?.link ?? null

    // 우선순위에 따라 이미지 링크를 선정하여 반환한다.
    // (https://github.com/odc/tomocube/issues/620)
    const getLinkByModalityPriority = (priorityModality: 'HT' | 'BF' | 'FL' | 'PC') => {
      const priorities = [priorityModality, ...['HT', 'BF', 'FL', 'PC'].filter(v => v !== priorityModality)]
      for (const modality of priorities) {
        const link = getLinkByModality(modality)
        if (link) {
          return link
        }
      }
      return null
    }

    switch (imageViewType) {
      case 'HT':
      case 'PC':
      case 'FL':
      case 'BF':
        return getLinkByModalityPriority(imageViewType) ?? item.imageFile.link
      case 'MainAttachment':
        return item.mainAttachment?.link || null
      case 'TCF':
        return getLinkByModalityPriority('HT') ?? item.imageFile.link
      // return item.imageFile.link
    }
  }

  return (
    <>
      <BlockingLoadBox show={queueDownloading} message="Start downloading.." />
      <DownloadConfirmComponent {...DownloadConfirmComponentProps} />
      <PickerTableComponent {...pickerTableProps} />
      <ODModalAddWorksetsToProjectResult
        orgId={orgId}
        setIsOpen={setIsOpenWorksetToProjectResult}
        isOpen={isOpenWorksetToProjectResult}
        openSelectProject={handleSelectWorksetsToProject}
      />
      {showToolbar && (
        <>
          {/*<ODImageSearch listableContext={context} />*/}
          <ODImageGridSearchToolBar
            listableContext={context}
            setImageSize={setImageSize}
            imageSize={imageSize}
            onImageViewTypeChanged={setImageViewType}
            hideWorksetFilter={!!inWorksetId}
          />
          <div
            style={{
              paddingTop: 20,
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <ActiveFilter listableContext={context} />
          </div>
        </>
      )}
      {showToolbar && showFastAddToWorkset && (
        <div style={{ display: 'flex', marginLeft: 38 }}>
          <AddAllItemsToWorksetWrapper onClick={handleAddAllItemsToWorkset}>
            <AddAllItemsToWorksetIconBox>
              <ODIcon
                icon={ODIcons.MaterialCreateNewFolder}
                style={{ fontSize: 17, color: '#fff', letterSpacing: 0.24 }}
              />
            </AddAllItemsToWorksetIconBox>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <span style={{ fontWeight: 'bold', letterSpacing: 0.2, marginRight: 7 }}>Add all items to Workset</span>(
              {totalCount} items)
            </div>
          </AddAllItemsToWorksetWrapper>
        </div>
      )}
      {showToolbar && showTagAllItemsInThisWorkset && totalCount > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {canChangeCategory && (
            <WorksetBigActionButton
              icon={ODIcons.MaterialCreateNewFolder}
              onClick={handleCategoryAllItemsInWorkset}
              subText={`(${totalCount} items)`}
              title="Category all items in this workset"
            />
          )}
          {canTag && (
            <WorksetBigActionButton
              icon={ODIcons.MaterialCreateNewFolder}
              onClick={handleTagAllItemsInWorkset}
              subText={`(${totalCount} items)`}
              title="Tag all items in this workset"
            />
          )}
          {canDownload && (
            <WorksetBigActionButton
              icon={ODIcons.CoreDataTransferDown}
              onClick={handleDownloadAllItemsInWorkset}
              subText={`(Max ${totalCount} items)`}
              title="Download workset"
            />
          )}
          {canAddProject && (
            <WorksetBigActionButton
              icon={ODIcons.CoreLibraryAdd}
              onClick={() => handleSelectWorksetsToProject()}
              subText={''}
              title="Add to Project"
              width={181}
              customStyle={{ backgroundColor: ODColors.FadedBlue }}
              iconBoxCustomStyle={{ backgroundColor: ODColors.OffBlue }}
            />
          )}
          {canWorksetGroup && (
            <WorksetBigActionButton
              icon={ODIcons.CoreObjectGroup}
              onClick={() => setIsOpenWorksetGroupModal(true)}
              subText={''}
              title="Workset Group"
              width={181}
              customStyle={{ backgroundColor: ODColors.Tealish2 }}
              iconBoxCustomStyle={{ backgroundColor: ODColors.BluishGreen }}
            />
          )}
        </div>
      )}
      {itemOpened && (
        <TCFDetailModalInListableContext
          isOpen={!!itemOpened}
          toggle={() => setItemOpened(null)}
          itemId={itemOpened.itemId}
          lastTCFUpdatedTime={lastTCFUpdated}
          onTCFItemUpdated={v => {
            setItemOpened(v)
            setLastTCFUpdated(new Date().getTime())
          }}
          onTCFItemChanged={v => {
            setItemOpened(v)
            setLastTCFUpdated(0)
          }}
          listableContext={context}
          inWorksetId={inWorksetId}
        />
      )}
      <RemoveFromWorksetComponent {...removeFromWorksetComponentProps} />
      <ShowItemsComponent {...showItemsComponentProps} />
      <HideItemsComponent {...hideItemsComponentProps} />
      {isOpenCategoryInBulkModal !== CATEGORY_BULK_MODAL.Hidden && (
        <ODModalCategoryInBulk
          title="Edit Category in Bulk"
          onSave={handleBulkUpdateCategoryItem}
          onClose={() => setIsOpenCategoryInBulkModal(CATEGORY_BULK_MODAL.Hidden)}
          categories={categoryOptions}
        />
      )}
      {isOpenExplanationInBulkModal && (
        <ODModalExplanationInBulk
          onClose={() => setIsOpenExplanationInBulkModal(false)}
          onSave={handleBulkUpdateExplanationItem}
          title="Edit Explanation in Bulk"
        />
      )}
      {isOpenTagInBulkModal && (
        <ODModalTagInBulk
          onClose={() => setIsOpenTagInBulkModal(false)}
          onSave={handleBulkUpdateTagItem}
          title="Edit Tag in Bulk"
        />
      )}
      {isOpenAddToWorksetModal !== SHOW_ADD_TO_WORKSET_TYPE.HIDE && (
        <ODModalAddToWorkset
          onClose={() => setIsOpenAddToWorksetModal(SHOW_ADD_TO_WORKSET_TYPE.HIDE)}
          onSave={handleWorksetSave}
        />
      )}
      {isOpenWorksetGroupModal && inWorksetId && (
        <ODModalWorksetGroup
          worksetId={inWorksetId}
          onClose={() => setIsOpenWorksetGroupModal(false)}
          onSave={handleWorksetGroupSave}
          title={`Workset Group for ${currentWorksetName}`}
        />
      )}
      <ODImageGrid
        imageActionProps={{
          inWorksetId,
          listableContext: context,
          removing,
          handlingImageAction: handlingImageAction,
        }}
        imageGridRef={imageGridRef}
        contextMenuComponent={MenuComponent}
        imageSize={imageSize}
        listableContext={context}
        extractGridItemProps={item => ({
          link: tcfThumbnail(item),
          imageTagTypes: [
            ...(item.isTimeLapse ? [ImageTagType.Type_TimeLapse] : []),
            ...CommonUtils.getImageTypesFromMetadata(WebUtils.parseJSONSafe(item.meta, {})),
          ],
          dataId: item.dataId,
          title: item.title || '',
          shortName: item.shortName,
        })}
        onClickItem={setItemOpened}
      />
      <ODListableAutoLoader
        listableContext={context}
        loadingComponent={SmallLoading}
        noMoreComponent={NoMoreComponent}
      />
    </>
  )
})

interface IWorksetBigActionButtonProps {
  icon: ODIcons
  title: string
  subText: string
  onClick: () => void
  width?: number
  customStyle?: React.CSSProperties
  iconBoxCustomStyle?: React.CSSProperties
}

export const WorksetBigActionButton = (props: IWorksetBigActionButtonProps) => {
  const { onClick, title, icon, subText, width, customStyle, iconBoxCustomStyle } = props

  const divStyle = customStyle ? { ...customStyle } : {}
  const style = width ? { width: '100%', maxWidth: width, minWidth: width } : {}
  return (
    <div style={{ display: 'flex', marginLeft: 36, marginTop: 17 }}>
      <AddAllItemsToWorksetWrapper style={{ ...style, ...customStyle }} onClick={onClick}>
        <AddAllItemsToWorksetIconBox style={{ ...iconBoxCustomStyle }}>
          <ODIcon icon={icon} style={{ fontSize: 17, color: '#fff', letterSpacing: 0.24 }} />
        </AddAllItemsToWorksetIconBox>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <span style={{ fontWeight: 'bold', letterSpacing: 0.2, marginRight: 7 }}>{title}</span>
          {subText}
        </div>
      </AddAllItemsToWorksetWrapper>
    </div>
  )
}
