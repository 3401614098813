import React from 'react'
import styled from 'styled-components'
import { GQLWorkspaceItem } from '../../agent'
import { BlockingLoadBox } from '../../components/BlockingLoadBox'
import { FlexContentsContainer } from '../../components/FlexContentsContainer'
import { NoItemsFoundBox } from '../../components/NoItemsFoundBox'
import { GridItem } from '../../components/ODImageGrid/GridItem'
import { useCILSAgentContext } from '../../context/CILSAgentContext'

const Wrapper = styled.div`
  display: flex;
  //justify-content: space-between;
  padding: 30px;
  flex-wrap: wrap;
`

function ODImageGridLocal({ imageSize, onClick }: { imageSize: number; onClick: (item: GQLWorkspaceItem) => void }) {
  const {
    state: { loading: agentLoading, updateCount, workspaceItems: list },
  } = useCILSAgentContext()

  const loading = agentLoading && updateCount === 0

  return (
    <Wrapper>
      {loading && <BlockingLoadBox show />}
      {!loading && list.length === 0 && <NoItemsFoundBox />}
      {list &&
        list.map(item => {
          return (
            <GridItem
              key={item.localPath}
              size={imageSize}
              link={item.thumbnail || ''}
              onClick={() => onClick(item)}
              imageTagTypes={[]}
              dataId={item.dataId}
              title={item.localPath}
            />
          )
        })}
    </Wrapper>
  )
}

export const LocalItemsContainer: React.FC = () => {
  // const [itemOpened, setItemOpened] = React.useState<GQLItem | null>(null)

  return (
    <FlexContentsContainer>
      <div
        style={{
          paddingTop: 20,
          paddingLeft: 30,
          paddingRight: 30,
        }}
      />
      {/*{itemOpened && <TCFDetailModal isOpen={!!itemOpened} toggle={() => setItemOpened(null)} tcfItem={itemOpened} />}*/}
      <ODImageGridLocal
        imageSize={300}
        onClick={item => {
          console.log(`Local item clicked =>`, item)
        }}
      />
    </FlexContentsContainer>
  )
}
