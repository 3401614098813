import { PROJECT_ITEM_USER_STATUS } from '@cils/common'
import { BlockingLoadBox, ODListableContextType, useODModalConfirm } from '@odc/od-react-belt'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'
import styled, { css } from 'styled-components'
import { GQLItemExecution } from '../../../@types/server'
import { GQLQueueProjectDownloadInput } from '../../../agent'
import { CheckImg, UncheckImg } from '../../../assets/images'
import { IProjectDataListableOption } from '../../../containers/User/Project/list/useProjectDataListDataLoader'
import {
  PROJECT_ITEM_USER_STATUS_STRING,
  selectColorForProjectStatus,
  selectFontColorForProjectStatus,
} from '../../../containers/User/Project/ProjectCommon'
import { TCFDetailModal } from '../../../containers/User/TCFDetailModal'
import { useAPIs } from '../../../context/useAPIs'
import { ODColors } from '../../../global-styles'
import { useProjectPerm } from '../../../hooks/useProjectPerm'
import { ODListablePaginatedTable2 } from '../../../ODListable/ODListablePaginationTable2'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'
import { NoResultBox } from '../list/NoResultBox'
import { ODModalConfirmData } from '../modal/ODModalConfirmData'
import { ODModalSetAssigneeOrReviewer } from '../modal/ODModalSetAssigneeOrReviewer'
import { ProjectDataSelectMenu } from './ProjectDataSelectMenu'

interface IProjectDataTable {
  orgId: number
  projectId: number
  listableContext: React.Context<ODListableContextType<GQLItemExecution, IProjectDataListableOption>>
  myProjectPerm: number
  refreshList: () => void
  pageRefresh: () => void
  handleQueueDownload: (
    input: { hasSelectedIds?: boolean } & Partial<Pick<GQLQueueProjectDownloadInput, 'withResults' | 'onlyResults'>>
  ) => Promise<void>
  selectedIds: number[]
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>
  projectName: string
}

export const ProjectDataTable: React.FC<IProjectDataTable> = props => {
  const {
    orgId,
    listableContext,
    projectId,
    myProjectPerm,
    refreshList,
    pageRefresh,
    handleQueueDownload,
    selectedIds,
    setSelectedIds,
    projectName,
  } = props
  const {
    assignItemExecutionToAssignee,
    assignItemExecutionToReviewer,
    confirmItemExecution,
    unlockItemExecution,
  } = useAPIs()
  const projectPerm = useProjectPerm(orgId)
  const [loading, setLoading] = React.useState(false)
  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})
  const [isOpenSetAssignee, setIsOpenSetAssignee] = React.useState(false)
  const [isOpenConfirmData, setIsOpenConfirmData] = React.useState(false)
  const [isOpenSetReviewer, setIsOpenSetReviewer] = React.useState(false)
  const selectedId = React.useRef<number | null>(null)

  // for tcf
  const [isOpenTCF, setIsOpenTCF] = React.useState(false)
  const [lastTCFUpdated, setLastTCFUpdated] = React.useState(0)
  const [itemIdForTCF, setItemIdForTCF] = React.useState<number | null>(null)

  const [isShiftDown, setIsShiftDown] = React.useState(false)
  const [lastSelectedItem, setLastSelectedItem] = React.useState<GQLItemExecution | null>(null)

  const {
    state: { list },
    keyExtractor,
  }: ODListableContextType<GQLItemExecution, IProjectDataListableOption> = React.useContext(listableContext)

  const handleSetAssignee = async (userId: number) => {
    setLoading(true)
    try {
      if (selectedId.current) {
        await assignItemExecutionToAssignee({ ieIds: [selectedId.current], userId })
      } else {
        await assignItemExecutionToAssignee({ ieIds: selectedIds, userId })
      }
      selectedId.current = null
      setIsOpenSetAssignee(false)
      refreshList()
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
    }
  }

  const handleSetReviewer = async (userId: number) => {
    setLoading(true)
    try {
      if (selectedId.current) {
        await assignItemExecutionToReviewer({ ieIds: [selectedId.current], userId })
      } else {
        await assignItemExecutionToReviewer({ ieIds: selectedIds, userId })
      }
      selectedId.current = null
      setIsOpenSetReviewer(false)
      refreshList()
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
    }
  }

  // const handleCompleteExecuting = async (dataTitle: string) => {
  //   if (
  //     await confirm({
  //       title: `Complete executing`,
  //       message: (
  //         <>
  //           Are you sure you want to complete your execution
  //           <br />
  //           of <span style={{ color: ODColors.Primary }}>{dataTitle}</span>?
  //         </>
  //       ),
  //       yes: 'Complete',
  //       no: 'Cancel',
  //     })
  //   ) {
  //     try {
  //       setLoading(true)
  //       //
  //     } catch (ex) {
  //       ODWebUtils.showError(ex)
  //     } finally {
  //       setLoading(false)
  //     }
  //     // }
  //   }
  // }

  const handleDataUnlock = async () => {
    const tempId = selectedId.current
    selectedId.current = null
    if (
      await confirm({
        title: `Data Unlock`,
        message: <>Are you sure you want to unlock data?</>,
        yes: 'Unlock',
        no: 'Cancel',
      })
    ) {
      try {
        setLoading(true)
        if (tempId) {
          await unlockItemExecution({ projectId, ieIds: [tempId] })
        } else {
          await unlockItemExecution({ projectId, ieIds: selectedIds })
        }
        refreshList()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDataConfirm = async (isUnConfirm: boolean) => {
    setLoading(true)
    try {
      if (selectedId.current) {
        await confirmItemExecution({ projectId, ieIds: [selectedId.current], isUnConfirm })
      } else {
        await confirmItemExecution({ projectId, ieIds: selectedIds, isUnConfirm })
      }
      selectedId.current = null
      setIsOpenConfirmData(false)
      pageRefresh()
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
    }
  }

  const allCheckBoxIcon = React.useMemo(() => {
    const result = list.filter(item => selectedIds.includes(item.ieId))
    if (result.length === list.length) {
      return CheckImg
    } else {
      return UncheckImg
    }
  }, [selectedIds, list])

  const LinkToDataDetail = (value: GQLItemExecution) => ({
    pathname: SiteUrls.User.Org.Project.DataDetail(projectId, value.ieId, value.item.title ?? '-')(orgId),
    state: { itemId: value.itemId, myProjectPerm: myProjectPerm, projectName },
  })

  const handleDataCheck = (value: GQLItemExecution) => {
    setLastSelectedItem(value)
    const hasBeenSelected = selectedIds.findIndex(v => v === value.ieId) < 0

    if (isShiftDown) {
      const newSelectedItemIds = getNewSelectedItemIds(value)
      const selections = _.uniqBy([...selectedIds, ...newSelectedItemIds], v => v)

      if (!hasBeenSelected) {
        // return selections.filter(item => !newSelectedItems.includes(item))
        setSelectedIds(selections.filter(item => newSelectedItemIds.findIndex(v => v === value.ieId) < 0))
      } else {
        setSelectedIds(selections)
      }
    } else {
      if (selectedIds.includes(value.ieId)) {
        setSelectedIds(p => p.filter(id => id !== value.ieId))
      } else {
        setSelectedIds(p => [...p, value.ieId])
      }
    }
  }

  const getNewSelectedItemIds = (v: GQLItemExecution) => {
    const currentSelectedIndex = list.findIndex(item => parseInt(keyExtractor(item), 10) === v.ieId)
    const lastSelectedIndex = list.findIndex(item => parseInt(keyExtractor(item), 10) === lastSelectedItem?.ieId)

    return list
      .slice(Math.min(lastSelectedIndex, currentSelectedIndex), Math.max(lastSelectedIndex, currentSelectedIndex) + 1)
      .map(item => item.ieId)
    // .map(item => parseInt(keyExtractor(item), 10))
  }

  const handleKeyUp = (e: any) => {
    if (e.key === 'Shift') {
      setIsShiftDown(false)
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Shift') {
      setIsShiftDown(true)
    }
  }

  React.useEffect(() => {
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <TableBox>
      <BlockingLoadBox show={loading} />
      <ConfirmComponent {...confirmProps} />
      {isOpenTCF && itemIdForTCF && (
        <TCFDetailModal
          isOpen={isOpenTCF}
          toggle={() => {
            setIsOpenTCF(false)
            setItemIdForTCF(null)
          }}
          itemId={itemIdForTCF}
          lastTCFUpdatedTime={lastTCFUpdated}
          onTCFItemChanged={() => setLastTCFUpdated(0)}
          onTCFItemUpdated={() => setLastTCFUpdated(new Date().getTime())}
          onLoadMore={() => null}
        />
      )}
      <ODModalSetAssigneeOrReviewer
        isOpen={isOpenSetAssignee}
        onClose={() => {
          selectedId.current = null
          setIsOpenSetAssignee(false)
        }}
        onConfirm={handleSetAssignee}
        target={'assignee'}
        projectId={projectId}
      />
      <ODModalConfirmData
        isOpen={isOpenConfirmData}
        onClose={() => {
          selectedId.current = null
          setIsOpenConfirmData(false)
        }}
        onConfirm={handleDataConfirm}
      />
      <ODModalSetAssigneeOrReviewer
        isOpen={isOpenSetReviewer}
        onClose={() => {
          selectedId.current = null
          setIsOpenSetReviewer(false)
        }}
        onConfirm={handleSetReviewer}
        target={'reviewer'}
        projectId={projectId}
      />
      {/* 아래의 컴포넌트는 선택된 데이터가 있을때만 표시 */}
      {selectedIds.length > 0 && (
        <ProjectDataSelectMenu
          numSelectedItem={selectedIds.length}
          clearSelectedId={() => setSelectedIds([])}
          handleDataUnlock={handleDataUnlock}
          setAssignee={() => setIsOpenSetAssignee(true)}
          setReviewer={() => setIsOpenSetReviewer(true)}
          setConfirm={() => setIsOpenConfirmData(true)}
          handleQueueDownload={handleQueueDownload}
        />
      )}
      {list && list.length > 0 && (
        <ODListablePaginatedTable2
          numColumns={12}
          renderHeader={() => (
            <tr>
              {/*    /!* 프로젝트 권한 중 Owner, Admin, Editor 만 선택 가능 *!/*/}
              {/*      /!* Viewer 권한은 선택을 할 수 없다. *!/*/}
              {projectPerm.isSameOrGreaterThanEditor(myProjectPerm) && (
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const result = list.filter(item => selectedIds.includes(item.ieId))
                    list.forEach(value => {
                      const key = value.ieId
                      setSelectedIds(arr => {
                        const index = arr.indexOf(key)
                        if (index >= 0) {
                          if (result.length === list.length) {
                            arr.splice(index, 1)
                          }
                          return [...arr]
                        }
                        arr.push(key)
                        return [...arr]
                      })
                    })
                    // if (selectedIds.length < list.length) {
                    //   setSelectedIds(list.map(item => item.itemId))
                    // } else {
                    //   setSelectedIds([])
                    // }
                  }}
                >
                  <CheckboxImage src={allCheckBoxIcon} style={{ cursor: 'pointer', top: -4 }} alt="" />
                </th>
              )}
              <th>Data Id</th>
              <th>Data Title</th>
              <th>Workset</th>
              <th>Locked</th>
              <th>Assignee</th>
              <th>Assignee Status</th>
              <th>Assignee Result</th>
              <th>Reviewer</th>
              <th>Reviewer Status</th>
              <th>Reviewer Result</th>
              <th>Confirmed</th>
            </tr>
          )}
          renderRow={(value: GQLItemExecution, context) => {
            return (
              <tr key={value.ieId}>
                {/* Viewer 권한이면 아래 체크박스는 보이지 않도록 한다. */}
                {projectPerm.isSameOrGreaterThanEditor(myProjectPerm) && (
                  <td style={{ cursor: 'pointer' }} onClick={() => handleDataCheck(value)}>
                    <CheckboxImage src={selectedIds.includes(value.ieId) ? CheckImg : UncheckImg} alt="" />
                  </td>
                )}
                <td style={{ color: ODColors.SteelTwo }}>{value.dataId ?? '-'}</td>
                {/* 클릭하면 해당 이미지가 새 창에서 표시 */}
                <td>
                  <PrimaryBoldText
                    onClick={() => {
                      if (!value.itemId) {
                        return
                      }

                      setIsOpenTCF(true)
                      setItemIdForTCF(value.itemId)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {value.item.title ?? '-'}
                  </PrimaryBoldText>
                </td>
                {/* 클릭하면 해당 워크셋이 새창에서 표시 */}
                <td>
                  {value.item.inWorksets.map(item => {
                    return (
                      <div key={item.wsId}>
                        <a
                          key={item.wsId}
                          href={SiteUrls.User.Org.Workset.Detail(item.wsId)(orgId)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PrimaryBoldText>{item.name}</PrimaryBoldText>
                        </a>
                      </div>
                    )
                  })}
                </td>
                <CanClickTd
                  hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}
                  onClick={async () => {
                    if (projectPerm.isSameOrGreaterThanEditor(myProjectPerm)) {
                      selectedId.current = value.ieId
                      await handleDataUnlock()
                    }
                  }}
                >
                  {/* 클릭 시 Data Unlock 팝업 표시 // Viewer 권한은 X */}
                  <PrimaryText hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}>
                    {value.locker?.name ?? '-'}
                  </PrimaryText>
                </CanClickTd>
                <CanClickTd
                  hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}
                  onClick={() => {
                    if (projectPerm.isSameOrGreaterThanEditor(myProjectPerm)) {
                      selectedId.current = value.ieId
                      setIsOpenSetAssignee(true)
                    }
                  }}
                >
                  {/* 클릭 시 set Assignee 화면 표시 // Viewer 권한은 X */}
                  <PrimaryText hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}>
                    {value.assignee?.name ?? '-'}
                  </PrimaryText>
                </CanClickTd>
                <td>
                  <StatusBox
                    style={{
                      backgroundColor: selectColorForProjectStatus(value.assignStatus as PROJECT_ITEM_USER_STATUS),
                      color: selectFontColorForProjectStatus(value.assignStatus as PROJECT_ITEM_USER_STATUS),
                    }}
                  >
                    {PROJECT_ITEM_USER_STATUS_STRING[value.assignStatus] ?? '-'}
                  </StatusBox>
                  {/* Complete executing 팝업 표시 */}
                  {/* 외부 프로그램에서 하기 때문에 제거 */}
                  {/*{value.assignStatus === ProjectStatus.Executing && (*/}
                  {/*  <div style={{ width: '100%', maxWidth: 100, textAlign: 'center' }}>*/}
                  {/*    <ODVSpace h={3} />*/}
                  {/*    <PrimaryText*/}
                  {/*      onClick={() => handleCompleteExecuting('미구현')}*/}
                  {/*      style={{ textDecoration: 'underline' }}*/}
                  {/*    >*/}
                  {/*      Complete*/}
                  {/*    </PrimaryText>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </td>
                {/* Content Past Icon이 없다. 이건 추후 다시 찾아 보자. */}
                <td>
                  {value.hasResults ? (
                    <Link to={LinkToDataDetail(value)}>
                      <PrimaryText>View</PrimaryText>
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
                {/* set Reviewer 화면 표시 // Viewer 권한은 X */}
                <CanClickTd
                  hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}
                  onClick={() => {
                    if (projectPerm.isSameOrGreaterThanEditor(myProjectPerm)) {
                      selectedId.current = value.ieId
                      setIsOpenSetReviewer(true)
                    }
                  }}
                >
                  <PrimaryText hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}>
                    {value.reviewer?.name ?? '-'}
                  </PrimaryText>
                </CanClickTd>
                <td>
                  <StatusBox
                    style={{
                      backgroundColor: selectColorForProjectStatus(value.reviewStatus as PROJECT_ITEM_USER_STATUS),
                      color: selectFontColorForProjectStatus(value.reviewStatus as PROJECT_ITEM_USER_STATUS),
                    }}
                  >
                    {PROJECT_ITEM_USER_STATUS_STRING[value.reviewStatus] ?? '-'}
                  </StatusBox>
                  {/* Complete executing 팝업 표시 */}
                  {/* 외부 프로그램에서 하기 때문에 제거 */}
                  {/*{value.reviewStatus === ProjectStatus.Executing && (*/}
                  {/*  <div style={{ width: '100%', maxWidth: 100, textAlign: 'center' }}>*/}
                  {/*    <ODVSpace h={3} />*/}
                  {/*    <PrimaryText*/}
                  {/*      onClick={() => handleCompleteExecuting(value.item.title ?? '-')}*/}
                  {/*      style={{ textDecoration: 'underline' }}*/}
                  {/*    >*/}
                  {/*      Complete*/}
                  {/*    </PrimaryText>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </td>
                <td>
                  {value.hasResults ? (
                    <Link to={LinkToDataDetail(value)}>
                      <PrimaryText>View</PrimaryText>
                    </Link>
                  ) : (
                    '-'
                  )}
                </td>
                {/*  // Viewer 권한은 X */}
                <CanClickTd
                  hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}
                  onClick={() => {
                    if (projectPerm.isSameOrGreaterThanEditor(myProjectPerm)) {
                      selectedId.current = value.ieId
                      setIsOpenConfirmData(true)
                    }
                  }}
                >
                  <PrimaryText hasRemoveCursor={!projectPerm.isSameOrGreaterThanEditor(myProjectPerm)}>
                    {value.isConfirmed ? 'Confirmed' : '-'}
                  </PrimaryText>
                </CanClickTd>
              </tr>
            )
          }}
          listableContext={listableContext}
          eventParentContext={{}}
        />
      )}

      {list.length === 0 && (
        <>
          <Table responsive style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}>
            <thead>
              {/* 프로젝트 권한 중 Owner, Admin, Editor 만 선택 가능 */}
              <tr>
                {/* Viewer 권한은 선택을 할 수 없다. */}
                <th>Data Id</th>
                <th>Data Title</th>
                <th>Workset</th>
                <th>Locked</th>
                <th>Assignee</th>
                <th>Assignee Status</th>
                <th>Assignee Result</th>
                <th>Reviewer</th>
                <th>Reviewer Status</th>
                <th>Reviewer Result</th>
                <th>Confirmed</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
            </tbody>
          </Table>
          <NoResultBox height={480} text={'No results found.'} />
        </>
      )}
    </TableBox>
  )
}

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
`

const PrimaryBoldText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.Primary};
  font-weight: bold;
`

const CanClickTd = styled.td`
  cursor: pointer;

  ${(p: { hasRemoveCursor?: boolean }) =>
    p.hasRemoveCursor &&
    css`
      cursor: default;
    `}
`

const PrimaryText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.Primary};
  cursor: pointer;

  ${(p: { hasRemoveCursor?: boolean }) =>
    p.hasRemoveCursor &&
    css`
      cursor: default;
    `}
`

const StatusBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100px;
  height: 14px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  position: relative;
  top: 4px;
`
const CheckboxImage = styled.img`
  position: relative;
  top: 5px;
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
`
