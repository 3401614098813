import { ApiKeyPropsInputValidation, populateApiKeyPropsInput } from '@cils/common'
import React from 'react'
import * as Yup from 'yup'
import { GQLApiKey, GQLApiKeyPropsInput, GQLOkResponse, GQLSingleIDInput } from '../../../@types/server'
import { useModalConfirm } from '../../../components/ODModal/ODModalConfirm'
import { useCounter, useODMutation, useODQuery } from '../../../context/ODCommon'
import { ODApiKeyInput } from '../../../ODEntityEditor/FormComponents/ODApiKeyInput'
import { ODEntityInput } from '../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type ApiKeyEditContainerProps = {
  idEditing?: number
}

const GQL_CREATE = `
mutation createApiKey($data: ApiKeyPropsInput!) {
  createApiKey(data: $data) {
    keyId
  }
}
`

const GQL_UPDATE = `
mutation updateApiKey($data: ApiKeyPropsInput!) {
  updateApiKey(data: $data) {
    keyId
    name
    key
  }
}
`

const GQL_GET = `
query getApiKey($data: SingleIDInput!) {
  getApiKey(data: $data) {
    keyId
    name
    key
  }
}
`

const GQL_REMOVE = `
mutation removeApiKey($data: SingleIDInput!) {
  removeApiKey(data: $data) {
    ok
  }
}
`

export const GQL_REGENERATE_APIKEY = `
mutation regenerateKey($data: SingleIDInput!) {
  regenerateKey(data: $data) {
    keyId
    name
    key
  }
}
`

type PropsInput = GQLApiKeyPropsInput
type Entity = GQLApiKey

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: ApiKeyPropsInputValidation.name.required('Name is required.'),
  })
}

export const ApiKeyEditContainer: React.FC<ApiKeyEditContainerProps> = props => {
  const { idEditing } = props
  const apiCreate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_CREATE)
  const apiUpdate = useODMutation<Partial<PropsInput>, Partial<Entity>>(GQL_UPDATE)
  const apiGet = useODQuery<GQLSingleIDInput, Entity>(GQL_GET)
  const apiRemove = useODMutation<GQLSingleIDInput, GQLOkResponse>(GQL_REMOVE)
  const apiRegenerateKey = useODMutation<GQLSingleIDInput, GQLApiKey>(GQL_REGENERATE_APIKEY)
  const [token, updateToken] = useCounter()

  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        if (!data) {
          return {
            id: null,
            name: '',
          }
        }
        return {
          id: data.keyId,
          name: data.name,
          key: data.key,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (idEditing) {
          await apiUpdate({ id: idEditing, ...data })
          Utils.showSuccess('Updated an API Key', 'Success')
        } else {
          await apiCreate({ name: data.name })
          Utils.showSuccess('Added a new API Key', 'Success')
        }
        return SiteUrls.Admin.API.List
      },
      onUnexpectedError: (ex: Error) => {
        Utils.noop(token) // nothing but to avoid warning
        Utils.showError(ex)
      },
      getValidationSchema,
      populateDevData: populateApiKeyPropsInput,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('Removed an API Key.', 'Success')
        }
        return SiteUrls.Admin.API.List
      },
    }),
    // by using token as dep, we can easily update whole thing.
    [idEditing, apiCreate, apiGet, apiUpdate, apiRemove, token]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const { Component, confirm, props: confirmComponentProps } = useModalConfirm({
    message: (
      <>
        This cannot be undone.
        <br />
        Are you sure?
      </>
    ),
    yes: 'Confirm',
    no: 'Cancel',
  })

  const handleRegenerateKey = React.useCallback(async () => {
    if (await confirm()) {
      await apiRegenerateKey({ id: idEditing! }).then(() => {
        Utils.showSuccess('API Key has been changed.', 'Success')
        updateToken()
      }, Utils.showError)
    }
  }, [apiRegenerateKey, idEditing, confirm, updateToken])

  const title = !idEditing ? 'New API Key' : 'Edit API Key'

  return (
    <Provider title={title}>
      <Component {...confirmComponentProps} />
      <ODEntityInput name="name" label="Name of the ApiKey" placeholder="Enter name" inputType="text" />
      {idEditing && (
        <ODApiKeyInput
          inputProps={{ name: 'key', label: 'API Key', inputType: 'text', placeholder: 'Api Key' }}
          buttonTitle="Re-issue"
          onClickIssue={handleRegenerateKey}
        />
      )}
      <hr />
      <ODEntityEditorFooter
        saveButtonName="Save"
        deleteButtonName={idEditing ? 'Delete' : undefined}
        context={Context}
      />
    </Provider>
  )
}
