import { ALERT_TYPE } from '@cils/common'
import React from 'react'
import { GQLAlert, GQLDateTimeRange } from '../../../@types/server'
import { useAPIs } from '../../../context/useAPIs'
import { ODListableOption, ODListableResponseType } from '../../../ODListable/ODListableContext'

export interface AlertDataLoaderOption extends ODListableOption {
  filter: string | null
  userId: number | null
  orgId: number | null
  timeRange: GQLDateTimeRange | null
  alertTypes: Array<ALERT_TYPE> | null
}

export function useAlertDataLoader(
  userId: number | null,
  orgId: number | null,
  alertTypes: ALERT_TYPE[] | null,
  timeRange: GQLDateTimeRange | null
) {
  const { listAlerts } = useAPIs()
  return React.useCallback(
    async function MachineDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AlertDataLoaderOption
    ): Promise<ODListableResponseType<GQLAlert>> {
      const r = await listAlerts({
        page,
        pageSize,
        filter: options.filter || null,
        userId,
        orgId,
        // @ts-ignore
        alertTypes,
        timeRange,
      })
      return r as ODListableResponseType<GQLAlert>
    },
    [orgId, listAlerts, alertTypes, timeRange, userId]
  )
}
