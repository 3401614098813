import React from 'react'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import styled from 'styled-components'
import { ODColors } from '../global-styles'
import { ODIcon, ODIcons } from './ODIcon'
import { EDIT_DROPDOWN_MENU } from './ODImageGrid/ImageAction'

export const DropButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 186px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  text-align: left;
`
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 41px;
  width: 186px;
  padding-left: 14px;
  cursor: pointer;
  border-bottom: solid 1px #c8ced3;

  &:hover {
    background-color: ${ODColors.Primary};
    & > span {
      color: #ffffff;
    }
  }
`
const Value = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
  cursor: pointer;
`

type Props = {
  onChange: (v: EDIT_DROPDOWN_MENU) => void
  inWorksetId?: number
}

export const EditDropDownComponent: React.FC<Props> = props => {
  const { onChange, inWorksetId } = props

  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="div">
        <DropButton>
          <span>Edit</span>
          <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
        </DropButton>
      </DropdownToggle>
      <DropdownMenu style={{ minWidth: 186 }}>
        <OptionWrapper onClick={() => onChange(EDIT_DROPDOWN_MENU.Category)}>
          <Value>Category</Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onChange(EDIT_DROPDOWN_MENU.Tag)}>
          <Value>Tag</Value>
        </OptionWrapper>
        <OptionWrapper onClick={() => onChange(EDIT_DROPDOWN_MENU.Explanation)}>
          <Value>Explanation</Value>
        </OptionWrapper>
        {inWorksetId && (
          <>
            <OptionWrapper onClick={() => onChange(EDIT_DROPDOWN_MENU.Hide)}>
              <Value>Hide</Value>
            </OptionWrapper>
            <OptionWrapper onClick={() => onChange(EDIT_DROPDOWN_MENU.Show)}>
              <Value>Show</Value>
            </OptionWrapper>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
