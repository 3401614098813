import { AGENT_STATUS_MESSAGE, AGENT_STATUS_MESSAGE_TYPE, MACHINE_STATUS, Utils as CommonUtils } from '@cils/common'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import {
  GQLListableMachine,
  GQLListableMachineInput,
  GQLMachine,
  GQLMachineStatusInputByAdmin,
} from '../../../@types/server'
import { Clickable } from '../../../components/Clickable'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import {
  ODDropdownComponent,
  ODDropdownItem,
  renderDropdownItemNoFilter,
} from '../../../components/ODDropdownComponent'
import { OrgDropdownComponent } from '../../../components/OrgDropdownComponent'
import { useODMutation, useODQuery } from '../../../context/ODCommon'
import { ODColors } from '../../../global-styles'
import { MACHINE_FULL_SNAPSHOT } from '../../../gqls'
import {
  AgentDataLoaderOption,
  createODListableContext,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

type Props = {}

const GQL_UPDATE_MACHINE_STATUS_BY_ADMIN = `
mutation updateMachineStatusByAdmin($data: MachineStatusInputByAdmin!) {
  updateMachineStatusByAdmin(data: $data) {
    ${MACHINE_FULL_SNAPSHOT}
  }
}
`

const Label = styled.label`
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #73818f;
`
export const AutoReloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 48px;
  margin-top: 6px;
`
// @ts-ignore
export const AutoReloadLabel = styled(Label)`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`
export const AutoReloadInput = styled.input`
  margin-right: 7px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
`

const { Provider, Context } = createODListableContext<GQLMachine, AgentDataLoaderOption>()

const GQL_LIST = `
query listMachine($data: ListableMachineInput!) {
  listMachine(data: $data) {
    list {
      ${MACHINE_FULL_SNAPSHOT}
    }
    totalCount
    page
    pageSize
  }
}
`

const OwnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type OwnerProps = {
  user: { name: string | null; email: string | null }
}

export const Owner: React.FC<OwnerProps> = ({ user }) => {
  return (
    <div>
      {user.name || '-'} ({user.email || '-'})
    </div>
  )
}

const AccessTime: React.FC<{ id: string; time: Date | null }> = ({ id, time }) => {
  const color = time ? Utils.colorsForDecay(moment(time).diff(moment(), 'day')) : '#8f8f8f'
  const text = time ? moment(time).fromNow() : 'Never'
  const fullTime = time ? moment(time).format('lll') : 'Never'

  const divId = 'tooltip_agent_access_time_' + id

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }} id={divId}>
        <span style={{ color, fontWeight: 'bold' }}>{text}</span>
      </div>
      <UncontrolledTooltip target={divId} placement="top" delay={{ show: 0, hide: 0 }}>
        {fullTime}
      </UncontrolledTooltip>
    </>
  )
}

type MACHINE_STATUS_DROPDOWN_ITEM = ODDropdownItem & { value: MACHINE_STATUS | null }

const StatusDropdownList: Array<MACHINE_STATUS_DROPDOWN_ITEM> = [
  {
    id: 0,
    text: 'All',
    value: null,
  },
  {
    id: 1,
    text: 'Running',
    value: MACHINE_STATUS.running,
  },
  {
    id: 2,
    text: 'Stopped',
    value: MACHINE_STATUS.stopped,
  },
]

type MACHINE_HIDDEN_DROPDOWN_ITEM = ODDropdownItem & { value: boolean | null }
const HiddenDropdownList: Array<MACHINE_HIDDEN_DROPDOWN_ITEM> = [
  {
    id: 0,
    text: 'Hide hidden',
    value: false,
  },
  {
    id: 1,
    text: 'Show hidden',
    value: null,
  },
  {
    id: 2,
    text: 'Show hidden only',
    value: true,
  },
]

const AgentRunningState = ({ machine: v }: { machine: GQLMachine }) => {
  if (!v.agentStatus) {
    return <span style={{ color: ODColors.Redish }}>Stopped</span>
  }

  // @ts-ignore
  if (v.agentStatus === MACHINE_STATUS.running) {
    const now = moment()
    if (moment(v.lastAccessedTime).isBefore(now.add(-10, 'minutes'))) {
      return <span style={{ color: ODColors.Redish }}>Stopped-</span>
    }
    return <span>Running</span>
  }
  return <span style={{ color: ODColors.Redish }}>Stopped-</span>
}

const createTableDefinition = (
  toggleVisibility: Function
): ODListableTableDefinition<GQLMachine, AgentDataLoaderOption> => [
  {
    id: 'machineId',
    title: 'ID',
    transform: v => v.machineId,
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'org',
    title: 'Organization',
    transform: v => v.org.name,
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'agentName',
    title: 'Agent Name',
    transform: v => v.machineName,
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'agentLogin',
    title: 'Agent Login',
    transform: v => {
      if (v.lastLoggedInUser) {
        return <span>{v.lastLoggedInUser?.name}</span>
      }
      return <span>No info</span>
    },
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'agentOwners',
    title: 'Agent Owners',
    transform: v => {
      if (v.lastLoggedInUser || true) {
        const owners = v.lastLoggedInUser?.owners || []
        // const owners = [
        //   { userId: 1, name: 'Jeff', email: 'jeff.oh@odcode.com' },
        //   { userId: 2, name: 'Kevin', email: 'kevin.park@odcode.com' },
        // ]
        if (owners.length === 0) {
          return <span>No info</span>
        }

        return (
          <OwnerWrapper>
            {owners.map(user => (
              <Owner user={user} key={user.userId} />
            ))}
          </OwnerWrapper>
        )
      }
      return <span>No info</span>
    },
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'regDate',
    title: 'Registration Time',
    transform: v => Utils.formatDate(v.createdAt),
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'lastAccess',
    title: 'Last Access',
    transform: v => <AccessTime id={v.machineId.toString()} time={v.lastAccessedTime} />,
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'serviceVersionStatus',
    title: 'Service Version/Status',
    transform: v => (
      <>
        <span>{v.agentVersion || '-'}</span>
        <br />
        <AgentRunningState machine={v} />
      </>
    ),
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'sync',
    title: 'Sync',
    transform: v => {
      if (!v.lastMessage) {
        return 'No info'
      }

      try {
        const m: AGENT_STATUS_MESSAGE = JSON.parse(v.lastMessage)
        switch (m.type) {
          case AGENT_STATUS_MESSAGE_TYPE.UPDATE_SYNC_STATUS: {
            return <span onClick={() => console.log(v)}>{CommonUtils.getScanInfoText(m.scanInfo)}</span>
          }
          default:
            return <span onClick={() => console.log(v)}>{v.lastMessage}</span>
        }
      } catch (ex) {
        return <span onClick={() => console.log(v)}>Failed to parse message.</span>
      }
    },
    thClass: 'text-left',
    className: 'text-left user-td',
  },
  {
    id: 'dir',
    title: 'Workspace',
    transform: v => {
      return <Link to={SiteUrls.Admin.Agent.DirStatus(v.machineId)}>View</Link>
    },
    thClass: 'text-left',
    className: 'text-left user-td',
    isHTML: false,
  },
  {
    id: 'visibility',
    title: 'Visibility',
    transform: v => {
      return <Clickable onClick={() => toggleVisibility(v)}>{v.hidden ? 'Hidden' : 'Not hidden'}</Clickable>
    },
    thClass: 'text-left',
    className: 'text-left user-td',
    isHTML: false,
  },
]

export const AgentListContainer: React.FC<Props> = () => {
  const apiList = useODQuery<GQLListableMachineInput, GQLListableMachine>(GQL_LIST)
  const [selectedOrgId, setSelectedOrgId] = React.useState<number | null>(null)
  const [selectedAgentStatus, setSelectedAgentStatus] = React.useState<string | null>(null)
  const [selectedHiddenStatus, setSelectedHiddenStatus] = React.useState<boolean | null>(false)
  const [autoReload, setAutoReload] = React.useState(false)
  const [updateToken, setUpdateToken] = React.useState(0)

  const apiUpdateMachineStatus = useODMutation<Partial<GQLMachineStatusInputByAdmin>, GQLMachine>(
    GQL_UPDATE_MACHINE_STATUS_BY_ADMIN
  )

  const toggleMachineStatus = React.useCallback(
    async (machine: GQLMachine) => {
      try {
        await apiUpdateMachineStatus({ id: machine.machineId, hidden: machine.hidden !== 1 })
        Utils.showSuccess('Changed hidden status.', 'Success')
        setUpdateToken(v => v + 1)
      } catch (ex) {
        Utils.showError(ex)
      }
    },
    [apiUpdateMachineStatus]
  )

  const [tableDefinition] = React.useState(() => createTableDefinition(toggleMachineStatus))

  React.useEffect(() => {
    let handler: any = null
    if (autoReload) {
      handler = setInterval(() => {
        setUpdateToken(v => v + 1)
      }, 1000)
    }

    return () => clearInterval(handler)
  }, [autoReload, setUpdateToken])

  const dataLoader = React.useCallback(
    async function MachineDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: AgentDataLoaderOption
    ): Promise<ODListableResponseType<GQLMachine>> {
      const r = await apiList({
        page,
        pageSize,
        filter: options.filter || null,
        agentStatus: selectedAgentStatus,
        orgId: selectedOrgId,
        hidden: selectedHiddenStatus,
      })
      return r as ODListableResponseType<GQLMachine>
    },
    [apiList, selectedOrgId, selectedAgentStatus, selectedHiddenStatus]
  )

  const handleChangeOrg = (v: Array<number> | null) => {
    setSelectedOrgId(v?.[0] || null)
  }

  const handleChangeStatus = (v: ODDropdownItem & { value: MACHINE_STATUS | null }) => {
    setSelectedAgentStatus(v.value)
  }

  const handleChangeHiddenFilter = (v: ODDropdownItem & { value: boolean | null }) => {
    setSelectedHiddenStatus(v.value)
  }

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Agent</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.machineId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
            refreshToken={updateToken.toString()}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by Agent name, Login email"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <AutoReloadWrapper>
                  <AutoReloadLabel>
                    <AutoReloadInput
                      type="checkbox"
                      value="Auto reload"
                      checked={autoReload}
                      onChange={(e: any) => setAutoReload(e.target.checked)}
                    />
                    Auto reload
                  </AutoReloadLabel>
                </AutoReloadWrapper>
                <div
                  style={{
                    minWidth: 76,
                    paddingTop: 5,
                    marginRight: 20,
                  }}
                >
                  <Label htmlFor="select" style={{ fontWeight: 'bold' }}>
                    Filter
                  </Label>
                </div>
                <div style={{ marginRight: 20 }}>
                  <OrgDropdownComponent onChange={handleChangeOrg} />
                </div>
                <div style={{ marginRight: 20 }}>
                  <ODDropdownComponent
                    list={StatusDropdownList}
                    onChange={handleChangeStatus}
                    renderItem={renderDropdownItemNoFilter}
                    textNotSelected="Status"
                  />
                </div>
                <ODDropdownComponent
                  list={HiddenDropdownList}
                  onChange={handleChangeHiddenFilter}
                  renderItem={renderDropdownItemNoFilter}
                  textNotSelected="Hide hidden"
                />
              </div>
              <div
                style={{
                  paddingTop: 20,
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
              >
                {/*<ActiveFilter listableContext={Context} />*/}
              </div>
            </div>
            <ODListablePaginatedTable
              fields={tableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
