import React, { useState } from 'react'
import styled from 'styled-components'
import { GQLAttachmentType } from '../@types/server'
import { ThumbnailInfo } from '../containers/User/DetailContainerComponent/convertItemAttachmentToThumbnailInfo'
import { ThumbnailOverlayToolbar } from '../containers/User/DetailContainerComponent/ThumbnailOverlayToolbar'
import { useModalConfirm } from './ODModal/ODModalConfirm'
import { AttachmentEditData, EditAttachmentModal } from './ODModal/ODModalEditAttachment'
import { ThumbnailNoImage } from './ThumbnailNoImage'

const Wrapper = styled.div`
  display: flex;
  position: relative;

  &:hover {
    .overlayWrapper {
      z-index: 99;
    }
  }
`
const OverlayWrapper = styled.div`
  display: flex;
  height: 159px;
  flex-direction: column;
  background-color: rgba(32, 32, 32, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  padding: 21px 30px;
`
const FileName = styled.span`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ffffff;
`
const ScreenShotText = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #ffffff;
`
const Desc = styled.span`
  opacity: 0.7;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: 0.24px;
  color: #ffffff;
`
const AttachmentTag = styled.div`
  width: 69px;
  height: 16px;
  border-radius: 4px;
  background-color: #20a8d8;
  display: flex;
  justify-content: center;
  align-items: center;
`
const AttachmentTagText = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 2px;
`
const ScrollWrapper = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

type Props = {
  thumbnail: ThumbnailInfo
  onSetAsMain: (v: ThumbnailInfo) => void
  attachmentTypes: Array<GQLAttachmentType>
  onSaveAttachment: (thumbnail: ThumbnailInfo, changed: AttachmentEditData) => void
  onRemoveAttachment: (thumbnail: ThumbnailInfo) => Promise<void>
  editable: boolean
  canDeleteAttachment?: boolean
}

export const ThumbnailOverlay: React.FC<Props> = props => {
  const {
    thumbnail,
    onSetAsMain,
    attachmentTypes,
    onSaveAttachment,
    onRemoveAttachment,
    editable,
    canDeleteAttachment,
  } = props
  const { link, isAttachment, fileName, description, attachmentType, isMain = false } = thumbnail

  const [mouseMove, setMouseMove] = useState({
    x: 0,
    y: 0,
  })

  const [isOpen, setIsOpen] = React.useState(false)
  const { Component, confirm, props: confirmComponentProps } = useModalConfirm({
    title: 'Delete attachment file',
    message: 'This cannot be undone. Are you sure? ',
    yes: 'Confirm',
    no: 'Cancel',
  })

  const handleSetAsMain = () => {
    if (!isMain && editable) {
      // only set, no un-set.
      onSetAsMain(thumbnail)
    }
  }

  const handleEditAttachment = async () => {
    if (!editable) {
      return
    }

    setIsOpen(true)
  }

  const handleSave = async (changed: AttachmentEditData) => {
    if (!editable) {
      return
    }

    setIsOpen(false)
    onSaveAttachment(thumbnail, changed)
  }

  const handleRemove = async () => {
    if (!editable) {
      return
    }

    if (await confirm()) {
      onRemoveAttachment(thumbnail)
    }
  }

  return (
    <Wrapper>
      {isOpen && (
        <EditAttachmentModal
          initialValue={thumbnail}
          attachmentTypes={attachmentTypes}
          onDismiss={() => setIsOpen(false)}
          onSave={handleSave}
          mouseMove={mouseMove}
          isUpload={false}
        />
      )}
      <Component {...confirmComponentProps} />
      <img src={link} alt="thumbnail" style={{ width: 551, height: 551 }} />
      {isAttachment && (
        <OverlayWrapper className="overlayWrapper" onMouseDown={e => setMouseMove({ x: e.clientX, y: e.clientY })}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 10 }}>
              <FileName>{fileName}</FileName>
              <AttachmentTag style={{ marginLeft: 10 }}>
                <AttachmentTagText>Attachment</AttachmentTagText>
              </AttachmentTag>
            </div>
            <ThumbnailOverlayToolbar
              editable={isMain}
              handleSetAsMain={handleSetAsMain}
              handleEditAttachment={handleEditAttachment}
              handleRemove={handleRemove}
              isMain={isMain}
              canEditAttachment={editable}
              canDeleteAttachment={canDeleteAttachment}
              downloadLink={link}
            />
          </div>
          <div style={{ marginTop: 9, marginBottom: 8 }}>
            <ScreenShotText>{attachmentType?.name}</ScreenShotText>
          </div>
          <ScrollWrapper>
            <Desc>{description}</Desc>
          </ScrollWrapper>
        </OverlayWrapper>
      )}
    </Wrapper>
  )
}
