import moment from 'moment'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { GQLApiKey, GQLListableApiKey, GQLListableApiKeyInput } from '../../../@types/server'
import { Clickable } from '../../../components/Clickable'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { useODQuery } from '../../../context/ODCommon'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { ODListableSearchBox } from '../../../ODListable/ODListableSearchBox'
import { SiteUrls } from '../../../urls'
import { Utils } from '../../../utils'

export interface ApiKeyDataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLApiKey, ApiKeyDataLoaderOption>()

const GQL_LIST_APIKEY = `
query listApiKey($data: ListableApiKeyInput!) {
  listApiKey(data: $data) {
    list {
      keyId
      key
      name
      keyIssuedAt
      lastUsedAt
    }
    totalCount
    page
    pageSize
  }
}
`

export const ApiKeyTag: React.FC<{ item: GQLApiKey }> = ({ item }) => {
  const { lastUsedAt } = item
  const color = Utils.colorsForDecay(moment(lastUsedAt || item.createdAt).diff(moment(), 'day'))
  const text = lastUsedAt ? moment(item.lastUsedAt).fromNow() : 'Never'

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <CopyToClipboard text={item.key} onCopy={() => Utils.showInfo('Copied!')}>
        <Clickable>{item.key}</Clickable>
      </CopyToClipboard>
      <span style={{ color, fontWeight: 'bold' }}>Last used at : {text}</span>
    </div>
  )
}

export const ApiKeyListContainer: React.FC = () => {
  const apiList = useODQuery<GQLListableApiKeyInput, GQLListableApiKey>(GQL_LIST_APIKEY)

  const dataLoader = React.useCallback(
    async function ApiKeyDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ApiKeyDataLoaderOption
    ): Promise<ODListableResponseType<GQLApiKey>> {
      const r = await apiList({ page, pageSize, filter: options.filter || null })
      return r as ODListableResponseType<GQLApiKey>
    },
    [apiList]
  )
  const TableDefinition: ODListableTableDefinition<GQLApiKey, ApiKeyDataLoaderOption> = [
    {
      id: 'name',
      title: 'Name',
      transform: v => v.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'key',
      title: 'Key',
      transform: v => <ApiKeyTag item={v} />,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'createdAt',
      title: 'Creation Date',
      transform: v => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
    {
      id: 'keyUpdatedAt',
      title: 'Key Generated',
      transform: v => Utils.formatDate(v.keyIssuedAt),
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
    {
      id: 'setting',
      title: 'Action',
      transform: v => {
        return (
          <Link to={SiteUrls.Admin.API.Edit(v.keyId)}>
            <Clickable>Setting</Clickable>
          </Link>
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
  ]

  return (
    <FlexContentsContainer>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>API Key</CardHeader>
        <CardBody>
          <Provider
            dataLoader={dataLoader}
            keyExtractor={v => v.keyId.toString()}
            pageSize={10}
            onDataLoaderError={Utils.showError}
            searchOnLoad
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ODListableSearchBox
                listableContext={Context}
                placeholder="Search by name, key"
                style={{ flexGrow: 6, maxWidth: 600 }}
              />
              <div>
                <Link to={SiteUrls.Admin.API.Add} style={{ textDecoration: 'none' }}>
                  <Button block color="primary" style={{ minWidth: 135 }}>
                    New API Key
                  </Button>
                </Link>
              </div>
            </div>
            <ODListablePaginatedTable
              fields={TableDefinition}
              listableContext={Context}
              renderLoading={() => 'Loading..'}
              renderEmpty={() => 'No result.'}
            />
            <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
          </Provider>
        </CardBody>
      </Card>
    </FlexContentsContainer>
  )
}
