import { TextField } from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useDetectOutsideClick } from '../utils/useDetectOutsideClick'
import { ODIcon, ODIcons } from './ODIcon'
import { DROPDOWN_MENU } from './ODImageGrid/ODImageGridSearchToolBar'
import { ShareModalDropDownProps } from './ODModal/ODModalShare'

const TextBox = styled.div`
  display: flex;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
`
const Modal = styled.div`
  position: relative;
  display: inline-block;
`
const ModalContent = styled.div`
  display: none;
  position: absolute;
  width: 350px;
  height: 153px;
  border-radius: 3px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  border: 1px solid #ececec;
  z-index: 9999;
  flex-direction: column;
  padding: 24px;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`
const DeleteButton = styled.div`
  width: 88px;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #f86c6b;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const DeleteButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #f86c6b;
`
const SaveButton = styled.div`
  width: 135px;
  height: 35px;
  border-radius: 3px;
  background-color: #29b6ca;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const SaveButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`
const CloseWrapper = styled.div`
  position: absolute;
  right: 22px;
  top: 18px;
  cursor: pointer;
  z-index: 9999;
`

const ExpireText = styled.span`
  font-size: 11px;
  letter-spacing: 0.24px;
  color: #29b6ca;
  margin-left: 8px;
  margin-top: 3px;
`

type Props = {
  initialValue: string | null
  index: number
  handleSave: (v: string) => void
  onDelete: () => void
  handleCloseModal: (v: string) => void
}

function convertInitialValue(expireAt: string | null): string {
  return expireAt
    ? moment(expireAt).format('YYYY-MM-DD')
    : moment()
        .add(1, 'month')
        .format('YYYY-MM-DD')
}

export const SetExpirationModal: React.FC<Props & ShareModalDropDownProps> = props => {
  const { handleSave, index, initialValue, onDelete, isMenuOpen, setIsMenuOpen, handleCloseModal } = props

  const [value, setValue] = useState<string>(convertInitialValue(initialValue))
  const modalRef = React.useRef<HTMLDivElement>(null)
  const isModalOpen = isMenuOpen === DROPDOWN_MENU.Expiration + '_' + index

  useDetectOutsideClick(() => setIsMenuOpen(null), modalRef, isModalOpen)

  React.useEffect(() => {
    setValue(convertInitialValue(initialValue))
  }, [setValue, isMenuOpen, initialValue])

  const onSave = () => {
    handleSave(value)
    setIsMenuOpen(null)
  }

  const handleRemove = () => {
    setIsMenuOpen(null)
    onDelete()
  }

  return (
    <Modal ref={modalRef}>
      <TextBox onClick={() => handleCloseModal(DROPDOWN_MENU.Expiration + '_' + index)}>
        {initialValue && <ExpireText>Until {moment(initialValue).format('YYYY-MM-DD')}</ExpireText>}
        {!initialValue && (
          <ODIcon
            icon={ODIcons.CoreAvTimer}
            style={{ fontSize: 14, color: '#29b6ca', cursor: 'pointer', marginLeft: 9 }}
          />
        )}
      </TextBox>
      <ModalContent style={{ display: isModalOpen ? 'flex' : 'none' }}>
        <CloseWrapper onClick={() => handleCloseModal(DROPDOWN_MENU.Expiration + '_' + index)}>
          <ODIcon icon={ODIcons.CoreX} style={{ color: '#bdbdbd', fontSize: 18, cursor: 'pointer' }} />
        </CloseWrapper>
        <ColumnWrapper>
          <Title style={{ marginBottom: 10 }}>Set Expiration</Title>
          <form noValidate>
            <TextField
              id={`expiration_` + index}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          </form>
          <RowWrapper style={{ justifyContent: 'space-between', marginTop: 20 }}>
            <DeleteButton onClick={handleRemove}>
              <DeleteButtonText>Delete</DeleteButtonText>
            </DeleteButton>
            <SaveButton onClick={onSave}>
              <SaveButtonText>Save</SaveButtonText>
            </SaveButton>
          </RowWrapper>
        </ColumnWrapper>
      </ModalContent>
    </Modal>
  )
}
