import { ORG_ROLE, WORKSET_USER_PERM, worksetUserPermissionToString } from '@cils/common'
import { ODHSpace, ODListableResponseType, ODModalSize } from '@odc/od-react-belt'
import moment from 'moment'
import React from 'react'
import { GQLWorkset } from '../../../@types/server'
import { ODIcon, ODIcons } from '../../../components/ODIcon'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { useAPIs } from '../../../context/useAPIs'
import { useOrgPerm } from '../../../context/useOrgPerm'
import { ODColors } from '../../../global-styles'
import { ODListableOption } from '../../../ODListable/ODListableContext'
import { IWorksetListableOption } from '../Workset/WorksetCommon'
import { useWorksetPickerTableModal } from '../WorksetGroup/useWorksetPickerTableModal'

export interface IWorksetsToProjectPickerListableOption extends ODListableOption {
  filter: string
}

const THeads = ['Workset name', 'Items', 'Last item added', 'Owner', 'Created', 'Your Permission', 'Project']

export function useWorksetsToProjectPickerModal(projectId: number | null) {
  const { orgId } = useODSingleOrgContext()
  const { listWorkset, listWorksetInOrgWithFullPermission } = useAPIs()

  // Manage Organization Workset 권한인지?
  const isManageOrganizationWorkset = useOrgPerm(orgId)?.isAllowed(ORG_ROLE.EDIT_ORG_WORKSET) || false

  const dataLoader = React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IWorksetListableOption
    ): Promise<ODListableResponseType<GQLWorkset>> {
      if (isManageOrganizationWorkset) {
        const r = await listWorksetInOrgWithFullPermission({
          page,
          pageSize,
          orgId: orgId,
          ...options,
        })
        return r as ODListableResponseType<GQLWorkset>
      } else {
        const r = await listWorkset({
          page,
          pageSize,
          orgId: orgId,
          ...options,
        })
        return r as ODListableResponseType<GQLWorkset>
      }
    },
    [listWorkset, listWorksetInOrgWithFullPermission, isManageOrganizationWorkset, orgId]
  )

  const confirmButtonTitle = 'Add Worksets'
  const confirmButtonLeftText = 'Only worksets with Owner, Editor, Collaborator permission can be selected.'
  return useWorksetPickerTableModal<GQLWorkset, IWorksetsToProjectPickerListableOption>({
    size: ODModalSize.XLarge,
    pageSpan: 10,
    rounded: false,
    hasCurrentPageToggle: true,
    disabledSubmit: true,
    pickDefaultOptions: {
      confirmButtonTitle,
    },
    dataLoader: dataLoader,
    keyExtractor: v => v.wsId,
    addedItemExtractor: v => {
      // projectId가 없는 경우는 워크셋 그룹 생성 직후
      if (!projectId) {
        return false
      }
      const isInclude = v.projects.find(pj => pj.projectId === projectId)

      return !!isInclude
    }, // 추후 워크셋 항목이 해당 워크셋 그룹에 이미 추가 되었는지 체크 해야 함
    viewerExtractor: v => v.myPerm === WORKSET_USER_PERM.Viewer, // 해당 워크셋에서 나의 권한이 뷰어인지
    numColumns: 7,
    renderConfirmButtonLeftText: () => (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
        <ODIcon icon={ODIcons.MaterialError} style={{ fontSize: 14, color: ODColors.Primary }} />
        <ODHSpace w={6} />
        <span style={{ fontSize: 14, color: ODColors.Primary }}>{confirmButtonLeftText}</span>
      </div>
    ),
    renderTH: (index: number) => THeads[index],
    renderTD: (index: number, v: GQLWorkset) => {
      switch (index) {
        case 0:
          return v.name ?? '-'
        case 1:
          return v.numItems.toLocaleString()
        case 2:
          return v.lastAddedAt ? moment(v.lastAddedAt).format('YYYY-MM-DD HH:mm:ss') : '-'
        case 3:
          const owner = v.members.find(v => v.permLevel === WORKSET_USER_PERM.Owner)
          return <div>{owner?.user?.name || '-'}</div>
        case 4:
          return v.createdAt ? moment(v.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'
        case 5:
          return v.myPerm ? worksetUserPermissionToString(v.myPerm) : '-'
        case 6:
          return (
            <div>
              {v.projects?.map(pj => {
                return <div key={pj.projectId}>{pj.name}</div>
              })}
            </div>
          )
      }
    },
  })
}
