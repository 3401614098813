import { ErrorCode, PROJECT_TYPE } from '@cils/common'
import { BlockingLoadBox, ODHSpace, ODVSpace, ODWebUtils, useCounter } from '@odc/od-react-belt'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'
import styled from 'styled-components'
import { GQLPROJECT_TYPE } from '../../../../@types/server'
import { ODModalCopyFromPreset } from '../../../../components/project/modal/ODModalCopyFromPreset'
import { ODModalCreateProject } from '../../../../components/project/modal/ODModalCreateProject'
import { ODModalDeleteProject } from '../../../../components/project/modal/ODModalDeleteProject'
import { ODModalEditMetadata } from '../../../../components/project/modal/ODModalEditMetadata'
import { useAPIs } from '../../../../context/useAPIs'
import { SingleQueryWrapper } from '../../../../context/useSingleQueryAPI'
import { ODColors } from '../../../../global-styles'
import { SiteUrls } from '../../../../urls'
import { Utils } from '../../../../utils'
import { IMetaData } from '../store/metadata/IMetaData'

interface IProjectSettings {
  orgId: number
  projectId: number
  ownerOrgId: number
  pageRefresh: () => void
  projectName: string
  projectType: GQLPROJECT_TYPE
}

// 해당 프로젝트에서 권한이 Admin이상인 경우에만 접근 가능
export const ProjectSettings: React.FC<IProjectSettings> = props => {
  const { orgId, projectId, pageRefresh, projectName, projectType } = props
  const { updateProject, removeProject, updateProjectMetaData, getProjectMetaData } = useAPIs()
  const [isOpenEditProjectInfo, setIsOpenEditProjectInfo] = React.useState(false)
  const [isOpenDeleteProject, setIsOpenDeleteProject] = React.useState(false)
  const [isOpenEditMetadata, setIsOpenEditMetadata] = React.useState(false)
  const [isOpenCopyFromPreset, setIsOpenCopyFromPreset] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [redirect, setRedirect] = React.useState('')
  const [metaDataToken, metaDataRefresh] = useCounter()

  const handleUpdateProject = React.useCallback(
    async (name: string, projectType: PROJECT_TYPE) => {
      setLoading(true)
      try {
        await updateProject({ projectId, name, type: Utils.formatProjectTypeForSubmit(projectType) })
        pageRefresh()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, updateProject, projectId, pageRefresh]
  )

  const handleRemoveProject = React.useCallback(
    async (password: string) => {
      setLoading(true)
      try {
        await removeProject({ projectId, password })
        setIsOpenDeleteProject(false)
        setRedirect(SiteUrls.User.Org.Project.Main(orgId))
      } catch (ex) {
        Utils.showError(ex, '', {}, { [ErrorCode.ServerWrongLoginInfo]: 'Password is invalid' })
      } finally {
        setLoading(false)
      }
    },
    [setLoading, removeProject, projectId, orgId]
  )

  const handleCopyFromPreset = React.useCallback(
    async (presetId: number) => {
      setLoading(true)
      try {
        await updateProjectMetaData({ projectId, orgId, presetId })
        setIsOpenCopyFromPreset(false)
        metaDataRefresh()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
    },
    [setLoading, updateProjectMetaData, setIsOpenCopyFromPreset, metaDataRefresh, orgId, projectId]
  )

  const handleUpdateMetaData = React.useCallback(
    async (rawData: string) => {
      setLoading(true)
      try {
        await updateProjectMetaData({ projectId, orgId, rawData })
        setIsOpenEditMetadata(false)
        metaDataRefresh()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
    },
    [updateProjectMetaData, setLoading, setIsOpenEditMetadata, metaDataRefresh]
  )

  if (redirect) {
    return <Redirect to={redirect} />
  }

  // const listMetadata = Array(3)
  //   .fill('')
  //   .map((_, index) => {
  //     return {
  //       key: `test${index}`,
  //       name: `tester${index}`,
  //     }
  //   })

  return (
    <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
      <BlockingLoadBox show={loading} />
      <ODModalCreateProject
        isOpen={isOpenEditProjectInfo}
        onClose={() => setIsOpenEditProjectInfo(false)}
        onCreate={handleUpdateProject}
        isEdit
        existingName={projectName}
      />
      <ODModalDeleteProject
        isOpen={isOpenDeleteProject}
        onClose={() => setIsOpenDeleteProject(false)}
        onDelete={handleRemoveProject}
        projectName={projectName}
      />
      <ODModalCopyFromPreset
        orgId={orgId}
        isOpen={isOpenCopyFromPreset}
        onClose={() => setIsOpenCopyFromPreset(false)}
        onConfirm={handleCopyFromPreset}
      />
      <CardBody style={{ padding: '31px 22px 142px' }}>
        <ColumnRow>
          <Title>Project Information</Title>
          <ODVSpace h={26} />
          <Row>
            <ProjectInfoLabel>Project name</ProjectInfoLabel>
            <ODHSpace w={51} />
            <ProjectInfoValue>{projectName}</ProjectInfoValue>
          </Row>
          <ODVSpace h={23} />
          <Row>
            <ProjectInfoLabel>Project type</ProjectInfoLabel>
            <ODHSpace w={51} />
            <ProjectInfoValue>{projectType}</ProjectInfoValue>
          </Row>
          <ODVSpace h={30} />
          <Row>
            <ButtonWithOutline onClick={() => setIsOpenEditProjectInfo(true)}>Edit Information</ButtonWithOutline>
            <ODHSpace w={10} />
            <ButtonWithOutline
              onClick={() => setIsOpenDeleteProject(true)}
              style={{ border: `1px solid ${ODColors.Salmon}`, color: ODColors.Salmon }}
            >
              Delete project
            </ButtonWithOutline>
          </Row>
        </ColumnRow>
        <ODVSpace h={30} />
        <div style={{ width: '100%', height: 1, backgroundColor: ODColors.Silver }} />
        <ODVSpace h={30} />
        <ColumnRow>
          <Title>Project Metadata</Title>
          <ODVSpace h={30} />
          <SingleQueryWrapper deps={[projectId, metaDataToken]} getter={() => getProjectMetaData({ id: projectId })}>
            {({ data }) => {
              const parsedData: IMetaData[] = data.rawData ? JSON.parse(data.rawData) : []

              return (
                <>
                  <ODModalEditMetadata
                    isOpen={isOpenEditMetadata}
                    onClose={() => setIsOpenEditMetadata(false)}
                    onSave={rawData => handleUpdateMetaData(rawData)}
                    existingMetaData={parsedData}
                  />
                  <Table responsive striped style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}>
                    <thead>
                      <tr>
                        <th>Key</th>
                        <th>Column name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parsedData.map(item => {
                        return (
                          <tr key={item.key}>
                            <td>{item.key}</td>
                            <td>{item.columnName}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </>
              )
            }}
          </SingleQueryWrapper>
          <ODVSpace h={22} />
          <Row>
            <ButtonWithOutline onClick={() => setIsOpenCopyFromPreset(true)}>Copy from Preset</ButtonWithOutline>
            <ODHSpace w={10} />
            <ButtonWithOutline onClick={() => setIsOpenEditMetadata(true)}>Edit Metadata</ButtonWithOutline>
          </Row>
        </ColumnRow>
      </CardBody>
    </Card>
  )
}

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  color: ${ODColors.Primary};
`

const Row = styled.div`
  display: flex;
`

const ProjectInfoLabel = styled.span`
  width: 100%;
  max-width: 116px;
  font-size: 14px;
  font-weight: bold;
  color: ${ODColors.Steel};
`
const ProjectInfoValue = styled.span`
  font-size: 14px;
  color: ${ODColors.CharcoalGreyTwo};
`

const ButtonWithOutline = styled.button`
  width: 100%;
  max-width: 176px;
  height: 35px;
  border-radius: 3px;
  border: 1px solid ${ODColors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${ODColors.Primary};
`
