import React from 'react'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../../../../components/ODIcon'

interface ICallOutBoxProps {
  wrapperAttr?: React.HTMLAttributes<HTMLDivElement>
  icon: ODIcons
  name: string
  email: string
}

const Wrapper = styled.div`
  width: 100%;
  height: 56px;
  border: solid 1px var(--primary);
  background-color: #e9f7f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 18px;
  color: var(--primary);
`

const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: var(--primary);
  margin-left: 11px;
`

const Email = styled.div`
  font-size: 12px;
  color: var(--primary);
  margin-left: 11px;
`

export const CallOutBox: React.FC<ICallOutBoxProps> = props => {
  const { wrapperAttr = {}, icon, name, email } = props
  return (
    <Wrapper {...wrapperAttr}>
      <div style={{ transform: 'scale(1.2)' }}>
        <ODIcon icon={icon} />
      </div>
      <Name>{name}</Name>
      <Email>{email}</Email>
    </Wrapper>
  )
}
