import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import { GQLProjectMetaDataPreset } from '../../../../@types/server'
import { BlockingLoadBox } from '../../../../components/BlockingLoadBox'
import { ODSearchBox } from '../../../../components/ODSearchBox'
import { useAPIs } from '../../../../context/useAPIs'
import { ODColors } from '../../../../global-styles'
import { ODListableContextType, ODListableOption } from '../../../../ODListable/ODListableContext'
import { SiteUrls } from '../../../../urls'
import { IProjectMetadataListableOption } from '../ProjectMetadataCommon'

interface IProjectMetaDataSearch<T, O extends ODListableOption> {
  listableContext: React.Context<ODListableContextType<T, O>>
  orgId: number
}

export const ProjectMetaDataSearch: React.FC<IProjectMetaDataSearch<
  GQLProjectMetaDataPreset,
  IProjectMetadataListableOption
>> = props => {
  const { listableContext, orgId } = props
  const {
    updateLoadOption,
    state: { loadOption },
  }: ODListableContextType<GQLProjectMetaDataPreset, IProjectMetadataListableOption> = React.useContext(listableContext)
  const [keyword, setKeyword] = React.useState('')

  const handleChange = (v: string) => {
    setKeyword(v)
    updateLoadOption({ ...loadOption, filter: v })
  }

  return (
    <Container
      fluid
      style={{
        backgroundColor: 'white',
        borderBottom: '1px solid #c8ced3',
      }}
    >
      <div>
        <Row>
          <Col md={8} className="vcenter">
            <RowWrapper style={{ alignItems: 'center' }}>
              <ODSearchBox
                placeholder="Search by Project name"
                value={keyword}
                onChange={handleChange}
                style={{ width: '100%', maxWidth: 602, paddingTop: 12 }}
              />
            </RowWrapper>
          </Col>
          <Col md={4} className="vcenter">
            <RowWrapper style={{ justifyContent: 'flex-end' }}>
              <Link to={SiteUrls.OrgAdmin.ProjectMetadata.Add(orgId)} style={{ textDecoration: 'none' }}>
                <Button>
                  <ButtonText>New Preset</ButtonText>
                </Button>
              </Link>
            </RowWrapper>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

const Button = styled.div`
  width: 135px;
  min-width: 135px;
  max-width: 135px;
  height: 35px;
  padding: 6px 0 7px 1px;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ButtonText = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 72px;
  background-color: #ffffff;
`
