import React from 'react'
import { Button } from 'reactstrap'
import { ODEntityInput, ODEntityInputProps } from './ODEntityInput'

type ODApiKeyInputProps<C> = {
  inputProps: ODEntityInputProps<C>
  buttonTitle: string
  onClickIssue: () => void
}

export function ODApiKeyInput<C>(props: ODApiKeyInputProps<C>) {
  const { inputProps, buttonTitle, onClickIssue } = props

  return (
    <ODEntityInput {...inputProps} readOnly prependChildren>
      <Button style={{ minWidth: 150, marginRight: 10 }} color="primary" outline type="button" onClick={onClickIssue}>
        {buttonTitle}
      </Button>
    </ODEntityInput>
  )
}
