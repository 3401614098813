import { USER_PRIV, WORKSET_USER_PERM } from '@cils/common'
import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import {
  GQLListableUser,
  GQLListableUserGroup,
  GQLListableUserGroupInput,
  GQLListableUserInput,
} from '../../@types/server'
import { useODQuery } from '../../context/ODCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { GQL_LIST_USER_GROUP, GQL_LIST_USERS_FOR_WORKSET_PERM } from '../../gqls'
import { ODButton, ODButtonSize, ODButtonTheme } from '../ODButton'

interface ISelectWorksetMemberAndPermissionProps {
  onSubmit: (member: SelectOptionType, worksetUserPermToAdd: number) => Promise<any>
  isRemoving: boolean // removing 용인가? 이 경우 worksetUserPermToAdd 에는 0 값이 들어온다.
  disabled: boolean
  options?: SelectOptionType[]
}

export type SelectOptionType = {
  value: number // userId or userGroupId
  key: string // user_{userId} or group_{userGroupId}
  label: string // label
}

const WORKSET_USER_PERM_OPTIONS: SelectOptionType[] = [
  { key: WORKSET_USER_PERM.Viewer.toString(), label: 'Viewer', value: WORKSET_USER_PERM.Viewer },
  { key: WORKSET_USER_PERM.Collaborator.toString(), label: 'Collaborator', value: WORKSET_USER_PERM.Collaborator },
  { key: WORKSET_USER_PERM.Editor.toString(), label: 'Editor', value: WORKSET_USER_PERM.Editor },
  // { key: WORKSET_USER_PERM.Owner, label: 'Owner', value: WORKSET_USER_PERM.Owner },
]

export const SelectWorksetMemberAndPermission: React.FC<ISelectWorksetMemberAndPermissionProps> = props => {
  const { onSubmit, isRemoving, disabled, options } = props
  const { orgId } = useODSingleOrgContext()
  const apiFindUser = useODQuery<GQLListableUserInput, GQLListableUser>(GQL_LIST_USERS_FOR_WORKSET_PERM)
  const apiFindUserGroup = useODQuery<GQLListableUserGroupInput, GQLListableUserGroup>(GQL_LIST_USER_GROUP)

  const [permToAdd, setPermToAdd] = React.useState(WORKSET_USER_PERM.Viewer)
  const [memberToAdd, setMemberToAdd] = React.useState<SelectOptionType | null>(null)

  const findUser = React.useCallback(
    async (inputValue: string): Promise<Array<SelectOptionType>> => {
      const r = await apiFindUser({
        filter: inputValue,
        priv: [USER_PRIV.Normal, USER_PRIV.SuperAdmin],
        page: 1,
        pageSize: 30,
      })
      const r2 = await apiFindUserGroup({
        filter: inputValue,
        page: 1,
        pageSize: 30,
        orgId,
      })

      const UserList = r.list.map(user => {
        return { value: user.userId, key: 'user_' + user.userId.toString(), label: `${user.name} : ${user.email}` }
      })
      const UserGroupList = r2.list.map(group => {
        return { value: group.groupId, key: 'group_' + group.groupId.toString(), label: `Group: ${group.name}` }
      })

      return UserGroupList.concat(UserList)
    },
    [orgId, apiFindUser, apiFindUserGroup]
  )

  const handleAddMemberChange = (v: any) => {
    setMemberToAdd(v)
    // setMembersToAdd(existing => [...existing, v])
  }

  const handleAddMember = async () => {
    if (!memberToAdd) {
      return
    }

    try {
      await onSubmit(memberToAdd, isRemoving ? 0 : permToAdd)
    } catch (ex) {
      //
    }
  }

  const title = !isRemoving ? 'Add Member or Group to Workset' : 'Remove Member or Group from Workset'

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 33 }}>
        <Title>{title}</Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 12,
          }}
        >
          {options && (
            <CustomSelectBox
              isDisabled={disabled}
              cacheOptions={false}
              defaultOptions
              isMulti={false}
              options={options}
              onChange={handleAddMemberChange}
              value={memberToAdd}
            />
          )}
          {!options && (
            <CustomAsyncSelectBox
              isDisabled={disabled}
              cacheOptions={false}
              defaultOptions
              isMulti={false}
              loadOptions={findUser}
              onChange={handleAddMemberChange}
              value={memberToAdd}
            />
          )}
          {!isRemoving && (
            <WorksetUserPermSelectBox
              isDisabled={disabled}
              cacheOptions={false}
              defaultOptions
              isMulti={false}
              options={WORKSET_USER_PERM_OPTIONS}
              value={WORKSET_USER_PERM_OPTIONS.find(v => v.value === permToAdd)!}
              onChange={(v: SelectOptionType) => setPermToAdd(v.value)}
            />
          )}
        </div>
        <InviteDesc>Type Name, Email or Group Name</InviteDesc>
        <ODButton
          disabled={disabled}
          fullWidth
          size={ODButtonSize.Small}
          theme={isRemoving ? ODButtonTheme.Danger : ODButtonTheme.Primary}
          onClick={handleAddMember}
          style={{ marginTop: 18 }}
        >
          {isRemoving ? 'Remove' : 'Add'}
        </ODButton>
      </div>
    </>
  )
}

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`

const CustomSelectBox = styled(Select)`
  flex-grow: 3;
`

const CustomAsyncSelectBox = styled(AsyncSelect)`
  flex-grow: 3;
`

const WorksetUserPermSelectBox = styled(Select)`
  flex-grow: 0;
  margin-left: 8px;
  min-width: 166px;
`

const InviteDesc = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #73818f;
`
