import { PROJECT_ITEM_USER_STATUS } from '@cils/common'
import { ODHSpace, ODVSpace, useCounter, useODListableContext, useODModalConfirm } from '@odc/od-react-belt'
import { Decimal } from 'decimal.js'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import { Card, CardBody } from 'reactstrap'
import {
  GQLITEM_EXECUTION_ASSIGN_FILTER,
  GQLITEM_EXECUTION_CONFIRM_FILTER,
  GQLITEM_EXECUTION_LOCK_FILTER,
  GQLITEM_EXECUTION_SORT_OPTION,
  GQLItemExecution,
  GQLPROJECT_ITEM_USER_STATUS,
} from '../../../../@types/server'
import { GQLQueueProjectDownloadInput } from '../../../../agent'
import { ODIcons } from '../../../../components/ODIcon'
import { NotEnoughStorageBody } from '../../../../components/ODModal/NotEnoughStorageBody'
import { ProjectDataActiveFilters } from '../../../../components/project/data/ProjectDataActiveFilters'
import { ProjectDataTable } from '../../../../components/project/data/ProjectDataTable'
import { ProjectAssigneeDropDown } from '../../../../components/project/dropdown/ProjectAssigneeDropDown'
import { ProjectConfirmedDropDown } from '../../../../components/project/dropdown/ProjectConfirmedDropDown'
import { ProjectLockedDropDown } from '../../../../components/project/dropdown/ProjectLockedDropDown'
import { ProjectReviewerDropDown } from '../../../../components/project/dropdown/ProjectReviewerDropDown'
import { ProjectSortDropDown } from '../../../../components/project/dropdown/ProjectSortDropDown'
import { ProjectStatusDropDown } from '../../../../components/project/dropdown/ProjectStatusDropDown'
import { WorksetsInProjectDropDown } from '../../../../components/project/dropdown/WorksetsInProjectDropDown'
import { useCILSAgentContext } from '../../../../context/CILSAgentContext'
import { ODColors } from '../../../../global-styles'
import { useProjectPerm } from '../../../../hooks/useProjectPerm'
import { Utils } from '../../../../utils'
import { WorksetBigActionButton } from '../../TCFItems/ItemsList'
import { IProjectDataListableOption, useProjectDataListDataLoader } from '../list/useProjectDataListDataLoader'
import { ItemExecutionOptionFilter, ProjectSelectOptionType, selectColorForProjectStatus } from '../ProjectCommon'
import { ProjectDataSearch } from './ProjectDataSearch'

interface IProjectDataPage {
  projectId: number
  orgId: number
  ownerOrgId: number
  myProjectPerm: number
  numConfirmedItems: number
  pageRefresh: () => void
  projectName: string
}

export interface IProjectActiveFilter {
  color: string
  label: string
  onClose: () => void
}

export const ProjectDataPage: React.FC<IProjectDataPage> = props => {
  const { projectId, orgId, myProjectPerm, numConfirmedItems, pageRefresh, projectName } = props
  const projectPerm = useProjectPerm(orgId)

  const [filterWorkset, setFilterWorkset] = React.useState<ProjectSelectOptionType<number> | null>(null)
  const [filterLocked, setFilterLocked] = React.useState<ProjectSelectOptionType<
    ItemExecutionOptionFilter<GQLITEM_EXECUTION_LOCK_FILTER>
  > | null>(null)
  const [filterAssignee, setFilterAssignee] = React.useState<ProjectSelectOptionType<
    ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>
  > | null>(null)
  const [filterAssigneeStatus, setFilterAssigneeStatus] = React.useState<GQLPROJECT_ITEM_USER_STATUS | null>(null)
  const [filterReviewer, setFilterReviewer] = React.useState<ProjectSelectOptionType<
    ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>
  > | null>(null)
  const [filterReviewerStatus, setFilterReviewerStatus] = React.useState<GQLPROJECT_ITEM_USER_STATUS | null>(null)
  const [filterConfirmed, setFilterConfirmed] = React.useState<GQLITEM_EXECUTION_CONFIRM_FILTER | null>(null)
  const [filterSort, setFilterSort] = React.useState<GQLITEM_EXECUTION_SORT_OPTION | null>(null)
  const dataLoader = useProjectDataListDataLoader(
    filterWorkset?.value ?? null,
    filterLocked ?? null,
    filterAssignee ?? null,
    filterAssigneeStatus ?? null,
    filterReviewer ?? null,
    filterReviewerStatus ?? null,
    filterConfirmed ?? null,
    filterSort ?? null,
    projectId
  )
  const { Provider, Context } = useODListableContext<GQLItemExecution, IProjectDataListableOption>()
  const [token, refresh] = useCounter()
  const [queueDownloading, setQueueDownloading] = React.useState(false)
  const [selectedIds, setSelectedIds] = React.useState<number[]>([])

  const clearFilters = () => {
    setFilterWorkset(null)
    setFilterLocked(null)
    setFilterAssignee(null)
    setFilterAssigneeStatus(null)
    setFilterReviewer(null)
    setFilterReviewerStatus(null)
    setFilterConfirmed(null)
  }
  const activeFilters: IProjectActiveFilter[] = React.useMemo(() => {
    const filters: IProjectActiveFilter[] = []

    if (filterWorkset) {
      filters.push({
        color: ODColors.DarkPeriwinkle,
        label: filterWorkset.label,
        onClose: () => setFilterWorkset(null),
      })
    }

    if (filterLocked) {
      filters.push({
        color: ODColors.FadedBlue,
        label: `Locked : ${filterLocked.label}`,
        onClose: () => setFilterLocked(null),
      })
    }

    if (filterAssignee) {
      filters.push({
        color: ODColors.FadedBlue,
        label: `Assignee : ${filterAssignee.label}`,
        onClose: () => setFilterAssignee(null),
      })
    }

    if (filterAssigneeStatus) {
      filters.push({
        color: selectColorForProjectStatus(filterAssigneeStatus),
        label: `Assignee : ${
          Utils.convertProjectItemUserStatus((filterAssigneeStatus as unknown) as PROJECT_ITEM_USER_STATUS).label
        }`,
        onClose: () => setFilterAssigneeStatus(null),
      })
    }

    if (filterReviewer) {
      filters.push({
        color: ODColors.FadedBlue,
        label: `Reviewer : ${filterReviewer.label}`,
        onClose: () => setFilterReviewer(null),
      })
    }

    if (filterReviewerStatus) {
      filters.push({
        color: selectColorForProjectStatus(filterReviewerStatus),
        label: `Reviewer : ${
          Utils.convertProjectItemUserStatus((filterReviewerStatus as unknown) as PROJECT_ITEM_USER_STATUS).label
        }`,
        onClose: () => setFilterReviewerStatus(null),
      })
    }

    if (filterConfirmed) {
      filters.push({
        color: ODColors.Steel,
        label: filterConfirmed,
        onClose: () => setFilterConfirmed(null),
      })
    }

    return filters
  }, [
    filterWorkset,
    filterLocked,
    filterAssignee,
    filterAssigneeStatus,
    filterReviewer,
    filterReviewerStatus,
    filterConfirmed,
  ])

  const { queueProjectDownload, checkProjectDownload, getFreeDiskSpaceForDownload } = useCILSAgentContext()

  const {
    Component: DownloadConfirmComponent,
    confirm: confirmDownload,
    props: DownloadConfirmComponentProps,
  } = useODModalConfirm({ title: 'Not enough storage', yes: 'Download', no: 'Cancel' })

  const handleQueueDownload = async (
    input: { hasSelectedIds?: boolean } & Partial<Pick<GQLQueueProjectDownloadInput, 'withResults' | 'onlyResults'>>
  ) => {
    const queueDownloadInput: Partial<GQLQueueProjectDownloadInput> = {
      projectId,
    }

    if (input.hasSelectedIds) {
      queueDownloadInput.selectedIds = [...selectedIds]
    }

    if (input.withResults || typeof input.withResults === 'boolean') {
      queueDownloadInput.withResults = input.withResults
    }

    if (input.onlyResults || typeof input.withResults === 'boolean') {
      queueDownloadInput.onlyResults = input.onlyResults
    }

    try {
      setQueueDownloading(true)
      const checkedSize = await checkProjectDownload(queueDownloadInput)

      let availableSize = new Decimal(999999999999)

      try {
        const freeSpace = await getFreeDiskSpaceForDownload()
        availableSize = new Decimal(freeSpace.output)
      } catch (ex) {
        Utils.showError('PC 의 용량 체크에 실패하였습니다.')
        console.error(ex)
      }
      const requiredSize = new Decimal(checkedSize.output)

      setQueueDownloading(false)

      if (requiredSize.gt(availableSize)) {
        if (
          !(await confirmDownload({
            message: (
              <NotEnoughStorageBody
                downloadSize={prettyBytes(requiredSize.toNumber())}
                freeSpace={prettyBytes(availableSize.toNumber())}
              />
            ),
          }))
        ) {
          return
        }
      }

      setQueueDownloading(true)

      const res = await queueProjectDownload(queueDownloadInput)
      const numDownload = res.ok
      Utils.showSuccess(`Queued ${numDownload} items for download.`)
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setQueueDownloading(false)
    }
  }

  return (
    <Provider
      dataLoader={dataLoader}
      keyExtractor={v => v.ieId.toString()}
      pageSize={1000}
      onDataLoaderError={Utils.showError}
      searchOnLoad
      refreshToken={token.toString()}
    >
      <DownloadConfirmComponent {...DownloadConfirmComponentProps} />
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardBody>
          <ProjectDataSearch orgId={orgId} listableContext={Context} numConfirmedItems={numConfirmedItems}>
            <WorksetsInProjectDropDown disabled={false} projectId={projectId} setValue={setFilterWorkset} />
            <ODHSpace w={5} />
            <ProjectLockedDropDown value={filterLocked} setValue={setFilterLocked} projectId={projectId} />
            <ODHSpace w={5} />
            <ProjectAssigneeDropDown value={filterAssignee} setValue={setFilterAssignee} projectId={projectId} />
            <ODHSpace w={5} />
            <ProjectStatusDropDown
              dropDownName={'Assignee Status'}
              value={(filterAssigneeStatus as unknown) as PROJECT_ITEM_USER_STATUS}
              setValue={value => setFilterAssigneeStatus(value)}
            />
            <ODHSpace w={5} />
            <ProjectReviewerDropDown value={filterReviewer} setValue={setFilterReviewer} projectId={projectId} />
            <ODHSpace w={5} />
            <ProjectStatusDropDown
              dropDownName={'Reviewer Status'}
              value={(filterReviewerStatus as unknown) as PROJECT_ITEM_USER_STATUS}
              setValue={value => setFilterReviewerStatus(value)}
            />
            <ODHSpace w={5} />
            <ProjectConfirmedDropDown value={filterConfirmed} setValue={setFilterConfirmed} />
            <ODHSpace w={5} />
            <ProjectSortDropDown setFilterSort={setFilterSort} />
          </ProjectDataSearch>
          {/* 뷰어 권한이라면 아래 다운로드 버튼들을 표시하지 않는다. */}
          {/*
          클릭 시 Agent를 통해 프로젝트의 모든 데이터 자체와 데이터 중 Finished(Success),
          Confirmed 상태의 프로젝트 결과 산출물 모두 다운로드 시작
          */}
          {/*
          클릭 시 Agent를 통해 프로젝트의 모든 데이터 중 Finished(Success) 와 Confirmed 상태의 데이터의 프로젝트 결과 산출물만 다운로드 시작
          */}
          {projectPerm.isSameOrGreaterThanEditor(myProjectPerm) && (
            <div style={{ display: 'flex' }}>
              <WorksetBigActionButton
                icon={ODIcons.CoreDataTransferDown}
                onClick={() => handleQueueDownload({ withResults: true })}
                title="Download all items and results"
                subText={''}
                width={327}
                customStyle={{ marginLeft: -36, marginTop: 20 }}
              />
              <ODHSpace w={10} />
              <WorksetBigActionButton
                icon={ODIcons.CoreDataTransferDown}
                onClick={() => handleQueueDownload({ onlyResults: true })}
                title="Download all results"
                subText={''}
                width={256}
                customStyle={{ marginLeft: -26, marginTop: 20 }}
              />
            </div>
          )}
          {!!activeFilters && activeFilters.length > 0 && (
            <>
              <ODVSpace h={40} />
              <ProjectDataActiveFilters activeFilters={activeFilters} clearFilters={clearFilters} />
            </>
          )}
          <ODVSpace h={40} />
          <ProjectDataTable
            orgId={orgId}
            projectId={projectId}
            listableContext={Context}
            myProjectPerm={myProjectPerm}
            refreshList={refresh}
            pageRefresh={pageRefresh}
            handleQueueDownload={handleQueueDownload}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            projectName={projectName}
          />
        </CardBody>
      </Card>
    </Provider>
  )
}
