import React, { FormEvent } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { Subject } from 'rxjs'
import { ODIcon, ODIcons } from './ODIcon'

interface Props {
  placeholder: string
  style?: object
  formGroupStyle?: object
  value: string
  onChange: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  resetKeywordOnLocationChange?: boolean
}

export function ODSearchBox(props: Props) {
  const {
    placeholder,
    style = {},
    formGroupStyle = {},
    value,
    onChange,
    onKeyDown,
    resetKeywordOnLocationChange,
  } = props

  const [keyword, setKeyword] = React.useState('')
  const submitSubject = React.useRef(new Subject<string>())
  const typingSubject = React.useRef(new Subject<string>())
  const location = useLocation()

  const runSearch = React.useCallback(
    (search: string) => {
      const trimmed = search.trim()
      if (value !== trimmed) {
        onChange(trimmed)
      }
    },
    [value, onChange]
  )

  React.useEffect(() => {
    if (resetKeywordOnLocationChange) {
      setKeyword('')
      onChange('')
    }
    // onChange 를 deps 에 넣으니 계속 호출되는 문제가 있다..
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, resetKeywordOnLocationChange, setKeyword])

  const handleSubmit = React.useCallback((e: FormEvent, keyword: string) => {
    e?.preventDefault()
    submitSubject.current.next(keyword.trim())
  }, [])

  React.useEffect(() => {
    if (keyword === '') {
      runSearch(keyword)
    }
  }, [keyword, runSearch])

  return (
    <div style={style}>
      <Form onSubmit={e => handleSubmit(e, keyword)}>
        <FormGroup style={formGroupStyle}>
          <InputGroup size="normal">
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ backgroundColor: 'transparent', borderRightColor: 'transparent' }}>
                <ODIcon icon={ODIcons.CoreSearch} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              style={{ borderLeftColor: 'transparent' }}
              type="search"
              placeholder={placeholder}
              value={keyword}
              onChange={e => {
                setKeyword(e.target.value)
                if (e.target.value.trim() === '') {
                  submitSubject.current.next('')
                } else {
                  typingSubject.current.next(e.target.value.trim())
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  runSearch(keyword)
                }
                onKeyDown?.(e)
              }}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    </div>
  )
}
