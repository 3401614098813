import React, { useRef, useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import styled from 'styled-components'
import { Utils } from '../../utils'
import { ODButton, ODButtonTheme } from '../ODButton'
import { EDIT_TYPE } from '../ODImageGrid/ImageAction'
import { ODModal } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
`
const InputBox = styled.input`
  width: 100%;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #73818f;
  margin-top: 9px;
`
const DescText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #73818f;
`
const SelectWrapper = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
`
const SelectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SelectableItemTitle = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 4px;
  margin-bottom: 14px;
  color: #2e343a;
`
const SelectableItemDescription = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f353a;
`

type ODModalTagInBulkProps = {
  onClose: () => void
  onSave: (v: { tags: string[]; editType: EDIT_TYPE }) => Promise<void>
  title: string
}

export const ODModalTagInBulk: React.FC<ODModalTagInBulkProps> = props => {
  const { onClose, onSave, title } = props

  const [inputTags, setInputTags] = useState<string>('')
  const [type, setType] = useState<EDIT_TYPE>(EDIT_TYPE.add)
  const inputFocus = useRef<HTMLInputElement>(null)

  const handleModalClose = async (v: { inputTags: string; type: EDIT_TYPE }) => {
    if (!inputTags) {
      Utils.showError('태그를 입력해주세요.')
      return
    }

    const arr = v.inputTags.split(',')
    await onSave({ tags: arr, editType: v.type })
    onClose()
  }

  return (
    <ODModal isOpen toggle={() => null}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={title} onClose={onClose} />
        <ODModalBody>
          <Title>Tag</Title>
          <InputBox ref={inputFocus} type="text" value={inputTags} onChange={e => setInputTags(e.target.value)} />
          <div style={{ display: 'flex', marginBottom: 33, marginTop: 8 }}>
            <DescText>Enter comma-separated values</DescText>
          </div>
          <Title>Privacy</Title>
          <SelectsWrapper style={{ marginTop: 20 }}>
            <SelectWrapper>
              <FormGroup check className="radio">
                <Input
                  className="form-check-input"
                  type="radio"
                  id="add"
                  name="editType"
                  value={EDIT_TYPE.add}
                  checked={type === EDIT_TYPE.add}
                  onChange={() => setType(EDIT_TYPE.add)}
                />
                <Label check className="form-check-label" htmlFor="editType">
                  <SelectableItemTitle>Add Tags</SelectableItemTitle>
                  <SelectableItemDescription>Keep current tags</SelectableItemDescription>
                </Label>
              </FormGroup>
            </SelectWrapper>
            <SelectWrapper>
              <FormGroup check className="radio">
                <Input
                  className="form-check-input"
                  type="radio"
                  id="replace"
                  name="editType"
                  value={EDIT_TYPE.replace}
                  checked={type === EDIT_TYPE.replace}
                  onChange={() => setType(EDIT_TYPE.replace)}
                />
                <Label check className="form-check-label" htmlFor="editType">
                  <SelectableItemTitle>Replace Tags</SelectableItemTitle>
                  <SelectableItemDescription>Delete current tags and add new tags</SelectableItemDescription>
                </Label>
              </FormGroup>
            </SelectWrapper>
          </SelectsWrapper>
        </ODModalBody>
        <ODModalFooter>
          <ODButton
            theme={ODButtonTheme.Primary}
            onClick={() => handleModalClose({ inputTags, type })}
            style={{ width: '100%' }}
          >
            Save
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}
