"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.populateCilsStoragePropsInput = exports.populateAttachmentTypePropsInput = exports.populateAgentPropsInput = exports.populateCategoryPropsInput = exports.populateApiKeyPropsInput = exports.populateOrgPropsInput = void 0;

var faker = require("faker");

function populateOrgPropsInput() {
  return {
    name: faker.company.companyName()
  };
}

exports.populateOrgPropsInput = populateOrgPropsInput;

function populateApiKeyPropsInput() {
  return {
    name: "Key for ".concat(faker.company.companyName())
  };
}

exports.populateApiKeyPropsInput = populateApiKeyPropsInput;

function populateCategoryPropsInput() {
  return {
    name: faker.hacker.noun(),
    color: 'ff0000ff'
  };
}

exports.populateCategoryPropsInput = populateCategoryPropsInput;

function populateAgentPropsInput() {
  return {
    name: faker.hacker.noun(),
    email: faker.internet.email(),
    password: '12345678'
  };
}

exports.populateAgentPropsInput = populateAgentPropsInput;

function populateAttachmentTypePropsInput() {
  return {
    name: faker.hacker.noun()
  };
}

exports.populateAttachmentTypePropsInput = populateAttachmentTypePropsInput;

function populateCilsStoragePropsInput() {
  return {
    name: faker.hacker.noun(),
    address: '127.0.0.1:8082',
    addressOptional: '127.0.0.1:4000',
    secret: '123456'
  };
}

exports.populateCilsStoragePropsInput = populateCilsStoragePropsInput;