import React from 'react'
import styled from 'styled-components'
import { LOCAL_STORAGE_KEY_IMAGE_SIZE } from '../../common'
import { Label } from './Common'
import { SelectOptions } from './SelectOptions'

export const IMAGE_SIZE_TOGGLE_NAME = 'ImageSize'

type ImageSizeToggleProps = {
  imageSize: number
  setImageSize: (e: number) => void
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ImageSizeToggle: React.FC<ImageSizeToggleProps> = props => {
  const { imageSize, setImageSize } = props

  return (
    <div style={{ display: 'inline-block' }}>
      <Wrapper>
        <Label style={{ fontWeight: 'bold', minWidth: 106 }}>Image Size</Label>
        <SelectOptions
          buttonWidth={64}
          value={['100', '200', '300']}
          name={IMAGE_SIZE_TOGGLE_NAME}
          selectedValue={imageSize.toString()}
          onChange={v => {
            localStorage.setItem(LOCAL_STORAGE_KEY_IMAGE_SIZE, v)
            setImageSize(parseInt(v, 10))
          }}
        />
      </Wrapper>
    </div>
  )
}
