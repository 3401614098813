import { WORKSET_PERM } from '@cils/common'
import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import {
  GQLAttachmentType,
  GQLItem,
  GQLListableAttachmentType,
  GQLListableAttachmentTypeInput,
  GQLListableCategory,
  GQLListableCategoryInput,
  GQLOrgHasUser,
  GQLSingleIDInput,
  GQLUser,
  GQLWorkset,
  GQLWORKSET_PERM,
  GQLWorksetPropsInput,
} from '../@types/server'
import { SelectOptionType } from '../components/ODModal/SelectWorksetMemberAndPermission'
import { ProjectSelectOptionType } from '../containers/User/Project/ProjectCommon'
import { SiteUrls } from '../urls'
import { Utils } from '../utils'
import { GQL_ITEM_SNAPSHOT, useODAppContext } from './ODAppContext'
import { useCounter, useODMutation, useODQuery } from './ODCommon'
import { useAPIs } from './useAPIs'

type CILSHelperType = {
  mainOrg: GQLOrgHasUser | null
  currentOrg: GQLOrgHasUser | null
  isInsideCILSAdmin: boolean
}

const regexOrgUrl = /^\/organization\/(\d+)/

export function useCILSHelper(): CILSHelperType {
  const {
    state: { userProfile },
  } = useODAppContext()

  const location = useLocation()

  const getCurrentOrg = React.useCallback((url: string, userProfile: GQLUser | null): GQLOrgHasUser | null => {
    // const url: string = props.location.pathname
    const match = url.match(regexOrgUrl)
    if (match) {
      const orgId = parseInt(match[1], 10)
      if (orgId > 0 && userProfile?.organizations) {
        const orgHasUser = userProfile!.organizations.find(o => o.org.orgId === orgId)
        return orgHasUser || null
      }
    }
    return null
  }, [])

  const matchAdmin = useRouteMatch({
    path: SiteUrls.Admin.Root,
    strict: false,
    sensitive: true,
  })

  const [currentOrg, setCurrentOrg] = React.useState<GQLOrgHasUser | null>(null)

  React.useEffect(() => {
    setCurrentOrg(getCurrentOrg(location.pathname, userProfile))
  }, [setCurrentOrg, getCurrentOrg, location.pathname, userProfile])

  return {
    mainOrg: userProfile?.organizations?.[0] || null,
    currentOrg,
    isInsideCILSAdmin: !!matchAdmin,
  }
}

export type CategoryOption = {
  categoryId: number
  value: string
  label: string
}

export type OrgOption = {
  orgId: number
  value: string
  label: string
}

export const GQL_LIST_CATEGORY = `
query listCategory($data: ListableCategoryInput!) {
  listCategory(data: $data) {
    list {
      categoryId
      name
      color
    }
    totalCount
    page
    pageSize
  }
}`

/**
 * 특정 기관의 카테고리 정보 fetch 용
 * @param orgId
 */
export function useCILSCategory(orgId: number) {
  const [categories, setCategories] = React.useState<Array<CategoryOption>>([])

  const refresh = useODQuery<GQLListableCategoryInput, GQLListableCategory>(GQL_LIST_CATEGORY)

  React.useEffect(() => {
    refresh({ orgId, filter: null, page: 1, pageSize: 1000 }).then(v => {
      const categories: Array<CategoryOption> = v.list.map(c => ({
        categoryId: c.categoryId,
        value: c.name!,
        label: c.name!,
      }))
      setCategories(categories)
    })
  }, [orgId, refresh])

  return {
    refresh,
    categories,
  }
}

/**
 *  특정 기관의 워크셋 그룹 리스트 fetch 용 .... 현재는 Add Worksets to... 모달의 드랍다운 메뉴에서 사용 하고 있다.
 */

export function useWorksetGroupList(orgId: number) {
  const [categories, setCategories] = React.useState<Array<CategoryOption>>([])
  const { listWorksetGroupInOrg } = useAPIs()

  const refresh = listWorksetGroupInOrg

  React.useEffect(() => {
    refresh({ orgId, filter: null, page: 1, pageSize: 1000 }).then(v => {
      const categories: Array<CategoryOption> = v.list.map(c => ({
        categoryId: c.wsgId,
        value: c.wsgId.toString(),
        label: c.name!,
      }))
      setCategories(categories)
    })
  }, [orgId, refresh])

  return {
    refresh,
    categories,
  }
}

/**
 *  특정 기관의 워크셋 그룹 리스트 fetch 용 .... 현재는 Add Worksets to... 모달의 드랍다운 메뉴에서 사용 하고 있다.
 */

export interface WorksetGroupOption {
  id: number
  value: string
  label: string
  valueForSubmit: number[]
}

export function useWorksetGroupListHasWorksetIds(orgId: number) {
  const [worksetGroups, setWorksetGroups] = React.useState<Array<WorksetGroupOption>>([])
  const { listWorksetGroupInOrg } = useAPIs()

  const refresh = listWorksetGroupInOrg

  React.useEffect(() => {
    refresh({ orgId, filter: null, page: 1, pageSize: 1000 }).then(v => {
      const categories: Array<WorksetGroupOption> = v.list.map(c => ({
        id: c.wsgId,
        value: c.wsgId.toString(),
        label: c.name,
        valueForSubmit: c.worksets.map(workset => workset.wsId),
      }))
      setWorksetGroups(categories)
    })
  }, [orgId, refresh])

  return {
    refresh,
    worksetGroups,
  }
}

/**
 *  특정 기관의 프로젝트 안의 워크셋 리스트 fetch 용 .... 현재는 Add Worksets to... 모달의 드랍다운 메뉴에서 사용 하고 있다.
 */

export function useWorksetInProjectList(projectId: number) {
  const [worksets, setWorksets] = React.useState<ProjectSelectOptionType<number>[]>([])
  const { listProjectHasWorkset } = useAPIs()

  const refresh = listProjectHasWorkset

  React.useEffect(() => {
    refresh({ projectId, filter: null, page: 1, pageSize: 1000 }).then(v => {
      const listWorkset: Array<ProjectSelectOptionType<number>> = v.list.map(c => ({
        key: c.worksetId,
        value: c.worksetId,
        label: c.workset.name ?? '-',
      }))
      setWorksets(listWorkset)
    })
  }, [projectId, refresh])

  return {
    refresh,
    worksets,
  }
}

/**
 *  특정 기관의 프로젝트 안의 유저 리스트 fetch 용 .... 현재는 Add Worksets to... 모달의 드랍다운 메뉴에서 사용 하고 있다.
 */

export function useUsersInProjectList(projectId: number) {
  const [users, setUsers] = React.useState<Array<ProjectSelectOptionType<number>>>([])
  const [token, refresh] = useCounter()

  const { listProjectHasUser } = useAPIs()

  React.useEffect(() => {
    listProjectHasUser({ projectId, filter: null, page: 1, pageSize: 1000 }).then(v => {
      const listWorkset: Array<ProjectSelectOptionType<number>> = v.list.map(item => ({
        value: item.userId,
        key: item.userId,
        label: `${item.user.name} : ${item.user.email}`,
      }))
      setUsers(listWorkset)
    })
  }, [listProjectHasUser, projectId, token])

  return {
    listProjectHasUser,
    users,
    refresh,
  }
}

/**
 *  특정 기관의 프로젝트 안의 유저 리스트 fetch 용 .... 현재는 Add Worksets to... 모달의 드랍다운 메뉴에서 사용 하고 있다.
 */

export function useProjectMetaDataPresetInOrgList(orgId: number) {
  const [list, setList] = React.useState<Array<SelectOptionType>>([])
  const { listProjectMetaData } = useAPIs()

  const refresh = listProjectMetaData

  React.useEffect(() => {
    refresh({ orgId, filter: null, page: 1, pageSize: 1000 }).then(v => {
      const listPreset: Array<SelectOptionType> = v.list.map(preset => ({
        value: preset.psId,
        key: 'preset_' + preset.psId.toString(),
        label: preset.name,
      }))
      setList(listPreset)
    })
  }, [refresh])

  return {
    refresh,
    list,
  }
}

const GQL_LIST_ATTACHMENT_TYPE = `
query listAttachmentType($data: ListableAttachmentTypeInput!) {
  listAttachmentType(data: $data) {
    list {
      typeId
      name
    }
    totalCount
    page
    pageSize
  }
}
`

/**
 * 특정 기관의 Attachment Types 정보 fetch 용
 */
export function useCILSAttachmentTypes() {
  const [types, setTypes] = React.useState<Array<GQLAttachmentType>>([])

  const refresh = useODQuery<GQLListableAttachmentTypeInput, GQLListableAttachmentType>(GQL_LIST_ATTACHMENT_TYPE)

  React.useEffect(() => {
    refresh({ filter: null, page: 1, pageSize: 1000 }).then(v => {
      setTypes(v.list)
    })
  }, [refresh])

  return {
    refresh,
    types,
  }
}

const GQL_GET_ITEM = `
query getItem($data: SingleIDInput!) {
  getItem(data: $data) {
    ${GQL_ITEM_SNAPSHOT}
  }
}`

export function useGQLGetItem() {
  const api = useODQuery<GQLSingleIDInput, GQLItem>(GQL_GET_ITEM)
  return React.useCallback(
    async (itemId: number) => {
      return api({ id: itemId })
    },
    [api]
  )
}

const GQL_CREATE_WORKSET = `
mutation createWorkset($data: WorksetPropsInput!) {
  createWorkset(data: $data) {
    wsId
    name
  }
}
`

export function useCreateWorksetHooks(orgId: number, afterSuccess: (wsId: number, name: string) => Promise<void>) {
  const [isLoading, setIsLoading] = React.useState(false)

  const apiCreateWorkset = useODMutation<Partial<GQLWorksetPropsInput>, GQLWorkset>(GQL_CREATE_WORKSET)
  const handleAddWorksetModal = React.useCallback(
    async (v: { name: string; perm: WORKSET_PERM }) => {
      setIsLoading(true)
      try {
        const r = await apiCreateWorkset({
          name: v.name,
          ownerOrgId: orgId,
          defaultPerm: (v.perm as unknown) as GQLWORKSET_PERM,
        })
        Utils.showSuccess(`Successfully created a workset.`, 'Success')
        await afterSuccess(r.wsId, r.name || '')
        return true
      } catch (ex) {
        Utils.showError(ex)
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [apiCreateWorkset, setIsLoading, afterSuccess, orgId]
  )

  return { isLoading, handleAddWorksetModal }
}
