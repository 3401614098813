import React from 'react'
import { useRecoilState } from 'recoil'
import { globalLoadingState } from '../context/atoms'
import { BlockingLoadBox } from './BlockingLoadBox'

export const TopLevelLoadBox: React.FC = () => {
  const [state] = useRecoilState(globalLoadingState)

  return <BlockingLoadBox show={state.show} message={state.message ?? 'Loading..'} />
}
