import { ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { ODButton, ODButtonTheme } from '../ODButton'
import { ODToastType, showODToast } from '../ODToast'
import { ODModal, ODModalSize } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalFooter } from './ODModalFooter'
import { ODModalHeader } from './ODModalHeader'

interface IODModalCreateWorksetGroup {
  isOpen: boolean
  onClose: () => void
  onCreate: (name: string) => void
}

export const ODModalCreateWorksetGroup: React.FC<IODModalCreateWorksetGroup> = props => {
  const { isOpen, onClose, onCreate } = props
  const [groupName, setGroupName] = React.useState('')

  const handleCreate = React.useCallback(() => {
    if (!groupName || groupName === '') {
      showODToast(ODToastType.Error, 'Error', 'Name is required.')
      return
    }
    onCreate(groupName)
    setGroupName('')
    onClose()
  }, [groupName, onCreate])

  const modalTitle = 'New Workset Group'
  const inputName = 'Workset Group Name *'
  return (
    <ODModal isOpen={isOpen} toggle={onClose} size={ODModalSize.Normal} style={{ borderRadius: 4 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ODModalHeader title={modalTitle} onClose={onClose} />
        <ODModalBody>
          <InputNameRow>{inputName}</InputNameRow>
          <ODVSpace h={9} />
          <Input type="text" placeholder="" onChange={e => setGroupName(e.target.value)} value={groupName} />
        </ODModalBody>
        <ODModalFooter>
          <ODButton fullWidth theme={ODButtonTheme.Primary} onClick={handleCreate}>
            Create
          </ODButton>
        </ODModalFooter>
      </div>
    </ODModal>
  )
}

const InputNameRow = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #73818f;
`
