import { ODHSpace, ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { CILSLoading } from '../../../components/CommonComponents/CILSLoading'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { ODModal, ODModalSize } from '../../../components/ODModal/ODModal'
import { ODModalBody } from '../../../components/ODModal/ODModalBody'
import { ODModalHeader } from '../../../components/ODModal/ODModalHeader'
import { useODAppContext } from '../../../context/ODAppContext'
import { ODColors } from '../../../global-styles'

interface IOpeningTomoAnalysisModalProps {
  isOpen: boolean
  onClose: () => void
}

const BigText = styled.div`
  font-size: 18px;
  line-height: 1.56;
  text-align: center;
  color: #2f353a;
`

const RedText = styled.div`
  font-size: 23px;
  font-weight: bold;
  color: #f86c6b;
`

const GrayText = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: #73818f;
`

const DownloadText = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${ODColors.Primary};
  cursor: pointer;
  text-decoration: underline;
  padding-top: 2px;
`

export const OpeningTomoAnalysisModal: React.FC<IOpeningTomoAnalysisModalProps> = props => {
  const { isOpen, onClose } = props
  const {
    state: { appConfig },
  } = useODAppContext()

  const link = appConfig?.agentDownloadLink ?? ''

  return (
    <ODModal
      isOpen={isOpen}
      toggle={() => null}
      size={ODModalSize.Big}
      style={{ top: `calc((100% - 540px)/2 - 120px)` }}
    >
      <FlexContentsContainer>
        <div style={{ height: 540, display: 'flex', flexDirection: 'column' }}>
          <ODModalHeader title="Opening TCF in TomoAnalysis..." onClose={onClose} headerBottomPadding={30} />
          <ODModalBody style={{ flexGrow: 99, backgroundColor: 'red', padding: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div style={{ backgroundColor: '#f9f9f9', flexGrow: 99 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <BigText>
                    To view TCF data in TomoAnalysis, you need to download data in your PC.
                    <br />
                    To check the download status, the agent or downloader must be running.
                  </BigText>
                  <ODVSpace h={25} />
                  <RedText>Cannot connect to the Agent or Downloader</RedText>
                  <ODVSpace h={37} />
                  <CILSLoading />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  height: 129,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <GrayText>Don’t have the agent or downloader?</GrayText>
                  <ODHSpace w={11} />
                  <a href={link}>
                    <DownloadText>Download CILS Agent</DownloadText>
                  </a>
                </div>
              </div>
            </div>
          </ODModalBody>
        </div>
      </FlexContentsContainer>
    </ODModal>
  )
}
