import { ORG_ROLE, ORG_USER_PRIV, ORG_USER_PRIV_TO_STRING, PermUtils } from '@cils/common'
import { useCounter } from '@odc/od-react-belt'
import React from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { GQLOrgHasUser, GQLUpdateOrgHasUserInput } from '../../../../@types/server'
import { BlockingLoadBox } from '../../../../components/BlockingLoadBox'
import { ODIcons } from '../../../../components/ODIcon'
import { useOrgPerm } from '../../../../context/useOrgPerm'
import { Utils } from '../../../../utils'
import { CallOutBox } from './CallOutBox'
import { OrgPermissionTable } from './OrgPermissionTable'
import { OrgUserPrivSelect } from './OrgUserPrivSelect'

type Props = {
  orgHasUser: GQLOrgHasUser
  onUpdate: (input: GQLUpdateOrgHasUserInput) => Promise<any>
  onReject?: () => void
  onClose: (updated: boolean) => void
  noSuccessToast?: boolean
}

const UserTypeRow = styled.div`
  margin-top: 13px;
  margin-bottom: 14px;
  padding-left: 14px;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  height: 40px;
`

const UserTypeColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const UserType = styled.div`
  width: 174px;
  font-size: 14px;
  font-weight: bold;
  color: #2f353a;
`

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`

const DisabledPrivSelect = styled.div`
  width: 300px;
  height: 34px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #e4e7ea;
  display: flex;
  align-items: center;
  padding-left: 14px;
  color: #73818f;
`

export const ChangeUserOrgPermissionPage: React.FC<Props> = props => {
  const { orgHasUser: d, onUpdate, onReject, onClose, noSuccessToast } = props
  const myPermUtils = useOrgPerm(d.org.orgId)
  const isEditing = !!myPermUtils?.isAllowed(ORG_ROLE.EDIT_USER_PERM)
  const isBlocked = d.orgUserPriv === ORG_USER_PRIV.Rejected || d.orgUserPriv === ORG_USER_PRIV.Pending
  const [permUtils, setPermUtils] = React.useState(new PermUtils(d.orgUserPriv, d.permSchema))
  const isBlockedByPermUtils =
    permUtils.userPriv === ORG_USER_PRIV.Rejected || permUtils.userPriv === ORG_USER_PRIV.Pending
  const [, inc] = useCounter()
  const { orgUserPriv, permSchema } = d

  React.useEffect(() => {
    setPermUtils(new PermUtils(orgUserPriv, permSchema))
  }, [orgUserPriv, permSchema])

  const handleChangeUserPriv = (v: ORG_USER_PRIV) => {
    permUtils!.changeUserPriv(v)
    inc()
  }

  const handleSaveOrClose = async () => {
    if (!isEditing) {
      onClose(false)
    }

    if (!permUtils || !permUtils?.hasChange) {
      return
    }

    const successful = await onUpdate({
      userId: d.user.userId,
      orgId: d.org.orgId,
      orgUserPriv: permUtils.userPriv,
      permSchema: permUtils.getSchemaAsJson(),
    })

    if (successful && !noSuccessToast) {
      Utils.showSuccess('Saved permissions.', 'Success')
      onClose(true)
    }
  }

  if (!permUtils) {
    return <BlockingLoadBox show message="loading..." />
  }

  const showBlock = isEditing && !isBlocked && onReject

  return (
    <div>
      <CallOutBox
        icon={ODIcons.MaterialAccountCircle}
        email={d.user.username ?? d.user.email ?? '-'}
        name={d.user.name ?? '-'}
      />
      <UserTypeRow>
        <UserTypeColumn>
          <UserType>User Type</UserType>
          {isEditing && <OrgUserPrivSelect selected={permUtils.userPriv} onChange={handleChangeUserPriv} />}
          {!isEditing && (
            <DisabledPrivSelect>{ORG_USER_PRIV_TO_STRING[permUtils.userPriv as ORG_USER_PRIV]}</DisabledPrivSelect>
          )}
        </UserTypeColumn>
        <UserTypeColumn>
          {/*{!isEditing && (*/}
          {/*  <ODButton*/}
          {/*    theme={ODButtonTheme.PrimaryInvert}*/}
          {/*    size={ODButtonSize.Small}*/}
          {/*    rounded*/}
          {/*    style={{ paddingLeft: 10, paddingRight: 10 }}*/}
          {/*    onClick={() => setIsEditing(true)}*/}
          {/*  >*/}
          {/*    Edit Permission*/}
          {/*  </ODButton>*/}
          {/*)}*/}
        </UserTypeColumn>
      </UserTypeRow>
      <OrgPermissionTable
        orgHasUser={d}
        isEditing={!isBlockedByPermUtils && isEditing}
        permUtils={permUtils}
        onPermChanged={inc}
      />
      <FooterWrapper>
        {showBlock && (
          <Button type="button" color="danger" style={{ minWidth: 175 }} outline onClick={onReject}>
            Block User
          </Button>
        )}
        {!showBlock && <div />}
        <Button type="button" color="primary" style={{ minWidth: 175 }} onClick={() => handleSaveOrClose()}>
          {isEditing ? 'Save Permissions' : 'Close'}
        </Button>
      </FooterWrapper>
    </div>
  )
}
