import prettyBytes from 'pretty-bytes'
import React from 'react'
import styled from 'styled-components'
import { GQLItemSnapshotUploadStatus } from '../@types/server'
import { ODIcon, ODIcons } from './ODIcon'
import { CustomProgress } from './ProgressBarComponent'

type TCFUploadStatusProps = {
  status?: GQLItemSnapshotUploadStatus
}

const UploadStatusButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`
const UploadStatusWrapper = styled.div`
  position: relative;
  display: inline-block;
`
const UploadStatusContents = styled.div`
  display: none;
  position: absolute;
  right: 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #c8ced3;
  background-color: #ffffff;
  min-width: 320px;
  min-height: 75px;
  overflow-y: auto;
  z-index: 2;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 19px 12px 19px;
`
const UploadBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 145px;
  height: 13px;
  border-radius: 7px;
  background-color: #c8ced3;
  font-size: 9px;
  font-weight: bold;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TCFUploadStatus: React.FC<TCFUploadStatusProps> = props => {
  const { status } = props

  const [isMouseOver, setMouseOver] = React.useState(false)
  const statusRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (statusRef && statusRef.current) {
      statusRef.current.addEventListener('mouseover', () => setMouseOver(true))
      statusRef.current.addEventListener('mouseout', () => setMouseOver(false))
    }

    return () => {
      // eslint-disable-next-line
      statusRef.current!.removeEventListener('mouseover', () => setMouseOver(true))
      // eslint-disable-next-line
      statusRef.current!.removeEventListener('mouseout', () => setMouseOver(false))
    }
  }, [])

  const progressValue = status ? (((status?.uploadedBytes || 1) / (status?.totalBytes || 1)) * 100).toFixed(0) : 0

  return (
    <UploadStatusWrapper ref={statusRef} onClick={() => console.log(status)}>
      <UploadStatusButton>
        <ODIcon icon={ODIcons.CoreSync} style={{ fontSize: 16, color: '#73818f', letterSpacing: 0.34 }} />
      </UploadStatusButton>
      <UploadStatusContents style={{ display: isMouseOver ? 'flex' : 'none' }}>
        <UploadBox>
          <ODIcon icon={ODIcons.MaterialFileUpload} style={{ fontSize: 9, color: '#ffffff', marginRight: 2 }} />
          UPLOADING IN PROGRESS
        </UploadBox>
        <ColumnWrapper style={{ marginTop: 9 }}>
          <RowWrapper style={{ justifyContent: 'space-between' }}>
            {!status ? (
              <RowWrapper>
                <ODIcon icon={ODIcons.MaterialPause} style={{ fontSize: 12, color: '#7f8b98', marginRight: 4 }} />
              </RowWrapper>
            ) : (
              <RowWrapper>
                <ODIcon icon={ODIcons.MaterialPlayArrow} style={{ fontSize: 12, color: '#7f8b98', marginRight: 4 }} />
                <span style={{ fontSize: 11, letterSpacing: 0.39, color: '#000' }}>
                  {prettyBytes(status?.uploadedBytes || 0)} / {prettyBytes(status?.totalBytes || 0)}
                </span>
              </RowWrapper>
            )}
            <RowWrapper style={{ fontSize: 12, letterSpacing: 0.17, color: '#000', fontWeight: 'bold' }}>
              {status ? `${progressValue}%` : 'Pending'}
            </RowWrapper>
          </RowWrapper>
          <div style={{ marginTop: 2, paddingLeft: 2 }}>
            <CustomProgress value={progressValue} max={100} color={'#7f8b98'} />
          </div>
        </ColumnWrapper>
      </UploadStatusContents>
    </UploadStatusWrapper>
  )
}
