import React from 'react'
import { GQLDualIDInput, GQLItem, GQLListableItem, GQLListableItemInput, GQLTag } from '../../../@types/server'
import { GQL_ITEM_IN_LIST_SNAPSHOT, useODAppContext } from '../../../context/ODAppContext'
import { useODQuery, useODQuery2 } from '../../../context/ODCommon'
import { useODSingleOrgContext } from '../../../context/ODSingleOrgContext'
import { ODListableResponseType } from '../../../ODListable/ODListableContext'
import { ItemDataLoaderOption, ItemsContainer } from './ItemsContainer'

type TaggedItemsContainerProps = {
  tagId: number
}

const GQL_LIST_ITEMS = `
query listItem($data: ListableItemInput!) {
  listItem(data: $data) {
    list {
      ${GQL_ITEM_IN_LIST_SNAPSHOT}
    }
    page
    pageSize
    totalCount
  }
}
`

const GQL_GET_TAG = `
query getTag($data: DualIDInput!) {
  getTag(data: $data) {
    name
  }
}
`

export const TaggedItemsContainer: React.FC<TaggedItemsContainerProps> = props => {
  const { tagId } = props
  const apiList = useODQuery<Partial<GQLListableItemInput>, GQLListableItem>(GQL_LIST_ITEMS)
  const { orgId } = useODSingleOrgContext()
  const { data } = useODQuery2<GQLDualIDInput, GQLTag>(GQL_GET_TAG, {
    pickFirstKey: true,
    variables: { firstId: orgId, secondId: tagId },
  })

  const dataLoader = React.useCallback(
    async function ItemsDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemDataLoaderOption
    ): Promise<ODListableResponseType<GQLItem>> {
      return apiList({
        page,
        pageSize,
        filter: options.filter,
        orgId: options.orgId,
        tagIds: [tagId],
        hasAttachment: options.hasAttachment,
        timeLapseOnly: options.timeLapseOnly,
        tcfImageTypes: options.tcfImageTypes,
        // @ts-ignore
        sortBy: options.sortBy,
        modifiedTimeRange: options.modifiedTimeRange,
        categoryIds: options.categoryIds,
        afterKey,
        hidden: options.hidden,
      })
    },
    [tagId, apiList]
  )

  const { setCurrentTagName } = useODAppContext()
  React.useEffect(() => {
    if (data) {
      setCurrentTagName(data?.name || '')
    }
    return () => setCurrentTagName('')
  }, [setCurrentTagName, data])

  return <ItemsContainer showToolbar={true} dataLoader={dataLoader} />
}
