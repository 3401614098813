import { WORKSET_ACTION } from '@cils/common/lib/permission/worksetPermConsts'
import { WorksetPermUtils } from '@cils/common/lib/permission/worksetPermUtils'
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import { ODIcon, ODIcons } from '../../../components/ODIcon'

type ThumbnailOverlayToolbarProps = {
  editable: boolean
  handleSetAsMain: () => any
  handleEditAttachment: () => any
  handleRemove: () => any
  isMain: boolean
  grayTheme?: boolean
  wsPermUtils?: WorksetPermUtils
  canEditAttachment?: boolean
  canDeleteAttachment?: boolean
  downloadLink: string
}

interface MainButtonProps {
  enableButton: boolean
}

const MainButton = styled.div`
  width: 60px;
  height: 20px;
  opacity: ${(p: MainButtonProps) => (p.enableButton ? 1.0 : 0.5)};
  border-radius: 10px;
  border: solid 1px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MainButtonText = styled.span`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 10px;
  letter-spacing: 0.14px;
  color: #ffffff;
`

const PencilCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 13px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #29b6ca;
  }
`
const CustomToolTip = styled(UncontrolledTooltip)`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #ffffff;
`

export const ThumbnailOverlayToolbar: React.FC<ThumbnailOverlayToolbarProps> = props => {
  const {
    editable,
    handleSetAsMain,
    isMain,
    handleEditAttachment,
    handleRemove,
    grayTheme,
    wsPermUtils,
    canEditAttachment,
    canDeleteAttachment: canDelete,
    downloadLink,
  } = props

  const canDeleteAttachment =
    canDelete ?? (editable && wsPermUtils?.isAllowed(WORKSET_ACTION.DeleteAttachment)) ?? false
  const canEditAttachmentMeta =
    canEditAttachment ?? (editable && wsPermUtils?.isAllowed(WORKSET_ACTION.EditAttachmentMeta)) ?? false
  const canMakeMain = canEditAttachment ?? (editable && wsPermUtils?.isAllowed(WORKSET_ACTION.MakeMain)) ?? false
  const canDownload = true

  const styleOverrides = grayTheme
    ? {
        border: 'solid 1px #73818f',
        color: '#73818f',
      }
    : {}

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <MainButton
        id="MainButton"
        style={{ marginRight: 11, cursor: canMakeMain ? 'pointer' : 'auto', ...styleOverrides }}
        onClick={() => {
          if (canMakeMain) {
            handleSetAsMain()
          }
        }}
        enableButton={isMain}
      >
        {isMain ? (
          <ODIcon
            icon={ODIcons.MaterialStar}
            style={{ fontSize: 12, color: grayTheme ? '#2f353a' : '#ffc107', marginRight: 4 }}
          />
        ) : (
          <ODIcon
            icon={ODIcons.MaterialStarBorder}
            style={{ fontSize: 12, color: grayTheme ? '#73818f' : '#ffffff', marginRight: 4 }}
          />
        )}
        <MainButtonText style={{ marginTop: 2, color: grayTheme ? '#73818f' : '#ffffff' }}>Main</MainButtonText>
      </MainButton>
      {canMakeMain && (
        <CustomToolTip placement="top" target="MainButton">
          Set as main attachment
        </CustomToolTip>
      )}
      {canDownload && (
        <a href={downloadLink} target="_blank" rel="noreferrer noopener" download>
          <ODIcon
            icon={ODIcons.CoreDataTransferDown}
            style={{ color: grayTheme ? '#73818f' : '#ffffff', fontSize: 14, cursor: 'pointer', marginRight: 6 }}
          />
        </a>
      )}
      {canEditAttachmentMeta && (
        <PencilCircle style={{ marginRight: 11, ...(grayTheme ? { border: 0 } : {}) }}>
          <ODIcon
            icon={ODIcons.CorePencil}
            style={{ color: grayTheme ? '#73818f' : '#ffffff', fontSize: 14, cursor: 'pointer' }}
            onClick={() => {
              if (canEditAttachmentMeta) {
                handleEditAttachment()
              }
            }}
          />
        </PencilCircle>
      )}
      {canDeleteAttachment && (
        <ODIcon
          icon={ODIcons.CoreTrash}
          style={{ color: grayTheme ? '#73818f' : '#ffffff', fontSize: 14, cursor: 'pointer' }}
          onClick={() => {
            if (canDeleteAttachment) {
              handleRemove()
            }
          }}
        />
      )}
    </div>
  )
}
