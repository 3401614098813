import React from 'react'
import { useParams } from 'react-router-dom'
import { Label } from 'reactstrap'
import styled from 'styled-components'
import { GQLDateTimeRange } from '../../@types/server'
import { CategoryOption } from '../../context/ODCILSHooks'
import { GQLItemsListableContextType } from '../../ODListable/ODListableContext'
import { CategoryDropDownComponent } from '../CategoryDropDownComponent'
import { MoreDropDownComponent } from '../MoreDropDownComponent'
import { TagDropDownComponent } from '../TagDropDownComponent'
import { DropDownMenuProps } from './ODImageGridSearchToolBar'

type SelectOptionsProps = {
  handleMoreChange: (
    timeLapseOnly: boolean,
    tcfImageTypes: Array<string> | null,
    hasAttachment: boolean | null,
    hidden: boolean | null,
    modifiedTimeRange: GQLDateTimeRange | null
  ) => void
  categoryList: Array<CategoryOption> | null
  handleCategoryChange: (v: Array<number> | null) => void
  listableContext: React.Context<GQLItemsListableContextType>
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FilterOptions: React.FC<SelectOptionsProps & DropDownMenuProps> = props => {
  const { categoryList, handleMoreChange, handleCategoryChange, listableContext, isMenuOpen, setIsMenuOpen } = props

  const { catId, tagId } = useParams()

  return (
    <Wrapper>
      <div
        style={{
          fontWeight: 'bold',
          width: 76,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: 5,
          marginRight: 20,
        }}
      >
        <Label htmlFor="select" style={{ fontWeight: 'bold' }}>
          Filter
        </Label>
      </div>
      <div style={{ fontWeight: 'bold', width: 147, display: 'inline-block', marginRight: 6 }}>
        <CategoryDropDownComponent
          list={categoryList}
          onSearch={handleCategoryChange}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          listableContext={listableContext}
          disabled={!!catId}
        />
      </div>
      <div style={{ fontWeight: 'bold', width: 147, display: 'inline-block', marginRight: 6 }}>
        <TagDropDownComponent
          listableContext={listableContext}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          disabled={!!tagId}
        />
      </div>
      <div style={{ fontWeight: 'bold', width: 147, display: 'inline-block' }}>
        <MoreDropDownComponent
          handleMoreChange={handleMoreChange}
          listableContext={listableContext}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </Wrapper>
  )
}
