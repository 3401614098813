import { CategoryPropsInputValidation } from '@cils/common'
import { ODEntityLabeled, ODHSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import * as Yup from 'yup'
import { GQLProjectMetaDataPreset, GQLProjectMetaDataPresetUpdateInput } from '../../../../@types/server'
import { ODToastType, showODToast } from '../../../../components/ODToast'
import { useAPIs } from '../../../../context/useAPIs'
import { ODColors } from '../../../../global-styles'
import { ODEntityInput } from '../../../../ODEntityEditor/FormComponents/ODEntityInput'
import {
  createODEntityEditorContext,
  ODEntityEditorContextOptions,
} from '../../../../ODEntityEditor/ODEntityEditorContext'
import { ODEntityEditorFooter } from '../../../../ODEntityEditor/ODEntityEditorFooter'
import { SiteUrls } from '../../../../urls'
import { Utils } from '../../../../utils'
import { DOMetaDataEditor } from '../../../User/Project/store/metadata/DOMetaDataEditor'
import { IMetaData } from '../../../User/Project/store/metadata/IMetaData'

interface IProjectMetaDataEditPage {
  orgId: number
  idEditing?: number
}

function getValidationSchema(values: Partial<PropsInput>) {
  return Yup.object().shape({
    name: CategoryPropsInputValidation.name.required('Preset name is required.'),
  })
}

type PropsInput = GQLProjectMetaDataPresetUpdateInput
type Entity = GQLProjectMetaDataPreset

export const ProjectMetaDataEditPage: React.FC<IProjectMetaDataEditPage> = observer(props => {
  const { orgId, idEditing } = props
  const {
    createProjectMetaDataPreset: apiCreate,
    updateProjectMetaDataPreset: apiUpdate,
    getProjectMetaDataPreset: apiGet,
    removeProjectMetaDataPreset: apiRemove,
  } = useAPIs()
  const [metaDataEditor] = React.useState(() => new DOMetaDataEditor())
  const createOptions = React.useCallback<() => ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(
    () => ({
      initialValueLoader: async () => {
        if (idEditing) {
          return apiGet({ id: idEditing })
        }
        return null
      },
      mapServerValueToClient: async data => {
        const parsedData: IMetaData[] = data?.rawData ? JSON.parse(data.rawData) : []
        metaDataEditor.setInputs(parsedData)
        if (!data) {
          return {
            name: '',
            rawData: '',
            ownerOrgId: orgId,
          }
        }
        return {
          name: data.name,
          rawData: data.rawData,
          ownerOrgId: orgId,
        }
      },
      saveClientValueToServer: async (data: Partial<PropsInput>) => {
        if (!metaDataEditor.canSubmit) {
          showODToast(ODToastType.Error, ODToastType.Error, 'Key and column name must be entered in pairs.\n')
          return null
        }

        if (idEditing) {
          await apiUpdate({ id: idEditing, rawData: metaDataEditor.dataForSubmit, ownerOrgId: orgId, ...data })
          Utils.showSuccess('Updated a preset', 'Success')
        } else {
          await apiCreate({ name: data.name ?? '', rawData: metaDataEditor.dataForSubmit, ownerOrgId: orgId })
          Utils.showSuccess('Added a new preset.', 'Success')
        }
        return SiteUrls.OrgAdmin.ProjectMetadata.List(orgId)
      },
      onUnexpectedError: (ex: Error) => {
        Utils.showError(ex)
      },
      getValidationSchema,
      deleteItem: async () => {
        if (idEditing) {
          await apiRemove({ id: idEditing })
          Utils.showSuccess('Removed a preset.', 'Success')
        }
        return SiteUrls.OrgAdmin.ProjectMetadata.List(orgId)
      },
    }),
    [idEditing, apiCreate, apiUpdate, apiGet, apiRemove, orgId]
  )

  const [options, setOptions] = React.useState<ODEntityEditorContextOptions<Entity, Partial<PropsInput>>>(() =>
    createOptions()
  )
  const [{ Provider, Context }, setContext] = React.useState(() =>
    createODEntityEditorContext<Entity, Partial<PropsInput>>(options)
  )

  React.useEffect(() => setOptions(createOptions()), [createOptions, apiCreate])
  React.useEffect(() => setContext(createODEntityEditorContext<Entity, Partial<PropsInput>>(options)), [options])

  const title = !idEditing ? 'New Preset' : 'Edit Preset'

  return (
    <>
      <Provider title={title}>
        <ODEntityInput name="name" label="Preset Name" placeholder="text" inputType="text" />
        <ODEntityLabeled name={'rawData'} label={'Metadata'}>
          <div style={{ position: 'relative', top: 7, display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
              <Label>Key</Label>
              <ODHSpace w={10} />
              <Label>Column name</Label>
            </Row>
            {metaDataEditor.inputs.map((item, index) => {
              return (
                <div style={{ display: 'flex', marginBottom: 10 }} key={index}>
                  <Input
                    type="text"
                    placeholder="Key"
                    value={item.key}
                    onChange={e => item.setKey(e.target.value)}
                    style={{ flex: 1 }}
                  />
                  <ODHSpace w={10} />
                  <Input
                    type="text"
                    placeholder="Column name"
                    value={item.columnName}
                    onChange={e => item.setColumnName(e.target.value)}
                    style={{ flex: 1 }}
                  />
                </div>
              )
            })}
          </div>
        </ODEntityLabeled>
        <hr />
        <ODEntityEditorFooter
          saveButtonName="Save"
          deleteButtonName={idEditing ? 'Delete' : undefined}
          context={Context}
        />
      </Provider>
    </>
  )
})

const Row = styled.div`
  display: flex;
`

const Label = styled.div`
  flex: 1;
  font-size: 12px;
  font-weight: bold;
  color: ${ODColors.Steel};
`
