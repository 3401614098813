import React from 'react'

interface INewMarkProps {}

export const NewMark: React.FC<INewMarkProps> = props => {
  return (
    <span
      style={{
        backgroundColor: 'red',
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: 5,
        marginLeft: 10,
        padding: '2px 6px',
      }}
    >
      N
    </span>
  )
}
