import React from 'react'
import { Utils } from '../../utils'

interface ICILSErrorBoxProps {
  error: Error
}

export const CILSErrorBox: React.FC<ICILSErrorBoxProps> = props => {
  const { error } = props
  return <div>{Utils.parseErrorMessage(error) || 'Error occurred..'}</div>
}
