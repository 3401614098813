import React, { useRef, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { CategoryOption } from '../context/ODCILSHooks'
import { Key, KeyWrapper, RowWrapper } from './EditValueComponent'
import { ODIcon, ODIcons } from './ODIcon'
import { UseConfirmModalOptions, useModalConfirm } from './ODModal/ODModalConfirm'

const CustomSelectBox = styled(Select)`
  width: 424px;
`
const CategoryBox = styled.div`
  height: 19px;
  border-radius: 4px;
  background-color: #6a92c7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  margin-right: 4px;
  cursor: pointer;
`
const CategoryBoxValue = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`

const deleteCategoryOptions: UseConfirmModalOptions = {
  message: <>Are you sure you want to delete this category?</>,
  yes: 'Delete',
  no: 'Cancel',
}

type Props = {
  initialValue: Array<CategoryOption>
  categories: Array<CategoryOption>
  editable: boolean
  onChange: (value: Array<CategoryOption>) => void
}

export const CategoryEditComponent: React.FC<Props> = props => {
  const { categories, onChange, initialValue, editable } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [inputValue, setInputValue] = useState(initialValue)
  const selectFocus = useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    setInputValue(initialValue)
  }, [JSON.stringify(initialValue), setInputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (isEdit) {
      selectFocus.current && selectFocus.current.focus()
    }
  }, [isEdit])

  const { Component: DeleteCategory, confirm: ConfirmCategory, props: deleteCategoryProps } = useModalConfirm(
    deleteCategoryOptions
  )

  const handleSave = React.useCallback(
    async (event: Array<CategoryOption>) => {
      setIsEdit(false)
      setIsDisabled(true)
      try {
        await onChange(event)
      } catch (ex) {
        console.error(ex)
      } finally {
        setIsDisabled(false)
      }
    },
    [onChange]
  )

  const onRemoveCategoryTag = async (value: string) => {
    if (!editable) {
      return
    }

    const remove = () => {
      const arr = inputValue
      const itemToFind = arr.find(function(item: CategoryOption) {
        return item.value === value
      })
      const idx = inputValue.indexOf(itemToFind!!)
      if (idx > -1) {
        arr.splice(idx, 1)
      }
      setInputValue(arr)
      handleSave(arr)
    }

    if (await ConfirmCategory()) {
      remove()
    }
  }

  const categoryValue = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          maxWidth: 417,
          flexWrap: 'wrap',
        }}
      >
        {inputValue &&
          inputValue.map((v: CategoryOption, i) => {
            return (
              <CategoryBox
                key={i}
                onClick={() => onRemoveCategoryTag(v.value)}
                style={{ cursor: editable ? 'pointer' : 'default' }}
              >
                <CategoryBoxValue>{v.label}</CategoryBoxValue>
              </CategoryBox>
            )
          })}
      </div>
    )
  }

  const editIcon = (() => {
    if (!editable) {
      return null
    }

    if (isEdit) {
      return (
        <ODIcon
          icon={ODIcons.CoreSave}
          style={{ color: '#73818f', cursor: isDisabled ? 'none' : 'pointer' }}
          onClick={() => !isDisabled && handleSave(inputValue)}
        />
      )
    }

    return (
      <ODIcon
        icon={ODIcons.CorePencil}
        style={{ color: '#73818f', cursor: isDisabled ? 'none' : 'pointer' }}
        onClick={() => !isDisabled && setIsEdit(true)}
      />
    )
  })()

  return (
    <>
      <DeleteCategory {...deleteCategoryProps} />
      <RowWrapper
        style={{
          height: 28,
          alignItems: 'center',
        }}
      >
        <KeyWrapper>
          <Key>Category</Key>
        </KeyWrapper>
        {isEdit ? (
          <CustomSelectBox
            isMulti
            name="category"
            options={categories}
            value={inputValue}
            onChange={(e: React.SetStateAction<CategoryOption[]>) => setInputValue(e)}
            ref={selectFocus}
          />
        ) : (
          categoryValue()
        )}
      </RowWrapper>
      {editIcon}
    </>
  )
}
