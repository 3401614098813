import React from 'react'
import { Col, Container, Label, Row } from 'reactstrap'
import styled from 'styled-components'
import { GQLDateTimeRange } from '../../@types/server'
import { useCILSCategory } from '../../context/ODCILSHooks'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { ODListableContextType, ODListableOption } from '../../ODListable/ODListableContext'
import { ODSearchBox } from '../ODSearchBox'
import { SortByDropDownComponent } from '../SortByDropDownComponent'
import { FilterOptions } from './FilterOptions'
import { ImageCounts } from './ImageCounts'
import { ImageSizeToggle } from './ImageSizeToggle'
import { RangeInput } from './RangeInput'
import { TCF_MAIN_VIEW_TYPE, ViewToggle } from './ViewToggle'
import { WorksetFilterToggle } from './WorksetFilterToggle'

export type ODImageGridSearchToolBarProps<T, O extends ODListableOption> = {
  listableContext: React.Context<ODListableContextType<T, O>>
  hideWorksetFilter?: boolean
}

export enum DROPDOWN_MENU {
  SortBy,
  Category,
  Tag,
  AllItems,
  More,
  Expiration,
  Permission,
  MemberPermission,
  SortByTag,
  SortByWorkset,
  SortByProject,
  SortByWorksetGroup,
  ViewType,
  StatusByOrgAdminProjects,
}

export enum WORKSET_FILTER_MENU {
  All = 'All',
  NotAdded = 'Not added',
  Added = 'Added',
}

export type DropDownMenuProps = {
  isMenuOpen: DROPDOWN_MENU | null
  setIsMenuOpen: (v: DROPDOWN_MENU | null) => void
}

type ImageSizeAndTypeProps = {
  setImageSize: (v: number) => void
  imageSize: number
  onImageViewTypeChanged: (v: TCF_MAIN_VIEW_TYPE) => void
}

const SortByWrapper = styled.div``

export function ODImageGridSearchToolBar<T, O extends ODListableOption>(
  props: ODImageGridSearchToolBarProps<T, O> & ImageSizeAndTypeProps
) {
  const { listableContext, setImageSize, imageSize, onImageViewTypeChanged, hideWorksetFilter } = props
  const {
    updateLoadOption,
    state: { loadOption },
  }: ODListableContextType<T, O> = React.useContext(listableContext)
  const { orgId } = useODSingleOrgContext()
  const { categories } = useCILSCategory(orgId)

  const [keyword, setKeyword] = React.useState('')
  const [isMenuOpen, setIsMenuOpen] = React.useState<DROPDOWN_MENU | null>(null)
  const [worksetFilter, setWorksetFilter] = React.useState<WORKSET_FILTER_MENU>(WORKSET_FILTER_MENU.All)

  const handleChange = (v: string) => {
    setKeyword(v)
    updateLoadOption({ ...loadOption, filter: v })
  }

  const handleSortByChange = (v: string) => {
    updateLoadOption({ ...loadOption, sortBy: v })
  }

  const handleCategoryChange = (v: Array<number> | null) => {
    updateLoadOption({ ...loadOption, categoryIds: v })
  }

  const handleMoreChange = (
    timeLapseOnly: boolean,
    tcfImageTypes: Array<string> | null,
    hasAttachment: boolean | null,
    hidden: boolean | null,
    modifiedTimeRange: GQLDateTimeRange | null
  ) => {
    updateLoadOption({ ...loadOption, timeLapseOnly, tcfImageTypes, hasAttachment, modifiedTimeRange, hidden })
  }

  const handleWorksetFilterChange = (v: string) => {
    switch (v) {
      case WORKSET_FILTER_MENU.All:
        setWorksetFilter(WORKSET_FILTER_MENU.All)
        updateLoadOption({ ...loadOption, addedToWorkset: null })
        return
      case WORKSET_FILTER_MENU.NotAdded:
        setWorksetFilter(WORKSET_FILTER_MENU.NotAdded)
        updateLoadOption({ ...loadOption, addedToWorkset: false })
        return
      case WORKSET_FILTER_MENU.Added:
        setWorksetFilter(WORKSET_FILTER_MENU.Added)
        updateLoadOption({ ...loadOption, addedToWorkset: true })
        return
      default:
        return
    }
  }

  return (
    <Container
      fluid
      style={{
        backgroundColor: 'white',
        paddingTop: 25,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 25,
        borderBottom: '1px solid #c8ced3',
      }}
    >
      <div style={{ maxWidth: 1440 }}>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Col md={7} className="vcenter">
            <ODSearchBox
              placeholder="Search by title, path, explanation."
              value={keyword}
              onChange={handleChange}
              style={{ maxWidth: 602 }}
            />
          </Col>
          <Col md={5} className="vcenter" style={{ alignItems: 'flex-end', paddingBottom: 14 }}>
            <ImageCounts listableContext={listableContext} />
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <Col
            md={7}
            className="vcenter"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              minWidth: 602,
            }}
          >
            <SortByWrapper>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div
                  style={{
                    fontWeight: 'bold',
                    width: 76,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: 5,
                    marginRight: 20,
                  }}
                >
                  <Label htmlFor="select" style={{ fontWeight: 'bold' }}>
                    Sort by
                  </Label>
                </div>
                <div style={{ fontWeight: 'bold', width: 150, display: 'inline-block' }}>
                  <SortByDropDownComponent
                    onChange={e => handleSortByChange(e)}
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={setIsMenuOpen}
                  />
                </div>
              </div>
            </SortByWrapper>
            <ViewToggle onChange={onImageViewTypeChanged} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
          </Col>
          <Col
            md={5}
            className="vcenter"
            style={{ justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}
          >
            <ImageSizeToggle imageSize={imageSize} setImageSize={setImageSize} />
            <RangeInput
              imageSize={imageSize}
              setImageSize={setImageSize}
              customStyle={{ maxWidth: 154, marginLeft: 13 }}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          <Col
            md={7}
            className="vcenter"
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              minWidth: 572,
            }}
          >
            <FilterOptions
              // @ts-ignore
              listableContext={listableContext}
              categoryList={categories}
              handleMoreChange={handleMoreChange}
              handleCategoryChange={handleCategoryChange}
              setIsMenuOpen={setIsMenuOpen}
              isMenuOpen={isMenuOpen}
            />
          </Col>
          {!hideWorksetFilter && (
            <Col md={5} className="vcenter" style={{ alignItems: 'flex-end' }}>
              <WorksetFilterToggle worksetFilter={worksetFilter} handleChange={handleWorksetFilterChange} />
            </Col>
          )}
        </Row>
      </div>
    </Container>
  )
}
