import { ErrorCode } from '@cils/common'

export const Strings = {
  Dashboard: 'Dashboard',
  User: 'User',
  UserGroup: 'User Group',
  Agent: 'Agent',
  AgentStatus: 'Agent Status',
  AgentLogs: 'Agent Logs',
  Organization: 'Organization',
  APIKey: 'API Key',
  Metadata: 'Metadata',

  ItemsAll: 'All',
  Local: 'Local',
  Workset: 'Workset',
  Project: 'Project',
  AllProjects: 'All Projects',
  WorksetGroup: 'Workset Group',
  Favorite: 'Favorites',
  Tag: 'Tag',
  Category: 'Category',
  GoToAdmin: 'Admin',
  MyPage: 'MyPage',
  Profile: 'Profile',
  ChangePassword: 'Change Password',
  Sync: 'Sync',
  TCF: 'TCF',
  DirectoryView: 'Directory View',

  OrgAdminCategory: 'Category',
  ExitAdmin: 'Exit Admin',

  Add: 'Add',
  Edit: 'Edit',
}

type ErrorConvertingFunc = (message: string) => string

export type ErrorStringMap = { [key: number]: string | ErrorConvertingFunc }

export const ServerErrors: ErrorStringMap = {
  [ErrorCode.ServerParamError]: (msg: string) => {
    // const comps = msg.split(' ')
    return msg.split(':')[1] || msg
    // const parameter = comps[1]
    // return comps.slice(2).join(' ')
  },
  [ErrorCode.ServerWrongLoginInfo]: 'Failed to login.',
  [ErrorCode.LastSuperAdmin]: 'Last CILS Admin cannot be removed.',
  [ErrorCode.WorksetNameExists]: 'Given workset name already exists.',
  [ErrorCode.UserGroupCannotDeleteAttachedToWorkset]: 'This group can’t be deleted. The group is added in workset.',
  [ErrorCode.ServerUnauthorized]: 'You don’t have permission to do that action.',
  [ErrorCode.InvalidPassWordForRemoveProject]: 'Password is invalid',
}
