import { ReactNodeLike } from 'prop-types'
import React from 'react'
import { Col, FormGroup, Label } from 'reactstrap'

type ODEntityTextLabelProps = {
  name: string
  label: string
  children: ReactNodeLike
}

export const ODEntityTextLabel: React.FC<ODEntityTextLabelProps> = props => {
  const { name, label, children } = props
  return (
    <FormGroup row>
      <Col md="3">
        <Label htmlFor={`${name}-input`} style={{ paddingTop: 7 }}>
          {label}
        </Label>
      </Col>
      <Col xs="12" md="9">
        <div style={{ display: 'flex', width: '100%' }}>{children}</div>
      </Col>
    </FormGroup>
  )
}
