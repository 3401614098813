import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import styled from 'styled-components'

export enum ODToastType {
  Success = 'Success',
  Error = 'Error',
  Info = 'Info',
}

const ToastTitle = styled.span`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  padding-top: 10px;
  color: #000000;
`

type ToastBodyProps = {
  hasTitle: boolean
}

const ToastBody = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  padding-bottom: ${(p: ToastBodyProps) => (p.hasTitle ? 8 : 1)}px;
  color: #616b75;
`

interface Props {
  toastType: ODToastType
  title: string
  message: string
  onClickClose: () => void
}

const config = {
  [ODToastType.Success]: { color: '#4dbd74', icon: <CheckCircleIcon /> },
  [ODToastType.Error]: { color: '#e8bb41', icon: <WarningIcon /> },
  [ODToastType.Info]: { color: '#63c2de', icon: <InfoIcon /> },
}

export const ODToast: React.FC<Props> = ({ toastType, title, message, onClickClose }) => {
  const { color, icon } = config[toastType]

  return (
    <div
      style={{
        display: 'flex',
        borderLeft: `4px solid ${color}`,
        padding: 0,
        margin: 0,
        position: 'relative',
        height: '100%',
      }}
    >
      <div
        style={{
          flexGrow: 1,
          marginLeft: 16,
          position: 'relative',
          top: -1,
          color,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {icon}
      </div>
      <div style={{ flexGrow: 99, display: 'flex', flexDirection: 'column', height: '100%', paddingLeft: 14 }}>
        {title && (
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <ToastTitle>{title}</ToastTitle>
          </div>
        )}
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <ToastBody hasTitle={!!title}>{message}</ToastBody>
        </div>
      </div>
      <div
        style={{ marginRight: 14, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        onClick={onClickClose}
      >
        <CloseIcon />
      </div>
    </div>
  )
}

export function showODToast(type: ODToastType, title: string, message: string, options: Partial<ToastOptions> = {}) {
  let toastId: any = null

  const handleClose = () => {
    toast.dismiss(toastId)
  }

  const op = Object.assign(
    {
      hideProgressBar: true,
      closeButton: false,
    },
    options
  )

  toastId = toast(<ODToast toastType={type} title={title} message={message} onClickClose={handleClose} />, op)
}
