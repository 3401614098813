import React from 'react'
import { Progress } from 'reactstrap'
import styled from 'styled-components'
import { GQLProgressWithWorkset } from '../../../agent'
import { getProgressStatusFromProgressWithWorkset } from './common'

interface IAgentProgressStatusProps {
  item: GQLProgressWithWorkset
  isDownload: boolean
}

type ProgressStyleType = {
  color?: string
}

export const CustomProgress = styled(Progress)`
  height: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  & > div {
    background-color: ${(p: ProgressStyleType) => p.color};
  }
`
const StatusText = styled.span`
  font-size: 13px;
  letter-spacing: 0.43px;
`
const Percentage = styled.span`
  font-size: 13px;
  letter-spacing: 0.17px;
  text-align: right;
  color: #708393;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

export const AgentProgressStatus: React.FC<IAgentProgressStatusProps> = ({ isDownload, item }) => {
  const status = getProgressStatusFromProgressWithWorkset(item)
  const error = item.itemProgress?.hasError ?? null
  const isDeleted = status === 'Deleted'
  const isFinished = status === 'Finished'
  const percentage = (item.worksetProgress?.percentage || item.itemProgress?.percentage) ?? 0
  const showProgress = !isDeleted && !isFinished
  const [text, color] = (i => {
    if (error) {
      return [`Error : ${error}`, '#f86c6b']
    }
    const progress = (i.isWorkset ? i.worksetProgress?.progressStatus : i.itemProgress?.progressStatus)!
    switch (progress) {
      case 'Finished':
        return [isDownload ? 'Downloaded' : 'Uploaded', '#29b6ca']
      case 'InProgress':
        return [isDownload ? 'Downloading' : 'Uploading', '#4dbd74']
      case 'Merging':
        return ['Merging', '#4dbd74']
      case 'Deleted':
        return ['Deleted', '#73818f']
      case 'Pending':
        return ['Pending', '#7f8b98']
      case 'Paused':
        return ['Paused', '#7f8b98']
      default:
        return ['', '']
    }
  })(item)

  return (
    <>
      <Wrapper>
        <StatusText style={{ color, marginTop: showProgress ? 0 : 2 }}>{text}</StatusText>
        {showProgress && <Percentage>{percentage}%</Percentage>}
      </Wrapper>
      {showProgress && <CustomProgress value={percentage} max={100} color={color} />}
    </>
  )
}
