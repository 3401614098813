import { SUPPORT_EMAIL } from '@cils/common'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import logoCILSSmall from '../../assets/img/brand/logo-tomocube-cils-s.png'
import { SiteUrls } from '../../urls'
import { ODIcon, ODIcons } from '../ODIcon'
import { CoverCardContents } from './CoverCardContents'
import { LoginBody } from './LoginBody'
import { SignupBody } from './SignupBody'

const Wrapper = styled.div`
  position: relative;
  width: 934px;
  height: 480px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
`

type StyledProps = {
  isLoggingIn: boolean
}

const StyledParams = {
  leftOffset: 90,
  rightOffset: 407 + 90,
  markerLogin: 59,
  markerSignup: 140,
}

const transition = css`
  transition: all 1400ms cubic-bezier(0.04, 0.44, 0.27, 1.03);
`

// @ts-ignore
const CoverCard = styled.div`
  position: absolute;
  left: ${(p: StyledProps) => (p.isLoggingIn ? StyledParams.leftOffset : StyledParams.rightOffset)}px;
  ${transition};
  top: -20px;
  width: 407px;
  height: 517px;
  box-shadow: -4px 6px 8px 0 rgba(0, 0, 0, 0.19);
  background-color: #29b6ca;
`

const LeftNav = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 480px;
  background-color: #f9f9f9;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: #acb4bc;
`

const MenuMarker = styled.div`
  position: absolute;
  top: ${(p: StyledProps) => (p.isLoggingIn ? StyledParams.markerLogin : StyledParams.markerSignup)}px;
  ${transition};
  left: 0;
  width: 4px;
  height: 39px;
  background-color: #29b6ca;
`

type LoginSignupPageProps = {
  onSubmitLogin: (username: string, pw: string) => void
  onSubmitSignup: (username: string, name: string, email: string, pw: string, orgId: number) => void
  initialStateSignup?: boolean
}

type LeftNavItemProp = {
  icon: ODIcons
  name: string
  onClick: () => void
  top: number
}

const LeftNavItem: React.FC<LeftNavItemProp> = props => {
  return (
    <div
      style={{
        cursor: 'pointer',
        position: 'absolute',
        width: StyledParams.leftOffset,
        paddingTop: 5,
        top: props.top,
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ODIcon icon={props.icon} style={{ marginBottom: 5 }} />
        {props.name}
      </div>
    </div>
  )
}

const ContentsWrapper = styled.div`
  position: absolute;
  width: 407px;
  left: ${(p: StyledProps) => (!p.isLoggingIn ? StyledParams.leftOffset : StyledParams.rightOffset)}px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  transition: all 400ms;
`

const ContentsTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #29b6ca;
  margin-bottom: 7px;
`

const ContentsSubtitle = styled.div`
  font-size: 14px;
  text-align: center;
  color: #acb4bc;
  margin-bottom: 24px;
`

const HelpSpan = styled.span`
  font-size: 10px;
  color: #acb4bc;
`

const MailTo = styled.a`
  font-size: 10px;
  color: #29b6ca;
`

export const LoginSignupPage: React.FC<LoginSignupPageProps> = ({
  onSubmitLogin,
  onSubmitSignup,
  initialStateSignup,
}) => {
  const [isLogin, setLogin] = React.useState(!initialStateSignup)

  return (
    <Wrapper>
      <LeftNav>
        <LeftNavItem icon={ODIcons.CoreStorage} name="Login" onClick={() => setLogin(true)} top={61} />
        <LeftNavItem icon={ODIcons.CoreStorage} name="Sign up" onClick={() => setLogin(false)} top={143} />
      </LeftNav>
      <MenuMarker isLoggingIn={isLogin} />
      <ContentsWrapper isLoggingIn={false} style={{ opacity: isLogin ? 0 : 1 }}>
        <ContentsTitle>Sign Up</ContentsTitle>
        <ContentsSubtitle>Create your account</ContentsSubtitle>
        <SignupBody onSubmit={onSubmitSignup} />
      </ContentsWrapper>
      <ContentsWrapper isLoggingIn style={{ opacity: isLogin ? 1 : 0, paddingTop: 91 }}>
        <img
          alt="cils logo"
          src={logoCILSSmall}
          width={101}
          height={21}
          style={{ position: 'absolute', top: 20, left: 20 }}
        />
        <ContentsTitle>Log in</ContentsTitle>
        <ContentsSubtitle>Sign in to your account</ContentsSubtitle>
        <LoginBody onSubmitLogin={onSubmitLogin} />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 59 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <HelpSpan>HELP</HelpSpan> <MailTo href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</MailTo>
            </div>
            <Link to={SiteUrls.SyncWithoutLogin}>
              <HelpSpan>Agent page</HelpSpan>
            </Link>
          </div>
        </div>
      </ContentsWrapper>
      <CoverCard isLoggingIn={isLogin}>
        <CoverCardContents isLoggingIn={isLogin} onClickButton={() => setLogin(!isLogin)} />
      </CoverCard>
    </Wrapper>
  )
}
