import { WORKSET_USER_PERM } from '@cils/common'
import { ProjectUserPerm } from '@cils/common/lib/CILSStore/projectView/IAddUserToProjectStore'
import { ODHSpace, ODVSpace } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import Select from 'react-select'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { PROJECT_USER_PERM_STRING } from '../../containers/User/Project/ProjectCommon'
import { useODSingleOrgContext } from '../../context/ODSingleOrgContext'
import { useCILSStore } from '../../di/configureRootStore'
import { ODColors } from '../../global-styles'
import { SiteUrls } from '../../urls'
import { Utils } from '../../utils'
import { ODIcon, ODIcons } from '../ODIcon'
import { ODToastType, showODToast } from '../ODToast'
import { ODModal, ODModalSize } from './ODModal'
import { ODModalBody } from './ODModalBody'
import { ODModalHeader } from './ODModalHeader'
import { SelectOptionType } from './SelectWorksetMemberAndPermission'

interface IODModalChangeWorksetPerm {
  refresh: () => void
}

const PROJECT_PERM_OPTIONS = (currentPerm: ProjectUserPerm): SelectOptionType[] => {
  return [
    { key: 'Viewer', label: 'Viewer', value: ProjectUserPerm.Viewer },
    { key: 'Present Permission', label: 'Present Permission', value: currentPerm },
    { key: 'Remove from Project', label: 'Remove from Project', value: 0 },
    // { key: WORKSET_USER_PERM.Owner, label: 'Owner', value: WORKSET_USER_PERM.Owner },
  ]
}

const WORKSET_PERM_OPTIONS: SelectOptionType[] = [
  { key: WORKSET_USER_PERM.Viewer.toString(), label: 'Viewer', value: WORKSET_USER_PERM.Viewer },
  { key: 'Remove from Workset', label: 'Remove from Workset', value: 0 },
]

export const ODModalChangeWorksetPerm: React.FC<IODModalChangeWorksetPerm> = observer(props => {
  const { refresh } = props
  const store = useCILSStore()
  const { orgId } = useODSingleOrgContext()
  const editor = store.worksetShareStore.response
  const isOpen = store.worksetShareStore.isOpenChangeWorksetPerm
  // 화살표 함수로 해주지 않으면 this 를 잃어버림...
  const close = () => store.worksetShareStore.clearResponse()
  const selectFocus = useRef<HTMLInputElement>(null)

  if (!editor) {
    return null
  }

  const submit = async () => {
    if (!editor.canSubmit) {
      return showODToast(ODToastType.Error, ODToastType.Error, 'You must resolve conflicts to change permission.')
    }
    try {
      await editor.submit()
      showODToast(ODToastType.Success, ODToastType.Success, 'Updated permission')
      refresh()
      close()
    } catch (ex) {
      Utils.showError(ex)
    }
  }

  return (
    <ODModal isOpen={isOpen} toggle={close} size={ODModalSize.Big} style={{ maxWidth: 1210 }}>
      <ODModalHeader title={'Change Workset Permission'} onClose={close} headerBottomPadding={20} />
      <ODModalBody style={{ paddingLeft: 36, paddingRight: 38 }}>
        <DescBox>
          <DescText>
            To change the permission of the user in the workset, the permissions of the user you select must be equal to
            or higher than the permissions in all <br /> worksets added to the all projects. Modify the permissions of
            the project or workset to change the permission of the user.
          </DescText>
        </DescBox>
        <ODVSpace h={40} />
        <UserBox>
          <UserInfoBox>
            <ODIcon icon={ODIcons.MaterialAccountCircle} style={{ fontSize: 28, color: ODColors.Tealish }} />
            <ODHSpace w={11} />
            <UserNameText>{editor.targetUserName ?? '-'}</UserNameText>
          </UserInfoBox>
          <CustomSelectBox
            name="category"
            options={WORKSET_PERM_OPTIONS}
            value={WORKSET_PERM_OPTIONS.find(v => v.value === editor.targetUserWorksetPerm)}
            onChange={(e: SelectOptionType) => {
              editor.setTargetUserWorksetPerm(e.value)
            }}
            ref={selectFocus}
          />
        </UserBox>
        <ODVSpace h={40} />
        <Table striped style={{ marginTop: 0, borderBottom: `1px solid #c8ced2` }}>
          <thead>
            <tr>
              <th>Project with workset</th>
              <th>{editor.targetUserName ?? '-'} permission</th>
              <th>Conflict</th>
              <th>Change project permission</th>
            </tr>
          </thead>
          <tbody>
            {editor.messages.map(message => {
              return (
                <tr key={message.data.project.projectId}>
                  <td>
                    <a
                      href={SiteUrls.User.Org.Project.Detail(message.data.project.projectId)(orgId)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PrimaryText>{message.projectName ?? '-'}</PrimaryText>
                    </a>
                  </td>
                  <td>{message.existingProjectPerm ? PROJECT_USER_PERM_STRING[message.existingProjectPerm] : '-'}</td>
                  <td>{message.isConflict ? <span style={{ color: ODColors.Salmon }}>Conflict</span> : '-'}</td>
                  <td>
                    {message.canChangeProjectPerm && (
                      <CustomSelectBox
                        style={{ width: 100 }}
                        name="category"
                        options={PROJECT_PERM_OPTIONS(message.existingProjectPerm)}
                        value={PROJECT_PERM_OPTIONS(message.existingProjectPerm).find(
                          item => item.value === message.toChangeProjectPerm
                        )}
                        onChange={(e: SelectOptionType) => {
                          message.setToChangeProjectPerm(e.value)
                        }}
                        ref={selectFocus}
                      />
                    )}
                    {!message.canChangeProjectPerm && (
                      <span>
                        {message.existingProjectPerm ? PROJECT_USER_PERM_STRING[message.existingProjectPerm] : '-'}
                      </span>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <ConfirmButton onClick={submit}>Save</ConfirmButton>
      </ODModalBody>
    </ODModal>
  )
})

const DescBox = styled.div`
  display: flex;
  justify-content: center;
`

const DescText = styled.span`
  text-align: center;
  font-size: 14px;
  line-height: 1.43;
  color: ${ODColors.CoolGrey};
`

const UserBox = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 21px;
  padding-right: 15px;
  border: 1px solid ${ODColors.Primary};
  background-color: ${ODColors.PaleGrey2};
`

const UserInfoBox = styled.div`
  display: flex;
  align-items: center;
`

const UserNameText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${ODColors.Primary};
`

const CustomSelectBox = styled(Select)`
  width: 269px;
`

const PrimaryText = styled.span`
  color: ${ODColors.Primary};
  font-weight: bold;
`

const ConfirmButton = styled.button`
  width: 100%;
  max-width: 176px;
  height: 35px;
  border-radius: 3px;
  background-color: ${ODColors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${ODColors.White};
  margin-left: auto;
`
