import React from 'react'
import styled from 'styled-components'

type ODTextIconProps = {
  backgroundColor: string
  textColor?: string
  style?: object
}

const Oval = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${(p: ODTextIconProps) => p.backgroundColor};
  display: inline-block;
  text-align: center;

  span {
    width: 9px;
    height: 19px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.19px;
    color: ${(p: ODTextIconProps) => p.textColor || 'white'};
  }
`

export const ODTextIcon: React.FC<ODTextIconProps> = props => {
  const { children, style = {} } = props
  return (
    <Oval {...props} style={style}>
      <span>{children}</span>
    </Oval>
  )
}
