import { WorksetPermUtils } from '@cils/common/lib/permission/worksetPermUtils'
import React from 'react'
import {
  GQLAttachmentType,
  GQLItem,
  GQLItemAttachment,
  GQLListableItemAttachment,
  GQLListableItemAttachmentInput,
  GQLSingleIDInput,
} from '../../../@types/server'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { ItemAttachmentImage } from '../../../components/ItemAttachmentImage'
import { UseConfirmModalOptions, useModalConfirm } from '../../../components/ODModal/ODModalConfirm'
import { AttachmentEditData, EditAttachmentModal } from '../../../components/ODModal/ODModalEditAttachment'
import { useODQuery2 } from '../../../context/ODCommon'
import {
  createODListableContext,
  ODListableOption,
  ODListableResponseType,
} from '../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../ODListable/ODListablePaginationTable'
import { Utils } from '../../../utils'
import { convertItemAttachmentToThumbnailInfo, ThumbnailInfo } from './convertItemAttachmentToThumbnailInfo'
import { ThumbnailOverlayToolbar } from './ThumbnailOverlayToolbar'

export interface ItemAttachmentDataLoaderOption extends ODListableOption {
  filter: string | null
}

const { Provider, Context } = createODListableContext<GQLItemAttachment, ItemAttachmentDataLoaderOption>()

const GQL_GET_API = `
query listItemAttachment($itemInput: SingleIDInput!, $data: ListableItemAttachmentInput!) {
  getItem(data: $itemInput) {
    imageFile {
      link
    }
    mainAttachmentId
    permission {
      canModifyMetadata
      canShare
      canDownload
      canAddToWorkset
      canFavorite
      canAddAttachment
      canEditAttachment
    }
  }
  listItemAttachment(data: $data) {
    list {
      fileId
      link
      fileName
      attachmentType {
        typeId
        name
      }
      uploader {
        name
      }
      attachmentTypeId
      description
      createdAt
      updatedAt
    }
    totalCount
    page
    pageSize
  }
}
`

interface CustomInput {
  itemInput: GQLSingleIDInput
  data: Partial<GQLListableItemAttachmentInput>
}

interface CustomOutput {
  getItem: GQLItem
  listItemAttachment: GQLListableItemAttachment
}

interface Props {
  itemId: number
  onSetAttachmentAsMain: (v: GQLItemAttachment) => Promise<any>
  onUpdateAttachment: (thumbnail: ThumbnailInfo, changed: AttachmentEditData) => Promise<any>
  onRemove: (v: GQLItemAttachment) => any
  attachmentTypes: Array<GQLAttachmentType>
  refreshToken?: string
  wsPermUtils?: WorksetPermUtils
}

const deleteTagOptions: UseConfirmModalOptions = {
  message: <>Are you sure you want to delete this attachment file?</>,
  yes: 'Delete',
  no: 'Cancel',
}

export const ItemAttachmentList: React.FC<Props> = ({
  itemId,
  refreshToken = '',
  onSetAttachmentAsMain,
  onRemove,
  onUpdateAttachment,
  attachmentTypes,
  wsPermUtils,
}) => {
  const { api } = useODQuery2<CustomInput, CustomOutput>(GQL_GET_API, {
    wrapVariablesWithData: false,
    pickFirstKey: false,
    skip: true,
  })
  const [item, setItem] = React.useState<GQLItem | null>(null)
  const [mainAttachmentId, setMainAttachmentId] = React.useState<number | null>(null)
  const [ownRefreshToken, setOwnRefreshToken] = React.useState(0)
  const [editAttachment, setEditAttachment] = React.useState<ThumbnailInfo | null>(null)

  const { Component, confirm, props: confirmProps } = useModalConfirm(deleteTagOptions)

  const dataLoader = React.useCallback(
    async function ItemAttachmentDataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: ItemAttachmentDataLoaderOption
    ): Promise<ODListableResponseType<GQLItemAttachment>> {
      const r = await api({ itemInput: { id: itemId }, data: { page, pageSize, filter: options.filter, itemId } })
      setItem(r.getItem)
      setMainAttachmentId(r.getItem.mainAttachmentId)

      return r.listItemAttachment as ODListableResponseType<GQLItemAttachment>
    },
    [api, itemId]
  )

  const handleSetAsMain = async (v: GQLItemAttachment) => {
    await onSetAttachmentAsMain(v)
    setOwnRefreshToken(v => v + 1)
  }

  const handleEditAttachment = (v: GQLItemAttachment) => {
    setEditAttachment(convertItemAttachmentToThumbnailInfo(v, null))
  }

  const handleRemove = async (v: GQLItemAttachment) => {
    if (await confirm()) {
      await onRemove(v)
      setOwnRefreshToken(v => v + 1)
    }
  }

  const TableDefinition: ODListableTableDefinition<GQLItemAttachment, ItemAttachmentDataLoaderOption> = [
    {
      id: 'image',
      title: 'Image',
      transform: f => {
        const thumbnailInfo = convertItemAttachmentToThumbnailInfo(f, mainAttachmentId)

        return (
          <ItemAttachmentImage
            size={40}
            isSelected={false}
            item={thumbnailInfo}
            key={f.fileId.toString()}
            onClick={() => null}
          />
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'type',
      title: 'Type',
      transform: v => v.attachmentType.name,
      thClass: 'text-left',
      className: 'text-left user-td',
    },
    {
      id: 'description',
      title: 'Description',
      transform: v => <span style={{ color: '#73818f' }}>{v.description}</span>,
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
    {
      id: 'uploader',
      title: 'Uploader',
      transform: v => {
        return v.uploader?.name || '-'
      },
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
    {
      id: 'createdAt',
      title: 'Uploaded',
      transform: v => Utils.formatDate(v.createdAt),
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
    {
      id: 'modifiedAt',
      title: 'Modified',
      transform: v => Utils.formatDate(v.updatedAt),
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
    {
      id: 'setting',
      title: 'Action',
      transform: v => {
        const thumbnailInfo = convertItemAttachmentToThumbnailInfo(v, mainAttachmentId)

        return (
          <ThumbnailOverlayToolbar
            grayTheme
            editable
            handleSetAsMain={() => handleSetAsMain(v)}
            handleEditAttachment={() => handleEditAttachment(v)}
            handleRemove={() => handleRemove(v)}
            isMain={mainAttachmentId === v.fileId}
            wsPermUtils={wsPermUtils}
            canDeleteAttachment={item?.permission.canAddAttachment}
            canEditAttachment={item?.permission.canEditAttachment}
            downloadLink={thumbnailInfo.link}
          />
        )
      },
      thClass: 'text-left',
      className: 'text-left user-td',
      isHTML: false,
    },
  ]

  const handleSave = async (changed: AttachmentEditData) => {
    const p = onUpdateAttachment(editAttachment!, changed)
    setEditAttachment(null)
    await p
    setOwnRefreshToken(v => v + 1)
  }

  return (
    <FlexContentsContainer>
      <Component {...confirmProps} />
      {editAttachment && (
        <EditAttachmentModal
          initialValue={editAttachment}
          attachmentTypes={attachmentTypes}
          onDismiss={() => setEditAttachment(null)}
          onSave={handleSave}
          mouseMove={{ x: 900, y: 400 }} // .... magic number
          isUpload={false}
        />
      )}
      <Provider
        dataLoader={dataLoader}
        keyExtractor={v => v.fileId.toString()}
        pageSize={10}
        onDataLoaderError={Utils.showError}
        searchOnLoad
        refreshToken={refreshToken + ownRefreshToken}
      >
        {/*<div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
        {/*  <ODListableSearchBox*/}
        {/*    listableContext={Context}*/}
        {/*    placeholder="Search by name, key"*/}
        {/*    style={{ flexGrow: 6, maxWidth: 600 }}*/}
        {/*  />*/}
        {/*</div>*/}
        <ODListablePaginatedTable
          fields={TableDefinition}
          listableContext={Context}
          renderLoading={() => 'Loading..'}
          renderEmpty={() => 'No result.'}
        />
        <ODListablePagination hideIfSinglePage={false} listableContext={Context} />
      </Provider>
    </FlexContentsContainer>
  )
}
