import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { EventEmitter } from 'events'
import { AppOptions } from './AppOptions'

export function createApolloClient(
  getAuthToken: () => Promise<string | null>,
  setAuthToken: (token: string) => any,
  serverAddress: string,
  wsServerAddress?: string | null,
  emitter?: EventEmitter
): { client: ApolloClient<NormalizedCacheObject> } {
  const enableWebSocket = !!wsServerAddress && AppOptions.connectLocalAgent
  const wsLink = enableWebSocket
    ? new WebSocketLink({
        uri: `${wsServerAddress}/graphql`,
        options: {
          reconnect: true,
        },
      })
    : null

  const httpLink = createUploadLink({
    uri: `${serverAddress}/graphql`,
    credentials: 'include',
  })

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getAuthToken() // localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN)
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        token: token || '',
      },
    }
  })

  const parseTokenLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const cilsToken = response.extensions && response.extensions.cilsToken
      if (cilsToken) {
        setAuthToken(cilsToken)
      }
      return response
    })
  })

  const link = !enableWebSocket
    ? authLink.concat(parseTokenLink).concat(httpLink)
    : split(
        // split based on operation type
        ({ query }) => {
          // @ts-ignore
          const { kind, operation } = getMainDefinition(query)
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        // @ts-ignore
        wsLink,
        authLink.concat(httpLink)
      )

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) => {
            if (message.includes('CE-31001 ServerUnauthorized')) {
              emitter?.emit('ServerUnauthorized')
            }
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          })
        }

        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
        }
      }),
      link,
    ]),
    cache: new InMemoryCache(),
    // 우선 캐시는 모두 disable 하도록 한다. 상당히 신경써서 처리하지 않으면 리프레시가 동작하지 않는다.
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  })

  return { client }
}
