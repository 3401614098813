import { ODHSpace, ODListableContextType, ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import styled from 'styled-components'
import { GQLItemExecution, GQLWorksetGroup } from '../../../../@types/server'
import { ODIcon, ODIcons } from '../../../../components/ODIcon'
import { ODSearchBox } from '../../../../components/ODSearchBox'
import { ODColors } from '../../../../global-styles'
import { ODListableOption } from '../../../../ODListable/ODListableContext'
import { IProjectDataListableOption } from '../list/useProjectDataListDataLoader'
import { IProjectListableOption } from '../ProjectCommon'

interface IProjectDataSearch<T, O extends ODListableOption> {
  orgId: number
  listableContext: React.Context<ODListableContextType<GQLItemExecution, IProjectDataListableOption>>
  numConfirmedItems: number
}

export const ProjectDataSearch: React.FC<IProjectDataSearch<GQLWorksetGroup, IProjectListableOption>> = props => {
  const { children, listableContext, numConfirmedItems } = props

  const {
    updateLoadOption,
    state: { loadOption, totalCount },
  }: ODListableContextType<GQLItemExecution, IProjectDataListableOption> = React.useContext(listableContext)

  const [keyword, setKeyword] = React.useState('')

  const handleChange = (v: string) => {
    setKeyword(v)
    updateLoadOption({ ...loadOption, filter: v })
  }

  return (
    <Container>
      <SearchRow>
        <IconBox>
          <ODIcon icon={ODIcons.CoreLibrary} style={{ fontsize: 18, color: ODColors.Primary }} />
        </IconBox>
        <ODHSpace w={22} />
        <InfoBox>
          <TotalItemsText>Total {totalCount} items</TotalItemsText>
          <FinishedItemsText>Confirmed items {numConfirmedItems}</FinishedItemsText>
        </InfoBox>
        <ODSearchBox
          placeholder="Search by ID, Title"
          value={keyword}
          onChange={handleChange}
          style={{ width: '100%', maxWidth: 459, height: 35, marginLeft: 'auto', backgroundColor: ODColors.White }}
        />
      </SearchRow>
      <ODVSpace h={22} />
      <FiltersRow>{children}</FiltersRow>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 138px;
  background-color: ${ODColors.WhiteF7};
  border-left: 1px solid ${ODColors.Primary};
  padding: 22px;
  display: flex;
  flex-direction: column;
`

const SearchRow = styled.div`
  display: flex;
  align-items: flex-end;
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${ODColors.LightBlueGrey};
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
`

const TotalItemsText = styled.span`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  color: ${ODColors.Primary};
`

const FinishedItemsText = styled.span`
  font-size: 14px;
  letter-spacing: 0;
  color: ${ODColors.CharcoalGrey};
`

const FiltersRow = styled.div`
  display: flex;
  padding-left: 61px;
`
