import { ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import { GQLWorksetGroup } from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'
import { IWorksetGroupListableOption } from '../WorksetGroupCommon'

export function useWorksetGroupListDataLoader(orgId: number) {
  const { listWorksetGroupInOrg } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IWorksetGroupListableOption
    ): Promise<ODListableResponseType<GQLWorksetGroup>> {
      const r = await listWorksetGroupInOrg({
        page,
        pageSize,
        orgId,
        filter: options.filter,
        sortBy: options.sortBy,
      })
      return r as ODListableResponseType<GQLWorksetGroup>
    },
    [listWorksetGroupInOrg, orgId]
  )
}
