import { ODVSpace } from '@odc/od-react-belt'
import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { FlexContentsContainer } from '../../../components/FlexContentsContainer'
import { ODTabBarButton, ODTabBarContainer } from '../../../components/ODTabBarButton'
import { useCILSAgentContext } from '../../../context/CILSAgentContext'
import { AlertLogsListContainer } from '../../common/alert/AlertLogsListContainer'
import { AgentProgressListableTable } from './AgentProgressListableTable'
import { SelectedWorkset, SetSelectedWorkset } from './common'
import { DirectoryRootInfo, Wrapper } from './SyncCommon'
import { SyncScanProgress } from './SyncScanProgress'

interface ISyncAgentContainer {
  selectedWorkset: SelectedWorkset | undefined
  setSelectedWorkset: SetSelectedWorkset
}

export const SyncAgentContainer: React.FC<ISyncAgentContainer> = props => {
  const { selectedWorkset, setSelectedWorkset } = props

  const {
    state: { loginInfo },
  } = useCILSAgentContext()

  const [tab, setTab] = useQueryParam('tab', withDefault(NumberParam, 0))
  const isDownload = tab === 1
  const downloadRootPath = loginInfo?.downloadRoot ?? '-'
  const uploadRootPath = loginInfo?.uploadRoot ?? '-'
  const isAlert = tab === 2

  return (
    <Wrapper>
      <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
        <CardHeader>Agent</CardHeader>
        <CardBody>
          <FlexContentsContainer>
            <SyncScanProgress />
            <ODVSpace h={26} />
            <ODTabBarContainer>
              <ODTabBarButton active={tab === 0} onClick={() => setTab(0)}>
                <strong>Upload</strong>
              </ODTabBarButton>
              <ODTabBarButton active={tab === 1} onClick={() => setTab(1)}>
                <strong>Download</strong>
              </ODTabBarButton>
              <ODTabBarButton active={tab === 2} onClick={() => setTab(2)}>
                <strong>Alerts</strong>
              </ODTabBarButton>
            </ODTabBarContainer>
            <Card style={{ borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0, paddingLeft: 5 }}>
              {!isAlert && (
                <>
                  <ODVSpace h={30} />
                  <DirectoryRootInfo
                    isDownload={isDownload}
                    rootPath={isDownload ? downloadRootPath : uploadRootPath}
                    selectedWorkset={selectedWorkset}
                    onClickRoot={() => setSelectedWorkset(undefined)}
                  />
                  <ODVSpace h={20} />
                  <AgentProgressListableTable
                    isDownload={isDownload}
                    selectedWorkset={isDownload ? selectedWorkset : undefined}
                    setSelectedWorkset={setSelectedWorkset}
                  />
                </>
              )}
              {isAlert && loginInfo && (
                <>
                  <ODVSpace h={20} />
                  <AlertLogsListContainer orgId={null} userId={loginInfo!.userId} noCardWrap />
                </>
              )}
              {isAlert && !loginInfo && <div>Cannot connect agent.</div>}
            </Card>
          </FlexContentsContainer>
        </CardBody>
      </Card>
    </Wrapper>
  )
}
