import { ODListableOption, ODListableResponseType } from '@odc/od-react-belt'
import React from 'react'
import {
  GQLITEM_EXECUTION_ASSIGN_FILTER,
  GQLITEM_EXECUTION_CONFIRM_FILTER,
  GQLITEM_EXECUTION_LOCK_FILTER,
  GQLITEM_EXECUTION_SORT_OPTION,
  GQLItemExecution,
  GQLPROJECT_ITEM_USER_STATUS,
} from '../../../../@types/server'
import { useAPIs } from '../../../../context/useAPIs'
import { ItemExecutionOptionFilter, ProjectSelectOptionType } from '../ProjectCommon'

export interface IProjectDataListableOption extends ODListableOption {
  filter: string
  projectId: number
  wsId: number | null
  assignStatus: GQLPROJECT_ITEM_USER_STATUS | null
  reviewStatus: GQLPROJECT_ITEM_USER_STATUS | null
  sortBy: GQLITEM_EXECUTION_SORT_OPTION | null
}

export function useProjectDataListDataLoader(
  wsId: number | null,
  lockedFilter: ProjectSelectOptionType<ItemExecutionOptionFilter<GQLITEM_EXECUTION_LOCK_FILTER>> | null,
  assigneeFilter: ProjectSelectOptionType<ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>> | null,
  assignStatus: GQLPROJECT_ITEM_USER_STATUS | null,
  reviewerFilter: ProjectSelectOptionType<ItemExecutionOptionFilter<GQLITEM_EXECUTION_ASSIGN_FILTER>> | null,
  reviewStatus: GQLPROJECT_ITEM_USER_STATUS | null,
  confirmFilter: GQLITEM_EXECUTION_CONFIRM_FILTER | null,
  sortBy: GQLITEM_EXECUTION_SORT_OPTION | null,
  projectId: number
) {
  const { listItemExecution } = useAPIs()
  return React.useCallback(
    async function dataLoader(
      page: number,
      pageSize: number,
      afterKey: string | null,
      options: IProjectDataListableOption
    ): Promise<ODListableResponseType<GQLItemExecution>> {
      const r = await listItemExecution({
        page,
        pageSize,
        filter: options.filter,
        wsId,
        lockOption: lockedFilter?.value.type ?? null,
        lockerId: lockedFilter?.value.userId ?? null,
        assigneeOption: assigneeFilter?.value.type ?? null,
        assigneeId: assigneeFilter?.value.userId ?? null,
        assignStatus,
        reviewerOption: reviewerFilter?.value.type ?? null,
        reviewerId: reviewerFilter?.value.userId ?? null,
        reviewStatus,
        confirmOption: confirmFilter ?? null,
        sortBy,
        projectId,
      })
      return r as ODListableResponseType<GQLItemExecution>
    },
    [
      listItemExecution,
      wsId,
      lockedFilter,
      assigneeFilter,
      assignStatus,
      reviewerFilter,
      reviewStatus,
      sortBy,
      projectId,
      confirmFilter,
    ]
  )
}
