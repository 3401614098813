import { TCF_IMAGE_TYPE_CILS } from '@cils/common'
import { isBoolean } from 'lodash'
import moment, { Moment } from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import { GQLDateTimeRange } from '../@types/server'
import { GQLItemsListableContextType } from '../ODListable/ODListableContext'
import { useDetectOutsideClick } from '../utils/useDetectOutsideClick'
import { ODIcon, ODIcons } from './ODIcon'
import { DROPDOWN_MENU, DropDownMenuProps } from './ODImageGrid/ODImageGridSearchToolBar'

type Props = {
  handleMoreChange: (
    timeLapseOnly: boolean,
    tcfImageTypes: Array<string> | null,
    hasAttachment: boolean | null,
    hidden: boolean | null,
    modifiedTimeRange: GQLDateTimeRange | null
  ) => void
  listableContext: React.Context<GQLItemsListableContextType>
}

const DropButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 14px;
  padding-right: 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  text-align: left;
`
const DropDown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`
const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 456px;
  min-height: 150px;
  border: 1px solid #ececec;
  z-index: 1001;
  flex-direction: column;
  padding: 24px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Key = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2f353a;
  width: 94px;
  margin-right: 29px;
`
const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const SaveButton = styled.div`
  height: 35px;
  background-color: #29b6ca;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 8px;
  cursor: pointer;
`
const SaveButtonText = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  text-align: center;
  color: #ffffff;
`
const Label = styled.label`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #73818f;
`
const CustomDate = styled.div`
  .SingleDatePickerInput {
    width: 130px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #c8ced3;
  }
`

enum MODIFIED_DATE_OPTION {
  AnyTime,
  Today,
  Yesterday,
  Last7Days,
  Last30Days,
  Last90Days,
  Custom,
}

export const MoreDropDownComponent: React.FC<Props & DropDownMenuProps> = props => {
  const { handleMoreChange, listableContext, isMenuOpen, setIsMenuOpen } = props

  const {
    state: { loadOption },
  } = React.useContext(listableContext)

  const [isCheckedTimelapse, setCheckedTimelapse] = useState<boolean>(false)
  const [tcfImageTypes, setTcfImageTypes] = useState<Array<string>>([])
  const [hasAttachment, setHasAttachment] = useState<boolean | null>(null)
  const [hidden, setHidden] = useState<boolean | null>(null)
  const [modifiedDate, setModifiedDate] = useState<MODIFIED_DATE_OPTION>(MODIFIED_DATE_OPTION.AnyTime)
  const [startDateTime, setStartDateTime] = useState<Moment>(
    moment()
      .subtract(1, 'weeks')
      .startOf('isoWeek')
  )
  const [endDateTime, setEndDateTime] = useState<Moment>(moment())
  const [startDateFocused, setStartDateFocused] = useState<boolean | null>(false)
  const [endDateFocused, setEndDateFocused] = useState<boolean | null>(false)
  const [modifiedTimeRange, setModifiedTimeRange] = useState<GQLDateTimeRange | null>({ start: '', end: '' })

  const menuRef = React.useRef<HTMLDivElement>(null)
  const isOpen = isMenuOpen === DROPDOWN_MENU.More

  useDetectOutsideClick(() => setIsMenuOpen(null), menuRef, isOpen)

  useEffect(() => {
    if (loadOption) {
      setCheckedTimelapse(loadOption.timeLapseOnly!!)
      setTcfImageTypes(loadOption.tcfImageTypes || [])
      setHasAttachment(loadOption.hasAttachment === undefined ? null : loadOption.hasAttachment)
      setHidden(!isBoolean(loadOption.hidden) ? null : loadOption.hidden)
      if (loadOption.modifiedTimeRange === null || loadOption.modifiedTimeRange === undefined) {
        setModifiedDate(MODIFIED_DATE_OPTION.AnyTime)
      }
    }
  }, [loadOption])

  useEffect(() => {
    switch (modifiedDate) {
      case MODIFIED_DATE_OPTION.AnyTime:
        setModifiedTimeRange(null)
        return
      case MODIFIED_DATE_OPTION.Today:
        setModifiedTimeRange({
          start: moment()
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          end: moment().format('YYYY-MM-DD HH:mm:ss'),
        })
        return
      case MODIFIED_DATE_OPTION.Yesterday:
        setModifiedTimeRange({
          start: moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD 00:00:00'),
          end: moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD 23:59:59'),
        })
        return
      case MODIFIED_DATE_OPTION.Last7Days:
        setModifiedTimeRange({
          start: moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
          end: moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
        })
        return
      case MODIFIED_DATE_OPTION.Last30Days:
        setModifiedTimeRange({
          start: moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
          end: moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
        })
        return
      case MODIFIED_DATE_OPTION.Last90Days:
        setModifiedTimeRange({
          start: moment()
            .subtract(90, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
          end: moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
        })
        return
      default:
        return
    }
  }, [modifiedDate])

  const pushTcfImageTypesArray = (v: string, checked: boolean) => {
    if (checked) {
      const arr = [...tcfImageTypes]
      arr.push(v)
      setTcfImageTypes(arr)
    } else {
      const arr = [...tcfImageTypes]
      const index = arr.indexOf(v)
      if (index >= 0) {
        arr.splice(index, 1)
      }
      setTcfImageTypes(arr)
    }
  }

  const onSearchWorkset = () => {
    handleMoreChange(
      isCheckedTimelapse,
      tcfImageTypes.length > 0 ? tcfImageTypes : null,
      hasAttachment,
      hidden,
      modifiedDate === MODIFIED_DATE_OPTION.Custom
        ? {
            start: moment(startDateTime).format('YYYY-MM-DD 00:00:00'),
            end: moment(endDateTime).format('YYYY-MM-DD 23:59:59'),
          }
        : modifiedTimeRange
    )
    setIsMenuOpen(null)
  }

  const customModifiedDate = (() => {
    if (modifiedDate === MODIFIED_DATE_OPTION.Custom) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          <CustomDate style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <SingleDatePicker
              displayFormat="YYYY.MM.DD"
              small
              id="modified_date_start"
              date={startDateTime}
              onDateChange={date => date && setStartDateTime(date)}
              readOnly={false}
              focused={!!startDateFocused}
              onFocusChange={({ focused }) => {
                setStartDateFocused(focused)
                if (!focused) {
                  setEndDateFocused(true)
                }
              }}
              isOutsideRange={() => false}
            />
          </CustomDate>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 8, paddingRight: 8 }}>
            <span style={{ fontSize: 14, color: '#73818f', letterSpacing: 0.3 }}>~</span>
          </div>
          <CustomDate style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <SingleDatePicker
              displayFormat="YYYY.MM.DD"
              small
              id="modified_date_end"
              date={endDateTime}
              onDateChange={date => date && setEndDateTime(date)}
              readOnly={false}
              focused={!!endDateFocused}
              onFocusChange={({ focused }) => {
                setEndDateFocused(focused)
              }}
              isOutsideRange={date => date.isBefore(startDateTime)}
            />
          </CustomDate>
        </div>
      )
    }
    return null
  })()

  return (
    <DropDown ref={menuRef}>
      <DropButton onClick={() => (isOpen ? setIsMenuOpen(null) : setIsMenuOpen(DROPDOWN_MENU.More))}>
        <span>More</span>
        <ODIcon icon={ODIcons.MaterialArrowDropDownIcon} style={{ color: '#73818f', fontSize: 21 }} />
      </DropButton>
      <DropDownContent style={{ display: isOpen ? 'flex' : 'none' }}>
        <RowWrapper>
          <Key>Modified Date</Key>
          <Value style={{ flex: 1 }}>
            <Input
              style={{ width: '100%' }}
              type="select"
              name="select"
              id="select"
              value={modifiedDate}
              onChange={e => setModifiedDate(parseInt(e.target.value, 10))}
            >
              <option value={MODIFIED_DATE_OPTION.AnyTime}>Any time</option>
              <option value={MODIFIED_DATE_OPTION.Today}>Today</option>
              <option value={MODIFIED_DATE_OPTION.Yesterday}>Yesterday</option>
              <option value={MODIFIED_DATE_OPTION.Last7Days}>Last 7 days</option>
              <option value={MODIFIED_DATE_OPTION.Last30Days}>Last 30 days</option>
              <option value={MODIFIED_DATE_OPTION.Last90Days}>Last 90 days</option>
              <option value={MODIFIED_DATE_OPTION.Custom}>Custom...</option>
            </Input>
          </Value>
        </RowWrapper>
        {modifiedDate === MODIFIED_DATE_OPTION.Custom ? customModifiedDate : null}
        <RowWrapper style={{ marginTop: 12 }}>
          <Key>Type</Key>
          <Value>
            <Label style={{ marginBottom: 0, marginRight: 12 }}>
              <input
                type="checkbox"
                value={TCF_IMAGE_TYPE_CILS.I_3DHT}
                checked={tcfImageTypes.includes(TCF_IMAGE_TYPE_CILS.I_3DHT)}
                onChange={(e: any) => pushTcfImageTypesArray(e.target.value, e.target.checked)}
                style={{ marginRight: 7 }}
              />
              3D HT
            </Label>

            <Label style={{ marginBottom: 0, marginRight: 12 }}>
              <input
                type="checkbox"
                value={TCF_IMAGE_TYPE_CILS.I_FL}
                checked={tcfImageTypes.includes(TCF_IMAGE_TYPE_CILS.I_FL)}
                onChange={(e: any) => pushTcfImageTypesArray(e.target.value, e.target.checked)}
                style={{ marginRight: 7 }}
              />
              FL
            </Label>

            <Label style={{ marginBottom: 0, marginRight: 12 }}>
              <input
                type="checkbox"
                value={TCF_IMAGE_TYPE_CILS.I_2D}
                checked={tcfImageTypes.includes(TCF_IMAGE_TYPE_CILS.I_2D)}
                onChange={(e: any) => pushTcfImageTypesArray(e.target.value, e.target.checked)}
                style={{ marginRight: 7 }}
              />
              2D
            </Label>

            <Label style={{ marginBottom: 0, marginRight: 12 }}>
              <input
                type="checkbox"
                value={TCF_IMAGE_TYPE_CILS.I_BF}
                checked={tcfImageTypes.includes(TCF_IMAGE_TYPE_CILS.I_BF)}
                onChange={(e: any) => pushTcfImageTypesArray(e.target.value, e.target.checked)}
                style={{ marginRight: 7 }}
              />
              BF
            </Label>

            <Label style={{ marginBottom: 0, marginRight: 12 }}>
              <input
                type="checkbox"
                value={TCF_IMAGE_TYPE_CILS.I_PC}
                checked={tcfImageTypes.includes(TCF_IMAGE_TYPE_CILS.I_PC)}
                onChange={(e: any) => pushTcfImageTypesArray(e.target.value, e.target.checked)}
                style={{ marginRight: 7 }}
              />
              PC
            </Label>
          </Value>
        </RowWrapper>
        <RowWrapper style={{ marginTop: 12 }}>
          <Key>Timelapse</Key>
          <Value>
            <Label style={{ margin: 0 }}>
              <input
                type="checkbox"
                checked={isCheckedTimelapse || false}
                onChange={() => setCheckedTimelapse(!isCheckedTimelapse)}
                style={{ marginRight: 7 }}
              />
              Timelapse
            </Label>
          </Value>
        </RowWrapper>
        <RowWrapper style={{ marginTop: 12 }}>
          <Key>Attachment</Key>
          <Value>
            <Label style={{ margin: 0, marginRight: 12 }}>
              <input
                type="radio"
                name="attachment"
                style={{ marginRight: 7 }}
                checked={hasAttachment === null}
                onChange={() => setHasAttachment(null)}
              />
              Y/N
            </Label>
            <Label style={{ margin: 0, marginRight: 12 }}>
              <input
                type="radio"
                name="attachment"
                style={{ marginRight: 7 }}
                checked={hasAttachment === true}
                onChange={() => setHasAttachment(true)}
              />
              Y
            </Label>
            <Label style={{ margin: 0, marginRight: 12 }}>
              <input
                type="radio"
                name="attachment"
                style={{ marginRight: 7 }}
                checked={hasAttachment === false}
                onChange={() => setHasAttachment(false)}
              />
              N
            </Label>
          </Value>
        </RowWrapper>
        <RowWrapper style={{ marginTop: 12 }}>
          <Key>Hidden</Key>
          <Value>
            <Label style={{ margin: 0, marginRight: 12 }}>
              <input
                type="radio"
                name="hidden"
                style={{ marginRight: 7 }}
                checked={hidden === null}
                onChange={() => setHidden(null)}
              />
              Y/N
            </Label>
            <Label style={{ margin: 0, marginRight: 12 }}>
              <input
                type="radio"
                name="hidden"
                style={{ marginRight: 7 }}
                checked={hidden === true}
                onChange={() => setHidden(true)}
              />
              Y
            </Label>
            <Label style={{ margin: 0, marginRight: 12 }}>
              <input
                type="radio"
                name="hidden"
                style={{ marginRight: 7 }}
                checked={hidden === false}
                onChange={() => setHidden(false)}
              />
              N
            </Label>
          </Value>
        </RowWrapper>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
          <SaveButton onClick={() => onSearchWorkset()} style={{ marginTop: 20 }}>
            <SaveButtonText>Search</SaveButtonText>
          </SaveButton>
        </div>
      </DropDownContent>
    </DropDown>
  )
}
