import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'
import { ReactNodeLike } from 'prop-types'
import * as React from 'react'
import { useMutation, useQuery, useSubscription } from 'react-apollo-hooks'
import { Utils } from '../utils'

export type ODRoute = {
  path: string
  exact?: boolean
  name: ReactNodeLike
  component?: React.ComponentClass<any> | React.FunctionComponent<any>
  render?: (props: any) => ReactNodeLike
}

type ODSiteNavItem = {
  name: string
  url: string
  icon?: React.ReactNode
  iconStyle?: object
  iconSpanStyle?: object
  exact?: boolean
  isSeparator?: boolean
  attributes?: object
  class?: string
}

export type ODSiteNav = {
  items: Array<ODSiteNavItem>
}

export function useODQuery<I, O>(gqlString: string, simulateDelay: number = 0): (input: I) => Promise<O> {
  const { refetch } = useQuery(gql(gqlString), { skip: true, errorPolicy: 'none' })

  return React.useCallback(
    async (input: I) => {
      await new Promise(resolve => setTimeout(resolve, simulateDelay))
      let res = await refetch({ data: input })
      if (res.data === undefined && res.errors === undefined) {
        // 대체 무슨 상황?
        console.error(`Apollo returned unexpected undefined error. retrying...`)
        res = await refetch({ data: input })
        if (res.data === undefined && res.errors === undefined) {
          throw new Error(`Apollo returned undefined error.`)
        }
      }
      return Utils.extractGQLResponse(res) as O
    },
    [refetch, simulateDelay]
  )
}

type ODQuery2Response<I, O> = {
  api: (input: I) => Promise<O>
  data: O | undefined
  error: Error | undefined
  loading: boolean
  refetch: () => any
}

type ODQuery2Options<I> = {
  skip?: boolean
  simulateDelay?: number
  pickFirstKey?: boolean
  variables?: I
  refreshInterval?: number
  wrapVariablesWithData?: boolean
  client?: ApolloClient<any>
  showErrorToast?: boolean
}

export function useODQuery2<I, O>(gqlString: string, options: ODQuery2Options<I> = {}): ODQuery2Response<I, O> {
  const defaultOptions: ODQuery2Options<I> = {
    skip: false,
    simulateDelay: 0,
    pickFirstKey: false,
    refreshInterval: 0,
    wrapVariablesWithData: true,
    showErrorToast: false,
  }

  const op = { ...defaultOptions, ...options }
  const { showErrorToast } = op
  const { simulateDelay, pickFirstKey, variables, refreshInterval = 0, wrapVariablesWithData } = op
  const { data, error, loading, refetch } = useQuery(gql(gqlString), {
    skip: op.skip,
    errorPolicy: 'none',
    variables: wrapVariablesWithData ? { data: variables } : variables,
    client: options.client,
  })

  const [processedData, setProcessedData] = React.useState(data)
  React.useEffect(() => {
    if (pickFirstKey && data) {
      setProcessedData(data[Object.keys(data)[0]] as O)
    } else {
      setProcessedData(data)
    }
  }, [pickFirstKey, data])

  const api = React.useCallback(
    async (input: I) => {
      await new Promise(resolve => setTimeout(resolve, simulateDelay))
      const res = await refetch(wrapVariablesWithData ? { data: input } : input)
      if (pickFirstKey) {
        return Utils.extractGQLResponse(res) as O
      }
      return res.data as O
    },
    [refetch, simulateDelay, pickFirstKey, wrapVariablesWithData]
  )

  React.useEffect(() => {
    if (refreshInterval > 0) {
      const handler = setInterval(() => refetch({ data: variables }), refreshInterval)
      return () => clearInterval(handler)
    }
  }, [refreshInterval, variables, refetch])

  React.useEffect(() => {
    if (error && showErrorToast) {
      Utils.showError(error)
    }
    // eslint-disable-next-line
  }, [error])

  return { api, data: processedData, error, loading, refetch }
}

export function useODMutation<I, O>(gqlString: string, simulateDelay: number = 0): (input: I) => Promise<O> {
  const [mutationApi] = useMutation(gql(gqlString))
  return React.useCallback(
    async (input: I) => {
      await new Promise(resolve => setTimeout(resolve, simulateDelay))
      const res = await mutationApi({ variables: { data: input } })
      return Utils.extractGQLResponse(res) as O
    },
    [mutationApi, simulateDelay]
  )
}

type ODSubscriptionOptions<I> = {
  client?: ApolloClient<any>
}

export function useODSubscription<I, O>(gqlString: string, options: ODSubscriptionOptions<I> = {}) {
  return useSubscription(gql(gqlString), { variables: {}, client: options.client })
}

export function useCounter(): [number, () => void] {
  const [counter, setCounter] = React.useState(0)
  return [counter, () => setCounter(counter + 1)]
}

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}
