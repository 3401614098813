import { ORG_ROLE, WORKSET_USER_PERM, worksetUserPermissionToString } from '@cils/common'
import { BlockingLoadBox, useCounter, useODModalConfirm } from '@odc/od-react-belt'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import { GQLWorkset, GQLWorksetGroupHasWorkset } from '../../../../@types/server'
import { ODIcon, ODIcons } from '../../../../components/ODIcon'
import { ODToastType, showODToast } from '../../../../components/ODToast'
import { ODModalAddWorksetsToProjectResult } from '../../../../components/project/modal/ODModalAddWorksetsToProjectResult'
import { WorksetGroupDetailButtonsRow } from '../../../../components/worksetGroup/WorksetGroupDetailButtonsRow'
import { useAPIs } from '../../../../context/useAPIs'
import { useOrgPerm } from '../../../../context/useOrgPerm'
import { useCILSStore } from '../../../../di/configureRootStore'
import { createODListableContext, ODListableStyle } from '../../../../ODListable/ODListableContext'
import { ODListablePagination } from '../../../../ODListable/ODListablePagination'
import { ODListablePaginatedTable, ODListableTableDefinition } from '../../../../ODListable/ODListablePaginationTable'
import { ODColors } from '../../../../styles/ODColors'
import { SiteUrls } from '../../../../urls'
import { Utils } from '../../../../utils'
import { useProjectSelectModal } from '../../Project/useProjectSelectModal'
import { MultiWorksetShareModal } from '../../Workset/MultiWorksetShareModal'
import { useWorksetsToGroupPickerModal } from '../useWorksetsToGroupPickerModal'
import { IWorksetGroupHasWorksetListableOption } from '../WorksetGroupCommon'
import { useWorksetGroupHasWorksetListDataLoader } from './useWorksetGroupHasWorksetListDataLoader'

interface IWorksetGroupHasWorksetListableTable {
  worksetGroupName: string
  wsgId: number
  orgId: number
}

const { Provider, Context } = createODListableContext<
  GQLWorksetGroupHasWorkset,
  IWorksetGroupHasWorksetListableOption
>()

export const WorksetGroupHasWorksetListableTable: React.FC<IWorksetGroupHasWorksetListableTable> = observer(props => {
  const { wsgId, worksetGroupName, orgId } = props
  const [worksetsToShare, setWorksetsToShare] = React.useState<GQLWorkset[] | null>(null)
  // Manage Organization Workset 권한인지?
  const store = useCILSStore()
  const isManageOrganizationWorkset = useOrgPerm(orgId)?.isAllowed(ORG_ROLE.EDIT_ORG_WORKSET) || false
  const dataLoader = useWorksetGroupHasWorksetListDataLoader(wsgId, orgId, isManageOrganizationWorkset, worksetIds =>
    store.addWorksetToProjectStore.addWorksetIds(worksetIds)
  )
  const { removeWorksetGroupHasWorkset, createWorksetGroupHasWorkset } = useAPIs()
  const [token, refresh] = useCounter()
  const [loading, setLoading] = React.useState(false)
  const [isOpenResult, setIsOpenResult] = React.useState(false)

  const {
    Component: PickerTableComponent,
    props: pickerTableProps,
    pick: pickWorksetsToProject,
  } = useProjectSelectModal(async (projectId, projectName) => {
    store.addWorksetToProjectStore.setProjectId(projectId)
    store.addWorksetToProjectStore.setProjectName(projectName)
    await store.addWorksetToProjectStore.submit()
    setIsOpenResult(true)
  })

  const handleSelectWorksetsToProject = React.useCallback(async () => {
    const title = `Select Project`
    const searchPlaceholder = 'Search by project name, username'
    try {
      await pickWorksetsToProject({
        showSearch: true,
        searchPlaceholder,
        returnFullValue: false,
        title,
      })
    } catch (ex) {
      Utils.showError(ex)
    } finally {
      setLoading(false)
      refresh()
    }
  }, [pickWorksetsToProject])

  const { Component: ConfirmComponent, props: confirmProps, confirm } = useODModalConfirm({})

  const handleRemoveWorkset = async (item: GQLWorkset) => {
    if (
      await confirm({
        title: `Remove Workset`,
        message: (
          <>
            Are you sure you want to remove <span style={{ color: ODColors.Primary }}>{item.name}</span>
            <br />
            from the Group?
            <br />
            This cannot be undone.
          </>
        ),
        yes: 'Remove',
        no: 'Cancel',
      })
    ) {
      // if (item.myPerm === WORKSET_USER_PERM.Viewer) {
      //   showODToast(ODToastType.Error, ODToastType.Error, 'Permission이 Viewer인 경우에는 삭제 할 수 없습니다.')
      // } else {
      try {
        setLoading(true)
        await removeWorksetGroupHasWorkset({ wsgId, worksetId: item.wsId })
        refresh()
      } catch (ex) {
        Utils.showError(ex)
      } finally {
        setLoading(false)
      }
      // }
    }
  }

  const TableDefinition: ODListableTableDefinition<GQLWorksetGroupHasWorkset, IWorksetGroupHasWorksetListableOption> = [
    {
      id: 'WorksetName',
      title: 'Workset name',
      transform: v => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link to={SiteUrls.User.Org.Workset.Detail(v.wsId)(orgId)}>{v.workset.name ?? '-'}</Link>
        </div>
      ),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Items',
      title: 'Items',
      transform: v => {
        return <div>{v.workset.numItems}</div>
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'lastAdded',
      title: 'Last item added',
      transform: v => (v.workset.lastAddedAt ? moment(v.workset.lastAddedAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Owner',
      title: 'Owner',
      transform: v => {
        const owner = v.workset.members.find(v => v.permLevel === WORKSET_USER_PERM.Owner)
        return <div>{owner?.user?.name || '-'}</div>
      },
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'created',
      title: 'Created',
      transform: v => (v.workset.createdAt ? moment(v.workset.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'YourPermission',
      title: 'Your Permission',
      transform: v => (v.workset.myPerm ? worksetUserPermissionToString(v.workset.myPerm) : '-'),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Project',
      title: 'Project',
      transform: v => (
        <div>
          {v.workset.projects.map(project => (
            <Link key={project.projectId} to={SiteUrls.User.Org.Project.Detail(project.projectId)(orgId)}>
              {project.name ?? '-'}
            </Link>
          ))}
          {(!v.workset.projects || v.workset.projects.length === 0) && '-'}
        </div>
      ),
      thClass: 'text-left',
      className: 'text-left',
    },
    {
      id: 'Action',
      title: 'Action',
      transform: v => (
        <DeleteButton type={'button'} onClick={() => handleRemoveWorkset(v.workset)}>
          <ODIcon icon={ODIcons.CoreXCircle} style={{ width: 16, height: 16, fontSize: 16, color: ODColors.White }} />
        </DeleteButton>
      ),
      thClass: 'text-left',
      className: 'text-left',
    },
  ]

  const handleMultipleWorksetShare = async (worksets: GQLWorkset[]) => {
    if (worksets.length === 0) {
      showODToast(ODToastType.Info, ODToastType.Info, '워크셋이 존재하지 않습니다.')
      return
    }
    setWorksetsToShare(worksets)
    refresh()
  }

  const {
    Component: WorksetToGroupPickerTableComponent,
    props: worksetToGroupPickerTableProps,
    pick: pickWorksetsToGroup,
  } = useWorksetsToGroupPickerModal(wsgId)

  const handleMultipleWorksetsToGroup = React.useCallback(async () => {
    const groupName = worksetGroupName
    const title = `Add Worksets to ${groupName}`
    const searchPlaceholder = 'Search by workset name, project name, Workset group name'
    const selectedWorksetIds = await pickWorksetsToGroup({
      showSearch: true,
      searchPlaceholder,
      returnFullValue: false,
      title,
    })

    if (!selectedWorksetIds) {
      return
    }
    await createWorksetGroupHasWorkset({ wsgId, worksetIds: selectedWorksetIds as number[] })
    refresh()
  }, [pickWorksetsToGroup, createWorksetGroupHasWorkset, refresh, worksetGroupName, wsgId])

  return (
    <>
      <ConfirmComponent {...confirmProps} />
      <WorksetToGroupPickerTableComponent {...worksetToGroupPickerTableProps} />
      <PickerTableComponent {...pickerTableProps} />
      <ODModalAddWorksetsToProjectResult
        orgId={orgId}
        setIsOpen={setIsOpenResult}
        isOpen={isOpenResult}
        openSelectProject={handleSelectWorksetsToProject}
      />

      <BlockingLoadBox show={loading} />
      <Provider
        dataLoader={dataLoader}
        // WorksetGroup으로 교체 필요
        keyExtractor={v => v.wsId.toString()}
        pageSize={1000}
        style={ODListableStyle.TableStyle}
        refreshToken={token.toString()}
      >
        {worksetsToShare && (
          <MultiWorksetShareModal
            worksets={worksetsToShare}
            isOpen={!!worksetsToShare}
            onClose={() => setWorksetsToShare(null)}
            isLowerThanEditorCanNotControll={true}
          />
        )}
        <div style={{ padding: 17 }}>
          <Card style={{ padding: 0, margin: 0, flexGrow: 2 }}>
            <CardHeader style={{ backgroundColor: ODColors.PaleGrey, height: 45, fontSize: 14, letterSpacing: 0 }}>
              {worksetGroupName}
            </CardHeader>
            <WorksetGroupDetailButtonsRow
              listableContext={Context}
              addAndRemoveUsers={handleMultipleWorksetShare}
              addWorksetsToThisGroup={handleMultipleWorksetsToGroup}
              handleSelectWorksetsToProject={handleSelectWorksetsToProject}
            />
            <CardBody>
              <ODListablePaginatedTable
                fields={TableDefinition}
                listableContext={Context}
                renderLoading={() => 'Loading..'}
                renderEmpty={() => 'No Result.'}
              />
              <ODListablePagination hideIfSinglePage listableContext={Context} />
            </CardBody>
          </Card>
        </div>
      </Provider>
    </>
  )
})

const DeleteButton = styled.button`
  background-color: ${ODColors.Redish};
  width: 39px;
  height: 35px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`
