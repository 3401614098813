import { DEFAULT_ORG_USER_PERM_SCHEMA, PermUtils } from '@cils/common'
import React from 'react'
import { useODAppContext } from './ODAppContext'

export function useOrgPerm(orgId: number): PermUtils | undefined {
  const {
    state: { userProfile },
  } = useODAppContext()

  const [permUtil, setPermUtil] = React.useState<PermUtils | undefined>()

  React.useEffect(() => {
    const orgHasUser = userProfile?.organizations.find(o => o.org.orgId === orgId)
    if (!orgHasUser) {
      setPermUtil(undefined)
      return
    }

    setPermUtil(
      new PermUtils(
        orgHasUser.orgUserPriv,
        orgHasUser.permSchema ? JSON.parse(orgHasUser.permSchema) : DEFAULT_ORG_USER_PERM_SCHEMA
      )
    )
  }, [userProfile, orgId])
  return permUtil
}
